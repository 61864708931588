import {
  Component,
  Input,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { User } from "app/auth/models";
import { AuthenticationService } from "app/auth/service";
import { ToastrService } from "ngx-toastr";
import { AppComponent } from "../../../app.component";
import {
  StudentFileUploadModel,
  UploadFileModel,
} from "../model/UploadFileModel";
import { ClientStudentsService } from "../services/client-students.service";
import { StudentAddNewAttachmentComponent } from "./StudentAddNewAttachment/student-add-new-attachment.component";
@Component({
  selector: "app-attachment-section",
  templateUrl: "./attachment-section.component.html",
  styleUrls: ["./attachment-section.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class AttachmentSectionComponent implements OnInit {
  opened = false;
  public rowIndex: number = -1;
  public selectedData: any = {};
  public openedDelete: boolean = false;
  public _currentUser: User;
  @Input("studentId") studentId: number;
  @ViewChild("studentAddNewAttachmentComponent")
  addNewAttachmentComponent: StudentAddNewAttachmentComponent;
  @Input() gridData: any = [];
  @Input("disable") disable: boolean = true;
  public disabeld: boolean = false;
  /**
   * Constructor
   * * @param {AuthenticationService} _authenticationService
   */
  constructor(
    private _authenticationService: AuthenticationService,
    private _toastrService: ToastrService,
    private _modalService: NgbModal,
    private _appcomponent: AppComponent,
    private _clientStudentService: ClientStudentsService,
  ) {
    this._authenticationService.currentUser.subscribe(
      x => (this._currentUser = x),
    );
  }

  ngOnInit(): void {
    this.disabeld = !this.disable;
  }

  modalOpenMD(data: any = null, rowIndex?: number) {
    this.opened = true;
    this.addNewAttachmentComponent.data = data;
    if (data != null) {
      this.addNewAttachmentComponent.data = this.gridData[rowIndex];
    }
      this.rowIndex = rowIndex == null ? this.gridData.length : rowIndex;
    this.addNewAttachmentComponent.open();
  }

  reloadData(result: any) {
    let that = this;
    if (result.mode == "edit") {
      if (that.studentId != 0) {
        that.loadAttachments();
      }
    } else {
      that.reloadDataNew(result);
    }
  }
  reloadDataNew(result) {
    if (this.rowIndex != -1) {
      if (
        this.gridData[this.rowIndex] == undefined ||
        this.gridData[this.rowIndex] == null
      ) {
        this.gridData.push(JSON.parse(JSON.stringify(result.data)));
      } else {
        this.gridData[this.rowIndex] = JSON.parse(JSON.stringify(result.data));
      }
    } else {
      this.gridData.push(result.data);
    }
  }
  EditAttachment(fileModel: StudentFileUploadModel) {
    this.addNewAttachmentComponent.fileModel = fileModel;
    this.modalOpenMD();
  }

  getExtentionData(data: string) {
    return data.toUpperCase();
  }

  generateGoogleCloudViewUrl(fileName) {
    let that = this;
    let file = fileName.replaceAll("___", "/");
    let result: UploadFileModel = new UploadFileModel();
    result.name = file;
    that._clientStudentService.downloadFile(result).subscribe(response => {
      that.openBase64InNewTab(response.Value, "image/png");
    });
  }

  openBase64InNewTab(data, mimeType) {
    let byteCharacters = atob(data);
    let byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    let byteArray = new Uint8Array(byteNumbers);
    let file = new Blob([byteArray], { type: mimeType + ";base64" });
    let fileURL = URL.createObjectURL(file);
    window.open(fileURL);
  }

  deleteAttachment(data: any, rowIndex: number = 0) {
    let that = this;
    if (!data.Id) {
      that.gridData.splice(rowIndex, 1);
    } else {
      // delete by api on edit Student
      that.openedDelete = true;
      that.selectedData.Data = data;
      that.selectedData.RowIndex = rowIndex;
    }
  }

  close(status: string): void {
    let that = this;
    if (status == "deleted") {
      that.openedDelete = false;
      that.selectedData = {};
    }
  }

  confirmDeleteAttachment() {
    let that = this;
    that.openedDelete = false;
    that._appcomponent.toggeleLoader();
    that._clientStudentService
      .deleteAttachment(that.selectedData.Data.Id)
      .subscribe(
        (data: any) => {
          that.gridData.splice(that.selectedData.RowIndex, 1);
          that._toastrService.success(
            "The attachment was deleted successfully.",
            "",
          );
          that._appcomponent.toggeleLoader();
        },
        error => {
          if (error.status == 401) {
            that._toastrService.error("UnAuthorized", "");
          } else if (error.status == 403) {
            that._toastrService.error(error.error.Message, "");
          } else if (error.status == 500) {
            that._toastrService.error(
              "The attachment was not deleted due to a technical issue.",
              "",
            );
          } else {
            that._toastrService.error("Server internal error", "");
          }
          that._appcomponent.toggeleLoader();
        },
      );
  }

  /*Attachments*/
  loadAttachments() {
    let that = this;
    that._clientStudentService.getAttachments(that.studentId).subscribe(
      (Response: any) => {
        that.gridData = Response;
        that._appcomponent.toggeleLoader();
      },
      error => {
        if (error.status == 401) {
          that._toastrService.error("UnAuthorized.", "");
          that._appcomponent.toggeleLoader();
        } else {
          that._toastrService.error(
            "The attachments was not loaded due to a technical issue.",
            "",
          );
          that._appcomponent.toggeleLoader();
        }
      },
    );
  }
}
