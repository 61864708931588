import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AuthenticationService } from "app/auth/service";
import { environment } from "environments/environment";
import { ConsecutiveNoShowReportFiliter } from "../model/consecutive-no-show-filiter.model";
import { NoAttendanceReportModel } from "../model/no-attendance-report.model";
import { StudentDataModel } from "../model/student-data.model";

@Injectable({
  providedIn: "root",
})
export class ConsecutiveNoShowReportService {
  constructor(
    private _httpClient: HttpClient,
    private _authenticationService: AuthenticationService,
  ) {}
  getConsecutiveNoShowReport(filterModel: ConsecutiveNoShowReportFiliter) {
    let queryParams = new HttpParams();
    queryParams = queryParams.append(
      "number_of_consecutive",
      filterModel.NumberOfConsecutive,
    );
    queryParams = queryParams.append(
      "client_id",
      this._authenticationService.currentUserValue.client_id,
    );
    queryParams = queryParams.append("student_id", filterModel.StudentId);
    queryParams = queryParams.append(
      "consecutiveFilter",
      filterModel.consecutiveFilter.toString(),
    );
    queryParams = queryParams.append("isFromClient", true);
    queryParams = queryParams.append(
      "acc_id",
      this._authenticationService.currentUserValue.id,
    );

    return this._httpClient.get<NoAttendanceReportModel[]>(
      `${environment.apiUrl}/consecutive-no-show-report`,
      { params: queryParams },
    );
  }
  getPassengersData() {
    let queryParams = new HttpParams();

    queryParams = queryParams.append(
      "client_id",
      this._authenticationService.currentUserValue.client_id,
    );

    return this._httpClient.get<any>(
      `${environment.apiUrl}/rides-finder/passengers`,
      { params: queryParams },
    );
  }
  getStudentProfile(id) {
    return this._httpClient.get<StudentDataModel>(
      `${environment.apiUrl}/students/${id}/basic-profile`,
    );
  }
}
