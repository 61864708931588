import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AuthenticationService } from "app/auth/service";
import { ToastrService } from "ngx-toastr";
import { TabPosition } from "@progress/kendo-angular-layout";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { IFiltersConfig } from "app/controls/component/filters/interfaces/controls/IFiltersConfig";
import { User } from "app/auth/models";
import { AppComponent } from "app/app.component";
import { HelpersService } from "app/main/SharedServices/helpers.service";

import { ActivatedRoute } from "@angular/router";
import { ClientStudentsService } from "../../services/client-students.service";
import { StudentContactModel } from "../../model/StudentContactModel";
import { PermissionsList } from "app/enums/Permissions/PermissionList";
@Component({
  selector: "app-student-add-new-contact",
  templateUrl: "./student-add-new-contact.component.html",
  styleUrls: ["./student-add-new-contact.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class StudentAddNewContactComponent implements OnInit {
  public submitted = false;
  public PageTitle: string;
  public newContactForm: FormGroup;
  public position: TabPosition = "top";
  @ViewChild("AddNewContactModal") AddNewContactModal: TemplateRef<any>;
  @ViewChild("EditContactModal") EditContactModal: TemplateRef<any>;
  @Input("disable") disable: boolean = false;
  @Output() Added = new EventEmitter<any>();
  validRelationShip: boolean = false;
  public Config: IFiltersConfig;
  public _currentUser: User;
  studentId = 0;
  guardianexist: boolean = false;
  public data: any;
  public disabledContact: boolean = false;

  /**
   * Constructor
   * * @param {AuthenticationService} _authenticationService
   * * @param {HelpersService} _helpersService
   * * @param {ClientStudentsService} _clientStudentService
   * * @param {NgbModal} _modalService
   * * @param {ToastrService} _toastrService
   * * @param {FormBuilder} _formBuilder
   * * @param {AppComponent} _appcomponent
   */
  constructor(
    private _formBuilder: FormBuilder,
    private _authenticationService: AuthenticationService,
    private _toastrService: ToastrService,
    private _modalService: NgbModal,
    private _helperService: HelpersService,
    private _appcomponent: AppComponent,
    private _clientStudentService: ClientStudentsService,
    private route: ActivatedRoute,
  ) {
    this._authenticationService.currentUser.subscribe(
      x => (this._currentUser = x),
    );
  }
  public PermessionValue: string;

  ngOnInit(): void {
    this.disabledContact = this.disable;
    if (this.route.snapshot.paramMap.get("id")) {
      this.studentId = parseInt(this.route.snapshot.paramMap.get("id")!);
      this.PermessionValue = PermissionsList.ClientStudentsEdit.toString();
    } else {
      this.PermessionValue = PermissionsList.ClientStudentsCreate.toString();
    }
    this.newContactForm = this._formBuilder.group({
      relationshipName: ["", [Validators.required]],
      FirstName: ["", [Validators.required]],
      LastName: ["", [Validators.required]],
      PhoneNumber: [
        "",
        [Validators.pattern(/^\(\d{3}\)\s\d{3}-\d{4}$/), Validators.required],
      ],
      Email: ["", [Validators.email]],
      ContactPhoneExtention: [],
      ContactNote: [],
    });
    this.Config = {
      isFiltersEnable: false,
      Filters: [
        {
          filterValues: [
            { id: "Aunt", name: "Aunt" },
            { id: "Brother", name: "Brother" },
            { id: "Father", name: "Father" },
            { id: "Grandfather", name: "Grandfather" },
            { id: "Grandmother", name: "Grandmother" },
            { id: "Legal Guardian", name: "Legal Guardian" },
            { id: "Mother", name: "Mother" },
            { id: "Sister", name: "Sister" },
            { id: "Uncle", name: "Uncle" },
            { id: "Other", name: "Other" },
          ],
          filterClear: true,
          filterVisability: true,
          filterPlaceholder: "Select a relationship.",
        },
      ],
    };
  }

  changeRelationship(e: any) {
    this.relationshipName?.setValue(e.target.value, {
      onlySelf: true,
    });
  }

  // Access formcontrols getter
  get relationshipName() {
    return this.newContactForm.get("relationshipName");
  }

  public isValidForm(): boolean {
    let that = this;

    that.relationshipSelected();
    for (const control of Object.keys(that.newContactForm.controls)) {
      that.newContactForm.controls[control].markAsTouched();
    }

    return that.newContactForm.invalid;
  }

  public open() {
    if (this.data && this.data != null) {
      this.PageTitle = "Edit Emergency Contacts";
      this.newContactForm.setValue({
        relationshipName: this.data.RelationshipToEntity,
        FirstName: this.data.FirstName,
        LastName: this.data.LastName,
        PhoneNumber: this.getMobileNumber(this.data.PhoneNumber),
        Email: this.data.Email,
        ContactPhoneExtention:
          this.data.PhoneExtention == "0" ? "" : this.data.PhoneExtention,
        ContactNote: this.data.Note,
      });
      this.Config.Filters[0].filterValue = this.data.RelationshipToEntity;

      this._modalService.open(this.EditContactModal, {
        centered: true,
        backdrop: "static",
        size: "md",
      });
    } else {
      this.PageTitle = "Add Emergency Contacts";
      this._modalService.open(this.AddNewContactModal, {
        centered: true,
        backdrop: "static",
        size: "md",
      });
    }
  }

  public relationshipSelected() {
    if (this.Config.Filters[0].filterValue == null) {
      this.validRelationShip = true;
      return this.validRelationShip;
    } else {
      this.validRelationShip = false;
      return this.validRelationShip;
    }
  }

  getMobileNumber(mobileNumber: string) {
    return this._helperService.phoneNumber(mobileNumber);
  }

  close(model: any) {
    let that = this;
    for (const control of Object.keys(that.newContactForm.controls)) {
      that.newContactForm.controls[control].markAsUntouched();
    }

    that.newContactForm.reset();
    that.validRelationShip = false;
    model.close("Accept click");
    that.data = null;
  }

  onSubmit(modal: any) {
    let that = this;
    that.submitted = true;

    if (this.studentId != 0) {
      if (!that.isValidForm()) {
        that._appcomponent.toggeleLoader();
        //Add
        if (that.data == null || that.data?.Id == 0) {
          that._clientStudentService
            .addStudentContact(that.getAddContactObject())
            .subscribe(
              (Response: any) => {
                let newContact = that.getAddContactObject();
                newContact.Id = Response.Id;
                that._toastrService.success(
                  "The contact account was added successfully.",
                  "",
                );
                that.close(modal);
                that.Added.emit({ data: newContact, mode: "Add" });
                that._appcomponent.toggeleLoader();
              },
              error => {
                that.errorHandler(
                  error,
                  "The contact was not added due to technical issue.",
                );
              },
            );
        }
        //edit
        else {
          that._clientStudentService
            .editStudentContact(that.getAddContactObject())
            .subscribe(
              (Response: any) => {
                let newContact = that.getAddContactObject();
                that._toastrService.success(
                  "The contact was updated successfully.",
                  "",
                );
                that.close(modal);
                that.Added.emit({ data: newContact, mode: "edit" });
                that._appcomponent.toggeleLoader();
              },
              error => {
                that.errorHandler(
                  error,
                  "The contact was not edited due to technical issue.",
                );
              },
            );
        }
      }
    } else if (!that.isValidForm()) {
      that.Added.emit({ data: that.getAddContactObject(), mode: "Add" });
      that.close(modal);
    }
  }

  getAddContactObject() {
    let that = this;
    let data = new StudentContactModel();
    data.Id = that.data && that.data.Id != 0 ? that.data.Id : 0;
    data.FirstName = that.newContactForm.value.FirstName;
    data.LastName = that.newContactForm.value.LastName;
    data.Email = that.newContactForm.value.Email;

    data.PhoneNumber = that.newContactForm.value.PhoneNumber.replace(/\D/g, "");
    data.PhoneExtention = that.newContactForm.value.ContactPhoneExtention;
    data.RelationshipToEntity = that.newContactForm.value.relationshipName;
    data.isClientPortal = true;
    data.CreatedByAccountId = that._currentUser.id;
    data.Note = that.newContactForm.value.ContactNote;
    data.ParentId = that.studentId;

    return data;
  }

  closeConfiramtion() {
    this.guardianexist = false;
  }
  errorHandler(error: any, message: string) {
    let that = this;
    if (error.status == 401) {
      that._toastrService.error("UnAuthorized.", "");
      window.location.href = "/pages/authentication/login";
      that._appcomponent.toggeleLoader();
    } else if (error.status == 300) {
      that._toastrService.error(error.error.Message, "");
      that._appcomponent.toggeleLoader();
    } else {
      that._toastrService.error(message, "");
      that._appcomponent.toggeleLoader();
    }
  }
}
