<div
  *ngIf="Config.Filters[0].filterVisability"
  [formGroup]="parentForm"
>
  <!-- Basic Select with Search -->
  <label> Guardian App User </label>

  <ng-select
    [appendTo]="'body'"
    [(ngModel)]="Config.Filters[0].filterValue"
    [clearable]="Config.Filters[0].filterClear"
    [disabled]="Config.Filters[0].filterDisabled"
    [multiple]="Config.filterMultiple"
    [placeholder]="Config.Filters[0].filterPlaceholder"
    [formControlName]="'guardianName'"
    (change)="gurdianSelected()"
    (click)="gurdianSelected()"
  >
    <ng-option
      *ngFor="let item of Config.Filters[0].filterValues"
      [value]="item.id"
      [label]="item.name"
      >{{ item.name }} | {{ item.MobileNumber }}</ng-option
    >
  </ng-select>

  <div
    *ngIf="validGuardian"
    class="invalid-feedback"
    style="font-size: 10px !important"
    [ngClass]="{ 'd-block': validGuardian }"
  >
    <div *ngIf="validGuardian">
      This field is required, please select a value.
    </div>
  </div>
  <!--/ Basic Select with Search -->
</div>
<div class="col-12 separate"></div>
