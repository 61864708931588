<ng-template
  #AddNewPlace
  let-modal
>
  <div class="modal-header">
    <h5
      class="modal-title"
      id="myModalLabel160"
    >
      {{ PageTitle }}
    </h5>
    <button
      type="button"
      class="close"
      (click)="close(modal)"
      aria-label="Close"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div
    class="modal-body"
    tabindex="0"
    style="overflow-y: hidden; overflow-x: hidden; padding: 14px"
    ngbAutofocus
  >
    <div class="row">
      <div class="col-12 separate"></div>
      <div class="col-12">
        <form
          id="address-form"
          action=""
          method="get"
          autocomplete="off"
          [formGroup]="newPlaceform"
          (ngSubmit)="onSubmit(modal)"
        >
          <div class="row">
            <div class="col-lg-12">
              <ng-select
                appendTo="body"
                [(ngModel)]="Config.Filters[0].filterValue"
                [clearable]="Config.Filters[0].filterClear"
                [disabled]="PermessionValue"
                [multiple]="Config.filterMultiple"
                [placeholder]="Config.Filters[0].filterPlaceholder"
                formControlName="place"
                (change)="onChangeSelector()"
              >
                <ng-option
                  *ngFor="let item of Config.Filters[0].filterValues"
                  [value]="item.id"
                >
                  {{ item.name }}
                </ng-option>
              </ng-select>
              <div
                *ngIf="
                  newPlaceform.get('place').touched && newPlaceform.invalid
                "
                class="invalid-feedback"
                style="font-size: 10px !important"
                [ngClass]="{
                  'd-block':
                    newPlaceform.get('place').touched && newPlaceform.invalid
                }"
              >
                <div
                  *ngIf="
                    newPlaceform.get('place').touched && newPlaceform.invalid
                  "
                >
                  This field is required, please select a value.
                </div>
              </div>
            </div>
            <div
              class="col-lg-12 mt-2"
              id="place_schedule_body"
              *ngIf="placeResuls != null && placeResuls.length > 0"
            >
              <div class="col-lg-12">
                <div class="mb-4">
                  <h5 class="text-black">
                    <strong>Which calendar should be used?</strong>
                  </h5>
                  <p class="text-muted">
                    The place you want to add has multiple calendars. Select one
                    to use.
                  </p>
                </div>
              </div>
              <div class="col-md-9 mb-1">
                <span
                  class="text-danger"
                  style="font-size: 13px"
                  *ngIf="calenders_validation"
                  >Select a calender to save place</span
                >
              </div>
              <div class="place-schedule-container">
                <div class="col-lg-12">
                  <div
                    class="card cui-place-item"
                    *ngFor="let item of placeResuls"
                  >
                    <div class="card-header border-card-header">
                      <h5 class="text-blacks">
                        <strong>{{ item.Name }}</strong
                        ><br />
                        <small
                          class="text-muted"
                          *ngIf="item.NickName != ''"
                          >({{ item.NickName }})</small
                        >
                      </h5>
                    </div>
                    <div class="card-body">
                      <dl class="row">
                        <dt class="col-xl-4">Active Dates</dt>
                        <dd class="col-xl-8">
                          {{
                            getTimeValue(item.EffectiveFromDate, "MM/DD/YYYY")
                          }}
                          -
                          {{
                            getTimeValue(item.EffectiveUntilDate, "MM/DD/YYYY")
                          }}
                        </dd>
                        <dt class="col-xl-4">Grades</dt>
                        <dd class="col-xl-8">
                          <h5>
                            <span
                              class="badge badge-primary"
                              style="margin: 2px"
                              *ngFor="let grade of item.SchoolGrades"
                            >
                              {{ grade.Name }}
                            </span>
                          </h5>
                        </dd>
                      </dl>
                      <hr />
                      <dl class="row">
                        <dd class="col-lg-4 cui-crypto-list-currency">
                          <span>Early Start</span>
                          {{ getTimeValue(item.EarlyStartTime, "hh:mm A") }}
                        </dd>
                        <dd class="col-lg-4 cui-crypto-list-currency">
                          <span>Default Start</span>
                          {{ getTimeValue(item.DefaultStartTime, "hh:mm A") }}
                        </dd>
                        <dd class="col-lg-4 cui-crypto-list-currency">
                          <span>Late Start</span>
                          {{ getTimeValue(item.LateStartTime, "hh:mm A") }}
                        </dd>
                        <dd class="col-lg-4 cui-crypto-list-currency">
                          <span>Early Return</span>
                          {{ getTimeValue(item.EarlyReturnTime, "hh:mm A") }}
                        </dd>
                        <dd class="col-lg-4 cui-crypto-list-currency">
                          <span>Default Return</span>
                          {{ getTimeValue(item.DefaultReturnTime, "hh:mm A") }}
                        </dd>
                        <dd class="col-lg-4 cui-crypto-list-currency">
                          <span>Late Return</span>
                          {{ getTimeValue(item.LateReturnTime, "hh:mm A") }}
                        </dd>
                      </dl>
                      <hr />
                      <a
                        class="{{ item.Class }}"
                        href="javascript:void(0)"
                        (click)="selectPlaceCalendar(item)"
                        style="cursor: default"
                        [hidden]="disabeld"
                      >
                        {{ item.ButtonText }}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
    <span
      class="hint"
      style="text-transform: none"
      >If you can’t find the place that you are looking for contact the team at
      care@goadroit.com</span
    >
  </div>
  <div class="modal-footer about-adroit-footer">
    <button
      type="button"
      (click)="close(modal)"
      rippleeffect=""
      class="btn btn-close waves-effect waves-float waves-light"
      style="border-color: #1877f2; margin-bottom: 20px; color: #1877f2"
    >
      Close
    </button>
    <button
      type="submit"
      class="btn btn-info"
      (click)="onSubmit(modal)"
      style="margin-bottom: 20px; margin-right: 20px"
      [hidden]="disabeld"
      rippleEffect
    >
      Save
    </button>
  </div>
  <kendo-dialog
    title=""
    *ngIf="attentionInfo"
    (close)="closeAttentionDialog(modal)"
    [minWidth]="250"
    [width]="400"
  >
    <p style="text-align: center">The selected place already added.</p>
    <kendo-dialog-titlebar>
      <div style="font-size: 18px; line-height: 1.3em; font-weight: 500">
        <span
          class="k-icon k-i-info-circle mr-1"
          style="color: #eea236; font-size: 26px; margin-top: -3px"
        ></span
        >Attention
      </div>
    </kendo-dialog-titlebar>
    <kendo-dialog-actions>
      <p style="text-align: center">
        <button
          type="button"
          (click)="closeAttentionDialog(modal)"
          rippleeffect=""
          class="btn btn-info waves-effect waves-float waves-light"
        >
          Ok, got it.
        </button>
      </p>
    </kendo-dialog-actions>
  </kendo-dialog>
</ng-template>
