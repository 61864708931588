import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AppComponent } from "app/app.component";
import { AuthenticationService } from "app/auth/service";
import { IFiltersConfig } from "app/controls/component/filters/interfaces/controls/IFiltersConfig";
import { FiltersTypes } from "app/enums/controls/filters/FiltersTypes";
import { RideBlueprintSheetService } from "app/main/RideBlueprints/service/ride-blueprint-sheet.service";
import { HelpersService } from "app/main/SharedServices/helpers.service";
import { ToastrService } from "ngx-toastr";
import { LocationModel } from "app/main/HistoricalRides/model/location.model";
import { StudentPlaceModel } from "app/main/NoShowReports/model/student-place.model";
import { ContactModel } from "app/main/NoShowReports/model/contact.model";
import { NoShowReportService } from "app/main/NoShowReports/services/no-show-report.services";
import { StudentServiceDetailsModel } from "app/main/NoShowReports/model/student-service-details.model";
import { StudentTransportationProfileModel } from "app/main/NoShowReports/model/student-transportation-profile.model";
import { PlacesService } from "../services/Places.services";
import { PlaceFiliter } from "../model/Place-filiter.model";
import { PlaceModel } from "../model/place.model";

@Component({
  selector: "app-places",
  templateUrl: "./Places.component.html",
  styleUrls: ["./Places.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class PlacesComponent implements OnInit {
  gridView: PlaceModel[] = [];
  public opendSearch: boolean;
  public opendProfile: boolean;
  public fullName: string = "";
  public validation = "";
  public noShowcolumn: boolean = false;
  public lateCancelcolumn: boolean = false;
  public earlyCancelcolumn: boolean = false;
  public status: string;
  public studentAdress: LocationModel[];
  public placesAttended: StudentPlaceModel;
  public EmergencyContacts: ContactModel[];
  public studentInfo: StudentTransportationProfileModel;
  public serviceDetails: StudentServiceDetailsModel;
  public Equipment: any[];
  public skip = 0;
  public innepagesize = 3;
  constructor(
    private modalService: NgbModal,
    private _appComponent: AppComponent,
    private _rideBlueprintSheetService: RideBlueprintSheetService,
    private _sanitized: DomSanitizer,
    private _helperService: HelpersService,
    private _authenticationService: AuthenticationService,
    private _noShowReportService: NoShowReportService,
    private _toastrService: ToastrService,
    private _placesService: PlacesService,
  ) {
    this._appComponent._mainpageTitle = "Places";
  }

  ngOnInit(): void {
    this.pagesize = 25;
    this.gridIndex = 25;
    this.getGridData();
  }

  FiltersConfig: IFiltersConfig = {
    isFiltersEnable: false,
    Filters: [
      {
        filterType: FiltersTypes.searchByText,
        filterPlaceholder: "Search by Name, Address",
        filterClear: true,
        filterVisability: true,
        filtersize: "4",
      },
      {
        filterType: FiltersTypes.state,
        filterPlaceholder: "Filter by State",
        filterClear: true,
        filterVisability: true,
        filtersize: "4",
      },
      {
        filterType: FiltersTypes.Types,
        filterPlaceholder: "Filter By Types",
        filterVisability: true,
        filterClear: true,
        filtersize: "4",
      },
    ],
  };
  public gridData: any[] = [];
  public pagesize;
  public gridIndex;
  getGridData(filterModel?: PlaceFiliter) {
    let that = this;

    if (filterModel == null || filterModel == undefined) {
      filterModel = new PlaceFiliter();
    }

    filterModel.searchTerm = that.FiltersConfig.Filters.filter(
      x => x.filterType == FiltersTypes.searchByText,
    )[0].filterValue
      ? that.FiltersConfig.Filters.filter(
          x => x.filterType == FiltersTypes.searchByText,
        )[0].filterValue
      : "";

    filterModel.type = that.FiltersConfig.Filters.filter(
      x => x.filterType == FiltersTypes.Types,
    )[0].filterValue
      ? that.FiltersConfig.Filters.filter(
          x => x.filterType == FiltersTypes.Types,
        )[0].filterValue
      : "";

    filterModel.state = that.FiltersConfig.Filters.filter(
      x => x.filterType == FiltersTypes.state,
    )[0].filterValue
      ? that.FiltersConfig.Filters.filter(
          x => x.filterType == FiltersTypes.state,
        )[0].filterValue
      : "";

    this._placesService.getPlaces(filterModel).subscribe(
      Response => {
        this._appComponent.toggeleLoader();
        that.gridView = Response.Places;
      },
      error => {
        if (error.status == 401) {
          this._toastrService.error("UnAuthorized.", "");
          window.location.href = "/pages/authentication/login";
        } else {
          this._toastrService.error(error.error.Message, "");
        }
      },
    );
    this._appComponent.toggeleLoader();
  }

  public viewMore(): void {
    this.pagesize = parseInt(this.pagesize) + parseInt(this.gridIndex);
  }
  public changeGridIndex(): void {
    this.pagesize = this.gridIndex;
  }
}
