<div class="row section-div">
    <div class="col-lg-12">
        <strong id="contacts_block_title">Emergency Contact(s)</strong>
    </div>
    <div>
        <kendo-grid #mainGrid
                    [data]="gridData"
                    [pageSize]="state.take"
                    [skip]="state.skip"
                    [sort]="state.sort"
                    [filter]="state.filter"
                    [pageable]="false">
            <ng-template kendoGridNoRecordsTemplate>
                No contacts have been added yet.
            </ng-template>
            <ng-template kendoGridToolbarTemplate
                         NgClass="p-0">
            </ng-template>
            <kendo-grid-spacer></kendo-grid-spacer>

            <kendo-grid-column field="Name"
                               title="Name"
                               [sortable]="false">
                <ng-template kendoGridCellTemplate
                             let-dataItem
                             let-rowIndex="rowIndex">
                    <a class="btn btn-link pl-0 pr-0"
                       (click)="modalOpenMD(dataItem, rowIndex)">
                        {{ dataItem.FirstName + " " + dataItem.LastName }}
                    </a>
                </ng-template>
            </kendo-grid-column>

            <kendo-grid-column field="Relationship"
                               title="Relationship"
                               [sortable]="false">
                <ng-template kendoGridCellTemplate
                             let-dataItem
                             let-rowIndex="rowIndex">
                    {{ dataItem.RelationshipToEntity }}
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="ReachAt"
                               title="Reach At"
                               [sortable]="false">
                <ng-template kendoGridCellTemplate
                             let-dataItem
                             let-rowIndex="rowIndex">
                    <span>
                        {{ getMobileNumber(dataItem.PhoneNumber) }}

                        <span *ngIf=" dataItem.PhoneExtention != 0 &&  dataItem.PhoneExtention != '' && dataItem.PhoneExtention != null ">

                            ext.  {{dataItem.PhoneExtention}}

                        </span>

                        <br />
                        {{ dataItem.Email }}
                    </span>
                </ng-template>
            </kendo-grid-column>

            <kendo-grid-column [sortable]="false"
                               title="Action"
                               [width]="90">
                <ng-template kendoGridCellTemplate
                             let-dataItem
                             let-rowIndex="rowIndex">
                    <div class="d-flex align-items-center">
                        <div ngbDropdown
                             [placement]="'left-top'"
                             container="body"
                             class="m-auto">
                            <a ngbDropdownToggle
                               href="javascript:void(0);"
                               class="hide-arrow"
                               id="dropdownBrowserState"
                               data-toggle="dropdown"
                               aria-haspopup="true"
                               aria-expanded="false">
                                <i data-feather="more-vertical"
                                   class="text-primary cursor-pointer mr-50"></i>
                            </a>
                            <div ngbDropdownMenu
                                 class="dropdown-menu-right"
                                 aria-labelledby="dropdownBrowserState"
                                 disabled>
                                <a href="javascript:void(0)"
                                   (click)="modalOpenMD(dataItem, rowIndex)"
                                   ngbDropdownItem
                                   class="d-flex align-items-center">
                                    Edit
                                </a>
                                <div class="dropdown-divider"
                                     *ngIf="!disabeld"></div>
                                <a href="javascript:void(0)"
                                   (click)="deleteContactStudent(dataItem, rowIndex)"
                                   ngbDropdownItem
                                   class="d-flex align-items-center"
                                   *ngIf="!disabeld">
                                    Delete
                                </a>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </kendo-grid-column>
            <ng-template kendoPagerTemplate
                         *ngIf="grideView.length != 0">
                <kendo-grid-spacer></kendo-grid-spacer>
                <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
                <kendo-pager-numeric-buttons [buttonCount]="3"></kendo-pager-numeric-buttons>
                <kendo-pager-next-buttons></kendo-pager-next-buttons>
                <kendo-pager-info></kendo-pager-info>
            </ng-template>
        </kendo-grid>
    </div>
    <div class="col-lg-3">
        <button type="button"
                rippleeffect=""
                class="btn btn-close waves-effect waves-float waves-light mt-1"
                style="border-color: #1877f2; margin-bottom: 20px; color: #1877f2"
                (click)="modalOpenMD()"
                [hidden]="disabeld">
            Add Contact
        </button>
    </div>
</div>

<kendo-dialog title=""
              *ngIf="openedDelete"
              (close)="close('deleted')"
              [minWidth]="250"
              [width]="400">
    <p style="text-align: center">
        Are you sure you want to delete the contact information of
        {{ EmergencyContactName }}?
    </p>
    <kendo-dialog-titlebar>
        <div style="font-size: 18px; line-height: 1.3em; font-weight: 500">
            <span class="k-icon k-i-info-circle mr-1"
                  style="color: #eea236; font-size: 26px; margin-top: -3px"></span>Delete Confirmation
        </div>
    </kendo-dialog-titlebar>
    <kendo-dialog-actions>
        <p style="text-align: center">
            <button type="button"
                    (click)="close('deleted')"
                    rippleeffect=""
                    class="btn btn-default waves-effect waves-float waves-light"
                    style="
          background-color: #acb7bf;
          border-color: #acb7bf;
          color: white;
          margin-right: 10px;
        ">
                No
            </button>
            <button type="button"
                    (click)="confirmDeleteContactStudent()"
                    rippleeffect=""
                    class="btn btn-danger waves-effect waves-float waves-light">
                Yes
            </button>
        </p>
    </kendo-dialog-actions>
</kendo-dialog>

<app-student-add-new-contact #studentAddNewContactComponent
                             (Added)="reloadData($event)"
                             [disable]="disabeld"></app-student-add-new-contact>
