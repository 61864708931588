import {
  Component,
  Input,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { AuthenticationService } from "app/auth/service";
import { ToastrService } from "ngx-toastr";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { User } from "app/auth/models";
import { AppComponent } from "app/app.component";
import { HelpersService } from "app/main/SharedServices/helpers.service";
import { State } from "@progress/kendo-data-query";
import { StudentAddNewContactComponent } from "./StudentAddNewContact/student-add-new-contact.component";
import { ClientStudentsService } from "../services/client-students.service";
@Component({
  selector: "app-student-emergency-contacts-section",
  templateUrl: "./student-emergency-contacts-section.component.html",
  styleUrls: ["./student-emergency-contacts-section.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class StudentEmergencyContactsSectionComponent implements OnInit {
  public _currentUser: User;
  opened = false;
  public rowIndex: number = -1;
  public selectedData: any = {};
  public openedDelete: boolean = false;
  EmergencyContactName: string;
  @Input("studentId") studentId: number;
  @ViewChild("studentAddNewContactComponent")
  addNewContactComponent: StudentAddNewContactComponent;
  grideView: any = [];
  @Input() gridData: any = [];
  @Input("disable") disable: boolean = true;
  public disabeld: boolean = false;
  state: State = {
    skip: 0,
    take: 25,
    group: [],
    filter: { filters: [], logic: "or" },
    sort: [
      {
        field: "name",
        dir: "asc",
      },
    ],
  };

  public pageSize = 5;
  public skip = 0;
  /**
   * Constructor
   * * @param {AuthenticationService} _authenticationService
   * * @param {HelpersService} _helpersService
   * * @param {ClientGuardiansService} _clientGurdiansService
   * * @param {NgbModal} _modalService
   * * @param {ToastrService} _toastrService
   * * @param {FormBuilder} _formBuilder
   * * @param {AppComponent} _appcomponent
   */
  constructor(
    private _formBuilder: FormBuilder,
    private _authenticationService: AuthenticationService,
    private _toastrService: ToastrService,
    private _modalService: NgbModal,
    private _helperService: HelpersService,
    private _appcomponent: AppComponent,
    private _clientStudentService: ClientStudentsService,
  ) {
    this._authenticationService.currentUser.subscribe(
      x => (this._currentUser = x),
    );
  }

  ngOnInit(): void {
    this.disabeld = !this.disable;
    let that = this;
    if (that.studentId != 0) {
      that.loadContacts();
    }
  }

  reloadData(result: any) {
    let that = this;
    if (that.studentId != 0) {
      that.loadContacts();
    } else {
      if (that.rowIndex != -1) {
        if (
          that.gridData[that.rowIndex] == undefined ||
          that.gridData[that.rowIndex] == null
        ) {
          that.gridData.push(JSON.parse(JSON.stringify(result.data)));
        } else {
          that.gridData[that.rowIndex] = JSON.parse(
            JSON.stringify(result.data),
          );
        }
      } else {
        that.gridData.push(JSON.parse(JSON.stringify(result.data)));
      }
    }
  }

  modalOpenMD(data: any = null, rowIndex?: number) {
    let that = this;
    that.opened = true;
    that.addNewContactComponent.data = data;
    if (data != null) {
      that.addNewContactComponent.data = that.gridData[rowIndex];
    }
    that.rowIndex = rowIndex == null ? that.gridData.length : rowIndex;
    that.addNewContactComponent.open();
  }

  getMobileNumber(mobileNumber: string) {
    return this._helperService.phoneNumber(mobileNumber);
  }

  deleteContactStudent(data: any, rowIndex: number) {
    let that = this;
    if (!data.Id) {
      that.gridData.splice(rowIndex, 1);
    } else {
      //unlink by api on edit Student
      that.openedDelete = true;
      that.selectedData.Data = data;
      that.EmergencyContactName = data.FirstName + " " + data.LastName;
      that.selectedData.RowIndex = rowIndex;
    }
  }

  close(status: string): void {
    let that = this;
    if (status == "deleted") {
      that.openedDelete = false;
      that.selectedData = {};
      that.EmergencyContactName = "";
    }
  }

  confirmDeleteContactStudent() {
    let that = this;
    that.openedDelete = false;
    that._appcomponent.toggeleLoader();
    that._clientStudentService
      .deleteStudentContact(that.selectedData.Data.Id)
      .subscribe(
        (data: any) => {
          that.gridData.splice(that.selectedData.RowIndex, 1);
          that._toastrService.success(
            "The contact was deleted successfully.",
            "",
          );
          that._appcomponent.toggeleLoader();
        },
        error => {
          if (error.status == 401) {
            that._toastrService.error("UnAuthorized", "");
          } else if (error.status == 403) {
            that._toastrService.error(error.error.Message, "");
          } else if (error.status == 500) {
            that._toastrService.error(
              "The contact was not deleted due to a technical issue.",
              "",
            );
          } else {
            that._toastrService.error("Server internal error", "");
          }
          that._appcomponent.toggeleLoader();
        },
      );
  }

  /*Emergency Contact(s)*/
  loadContacts() {
    let that = this;
    that._appcomponent.toggeleLoader();
    that._clientStudentService.getContacts(that.studentId).subscribe(
      (Response: any) => {
        that.gridData = Response;
        that._appcomponent.toggeleLoader();
      },
      error => {
        if (error.status == 401) {
          that._toastrService.error("UnAuthorized.", "");
          that._appcomponent.toggeleLoader();
        } else {
          that._toastrService.error(
            "The contacts was not loaded due to a technical issue.",
            "",
          );
          that._appcomponent.toggeleLoader();
        }
      },
    );
  }
}
