<div class="pageHeader">
  <strong class="PageHeadline">{{ PageTitle }}</strong>
</div>
<form
  [formGroup]="AddEditStudentData"
  (keydown.enter)="$event.preventDefault()"
  class="districtsContainer"
>
  <div class="toolbar_container"></div>
  <div class="row">
    <div
      class="form_headline_container col-lg-6"
      style="padding-left: 23px"
    >
      <span class="form_headline">Basic Details</span>
    </div>
    <div class="form_headline_container col-lg-6">
      <span class="form_headline">Health Details</span>
    </div>
  </div>
  <div class="mainDiv row">
    <div class="col-lg-6 custom_district_form">
      <div class="row">
        <div class="col-lg-12 fieldsDiv">
          <app-personal-photo
            #PersonalPhotoComponent
            [Id]="studentId"
            [Title]="title"
            [EntityType]="entityType"
            [Folder]="folder"
            [Type]="type"
            [disable]="disabled"
          ></app-personal-photo>
        </div>

        <div class="col-lg-6 fieldsDiv">
          <label
            for="FirstName"
            class="form_field_label"
            >First Name</label
          >
          <app-required-field
            type="text"
            formControlName="FirstName"
            placeholder="First Name"
            [disabled]="PermessionValue"
          ></app-required-field>
        </div>
        <div class="col-lg-6 fieldsDiv">
          <label
            for="Last Name"
            class="form_field_label"
            >Last Name</label
          >
          <app-required-field
            type="text"
            formControlName="LastName"
            placeholder="Last Name"
            [disabled]="PermessionValue"
          ></app-required-field>
        </div>
        <div class="col-lg-6 fieldsDiv">
          <label
            for="Middle Name"
            class="form_field_label"
            >Middle Name</label
          >
          <app-required-field
            type="text"
            formControlName="MiddleName"
            placeholder="(optional)"
            [disabled]="PermessionValue"
          ></app-required-field>
        </div>
        <div class="col-lg-6 fieldsDiv">
          <label
            for="Nick Name"
            class="form_field_label"
            >Nick Name</label
          >
          <app-required-field
            type="text"
            formControlName="NickName"
            placeholder="(optional)"
            [disabled]="PermessionValue"
          ></app-required-field>
        </div>
        <div class="col-lg-6 fieldsDiv">
          <app-single-selector
            [data]="Languages"
            placeholder="Select a language."
            bindLabel="Name"
            bindValue="Id"
            name="Home Language"
            [clearable]="true"
            formControlName="Language"
            [disable]="disabled"
          ></app-single-selector>
        </div>
        <div class="col-lg-6 fieldsDiv">
          <app-single-selector
            [data]="grades"
            placeholder="Select a school grade."
            bindLabel="Name"
            bindValue="Id"
            name="School Grade"
            [clearable]="true"
            formControlName="SchoolGrade"
            [disable]="disabled"
          ></app-single-selector>
        </div>
        <div class="col-lg-5 fieldsDiv">
          <app-date-time-picker
            name="Date Of Birth"
            placeholder="00/00/0000"
            formControlName="DateOfBirth"
            [initialValue]="initialDate"
            (ngModelChange)="ChangeAgeValue()"
            [disable]="disabled"
          ></app-date-time-picker>
        </div>
        <div
          class="col-lg-1 fieldsDiv"
          style="padding-left: 0px"
        >
          <label
            for="Age"
            class="form_field_label"
            >Age</label
          >
          <app-optional-field
            type="text"
            formControlName="Age"
            placeholder="0"
            disabled="true"
          ></app-optional-field>
        </div>
        <div class="col-lg-6 fieldsDiv">
          <app-single-selector
            [data]="genders"
            placeholder="Select a Gender."
            bindLabel="name"
            bindValue="id"
            name="Gender"
            [clearable]="true"
            formControlName="Gender"
            [disable]="disabled"
          ></app-single-selector>
        </div>
      </div>
    </div>

    <div class="col-lg-6 custom_district_form">
      <div class="row">
        <div class="col-lg-6 fieldsDiv">
          <label
            for="Weight"
            class="form_field_label"
            >Weight (Pound)</label
          >
          <app-required-field
            type="number"
            formControlName="Weight"
            placeholder="Weight (0.00)"
            [disabled]="PermessionValue"
          ></app-required-field>
        </div>
        <div class="col-lg-6 fieldsDiv">
          <label
            for="Height"
            class="form_field_label"
            >Height (Inch)</label
          >
          <app-required-field
            type="number"
            formControlName="Height"
            placeholder="Height (0.00)"
            [disabled]="PermessionValue"
          ></app-required-field>
        </div>

        <div class="col-lg-6 fieldsDiv">
          <app-multi-selector
            [data]="populationTypes"
            bindLabel="Name"
            bindValue="Id"
            placeholder="Select a population type"
            name="Population Type"
            [clearable]="false"
            formControlName="PopulationType"
            [multiple]="true"
            [disable]="disabled"
          ></app-multi-selector>
        </div>
        <div class="col-lg-6 fieldsDiv">
          <app-multi-selector
            [data]="specialEdCategories"
            bindLabel="Name"
            bindValue="Id"
            placeholder="select a category. (optional)"
            name="Special-Ed Category"
            [clearable]="false"
            formControlName="SpecialEdCategories"
            [multiple]="true"
            [disable]="disabled"
          ></app-multi-selector>
        </div>
        <div class="col-lg-12 fieldsDiv">
          <label
            for="HealthConcernsAndComments"
            class="form_field_label"
            >Health Concerns And Comments</label
          >
          <app-required-field-textarea
            formControlName="HealthConcernsAndComments"
            placeholder="Health Concerns And Comments."
            styl="height: 105px;"
            [disabledd]="PermessionValues"
          ></app-required-field-textarea>
        </div>
        <div class="col-lg-12 fieldsDiv">
          <label
            for="InternalNote"
            class="form_field_label"
            >Internal Note</label
          >
          <app-required-field-textarea
            formControlName="InternalNote"
            placeholder="Internal Note"
            [disabledd]="PermessionValues"
          ></app-required-field-textarea>
        </div>
      </div>
    </div>
  </div>
  <div class="main_sections_container">
    <div class="tabs_toolbar_container"></div>
    <kendo-tabstrip
      [tabPosition]="position"
      style="margin: 10px"
    >
      <kendo-tabstrip-tab
        title="Guardians/Contacts"
        style="font-weight: 500"
        [selected]="true"
      >
        <ng-template kendoTabContent>
          <!--Guardians-->
          <app-gurdians-student-section
            [gridData]="GuardianGridData"
            [studentId]="studentId"
            [disable]="disabled"
          ></app-gurdians-student-section>
          <!--Emergency Contact(s)-->
          <app-student-emergency-contacts-section
            [gridData]="ContactGridData"
            [studentId]="studentId"
            [disable]="disabled"
          ></app-student-emergency-contacts-section>
        </ng-template>
      </kendo-tabstrip-tab>
      <kendo-tabstrip-tab title="Addresses">
        <ng-template kendoTabContent>
          <!--Home Address(es)-->
          <app-address-student-section
            [AddressGridData]="AddressGridData"
            [PlaceGridData]="PlaceGridData"
            [studentId]="studentId"
            [disable]="disabled"
          ></app-address-student-section>
        </ng-template>
      </kendo-tabstrip-tab>
      <kendo-tabstrip-tab title="Special Requirements">
        <ng-template kendoTabContent>
          <!--Special Requirements-->
          <app-student-special-requirements-section
            #RequirementsSectionComponent
            [TransportationData]="TransportationData"
            [studentId]="studentId"
            (Added)="reloadData($event)"
            [disable]="disabled"
          ></app-student-special-requirements-section>
        </ng-template>
      </kendo-tabstrip-tab>
      <kendo-tabstrip-tab title="Transportation  Schedule">
        <ng-template kendoTabContent>
          <!--Transportation Schedule-->
          <app-transportation-schedule-section
            [AddressGridData]="AddressGridData"
            [PlaceGridData]="PlaceGridData"
            [gridData]="ScheduleGridData"
            [studentId]="studentId"
            [disable]="disabled"
          ></app-transportation-schedule-section>
        </ng-template>
      </kendo-tabstrip-tab>
      <kendo-tabstrip-tab title="Service Cancellations">
        <ng-template kendoTabContent>
          <!--Service Cancellations-->
          <app-service-cancelation-section
            [upcomingGridData]="UpcomingServiceCancelationGridData"
            [recurentGridData]="RecurrentServiceCancelationGridData"
            [pastGridData]="PastServiceCancelationGridData"
            [studentId]="studentId"
            [disable]="disabled"
          ></app-service-cancelation-section>
        </ng-template>
      </kendo-tabstrip-tab>
      <kendo-tabstrip-tab title="Instructions">
        <ng-template kendoTabContent>
          <!--Instructions-->
          <app-instructions-section
            [gridData]="InstructionsGridData"
            [studentId]="studentId"
            [disable]="disabled"
          ></app-instructions-section>
        </ng-template>
      </kendo-tabstrip-tab>
      <kendo-tabstrip-tab title="Attachments">
        <ng-template kendoTabContent>
          <!--Attachments-->
          <app-attachment-section
            [gridData]="AttachmentGridData"
            [studentId]="studentId"
            [disable]="disabled"
          ></app-attachment-section>
        </ng-template>
      </kendo-tabstrip-tab>
    </kendo-tabstrip>
  </div>
  <div class="modal-footer about-adroit-footer">
    <button
      type="button"
      rippleeffect=""
      (click)="onClose()"
      class="btn btn-close waves-effect waves-float waves-light"
      style="border-color: #1877f2; margin-bottom: 20px; color: #1877f2"
    >
      Close
    </button>
    <button
      [hidden]="!disabled"
      type="submit"
      class="btn btn-info"
      style="margin-bottom: 20px; margin-right: 20px"
      rippleEffect
      (click)="onSubmit()"
    >
      {{saveButtonName}}
    </button>
  </div>
</form>
