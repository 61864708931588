import { Component, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import {
    DataBindingDirective,
    GridDataResult,
    PageSizeItem,
} from "@progress/kendo-angular-grid";
import { process, SortDescriptor, State } from "@progress/kendo-data-query";
import { SelectEvent } from "@progress/kendo-angular-layout";
import { DriverProfile } from "../model/driver-profile.model";
import { RideSheetModel } from "../model/ride-sheet.model";
import { RideSheetWaypointModel } from "../model/ride-sheet-waypoint.model";
import { RideMonitorRideWaypointModel } from "../model/ride-monitor-ride-waypoint.model";
import { FiltersTypes } from "app/enums/controls/filters/FiltersTypes";
import { IFiltersConfig } from "app/controls/component/filters/interfaces/controls/IFiltersConfig";
import { RideActivityLogModel } from "../model/ride-activity-log.model";
import { HistoricalRidesFilterModel } from "../model/historical-rides-filter.model";
import { RideBlueprintDataSheetModel } from "../model/ride-blueprint-datasheet.model";
import { ToastrService } from "ngx-toastr";
import moment from "moment";
import { RideMonitorModel } from "../model/ride-monitor.model";
import { AppComponent } from "../../../app.component";
import { ClassColour } from "../../Enum/statistics/class-colour";
import { HistoricalRidesServices } from "../services/historical-rides.services";
import {
    RideStatusTypeEnum,
    RideWaypointCargoTypeEnum,
} from "../../../enums/lookups/LookupEnum";

const { compile } = require("html-to-text"); //https://www.npmjs.com/package/html-to-text
const options = {};
const compiledConvert = compile(options); // options passed here
@Component({
    selector: "app-historicalRides",
    templateUrl: "./historical-rides.component.html",
    styleUrls: ["./historical-rides.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class HistoricalRidesComponent implements OnInit {
    None = RideStatusTypeEnum.None;
    InProgress = RideStatusTypeEnum.InProgress;
    Completed = RideStatusTypeEnum.Completed;
    NoShow = RideStatusTypeEnum.NoShow;
    CanceledDueToHoliday = RideStatusTypeEnum.CanceledDueToHoliday;
    CanceledInAdvance = RideStatusTypeEnum.CanceledInAdvance;
    CanceledLate = RideStatusTypeEnum.CanceledLate;
    CanceledDueToPandemic = RideStatusTypeEnum.CanceledDueToPandemic;
    Deleted = RideStatusTypeEnum.Deleted;
    Offered = RideStatusTypeEnum.Offered;
    Accepted = RideStatusTypeEnum.Accepted;
    Declined = RideStatusTypeEnum.Declined;

    PickedUp = RideWaypointCargoTypeEnum.PickedUp;
    DroppedOff = RideWaypointCargoTypeEnum.DroppedOff;
    NotDroppedOff = RideWaypointCargoTypeEnum.NotDroppedOff;
    SkipDropOff = RideWaypointCargoTypeEnum.SkipDropOff;
    Abscent = RideWaypointCargoTypeEnum.Abscent;

    contentHeader: object;
    isFiltersEnable = false;
    pageSize = 25;
    gridIndex = 25;
    disableLoadMore: boolean = false;
    basicSelectedOption: number = 0;
    opened = false;
    opendSearch = false;
    skip: number = 0;
    CancelTransportationopened = false;
    gridData: any[] = [];
    dataRides: RideMonitorModel[] = [];
    gridView: any;
    wayPotintId: number;
    driverProfile: DriverProfile;
    rideInfo: RideSheetModel;
    waypoints: RideSheetWaypointModel[];
    rideWaypoints: RideMonitorRideWaypointModel[];
    rideActivityLog: RideActivityLogModel[];
    bluePrint: RideBlueprintDataSheetModel;
    rideMonitorModel: RideMonitorModel;
    @ViewChild(DataBindingDirective) dataBinding: DataBindingDirective;
    @ViewChild("KendoGridHistorical") KendoGridHistorical: Component | undefined;
    public textValidation: string;
    state: State = {
        skip: 0,
        take: 25,
        group: [],
        filter: { filters: [], logic: "or" },
        sort: [
            {
                field: "ride",
                dir: "asc",
            },
        ],
    };
    pagesizes: PageSizeItem[] = [
        { text: "50", value: 50 },
        { text: "100", value: 100 },
        { text: "250", value: 250 },
        { text: "500", value: 500 },
        { text: "1000", value: 1000 },
        { text: "All", value: -1 },
    ];
    GridData: GridDataResult = { data: [], total: 0 };

    FiltersConfig: IFiltersConfig = {
        isFiltersEnable: true,
        Filters: [
            {
                filterType: FiltersTypes.fromDatePicker,
                filterPlaceholder: "From Date",
                filterClear: true,
                filterVisability: true,
            },
            {
                filterType: FiltersTypes.toDatePicker,
                filterPlaceholder: "To Date",
                filterClear: true,
                filterVisability: true,
            },
            {
                filterType: FiltersTypes.bluePrintNumber,
                filterPlaceholder: "Filter by Blueprint #",
                filterClear: true,
                filterVisability: true,
            },
            {
                filterType: FiltersTypes.rideTypes,
                filterPlaceholder: "Filter by Type",
                filterClear: true,
                filterVisability: true,
            },
            {
                filterType: FiltersTypes.DayPeriods,
                filterPlaceholder: "Filter by Start Period",
                filterClear: true,
                filterVisability: true,
            },
            {
                filterType: FiltersTypes.Statues,
                filterPlaceholder: "Filter by Status",
                filterClear: true,
                filterVisability: true,
            },
            {
                filterType: FiltersTypes.places,
                filterPlaceholder: "Filter by Place",
                filterClear: true,
                filterVisability: true,
            },
            {
                filterType: FiltersTypes.Passengers,
                filterPlaceholder: "Filter by Passengers",
                filterClear: true,
                filterVisability: true,
            },
        ],
    };
    public statistics: any[] = [
        {
            divClass: ClassColour.success,
            label: "Completed",
            value: 0,
            valueLabel: "rides",
        },
        {
            divClass: ClassColour.success,
            label: "No-show",
            value: 0,
            valueLabel: "rides",
        },
        {
            divClass: ClassColour.success,
            label: "Late Cancel",
            value: 0,
            valueLabel: "rides",
        },
        {
            divClass: ClassColour.light,
            label: "Early Cancel",
            value: 0,
            valueLabel: "rides",
        },
        {
            divClass: ClassColour.light,
            label: "Holiday",
            value: 0,
            valueLabel: "rides",
        },
        {
            divClass: ClassColour.danger,
            label: "3X No-show",
            value: 0,
            valueLabel: "rides",
        },
    ];
    constructor(
        private modalService: NgbModal,
        private _historicalridesservices: HistoricalRidesServices,
        private _toastrService: ToastrService,
        private _appComponent: AppComponent,
    ) {
        this._appComponent._mainpageTitle = "Reports";
    }

    ngOnInit() {
        let that = this;
        that.contentHeader = {
            headerTitle: "Home",
            actionButton: true,
            breadcrumb: {
                type: "",
                links: [
                    {
                        name: "Home",
                        isLink: true,
                        link: "/",
                    },
                    {
                        name: "Sample",
                        isLink: false,
                    },
                ],
            },
        };
        that.getPassengers();
        that.getPlaces();
        that.getRideBluePrint();
        that.gridView = that.gridData;
    }

    viewFilter(): void {
        if (this.isFiltersEnable) {
            this.isFiltersEnable = false;
        } else {
            this.isFiltersEnable = true;
        }
    }

    public sortChange(sort: SortDescriptor[]): void {
        let that = this;
        this.state.sort = sort;
        let filterModel: HistoricalRidesFilterModel =
            new HistoricalRidesFilterModel();
        console.log(sort[0].dir);
        if (sort[0].dir == "desc") {
            filterModel.sort_by = "desc";
        } else {
            filterModel.sort_by = "asc";
        }
        that.getRiderhisoricaldata(filterModel);
    }
    checkTheFilterModel(filterModel): HistoricalRidesFilterModel {
        if (filterModel == null || filterModel == undefined) {
            return new HistoricalRidesFilterModel();
        }
        return filterModel;
    }
    getRiderhisoricaldata(filterModel?: HistoricalRidesFilterModel) {
        let that = this;
        filterModel = that.checkTheFilterModel(filterModel);
        let a = that.FiltersConfig.Filters.filter(
            x => x.filterType == FiltersTypes.fromDatePicker,
        )[0].filterValue
            ? this.FiltersConfig.Filters.filter(
                x => x.filterType == FiltersTypes.fromDatePicker,
            )[0].filterValue
            : null;

        let b = that.FiltersConfig.Filters.filter(
            x => x.filterType == FiltersTypes.toDatePicker,
        )[0].filterValue
            ? this.FiltersConfig.Filters.filter(
                x => x.filterType == FiltersTypes.toDatePicker,
            )[0].filterValue
            : null;

        filterModel.fromServiceDate = that.FiltersConfig.Filters.filter(
            x => x.filterType == FiltersTypes.fromDatePicker,
        )[0].filterValue
            ? this.FiltersConfig.Filters.filter(
                x => x.filterType == FiltersTypes.fromDatePicker,
            )[0].filterValue.toLocaleDateString()
            : null;

        filterModel.toServiceDate = that.FiltersConfig.Filters.filter(
            x => x.filterType == FiltersTypes.toDatePicker,
        )[0].filterValue
            ? this.FiltersConfig.Filters.filter(
                x => x.filterType == FiltersTypes.toDatePicker,
            )[0].filterValue.toLocaleDateString()
            : null;

        filterModel.rideBlueprintId = that.FiltersConfig.Filters.filter(
            x => x.filterType == FiltersTypes.bluePrintNumber,
        )[0].filterValue
            ? this.FiltersConfig.Filters.filter(
                x => x.filterType == FiltersTypes.bluePrintNumber,
            )[0].filterValue
            : 0;

        filterModel.rideType = that.FiltersConfig.Filters.filter(
            x => x.filterType == FiltersTypes.rideTypes,
        )[0].filterValue
            ? this.FiltersConfig.Filters.filter(
                x => x.filterType == FiltersTypes.rideTypes,
            )[0].filterValue
            : "";

        filterModel.period = that.FiltersConfig.Filters.filter(
            x => x.filterType == FiltersTypes.DayPeriods,
        )[0].filterValue
            ? this.FiltersConfig.Filters.filter(
                x => x.filterType == FiltersTypes.DayPeriods,
            )[0].filterValue
            : "";

        filterModel.rideStatusId = that.FiltersConfig.Filters.filter(
            x => x.filterType == FiltersTypes.Statues,
        )[0].filterValue
            ? this.FiltersConfig.Filters.filter(
                x => x.filterType == FiltersTypes.Statues,
            )[0].filterValue
            : "";

        filterModel.placeId = that.FiltersConfig.Filters.filter(
            x => x.filterType == FiltersTypes.places,
        )[0].filterValue
            ? this.FiltersConfig.Filters.filter(
                x => x.filterType == FiltersTypes.places,
            )[0].filterValue
            : 0;

        filterModel.studentId = that.FiltersConfig.Filters.filter(
            x => x.filterType == FiltersTypes.Passengers,
        )[0].filterValue
            ? this.FiltersConfig.Filters.filter(
                x => x.filterType == FiltersTypes.Passengers,
            )[0].filterValue
            : 0;
        if (that.IsNullDates(filterModel)) {
            that.openSearchvalidation("Select dates.");
        } else if (filterModel.fromServiceDate == null) {
            that.openSearchvalidation("Select From Date");
        } else if (filterModel.toServiceDate == null) {
            that.openSearchvalidation("Select To Date");
        } else if (a > b) {
            that.openSearchvalidation("To date should be grater than from date.");
        } else {
            this.prepareHistoricaRidesWithDate(filterModel);
        }
    }
    IsNullDates(filterModel): boolean {
        return (
            filterModel.fromServiceDate == null && filterModel.toServiceDate == null
        );
    }
    viewMore(): void {
        let that = this;
        let filterModel: HistoricalRidesFilterModel =
            new HistoricalRidesFilterModel();
        that.gridIndex = 0;
        that.skip = 0;
        if (that.dataRides.length >= this.basicSelectedOption) {
            return;
        }
        if (that.dataRides.length > this.basicSelectedOption) {
            this.disableLoadMore = true;
        }
        if (that.dataRides.length + 10 >= this.basicSelectedOption) {
            that.skip = 0;
            filterModel.limit = this.basicSelectedOption;
            this.disableLoadMore = true;
        } else {
            filterModel.limit = that.dataRides.length + 10;
            that.skip = 0;
        }
        that.getRiderhisoricaldata(filterModel);
    }

    changeGridIndex(): Boolean {
        let that = this;
        if (that.gridIndex < that.dataRides.length) {
            let filterModel: HistoricalRidesFilterModel =
                new HistoricalRidesFilterModel();
            that.dataRides = [];
            that.skip = 0;
            filterModel.limit = that.gridIndex;
            that.getRiderhisoricaldata(filterModel);
            return false;
        }
        if (that.gridIndex > that.dataRides.length) {
            if (that.gridIndex > that.gridView.total) {
                that.gridIndex = that.gridView.total;
                let filterModel: HistoricalRidesFilterModel =
                    new HistoricalRidesFilterModel();
                that.dataRides = [];
                that.skip = 0;
                filterModel.limit = that.gridIndex;
                that.getRiderhisoricaldata(filterModel);
            }
            let filterModel: HistoricalRidesFilterModel =
                new HistoricalRidesFilterModel();
            that.dataRides = [];
            that.skip = 0;
            filterModel.limit = that.gridIndex;
            that.getRiderhisoricaldata(filterModel);
            return false;
        }
    }

    modalOpenLG(modalId, id, partner_id, type, name) {
        let that = this;
        if (type == "dreiverDetails") {
            this._historicalridesservices.getDriverProfile(id, partner_id).subscribe(
                Response => {
                    that._appComponent.toggeleLoader();
                    that.driverProfile = Response;
                    that.driverProfile.Name = name;
                    that.driverProfile.Id = id;
                    this.modalService.open(modalId, {
                        centered: true,
                        backdrop: "static",
                        size: "lg",
                    });
                },
                error => {
                    if (error.status == 401) {
                        this._toastrService.error("UnAuthorized.", "");
                        window.location.href = "/pages/authentication/login";
                    } else {
                        this._toastrService.error(error.error.Message, "");
                    }
                },
            );
            that._appComponent.toggeleLoader();
        } else if (type == "RideSummary") {
            this._historicalridesservices.getRideInfo(partner_id).subscribe(
                Response => {
                    that._appComponent.toggeleLoader();
                    that.rideInfo = Response;
                    that.waypoints = Response.Waypoints;
                    this.modalService.open(modalId, {
                        centered: true,
                        backdrop: "static",
                        size: "lg",
                    });
                },
                error => {
                    if (error.status == 401) {
                        this._toastrService.error("UnAuthorized.", "");
                        window.location.href = "/pages/authentication/login";
                    } else {
                        this._toastrService.error(error.error.Message, "");
                    }
                },
            );
            that._appComponent.toggeleLoader();
        } else if (type == "RideBlueprintSheet") {
            this._historicalridesservices
                .getRideBlueprintSheetData(partner_id)
                .subscribe(
                    Response => {
                        that._appComponent.toggeleLoader();
                        that.bluePrint = Response;
                        this.modalService.open(modalId, {
                            centered: true,
                            backdrop: "static",
                            size: "lg",
                        });
                    },
                    error => {
                        if (error.status == 401) {
                            this._toastrService.error("UnAuthorized.", "");
                            window.location.href = "/pages/authentication/login";
                        } else {
                            this._toastrService.error(error.error.Message, "");
                        }
                    },
                );
            that._appComponent.toggeleLoader();
        }
    }

    modalOpenRideMonitorModel(modelId, ride: RideMonitorModel) {
        let that = this;
        that.rideMonitorModel = ride;
        this._historicalridesservices.getRideWaypoints(ride.RideUuid).subscribe(
            Response => {
                that._appComponent.toggeleLoader();
                that.rideWaypoints = Response;
                this.modalService.open(modelId, {
                    centered: true,
                    backdrop: "static",
                    size: "xl",
                });
            },
            error => {
                if (error.status == 401) {
                    this._toastrService.error("UnAuthorized.", "");
                    window.location.href = "/pages/authentication/login";
                } else {
                    this._toastrService.error(error.error.Message, "");
                }
            },
        );
        that._appComponent.toggeleLoader();
    }

    close(status: string): void {
        console.log(`Dialog result: ${status}`);
        this.opened = false;
        this.opendSearch = false;
    }

    openRideActivityLog(RideUuid, waypointId, index): void {
        let that = this;
        that.wayPotintId = index + 1;

        this._historicalridesservices
            .getRideActivityLog(RideUuid, waypointId)
            .subscribe(
                Response => {
                    that.rideActivityLog = Response;

                    this.opened = true;
                },
                error => {
                    if (error.status == 401) {
                        this._toastrService.error("UnAuthorized.", "");
                        window.location.href = "/pages/authentication/login";
                    } else {
                        this._toastrService.error(error.error.Message, "");
                    }
                },
            );
    }

    onTabSelect(e: SelectEvent): void {
        console.log(e);
    }

    getPassengers() {
        let passenger = this.FiltersConfig.Filters.filter(
            x => x.filterType == FiltersTypes.Passengers,
        )[0];
        this._historicalridesservices.getPassengers().subscribe(
            Response => {
                let passengerfilterres = [];
                Response.forEach(function (value) {
                    let data = {
                        id: value.StudentId,
                        name: value.FirstName + " " + value.LastName,
                    };
                    passengerfilterres.push(data);
                });
                passenger.filterValues = passengerfilterres;
                passenger.filterDisabled = false;
            },
            error => {
                if (error.status == 401) {
                    this._toastrService.error("UnAuthorized.", "");
                    window.location.href = "/pages/authentication/login";
                } else {
                    this._toastrService.error(error.error.Message, "");
                }
            },
        );
    }

    getPlaces() {
        let places = this.FiltersConfig.Filters.filter(
            x => x.filterType == FiltersTypes.places,
        )[0];
        this._historicalridesservices.getPlaces().subscribe(
            Response => {
                let placesfilterres = [];
                Response.Places.forEach(function (value) {
                    let data = {
                        id: value.Id,
                        name: value.Name,
                    };
                    placesfilterres.push(data);
                });
                places.filterValues = placesfilterres;
                places.filterDisabled = false;
            },
            error => {
                if (error.status == 401) {
                    this._toastrService.error("UnAuthorized.", "");
                    window.location.href = "/pages/authentication/login";
                } else {
                    this._toastrService.error(error.error.Message, "");
                }
            },
        );
    }

    getRideBluePrint() {
        let bluePrintIds = this.FiltersConfig.Filters.filter(
            x => x.filterType == FiltersTypes.bluePrintNumber,
        )[0];
        this._historicalridesservices.getBluePrintIds().subscribe(
            Response => {
                let bluePrintfilterres = [];
                Response.RideBlueprints.forEach(function (value) {
                    let data = {
                        id: value.Id,
                        name: value.Id,
                    };
                    bluePrintfilterres.push(data);
                });
                bluePrintIds.filterValues = bluePrintfilterres;
                bluePrintIds.filterDisabled = false;
            },
            error => {
                if (error.status == 401) {
                    this._toastrService.error("UnAuthorized.", "");
                    window.location.href = "/pages/authentication/login";
                } else {
                    this._toastrService.error(error.error.Message, "");
                }
            },
        );
    }

    moment(date: string, durationInMinutes: number) {
        return moment(date).add("minutes", durationInMinutes).format("hh:mm A");
    }

    ApplyPhoneMask(value) {
        let x = value.substring(0, 3);
        let y = value.substring(3, 6);
        let z = value.substring(6, 10);
        return "(" + x + ") " + y + "-" + z;
    }

    ContactPassengers(names) {
        let contactHtml = "";
        for (let name of names) {
            contactHtml +=
                name.FullName +
                " (" +
                name.RelationshipToEntity +
                ") " +
                "| " +
                this.ApplyPhoneMask(name.PhoneNumber) +
                "  ";
        }

        return contactHtml;
    }

    Equipments(names) {
        let equipmentHtml = "";
        for (let name of names) {
            equipmentHtml += name.Name + "  ";
        }

        return equipmentHtml;
    }

    ContactDestinations(names) {
        let contactHtml = "";
        for (let name of names) {
            contactHtml +=
                name.FullName +
                " (" +
                name.ContactType +
                ") " +
                "| " +
                this.ApplyPhoneMask(name.PhoneNumber) +
                "  ";
        }

        return contactHtml;
    }
    openSearchvalidation(text): void {
        this.textValidation = text;
        this.opendSearch = true;
    }
    convertDateUinx(dateUnix: number) {
        return moment.unix(dateUnix).format("MM/DD/YYYY hh:mm:ss A");
    }
    CargoInstructions(item) {
        let instructionHtml = "";
        let instructionCount = 0;
        if (item.PickupInstructionsNote != null) {
            let pickupInstructionsNote =
                item.PickupInstructionsNote.split('"').join("");
            instructionHtml +=
                "<span>#StrongStart#Pick-up Instructions#StrongEnd#</span>#NewLine#";
            instructionHtml +=
                "<span>" + pickupInstructionsNote + ".</span>#NewLine#";
        }

        if (item.DropOffInstructionsNote != null) {
            let dropOffInstructionsNote =
                item.DropOffInstructionsNote.split('"').join("");
            instructionHtml += instructionHtml != "" ? "<hr/>" : "";

            instructionHtml +=
                "<span>#NewLine# #StrongStart#Drop-off Instructions#StrongEnd#</span>#NewLine#";
            instructionHtml +=
                "<span>" + dropOffInstructionsNote + ".</span>#NewLine# ";
        }

        if (item.OnboardInstructionsNote != null) {
            let onboardInstructionsNote =
                item.OnboardInstructionsNote.split('"').join("");
            instructionHtml += instructionHtml != "" ? "<hr/>" : "";

            instructionHtml +=
                "<span>#NewLine# #StrongStart#Onboard Instructions#StrongEnd#</span>#NewLine# ";
            instructionHtml +=
                "<span>" + onboardInstructionsNote + ".</span>#NewLine# ";
        }

        if (item.SpecialInstructionsNote != null) {
            let specialInstructionsNote =
                item.SpecialInstructionsNote.split('"').join("");
            instructionHtml += instructionHtml != "" ? "<hr/>" : "";
            instructionHtml +=
                "<span>#NewLine# #StrongStart#Special Instructions#StrongEnd#</span>#NewLine# ";
            instructionHtml +=
                "<span>" + specialInstructionsNote + ".</span>#NewLine# ";
        }

        item.OtherInstructionsNote?.forEach(element => {
            instructionHtml += instructionHtml != "" ? "<hr/>" : "";
        });

        let result: string = compiledConvert(instructionHtml, options);
        let finalResutl = result
            .replaceAll("#NewLine#", "<br/>")
            .replaceAll("#StrongStart#", "<strong>")
            .replaceAll("#StrongEnd#", "</strong>");
        return finalResutl;
    }
    prepareHistoricaRidesWithDate(filterModel: HistoricalRidesFilterModel) {
        let that = this;
        filterModel.offset = that.skip;
        filterModel.limit = that.gridIndex > 0 ? that.gridIndex : filterModel.limit;

        that._appComponent.toggeleLoader();
        that._historicalridesservices.getGridData(filterModel).subscribe(
            Response => {
                let newData = Response.Rides.filter(
                    x => x.RideStatusId != RideStatusTypeEnum.Deleted,
                );
                that.dataRides = [];
                that.dataRides = [...that.dataRides, ...newData];
                that.state.take = that.dataRides.length;
                that.GridData = process(that.dataRides, that.state);
                that.GridData.total = Response.TotalNumberofFilteredRecords;
                that.gridView = process(that.dataRides, that.state);
                that.gridView.total = Response.TotalNumberofFilteredRecords;
                that.basicSelectedOption = Response.TotalNumberofFilteredRecords;
                that._appComponent.toggeleLoader();
            },
            error => {
                if (error.status == 401) {
                    that._toastrService.error("UnAuthorized.", "");
                    window.location.href = "/pages/authentication/login";
                } else {
                    that._toastrService.error(error.error.Message, "");
                }
            },
        );

        that._historicalridesservices
            .getGetRideFinderStatistics(filterModel)
            .subscribe(
                Response => {
                    that.statistics[0].value = Response.NumberOfCompletedRides;
                    that.statistics[1].value = Response.NumberOfNoShowRides;
                    that.statistics[2].value = Response.NumberOfCanceledLateRides;
                    that.statistics[3].value = Response.NumberOfEarlyCancelRides;
                    that.statistics[4].value = Response.NumberOfHolidayRides;
                    that.statistics[5].value = Response.NumberOfThreeXNoShowRides;
                },
                error => {
                    if (error.status == 401) {
                        that._toastrService.error("UnAuthorized.", "");
                        window.location.href = "/pages/authentication/login";
                    } else {
                        that._toastrService.error(error.error.Message, "");
                    }
                },
            );
    }
}
