import {
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AppComponent } from "app/app.component";
import { AuthenticationService } from "app/auth/service";
import { IFiltersConfig } from "app/controls/component/filters/interfaces/controls/IFiltersConfig";
import { FiltersTypes } from "app/enums/controls/filters/FiltersTypes";
import { RideBlueprintSheetService } from "app/main/RideBlueprints/service/ride-blueprint-sheet.service";
import { HelpersService } from "app/main/SharedServices/helpers.service";
import { ToastrService } from "ngx-toastr";
import { ConsecutiveNoShowReportFiliter } from "../model/consecutive-no-show-filiter.model";
import moment from "moment";
import { LocationModel } from "app/main/HistoricalRides/model/location.model";
import { ExcelExportData } from "@progress/kendo-angular-excel-export";
import { ConsecutiveNoShowReportService } from "../services/consecutive-no-show-report.services";
import { NoAttendanceReportModel } from "../model/no-attendance-report.model";
import { StudentPlaceModel } from "app/main/NoShowReports/model/student-place.model";
import { ContactModel } from "app/main/NoShowReports/model/contact.model";
import { NoShowReportService } from "app/main/NoShowReports/services/no-show-report.services";
import { StudentServiceDetailsModel } from "app/main/NoShowReports/model/student-service-details.model";
import { StudentTransportationProfileModel } from "app/main/NoShowReports/model/student-transportation-profile.model";
import { RideStatusTypeEnum } from "../../../enums/lookups/LookupEnum";

@Component({
  selector: "app-no-show-report",
  templateUrl: "./consecutive-no-show-report.component.html",
  styleUrls: ["./consecutiven-no-show-report.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class ConsecutiveNoShowReportComponent implements OnInit {
  @ViewChild("excel") excel: ElementRef<HTMLElement>;

  gridView: NoAttendanceReportModel[] = [];
  public opendSearch: boolean;
  public opendProfile: boolean;
  public fullName: string = "";
  public validation = "";
  public noShowcolumn: boolean = false;
  public lateCancelcolumn: boolean = false;
  public earlyCancelcolumn: boolean = false;
  public status: string;
  public studentAdress: LocationModel[];
  public placesAttended: StudentPlaceModel;
  public EmergencyContacts: ContactModel[];
  public studentInfo: StudentTransportationProfileModel;
  public serviceDetails: StudentServiceDetailsModel;
  public Equipment: any[];
  public skip = 0;
  public innepagesize = 3;
  constructor(
    private modalService: NgbModal,
    private _appComponent: AppComponent,
    private _rideBlueprintSheetService: RideBlueprintSheetService,
    private _sanitized: DomSanitizer,
    private _helperService: HelpersService,
    private _authenticationService: AuthenticationService,
    private _noShowReportService: NoShowReportService,
    private _toastrService: ToastrService,
    private _consecutiveNoShowReportService: ConsecutiveNoShowReportService,
  ) {
    this._appComponent._mainpageTitle = "Reports";
  }

  ngOnInit(): void {
    this.pagesize = 25;
    this.gridIndex = 25;
    this.getPassengersData();
  }

  FiltersConfig: IFiltersConfig = {
    isFiltersEnable: true,
    Filters: [
      {
        filterType: FiltersTypes.searchByNumber,
        filterPlaceholder: "Number of Consecutive Rides",
        filterClear: true,
        filterVisability: true,
        filtersize: "4",
      },
      {
        filterType: FiltersTypes.ConsecutivNoShowStatus,
        filterPlaceholder: "Filter by Status",
        filterClear: true,
        filterVisability: true,
        filterMultiple: true,
        filtersize: "4",
      },
      {
        filterType: FiltersTypes.Passengers,
        filterPlaceholder: "Filter By Student",
        filterVisability: true,
        filterClear: true,
        filtersize: "4",
      },
    ],
  };
  public gridData: any[] = [];
  public pagesize;
  public gridIndex;
  getGridData(filterModel?: ConsecutiveNoShowReportFiliter) {
    let that = this;

    if (filterModel == null || filterModel == undefined) {
      filterModel = new ConsecutiveNoShowReportFiliter();
    }
    filterModel.NumberOfConsecutive = that.FiltersConfig.Filters.filter(
      x => x.filterType == FiltersTypes.searchByNumber,
    )[0].filterValue
      ? that.FiltersConfig.Filters.filter(
          x => x.filterType == FiltersTypes.searchByNumber,
        )[0].filterValue
      : 0;

    filterModel.StudentId = that.FiltersConfig.Filters.filter(
      x => x.filterType == FiltersTypes.Passengers,
    )[0].filterValue
      ? that.FiltersConfig.Filters.filter(
          x => x.filterType == FiltersTypes.Passengers,
        )[0].filterValue
      : 0;

    filterModel.consecutiveFilter = that.FiltersConfig.Filters.filter(
      x => x.filterType == FiltersTypes.ConsecutivNoShowStatus,
    )[0].filterValue
      ? that.FiltersConfig.Filters.filter(
          x => x.filterType == FiltersTypes.ConsecutivNoShowStatus,
        )[0].filterValue
      : 0;

    if (
      filterModel.NumberOfConsecutive == 0 &&
      filterModel.consecutiveFilter == ""
    ) {
      that.openSearchvalidation(
        "kindly fill out the Number Of Consecutive and the status ",
      );
    } else if (filterModel.NumberOfConsecutive == 0) {
      that.openSearchvalidation(
        "kindly fill out the Number Of Consecutive Rides",
      );
    } else if (filterModel.consecutiveFilter == "") {
      that.openSearchvalidation("kindly fill out the Status");
    } else {
      that.getDefaultGridData(filterModel);
    }
  }
  getPassengersData() {
    let passenger = this.FiltersConfig.Filters.filter(
      x => x.filterType == FiltersTypes.Passengers,
    )[0];
    this._consecutiveNoShowReportService.getPassengersData().subscribe(
      response => {
        let passengerfilterres = [];
        response.forEach(function (value) {
          let data = {
            id: value.StudentId,
            name: value.FirstName + " " + value.LastName,
          };
          passengerfilterres.push(data);
        });
        passenger.filterValues = passengerfilterres;
        passenger.filterDisabled = false;
      },
      error => {
        if (error.status == 401) {
          this._toastrService.error("UnAuthorized.", "");
          window.location.href = "/pages/authentication/login";
        } else {
          this._toastrService.error(error.error.Message, "");
        }
      },
    );
  }

  openSearchvalidation(text: string): void {
    this.validation = text;
    this.opendSearch = true;
  }
  close(status: string): void {
    console.log(`Dialog result: ${status}`);

    this.opendSearch = false;
    this.opendProfile = false;
  }
  public viewMore(): void {
    this.pagesize = parseInt(this.pagesize) + parseInt(this.gridIndex);
  }
  public changeGridIndex(): void {
    this.pagesize = this.gridIndex;
  }
  getTime(time) {
    return time == null ? "NA" : moment.utc(time).local().format("LT");
  }

  getAge(date) {
    return moment().diff(moment(date).format("MM/DD/YYYY"), "years");
  }

  public GetExcelData = (): ExcelExportData => {
    let result: ExcelExportData = {
      data: this.gridView.slice(),
    };

    this.excel.nativeElement.click();
    return result;
  };

  getDate(dateValue) {
    return moment(dateValue).format("MM/DD/YYYY");
  }

  print() {
    window.print();
  }

  modalOpenStudentProfileModel(fisrtName, LastName, studentId) {
    this._appComponent.toggeleLoader();

    this.fullName = fisrtName + " " + LastName;
    this._noShowReportService.getStudentProfile(studentId).subscribe(
      Response => {
        this.studentInfo = Response[0].BasicInformation;
        this.serviceDetails = Response[0].ServiceDetails;
        this.studentAdress = Response[0].HomeAddresses;
        this.placesAttended = Response[0].PlacesAttended;
        this.EmergencyContacts = Response[0].EmergencyContacts;
        this.Equipment = Response[0].Equipment;
        this._appComponent.toggeleLoader();
        this.opendProfile = true;
      },
      error => {
        if (error.status == 401) {
          this._toastrService.error("UnAuthorized.", "");
          this._appComponent.toggeleLoader();

          window.location.href = "/pages/authentication/login";
        } else {
          this._toastrService.error(error.error.Message, "");
          this._appComponent.toggeleLoader();
        }
      },
    );
  }

  getDefaultGridData(filterModel: any) {
    {
      let that = this;
      this._consecutiveNoShowReportService
        .getConsecutiveNoShowReport(filterModel)
        .subscribe(
          Response => {
            that._appComponent.toggeleLoader();
            that.gridView = Response;
            that.status = filterModel.consecutiveFilter;
            that.earlyCancelcolumn = !that.status.includes(
              RideStatusTypeEnum.CanceledInAdvance.toString(),
            );
            that.lateCancelcolumn = !that.status.includes(
              RideStatusTypeEnum.CanceledLate.toString(),
            );
            that.noShowcolumn = !that.status.includes(
              RideStatusTypeEnum.NoShow.toString(),
            );
            let i = 1;

            if (that.gridView != null)
              that.gridView.forEach(element => {
                element.CancelTime = that.getCancelationTime(element);
                element.NoShowTime =
                  that.getTime(element.NoShowTime) == "NA"
                    ? "N/A"
                    : that.getTime(element.NoShowTime);
                element.EarlyCancelTime =
                  that.getTime(element.EarlyCancelTime) == "NA"
                    ? "N/A"
                    : that.getTime(element.EarlyCancelTime);
                element.DateOfService = this.getDate(element.DateOfService);
                element.id = i;
                i++;
              });
          },
          error => {
            if (error.status == 401) {
              that._toastrService.error("UnAuthorized.", "");
              window.location.href = "/pages/authentication/login";
            } else {
              that._toastrService.error(error.error.Message, "");
            }
          },
        );
      that._appComponent.toggeleLoader();
    }
  }
  getCancelationTime(element) {
    let that = this;
    return this.getTime(element.CancelTime) == "NA"
      ? "N/A"
      : that.getTime(element.CancelTime);
  }
}
