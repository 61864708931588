import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AppComponent } from "app/app.component";
import { ActivatedRoute } from "@angular/router";
import { AuthenticationService } from "app/auth/service";
import { State } from "@progress/kendo-data-query";
import { ToastrService } from "ngx-toastr";
import { AccountDataModel } from "../model/AccountDataModel";
import { GuardianDataModel } from "../model/GuardianDataModel";
import { User } from "app/auth/models/user";
import { ClientGuardiansService } from "../services/client-guardians.service";
import { HelpersService } from "app/main/SharedServices/helpers.service";
import { PersonalPhotoComponent } from "../../../controls/component/photo/personal-photo/personal-photo.component";

@Component({
  selector: "app-add-edit-student",
  templateUrl: "./edit-guardian.component.html",
  styleUrls: ["./edit-guardian.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class EditGuardianComponent implements OnInit {
  //#region  Properities

  public GuardianGridData: any = [];
  public AddressGridData: any = [];
  public openRestPass: boolean = false;
  @Output() update = new EventEmitter<boolean>();

  _currentUser: User;

  newGuardianForm: FormGroup;
  public guardianGrid: any[];
  public openUnlink: boolean = false;
  public fullName: string = "";
  public guardianId: number;
  public accountId: number;
  public entityType = "guardian";
  public folder = "guardian";
  public type = "Profile Photo";
  public title = "Edit Guardian Photo";
  @ViewChild("PersonalPhotoComponent")
  personalPhotoComponent: PersonalPhotoComponent;
  //#endregion

  //#region TabsButtons

  //#endregion

  //#region Districts Form properites

  get FirstName() {
    return this.newGuardianForm.get("FirstName")!;
  }
  get LastName() {
    return this.newGuardianForm.get("LastName")!;
  }
  get Email() {
    return this.newGuardianForm.get("Email")!;
  }
  get PhoneNumber() {
    return this.newGuardianForm.get("PhoneNumber")!;
  }

  //#endregion
  guardianid: number = 0;
  //#region  constructor & ngOnInit
  constructor(
    private _formBuilder: FormBuilder,
    private _appComponent: AppComponent,
    private route: ActivatedRoute,
    private _authenticationService: AuthenticationService,
    private _clientGuardiansService: ClientGuardiansService,
    private _toastrService: ToastrService,
    private _helperService: HelpersService,
  ) {
    this._authenticationService.currentUser.subscribe(
      x => (this._currentUser = x),
    );
    this._appComponent._mainpageTitle = "App Guardian User";
  }
  state: State = {
    skip: 0,
    take: 25,
    group: [],
    filter: { filters: [], logic: "or" },
    sort: [
      {
        field: "name",
        dir: "asc",
      },
    ],
  };
  public DistrictId: number = 0;
  ngOnInit(): void {
    let id = parseInt(this.route.snapshot.paramMap.get("id")!);
    this.guardianId = id;
    this.getAdress(id);
    this.getGuardian(id);

    if (this.route.snapshot.paramMap.get("id")) {
      id = parseInt(this.route.snapshot.paramMap.get("id")!);

      this.guardianid = id;

      this.newGuardianForm = this._formBuilder.group({
        FirstName: ["", [Validators.required]],
        LastName: ["", [Validators.required]],
        PhoneNumber: [
          "",
          [Validators.pattern(/^\(\d{3}\)\s\d{3}-\d{4}$/), Validators.required],
        ],
        Email: ["", [Validators.email]],
        Password: [""],
        guardianId: [""],
      });

      this.getStudentData(id);
    }
  }

  //#endregion

  //#endregion
  public isValidForm(): boolean {
    let that = this;
    for (const control of Object.keys(that.newGuardianForm.controls)) {
      that.newGuardianForm.controls[control].markAsTouched();
    }

    return that.newGuardianForm.invalid;
  }

  onSubmit() {
    let that = this;
    if (!that.isValidForm()) {
      that._appComponent.toggeleLoader();

      that._clientGuardiansService
        .updateGuardian(that.guardianId, that.getAddGuardianObject())
        .subscribe(
          (Response: any) => {
            this.update.emit(true);
            that._toastrService.success(
              "The gurdians was updated successfully.",
              "",
            );
            that._appComponent.toggeleLoader();
            setTimeout(() => {
              window.location.href = "client-gurdians/gurdians";
            }, 600);
          },
          error => {
            if (error.status == 401) {
              that._toastrService.error("UnAuthorized.", "");
              window.location.href = "/pages/authentication/login";
              that._appComponent.toggeleLoader();
            } else {
              that._toastrService.error(
                "The gurdians was not  added due to technical issue.",
                "",
              );
              that._appComponent.toggeleLoader();
            }
          },
        );
    }
  }

  ChangeAgeValue() {
    let date = new Date(this.newGuardianForm.get("DateOfBirth").value);
    this.newGuardianForm
      .get("Age")
      .patchValue(Math.abs(date.getFullYear() - new Date().getFullYear()));
  }

  getAddGuardianObject() {
    let that = this;
    let data = new GuardianDataModel();

    data.Id = 0;
    let account = new AccountDataModel();
    account.Id = Number(that.newGuardianForm.value.guardianId);
    account.FirstName = that.newGuardianForm.value.FirstName;
    account.LastName = that.newGuardianForm.value.LastName;
    account.Email = that.newGuardianForm.value.Email;

    account.MobileNumber = that.newGuardianForm.value.PhoneNumber.replace(
      /\D/g,
      "",
    );
    data.Account = account;
    data.RelationshipToStudent = that.newGuardianForm.value.relationshipName;
    data.StudentId = that.newGuardianForm.value.guardianid;
    data.AccountId = 0;
    data.isClientPortal = true;
    data.CreatedByAccountId = that._currentUser.id;

    return data;
  }

  getStudentData(guardianid) {
    let that = this;
    this._clientGuardiansService.loadGuardian(guardianid).subscribe(
      Response => {
        that._appComponent.toggeleLoader();

        that.GuardianGridData = Response.Account;
        that.newGuardianForm.setValue({
          FirstName: that.GuardianGridData.FirstName,
          LastName: that.GuardianGridData.LastName,
          Password: "12345678",
          Email: that.GuardianGridData.Email,
          PhoneNumber: this.getMobileNumber(that.GuardianGridData.MobileNumber),
          guardianId: guardianid,
        });
        if (Response?.Account.ProfilePhoto) {
          that.personalPhotoComponent.data = Response.Account.ProfilePhoto;
          that.personalPhotoComponent.loadPersonalPhoto();
        }
      },
      error => {
        if (error.status == 401) {
          this._toastrService.error("UnAuthorized.", "");
          window.location.href = "/pages/authentication/login";
        } else {
          this._toastrService.error(error.error.Message, "");
        }
      },
    );
    that._appComponent.toggeleLoader();
  }

  getAdress(guardianid) {
    let that = this;
    this._clientGuardiansService.loadGuardianAddresses(guardianid).subscribe(
      Response => {
        that.AddressGridData = Response;
      },
      error => {
        if (error.status == 401) {
          this._toastrService.error("UnAuthorized.", "");
          window.location.href = "/pages/authentication/login";
        } else {
          this._toastrService.error(error.error.Message, "");
        }
      },
    );
  }

  getGuardian(guardianid) {
    let that = this;
    this._clientGuardiansService.loadGuardianOfStudents(guardianid).subscribe(
      Response => {
        that.guardianGrid = Response;
      },
      error => {
        if (error.status == 401) {
          this._toastrService.error("UnAuthorized.", "");
          window.location.href = "/pages/authentication/login";
        } else {
          this._toastrService.error(error.error.Message, "");
        }
      },
    );
  }

  close(status: string): void {
    console.log(`Dialog result: ${status}`);
    this.openUnlink = false;
    this.openRestPass = false;
  }

  openUnlinkPopup(AccountId, FirstName, lastName) {
    this.accountId = AccountId;
    this.fullName = FirstName + " " + lastName;
    this.openUnlink = true;
  }

  Unlink() {
    let that = this;
    that.openUnlink = false;
    this._clientGuardiansService
      .unLinkGuardianFromStudent(that.guardianId, that.accountId)
      .subscribe(
        Response => {
          that._appComponent.toggeleLoader();
          that.getGuardian(that.guardianId);
          that.getStudentData(that.guardianId);
          that.getAdress(that.guardianId);
        },
        error => {
          if (error.status == 401) {
            this._toastrService.error("UnAuthorized.", "");
            window.location.href = "/pages/authentication/login";
          } else {
            this._toastrService.error(error.error.Message, "");
          }
        },
      );
    that._appComponent.toggeleLoader();
  }

  resetPassword() {
    let that = this;
    this.openRestPass = false;
    this._clientGuardiansService.resetPassword(that.guardianId).subscribe(
      Response => {
        that._appComponent.toggeleLoader();
        that._toastrService.success("The password was rest Successfully.", "");
      },
      error => {
        if (error.status == 401) {
          this._toastrService.error("UnAuthorized.", "");
          window.location.href = "/pages/authentication/login";
        } else {
          this._toastrService.error(error.error.Message, "");
        }
      },
    );
    that.getStudentData(that.guardianId);
    that.getAdress(that.guardianId);
    that.getGuardian(that.guardianId);
    that._appComponent.toggeleLoader();
  }

  openRestPassPopup() {
    this.openRestPass = true;
  }

  reloadData(isDeleted: any) {
    let that = this;
    if (isDeleted) {
      that.getAdress(that.guardianId);
    }
  }

  getMobileNumber(mobileNumber: string) {
    return this._helperService.phoneNumber(mobileNumber);
  }
}
