import { AbstractControl, ValidationErrors } from "@angular/forms";

export class WhiteSpace {
  static noSpaceAllowed(control: AbstractControl): ValidationErrors | null {
    if (!(control.value || "").trim().length) {
      return { noSpaceAllowed: true };
    }

    return null;
  }
}
