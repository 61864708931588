<input
  type="text"
  id="{{ Id }}"
  [(ngModel)]="input"
  (ngModelChange)="writeValue($event)"
  (input)="onChange($any($event.target).value)"
  (blur)="onTouched(); blur.emit()"
  [disabled]="disabled"
  [placeholder]="placeholder"
  class="form-control"
  maxlength="{{ maxlength }}"
  [ngClass]="{
    'is-invalid': controlDir?.control?.touched && controlDir.control?.invalid
  }"
/>
<div
  class="invalid-feedback"
  style="font-size: 10px !important"
>
  {{ validationError }}
</div>
<div
  class="invalid-message"
  style="font-size: 10px !important"
  *ngIf="extraValidationError.Valid"
>
  {{ extraValidationError.validationError }}
</div>
