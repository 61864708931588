<!-- vertical-layout -->
<ng-container *ngIf="coreConfig.layout.type === 'vertical'">
  <vertical-layout
    [IsHidden]="_Auth.hasUserInfo"
    [pageTitle]="_mainpageTitle"
  ></vertical-layout>
</ng-container>
<!-- / vertical-layout -->

<!-- horizontal-layout -->
<ng-container *ngIf="coreConfig.layout.type === 'horizontal'">
  <horizontal-layout [IsHidden]="_Auth.hasUserInfo"></horizontal-layout>>
</ng-container>
<!-- / horizontal-layout -->

<!-- theme customizer -->
<core-sidebar
  name="themeCustomizer"
  class="customizer d-none d-md-block"
  [invisibleOverlay]="true"
  *ngIf="coreConfig.layout.customizer"
>
  <a
    class="customizer-toggle d-flex align-items-center justify-content-center"
    (click)="toggleSidebar('themeCustomizer')"
  >
    <span
      [data-feather]="'settings'"
      [class]="'spinner'"
    ></span>
  </a>
  <core-theme-customizer></core-theme-customizer>
</core-sidebar>

<!-- / theme customizer -->
<div
  id="Loader"
  *ngIf="showLoader"
  style="width: 100%; height: 100%"
>
  <div class="loading">
    <div class="effect-1 effects"></div>
    <div class="effect-2 effects"></div>
    <div class="effect-3 effects"></div>
  </div>
</div>
