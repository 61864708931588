import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Self,
} from "@angular/core";
import { ControlValueAccessor, NgControl, Validators } from "@angular/forms";

@Component({
  selector: "app-required-field-textarea",
  templateUrl: "./required-field-textarea.component.html",
})
export class RequiredFieldTextareaComponent
  implements OnInit, ControlValueAccessor
{
  @Input() placeholder: string = "";
  @Output() blur: EventEmitter<void> = new EventEmitter<void>();
  disabled!: boolean;
  onChange: (value: any) => void = () => {};
  onTouched: () => void = () => {};
  input: string;

  constructor(@Self() public controlDir: NgControl) {
    controlDir.valueAccessor = this;
  }

  ngOnInit(): void {
    const control = this.controlDir.control;
    const validators = control?.validator
      ? [control.validator, Validators.required]
      : null;
    control?.setValidators(validators);
    control?.updateValueAndValidity();
  }

  writeValue(value: any): void {
    this.input = value;
  }

  registerOnChange(onChange: (value: any) => void): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: () => void): void {
    this.onTouched = onTouched;
  }

  setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
  }
}
