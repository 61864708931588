import { Directive, Input } from "@angular/core";
import { NgControl } from "@angular/forms";
import { AuthenticationService } from "app/auth/service/authentication.service";
import { PermissionsList } from "app/enums/Permissions/PermissionList";

@Directive({
  selector: "[formControlName][disabledd]",
})
export class DisabledDirective {
  @Input("disabledd") Permission: string;
  constructor(
    private _authenticationService: AuthenticationService,
    public ngControl: NgControl,
  ) {}
  disabled: boolean = true;
  ngOnInit(): void {
    this.disabled = this._authenticationService.checkaccess(
      [PermissionsList[this.Permission]].toString(),
    );
    this.disableCheack();
  }

  disableCheack() {
      if (this.disabled) {
      this.ngControl.control["enable"]();
    } else {
      this.ngControl.control["disable"]();
    }
  }
}
