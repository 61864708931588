export class ClientGurdiansFilterModel {
  searchTerm: string = "";
  clientId: number = 0;
  archived: boolean = null;
  limit: number = 25;
  offset: number = 0;
  sort_by: string = "name";
  order_by: string = "asc";
  viewType: string = "";
  isClientPortal: boolean = false;
  studentId: number = 0;
  isBlocked: boolean = null;
}
