import { AccountStudentDataModel } from "./AccountStudentDataModel";

export class GuardianDataModel {
  Id: number;
  StudentId: number;
  AccountId: string;
  RelationshipToStudent: string;
  Account: AccountStudentDataModel;
  isClientPortal: boolean;
}
