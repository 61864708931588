import { NgModule } from "@angular/core";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { RequiredFieldComponent } from "./required-field.component";
import { RequiredFieldTextareaComponent } from "./required-field-textarea.component";
import { BrowserModule } from "@angular/platform-browser";
import { CoreCommonModule } from "../../../../@core/common.module";
import { OptionalFieldComponent } from "./OptionalField/optional-field.component";
import { MultiSelectorComponent } from "./MulitSelector/multi-selector.component";
import { NgSelectModule } from "@ng-select/ng-select";
import { CommonModule } from "@angular/common";
import { SingleSelectorComponent } from "./SingleSelector/single-selector.component";
import { DateTimePickerComponent } from "./DateTimePicker/date-time-picker.component";
import { DatePickerModule } from "@progress/kendo-angular-dateinputs";
import { Ng2FlatpickrModule } from "ng2-flatpickr";
import { TypeaHeadComponent } from "./typea-head/typea-head.component";
import { DropDownListModule } from "@progress/kendo-angular-dropdowns";

@NgModule({
  declarations: [
    RequiredFieldComponent,
    RequiredFieldTextareaComponent,
    OptionalFieldComponent,
    MultiSelectorComponent,
    SingleSelectorComponent,
    DateTimePickerComponent,
    TypeaHeadComponent,
  ],
  exports: [
    RequiredFieldComponent,
    RequiredFieldTextareaComponent,
    OptionalFieldComponent,
    MultiSelectorComponent,
    SingleSelectorComponent,
    DateTimePickerComponent,
    TypeaHeadComponent,
    NgbModule,
  ],
  imports: [
    CoreCommonModule,
    NgbModule,
    BrowserModule,
    NgSelectModule,
    CommonModule,
    DatePickerModule,
    Ng2FlatpickrModule,
    CommonModule,
    DropDownListModule,
  ],
})
export class ControlsModule {}
