<textarea
  [(ngModel)]="input"
  (input)="onChange($any($event.target).value)"
  (blur)="onTouched(); blur.emit()"
  [disabled]="disabled"
  [placeholder]="placeholder"
  class="form-control form-control-adr"
  [ngClass]="{
    'is-invalid': controlDir?.control?.touched && controlDir.control?.invalid
  }"
></textarea>
<div class="invalid-feedback">This field is required.</div>
