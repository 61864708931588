<!--Home Address(es)-->
<div class="row section-div">
  <div class="col-lg-12">
    <strong id="address_block_title">Home Address(es)</strong>
    <p
      class="text-muted"
      id="address_block_sub_title"
      style="padding-top: 4px"
    >
      The address(es) the student is picked-up from or dropped-off at before or
      after school.
    </p>
  </div>
  <kendo-grid
    #mainGrid
    [data]="AddressGridData"
    [pageSize]="state.take"
    [skip]="state.skip"
    [sort]="state.sort"
  >
    <ng-template kendoGridNoRecordsTemplate> No addresses. </ng-template>
    <ng-template
      kendoGridToolbarTemplate
      NgClass="p-0"
    >
    </ng-template>
    <kendo-grid-spacer></kendo-grid-spacer>
    <kendo-grid-column
      [width]="50"
      field="Index"
      [sortable]="false"
      title="#"
    >
      <ng-template
        kendoGridCellTemplate
        let-rowIndex="rowIndex+1"
      >
        {{ rowIndex }}
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column
      field="Nickname"
      title="Nickname"
      [sortable]="false"
    >
      <ng-template
        kendoGridCellTemplate
        let-dataItem
        let-rowIndex="rowIndex"
      >
        {{ dataItem.Name }}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="Address"
      title="Address"
      [sortable]="false"
    >
      <ng-template
        kendoGridCellTemplate
        let-dataItem
        let-rowIndex="rowIndex"
      >
        <a
          class="btn btn-link pl-0 pr-0"
          (click)="modalOpenMD(dataItem, rowIndex)"
        >
          {{ dataItem.Location.FullAddress }}
        </a>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column
      [sortable]="false"
      title="Action"
      [width]="90"
    >
      <ng-template
        kendoGridCellTemplate
        let-dataItem
        let-rowIndex="rowIndex"
      >
        <div class="d-flex align-items-center">
          <div
            ngbDropdown
            [placement]="'left-top'"
            container="body"
            class="m-auto"
          >
            <a
              ngbDropdownToggle
              href="javascript:void(0);"
              class="hide-arrow"
              id="dropdownBrowserState"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i
                data-feather="more-vertical"
                class="text-primary cursor-pointer mr-50"
              ></i>
            </a>
            <div
              ngbDropdownMenu
              class="dropdown-menu-right"
              aria-labelledby="dropdownBrowserState"
              disabled
            >
              <a
                (click)="modalOpenMD(dataItem, rowIndex)"
                ngbDropdownItem
                class="d-flex align-items-center"
              >
                Edit
              </a>
              <div
                class="dropdown-divider"
                *ngIf="!disabeld"
              ></div>
              <a
                href="javascript:void(0)"
                (click)="deleteAddress(dataItem, rowIndex)"
                ngbDropdownItem
                class="d-flex align-items-center"
                *ngIf="!disabeld"
              >
                Delete
              </a>
            </div>
          </div>
        </div>
      </ng-template>
    </kendo-grid-column>
  </kendo-grid>
  <div class="col-lg-3">
    <button
      type="button"
      rippleeffect=""
      class="btn btn-close waves-effect waves-float waves-light mt-1"
      style="border-color: #1877f2; margin-bottom: 20px; color: #1877f2"
      (click)="modalOpenMD()"
      [hidden]="disabeld"
    >
      Add Address
    </button>
  </div>
</div>

<!--Place(s) Attended-->
<div class="row section-div">
  <div class="col-lg-12">
    <strong id="Place_block_title">Place(s) Attended</strong>
    <p
      class="text-muted"
      id="address_block_sub_title"
      style="padding-top: 4px"
    >
      The place(s) the student attends for education.
    </p>
  </div>
  <div>
    <kendo-grid
      #mainGrid
      [data]="PlaceGridData"
      [pageSize]="state.take"
      [skip]="state.skip"
      [sort]="state.sort"
    >
      <ng-template kendoGridNoRecordsTemplate>
        No places attended.
      </ng-template>
      <ng-template
        kendoGridToolbarTemplate
        NgClass="p-0"
      >
      </ng-template>
      <kendo-grid-spacer></kendo-grid-spacer>

      <kendo-grid-column
        field="Name"
        title="Name"
        [sortable]="false"
      >
        <ng-template
          kendoGridCellTemplate
          let-dataItem
          let-rowIndex="rowIndex"
        >
          <label>
            <span class="pull-left">{{ dataItem.Name }}</span>
            <br />
            <span class="text-muted pull-left"
              >{{ dataItem.Location.Street1 }} {{ dataItem.Location.City }},
              {{ dataItem.Location.State }}
              {{ dataItem.Location.Zipcode }}</span
            >
          </label>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column
        field="Calendar"
        title="Calendar"
        [sortable]="false"
      >
        <ng-template
          kendoGridCellTemplate
          let-dataItem
          let-rowIndex="rowIndex"
        >
          <ng-container
            *ngIf="
              (dataItem.PlaceScheduleName == '' &&
                dataItem.PlaceScheduleName == null) ||
                (dataItem.PlaceScheduleNickName == '' &&
                  dataItem.PlaceScheduleNickName == null);
              then TemplateOne;
              else TemplateTwo
            "
          >
          </ng-container>
          <ng-template #TemplateOne>
            <span> Not Available </span>
          </ng-template>
          <ng-template #TemplateTwo>
            <span>{{ dataItem.PlaceScheduleName }}</span
            ><br />
            <span class="text-muted">{{ getCalendar(dataItem) }}</span>
          </ng-template>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column
        field="Status"
        title="Status"
        [sortable]="false"
      >
        <ng-template
          kendoGridCellTemplate
          let-dataItem
          let-rowIndex="rowIndex"
        >
          <ng-container
            *ngIf="
              dataItem.Status == 'Archived';
              then TemplateOne;
              else TemplateTwo
            "
          >
          </ng-container>
          <ng-template #TemplateOne>
            <div
              class="font-size-12 badge badge-danger"
              data-original-title="Status"
            >
              {{ dataItem.Status }}
            </div>
          </ng-template>
          <ng-template #TemplateTwo>
            <div
              *ngIf="dataItem.RequiresAttention > 0"
              class="font-size-12 badge badge-danger hover-pointer"
              (click)="
                showAttentionDialog(
                  dataItem.Place.RequiresAttention,
                  'dataItem.Name'
                )
              "
            >
              Attention
            </div>
            <div
              *ngIf="dataItem.RequiresAttention <= 0"
              class="font-size-12 badge badge-success"
            >
              {{ dataItem.Status }}
            </div>
          </ng-template>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column
        [sortable]="false"
        title="Action"
        [width]="90"
      >
        <ng-template
          kendoGridCellTemplate
          let-dataItem
          let-rowIndex="rowIndex"
        >
          <div class="d-flex align-items-center">
            <div
              ngbDropdown
              [placement]="'left-top'"
              container="body"
              class="m-auto"
            >
              <a
                ngbDropdownToggle
                href="javascript:void(0);"
                class="hide-arrow"
                id="dropdownBrowserState"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i
                  data-feather="more-vertical"
                  class="text-primary cursor-pointer mr-50"
                ></i>
              </a>
              <div
                ngbDropdownMenu
                class="dropdown-menu-right"
                aria-labelledby="dropdownBrowserState"
                disabled
              >
                <a
                  href="javascript:void(0)"
                  (click)="modalOpenPlace(dataItem, rowIndex)"
                  ngbDropdownItem
                  class="d-flex align-items-center"
                >
                  Edit Calendar
                </a>
                <div
                  class="dropdown-divider"
                  *ngIf="!disabeld"
                ></div>
                <a
                  href="javascript:void(0)"
                  (click)="openDeleteStudentPlaceAlert(dataItem, rowIndex)"
                  ngbDropdownItem
                  class="d-flex align-items-center"
                  *ngIf="!disabeld"
                >
                  Remove
                </a>
              </div>
            </div>
          </div>
        </ng-template>
      </kendo-grid-column>
    </kendo-grid>
  </div>
  <div class="col-lg-3">
    <button
      type="button"
      rippleeffect=""
      class="btn btn-close waves-effect waves-float waves-light mt-1"
      style="border-color: #1877f2; margin-bottom: 20px; color: #1877f2"
      (click)="modalOpenPlace()"
      [hidden]="disabeld"
    >
      Add Place
    </button>
  </div>
</div>

<kendo-dialog
  title=""
  *ngIf="openedDelete"
  (close)="close('deleted')"
  [minWidth]="250"
  [width]="400"
>
  <div class="col-lg-12 fieldsDiv">
    Are you sure you want to delete the address?
  </div>
  <kendo-dialog-titlebar>
    <div style="font-size: 18px; line-height: 1.3em; font-weight: 500">
      <span
        class="k-icon k-i-info-circle mr-1"
        style="color: #eea236; font-size: 26px; margin-top: -3px"
      ></span
      >Delete Confirmation
    </div>
  </kendo-dialog-titlebar>
  <form
    [formGroup]="deleteAddressStudentData"
    (keydown.enter)="$event.preventDefault()"
  >
    <div class="col-lg-12 fieldsDiv">
      <label
        for="InternalNote"
        class="form_field_label"
        >Reason</label
      >
      <app-required-field-textarea
        formControlName="InternalNote"
        placeholder="Please enter a reason in order to delete."
      ></app-required-field-textarea>
    </div>
  </form>
  <kendo-dialog-actions>
    <p style="text-align: center">
      <button
        type="button"
        (click)="close('deleted')"
        rippleeffect=""
        class="btn btn-default waves-effect waves-float waves-light"
        style="
          background-color: #acb7bf;
          border-color: #acb7bf;
          color: white;
          margin-right: 10px;
        "
      >
        No
      </button>
      <button
        type="button"
        (click)="confirmDeleteAddress()"
        rippleeffect=""
        [disabled]="deleteAddressStudentData.invalid"
        class="btn btn-danger waves-effect waves-float waves-light"
      >
        Yes
      </button>
    </p>
  </kendo-dialog-actions>
</kendo-dialog>

<kendo-dialog
  title=""
  *ngIf="openedDeleteDenied"
  (close)="close('denied')"
  [minWidth]="250"
  [width]="400"
>
  <p style="text-align: center">
    This place cannot be deleted. This place is currently linked to at least one
    Ride Blueprint
  </p>
  <kendo-dialog-titlebar>
    <div style="font-size: 18px; line-height: 1.3em; font-weight: 500">
      <span
        class="k-icon k-i-info-circle mr-1"
        style="color: #eea236; font-size: 26px; margin-top: -3px"
      ></span
      >Delete Denied
    </div>
  </kendo-dialog-titlebar>
  <kendo-dialog-actions>
    <p style="text-align: center">
      <button
        type="button"
        (click)="close('denied')"
        rippleeffect=""
        class="btn btn-danger waves-effect waves-float waves-light"
      >
        Ok, Dismiss.
      </button>
    </p>
  </kendo-dialog-actions>
</kendo-dialog>
<!----------------------------------------------------------------------------------------------------->

<kendo-dialog
  title=""
  *ngIf="openedDeleteStudentPlace"
  (close)="close('DeleteStudentPlace')"
  [minWidth]="250"
  [width]="400"
>
  <kendo-dialog-titlebar>
    <div style="font-size: 18px; line-height: 1.3em; font-weight: 500">
      <span
        class="k-icon k-i-info-circle mr-1"
        style="color: #eea236; font-size: 26px; margin-top: -3px"
      ></span
      >Remove Denied
    </div>
  </kendo-dialog-titlebar>
  <p class="text-left">{{ deleteStudentPlaceContent.Content }}</p>
  <p></p>
  <p class="text-left text-black">{{ deleteStudentPlaceContent.Step1 }}</p>
  <p class="text-left text-black">{{ deleteStudentPlaceContent.Step2 }}</p>
  <kendo-dialog-actions>
    <p style="text-align: center">
      <button
        type="button"
        (click)="close('DeleteStudentPlace')"
        rippleeffect=""
        class="btn btn-info waves-effect waves-float waves-light"
      >
        Ok, Dismiss.
      </button>
    </p>
  </kendo-dialog-actions>
</kendo-dialog>

<kendo-dialog
  title=""
  *ngIf="openedRemoveConfirmationStudentPlace"
  (close)="close('ConfirmationStudentPlace')"
  [minWidth]="250"
  [width]="400"
>
  <p style="text-align: center">
    Are you sure you want to remove this place
    {{ deleteStudentPlaceContent.Content }} ?
  </p>
  <kendo-dialog-titlebar>
    <div style="font-size: 18px; line-height: 1.3em; font-weight: 500">
      <span
        class="k-icon k-i-info-circle mr-1"
        style="color: #eea236; font-size: 26px; margin-top: -3px"
      ></span
      >Remove Confirmation
    </div>
  </kendo-dialog-titlebar>
  <form
    [formGroup]="deleteStudentPlace"
    (keydown.enter)="$event.preventDefault()"
  >
    <div class="col-lg-12 fieldsDiv">
      <app-required-field-textarea
        formControlName="InternalNote"
        placeholder="Please enter a reason in order to remove."
      ></app-required-field-textarea>
    </div>
  </form>
  <kendo-dialog-actions>
    <p style="text-align: center">
      <button
        type="button"
        (click)="close('ConfirmationStudentPlace')"
        rippleeffect=""
        class="btn btn-default waves-effect waves-float waves-light"
        style="
          background-color: #acb7bf;
          border-color: #acb7bf;
          color: white;
          margin-right: 10px;
        "
      >
        No
      </button>
      <button
        type="button"
        (click)="confirmDeleteStudentPlaceByPlaceId()"
        rippleeffect=""
        [disabled]="deleteStudentPlace.invalid"
        class="btn btn-danger waves-effect waves-float waves-light"
      >
        Yes
      </button>
    </p>
  </kendo-dialog-actions>
</kendo-dialog>

<app-student-add-new-home-address
  [gridData]="AddressGridData"
  #studentaddHomeAddressComponent
  (Added)="reloadData($event)"
  [disable]="disabeld"
>
</app-student-add-new-home-address>

<app-student-add-new-place
  [gridData]="PlaceGridData"
  #studentaddPlaceComponent
  (Added)="reloadPlaceData($event)"
  [disable]="disabeld"
>
</app-student-add-new-place>
