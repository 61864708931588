import { Component, EventEmitter, Input, Output } from "@angular/core";
import { IFilters } from "../interfaces/controls/IFiltersConfig";

@Component({
  selector: "app-gurdians-status",
  templateUrl: "./gurdians-status.component.html",
  styleUrls: ["./gurdians-status.component.scss"],
})
export class GurdiansStatusComponent {
  @Input("filterData") Config: IFilters;
  @Output() changeValue = new EventEmitter<string>();

  ngOnInit(): void {
    this.Config.filterValues = [
      { id: "Active", name: "Active" },
      { id: "blocked", name: "Blocked" },
      { id: "archived", name: "Archived" },
    ];
  }
  Onchange() {
    this.changeValue.emit("complete");
  }
}
