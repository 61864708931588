<div class="row">
  <div class="col-lg-12 col-md-12">
    <div class="card widget-card">
      <div class="card-body">
        <div
          class="row"
          dragula="card-drag-area"
          id="draggable-wedgit"
        >
          <div class="col pb-lg-0 draggable">
            <div class="{{ isMoveEnable ? 'MoveEnabled' : '' }}">
              <div
                class="WidgetmoveIcon"
                *ngIf="isMoveEnable"
              >
                <span
                  [data-feather]="'move'"
                  [class]="'mr-25'"
                ></span>
              </div>
              <div class="widget-body widget-primary">
                <h4 class="text-secondary"># OF STUDENTS</h4>
                <h3 class="text-primary">
                  {{ NoStudents }}
                </h3>
                <hr />
              </div>
            </div>
          </div>
          <div class="col pb-lg-0 draggable">
            <div class="{{ isMoveEnable ? 'MoveEnabled' : '' }}">
              <div
                class="WidgetmoveIcon"
                *ngIf="isMoveEnable"
              >
                <span
                  [data-feather]="'move'"
                  [class]="'mr-25'"
                ></span>
              </div>
              <div class="widget-body widget-warning">
                <h4 class="text-secondary"># OF PLACES</h4>
                <h3 class="text-primary">
                  {{ NoPlaces }}
                </h3>
                <hr />
              </div>
            </div>
          </div>
          <div class="col pb-lg-0 draggable">
            <div class="{{ isMoveEnable ? 'MoveEnabled' : '' }}">
              <div
                class="WidgetmoveIcon"
                *ngIf="isMoveEnable"
              >
                <span
                  [data-feather]="'move'"
                  [class]="'mr-25'"
                ></span>
              </div>
              <div class="widget-body widget-succes">
                <h4 class="text-secondary"># OF OUTBOUND BLUEPRINTS</h4>
                <h3 class="text-primary">
                  {{ NoOfOutboundBluerPrint }}
                </h3>
                <hr />
              </div>
            </div>
          </div>
          <div class="col pb-lg-0 draggable">
            <div class="{{ isMoveEnable ? 'MoveEnabled' : '' }}">
              <div
                class="WidgetmoveIcon"
                *ngIf="isMoveEnable"
              >
                <span
                  [data-feather]="'move'"
                  [class]="'mr-25'"
                ></span>
              </div>

              <div class="widget-body widget-light">
                <h4 class="text-secondary"># OF RETURN BLUEPRINTS</h4>
                <h3 class="text-primary">
                  {{ NoOfReturnBluePrint }}
                </h3>
                <hr />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
