import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Self,
} from "@angular/core";
import { ControlValueAccessor, NgControl, Validators } from "@angular/forms";

@Component({
  selector: "app-single-selector",
  templateUrl: "./single-selector.component.html",
  styleUrls: ["./single-selector.component.scss"],
})
export class SingleSelectorComponent implements OnInit, ControlValueAccessor {
  @Input() placeholder: string = "";
  @Input() data: [];
  @Input() clearable: boolean = false;
  @Input() name: string = "";
  @Input() bindLabel: string = "";
  @Input() bindValue: string = "";
  @Input() disabled: boolean = false;
  @Input() multiple: boolean = true;
  @Output() blur: EventEmitter<void> = new EventEmitter<void>();
  @Input() validationError: string = "";
  @Input("disable") disable: boolean = true;
  public disabeld: boolean = false;
  onChange: (value: any) => void = () => {
    console.log("changes selector");
  };
  @Input() OnChangeFunction: Function;
  onTouched: () => void = () => {};
  input: string;
  class: string = "";
  constructor(@Self() public controlDir: NgControl) {
    controlDir.valueAccessor = this;
  }

  ngOnInit(): void {
    this.disabeld = !this.disable;

    const control = this.controlDir.control;
    const validators = control?.validator
      ? [control.validator, Validators.required]
      : null;
    control?.setValidators(validators);
    control?.updateValueAndValidity();
  }

  writeValue(value: any): void {
    this.input = value;
    this.onChangeSelector(value);
  }

  registerOnChange(onChange: (value: any) => void): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: () => void): void {
    this.onTouched = onTouched;
  }
  setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
  }

  onChangeSelector(valueRequest: any) {
    let that = this;
    if (that.input == null) {
      that.input = "";
    }
    if (valueRequest != undefined) {
      that.input =
            valueRequest.id == undefined ? valueRequest.Id : valueRequest.id;
      that.onChange(that.input);

    } else {
      let value: string;
        that.input = value;
       that.onChange(that.input);

    }
  }
}
