import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { CoreMenu } from "../../../@core/types";
import { environment } from "../../../environments/environment";
import { PermissionsList } from "../../enums/Permissions/PermissionList";
import { SocialUser } from "../Interfaces/autentication/authentication.interface";
import { Role } from "../models";
import { User } from "../models/user";

@Injectable({ providedIn: "root" })
export class AuthenticationService {
    //public
    public currentUser: Observable<User>;
    //private
    private currentUserSubject: BehaviorSubject<User>;
    /**
     *
     * @param {HttpClient} _http
     */
    constructor(private _http: HttpClient) {
        this.currentUserSubject = new BehaviorSubject<User>(
            JSON.parse(localStorage.getItem("currentUser")),
        );
        this.currentUser = this.currentUserSubject.asObservable();
    }
    // getter: currentUserValue
    public get currentUserValue(): User {
        return this.currentUserSubject.value;
    }
    public get hasUserInfo(): boolean {
        return (
            this.currentUserSubject.value != null &&
            !window.location.href.includes("login")
        );
    }
    /**
     *  Confirms if user is admin
     */
    get isAdmin() {
        return (
            this.currentUser && this.currentUserSubject.value.role === Role.Admin
        );
    }
    /**
     *  Confirms if user is client
     */
    get isClient() {
        return (
            this.currentUser && this.currentUserSubject.value.role === Role.Client
        );
    }
    /**
     * User login
     *
     * @param email
     * @param password
     * @returns user
     */
    login(email: string, password: string) {
        return this._http.post<any>(`${environment.apiUrl}/auth/sign-in`, {
            email,
            password,
            api_client_name: "app-web-client",
        });
    }
    /**
     * User logout
     *
     */
    logout() {
        // remove user from local storage to log user out
        localStorage.removeItem("currentUser");
        if (environment.type != "Development") {
            $.ajax({
                type: "GET",
                url: environment.webUrl + "api/auth/sign-out", // the URL of the controller action method
                success: function (result) { },
                error: function (req, status, error) { },
            });
        }

        // notify
        this.currentUserSubject.next(null);
    }
    /**
     * check if user LoggedIn
     *
     */
    LoggedIn() {
        return !!localStorage.getItem("currentUser");
    }
    /**
     * check if user have access to a spacific page
     *
     */
    checkaccess(pagename: string): boolean {
        let userinfo = JSON.parse(localStorage.getItem("currentUser")) as User;
        if (userinfo?.Permissions.includes(PermissionsList[pagename])) return true;
        else return false;
    }

    checkparantaccess(permissionsLists: PermissionsList[]): boolean {
        let userinfo = JSON.parse(localStorage.getItem("currentUser")) as User;
        if (userinfo != null) {
            for (const element of userinfo.Permissions) {
                for (const element1 of permissionsLists) {
                    if (element == element1) {
                        return true;
                    }
                }
            }
        }
        return false;
    }

    getdefaultpage(array: CoreMenu[]): string {
        let userinfo = JSON.parse(localStorage.getItem("currentUser")) as User;
        if (userinfo != null) {
            for (const element of array) {
                for (const element1 of element.children) {
                    if (
                        userinfo.Permissions.includes(PermissionsList[element1.permission])
                    ) {
                        return "/" + element1.url;
                    }
                }
            }
        }

        return "/";
    }

    saveUserData(userinfo: User) {
        localStorage.setItem("currentUser", JSON.stringify(userinfo));
        this.currentUserSubject = new BehaviorSubject<User>(
            JSON.parse(localStorage.getItem("currentUser")),
        );
        this.currentUser = this.currentUserSubject.asObservable();
    }

    changePassWord(OldPassword, NewPassword) {
        let body = {
            OldPassword: OldPassword,
            NewPassword: NewPassword,
        };
        return this._http.patch<any>(
            `${environment.apiUrl}/accounts/me/change-password`,
            body,
        );
    }

    updateUserInfo(userinfo: User) {
        const body = {
            Id: userinfo.id,
            FirstName: userinfo.first_name,
            LastName: userinfo.last_name,
            Email: userinfo.email,
        };
        return this._http.put<any>(
            `${environment.apiUrl}/accounts/${userinfo.id}`,
            body,
        );
    }

    loginBySSO(body: SocialUser): Observable<any> {
        return this._http.post<any>(`${environment.apiUrl}/auth/customers-login-sso`, body,);
    }
}
