<div class="row section-div">
  <kendo-grid
    #mainGrid
    [data]="gridData"
    [pageSize]="state.take"
    [skip]="state.skip"
    [sort]="state.sort"
  >
    <ng-template kendoGridNoRecordsTemplate> No addresses. </ng-template>
    <ng-template
      kendoGridToolbarTemplate
      NgClass="p-0"
    >
    </ng-template>
    <kendo-grid-spacer></kendo-grid-spacer>
    <kendo-grid-column
      [width]="50"
      field="Index"
      [sortable]="true"
      title="#"
    >
      <ng-template
        kendoGridCellTemplate
        let-rowIndex="rowIndex+1"
      >
        {{ rowIndex }}
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column
      field="Nickname"
      title="Nickname"
      [sortable]="false"
      [width]="220"
    >
      <ng-template
        kendoGridCellTemplate
        let-dataItem
        let-rowIndex="rowIndex"
      >
        {{ dataItem.Name }}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="Address"
      title="Address"
      [sortable]="false"
      [width]="340"
    >
      <ng-template
        kendoGridCellTemplate
        let-dataItem
        let-rowIndex="rowIndex"
      >
        <a
          class="btn btn-link pl-0 pr-0"
          (click)="modalOpenMD('Edit', dataItem, rowIndex)"
        >
          {{ dataItem.Location.Street1 }},{{
            dataItem.Location.TimezoneOffsetInSecond
          }},{{ dataItem.Location.City }},{{ dataItem.Location.State }}
        </a>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      [sortable]="false"
      title="Action"
      [width]="90"
    >
      <ng-template
        kendoGridCellTemplate
        let-dataItem
        let-rowIndex="rowIndex"
      >
        <div class="d-flex align-items-center">
          <div
            ngbDropdown
            [placement]="'left-top'"
            container="body"
            class="m-auto"
          >
            <a
              ngbDropdownToggle
              href="javascript:void(0);"
              class="hide-arrow"
              id="dropdownBrowserState"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i
                data-feather="more-vertical"
                class="text-primary cursor-pointer mr-50"
              ></i>
            </a>
            <div
              ngbDropdownMenu
              class="dropdown-menu-right"
              aria-labelledby="dropdownBrowserState"
              disabled
            >
              <a
                ngbDropdownItem
                class="d-flex align-items-center"
                (click)="modalOpenMD('Edit', dataItem, rowIndex)"
              >
                Edit
              </a>
              <div class="dropdown-divider"></div>
              <a
                href="javascript:void(0)"
                (click)="openDeleteAddress(dataItem.Location.LocationId)"
                ngbDropdownItem
                class="d-flex align-items-center"
              >
                Delete
              </a>
            </div>
          </div>
        </div>
      </ng-template>
    </kendo-grid-column>
  </kendo-grid>
  <div class="col-lg-3">
    <button
      type="button"
      rippleeffect=""
      class="btn btn-close waves-effect waves-float waves-light"
      style="border-color: #1877f2; margin-bottom: 20px; color: #1877f2"
      (click)="modalOpenMD('Add')"
    >
      Add Address
    </button>
  </div>
</div>
<app-gurdians-add-new-home-address
  [gridData]="gridData"
  #studentaddHomeAddressComponent
  (Added)="reloadData($event)"
></app-gurdians-add-new-home-address>

<kendo-dialog
  title=""
  *ngIf="openDeletePopup"
  (close)="close('block')"
  [minWidth]="250"
  [width]="400"
>
  <p style="text-align: center">
    Are you sure you want to delete the address information?
  </p>
  <kendo-dialog-titlebar>
    <div style="font-size: 18px; line-height: 1.3em; font-weight: 500">
      <span
        class="k-icon k-i-info-circle mr-1"
        style="color: #eea236; font-size: 26px; margin-top: -3px"
      ></span>
      Block Confirmation
    </div>
  </kendo-dialog-titlebar>

  <kendo-dialog-actions>
    <p style="text-align: center">
      <button
        type="button"
        (click)="close('block')"
        rippleeffect=""
        class="btn btn-default waves-effect waves-float waves-light"
        style="
          background-color: #acb7bf;
          border-color: #acb7bf;
          color: white;
          margin-right: 10px;
        "
      >
        No
      </button>
      <button
        type="button"
        (click)="removeFromStudent()"
        rippleeffect=""
        class="btn btn-danger waves-effect waves-float waves-light"
      >
        Yes, delete it.
      </button>
    </p>
  </kendo-dialog-actions>
</kendo-dialog>
