import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Self,
} from "@angular/core";
import { ControlValueAccessor, NgControl, Validators } from "@angular/forms";

@Component({
  selector: "app-multi-selector",
  templateUrl: "./multi-selector.component.html",
  styleUrls: ["./multi-selector.component.scss"],
})
export class MultiSelectorComponent implements OnInit, ControlValueAccessor {
  @Input() placeholder: string = "";
  @Input() data: any = [];
  @Input() clearable: boolean = false;
  @Input() name: string = "";
  @Input() bindLabel: string = "";
  @Input() bindValue: string = "";
  @Input() disabled: boolean = false;
  @Input() multiple: boolean = true;
  @Output() blur: EventEmitter<void> = new EventEmitter<void>();
  @Input() validationError: string = "";
  @Input("disable") disable: boolean = true;
  public disabeld: boolean = false;
  onChange: (value: any) => void = () => {};
  @Input() OnChangeFunction: Function;
  onTouched: () => void = () => {};
  input: any;
  class: string = "";
  constructor(@Self() public controlDir: NgControl) {
    controlDir.valueAccessor = this;
  }

  ngOnInit(): void {
    this.disabeld = !this.disable;
    const control = this.controlDir.control;
    const validators = control?.validator
      ? [control.validator, Validators.required]
      : null;
    control?.setValidators(validators);
    control?.updateValueAndValidity();
  }

  writeValue(value: any): void {
    if (value != null && value.length > 0) {
      this.input = [];
      value.forEach(a => {
        this.onChangeSelector(a, "add");
      });
    }
  }
  registerOnChange(onChange: (value: any) => void): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: () => void): void {
    this.onTouched = onTouched;
  }

  setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
  }

    onChangeSelector(valueRequest: any, eventType) {
        let that = this;
        if (that.input == null) {
            that.input = [];
        }
        if (eventType == "add") {
            that.input.push(valueRequest.Id);
            that.onChange(that.input);
        } else {
            let index2 = that.input.findIndex(a => a == valueRequest.value.Id);
            that.input.splice(index2, 1);
            if (that.input.length == 0) {
                that.input = null;
            }
             that.onChange(that.input);
        }
    }
}
