export class UploadFileModel {
  parentId: number;
  name: string;
  file: string;
  type: string;
  extension: string;
  folder: string;
  entityType: string;
}
export class StudentFileUploadModel {
  FileId: number;
  CdnFileName: string;
  CdnFileUrl: string;
  CdnFileId: string;
  Extension: string;
  Name: string;
  ParentId: number;
  PartnerId: number = 0;
  TypeName: string = "Student Attachment";
}
