<div
  *ngIf="Config.filterVisability"
  class="form-group"
>
  <ng-select
    appendTo="body"
    [items]="Config.filterValues"
    [clearable]="Config.filterClear"
    [disabled]="Config.filterDisabled"
    [multiple]="Config.filterMultiple"
    [placeholder]="Config.filterPlaceholder"
    [(ngModel)]="Config.filterValue"
    bindLabel="name"
    bindValue="name"
  >
    <ng-template
      ng-option-tmp
      ng-label-tmp
      let-item="item"
    >
      {{ item.name }}
      <br />
      <small class="text-secondary">{{ item.date }}</small>
    </ng-template>
  </ng-select>
</div>
