<div
  id="actionBar"
  class="d-flex align-items-end bg-white"
>
  <div class="container-fluid">
    <div class="d-flex w-100 actionBar-content align-items-end">
      <div class="flex-fill">
        <div class="sub-title pb-0">Guardians App Users</div>
        <button
          type="button"
          class="btn btn-info btn-sm"
          style="margin-top: 10px"
          rippleEffect
          (click)="modalOpenMD()"
        >
          Add New Guardian
        </button>
      </div>
      <div class="flex-fill text-right">
        <div class="btn-group">
          <div
            ngbDropdown
            [placement]="'bottom-right'"
          >
            <button
              ngbDropdownToggle
              id="dropdownSettings"
              class="btn-icon btn btn-info btn-round btn-sm btn-page-action"
              type="button"
              data-automation-id="dropdown_btn"
              rippleEffect
            >
              <i
                class="fa fa-bars"
                aria-hidden="true"
              ></i>
            </button>
            <div
              ngbDropdownMenu
              aria-labelledby="dropdownSettings"
            >
              <a
                ngbDropdownItem
                href="javascript:window.print();"
                data-automation-id="print_btn"
                ><span
                  [data-feather]="'printer'"
                  [class]="'mr-50'"
                ></span>
                Print</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<br />

<div class="content-wrapper container-fluid">
  <div class="content-body">
    <div class="card">
      <app-collection-filters
        (search)="getFilterData()"
        (clearSearch)="clearAllData()"
        [filters]="FiltersConfig"
      ></app-collection-filters>
      <div class="card-body">
        <kendo-grid
          #mainGrid
          [data]="gridData"
          [pageSize]="state.take"
          [skip]="state.skip"
          [sortable]="true"
          [sort]="state.sort"
          [filter]="state.filter"
          [pageable]="false"
          (sortChange)="sortChange($event)"
        >
          <ng-template
            kendoGridToolbarTemplate
            NgClass="p-0"
          >
            <div class="col-lg-12 toolbar-control">
              <div class="row">
                <div class="col-md-6 col-12 pb-0">
                  <div
                    class="d-flex justify-content-between align-items-center"
                  >
                    <label class="d-flex align-items-center">
                      Show
                      <select
                        (change)="changeGridIndex()"
                        [(ngModel)]="gridIndex"
                        class="form-control mx-25 form-control-sm ng-pristine ng-valid ng-touched"
                      >
                        <option value="5">5</option>
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                      </select>
                      entries
                    </label>
                  </div>
                </div>
                <div
                  class="col-md-6 col-12 d-flex justify-content-md-end pb-0 pr-0"
                >
                  <label class="d-flex align-items-center mr-0">
                    Search
                    <input
                      [style.width.px]="165"
                      class="form-control form-control-sm ml-25"
                      placeholder="Search"
                      (input)="onFilter($event)"
                    />
                  </label>
                </div>
              </div>
            </div>
            <kendo-grid-spacer></kendo-grid-spacer>
          </ng-template>

          <kendo-grid-spacer></kendo-grid-spacer>

          <kendo-grid-column
            [width]="50"
            field="Index"
            [sortable]="false"
            title="#"
          >
            <ng-template
              kendoGridCellTemplate
              let-rowIndex="rowIndex+1"
            >
              {{ rowIndex }}
            </ng-template>
          </kendo-grid-column>

          <kendo-grid-column
            field="Name"
            title="Name"
            [sortable]="true"
            [width]="250"
            (sortChange)="sortChange($event)"
          >
            <ng-template
              kendoGridCellTemplate
              let-dataItem
              let-rowIndex="rowIndex"
            >
              <a
                class="btn btn-link pl-0 pr-0"
                href="client-gurdians/gurdians/edit/{{ dataItem.Id }}"
              >
                {{ dataItem.FirstName }} {{ dataItem.LastName }}
              </a>
            </ng-template>
          </kendo-grid-column>

          <kendo-grid-column
            field="MobileNumber"
            title="Mobile Number"
            [sortable]="false"
          >
            <ng-template
              kendoGridCellTemplate
              let-dataItem
              let-rowIndex="rowIndex"
            >
              {{ getMobileNumber(dataItem.MobileNumber) }}
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-column
            field="Email"
            title="Email"
            [sortable]="false"
          >
            <ng-template
              kendoGridCellTemplate
              let-dataItem
              let-rowIndex="rowIndex"
              ><span> {{ getEmail(dataItem.Email) }}</span>
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-column
            field="GuardianOf"
            title="Guardian Of"
            [sortable]="false"
          >
            <ng-template
              kendoGridCellTemplate
              let-dataItem
              let-rowIndex="rowIndex"
            >
              <a
                class="btn btn-link pl-0 pr-0"
                href="customer-success/studentsEdit/{{ dataItem.Id }}"
              >
                {{ dataItem.GuardianOf }}
              </a>
            </ng-template>
          </kendo-grid-column>

          <kendo-grid-column
            field="Status"
            title="Status"
            [sortable]="false"
          >
            <ng-template
              kendoGridCellTemplate
              let-dataItem
              let-rowIndex="rowIndex"
            >
              <span [class]="getstatusColor(dataItem)">
                {{ dataItem.Status }}
              </span>
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-column
            [sortable]="false"
            title="Action"
            [width]="90"
          >
            <ng-template
              kendoGridCellTemplate
              let-dataItem
              let-rowIndex="rowIndex"
            >
              <div class="d-flex align-items-center">
                <div
                  ngbDropdown
                  [placement]="'left-top'"
                  container="body"
                  class="m-auto"
                >
                  <a
                    ngbDropdownToggle
                    href="javascript:void(0);"
                    class="hide-arrow"
                    id="dropdownBrowserState"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <i
                      data-feather="more-vertical"
                      class="text-primary cursor-pointer mr-50"
                    ></i>
                  </a>
                  <div
                    ngbDropdownMenu
                    class="dropdown-menu-right"
                    aria-labelledby="dropdownBrowserState"
                  >
                    <a
                      href="client-gurdians/gurdians/edit/{{ dataItem.Id }}"
                      ngbDropdownItem
                      class="d-flex align-items-center"
                    >
                      Edit
                    </a>
                    <ng-container *ngIf="!dataItem.IsArchived">
                      <a
                        href="javascript:void(0)"
                        (click)="
                          blockGuardian(
                            dataItem.Id,
                            dataItem.FirstName,
                            dataItem.LastName,
                            dataItem.IsBlocked
                          )
                        "
                        ngbDropdownItem
                        class="d-flex align-items-center"
                      >
                        {{ !dataItem.IsBlocked ? "Block" : "Unblock" }}
                      </a>
                    </ng-container>
                    <ng-container *ngIf="dataItem.IsArchived">
                      <a
                        href="javascript:void(0)"
                        (click)="
                          blockGuardian(
                            dataItem.Id,
                            dataItem.FirstName,
                            dataItem.LastName,
                            dataItem.IsBlocked
                          )
                        "
                        ngbDropdownItem
                        class="d-flex align-items-center disabled"
                      >
                        {{ !dataItem.IsBlocked ? "Block" : "Unblock" }}
                      </a>
                    </ng-container>
                    <a
                      href="javascript:void(0)"
                      (click)="
                        archiveGuardian(
                          dataItem.Id,
                          dataItem.FirstName,
                          dataItem.LastName,
                          dataItem.IsArchived,
                          dataItem.GuardianOf
                        )
                      "
                      ngbDropdownItem
                      class="d-flex align-items-center"
                    >
                      {{ !dataItem.IsArchived ? "Archive" : "Unarchive" }}
                    </a>

                    <div class="dropdown-divider"></div>
                    <a
                      href="javascript:void(0)"
                      (click)="
                        deleteGuardian(
                          dataItem.Id,
                          dataItem.FirstName,
                          dataItem.LastName
                        )
                      "
                      ngbDropdownItem
                      class="d-flex align-items-center"
                    >
                      Delete
                    </a>
                  </div>
                </div>
              </div>
            </ng-template>
          </kendo-grid-column>
        </kendo-grid>

        <div class="col-lg-12 text-center Load-More-section">
          <button
            [disabled]="disableLoadMore"
            class="btn btn-light btn-Load-More btn-sm"
            (click)="viewMore()"
          >
            load more
          </button>
          <div class="page-count ng-star-inserted">
            {{
              basicSelectedOption >= gridData.length
                ? gridData.length
                : basicSelectedOption
            }}
            of {{ basicSelectedOption }} record
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Attention Blocks -->
  <kendo-dialog
    title=""
    *ngIf="opendbolck"
    (close)="close('block')"
    [minWidth]="250"
    [width]="400"
  >
    <p style="text-align: center">
      Are you sure you want to block guardian app user account for
      {{ GurdianName }} ?
    </p>
    <kendo-dialog-titlebar>
      <div style="font-size: 18px; line-height: 1.3em; font-weight: 500">
        <span
          class="k-icon k-i-info-circle mr-1"
          style="color: #eea236; font-size: 26px; margin-top: -3px"
        ></span>
        Block Confirmation
      </div>
    </kendo-dialog-titlebar>

    <kendo-dialog-actions>
      <p style="text-align: center">
        <button
          type="button"
          (click)="close('block')"
          rippleeffect=""
          class="btn btn-default waves-effect waves-float waves-light"
          style="
            background-color: #acb7bf;
            border-color: #acb7bf;
            color: white;
            margin-right: 10px;
          "
        >
          No
        </button>
        <button
          type="button"
          (click)="ConfirmBlockGuardian()"
          rippleeffect=""
          class="btn btn-danger waves-effect waves-float waves-light"
        >
          Yes
        </button>
      </p>
    </kendo-dialog-actions>
  </kendo-dialog>
  <kendo-dialog
    title=""
    *ngIf="openNotBlock"
    (close)="close('notblocked')"
    [minWidth]="250"
    [width]="400"
  >
    <p style="text-align: center">
      Are you sure you want to unblock guardian app user account for
      {{ GurdianName }} ?
    </p>
    <kendo-dialog-titlebar>
      <div style="font-size: 18px; line-height: 1.3em; font-weight: 500">
        <span
          class="k-icon k-i-info-circle mr-1"
          style="color: #eea236; font-size: 26px; margin-top: -3px"
        ></span>
        Unblock Confirmation
      </div>
    </kendo-dialog-titlebar>

    <kendo-dialog-actions>
      <p style="text-align: center">
        <button
          type="button"
          (click)="close('notblocked')"
          rippleeffect=""
          class="btn btn-default waves-effect waves-float waves-light"
          style="
            background-color: #acb7bf;
            border-color: #acb7bf;
            color: white;
            margin-right: 10px;
          "
        >
          No
        </button>
        <button
          type="button"
          (click)="ConfirmBlockGuardian()"
          rippleeffect=""
          class="btn btn-danger waves-effect waves-float waves-light"
        >
          Yes
        </button>
      </p>
    </kendo-dialog-actions>
  </kendo-dialog>

  <kendo-dialog
    title=""
    *ngIf="openedArchive"
    (close)="close('archived')"
    [minWidth]="250"
    [width]="400"
  >
    <p style="text-align: center">
      Are you sure you want to archive the guardian app user account for
      {{ GurdianName }} ?
    </p>
    <kendo-dialog-titlebar>
      <div style="font-size: 18px; line-height: 1.3em; font-weight: 500">
        <span
          class="k-icon k-i-info-circle mr-1"
          style="color: #eea236; font-size: 26px; margin-top: -3px"
        ></span>
        Archive Confirmation
      </div>
    </kendo-dialog-titlebar>

    <kendo-dialog-actions>
      <p style="text-align: center">
        <button
          type="button"
          (click)="close('archived')"
          rippleeffect=""
          class="btn btn-default waves-effect waves-float waves-light"
          style="
            background-color: #acb7bf;
            border-color: #acb7bf;
            color: white;
            margin-right: 10px;
          "
        >
          No
        </button>
        <button
          type="button"
          (click)="ConfirmArchiveGuardian()"
          rippleeffect=""
          class="btn btn-danger waves-effect waves-float waves-light"
        >
          Yes
        </button>
      </p>
    </kendo-dialog-actions>
  </kendo-dialog>
  <kendo-dialog
    title=""
    *ngIf="openNotArchive"
    (close)="close('notArchived')"
    [minWidth]="250"
    [width]="400"
  >
    <p style="text-align: center">
      Are you sure you want to unarchive the guardian app user account for
      {{ GurdianName }} ?
    </p>
    <kendo-dialog-titlebar>
      <div style="font-size: 18px; line-height: 1.3em; font-weight: 500">
        <span
          class="k-icon k-i-info-circle mr-1"
          style="color: #eea236; font-size: 26px; margin-top: -3px"
        ></span>
        Unarchive Confirmation
      </div>
    </kendo-dialog-titlebar>

    <kendo-dialog-actions>
      <p style="text-align: center">
        <button
          type="button"
          (click)="close('notArchived')"
          rippleeffect=""
          class="btn btn-default waves-effect waves-float waves-light"
          style="
            background-color: #acb7bf;
            border-color: #acb7bf;
            color: white;
            margin-right: 10px;
          "
        >
          No
        </button>
        <button
          type="button"
          (click)="ConfirmArchiveGuardian()"
          rippleeffect=""
          class="btn btn-danger waves-effect waves-float waves-light"
        >
          Yes
        </button>
      </p>
    </kendo-dialog-actions>
  </kendo-dialog>
  <kendo-dialog
    title=""
    *ngIf="openedDelete"
    (close)="close('deleted')"
    [minWidth]="250"
    [width]="400"
  >
    <p style="text-align: center">
      Are you sure you want to delete the guardian app user account for
      {{ GurdianName }} ?
    </p>
    <kendo-dialog-titlebar>
      <div style="font-size: 18px; line-height: 1.3em; font-weight: 500">
        <span
          class="k-icon k-i-info-circle mr-1"
          style="color: #eea236; font-size: 26px; margin-top: -3px"
        ></span>
        Delete Confirmation
      </div>
    </kendo-dialog-titlebar>

    <kendo-dialog-actions>
      <p style="text-align: center">
        <button
          type="button"
          (click)="close('deleted')"
          rippleeffect=""
          class="btn btn-default waves-effect waves-float waves-light"
          style="
            background-color: #acb7bf;
            border-color: #acb7bf;
            color: white;
            margin-right: 10px;
          "
        >
          No
        </button>
        <button
          type="button"
          (click)="ConfirmDeleteGuardian()"
          rippleeffect=""
          class="btn btn-danger waves-effect waves-float waves-light"
        >
          Yes
        </button>
      </p>
    </kendo-dialog-actions>
  </kendo-dialog>
  <kendo-dialog
    title=""
    *ngIf="opendDeniedArchvied"
    (close)="close('deniedArchived')"
    [minWidth]="250"
    [width]="400"
  >
    <p style="text-align: center">
      This Gurdian cannot be Unrchived. At least one active student should be
      linked with this gurdian.
    </p>
    <kendo-dialog-titlebar>
      <div style="font-size: 18px; line-height: 1.3em; font-weight: 500">
        <span
          class="k-icon k-i-info-circle mr-1"
          style="color: #eea236; font-size: 26px; margin-top: -3px"
        ></span>
        Unarchive Denied
      </div>
    </kendo-dialog-titlebar>

    <kendo-dialog-actions>
      <p style="text-align: center">
        <button
          type="button"
          (click)="close('deniedArchived')"
          rippleeffect=""
          class="btn btn-default waves-effect waves-float waves-light"
          style="
            background-color: #acb7bf;
            border-color: #acb7bf;
            color: white;
            margin-right: 10px;
          "
        >
          Ok, dismiss.
        </button>
      </p>
    </kendo-dialog-actions>
  </kendo-dialog>
  <!-- Attention Blocks -->
</div>

<!-- Modal Add New Gurdian -->
<app-add-new-guardian
  #addNewGuardianComponent
  (Added)="reloadData()"
></app-add-new-guardian>
<!-- / Modal -->
