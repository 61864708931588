import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { IFilters } from "../interfaces/controls/IFiltersConfig";
import { FlatpickrOptions } from "ng2-flatpickr";
import { FlatpickrInstance } from "ng2-flatpickr/src/flatpickr-instance";
import { FormatSettings } from "@progress/kendo-angular-dateinputs";

@Component({
  selector: "app-to-date-picker",
  templateUrl: "./to-date-picker.component.html",
  styleUrls: ["./to-date-picker.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class ToDatePickerComponent implements OnInit {
  @Input("filterData") Config: IFilters;
  @Output() changeValue = new EventEmitter();
  @ViewChild("ToDate") ToDate: FlatpickrInstance;

  public ToDateOptions: FlatpickrOptions = {
    altInput: true,
    weekNumbers: true,
    locale: {
      firstDayOfWeek: 1, // start week on Monday
    },
  };
  constructor(private cd: ChangeDetectorRef) {}

  public format: FormatSettings = {
    displayFormat: "MM/dd/yyyy",
    inputFormat: "MM/dd/yyyy",
  };
  ngOnInit(): void {
    this.cd.detectChanges();
  }
  onchange() {
    this.changeValue.emit();
  }

  clear() {
    (<any>this.ToDate).flatpickr.clear();
    this.Config.filterValue = null;
  }
  public isCenturyMeeting(date: Date) {
    return date.getDate() % 20 ? "meeting" : "";
  }
}
