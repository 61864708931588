import { AccountDataModel } from "./AccountDataModel";

export class GuardianDataModel {
  Id: number;

  StudentId: number;
  AccountId: number;
  RelationshipToStudent: string;
  Account: AccountDataModel;
  CreatedByAccountId: number;
  isClientPortal: boolean;
}
