export class HistoricalRidesFilterModel {
  isFromClient: boolean = true;
  fromServiceDate: Date = null;
  toServiceDate: Date = null;
  studentId: number = 0;
  partnerId: number = 0;
  period: string = "";
  rideStatusId: string = "";
  rideType: string = "";
  clientId: number = 0;
  placeId: number = 0;
  attendantId: number = 0;
  attendantType = "";
  limit: number = 25;
  offset: number = 0;
  sort_by: string = "dateofservice";
  order_by: string = "asc";
  rideId: number = 0;
  rideBlueprintId: number = 0;
  organizationId: number = 0;
  isInvoiceable: boolean = null;
  isServiceProviderPortal: boolean = null;
}
