<ng-template
  #AddNewAttachmentModal
  let-modal
>
  <div class="modal-header">
    <h5
      class="modal-title"
      id="myModalLabel160"
    >
      {{ PageTitle }}
    </h5>

    <button
      type="button"
      class="close"
      (click)="close(modal)"
      aria-label="Close"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div
    class="modal-body"
    tabindex="0"
    style="padding: 14px"
    ngbAutofocus
  >
    <h4 class="text-black"><strong>Basic Details</strong></h4>
    <form
      [formGroup]="newAttachmentForm"
      (ngSubmit)="onSubmit(modal)"
    >
      <div class="row">
        <div class="col-lg-12">
          <label>File Name</label>
          <app-required-field
            Id="fileName"
            type="text"
            formControlName="fileName"
            placeholder="File Name"
            [disabledd]="PermessionValue"
          ></app-required-field>
        </div>
        <div class="col-lg-12 separate"></div>
        <div class="col-lg-12 separate"></div>
        <div class="col-lg-12">
          <h5 class="text-black"><strong>File Upload</strong></h5>
        </div>
        <div class="col-lg-12">
          <a
            class="cui-utils-link-underlined cui-utils-link-blue"
            [hidden]="data == null"
            (click)="generateGoogleCloudViewUrl(data?.CdnFileName)"
            id="fileURL"
            >View File</a
          >
          <kendo-upload
            #myUpload="kendoUpload"
            accesskey=""
            [hidden]="data != null"
            [autoUpload]="true"
            [multiple]="false"
            [saveUrl]="uploadSaveUrl"
            [removeUrl]="uploadRemoveUrl"
            formControlName="file"
            [restrictions]="myRestrictions"
          >
            <kendo-upload-messages
              [dropFilesHere]="''"
              select="Upload File"
            >
            </kendo-upload-messages>
          </kendo-upload>
        </div>
      </div>
    </form>
  </div>

  <div class="modal-footer about-adroit-footer">
    <button
      type="button"
      (click)="close(modal)"
      rippleeffect=""
      class="btn btn-close waves-effect waves-float waves-light"
      style="border-color: #1877f2; margin-bottom: 20px; color: #1877f2"
    >
      Close
    </button>
    <button
      type="submit"
      [disabled]="newAttachmentForm.invalid"
      class="btn btn-info"
      (click)="onSubmit(modal)"
      style="margin-bottom: 20px; margin-right: 20px"
      [hidden]="!disabled"
      rippleEffect
    >
      Submit
    </button>
  </div>
</ng-template>
