import {
  Component,
  ComponentFactoryResolver,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AuthLoginV1Component } from "../../pages/authentication/auth-login-v1/auth-login-v1.component";
import { HomeService } from "../home.service";

@Component({
  selector: "app-client-dashboard-statistics",
  templateUrl: "./client-dashboard-statistics.component.html",
})
export class ClientDashboardStatisticsComponent implements OnInit {
  public NoStudents = 0;
  public NoPlaces = 0;
  public NoOfOutboundBluerPrint = 0;
  public NoOfReturnBluePrint = 0;
  public contractLogForm: FormGroup;
  public isMoveEnable = false;
  public CreatedAtUtc = new Date().toUTCString();

  componentRef;
  @ViewChild("login", { read: ViewContainerRef, static: true })
  loginComponent: ViewContainerRef;

  getStatisticsData() {
    this._homeService.getStatistics().subscribe(response => {
      this.NoStudents = Number(response.StudentsNumber);
      this.NoPlaces = Number(response.PlacesNumber);
      this.NoOfOutboundBluerPrint = Number(response.OutboundBlueprints);
      this.NoOfReturnBluePrint = Number(response.ReturnBlueprints);
    });
  }

  constructor(
    private _homeService: HomeService,
    private _formBuilder: FormBuilder,
    private resolver: ComponentFactoryResolver,
  ) {}

  ngOnInit(): void {
    this.getStatisticsData();
    this.initFormBuilder();
  }

  public initFormBuilder() {
    let that = this;
    that.contractLogForm = this._formBuilder.group({
      InternalNote: ["", [Validators.required]],
      text: ["", [Validators.required]],
    });
  }

  public createComponent() {
    let that = this;
    let requiredFieldCompFactory =
      that.resolver.resolveComponentFactory(AuthLoginV1Component);
    that.componentRef = that.loginComponent.createComponent(
      requiredFieldCompFactory,
    );
    that.componentRef.instance.submitted = true;
  }
}
