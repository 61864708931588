import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouterModule, Routes } from "@angular/router";

import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule } from "@ngx-translate/core";
import "hammerjs";
import { ToastrModule } from "ngx-toastr"; // For auth after login toast

import { CoreCommonModule } from "@core/common.module";
import { CoreSidebarModule, CoreThemeCustomizerModule } from "@core/components";
import { CoreModule } from "@core/core.module";

import { coreConfig } from "app/app-config";

import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { DatePickerModule } from "@progress/kendo-angular-dateinputs";
import { AppComponent } from "app/app.component";
import { LayoutModule } from "app/layout/layout.module";
import { ActiveRidesModule } from "app/main/ActiveRides/ActiveRides.module";
import { DashboardModule } from "app/main/Dashboard/dashboard.module";
import { HistoricalRidesModule } from "app/main/HistoricalRides/HistoricalRides.module";
import { InvoicesModule } from "app/main/Invoices/Invoices.module";
import { RideBlueprintsModule } from "app/main/RideBlueprints/RideBlueprints.module";
import { SafePipeModule } from "safe-pipe";
import { AuthGuard, JwtInterceptor } from "./auth/helpers";
import { GuardiansModule } from "./main/Guardians/Guardians.module";
import { HistoricalRidesServices } from "./main/HistoricalRides/services/historical-rides.services";

import { GoogleMapsModule } from "@angular/google-maps";
import { UploadModule } from "@progress/kendo-angular-upload";
import { GoogleLoginProvider, MicrosoftLoginProvider, SocialAuthServiceConfig, SocialLoginModule } from 'angularx-social-login';
import { environment } from "../environments/environment";
import { PhotoModule } from "./controls/component/photo/personal-photo/photo.module";
import { ConsecutiveNoShowReportModule } from "./main/ConsecutiveNoShowReport/consecutive-no-show-report.module";
import { NoShowReportsModule } from "./main/NoShowReports/no-show-report.module";
import { PlacesModule } from "./main/Places/Place.module";
import { StudentsModule } from "./main/Students/Students.module";
import { UploadInterceptor } from "./utilities/interceptores/upload.interceptor";

const appRoutes: Routes = [
    {
        path: "pages",
        loadChildren: () =>
            import("./main/pages/pages.module").then(m => m.PagesModule),
    },
    {
        path: "Profile",
        loadChildren: () =>
            import("./main/Profile/Profile.module").then(m => m.ProfileModule),
        canActivate: [AuthGuard],
    },
    {
        path: "",
        redirectTo: "/home",
        pathMatch: "full",
    },
    {
        path: "",
        redirectTo: "/pages/authentication/login",
        pathMatch: "full",
    },
    {
        path: "**",
        redirectTo: "/pages/miscellaneous/error", //Error 404 - Page not found
    },
];

@NgModule({
    declarations: [AppComponent],
    providers: [
        HistoricalRidesServices,
        AuthGuard,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: JwtInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: UploadInterceptor,
            multi: true,
        },
        {
            provide: 'SocialAuthServiceConfig',
            useValue: {
                autoLogin: false,
                providers: [
                    {
                        id: MicrosoftLoginProvider.PROVIDER_ID,
                        provider: new MicrosoftLoginProvider(environment.microsoft_client_id),
                    },
                ],
                onError: err => {
                    console.error(err);
                },
            } as SocialAuthServiceConfig,
        }
    ],
    imports: [
        FormsModule,
        ReactiveFormsModule,
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        RouterModule.forRoot(appRoutes, {
            scrollPositionRestoration: "enabled", // Add options right here
            relativeLinkResolution: "legacy",
        }),
        TranslateModule.forRoot(),
        //NgBootstrap
        NgbModule,
        ToastrModule.forRoot(),

        // Core modules
        CoreModule.forRoot(coreConfig),
        CoreCommonModule,
        CoreSidebarModule,
        CoreThemeCustomizerModule,

        // App modules
        LayoutModule,
        DashboardModule,
        InvoicesModule,
        RideBlueprintsModule,
        ActiveRidesModule,
        HistoricalRidesModule,
        DatePickerModule,
        GuardiansModule,
        GoogleMapsModule,
        NoShowReportsModule,
        StudentsModule,
        UploadModule,
        PhotoModule,

        ConsecutiveNoShowReportModule,
        PlacesModule,
        SafePipeModule,
        SocialLoginModule,
        /*TooltipsModule*/
    ],

    bootstrap: [AppComponent],
})
export class AppModule { }
