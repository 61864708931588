import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { CoreCommonModule } from "@core/common.module";
import { ContentHeaderModule } from "app/layout/components/content-header/content-header.module";
import { ClientInvoicesComponent } from "./ClientInvoices/client-invoices.component";
import { BrowserModule } from "@angular/platform-browser";
import { DataTablesModule } from "angular-datatables";
import { QuillModule } from "ngx-quill";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { NgSelectModule } from "@ng-select/ng-select";
import { OwlDateTimeModule, OwlNativeDateTimeModule } from "ng-pick-datetime";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import { CsvModule } from "@ctrl/ngx-csv";
import { GridModule } from "@progress/kendo-angular-grid";
import { PipesModule } from "../../utilities/customPipes/customPipesModule";
import { InvoiceDetailService } from "./services/invoice-detail.service";
import { InvoiceComponent } from "./Invoice/invoice.component";
import { InvoiceDetailComponent } from "./InvoiceDetails/invoice-detail.component";
import { AuthGuard } from "app/auth/helpers/auth.guards";
import { AuthorizationGuard } from "app/auth/helpers/authorization.guard";
import { PermissionsList } from "app/enums/Permissions/PermissionList";
import { CientInvoicesService } from "./services/client-invoices.service";
import { FiltersModule } from "app/controls/component/filters/filters.module";

const routes = [
  {
    path: "client-invoices/Invoices",
    component: ClientInvoicesComponent,
    data: {
      animation: "ClientInvoices",
      PermissionPageName: [
        PermissionsList[PermissionsList.ClientClientInvoicesView],
      ],
    },
    canActivate: [AuthGuard, AuthorizationGuard],
  },
  {
    path: "client-invoices/Invoices/Invoice/:id",
    component: InvoiceComponent,
    data: {
      animation: "Invoice",
      PermissionPageName: [
        PermissionsList[PermissionsList.ClientClientInvoicesView],
      ],
    },
    canActivate: [AuthGuard, AuthorizationGuard],
  },
  {
    path: "InvoiceDetail/:id",
    component: InvoiceDetailComponent,
    data: {
      animation: "InvoiceDetail",
      PermissionPageName: [
        PermissionsList[PermissionsList.ClientClientInvoicesView],
      ],
    },
    canActivate: [AuthGuard, AuthorizationGuard],
  },
];

@NgModule({
  declarations: [
    InvoiceComponent,
    ClientInvoicesComponent,
    InvoiceDetailComponent,
  ],
  exports: [InvoiceComponent, ClientInvoicesComponent, InvoiceDetailComponent],
  providers: [CientInvoicesService, InvoiceDetailService],
  imports: [
    RouterModule.forChild(routes),
    ContentHeaderModule,
    QuillModule.forRoot(),
    NgxDatatableModule.forRoot({
      messages: {
        emptyMessage: '<div class="text-center">No records available</div>', // Message to show when array is presented, but contains no values
        totalMessage: "record", // Footer total message
        selectedMessage: "selected", // Footer selected message
      },
    }),
    GridModule,
    TranslateModule,
    CoreCommonModule,
    NgbModule,
    BrowserModule,
    DataTablesModule,
    NgSelectModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    CsvModule,
    FiltersModule,
    PipesModule,
  ],
})
export class InvoicesModule {}
