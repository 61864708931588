import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { CoreCommonModule } from "@core/common.module";
import { ContentHeaderModule } from "app/layout/components/content-header/content-header.module";
import { BrowserModule } from "@angular/platform-browser";
import { DataTablesModule } from "angular-datatables";
import { QuillModule } from "ngx-quill";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { NgSelectModule } from "@ng-select/ng-select";
import { OwlDateTimeModule, OwlNativeDateTimeModule } from "ng-pick-datetime";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import { CsvModule } from "@ctrl/ngx-csv";
import { ExcelModule, GridModule } from "@progress/kendo-angular-grid";
import { FiltersModule } from "app/controls/component/filters/filters.module";
import { PermissionsList } from "app/enums/Permissions/PermissionList";
import { AuthGuard } from "app/auth/helpers";
import { AuthorizationGuard } from "app/auth/helpers/authorization.guard";
import { ConsecutiveNoShowReportService } from "./services/consecutive-no-show-report.services";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ButtonsModule } from "@progress/kendo-angular-buttons";
import { DateInputsModule } from "@progress/kendo-angular-dateinputs";
import { DialogsModule } from "@progress/kendo-angular-dialog";
import { DropDownsModule } from "@progress/kendo-angular-dropdowns";
import { IconsModule } from "@progress/kendo-angular-icons";
import { InputsModule } from "@progress/kendo-angular-inputs";
import { IntlModule } from "@progress/kendo-angular-intl";
import { LabelModule } from "@progress/kendo-angular-label";
import { LayoutModule } from "@progress/kendo-angular-layout";
import { StatisticsModule } from "app/controls/component/statistics/statistics/statistics.module";
import { ConsecutiveNoShowReportComponent } from "./ConsecutiveNoShowReport/consecutive-no-show-report.component";

const routes = [
  {
    path: "ConsecutiveNoShowReport/Index",
    component: ConsecutiveNoShowReportComponent,
    data: {
      animation: "ConsecutiveNoShowReport",
      PermissionPageName: [
        PermissionsList[PermissionsList.ClientConsecutiveNoShowReportView],
      ],
    },
    canActivate: [AuthGuard, AuthorizationGuard],
  },
];

@NgModule({
  declarations: [ConsecutiveNoShowReportComponent],
  exports: [ConsecutiveNoShowReportComponent],
  providers: [ConsecutiveNoShowReportService],
  imports: [
    BrowserAnimationsModule,
    ReactiveFormsModule,
    IntlModule,
    DateInputsModule,
    LayoutModule,
    FormsModule,
    IconsModule,
    InputsModule,
    LabelModule,
    DropDownsModule,
    GridModule,
    ButtonsModule,
    DialogsModule,
    RouterModule.forChild(routes),
    ContentHeaderModule,
    QuillModule.forRoot(),
    NgxDatatableModule.forRoot({
      messages: {
        emptyMessage: '<div class="text-center">No records available</div>', // Message to show when array is presented, but contains no values
        totalMessage: "total records",
        selectedMessage: "selected", // Footer selected message
      },
    }),
    TranslateModule,
    CoreCommonModule,
    NgbModule,
    BrowserModule,
    DataTablesModule,
    NgSelectModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    CsvModule,
    StatisticsModule,
    FiltersModule,
    ExcelModule,
  ],
})
export class ConsecutiveNoShowReportModule {}
