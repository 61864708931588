<div class="app-content content">
  <div class="content-overlay"></div>
  <div
    class="header-navbar-shadow"
    *ngIf="!coreConfig.layout.navbar.hidden"
  ></div>
  <div
    id="Page-content"
    (scroll)="onScroll()"
  >
    <div id="Main-content">
      <div
        [@zoomIn]="zoomIn(outlet)"
        [@fadeIn]="fadeIn(outlet)"
      >
        <router-outlet #outlet="outlet">
          <content></content>
        </router-outlet>
      </div>
    </div>
    <footer class="footer footer-light footer-static"></footer>
  </div>
</div>
