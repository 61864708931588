export class AddressStudentModel {
  LocationId: number;
  FullAddress?: string;
  Country?: string;
  City?: string;
  State?: string;
  County?: string;
  ZipCode?: string;
  Street1?: string;
  Street2?: string;
  Latitude?: string;
  Longitude?: string;
  CustomLatitude?: string;
  CustomLongitude?: string;
  TimeZoneId?: number;
}

export class HomeAddressStudentModel {
  ParentId: number;
  Name: string;
  Location: AddressStudentModel;
  Type: string;
  Id: number;
  RowId: number;
  IsAddedFromClientPortal: boolean;
  ExcludeAddress: boolean = false;
}
