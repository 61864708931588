import { Component, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import {
  DataBindingDirective,
  PageSizeItem,
} from "@progress/kendo-angular-grid";
import { SortDescriptor, State } from "@progress/kendo-data-query";
import { User } from "app/auth/models";
import { AuthenticationService } from "app/auth/service";
import moment from "moment";
import { AppComponent } from "app/app.component";
import { ToastrService } from "ngx-toastr";
import { FiltersTypes } from "app/enums/controls/filters/FiltersTypes";
import { CollectionFiltersComponent } from "app/controls/component/filters/collection-filters/collection-filter.component";
import { IFiltersConfig } from "app/controls/component/filters/interfaces/controls/IFiltersConfig";
import { ClientGuardiansService } from "../services/client-guardians.service";
import { ClientGurdiansFilterModel } from "../model/ClientGurdiansFilterModel";
import { AddNewGuardianComponent } from "../AddNewGuardian/add-new-guardian.component";
import { HelpersService } from "app/main/SharedServices/helpers.service";
@Component({
  selector: "app-client-guardians",
  templateUrl: "./client-guardians.component.html",
  styleUrls: ["./client-guardians.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class ClientGuardiansComponent implements OnInit {
  @ViewChild(DataBindingDirective) dataBinding: DataBindingDirective;
  @ViewChild("addNewGuardianComponent")
  addNewGuardianComponent: AddNewGuardianComponent;

  contentHeader: object;
  isFiltersEnable = false;
  pageSize = 25;
  gridIndex: number = 0;
  disableLoadMore: boolean = false;
  basicSelectedOption: number = 0;
  opened = false;
  opendSearch = false;
  skip: number = 0;
  CancelTransportationopened = false;
  gridData: any[] = [];
  gridView: any;
  wayPotintId: number;
  total: number = 0;
  state: State = {
    skip: 0,
    take: 25,
    group: [],
    filter: { filters: [], logic: "or" },
    sort: [
      {
        field: "name",
        dir: "asc",
      },
    ],
  };
  pagesizes: PageSizeItem[] = [
    { text: "50", value: 50 },
    { text: "100", value: 100 },
    { text: "250", value: 250 },
    { text: "500", value: 500 },
    { text: "1000", value: 1000 },
    { text: "All", value: -1 },
  ];
  public _currentUser: User;
  public FiltersConfig: IFiltersConfig = {
    isFiltersEnable: false,
    Filters: [
      {
        filterType: FiltersTypes.Passengers,
        filterPlaceholder: "Filter by Student",
        filterClear: true,
        filterVisability: true,
      },
      {
        filterType: FiltersTypes.GurdiansStatus,
        filterPlaceholder: "Select by status",
        filterClear: true,
        filterVisability: true,
      },
    ],
  };
  @ViewChild(CollectionFiltersComponent)
  collectionOfFiltersComponent: CollectionFiltersComponent;
  /**
   * Constructor
   * * @param {AuthenticationService} _authenticationService
   * * @param {HelpersService} _helpersService
   * * @param {ClientGuardiansService} _clientGurdiansService
   * * @param {AppComponent} _appComponent
   * * @param {ToastrService} _toastrService
   */
  constructor(
    private _authenticationService: AuthenticationService,
    private _appComponent: AppComponent,
    private _toastrService: ToastrService,
    private _helperService: HelpersService,
    private _clientGurdiansService: ClientGuardiansService,
  ) {
    this._authenticationService.currentUser.subscribe(
      x => (this._currentUser = x),
    );
    let that = this;
    that._appComponent._mainpageTitle = "Passengers";
  }
  public pagesize;

  public onFilter(input: Event): void {
    const inputValue = (input.target as HTMLInputElement).value;
    let filterModel: ClientGurdiansFilterModel = this.getFilterModel();
    filterModel.searchTerm = inputValue;
    this.getGridData(filterModel);
  }
  ngOnInit() {
    this.pagesize = 25;
    this.gridIndex = 25;
    this.getPassengers();
    let filterModel: ClientGurdiansFilterModel = this.getFilterModel();
    this.getGridData(filterModel);
    this.gridView = this.gridData;
    this.contentHeader = {
      headerTitle: "",
      actionButton: true,
      breadcrumb: {
        type: "",
        links: [
          {
            name: "Home",
            isLink: true,
            link: "/",
          },
          {
            name: "Sample",
            isLink: false,
          },
        ],
      },
    };
  }

  public viewFilter(): void {
    if (this.isFiltersEnable) {
      this.isFiltersEnable = false;
    } else {
      this.isFiltersEnable = true;
    }
  }

  public viewMore(): void {
    let that = this;
    let filterModel: ClientGurdiansFilterModel = that.getFilterModel();
    if (that.gridData.length >= this.basicSelectedOption) {
      return;
    }
    if (that.gridData.length > this.basicSelectedOption) {
      this.disableLoadMore = true;
    }
    if (that.gridData.length + 5 >= this.basicSelectedOption) {
      that.skip = 0;
      filterModel.limit = this.basicSelectedOption;
      this.disableLoadMore = true;
    } else {
      filterModel.limit = that.gridData.length + that.gridIndex;
      that.skip = 0;
    }
    that.getGridData(filterModel);
  }
  public changeGridIndex(): void {
    this.gridIndex = Number(this.gridIndex);
    this.pagesize = Number(this.gridIndex);
    let filteModel = this.getFilterModel();
    this.getGridData(filteModel);
  }
  getGridData(filterModel?: ClientGurdiansFilterModel) {
    let that = this;
    filterModel.offset = that.skip;

    this._appComponent.toggeleLoader();
    this._clientGurdiansService.getDataTableRows(filterModel).subscribe(
      (data: any) => {
        that.gridData = data.Guardians;
        that.gridView = data.Guardians;
        that.total = data.TotalNumberofRecords;
        that.basicSelectedOption = data.TotalNumberofFilteredRecords;
        that.disableLoadMore =
          that.gridData.length == data.TotalNumberofFilteredRecords;
        this._appComponent.toggeleLoader();
      },
      error => {
        if (error.status == 401) {
          this._toastrService.error("UnAuthorized", "");
        } else if (error.status == 403) {
          this._toastrService.error(error.error.Message, "");
        } else if (error.status == 500) {
          this._toastrService.error(
            "The guardian app users was not loaded due to a technical issue.",
            "",
          );
        } else {
          this._toastrService.error("Server internal error", "");
        }
        this._appComponent.toggeleLoader();
      },
    );
  }

  getstatusColor(row) {
    let returnvalue;
    if (row.Status == "Blocked" || row.Status == "Archived") {
      returnvalue = "badge badge-danger";
    } else {
      returnvalue = "badge badge-success";
    }
    return returnvalue;
  }
  amountValue(amount: string) {
    return (
      "$" +
      this._helperService
        .formatNumberThousandSeparator(parseFloat(amount).toFixed(2))
        .toString()
    );
  }
  dateValue(date) {
    return moment(date).format("MM/DD/YYYY");
  }

  public clearAllData() {
    this.getFilterData();
  }

  // public changeStatus() {}
  getStatusFilterData() {
    let that = this;
    let statusRecord = that.FiltersConfig.Filters.filter(
      x => x.filterType == FiltersTypes.GurdiansStatus,
    )[0];
    return statusRecord.filterValue;
  }
  getStudentFilterData() {
    let that = this;
    let statusRecord = that.FiltersConfig.Filters.filter(
      x => x.filterType == FiltersTypes.Passengers,
    )[0];
    if (statusRecord.filterValue != undefined) return statusRecord.filterValue;
    else return 0;
  }
  getMobileNumber(mobileNumber: string) {
    return this._helperService.phoneNumber(mobileNumber);
  }

  public getFilterData(): void {
    let filterModel: ClientGurdiansFilterModel = this.getFilterModel();
    this.getGridData(filterModel);
  }

  public sortChange(sort: SortDescriptor[]): void {
    let that = this;
    this.state.sort = sort;
    let filterModel: ClientGurdiansFilterModel = this.getFilterModel();
    console.log(sort[0].dir);
    if (sort[0].dir == "desc") {
      filterModel.order_by = "desc";
      filterModel.sort_by = "name";
    } else {
      filterModel.order_by = "asc";
      filterModel.sort_by = "name";
    }
    that.getGridData(filterModel);
  }

  public getFilterModel() {
    let filterModel: ClientGurdiansFilterModel =
      new ClientGurdiansFilterModel();
    filterModel.archived = this.getStatusFilterData() == "archived";
    filterModel.isBlocked = this.getStatusFilterData() == "blocked";
    filterModel.clientId = this._currentUser.client_id;
    filterModel.limit = this.gridIndex > 0 ? this.gridIndex : filterModel.limit;
    filterModel.offset = this.skip;
    filterModel.order_by = "asc";
    filterModel.viewType = "ClientGuardians";
    filterModel.searchTerm = "";
    filterModel.studentId = this.getStudentFilterData();
    return filterModel;
  }
  getEmail(email: any) {
    if (email == "" || email == null) return "N/A";
    else return email;
  }
  getPassengers() {
    let passenger = this.FiltersConfig.Filters.filter(
      x => x.filterType == FiltersTypes.Passengers,
    )[0];
    this._clientGurdiansService.getPassengers().subscribe(
      Response => {
        let passengerfilterres = [];
        Response.forEach(function (value) {
          let data = {
            id: value.Id,
            name: value.FirstName + " " + value.LastName,
          };
          passengerfilterres.push(data);
        });
        passenger.filterValues = passengerfilterres;
        passenger.filterDisabled = false;
      },
      error => {
        if (error.status == 401) {
          this._toastrService.error("UnAuthorized.", "");
          window.location.href = "/pages/authentication/login";
        } else {
          this._toastrService.error(error.error.Message, "");
        }
      },
    );
  }
  public openedDelete: boolean = false;
  public openedArchive: boolean = false;
  public opendbolck: boolean = false;
  public openNotBlock: boolean = false;
  public GurdianName: string;
  public GurdianId: number = 0;
  public IsBlocked: boolean = false;
  public IsArchived: boolean = false;
  public openNotArchive: boolean = false;
  public opendDeniedArchvied: boolean = false;
  close(status: string): void {
    this.GurdianName = "";
    this.GurdianId = 0;
    this.IsBlocked = false;
    this.IsArchived = false;
    if (status == "block") {
      this.opendbolck = false;
    } else if (status == "deleted") {
      this.openedDelete = false;
    } else if (status == "archived") {
      this.openedArchive = false;
    } else if (status == "notblocked") {
      this.openNotBlock = false;
    } else if (status == "notArchived") {
      this.openNotArchive = false;
    } else if (status == "deniedArchived") {
      this.opendDeniedArchvied = false;
    }
  }

  blockGuardian(Id, fistName: string, lastName: string, isBlocked) {
    this.GurdianName = fistName + " " + lastName;
    this.GurdianId = Id;
    this.IsBlocked = isBlocked;
    if (isBlocked) this.openNotBlock = true;
    else this.opendbolck = true;
  }
  archiveGuardian(
    Id,
    fistName: string,
    lastName: string,
    isArchived,
    guaurdianOf: number,
  ) {
    this.GurdianName = fistName + " " + lastName;
    this.GurdianId = Id;
    this.IsArchived = isArchived;
    if (isArchived && guaurdianOf == 0) this.opendDeniedArchvied = true;
    else if (isArchived) this.openNotArchive = true;
    else this.openedArchive = true;
  }
  deleteGuardian(Id, fistName: string, lastName: string) {
    this.GurdianName = fistName + " " + lastName;
    this.GurdianId = Id;
    this.openedDelete = true;
  }

  ConfirmBlockGuardian() {
    let that = this;
    this.opendbolck = false;
    this.openNotBlock = false;
    this._appComponent.toggeleLoader();
    this._clientGurdiansService
      .blockGurdian(this.GurdianId, this.IsBlocked)
      .subscribe(
        (data: any) => {
          that.GurdianName = "";
          that.GurdianId = 0;

          that._toastrService.success(
            "The account was " +
              (this.IsBlocked ? "unblocked " : "blocked ") +
              "successfully.",
            "",
          );
          that.IsBlocked = false;
          that.IsArchived = false;
          let filterModel: ClientGurdiansFilterModel = this.getFilterModel();
          that.getGridData(filterModel);
          that._appComponent.toggeleLoader();
        },
        error => {
          if (error.status == 401) {
            that._toastrService.error("UnAuthorized", "");
          } else if (error.status == 403) {
            that._toastrService.error(error.error.Message, "");
          } else if (error.status == 500) {
            that._toastrService.error(
              "The account was not" +
                (that.IsBlocked ? "unblocked " : "blocked ") +
                "due to technical issue",
              "",
            );
          } else {
            that._toastrService.error("Server internal error", "");
          }
          that._appComponent.toggeleLoader();
        },
      );
  }

  ConfirmArchiveGuardian() {
    let that = this;
    this.openNotArchive = false;
    this.openedArchive = false;
    this._appComponent.toggeleLoader();
    this._clientGurdiansService
      .archiveGurdian(this.GurdianId, this.IsArchived)
      .subscribe(
        (data: any) => {
          that.GurdianName = "";
          that.GurdianId = 0;

          let message = "The account was";
          message += that.IsArchived ? " unarchived " : " archived ";
          message += " successfully.";
          that._toastrService.success(message, "");
          that.IsBlocked = false;
          that.IsArchived = false;
          let filterModel: ClientGurdiansFilterModel = this.getFilterModel();
          that.getGridData(filterModel);
          that._appComponent.toggeleLoader();
        },
        error => {
          if (error.status == 401) {
            that._toastrService.error("UnAuthorized", "");
          } else if (error.status == 403) {
            that._toastrService.error(error.error.Message, "");
          } else if (error.status == 500) {
            let message = "The account was not";
            message += that.IsArchived ? " unarchived " : " archived ";
            message += " due to technical issue";
            that._toastrService.error(message, "");
          } else {
            that._toastrService.error("Server internal error", "");
          }
          that._appComponent.toggeleLoader();
        },
      );
  }

  ConfirmDeleteGuardian() {
    let that = this;
    this.openedDelete = false;
    this._appComponent.toggeleLoader();
    this._clientGurdiansService.deleteGurdian(this.GurdianId).subscribe(
      (data: any) => {
        that.GurdianName = "";
        that.GurdianId = 0;
        that.IsBlocked = false;
        that.IsArchived = false;
        that._toastrService.success(
          "The account was deleted successfully.",
          "",
        );
        let filterModel: ClientGurdiansFilterModel = that.getFilterModel();
        that.getGridData(filterModel);
        that._appComponent.toggeleLoader();
      },
      error => {
        if (error.status == 401) {
          that._toastrService.error("UnAuthorized", "");
        } else if (error.status == 403) {
          that._toastrService.error(error.error.Message, "");
        } else if (error.status == 500) {
          that._toastrService.error(
            "The account was not deleted due to technical issue",
            "",
          );
        } else {
          that._toastrService.error("Server internal error", "");
        }
        that._appComponent.toggeleLoader();
      },
    );
  }
  modalOpenMD() {
    this.opened = true;
    this.addNewGuardianComponent.open();
  }
  reloadData() {
    let filterModel: ClientGurdiansFilterModel = this.getFilterModel();
    this.getGridData(filterModel);
  }
}
