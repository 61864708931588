<div
  *ngIf="Config.Filters[0].filterVisability"
  [formGroup]="parentForm"
  class="col-12"
  style="margin-top: 10px; margin-bottom: 10px"
>
  <label>Student</label>
  <!-- Basic Select with Search -->
  <ng-select
    appendTo="body"
    [appendTo]="'body'"
    [(ngModel)]="Config.Filters[1].filterValue"
    [clearable]="Config.Filters[1].filterClear"
    [disabled]="Config.Filters[1].filterDisabled"
    [multiple]="Config.filterMultiple"
    [placeholder]="Config.Filters[1].filterPlaceholder"
    [formControlName]="'studentName'"
    (change)="studentSelected()"
    (click)="studentSelected()"
  >
    <ng-option
      *ngFor="let item of Config.Filters[1].filterValues"
      [value]="item.id"
      >{{ item.name }}</ng-option
    >
  </ng-select>
  <div
    *ngIf="validStudent"
    class="invalid-feedback"
    style="font-size: 10px !important"
    [ngClass]="{ 'd-block': validStudent }"
  >
    <div *ngIf="validStudent">
      This field is required, please select a value.
    </div>
  </div>
  <!--/ Basic Select with Search -->
</div>
<div
  *ngIf="Config.Filters[0].filterVisability"
  [formGroup]="parentForm"
  class="col-12"
>
  <!-- Basic Select with Search -->
  <label>Relationship to Student</label>

  <ng-select
    appendTo="body"
    [appendTo]="'body'"
    [(ngModel)]="Config.Filters[0].filterValue"
    [clearable]="Config.Filters[0].filterClear"
    [disabled]="Config.Filters[0].filterDisabled"
    [multiple]="Config.filterMultiple"
    [placeholder]="Config.Filters[0].filterPlaceholder"
    [formControlName]="'relationshipName'"
    (change)="relationshipSelected()"
    (click)="relationshipSelected()"
  >
    <ng-option
      *ngFor="let item of Config.Filters[0].filterValues"
      [value]="item.id"
      >{{ item.name }}</ng-option
    >
  </ng-select>
  <div
    *ngIf="validRelationShip"
    class="invalid-feedback"
    style="font-size: 10px !important"
    [ngClass]="{ 'd-block': validRelationShip }"
  >
    <div *ngIf="validRelationShip">
      This field is required, please select a value.
    </div>
  </div>
  <!--/ Basic Select with Search -->
</div>
<div class="col-12 separate"></div>
