import { InvoiceType as InvoiceTypeEnum } from "./../../../enums/lookups/LookupEnum";
import {
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { DatatableComponent } from "@swimlane/ngx-datatable";
import { ActivatedRoute } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { AppComponent } from "app/app.component";
import moment from "moment";
import {
  ConfigurationFields,
  ConfigurationModel,
} from "../models/ConfigurationModel";
import { InvoiceModel } from "../models/InvoiceModel";
import { InvoiceDetailService } from "../services/invoice-detail.service";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { HelpersService } from "app/main/SharedServices/helpers.service";
@Component({
  selector: "app-Invoice",
  templateUrl: "./invoice.component.html",
  styleUrls: ["./invoice.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class InvoiceComponent implements OnInit {
  @ViewChild(DatatableComponent) table: DatatableComponent;
  @ViewChild("tableRowDetails") tableRowDetails: any;
  @ViewChild("pdfdiv") pdfdiv: ElementRef;

  // public
  public contentHeader: object;
  public today = new Date();
  public nextmonth = moment().add(30, "days").format("MM/DD/YYYY");
  public fromdate = "";
  public InvoiceNumber = "";
  public PO = "";
  public DueDate;
  public jointTotal = 0;
  public standardsTotal = 0;
  public invoiceTotal = 0;
  public invoiceUuid: any;
  public InvoiceType: any;

  public invoicedata: InvoiceModel = {
    ClientContractPurchaseOrderNumber: "",
    DueDate: "",
    ClientId: "",
    Client: {
      Name: "",
    },
    invoiceUuid: "",
    ClientMonthlyInvoiceLights: [],
    InvoiceType: "",
    InvoicePricingList: [],
    ClientMonthlyInvoicesJointBillingList: [],
  };
  public ConfigurationFields: ConfigurationFields = {
    CompanyAddress: [],
    CompanyPhone: "",
    FederalTaxID: "",
    CompanyLogo: "",
    CompanyEmail: "",
    Header: [],
    footerRight: [],
    footerLeft: [],
  };

  memo = InvoiceTypeEnum.Memo;
  manual = InvoiceTypeEnum.Manual;
  automatic = InvoiceTypeEnum.Automatic;

  /**
   * Constructor
   *
   * @param {DatatablesService} _datatablesService
   * @param {ActivatedRoute} route
   * @param {ToastrService} _toastrService
   * @param {HelpersService} _helpersService
   * @param {AppComponent} _appComponent
   */
  constructor(
    private _datatablesService: InvoiceDetailService,
    private route: ActivatedRoute,
    private _toastrService: ToastrService,
    private _appComponent: AppComponent,
    private _helpersService: HelpersService,
  ) {
    let that = this;
    that._appComponent._mainpageTitle = "Accounting";
  }
  print() {
    window.print();
  }
  ngOnInit() {
    let that = this;
    setTimeout(() => {
      that.getInvoiceData();
      window.dispatchEvent(new Event("resize"));
    }, 700);

    that.contentHeader = {
      actionButton: false,
      breadcrumb: {
        type: "",
        links: [
          {
            name: "Invoices",
          },
          {
            name: "Client Invoices",
            link: "/client-invoices/Invoices",
            isLink: true,
          },
          {
            name: "Invoice",
            isLink: false,
          },
        ],
      },
    };
  }

  getInvoiceData() {
    let that = this;
    that.invoiceUuid = that.route.snapshot.paramMap.get("id");
    that._appComponent.toggeleLoader();
    that._datatablesService.getInvoiceData(that.invoiceUuid).subscribe(
      response => {
        if (
          response.ClientMonthlyInvoiceList &&
          response.ClientMonthlyInvoiceList.length > 0
        ) {
          that.InvoiceType = response.InvoiceType;
          that.invoicedata.InvoiceType = response.InvoiceType;
          that.invoicedata.invoiceUuid = that.invoiceUuid;
          that.invoicedata.IsClientMonthlyInvoice = true;

          that.InvoiceNumber = response.ClientMonthlyInvoiceList[0].InvoiceId;
          that.PO =
            response.ClientMonthlyInvoiceList[0].ClientContractPurchaseOrderNumber;
          that.getClientData(response.ClientMonthlyInvoiceList[0].ClientId);
          that.DueDate = response.ClientMonthlyInvoiceList[0].DueDate;
          that.fromdate = response.ClientMonthlyInvoiceList[0].FromDate;
          that.invoicedata.ClientId =
            response.ClientMonthlyInvoiceList[0].ClientId.toString();
          that.invoicedata.InvoiceId =
            response.ClientMonthlyInvoiceList[0].InvoiceId;
          that.invoicedata.ClientContractPurchaseOrderNumber =
            response.ClientMonthlyInvoiceList[0].ClientContractPurchaseOrderNumber;
          that.invoicedata.SentOnDate =
            response.ClientMonthlyInvoiceList[0].SentOnDate;
          that.invoicedata.DueDate =
            response.ClientMonthlyInvoiceList[0].DueDate;
          that.invoicedata.FromDate =
            response.ClientMonthlyInvoiceList[0].FromDate;
          that.invoicedata.ToDate = response.ClientMonthlyInvoiceList[0].ToDate;

          that.invoicedata.InvoiceTotal =
            response.ClientMonthlyInvoiceList[0].InvoiceTotal;
          that.standardsTotal =
            response.ClientMonthlyInvoiceList[0].InvoiceTotal;

          that.invoicedata.ClientMonthlyInvoiceLights =
            response.ClientMonthlyInvoiceList[0].ClientMonthlyInvoiceLights;

          for (let i = 0; i < response.ClientMonthlyInvoiceList.length; i++) {
            that.invoicedata.InvoicePricingList[i] = {
              ...response.ClientMonthlyInvoiceList[i],
              ClientMonthlyInvoiceModelTotal: 0,
            };

            that.invoicedata.InvoicePricingList[
              i
            ].ClientMonthlyInvoiceModelTotal += Number(
              that.invoicedata.InvoicePricingList[i].SedanServiceFee,
            );

            that.invoicedata.InvoicePricingList[
              i
            ].ClientMonthlyInvoiceModelTotal += Number(
              that.invoicedata.InvoicePricingList[i].LargeVehicleServiceFee,
            );

            that.invoicedata.InvoicePricingList[
              i
            ].ClientMonthlyInvoiceModelTotal += Number(
              that.invoicedata.InvoicePricingList[i]
                .WheelChairVehicleServiceFee,
            );

            that.invoicedata.InvoicePricingList[
              i
            ].ClientMonthlyInvoiceModelTotal += Number(
              that.invoicedata.InvoicePricingList[i].AdditionalMilesFee,
            );

            that.invoicedata.InvoicePricingList[
              i
            ].ClientMonthlyInvoiceModelTotal += Number(
              that.invoicedata.InvoicePricingList[i].AdditionalStudentsFee,
            );

            that.invoicedata.InvoicePricingList[
              i
            ].ClientMonthlyInvoiceModelTotal += Number(
              that.invoicedata.InvoicePricingList[i].EquipmentFee,
            );

            that.invoicedata.InvoicePricingList[
              i
            ].ClientMonthlyInvoiceModelTotal += Number(
              that.invoicedata.InvoicePricingList[i].AttendantFee,
            );

            that.invoicedata.InvoicePricingList[
              i
            ].ClientMonthlyInvoiceModelTotal -= Number(
              that.invoicedata.InvoicePricingList[i].DiscountAmount,
            );

            that.invoicedata.InvoicePricingList[
              i
            ].ClientMonthlyInvoiceModelTotal += Number(
              that.invoicedata.InvoicePricingList[i].TotalTollFee,
            );

            that.invoicedata.InvoicePricingList[
              i
            ].ClientMonthlyInvoiceModelTotal += Number(
              that.invoicedata.InvoicePricingList[i].HardToServeFee,
            );

            that.invoicedata.InvoicePricingList[
              i
            ].ClientMonthlyInvoiceModelTotal += Number(
              that.invoicedata.InvoicePricingList[i].OtherFee,
            );

            that.invoicedata.InvoicePricingList[
              i
            ].ClientMonthlyInvoiceModelTotal += Number(
              that.invoicedata.InvoicePricingList[i].WaitingTimeFee,
            );

            that.invoicedata.InvoicePricingList[
              i
            ].ClientMonthlyInvoiceModelTotal += Number(
              that.invoicedata.InvoicePricingList[i].IncidentReimbursmentFee,
            );

            if (
              i == 0 &&
              response.ClientMonthlyInvoiceList[0].ClientMonthlyInvoiceLights &&
              response.ClientMonthlyInvoiceList[0].ClientMonthlyInvoiceLights
                .length > 0
            ) {
              let adjustmentData =
                response.ClientMonthlyInvoiceList[0].ClientMonthlyInvoiceLights.filter(
                  a => a.Type == "Adjustment",
                );
              adjustmentData.forEach(element => {
                that.invoicedata.InvoicePricingList[
                  i
                ].ClientMonthlyInvoiceModelTotal += Number(
                  element.ClientResponsibilityAmount,
                );
              });

              let fuelSurchargesData =
                response.ClientMonthlyInvoiceList[0].ClientMonthlyInvoiceLights.filter(
                  a => a.Type == "Fuel Surcharges",
                );
              fuelSurchargesData.forEach(element => {
                that.invoicedata.InvoicePricingList[
                  i
                ].ClientMonthlyInvoiceModelTotal += Number(
                  element.ClientResponsibilityAmount,
                );
              });
            }
          }
        }
        if (
          response.ClientMonthlyInvoicesJointBillingList &&
          response.ClientMonthlyInvoicesJointBillingList.length > 0
        ) {
          that.invoicedata.ClientMonthlyInvoicesJointBillingList =
            response.ClientMonthlyInvoicesJointBillingList;

          that.InvoiceNumber =
            response.ClientMonthlyInvoicesJointBillingList[0].InvoiceId;
          that.invoicedata.InvoiceId =
            response.ClientMonthlyInvoicesJointBillingList[0].InvoiceId;
          that.PO =
            response.ClientMonthlyInvoicesJointBillingList[0].ClientContractPurchaseOrderNumber;
          that.invoicedata.ClientContractPurchaseOrderNumber =
            response.ClientMonthlyInvoicesJointBillingList[0].ClientContractPurchaseOrderNumber;
          that.getClientData(
            response.ClientMonthlyInvoicesJointBillingList[0].ClientId,
          );
          that.DueDate =
            response.ClientMonthlyInvoicesJointBillingList[0].DueDate;
          that.fromdate =
            response.ClientMonthlyInvoicesJointBillingList[0].FromDate;
          that.invoicedata.ClientId =
            response.ClientMonthlyInvoicesJointBillingList[0].ClientId.toString();
          that.invoicedata.FromDate =
            response.ClientMonthlyInvoicesJointBillingList[0].FromDate;
          that.invoicedata.ToDate =
            response.ClientMonthlyInvoicesJointBillingList[0].ToDate;
          that.invoicedata.SentOnDate =
            response.ClientMonthlyInvoicesJointBillingList[0].SentOnDate;

          that.invoicedata.IsClientMonthlyInvoicesJointBilling = true;

          for (const element of response.ClientMonthlyInvoicesJointBillingList) {
            that.jointTotal += Number(element.SedanServiceFee);
            that.jointTotal += Number(element.LargeVehicleServiceFee);
            that.jointTotal += Number(element.WheelChairVehicleServiceFee);
          }

          if (
            (!response.ClientMonthlyInvoiceList ||
              response.ClientMonthlyInvoiceList.length == 0) &&
            response.ClientMonthlyInvoicesJointBillingList[0]
              .ClientMonthlyInvoiceLights &&
            response.ClientMonthlyInvoicesJointBillingList[0]
              .ClientMonthlyInvoiceLights.length > 0
          ) {
            let adjustmentData =
              response.ClientMonthlyInvoicesJointBillingList[0].ClientMonthlyInvoiceLights.filter(
                a => a.Type == "Adjustment",
              );
            adjustmentData.forEach(element => {
              that.jointTotal += Number(element.ClientResponsibilityAmount);
            });

            let fuelSurchargesData =
              response.ClientMonthlyInvoicesJointBillingList[0].ClientMonthlyInvoiceLights.filter(
                a => a.Type == "Fuel Surcharges",
              );
            fuelSurchargesData.forEach(element => {
              that.jointTotal += Number(element.ClientResponsibilityAmount);
            });
          }
        }

        if (
          response.TransactionsModel &&
          response.TransactionsModel.length > 0
        ) {
          that.invoicedata.TransactionsModel = response.TransactionsModel;
        }

        if (that.DueDate == null) that.DueDate = that.nextmonth;

        that.invoiceTotal = that.standardsTotal + that.jointTotal;
        that._appComponent.toggeleLoader();
        that.getConfiguratioFields();
      },
      error => {
        if (error.status == 401) {
          that._toastrService.error("UnAuthorized.", "");
          window.location.href = "/pages/authentication/login";
        } else {
          that._toastrService.error(error.error.Message, "");
        }
        that._appComponent.toggeleLoader();
      },
    );
  }

  getClientData(ClientId) {
    let that = this;
    that._datatablesService.getClientData(ClientId).subscribe(
      response => {
        that.invoicedata.Client = response;
      },
      error => {
        if (error.status == 401) {
          that._toastrService.error("UnAuthorized.", "");
          window.location.href = "/pages/authentication/login";
        } else {
          that._toastrService.error(error.error.Message, "");
        }
        that._appComponent.toggeleLoader();
      },
    );
  }

  clientAddress1() {
    let that = this;
    if (that.invoicedata.Client.Location)
      return (
        that.invoicedata.Client.Location.Street1 +
        " " +
        (that.invoicedata.Client.Location.Street2 == "" ||
        that.invoicedata.Client.Location.Street2 == null
          ? ""
          : "Suite " + that.invoicedata.Client.Location.Street2)
      );
  }
  clientAddress2() {
    let that = this;
    if (that.invoicedata.Client.Location)
      return (
        that.invoicedata.Client.Location.City +
        ", " +
        that.invoicedata.Client.Location.State +
        " " +
        that.invoicedata.Client.Location.Zipcode
      );
  }
  getFromToDate() {
    let that = this;
    return (
      moment(that.invoicedata.FromDate).format("MM/DD/yyyy") +
      " - " +
      moment(that.invoicedata.ToDate).format("MM/DD/yyyy")
    );
  }
  getConfiguratioFields() {
    let that = this;
    that._datatablesService.loadConfiguration().subscribe(
      response => {
        const configurations: ConfigurationModel[] = response;
        that.fillConfigurationFields(configurations);
      },
      error => {
        if (error.status == 401) {
          that._toastrService.error("UnAuthorized.", "");
          window.location.href = "/pages/authentication/login";
        } else {
          that._toastrService.error(error.error.Message, "");
        }
      },
    );
  }

  fillConfigurationFields(configurations: ConfigurationModel[]) {
    let AddressField = configurations.filter(
      x => x.PropertyName == "CompanyAddress",
    )[0];
    let CompanyPhone = configurations.filter(
      x => x.PropertyName == "CompanyPhone",
    )[0];
    let FederalTaxID = configurations.filter(
      x => x.PropertyName == "CompanyTaxID",
    )[0];
    let CompanyLogo = configurations.filter(
      x => x.PropertyName == "CompanyLogo",
    )[0];
    let CompanyEmail = configurations.filter(
      x => x.PropertyName == "CompanyEmail",
    )[0];
    let that = this;
    if (CompanyLogo.ImageValue?.length != 0) {
      let src = `data:image/png;base64,${CompanyLogo.ImageValue}`;
      that.ConfigurationFields.CompanyLogo = src;
    }
    if (AddressField.PropertyValue?.length != 0) {
      const value =
        AddressField.PropertyValue.toString().split(/(?:\r|\n|\r\n)/g);
      that.ConfigurationFields.CompanyAddress = value;
    }
    if (FederalTaxID.PropertyValue?.length != 0) {
      that.ConfigurationFields.FederalTaxID =
        FederalTaxID.PropertyValue.slice(0, 2) +
        "-" +
        FederalTaxID.PropertyValue.slice(2);
    }

    if (CompanyPhone.PropertyValue?.length != 0) {
      that.ConfigurationFields.CompanyPhone =
        that.getCompanyPhone(CompanyPhone);
    }
    if (CompanyEmail.PropertyValue?.length != 0) {
      that.ConfigurationFields.CompanyEmail = CompanyEmail.PropertyValue;
    }
    const reportConfigurations = configurations.filter(
      x => x.ParentId == null && x.PropertyName == "ClientInvoice",
    )[0];
    const Header = reportConfigurations.SubConfigurations.filter(
      x => x.PropertyType == 8,
    )[0];
    if (Header.PropertyValue?.length != 0) {
      const value = Header.PropertyValue.toString().split(/(?:\r|\n|\r\n)/g);
      that.ConfigurationFields.Header = value;
    }
    let FooterRight = reportConfigurations.SubConfigurations.filter(
      x => x.PropertyType == 6 && x.Direction == 1,
    )[0];
    if (FooterRight.PropertyValue?.length != 0) {
      const value =
        FooterRight.PropertyValue.toString().split(/(?:\r|\n|\r\n)/g);
      that.ConfigurationFields.footerRight = value;
    } else {
      that.ConfigurationFields.footerRight = [];
    }
    let FooterLeft = reportConfigurations.SubConfigurations.filter(
      x => x.PropertyType == 6 && x.Direction == 2,
    )[0];
    if (
      FooterLeft.PropertyValue != null &&
      FooterLeft.PropertyValue.length != 0
    ) {
      const value =
        FooterLeft.PropertyValue.toString().split(/(?:\r|\n|\r\n)/g);
      that.ConfigurationFields.footerLeft = value;
    } else {
      that.ConfigurationFields.footerLeft = [];
    }
  }

  SavePDF(): void {
    let that = this;
    let po = `_PO_${that._helpersService.poNumber(that.PO, 5)}`;
    if (that.InvoiceType != that.automatic) {
      po = "";
    }
    that._appComponent.toggeleLoader();
    html2canvas(that.pdfdiv.nativeElement, { scale: 3 }).then(canvas => {
      const imageGeneratedFromTemplate = canvas.toDataURL("image/png");
      const fileWidth = 200;
      const generatedImageHeight = (canvas.height * fileWidth) / canvas.width;
      let PDF = new jsPDF("p", "mm", "a4");
      PDF.addImage(
        imageGeneratedFromTemplate,
        "PNG",
        0,
        5,
        fileWidth,
        generatedImageHeight,
      );
      PDF.html(that.pdfdiv.nativeElement.innerHTML);
      PDF.save(
        `${moment(that.fromdate).format("yyyy_MMMM")}_Invoice_${
          that.InvoiceNumber
        }${po}.pdf`,
      );
      that._appComponent.toggeleLoader();
    });
  }
  getCompanyPhone(CompanyPhone) {
    return CompanyPhone.PropertyValue.length >= 3
      ? "(" +
          CompanyPhone.PropertyValue.slice(0, 3) +
          ") " +
          CompanyPhone.PropertyValue.slice(3, 5) +
          CompanyPhone.PropertyValue.slice(5)
      : CompanyPhone.PropertyValue;
  }
}
