<ng-template
  #AddNewGuardian
  let-modal
>
  <div class="modal-header">
    <h5
      class="modal-title"
      id="myModalLabel160"
    >
      {{ PageTitle }}
    </h5>
    <button
      type="button"
      class="close"
      (click)="close(modal)"
      aria-label="Close"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div
    class="modal-body"
    tabindex="0"
    ngbAutofocus
  >
    <div class="row">
      <div class="row">
        <div class="col-lg-12 padding-left: 26px;">
          <h4 class="text-black">
            <strong style="margin: 14px">Basic Details</strong>
          </h4>
        </div>
        <div class="col-12">
          <form
            [formGroup]="newGuardianForm"
            (ngSubmit)="onSubmit(modal)"
          >
            <div
              *ngIf="Config.Filters[0].filterVisability"
              class="col-12"
            >
              <!-- Basic Select with Search -->
              <div style="margin-bottom: 5px">Relationship to Student</div>
              <ng-select
                appendTo="body"
                [(ngModel)]="Config.Filters[0].filterValue"
                [clearable]="Config.Filters[0].filterClear"
                [multiple]="Config.filterMultiple"
                [placeholder]="Config.Filters[0].filterPlaceholder"
                formControlName="relationshipName"
                (change)="relationshipSelected()"
                (click)="relationshipSelected()"
              >
                <ng-option
                  *ngFor="let item of Config.Filters[0].filterValues"
                  [value]="item.id"
                  >{{ item.name }}</ng-option
                >
              </ng-select>

              <div class="dll-invalid-feedback">
                <div
                  *ngIf="validRelationShip"
                  class="invalid-feedback"
                  style="font-size: 10px !important"
                  [ngClass]="{ 'd-block': validRelationShip }"
                >
                  <div *ngIf="validRelationShip">
                    This field is required, please select a value.
                  </div>
                </div>
              </div>

              <!--/ Basic Select with Search -->
            </div>
            <div class="col-12 separate"></div>
            <kendo-tabstrip
              [tabPosition]="position"
              style="margin: 10px"
              (tabSelect)="onTapSelected($event)"
            >
              <kendo-tabstrip-tab
                title="Add New"
                style="font-weight: 500"
                [selected]="true"
              >
                <ng-template kendoTabContent>
                  <div
                    class="row"
                    style="display: flex"
                  >
                    <div class="col-lg-6">
                      <label>First Name</label>
                      <app-required-field
                        type="text"
                        formControlName="FirstName"
                        placeholder="First Name"
                      ></app-required-field>
                    </div>
                    <div class="col-lg-6">
                      <label>Last Name</label>
                      <app-required-field
                        type="text"
                        formControlName="LastName"
                        placeholder="Last Name"
                      ></app-required-field>
                    </div>
                    <div class="col-lg-12 separate"></div>
                    <div class="col-lg-6">
                      <label>Phone Number</label>
                      <app-required-field
                        type="tel"
                        formControlName="PhoneNumber"
                        placeholder="(000) 000-0000"
                        maxlength="14"
                        [appPhoneMask]
                      ></app-required-field>
                    </div>
                    <div class="col-lg-12 separate"></div>
                    <div class="col-lg-12">
                      <label>Email</label>
                      <app-optional-field
                        type="text"
                        formControlName="Email"
                        placeholder="(optional)"
                        validationError="Please enter a valid email address."
                      ></app-optional-field>
                    </div>
                    <div class="col-lg-12 separate"></div>
                    <div class="col-lg-6">
                      <label>Password</label>
                      <app-required-field
                        type="text"
                        formControlName="Password"
                        placeholder="Set to ChangeMe! by default."
                        disabled="true"
                      ></app-required-field>
                    </div>
                  </div>
                </ng-template>
              </kendo-tabstrip-tab>
              <kendo-tabstrip-tab title="Add Existing">
                <ng-template kendoTabContent>
                  <div
                    class="row"
                    style="display: flex"
                  >
                    <div class="col-lg-12">
                      <div
                        *ngIf="Config.Filters[0].filterVisability"
                        class="col-12"
                      >
                        <!-- Basic Select with Search -->
                        <div style="margin-bottom: 5px">Guardian App User</div>

                        <ng-select
                          appendTo="body"
                          [(ngModel)]="Config.Filters[2].filterValue"
                          [clearable]="Config.Filters[2].filterClear"
                          [disabled]="Config.Filters[2].filterDisabled"
                          [multiple]="Config.filterMultiple"
                          [placeholder]="Config.Filters[2].filterPlaceholder"
                          formControlName="guardianId"
                          (change)="gurdianSelected()"
                          (click)="gurdianSelected()"
                        >
                          <ng-option
                            *ngFor="let item of Config.Filters[2].filterValues"
                            [value]="item.Id"
                          >
                            {{ item.name }}
                            |
                            {{ item.MobileNumber }}
                          </ng-option>
                        </ng-select>
                        <div class="dll-invalid-feedback">
                          <div
                            *ngIf="validGuardian"
                            class="invalid-feedback"
                            style="font-size: 10px !important"
                            [ngClass]="{ 'd-block': validGuardian }"
                          >
                            <div *ngIf="validGuardian">
                              This field is required, please select a value.
                            </div>
                          </div>
                        </div>
                        <!--/ Basic Select with Search -->
                      </div>
                    </div>
                    <div class="col-12 separate"></div>
                  </div>
                </ng-template>
              </kendo-tabstrip-tab>
            </kendo-tabstrip>
          </form>
        </div>
      </div>
    </div>
  </div>

  <div class="modal-footer about-adroit-footer">
    <button
      type="button"
      (click)="close(modal)"
      rippleeffect=""
      class="btn btn-close waves-effect waves-float waves-light"
      style="border-color: #1877f2; margin-bottom: 20px; color: #1877f2"
    >
      Close
    </button>
    <button
      type="submit"
      class="btn btn-info"
      (click)="onSubmit(modal)"
      style="margin-bottom: 20px; margin-right: 20px"
      rippleEffect
    >
      Submit
    </button>
  </div>
</ng-template>

<kendo-dialog
  title=""
  *ngIf="guardianexist"
  (close)="closeConfiramtion()"
  [minWidth]="250"
  [width]="400"
>
  <p style="text-align: center">The selected guardian app user already added</p>
  <kendo-dialog-titlebar>
    <div style="font-size: 18px; line-height: 1.3em; font-weight: 500">
      <span
        class="k-icon k-i-info-circle mr-1"
        style="color: #eea236; font-size: 26px; margin-top: -3px"
      ></span>
      Add New Guardian Denied
    </div>
  </kendo-dialog-titlebar>

  <kendo-dialog-actions>
    <p style="text-align: center">
      <button
        type="button"
        (click)="closeConfiramtion(modal)"
        rippleeffect=""
        class="btn btn-default waves-effect waves-float waves-light"
        style="
          background-color: #acb7bf;
          border-color: #acb7bf;
          color: white;
          margin-right: 10px;
        "
      >
        Ok, got it.
      </button>
    </p>
  </kendo-dialog-actions>
</kendo-dialog>

<kendo-dialog
  title=""
  *ngIf="openRestPass"
  (close)="close(modal)"
  [minWidth]="250"
  [width]="400"
>
  <p style="text-align: center">
    Are you sure you want to reset the password ?
  </p>
  <kendo-dialog-titlebar>
    <div style="font-size: 18px; line-height: 1.3em; font-weight: 500">
      <span
        class="k-icon k-i-info-circle mr-1"
        style="color: #eea236; font-size: 26px; margin-top: -3px"
      ></span>
      Reset Password Confirmation
    </div>
  </kendo-dialog-titlebar>
  <kendo-dialog-actions>
    <p style="text-align: center">
      <button
        type="button"
        (click)="close(modal)"
        rippleeffect=""
        class="btn btn-default waves-effect waves-float waves-light"
        style="
          background-color: #acb7bf;
          border-color: #acb7bf;
          color: white;
          margin-right: 10px;
        "
      >
        No
      </button>
      <button
        type="button"
        (click)="resetPassword()"
        rippleeffect=""
        class="btn btn-danger waves-effect waves-float waves-light"
      >
        Yes, reset it.
      </button>
    </p>
  </kendo-dialog-actions>
</kendo-dialog>

<ng-template
  #EditGuardian
  let-modal
>
  <div class="modal-header">
    <h5
      class="modal-title"
      id="myModalLabel160"
    >
      Edit Guardian App User
    </h5>

    <button
      type="button"
      class="close"
      (click)="close(modal)"
      aria-label="Close"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div
    class="modal-body"
    tabindex="0"
    style="padding: 14px"
    ngbAutofocus
  >
    <div class="row">
      <div class="col-lg-12 padding-left: 26px;">
        <h4 class="text-black"><strong>Basic Details</strong></h4>
      </div>
      <div class="col-12">
        <form
          [formGroup]="newGuardianForm"
          (ngSubmit)="onSubmit(modal)"
        >
          <div
            *ngIf="Config.Filters[0].filterVisability"
            class="col-12"
            style="padding-left: 0px; padding-right: 0px"
          >
            <!-- Basic Select with Search -->
            <div style="margin-bottom: 5px">Relationship to Student</div>

            <ng-select
              appendTo="body"
              Id="relationSelector"
              [(ngModel)]="Config.Filters[0].filterValue"
              [clearable]="Config.Filters[0].filterClear"
              [disabledd]="PermessionValue"
              [multiple]="Config.filterMultiple"
              [placeholder]="Config.Filters[0].filterPlaceholder"
              formControlName="relationshipName"
              (change)="relationshipSelected()"
              (click)="relationshipSelected()"
            >
              <ng-option
                *ngFor="let item of Config.Filters[0].filterValues"
                [value]="item.id"
                >{{ item.name }}</ng-option
              >
            </ng-select>
            <div
              *ngIf="validRelationShip"
              class="invalid-feedback"
              style="font-size: 10px !important"
              [ngClass]="{ 'd-block': validRelationShip }"
            >
              <div *ngIf="validRelationShip">
                This field is required, please select a value.
              </div>
            </div>
            <!--/ Basic Select with Search -->
          </div>
          <div class="col-12 separate"></div>

          <div
            class="row"
            style="display: flex"
          >
            <div class="col-lg-6">
              <label>First Name</label>
              <app-required-field
                type="text"
                formControlName="FirstName"
                placeholder="First Name"
                [disabledd]="PermessionValue"
              ></app-required-field>
            </div>
            <div class="col-lg-6">
              <label>Last Name</label>
              <app-required-field
                type="text"
                formControlName="LastName"
                placeholder="Last Name"
                [disabledd]="PermessionValue"
              ></app-required-field>
            </div>
            <div class="col-lg-12 separate"></div>
            <div class="col-lg-6">
              <label>Phone Number</label>
              <app-required-field
                type="tel"
                formControlName="PhoneNumber"
                placeholder="(000) 000-0000"
                maxlength="14"
                [appPhoneMask]
                [disabledd]="PermessionValue"
              ></app-required-field>
            </div>
            <div class="col-lg-6">
              <label>Email</label>
              <app-optional-field
                type="text"
                formControlName="Email"
                placeholder="(optional)"
                validationError="Please enter a valid email address."
                [disabledd]="PermessionValue"
              ></app-optional-field>
            </div>
            <div class="col-lg-12 separate"></div>
            <div class="col-lg-6">
              <label>Password</label>
              <app-required-field
                type="text"
                formControlName="Password"
                placeholder="Set to ChangeMe! by default."
                disabled="true"
              ></app-required-field>
            </div>
            <div class="col-lg-6">
              <button
                type="button"
                (click)="openRestPassPopup()"
                rippleeffect=""
                class="btn btn-close waves-effect waves-float waves-light"
                style="
                  border-color: #1877f2;
                  margin-bottom: 20px;
                  color: #1877f2;
                  margin-top: 24px;
                "
                [hidden]="disabledAdd"
                [disabled]="allowedToReset"
              >
                Reset Password to Default
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>

  <div class="modal-footer about-adroit-footer">
    <button
      type="button"
      (click)="close(modal)"
      rippleeffect=""
      class="btn btn-close waves-effect waves-float waves-light"
      style="border-color: #1877f2; margin-bottom: 20px; color: #1877f2"
    >
      Close
    </button>
    <button
      type="submit"
      class="btn btn-info"
      (click)="onSubmit(modal)"
      style="margin-bottom: 20px; margin-right: 20px"
      rippleEffect
      [hidden]="disabledAdd"
    >
      Submit
    </button>
  </div>
</ng-template>
