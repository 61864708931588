import { Component, Input, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";
import { AppComponent } from "../../../../app.component";
import { AuthenticationService } from "../../../../auth/service/authentication.service";
import {
  StudentFileUploadModel,
  UploadFileModel,
} from "../../../../main/Students/model/UploadFileModel";
import { ClientStudentsService } from "../../../../main/Students/services/client-students.service";
import { PermissionsList } from "app/enums/Permissions/PermissionList";

@Component({
  selector: "app-personal-photo",
  templateUrl: "./personal-photo.component.html",
})
export class PersonalPhotoComponent implements OnInit {
  public openedProfileImage: boolean = false;
  modelfile = new UploadFileModel();
  fileModel = new StudentFileUploadModel();
  defualtImage = "./assets/images/pages/upload_profile.png";
  imgSRC = "./assets/images/pages/upload_profile.png";
  @Input("Id") id: number = 0;
  @Input("EntityType") entityType: string = "";
  @Input("Folder") folder: string = "";
  @Input("Type") type: string = "";
  @Input("Title") title: string = "";
  @Input("disable") disable: boolean = true;
  public disabeld: boolean = false;
  public PermessionValue: string =
    PermissionsList.ClientStudentsEdit.toString();

  data: any;

  constructor(
    private _authenticationService: AuthenticationService,
    private _toastrService: ToastrService,
    private _modalService: NgbModal,
    private _appcomponent: AppComponent,
    private _clientStudentService: ClientStudentsService,
    private route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.disabeld = !this.disable;
  }

  uploadFile(event: Event) {
    let that = this;
    const element = event.currentTarget as HTMLInputElement;
    let fileList: FileList | null = element.files;
    let reader = new FileReader();
    if (fileList && fileList[0]) {
      let file = fileList[0];
      let Extension = file.name
        .substring(file.name.lastIndexOf(".") + 1)
        .toLowerCase();
      reader.onloadend = function () {
        let data = reader.result.toString().split("base64,")[1];
        that.modelfile.entityType = that.entityType;
        that.modelfile.file = data;
        that.modelfile.name = file.name;
        that.modelfile.extension = Extension;
        that.modelfile.parentId = that.id;
        that.modelfile.folder = that.folder;
        that.modelfile.type = that.type;
        that.UploadFile(that.modelfile);
      };
      reader.readAsDataURL(file);
    }
  }

  UploadFile(uploadFileModel: UploadFileModel) {
    let that = this;

    if (
      that.modelfile.extension == "jpg" ||
      that.modelfile.extension == "jpeg" ||
      that.modelfile.extension == "png" ||
      that.modelfile.extension == "gif" ||
      that.modelfile.extension == "JPG" ||
      that.modelfile.extension == "JPEG" ||
      that.modelfile.extension == "PNG" ||
      that.modelfile.extension == "GIF"
    ) {
      that._appcomponent.toggeleLoader();
      this._clientStudentService.uploadFile(uploadFileModel).subscribe(
        response => {
          that.fileModel.CdnFileId = response.Id;
          that.fileModel.CdnFileUrl = response.Url;
          that.fileModel.CdnFileName = response.Name;
          that.fileModel.Extension = response.Extension;
          that.fileModel.TypeName = that.modelfile.type;
          that.fileModel.ParentId = this.id;
          that._toastrService.success(
            "The profile photo was uploaded successfully.",
            "",
          );
          that._appcomponent.toggeleLoader();
          that.generateGoogleCloudView(that.fileModel.CdnFileName);
        },
        error => {
          if (error.status == 401) {
            that._toastrService.error("UnAuthorized.", "");
            window.location.href = "/pages/authentication/login";
            that._appcomponent.toggeleLoader();
          } else if (error.status == 300) {
            that._toastrService.error(error.error.Message, "");
            that._appcomponent.toggeleLoader();
          } else {
            that._toastrService.error(
              "The profile photo was not uploaded due to technical issue.",
              "",
            );
            that._appcomponent.toggeleLoader();
          }
        },
      );
    } else {
      that._toastrService.error("The profile photo type not valid", "");
    }
  }

  generateGoogleCloudView(fileName) {
    let that = this;
    that._appcomponent.toggeleLoader();
    let file = fileName.replaceAll("___", "/");
    let result: UploadFileModel = new UploadFileModel();
    result.name = file;
    that._clientStudentService.downloadFile(result).subscribe(response => {
      that.openBase64InNewTab(response.Value, "image/png");
    });
    that._appcomponent.toggeleLoader();
  }

  openBase64InNewTab(data, mimeType) {
    let result = `data:${mimeType};base64,${data}`;
    this.imgSRC = result;
  }

  loadPersonalPhoto() {
    if (this.data) {
      this.generateGoogleCloudView(this.data.CdnFileName);
    }
  }

  openedProfilePicture(imgSRC) {
    let that = this;
    if (imgSRC == this.defualtImage) {
      return false;
    }
    that.openedProfileImage = true;
  }

  close() {
    let that = this;
    that.openedProfileImage = false;
  }
}
