import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { IFiltersConfig } from "app/controls/component/filters/interfaces/controls/IFiltersConfig";
import { ClientGuardiansService } from "../services/client-guardians.service";
import { ToastrService } from "ngx-toastr";
import { ClientGurdiansFilterModel } from "../model/ClientGurdiansFilterModel";
import { AuthenticationService } from "app/auth/service";
import { User } from "app/auth/models";
import { HelpersService } from "app/main/SharedServices/helpers.service";

@Component({
  selector: "app-guardian-app-user",
  templateUrl: "./guardian-app-users.component.html",
  styleUrls: ["./guardian-app-users.component.scss"],
})
export class GuardianAppUsersComponent implements OnInit {
  public Config: IFiltersConfig;
  @Output() changeValue = new EventEmitter<string>();
  public _currentUser: User;
  constructor(
    private _clientGurdiansService: ClientGuardiansService,
    private _toastrService: ToastrService,
    private _authenticationService: AuthenticationService,
    private _helperService: HelpersService,
  ) {
    this._authenticationService.currentUser.subscribe(
      x => (this._currentUser = x),
    );
  }
  @Input() parentForm: FormGroup;
  validGuardian: boolean = false;
  ngOnInit(): void {
    this.Config = {
      isFiltersEnable: true,
      Filters: [
        {
          filterValues: this.getGuardians(),
          filterClear: true,
          filterVisability: true,
          filterPlaceholder: "Select a guardian app user.",
        },
      ],
    };
  }

  getGuardians() {
    let guardianfilterres = [];
    let that = this;
    this._clientGurdiansService
      .getDataTableRows(this.getFilterModel())
      .subscribe(
        (Response: any) => {
          Response.Guardians.forEach(function (value) {
            let data = {
              Id: value.Id,
              name: value.FirstName + " " + value.LastName,
              MobileNumber: that.getMobileNumber(value.MobileNumber),
            };
            guardianfilterres.push(data);
          });
        },
        error => {
          if (error.status == 401) {
            this._toastrService.error("UnAuthorized.", "");
            window.location.href = "/pages/authentication/login";
          } else {
            this._toastrService.error(error.error.Message, "");
          }
        },
      );
    return guardianfilterres;
  }
  public getFilterModel() {
    let filterModel: ClientGurdiansFilterModel =
      new ClientGurdiansFilterModel();
    filterModel.archived = false;
    filterModel.isBlocked = false;
    filterModel.clientId = this._currentUser.client_id;
    filterModel.limit = 200;
    filterModel.offset = 0;
    filterModel.order_by = "asc";
    filterModel.viewType = "ClientGuardians";
    filterModel.searchTerm = "";
    filterModel.studentId = 0;
    return filterModel;
  }
  public gurdianSelected() {
    if (this.Config.Filters[0].filterValue == null) {
      this.validGuardian = true;
    } else {
      this.validGuardian = false;
    }
  }
  Onchange() {
    this.changeValue.emit("complete");
  }
  getMobileNumber(mobileNumber: string) {
    return this._helperService.phoneNumber(mobileNumber);
  }
}
