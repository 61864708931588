import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TabPosition } from "@progress/kendo-angular-layout";
import { AppComponent } from "app/app.component";
import { User } from "app/auth/models";
import { AuthenticationService } from "app/auth/service";
import { IFiltersConfig } from "app/controls/component/filters/interfaces/controls/IFiltersConfig";
import { GuardianSelectorsComponent } from "app/main/Guardians/GuardianSelectors/guardian-selectors.component";
import { ClientGuardiansService } from "app/main/Guardians/services/client-guardians.service";
import { HelpersService } from "app/main/SharedServices/helpers.service";
import { ToastrService } from "ngx-toastr";
import { AccountStudentDataModel } from "../../model/AccountStudentDataModel";
import { ClientGurdiansFilterModel } from "../../model/ClientGurdiansFilterModel";
import { GuardianDataModel } from "../../model/GuardianDataModel";
import { ClientStudentsService } from "../../services/client-students.service";
import { WhiteSpace } from "app/utilities/Validators/white-space.validator";
import { PermissionsList } from "app/enums/Permissions/PermissionList";

@Component({
  selector: "app-student-add-new-guardian",
  templateUrl: "./student-add-new-guardian.component.html",
  styleUrls: ["./student-add-new-guardian.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class StudentAddNewGuardianComponent implements OnInit {
  public PageTitle: string;
  public submitted = false;
  public newGuardianForm: FormGroup;
  public position: TabPosition = "top";
  @ViewChild("AddNewGuardian") AddNewGuardianModal: TemplateRef<any>;
  @ViewChild("EditGuardian") EditGuardianModel: TemplateRef<any>;
  @ViewChild(GuardianSelectorsComponent, { static: true })
  guardianSelectorsComponent: GuardianSelectorsComponent;
  @Output() Added = new EventEmitter<any>();
  @Input() gridData: any = [];
  public data: any;
  @Input("disable") disable: boolean = false;
  public disabledAdd: boolean = false;
  public PermessionValue: string;
  public openRestPass: boolean = false;
  validRelationShip: boolean = false;
  validStudent: boolean = false;
  validGuardian: boolean = false;
  public Config: IFiltersConfig;
  public _currentUser: User;
  guardianfilterres = [];
  addExistingTap: boolean = false;
  addNewTap: boolean = true;
  guardianexist: boolean = false;
  studentId: number = 0;
  allowedToReset:boolean=false;
  /**
   * Constructor
   * * @param {AuthenticationService} _authenticationService
   * * @param {HelpersService} _helpersService
   * * @param {ClientGuardiansService} _clientGurdiansService
   * * @param {NgbModal} _modalService
   * * @param {ToastrService} _toastrService
   * * @param {FormBuilder} _formBuilder
   * * @param {AppComponent} _appcomponent
   */
  constructor(
    private _formBuilder: FormBuilder,
    private _authenticationService: AuthenticationService,
    private _toastrService: ToastrService,
    private _modalService: NgbModal,
    private _clientGurdiansService: ClientGuardiansService,
    private _helperService: HelpersService,
    private _appcomponent: AppComponent,
    private route: ActivatedRoute,
    private _clientStudentService: ClientStudentsService,
  ) {
    let that = this;
    that._authenticationService.currentUser.subscribe(
      x => (that._currentUser = x),
    );
  }

  ngOnInit(): void {
    this.disabledAdd = this.disable;
    let that = this;
    if (that.route.snapshot.paramMap.get("id")) {
      that.allowedToReset=false;
      that.studentId = parseInt(that.route.snapshot.paramMap.get("id")!);
      this.PermessionValue = PermissionsList.ClientStudentsEdit.toString();
    } else {
      that.allowedToReset=true;
      this.PermessionValue = PermissionsList.ClientStudentsCreate.toString();
    }
    that.newGuardianForm = that._formBuilder.group({
      relationshipName: ["", [Validators.required]],
      FirstName: ["", [Validators.required, WhiteSpace.noSpaceAllowed]],
      LastName: ["", [Validators.required, WhiteSpace.noSpaceAllowed]],
      PhoneNumber: [
        "",
        [Validators.pattern(/^\(\d{3}\)\s\d{3}-\d{4}$/), Validators.required],
      ],
      Email: ["", [Validators.email]],
      Password: [""],
      guardianId: [""],
    });
    that.Config = {
      isFiltersEnable: false,
      Filters: [
        {
          filterValues: [
            { id: "Aunt", name: "Aunt" },
            { id: "Brother", name: "Brother" },
            { id: "Father", name: "Father" },
            { id: "Grandfather", name: "Grandfather" },
            { id: "Grandmother", name: "Grandmother" },
            { id: "Legal Guardian", name: "Legal Guardian" },
            { id: "Mother", name: "Mother" },
            { id: "Sister", name: "Sister" },
            { id: "Uncle", name: "Uncle" },
            { id: "Other", name: "Other" },
          ],
          filterClear: true,
          filterVisability: true,
          filterPlaceholder: "Select a relationship.",
        },
        {
          filterValues: this.getPassengers(),
          filterClear: true,
          filterVisability: true,
          filterPlaceholder: "Select a student.",
        },
        {
          filterValues: this.getGuardians(),
          filterClear: true,
          filterVisability: true,
          filterPlaceholder: "Select a guardian app user.",
        },
      ],
    };
  }

  changeRelationship(e: any) {
    let that = this;
    that.relationshipName?.setValue(e.target.value, {
      onlySelf: true,
    });
  }

  // Access formcontrols getter
  get relationshipName() {
    let that = this;
    return that.newGuardianForm.get("relationshipName");
  }

  get studentName() {
    let that = this;
    return that.newGuardianForm.get("studentName");
  }

  changeStudent(e: any) {
    let that = this;
    that.studentName?.setValue(e.target.value, {
      onlySelf: true,
    });
  }

  public isValidForm(): boolean {
    let that = this;
    if (that.addExistingTap) {
      that.relationshipSelected();
      that.gurdianSelected();
      return !that.relationshipSelected() && !that.gurdianSelected();
    } else {
      that.relationshipSelected();
      for (const control of Object.keys(that.newGuardianForm.controls)) {
        that.newGuardianForm.controls[control].markAsTouched();
      }

      return that.newGuardianForm.invalid;
    }
  }

  public open() {
    let that = this;

    if (that.data != null) {
      this.PageTitle = "Edit Guardian App User";
      that.newGuardianForm.setValue({
        relationshipName: that.data.RelationshipToStudent,
        FirstName: that.data.Account.FirstName,
        LastName: that.data.Account.LastName,
        PhoneNumber: that.getMobileNumber(that.data.Account.MobileNumber),
        Email: that.data.Account.Email,
        Password: "",
        guardianId: that.data.AccountId.toString(),
      });
      that.Config.Filters[0].filterValue = that.data.RelationshipToStudent;
      that._modalService.open(that.EditGuardianModel, {
        centered: true,
        backdrop: "static",
        size: "md",
      });
      that.addNewTap = true;
    } else {
      this.PageTitle = "Add Guardian App User";
      that._modalService.open(that.AddNewGuardianModal, {
        centered: true,
        backdrop: "static",
        size: "md",
      });
    }
  }

  getPassengers() {
    let that = this;
    let passengerfilterres = [];
    that._clientGurdiansService.getPassengers().subscribe(
      Response => {
        Response.forEach(function (value) {
          let data = {
            Id: value.Id,
            name: value.FirstName + " " + value.LastName,
          };
          passengerfilterres.push(data);
        });
      },
      error => {
        if (error.status == 401) {
          that._toastrService.error("UnAuthorized.", "");
          window.location.href = "/pages/authentication/login";
        } else {
          that._toastrService.error(error.error.Message, "");
        }
      },
    );
    return passengerfilterres;
  }

  public relationshipSelected() {
    let that = this;
    if (that.Config.Filters[0].filterValue == null) {
      that.validRelationShip = true;
      return that.validRelationShip;
    } else {
      that.validRelationShip = false;
      return that.validRelationShip;
    }
  }

  getGuardians() {
    let that = this;
    let guardianfilter = [];
    that._clientGurdiansService
      .getDataTableRows(that.getFilterModel())
      .subscribe(
        (Response: any) => {
          that.guardianfilterres = Response.Guardians;

          Response.Guardians.forEach(function (value) {
            let data = {
              Id: value.Id,
              name: value.FirstName + " " + value.LastName,
              MobileNumber: that.getMobileNumber(value.MobileNumber),
              Email: value.Email,
            };
            guardianfilter.push(data);
          });
        },
        error => {
          if (error.status == 401) {
            that._toastrService.error("UnAuthorized.", "");
            window.location.href = "/pages/authentication/login";
          } else {
            that._toastrService.error(error.error.Message, "");
          }
        },
      );
    return guardianfilter;
  }

  public getFilterModel() {
    let that = this;
    let filterModel: ClientGurdiansFilterModel =
      new ClientGurdiansFilterModel();
    filterModel.archived = false;
    filterModel.isBlocked = false;
    filterModel.clientId = that._currentUser.client_id;
    filterModel.limit = 200;
    filterModel.offset = 0;
    filterModel.order_by = "asc";
    filterModel.viewType = "ClientGuardians";
    filterModel.searchTerm = "";
    filterModel.studentId = 0;
    return filterModel;
  }

  public gurdianSelected() {
    let that = this;
    if (that.Config.Filters[2].filterValue == null) {
      that.validGuardian = true;
      return that.validGuardian;
    } else {
      that.validGuardian = false;
      return that.validGuardian;
    }
  }

  getMobileNumber(mobileNumber: string) {
    let that = this;
    return that._helperService.phoneNumber(mobileNumber);
  }

  onTapSelected(input: any) {
    let that = this;
    that.validGuardian = false;
    for (const control of Object.keys(that.newGuardianForm.controls)) {
      that.newGuardianForm.controls[control].markAsUntouched();
    }
    if (input.index == 1) {
      that.addExistingTap = true;
      that.addNewTap = false;
    } else {
      that.addExistingTap = false;
      that.addNewTap = true;
    }
  }

  close(model: any) {
    let that = this;
    for (const control of Object.keys(that.newGuardianForm.controls)) {
      that.newGuardianForm.controls[control].markAsUntouched();
    }
    that.newGuardianForm.reset();
    that.validStudent = false;
    that.validGuardian = false;
    that.validRelationShip = false;
    that.addExistingTap = false;
    this.openRestPass = false;
    that.addNewTap = true;
    that.data = null;
    model.close("Accept click");
  }

  onSubmit(modal: any) {
    let that = this;
    that.submitted = true;
    if (that.studentId != 0) {
      if (that.isValidForm() && that.addExistingTap) {
        that.getExistingForm(modal);
      } else if (!that.isValidForm() && that.addNewTap) {
        that.getAddForm(modal);
      }
    } else {
      if (that.isValidForm() && that.addExistingTap) {
        if (
          that.checkGuardianExist(Number(that.newGuardianForm.value.guardianId))
        ) {
          that.Added.emit({ data: that.getAddGuardianObject() });
          that.close(modal);
        }
      } else if (!that.isValidForm() && that.addNewTap) {
        that.checkGuardianToAdd(
          that.newGuardianForm.value.PhoneNumber.replace(/\D/g, ""),
          that.newGuardianForm.value.Email,
          modal,
        );
      }
    }
  }

  getAddGuardianObject() {
    let that = this;
    let data = new GuardianDataModel();
    let account = new AccountStudentDataModel();
    if (
      that.addExistingTap &&
      Number(that.newGuardianForm.value.guardianId) != 0
    ) {
      data.AccountId = that.newGuardianForm.value.guardianId.toString();
      let guardian = that.guardianfilterres.filter(
        x => x.Id == Number(that.newGuardianForm.value.guardianId),
      );
      let account = new AccountStudentDataModel();
      account.FirstName = guardian[0].FirstName;
      account.LastName = guardian[0].LastName;
      account.Email = guardian[0].Email;
      account.MobileNumber = guardian[0].MobileNumber;
      data.Account = account;

      data.StudentId = that.studentId;
      data.RelationshipToStudent = that.newGuardianForm.value.relationshipName;
    } else {
      account.FirstName = that.newGuardianForm.value.FirstName;
      account.LastName = that.newGuardianForm.value.LastName;
      account.Email = that.newGuardianForm.value.Email;
      account.MobileNumber = that.newGuardianForm.value.PhoneNumber.replace(
        /\D/g,
        "",
      );
      data.Account = account;
      data.Account.Id =
        that.data?.Account && that.data?.Account.Id != 0
          ? that.data?.Account.Id
          : 0;

      data.RelationshipToStudent = that.newGuardianForm.value.relationshipName;
      data.StudentId = that.studentId;
      data.AccountId = "0";
      data.isClientPortal = true;
      data.Id = that.data && that.data.Id != 0 ? that.data.Id : 0;
    }
    return data;
  }

  closeConfiramtion() {
    let that = this;
    that.guardianexist = false;
  }

  checkEmailExist(email: any, modal: any): any {
    let that = this;
    let exist = false;
    that._clientStudentService.checkExistEmail(0, email).subscribe(
      response => {
        if (
          response != null &&
          that.gridData.filter(e => e.Account.Email == email).length > 0
        ) {
          let message =
            "The email entered already exists, please enter another one.";
          that._toastrService.error(message, "");
          exist = false;
        } else {
          exist = true;
        }
        if (exist) {
          that.Added.emit({ data: that.getAddGuardianObject() });
          that.close(modal);
          that._appcomponent.toggeleLoader();
        } else that._appcomponent.toggeleLoader();
      },
      error => {
        if (error.status == 401) {
          that._toastrService.error("UnAuthorized.", "");
          window.location.href = "/pages/authentication/login";
          that._appcomponent.toggeleLoader();
          exist = false;
        } else if (error.status == 300) {
          that._toastrService.error(error.error.Message, "");
          that._appcomponent.toggeleLoader();
          exist = false;
        } else {
          that._toastrService.error(
            "The guardian account was not checked due to technical issue.",
            "",
          );
          that._appcomponent.toggeleLoader();
          exist = false;
        }
      },
    );
  }

  checkGuardianToAdd(mobileNumber: any, email: any, modal: any): any {
    let that = this;
    let exist = false;

    that._appcomponent.toggeleLoader();

    that._clientStudentService
      .checkExistMobileNumber(0, mobileNumber)
      .subscribe(
        response => {
          if (
            response != null &&
            that.gridData.filter(e => e.Account.MobileNumber == mobileNumber)
              .length > 0
          ) {
            let message =
              "The phone number entered already exists, please enter another one.";
            that._toastrService.error(message, "");
            exist = false;
          } else {
            exist = true;
          }
          if (exist && email == "") {
            that.Added.emit({ data: that.getAddGuardianObject() });
            that.close(modal);
            that._appcomponent.toggeleLoader();
          } else if (exist && email != "") {
            that.checkEmailExist(email, modal);
          } else {
            that._appcomponent.toggeleLoader();
          }
        },
        error => {
          if (error.status == 401) {
            that._toastrService.error("UnAuthorized.", "");
            window.location.href = "/pages/authentication/login";
            that._appcomponent.toggeleLoader();
            exist = false;
          } else if (error.status == 300) {
            that._toastrService.error(error.error.Message, "");
            that._appcomponent.toggeleLoader();
            exist = false;
          } else {
            that._toastrService.error(
              "The guardian account was not checked due to technical issue.",
              "",
            );
            that._appcomponent.toggeleLoader();
            exist = false;
          }
        },
      );
  }

  checkGuardianExist(Id: any) {
    let that = this;
    if (that.gridData.filter(e => e.AccountId == Id).length > 0) {
      that.guardianexist = true;
      return false;
    }
    return true;
  }

  resetPassword() {
    let that = this;
    this.openRestPass = false;
    that._appcomponent.toggeleLoader();
    this._clientGurdiansService.resetPassword(that.data.AccountId).subscribe(
      Response => {
        that._appcomponent.toggeleLoader();
        that._toastrService.success("The password was rest Successfully.", "");
      },
      error => {
        if (error.status == 401) {
          this._toastrService.error("UnAuthorized.", "");
          that._appcomponent.toggeleLoader();
        } else {
          this._toastrService.error(
            "The guardian account was not checked due to technical issue.",
            "",
          );
        }
        that._appcomponent.toggeleLoader();
      },
    );
  }

  openRestPassPopup() {
    this.openRestPass = true;
  }
  getExistingForm(modal: any) {
    let that = this;
    that._appcomponent.toggeleLoader();
    if (
      that.addExistingTap &&
      Number(that.newGuardianForm.value.guardianId) != 0
    ) {
      that._clientStudentService
        .checkGuardianExist(
          Number(that.newGuardianForm.value.guardianId),
          Number(that.studentId),
          that.getAddGuardianObject(),
        )
        .subscribe(
          (Response: any) => {
            if (Response) {
              that.guardianexist = true;
              that._appcomponent.toggeleLoader();
            } else {
              that.guardianexist = false;
              that._toastrService.success(
                "The guardian account was added successfully.",
                "",
              );
              that.Added.emit(null);
              that.close(modal);
              that._appcomponent.toggeleLoader();
            }
          },
          error => {
            if (error.status == 401) {
              that._toastrService.error("UnAuthorized.", "");
              window.location.href = "/pages/authentication/login";
              that._appcomponent.toggeleLoader();
            } else {
              that._toastrService.error(
                "The guardian account was not added due to technical issue.",
                "",
              );
              that._appcomponent.toggeleLoader();
            }
          },
        );
    }
  }
  getAddForm(modal: any) {
    let that = this;
    //Add
    if (that.data == null || that.data?.Id == 0) {
      that._appcomponent.toggeleLoader();
      that._clientStudentService
        .addNewGuardian(Number(that.studentId), that.getAddGuardianObject())
        .subscribe(
          (Response: any) => {
            that._toastrService.success(
              "The guardian account was added successfully.",
              "",
            );
            that.data = Response;
            let newGuardian = that.getAddGuardianObject();
            that.data.Id = 0;
            that.Added.emit(newGuardian);
            that._appcomponent.toggeleLoader();
            that.close(modal);
          },
          error => {
            if (error.status == 401) {
              that._toastrService.error("UnAuthorized.", "");
              window.location.href = "/pages/authentication/login";
              that._appcomponent.toggeleLoader();
            } else if (error.status == 300) {
              that._toastrService.error(error.error.Message, "");
              that._appcomponent.toggeleLoader();
            } else {
              that._toastrService.error(
                "The guardian account was not added due to technical issue.",
                "",
              );
              that._appcomponent.toggeleLoader();
            }
          },
        );
    }
    //edit
    else {
      that._appcomponent.toggeleLoader();
      that._clientStudentService
        .editGuardian(
          Number(that.studentId),
          that.data.Id,
          that.getAddGuardianObject(),
        )
        .subscribe(
          (Response: any) => {
            that._toastrService.success(
              "The guardian account was updated successfully.",
              "",
            );
            let newGuardian = that.getAddGuardianObject();
            that.Added.emit(newGuardian);
            that._appcomponent.toggeleLoader();
            that.close(modal);
          },
          error => {
            if (error.status == 401) {
              that._toastrService.error("UnAuthorized.", "");
              window.location.href = "/pages/authentication/login";
              that._appcomponent.toggeleLoader();
            } else if (error.status == 300) {
              that._toastrService.error(error.error.Message, "");
              that._appcomponent.toggeleLoader();
            } else {
              that._toastrService.error(
                "The guardian account was not added due to technical issue.",
                "",
              );
              that._appcomponent.toggeleLoader();
            }
          },
        );
    }
  }
}
