import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation,
} from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { AuthenticationService } from "app/auth/service";
import { ToastrService } from "ngx-toastr";
import { User } from "app/auth/models";
import { ClientStudentsService } from "../services/client-students.service";
import { StudentTransportationDetailsModel } from "../model/StudentTransportationDetailsModel";
import { IForm } from "../model/AddEditStudentDataFormModel";
import { IFiltersConfig } from "app/controls/component/filters/interfaces/controls/IFiltersConfig";
import { PermissionsList } from "app/enums/Permissions/PermissionList";
import { ActivatedRoute } from "@angular/router";
@Component({
  selector: "app-student-special-requirements-section",
  templateUrl: "./student-special-requirements-section.component.html",
  styleUrls: ["./student-special-requirements-section.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class StudentSpecialRequirementsSectionComponent
  implements OnInit, OnDestroy
{
  public _currentUser: User;
  @Input("studentId") studentId: number;
  @Input("IndexThree") indexThreeIsLoaded: boolean;
  @Output() Added = new EventEmitter<any>();
  @Input("disable") disable: boolean = true;
  public disabeld: boolean = false;
  public PermessionValue: string;
  opened = false;
  genders = [
    { id: "Female", name: "Female" },
    { id: "Male", name: "Male" },
    { id: "No Preference", name: "No Preference" },
  ];
  clientMonitors = [];
  RideNeeds = [];
  studentTransportationDetailForm: FormGroup;
  @Input() TransportationData: StudentTransportationDetailsModel;
  public Config: IFiltersConfig;
  /**
   * Constructor
   * * @param {AuthenticationService} _authenticationService
   * * @param {HelpersService} _helpersService
   * * @param {ClientGuardiansService} _clientGurdiansService
   * * @param {NgbModal} _modalService
   * * @param {ToastrService} _toastrService
   * * @param {FormBuilder} _formBuilder
   * * @param {AppComponent} _appcomponent
   */
  constructor(
    private _formBuilder: FormBuilder,
    private _authenticationService: AuthenticationService,
    private _toastrService: ToastrService,
    private _clientStudentService: ClientStudentsService,
    private route: ActivatedRoute,
  ) {
    this._authenticationService.currentUser.subscribe(
      x => (this._currentUser = x),
    );
  }

  ngOnDestroy(): void {
    let that = this;
    that.Added.emit(that.studentTransportationDetailForm.value);
  }

  ngOnInit(): void {
    if (this.route.snapshot.paramMap.get("id")) {
      this.PermessionValue = PermissionsList.ClientStudentsEdit.toString();
    } else {
      this.PermessionValue = PermissionsList.ClientStudentsCreate.toString();
    }
    this.disabeld = true;
    let that = this;
    that.getMonitors();
    that.getRideNeeds();

    let studentTransportationDetail: IForm<StudentTransportationDetailsModel> =
      {
        NeedsPlatformProvidedAttendant: [],
        NeedsClientProvidedAttendant: [],
        preferredMonitorGender: [],
        RideNeeds: [],
        ClientMonitor: [],
        IsSoloRider: [],
        NeedsWheelchairVehicle: [],
      };
    that.studentTransportationDetailForm = that._formBuilder.group(
      studentTransportationDetail,
    );

    that.Config = {
      isFiltersEnable: false,
      Filters: [
        {
          filterValues: that.genders,
          filterClear: true,
          filterVisability: true,
          filterPlaceholder: "Select prefferred Monitor Gender",
        },
        {
          filterValues: [],
          filterClear: true,
          filterVisability: true,
          filterPlaceholder: "Select Monitor.",
        },
        {
          filterValues: [],
          filterClear: true,
          filterVisability: true,
          filterPlaceholder: "Select any ride needs.",
          filterMultiple: true,
        },
      ],
    };

    setTimeout(() => {
      that.prepareFormData();
    }, 50);
  }
  prepareFormData() {
    let that = this;
    that.studentTransportationDetailForm.setValue({
      NeedsPlatformProvidedAttendant:
        that.TransportationData.NeedsPlatformProvidedAttendant,
      NeedsClientProvidedAttendant:
        that.TransportationData.NeedsClientProvidedAttendant,
      preferredMonitorGender: that.getPreferedGender(),
      RideNeeds: that.getInitRideNeeds(),
      ClientMonitor: that.getClientMonitor(),
      IsSoloRider: that.TransportationData.IsSoloRider,
      NeedsWheelchairVehicle: that.TransportationData.NeedsWheelchairVehicle,
    });

    if (that.TransportationData.preferredMonitorGender != null) {
      that.Config.Filters[0].filterValue =
        that.TransportationData.preferredMonitorGender;
    } else {
      that.Config.Filters[0].filterValue = that.genders[2].id;
    }
    if (that.checkTheClientMonitor()) {
      that.Config.Filters[1].filterValue =
        that.TransportationData.ClientMonitor;
    }
    if (that.TransportationData.RideNeeds != null) {
      that.Config.Filters[2].filterValue = [];
      that.TransportationData.RideNeeds.forEach(element => {
        if (element.Id) {
          that.Config.Filters[2].filterValue.push(element.Id);
        } else {
          that.Config.Filters[2].filterValue.push(element);
        }
      });
    }
    if (
      !that.studentTransportationDetailForm.value
        .NeedsClientProvidedAttendant &&
      !that.studentTransportationDetailForm.value.NeedsPlatformProvidedAttendant
    ) {
      that.studentTransportationDetailForm
        .get("preferredMonitorGender")
        .disable();
    }
    if (
      !that.studentTransportationDetailForm.value.NeedsClientProvidedAttendant
    ) {
      that.studentTransportationDetailForm.get("ClientMonitor").disable();
    }
  }
  checkTheClientMonitor() {
    let that = this;
    return (
      that.TransportationData?.ClientMonitor != null &&
      parseInt(that.TransportationData.ClientMonitor) != 0
    );
  }
  getPreferedGender() {
    let that = this;
    return that.TransportationData.preferredMonitorGender
      ? that.TransportationData.preferredMonitorGender
      : null;
  }
  getInitRideNeeds() {
    let that = this;
    return that.TransportationData.preferredMonitorGender
      ? that.TransportationData.RideNeeds
      : null;
  }
  getClientMonitor() {
    let that = this;
    return that.TransportationData?.ClientMonitor &&
      parseInt(that.TransportationData.ClientMonitor) != 0
      ? that.TransportationData.ClientMonitor
      : null;
  }
  getMonitors() {
    let that = this;
    this._clientStudentService.getClientMonitors().subscribe(
      Response => {
        Response.Assistants.forEach(element => {
          let monitor = {
            id: element.ClientAssistantId,
            name: element.FirstName + " " + element.LastName,
            LastName: element.LastName,
            PayingClient: element.PayingClient,
          };

          that.clientMonitors.push(monitor);
        });

        this.Config.Filters[1].filterValues = that.clientMonitors;
      },
      error => {
        if (error.status == 401) {
          this._toastrService.error("UnAuthorized.", "");
          window.location.href = "/pages/authentication/login";
        } else {
          this._toastrService.error(error.error.Message, "");
        }
      },
    );
  }

  getRideNeeds() {
    let that = this;
    this._clientStudentService.getRideNeeds().subscribe(
      Response => {
        let rideNeeds = [];
        Response.forEach(element => {
          let rideNeed = {
            Id: element.Id,
            Name: element.Name,
          };

          rideNeeds.push(rideNeed);
        });
        that.RideNeeds = rideNeeds;
        this.Config.Filters[2].filterValues = that.RideNeeds;
      },
      error => {
        if (error.status == 401) {
          this._toastrService.error("UnAuthorized.", "");
          window.location.href = "/pages/authentication/login";
        } else {
          this._toastrService.error(error.error.Message, "");
        }
      },
    );
  }

  clientMonitor() {
    this.studentTransportationDetailForm
      .get("NeedsPlatformProvidedAttendant")
      .setValue(false);

    if (
      !this.studentTransportationDetailForm.value.NeedsClientProvidedAttendant
    ) {
      this.studentTransportationDetailForm
        .get("preferredMonitorGender")
        .disable();
      this.studentTransportationDetailForm.get("ClientMonitor").disable();
    } else {
      this.studentTransportationDetailForm
        .get("preferredMonitorGender")
        .enable();
      this.studentTransportationDetailForm.get("ClientMonitor").enable();
    }
  }

  adroitMonitor() {
    this.studentTransportationDetailForm
      .get("NeedsClientProvidedAttendant")
      .setValue(false);

    if (
      !this.studentTransportationDetailForm.value
        .NeedsPlatformProvidedAttendant &&
      !this.studentTransportationDetailForm.value.NeedsClientProvidedAttendant
    ) {
      this.studentTransportationDetailForm
        .get("preferredMonitorGender")
        .disable();
      this.studentTransportationDetailForm.get("ClientMonitor").disable();
    } else {
      this.studentTransportationDetailForm.get("ClientMonitor").disable();
      this.studentTransportationDetailForm
        .get("preferredMonitorGender")
        .enable();
    }
  }

  getTransportationData() {
    let that = this;

    return that.studentTransportationDetailForm.value;
  }
}
