import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { IFilters } from "../interfaces/controls/IFiltersConfig";

@Component({
  selector: "app-noShowStatus",
  templateUrl: "./no-show-statuse.component.html",
})
export class NoShowReportStatuesComponent implements OnInit {
  @Input("filterData") Config: IFilters;
  @Output() changeValue = new EventEmitter<string>();

  ngOnInit(): void {
    this.Config.filterValues = [
      { id: "No Show", name: "No-show" },
      { id: "Late Cancel", name: "Late Cancel" },
    ];
  }
  Onchange() {
    this.changeValue.emit("complete");
  }
}
