import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { IFilters } from "../interfaces/controls/IFiltersConfig";

@Component({
  selector: "app-date-periods",
  templateUrl: "./date-periods.component.html",
})
export class DatePeriodsComponent implements OnInit {
  @Input("filterData") Config: IFilters;
  @Output() changeValue = new EventEmitter<string>();

  ngOnInit(): void {
    this.Config.filterValues = [
      { id: "Morning", name: "Morning" },
      { id: "Afternoon", name: "Afternoon" },
    ];
  }

  Onchange() {
    this.changeValue.emit("complete");
  }
}
