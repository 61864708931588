export class TransportaionScheduleStudentDataModel {
  StudentId: number;
  GroupId: number;
  Name: string;
  CreatedByAccount: string;
  StudentScheduleDetails: ScheduleStudentDataModel[];
  ServiceStartDate: string;
  ServiceEndDate: string;
  InternalNote: string;
}

export class ScheduleStudentDataModel {
  Id: number;
  DayId: number;
  Type: string;
  PickUpStudentPlaceId: string;
  DropOffStudentPlaceId: string;
  PickUpStudentPlaceName: string;
  DropOffStudentPlaceName: string;
  SpecialTime: string;
}
export class AddTransportaionScheduleStudentDataModel {
  StudentSchedules: TransportaionScheduleStudentDataModel[];
  isAddedFromClientPortal: boolean = true;
}
