<div class="row section-div">
  <div class="col-lg-12">
    <strong id="guardian_block_title">Guardians App User(s)</strong>
  </div>
  <div>
    <kendo-grid
      #mainGrid
      [data]="gridData"
      [pageSize]="state.take"
      [skip]="state.skip"
      [sort]="state.sort"
      [filter]="state.filter"
      [pageable]="false"
    >
      <ng-template kendoGridNoRecordsTemplate>
        No guardians added yet.
      </ng-template>
      <ng-template
        kendoGridToolbarTemplate
        NgClass="p-0"
      >
      </ng-template>
      <kendo-grid-spacer></kendo-grid-spacer>
      <kendo-grid-column
        field="Name"
        title="Name"
        [sortable]="false"
      >
        <ng-template
          kendoGridCellTemplate
          let-dataItem
          let-rowIndex="rowIndex"
        >
          <a
            class="btn btn-link pl-0 pr-0"
            (click)="modalOpenMD(dataItem, rowIndex)"
          >
            {{ dataItem.Account.FirstName + " " + dataItem.Account.LastName }}
          </a>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column
        field="Relationship"
        title="Relationship"
        [sortable]="false"
      >
        <ng-template
          kendoGridCellTemplate
          let-dataItem
          let-rowIndex="rowIndex"
        >
          {{ dataItem.RelationshipToStudent }}
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column
        field="ReachAt"
        title="Reach At"
        [sortable]="false"
      >
        <ng-template
          kendoGridCellTemplate
          let-dataItem
          let-rowIndex="rowIndex"
        >
          <span>
            {{ getMobileNumber(dataItem.Account.MobileNumber) }} <br />
            {{ dataItem.Account.Email }}
          </span>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column
        [sortable]="false"
        title="Action"
        [width]="90"
      >
        <ng-template
          kendoGridCellTemplate
          let-dataItem
          let-rowIndex="rowIndex"
        >
          <div class="d-flex align-items-center">
            <div
              ngbDropdown
              [placement]="'left-top'"
              container="body"
              class="m-auto"
            >
              <a
                ngbDropdownToggle
                href="javascript:void(0);"
                class="hide-arrow"
                id="dropdownBrowserState"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i
                  data-feather="more-vertical"
                  class="text-primary cursor-pointer mr-50"
                ></i>
              </a>
              <div
                ngbDropdownMenu
                class="dropdown-menu-right"
                aria-labelledby="dropdownBrowserState"
                disabled
              >
                <a
                  href="javascript:void(0)"
                  (click)="modalOpenMD(dataItem, rowIndex)"
                  ngbDropdownItem
                  class="d-flex align-items-center"
                >
                  Edit
                </a>
              </div>
            </div>
          </div>
        </ng-template>
      </kendo-grid-column>
    </kendo-grid>
  </div>
  <div class="col-lg-3">
    <button
      type="button"
      rippleeffect=""
      class="btn btn-close waves-effect waves-float waves-light mt-1"
      style="border-color: #1877f2; margin-bottom: 20px; color: #1877f2"
      (click)="modalOpenMD()"
      [hidden]="disabeld"
    >
      Add Guardian App User
    </button>
  </div>
</div>
<app-student-add-new-guardian
  [gridData]="gridData"
  #studentAddNewGuardianComponent
  (Added)="reloadData($event)"
  [disable]="disabeld"
></app-student-add-new-guardian>
