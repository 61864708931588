import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { IFilters } from "../interfaces/controls/IFiltersConfig";

@Component({
  selector: "app-state",
  templateUrl: "./state.component.html",
})
export class StateComponent implements OnInit {
  @Input("filterData") Config: IFilters;
  @Output() changeState = new EventEmitter<string>();

  ngOnInit(): void {
    let states = [
      { id: "AL", name: "AL" },
      { id: "AK", name: "AK" },
      { id: "AZ", name: "AZ" },
      { id: "AR", name: "AR" },
      { id: "CA", name: "CA" },
      { id: "CO", name: "CO" },
      { id: "CT", name: "CT" },
      { id: "DE", name: "DE" },
      { id: "FL", name: "FL" },
      { id: "GA", name: "GA" },
      { id: "HI", name: "HI" },
      { id: "ID", name: "ID" },
      { id: "IL", name: "IL" },
      { id: "IN", name: "IN" },
      { id: "IA", name: "IA" },
      { id: "KS", name: "KS" },
      { id: "KY", name: "KY" },
      { id: "LA", name: "LA" },
      { id: "ME", name: "ME" },
      { id: "MD", name: "MD" },
      { id: "MA", name: "MA" },
      { id: "MI", name: "MI" },
      { id: "MN", name: "MN" },
      { id: "MS", name: "MS" },
      { id: "MO", name: "MO" },
      { id: "MT", name: "MT" },
      { id: "NE", name: "NE" },
      { id: "NV", name: "NV" },
      { id: "NH", name: "NH" },
      { id: "NJ", name: "NJ" },
      { id: "NM", name: "NM" },
      { id: "NY", name: "NY" },
      { id: "NC", name: "NC" },
      { id: "MO", name: "MO" },
      { id: "ND", name: "ND" },
      { id: "OH", name: "OH" },
      { id: "OK", name: "OK" },
      { id: "OR", name: "OR" },
      { id: "PA", name: "PA" },
      { id: "RI", name: "RI" },
      { id: "SC", name: "SC" },
      { id: "SD", name: "SD" },
      { id: "TN", name: "TN" },
      { id: "TX", name: "TX" },
      { id: "UT", name: "UT" },
      { id: "VT", name: "VT" },
      { id: "VA", name: "VA" },
      { id: "WA", name: "WA" },
      { id: "WV", name: "WV" },
      { id: "WI", name: "WI" },
      { id: "WY", name: "WY" },
    ];
    this.Config.filterValues = states;
  }

  Onchange() {
    this.changeState.emit("complete");
  }
}
