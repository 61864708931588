<div
  id="actionBar"
  class="d-flex align-items-end bg-white"
>
  <div class="container-fluid">
    <div class="d-flex w-100 actionBar-content align-items-end">
      <div class="flex-fill">
        <div class="sub-title pb-0">Active Rides</div>
      </div>
    </div>
  </div>
</div>
<br />
<div class="content-wrapper container-fluid">
  <div class="content-body">
    <app-statistics [statisticsList]="statistics"></app-statistics>

    <div class="card">
      <app-collection-filters
        (search)="getGridData()"
        (clearSearch)="getGridData()"
        [filters]="FiltersConfig"
        style="margin-bottom: -23px"
      >
      </app-collection-filters>
      <br />
      <div class="card-body">
        <kendo-grid
          #gridActiveRide
          [kendoGridBinding]="gridView"
          [sortable]="true"
          [pageSize]="pagesize"
          (detailExpand)="getSubGridData($event.dataItem.RideUuid)"
          #dataGridVar
        >
          <ng-template
            kendoGridToolbarTemplate
            NgClass="p-0"
          >
            <div class="col-lg-12 toolbar-control">
              <div class="row">
                <div class="col-md-6 col-12 pb-0">
                  <div
                    class="d-flex justify-content-between align-items-center"
                  >
                    <label class="d-flex align-items-center">
                      Show
                      <select
                        (change)="changeGridIndex()"
                        [(ngModel)]="gridIndex"
                        class="form-control mx-25 form-control-sm ng-pristine ng-valid ng-touched"
                      >
                        <option value="5">5</option>
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                      </select>
                      entries
                    </label>
                  </div>
                </div>
                <div
                  class="col-md-6 col-12 d-flex justify-content-md-end pb-0 pr-0"
                >
                  <label class="d-flex align-items-center mr-0">
                    Search
                    <input
                      [style.width.px]="165"
                      class="form-control form-control-sm ml-25"
                      placeholder="Search"
                      kendoTextBox
                      (input)="onFilter($event)"
                    />
                  </label>
                </div>
              </div>
            </div>
            <kendo-grid-spacer></kendo-grid-spacer>
          </ng-template>

          <kendo-grid-column
            [width]="50"
            field="id"
            [sortable]="false"
            title="#"
          >
            <ng-template
              kendoGridCellTemplate
              let-rowIndex="rowIndex+1"
            >
              {{ rowIndex }}
            </ng-template>
          </kendo-grid-column>

          <kendo-grid-column
            field="RideId"
            title="Ride"
          >
            <ng-template
              kendoGridCellTemplate
              let-dataItem
              let-rowIndex="rowIndex"
            >
              <a
                class="btn btn-link pl-0 pr-0"
                (click)="
                  modalOpenRideBluePrintSheet(
                    RideSummary,
                    dataItem.RideBlueprintUuid
                  )
                "
              >
                {{ dataItem.RideId }}
              </a>
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-column
            field="StudentsName"
            title="Student"
          >
            <ng-template
              kendoGridCellTemplate
              let-dataItem
              let-rowIndex="rowIndex"
            >
              <div *ngIf="dataItem.Students.length > 1">
                <a
                  class="btn btn-link pl-0 pr-0 text-right"
                  ngbPopover="{{ dataItem.Students }}"
                  placement="right"
                  triggers="mouseenter:mouseleave"
                >
                  {{ dataItem.Students[0] }}...
                </a>
              </div>
              <div *ngIf="dataItem.Students.length == 1">
                <a class="btn btn-link pl-0 pr-0 text-right">
                  {{ dataItem.StudentsName }}
                </a>
              </div>
            </ng-template>
          </kendo-grid-column>

          <kendo-grid-column
            field="RideType"
            title="Type"
          >
            <ng-template
              kendoGridCellTemplate
              let-dataItem
              let-rowIndex="rowIndex"
            >
              {{ dataItem.RideType }}
              <span class="hint">({{ dataItem.VehicleTypeName }})</span>
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-column
            field="RideStatusId"
            title="Status"
          >
            <ng-template
              kendoGridCellTemplate
              let-dataItem
              let-rowIndex="rowIndex"
            >
              <div [ngSwitch]="dataItem.RideStatusId">
                <span
                  class="badge badge-pill badge-secondary"
                  *ngSwitchCase="CanceledInAdvance"
                >
                  Early Cancel
                </span>
                <span
                  class="badge badge-pill badge-secondary"
                  *ngSwitchCase="CanceledDueToHoliday"
                >
                  Holiday
                </span>
                <span
                  class="badge badge-pill badge-secondary"
                  *ngSwitchCase="CanceledDueToPandemic"
                >
                  Pandemic Holiday
                </span>
                <span
                  class="badge badge-pill badge-success"
                  *ngSwitchCase="Completed"
                >
                  Completed
                </span>
                <span
                  class="badge badge-pill badge-secondary"
                  *ngSwitchCase="NoShow"
                >
                  No Show
                </span>
                <span
                  class="badge badge-pill badge-secondary"
                  *ngSwitchCase="CanceledLate"
                >
                  Late Cancel
                </span>
                <span
                  class="badge badge-pill badge-warning"
                  *ngSwitchCase="InProgress"
                >
                  In Progress
                </span>
                <span
                  class="badge badge-primary"
                  *ngSwitchDefault
                >
                  Scheduled
                </span>
              </div>
            </ng-template>
          </kendo-grid-column>

          <kendo-grid-column
            field="STA"
            title="STA"
          >
            <ng-template
              kendoGridCellTemplate
              let-dataItem
              let-rowIndex="rowIndex"
            >
              {{
                GetScheduledTime(
                  dataItem.ScheduledFirstArrivalTime,
                  dataItem.ScheduledLastArrivalTime
                )
              }}
            </ng-template>
          </kendo-grid-column>

          <kendo-grid-column
            [sortable]="false"
            title="Action"
            [width]="90"
          >
            <ng-template
              kendoGridCellTemplate
              let-dataItem
              let-rowIndex="rowIndex"
            >
              <div class="d-flex align-items-center">
                <div>
                  <a
                    (click)="openMap(Map, dataItem.RideId)"
                    class="fa fa-map-marker pull-left"
                    aria-hidden="true"
                    style="
                      font-size: 20px;
                      color: #0190fe !important;
                      background-color: #eef0f4;
                    "
                    *ngIf="dataItem.RideStatusId == InProgress"
                  ></a>
                  <a
                    (click)="modalOpenRideMonitorModel(RideActivity, dataItem)"
                    class="fa fa-file-text-o"
                    style="color: #1e64dd"
                    aria-hidden="true"
                    style="
                      font-size: 20px;
                      color: #0190fe !important;
                      background-color: #eef0f4;
                    "
                  ></a>
                </div>
                <!-- openRideActivity(RideActivity,dataItem.RideUuid) -->
                <!-- modalOpenRideMonitorModel(RideActivity, dataItem) -->
                <div
                  ngbDropdown
                  [placement]="'left-top'"
                  container="body"
                  class="m-auto"
                >
                  <a
                    ngbDropdownToggle
                    href="javascript:void(0);"
                    class="hide-arrow"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <i
                      data-feather="more-vertical"
                      class="text-primary cursor-pointer mr-50"
                    ></i>
                  </a>
                  <div
                    ngbDropdownMenu
                    class="dropdown-menu-right"
                    aria-labelledby="dropdownBrowserState"
                    *ngIf="
                      dataItem.Jobs[0].PartnerFirstOrNickName !=
                      'Driver not Assigned'
                    "
                  >
                    <a
                      href="javascript:void(0)"
                      (click)="
                        modalOpenLG(
                          DriverDetails,
                          dataItem.Jobs[0].PartnerId,
                          dataItem.RideUuid,
                          dataItem.Jobs[0].PartnerFirstOrNickName
                        )
                      "
                      ngbDropdownItem
                      class="d-flex align-items-center"
                    >
                      Driver Info
                    </a>
                    <a
                      href="javascript:void(0)"
                      (click)="
                        modalOpenRideMonitorModel(RideActivity, dataItem)
                      "
                      ngbDropdownItem
                      class="d-flex align-items-center"
                    >
                      View Ride Activity
                    </a>
                  </div>
                  <div
                    ngbDropdownMenu
                    class="dropdown-menu-right"
                    aria-labelledby="dropdownBrowserState"
                    *ngIf="
                      dataItem.Jobs[0].PartnerFirstOrNickName ==
                      'Driver not Assigned'
                    "
                  >
                    <a
                      href="javascript:void(0)"
                      [ngStyle]="{ pointerEvents: 'none' }"
                      ngbDropdownItem
                      class="d-flex align-items-center"
                    >
                      Driver Will be Assigned Soon
                    </a>
                    <a
                      href="javascript:void(0)"
                      (click)="
                        modalOpenRideMonitorModel(RideActivity, dataItem)
                      "
                      ngbDropdownItem
                      class="d-flex align-items-center"
                    >
                      View Ride Activity
                    </a>
                  </div>
                </div>
              </div>
            </ng-template>
          </kendo-grid-column>

          <ng-template
            kendoGridDetailTemplate
            let-dataItem
          >
            <div>
              <kendo-grid
                [kendoGridBinding]="dataItem.subGrid"
                [sortable]="true"
                [scrollable]="false"
                [pageSize]="pagesize"
              >
                <kendo-grid-column
                  [width]="50"
                  [sortable]="false"
                  title="Stop #"
                >
                  <ng-template
                    kendoGridCellTemplate
                    let-dataItem
                    let-rowIndex="rowIndex+1"
                  >
                    {{ rowIndex }}
                    <a
                      style="box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0)"
                      rippleEffect
                      [ngbPopover]="elseBlock1"
                      placement="right"
                      [popoverTitle]="toTitleCase(dataItem.Status)"
                      container="body"
                      triggers="mouseenter:mouseleave"
                      ><span [class]="GetColorClass(dataItem.StatusId)"></span
                    ></a>
                    <ng-template #elseBlock1
                      ><span
                        style="display: block; width: 1000px !important"
                        [outerHTML]="GetPopoverData(dataItem)"
                      ></span
                    ></ng-template>
                  </ng-template>
                </kendo-grid-column>

                <kendo-grid-column
                  field="ETA"
                  title="ETA"
                  [width]="100"
                >
                  <ng-template
                    kendoGridCellTemplate
                    let-dataItem
                    let-rowIndex="rowIndex"
                  >
                    {{ GetETAData(dataItem) }}
                  </ng-template>
                </kendo-grid-column>

                <kendo-grid-column
                  field="Place"
                  title="Place"
                  [width]="150"
                >
                  <ng-template
                    kendoGridCellTemplate
                    let-dataItem
                    let-rowIndex="rowIndex"
                  >
                    {{ dataItem.Name }}
                    <br />
                    <span class="hint">
                      {{ dataItem.Location.Street1 }},
                      {{ dataItem.Location.City }},
                      {{ dataItem.Location.State }}
                      {{ dataItem.Location.Zipcode }}
                    </span>
                  </ng-template>
                </kendo-grid-column>

                <kendo-grid-column
                  field="Passengers"
                  title="Passenger(s)"
                  [width]="200"
                  [headerStyle]="{ 'padding-left': '26px' }"
                >
                  <ng-template
                    kendoGridCellTemplate
                    let-dataItem
                    let-rowIndex="rowIndex"
                  >
                    <div
                      *ngFor="let Passenger of dataItem.Cargo"
                      style="margin-top: 6px"
                      class="col-lg-12"
                    >
                      <span
                        *ngIf="Passenger.ActionType == 'Pick-up'"
                        class="k-icon k-i-arrow-up"
                      ></span>
                      <span
                        *ngIf="Passenger.ActionType != 'Pick-up'"
                        class="k-icon k-i-arrow-down"
                      ></span>
                      {{ GetPassengersName(Passenger) }}
                      <!-- {{ GetDataItems(dataItem) }} -->
                      <span class="hint">
                        ({{
                          Passenger.CargoType == "Assistant"
                            ? "Client Monitor"
                            : Passenger.CargoType == "Aide" ||
                                Passenger.CargoType == "Attendant" ||
                                Passenger.CargoType == "Attentdant"
                              ? "Monitor"
                              : Passenger.CargoType
                        }})
                      </span>

                      <a
                        *ngIf="
                          Passenger.ActionType == 'Pick-up' &&
                          Passenger.RideStatusId != CanceledInAdvance &&
                          Passenger.RideStatusId != CanceledLate &&
                          Passenger.RideStatusId != CanceledDueToHoliday &&
                          Passenger.RideStatusId != NoShow &&
                          dataItem.RideStatusId != Completed &&
                          Passenger.RideStatusId != PickedUp
                        "
                        href="javascript:void(0);"
                        id="dropdownBrowserState"
                        (click)="
                          openCancelTransportation(
                            dataItem.RideUuid,
                            Passenger.RideWaypointId,
                            Passenger.Id
                          )
                        "
                        style="
                          color: #1877f2 !important;
                          margin-left: 14px;
                          font-size: 11px;
                        "
                      >
                        Cancel Transportation
                      </a>
                      <div
                        *ngIf="
                          Passenger.RideStatusId == CanceledInAdvance ||
                          Passenger.RideStatusId == CanceledLate ||
                          Passenger.RideStatusId == CanceledDueToHoliday ||
                          dataItem.RideStatusId == NoShow ||
                          dataItem.RideStatusId == Completed ||
                          (dataItem.RideStatusId == InProgress &&
                            Passenger.RideStatusId != None)
                        "
                        class="col-lg-5 float-right"
                      >
                        <div [ngSwitch]="Passenger.RideStatusId">
                          <span
                            class="badge badge-pill badge-secondary"
                            *ngSwitchCase="CanceledInAdvance"
                          >
                            Early Cancel
                          </span>
                          <span
                            class="badge badge-pill badge-secondary"
                            *ngSwitchCase="CanceledDueToHoliday"
                          >
                            Holiday
                          </span>
                          <span
                            class="badge badge-pill badge-secondary"
                            *ngSwitchCase="CanceledDueToPandemic"
                          >
                            Pandemic Holiday
                          </span>
                          <div *ngSwitchCase="PickedUp">
                            <span class="badge badge-pill badge-success"
                              >Picked Up</span
                            >
                          </div>
                          <span
                            class="badge badge-pill badge-secondary"
                            *ngSwitchCase="NoShow"
                          >
                            No Show
                          </span>
                          <span
                            class="badge badge-pill badge-secondary"
                            *ngSwitchCase="CanceledLate"
                          >
                            Late Cancel
                          </span>
                          <div *ngSwitchCase="DroppedOff">
                            <span class="badge badge-pill badge-success"
                              >Dropped Off</span
                            >
                          </div>
                          <span
                            class="badge badge-primary"
                            *ngSwitchDefault
                          >
                            Scheduled
                          </span>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </kendo-grid-column>

                <kendo-grid-column
                  [sortable]="false"
                  title="Action"
                  [width]="50"
                >
                  <ng-template
                    kendoGridCellTemplate
                    let-dataItem
                    let-rowIndex="rowIndex"
                  >
                    <div class="d-flex align-items-center">
                      <div
                        ngbDropdown
                        [placement]="'left-top'"
                        container="body"
                        class="m-auto"
                      >
                        <a
                          ngbDropdownToggle
                          href="javascript:void(0);"
                          class="hide-arrow"
                          id="dropdownBrowserState"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <i
                            data-feather="more-vertical"
                            class="text-primary cursor-pointer mr-50"
                          ></i>
                        </a>
                        <div
                          ngbDropdownMenu
                          class="dropdown-menu"
                          aria-labelledby="dropdownBrowserState"
                        >
                          <a
                            href="javascript:void(0)"
                            (click)="
                              GetSubGridRideActivity(
                                dataItem.RideUuid,
                                dataItem.RideId,
                                dataItem.Id,
                                rowIndex + 1
                              )
                            "
                            ngbDropdownItem
                            class="d-flex align-items-center"
                          >
                            View Ride Activity Log
                          </a>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </kendo-grid-column>
              </kendo-grid>
            </div>
          </ng-template>
        </kendo-grid>

        <div class="col-lg-12 text-center Load-More-section">
          <button
            [disabled]="pagesize >= gridView.length"
            class="btn btn-light btn-Load-More btn-sm"
            (click)="viewMore()"
          >
            load more
          </button>
          <div class="page-count ng-star-inserted">
            {{ pagesize >= gridView.length ? gridView.length : pagesize }}
            of {{ gridView.length }} record
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal-size-lg d-inline-block">
  <!-- Modal -->
  <ng-template
    #RideSummary
    let-modal
  >
    <div class="modal-header">
      <h5
        class="modal-title"
        id="myModalLabel160"
      >
        Ride Blueprint Sheet #{{ rideBlueprintSheetData.Id }}
      </h5>
      <button
        type="button"
        class="close"
        (click)="modal.dismiss('Cross click')"
        aria-label="Close"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div
      class="modal-body"
      tabindex="0"
      ngbAutofocus
    >
      <div class="card-body invoice-padding py-0">
        <div class="my-2">
          <div
            class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0"
          >
            <div>
              <div class="logo-wrapper">
                <h5 class="text-primary invoice-logo">Basic Details</h5>
              </div>
            </div>
          </div>
          <hr class="m-0 mb-2" />
        </div>
        <!-- Invoice Note starts -->
        <div class="row">
          <div class="col-lg-6 col-md-12 col-sm-12 pb-0">
            <div class="d-flex bd-highlight info-container">
              <div class="w-30 info-title">Type</div>
              <div class="w-70 info-value">
                {{ rideBlueprintSheetData.Type }}
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-md-12 col-sm-12 pb-0">
            <div class="d-flex bd-highlight info-container">
              <div class="w-30 info-title">Service</div>
              <div class="w-70 info-value">
                {{ rideBlueprintSheetData.VehicleTypeName }}
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-md-12 col-sm-12 pb-0">
            <div class="d-flex bd-highlight info-container">
              <div class="w-30 info-title">Period</div>
              <div class="w-70 info-value">
                {{ rideBlueprintSheetData.Period }}
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-md-12 col-sm-12 pb-0">
            <div class="d-flex bd-highlight info-container">
              <div class="w-30 info-title">Student(s)</div>
              <div class="w-70 info-value">
                {{ rideBlueprintSheetData.NumberOfCargo }}
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-md-12 col-sm-12 pb-0">
            <div class="d-flex bd-highlight info-container">
              <div class="w-30 info-title">Duration</div>
              <div class="w-70 info-value">
                {{
                  FormatDurationInMinutes(
                    rideBlueprintSheetData.DurationInMinutes
                  )
                }}
                ({{ rideBlueprintSheetData.DistanceInMiles }} mi)
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-md-12 col-sm-12 pb-0">
            <div class="d-flex bd-highlight info-container">
              <div class="w-30 info-title">Monitor(s)</div>
              <div class="w-70 info-value">
                {{ rideBlueprintSheetData.NumberOfAttendants }}
              </div>
            </div>
          </div>
        </div>
        <!-- Invoice Note ends -->
      </div>
      <div class="invoice-preview-card mb-2">
        <!-- Header starts -->
        <div class="card-body invoice-padding pb-0">
          <div class="my-2">
            <div
              class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0"
            >
              <div>
                <div class="logo-wrapper">
                  <h5 class="text-primary invoice-logo">Ride Overview</h5>
                </div>
              </div>
            </div>
            <hr class="mt-0 mb-2" />
          </div>
          <div
            class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0"
          >
            <div class="w-100">
              <ul class="timeline">
                <li
                  *ngFor="
                    let wayPoint of rideBlueprintSheetData.Waypoints;
                    index as indexOfelement
                  "
                  class="timeline-item"
                >
                  <div class="timeline-time timeline-stop-time">
                    {{ wayPoint.ScheduledArrivalTime | date: "hh:mm a" }}
                  </div>

                  <span
                    class="badge badge-secondary bg-info Stop-span pt-1 pb-1"
                  >
                    Stop {{ indexOfelement + 1 }}
                  </span>
                  <span
                    class="timeline-point timeline-point-info timeline-point-indicator"
                  ></span>
                  <div class="timeline-event">
                    <div class="row">
                      <div class="col-lg-12 pb-0">
                        <p
                          class="Time-line-title text-primary bold mb-0 d-inline"
                        >
                          {{ wayPoint.Name }}
                        </p>
                      </div>
                      <div class="col-lg-12 pb-0">
                        <p class="pl-1 Time-line-title Text-secondary">
                          {{ wayPoint.FullAddress }}
                        </p>
                      </div>
                    </div>
                    <hr />
                    <div class="row">
                      <div class="col-lg-12 pb-0">
                        <div class="row">
                          <div class="col-lg-6 pb-0">
                            <div class="row">
                              <div class="col-lg-12 pb-0">
                                <p
                                  class="Time-line-title text-primary bold mb-0"
                                >
                                  {{ wayPoint.ActionType }}
                                </p>
                              </div>
                              <div
                                *ngFor="let cargoValue of wayPoint.Cargo"
                                class="col-lg-12 pb-0"
                              >
                                <p class="pl-1 Time-line-title Text-secondary">
                                  {{ cargoValue.Name }}
                                  <span class="hint">
                                    ({{
                                      cargoValue.CargoType == "Assistant"
                                        ? "Client Monitor"
                                        : cargoValue.CargoType == "Aide" ||
                                            cargoValue.CargoType ==
                                              "Attendant" ||
                                            cargoValue.CargoType == "Attentdant"
                                          ? "Monitor"
                                          : cargoValue.CargoType
                                    }})
                                  </span>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12 mt-1">
                      <p
                        *ngIf="
                          rideBlueprintSheetData.ScheduleTimeAdjustments != null
                        "
                        class="Time-line-title text-primary ride-sub-title bold mb-0 d-inline"
                      >
                        Weekly Schedule Adjustments
                      </p>
                      <ul class="disc-list ride-Schedule-list">
                        <li
                          *ngFor="
                            let ScheduleTimeAdjustment of rideBlueprintSheetData.ScheduleTimeAdjustments
                          "
                        >
                          {{ ScheduleTimeAdjustment.TypeName }}
                          {{
                            ScheduleTimeAdjustment.RecurrenceDay == "2"
                              ? "every " + "Monday"
                              : ScheduleTimeAdjustment.RecurrenceDay == "3"
                                ? "every " + "Tuesday"
                                : ScheduleTimeAdjustment.RecurrenceDay == "4"
                                  ? "every " + "Wednesday"
                                  : ScheduleTimeAdjustment.RecurrenceDay == "5"
                                    ? "every " + "Thursday"
                                    : ScheduleTimeAdjustment.RecurrenceDay ==
                                        "6"
                                      ? "every " + "Friday"
                                      : ScheduleTimeAdjustment.RecurrenceDay ==
                                          "7"
                                        ? "every " + "Saturday"
                                        : "every " + "Sunday"
                          }}
                          at
                          {{
                            moment(
                              wayPoint.ScheduledArrivalTime,
                              ScheduleTimeAdjustment.AdjustedDurationInMinutes
                            )
                          }}
                        </li>
                      </ul>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="card-body invoice-padding py-0">
        <div class="row">
          <div class="col-lg-6">
            <div class="my-1">
              <div
                class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0"
              >
                <div>
                  <div class="logo-wrapper">
                    <h5 class="text-primary invoice-logo">Equipment Needed</h5>
                  </div>
                </div>
              </div>
              <hr class="m-0" />
            </div>
            <!-- Invoice Note starts -->
            <div class="row">
              <div class="col-12 pb-0">
                <div>
                  <p
                    *ngIf="rideBlueprintSheetData.Equipment.length <= 0"
                    class="mb-75 text-secondary w-30 d-inline-block"
                  >
                    None required.
                  </p>
                  <div
                    *ngIf="rideBlueprintSheetData.Equipment.length > 0"
                    class="mb-75 text-secondary w-30 d-inline-block"
                  >
                    <p
                      *ngFor="
                        let equipment1 of rideBlueprintSheetData.Equipment
                      "
                    >
                      {{ equipment1.NumberOfEquipment }} x {{ equipment1.Name }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="my-1">
              <div
                class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0"
              >
                <div>
                  <div class="logo-wrapper">
                    <h5 class="text-primary invoice-logo">Calendars</h5>
                  </div>
                </div>
              </div>
              <hr class="m-0" />
            </div>
            <!-- Invoice Note starts -->
            <div class="row">
              <div class="col-12 pb-0">
                <p
                  *ngIf="rideBlueprintSheetData.Destinations.length <= 0"
                  class="mb-75 text-secondary d-inline-block"
                >
                  None.
                </p>
                <div
                  *ngIf="rideBlueprintSheetData.Destinations.length > 0"
                  class="mb-75 text-secondary d-inline-block"
                >
                  <p
                    *ngFor="let calendar of rideBlueprintSheetData.Destinations"
                  >
                    {{ calendar.PlaceScheduleName }} ({{
                      calendar.PlaceScheduleNickName
                    }}) - {{ calendar.Name }}
                  </p>
                </div>
              </div>
              <div></div>
            </div>
          </div>
        </div>
      </div>

      <div
        *ngIf="rideBlueprintSheetData.PlaceInstructions.length > 0"
        class="card-body invoice-padding py-0"
      >
        <div class="my-1">
          <div
            class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0"
          >
            <div>
              <div class="logo-wrapper">
                <h5 class="text-primary invoice-logo">Place Instructions</h5>
              </div>
            </div>
          </div>
          <hr class="m-0" />
        </div>
        <div class="row">
          <div class="col-12 pb-0">
            <table class="table adr-table">
              <caption>
                Instruction Table
              </caption>
              <thead class="thead-default">
                <tr>
                  <th>#</th>
                  <th>Type</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  *ngFor="
                    let instructios of rideBlueprintSheetData.PlaceInstructions;
                    index as indexOfelement
                  "
                  role="row"
                  class="odd"
                >
                  <td>
                    {{ indexOfelement + 1 }}
                  </td>

                  <td class="col-sm-2">
                    {{ instructios.Type }}
                    <br />
                  </td>

                  <td>
                    <div class="text-break">
                      {{ instructios.Description }}
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div class="card-body invoice-padding py-0">
        <div class="my-1">
          <div
            class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0"
          >
            <div>
              <div class="logo-wrapper">
                <h5 class="text-primary invoice-logo">Passenger(s)</h5>
              </div>
            </div>
          </div>
          <hr class="m-0" />
        </div>
        <div class="row">
          <div class="col-12 pb-0">
            <table class="table adr-table">
              <caption>
                Equipment Table
              </caption>
              <thead class="thead-default">
                <tr>
                  <th>#</th>
                  <th>Name</th>
                  <th>Contact(s)</th>
                  <th>Equipment</th>
                  <th>Instructions</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  *ngFor="
                    let item of rideBlueprintSheetData.Cargo;
                    index as indexOfelement
                  "
                  role="row"
                  class="odd"
                >
                  <td>
                    {{ indexOfelement + 1 }}
                  </td>

                  <td>
                    {{ item.FullName }} <br />
                    <span class="text-muted">
                      ({{
                        item.CargoType == "Assistant"
                          ? "Client Monitor"
                          : item.CargoType == "Aide" ||
                              item.CargoType == "Attendant" ||
                              item.CargoType == "Attentdant"
                            ? "Monitor"
                            : item.CargoType
                      }})
                    </span>
                    <br />
                  </td>

                  <td>
                    <div class="text-break">
                      {{ ContactPassengers(item.Contacts) }}
                    </div>
                  </td>

                  <td>
                    <div
                      *ngIf="
                        rideBlueprintSheetData.Cargo[0].Equipment.length == 0
                      "
                      class="d-block"
                    >
                      <p>None required</p>
                    </div>

                    <div class="d-block">
                      {{ Equipments(item.Equipment) }}
                      <br />
                    </div>
                  </td>

                  <td>
                    <button
                      type="button"
                      rippleEffect
                      class="btn btn-link pl-0 pr-0 text-left"
                      [ngbPopover]="elseBlock"
                      placement="right"
                      triggers="mouseenter:mouseleave"
                    >
                      View Instructions
                    </button>
                    <ng-template #elseBlock
                      ><span
                        style="display: block; width: 500px; height: 500px"
                        [outerHTML]="CargoInstructions(item)"
                      ></span
                    ></ng-template>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="card-body invoice-padding py-0">
        <div class="my-1">
          <div
            class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0"
          >
            <div>
              <div class="logo-wrapper">
                <h5 class="text-primary invoice-logo">Destination(s)</h5>
              </div>
            </div>
          </div>
          <hr class="m-0" />
        </div>
        <div class="row">
          <div class="col-12 pb-0">
            <table class="table adr-table">
              <caption>
                Equipment Table
              </caption>
              <thead class="thead-default">
                <tr>
                  <th>#</th>
                  <th>Name</th>
                  <th>Contact(s)</th>
                  <th>Note(s)</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  *ngFor="
                    let destination of rideBlueprintSheetData.Destinations;
                    index as indexOfelement
                  "
                  role="row"
                  class="odd"
                >
                  <td>
                    {{ indexOfelement + 1 }}
                  </td>

                  <td>
                    {{ destination.Name }}
                  </td>

                  <td
                    *ngFor="let contact of rideBlueprintSheetData.Destinations"
                  >
                    <div class="d-block">
                      {{ ContactDestinations(contact.Contacts) }}
                    </div>
                  </td>

                  <td>
                    <div *ngIf="destination.Note != ''">
                      <button
                        type="button"
                        rippleEffect
                        class="btn btn-link pl-0 pr-0 text-Paying-Client text-left"
                        ngbPopover="{{ destination.Note }}"
                        placement="right"
                        triggers="mouseenter:mouseleave"
                      >
                        View Note
                      </button>
                    </div>
                    <div *ngIf="destination.Note == ''">-</div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-close waves-effect waves-float waves-light"
          (click)="modal.close('Accept click')"
          rippleEffect
          style="border-color: #1877f2; color: #1877f2"
        >
          Close
        </button>
      </div>
    </div>
  </ng-template>
  <!-- / Modal -->
</div>

<kendo-dialog
  title="Cancel Transportation for {{ GetCancelTransportaionName() }}"
  *ngIf="CancelTransportationopened"
  (close)="closeCancelTransportation('cancel')"
  [minWidth]="250"
  [width]="600"
>
  <div class="invoice-preview-card mb-2">
    <div class="card-body invoice-padding pb-0 px-0 pt-0">
      <div
        class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0"
      >
        <div>
          <div class="logo-wrapper">
            <h5 class="text-primary invoice-logo">Reason</h5>
          </div>
        </div>
      </div>
    </div>
    <hr class="invoice-spacing mt-0 mb-0" />
    <div class="card-body invoice-padding px-0">
      <div class="row">
        <div class="col-lg-12">
          <div class="form-group">
            <label for="accountTextarea">
              The reason for why you are canceling this passenger’s
              transportation.
            </label>
            <app-typea-head #note></app-typea-head>
            <div
              style="
                list-style: none;
                color: #fb434a;
                padding: 0;
                margin: 5px 0 0 0;
                font-size: 80%;
                font-weight: 400;
              "
              *ngIf="disabled == false"
            >
              This field is required.
            </div>
          </div>
          <div>
            <div
              class="hint"
              style="text-transform: none"
            >
              <span>
                Note: This action will notify the driver and any monitors via
                push notification.
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <kendo-dialog-actions>
    <div class="modal-footer">
      <button
        type="button"
        (click)="closeCancelTransportation('no')"
        rippleeffect=""
        class="btn btn-close waves-effect waves-float waves-light"
        style="border-color: #1877f2; margin-bottom: 20px; color: #1877f2"
      >
        Close
      </button>
      <button
        type="button"
        (click)="loadCancelationType()"
        rippleeffect=""
        class="btn btn-info waves-effect waves-float waves-light"
        style="margin-bottom: 20px; margin-right: 20px"
      >
        Cancel Transportation
      </button>
    </div>
  </kendo-dialog-actions>
</kendo-dialog>

<div class="modal-size-lg d-inline-block">
  <!-- Modal -->
  <ng-template
    #RideActivity
    let-modal
    class="Ride-Modal"
  >
    <div class="modal-header">
      <h5
        class="modal-title"
        id="myModalLabel160"
      >
        Ride #{{ rideMonitorModel.RideId }}
      </h5>

      <button
        type="button"
        class="close"
        (click)="modal.dismiss('Cross click')"
        aria-label="Close"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div
      class="modal-body"
      tabindex="0"
      ngbAutofocus
    >
      <div class="card-body invoice-padding px-0 pb-0">
        <div
          class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0"
        >
          <div>
            <div class="logo-wrapper">
              <h5 class="text-primary invoice-logo">Waypoints</h5>
            </div>
          </div>
        </div>
      </div>
      <hr class="mt-0 mb-2" />

      <div class="row">
        <div class="col-lg-12">
          <kendo-grid
            [kendoGridBinding]="rideWaypoints"
            [sortable]="true"
            [scrollable]="false"
          >
            <kendo-grid-column
              [width]="90"
              [sortable]="false"
              title="Stop #"
            >
              <ng-template
                kendoGridCellTemplate
                let-rowIndex="rowIndex+1"
              >
                {{ rowIndex }}
              </ng-template>
            </kendo-grid-column>

            <kendo-grid-column
              field="ScheduledArrivalTime"
              [width]="100"
              title="Arrival"
            >
              <ng-template
                kendoGridCellTemplate
                let-dataItem
              >
                {{ dataItem.ScheduledArrivalTime | date: "shortTime" }}
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-column
              field="Location"
              title="Place"
            >
              <ng-template
                kendoGridCellTemplate
                let-dataItem
                let-rowIndex="rowIndex"
              >
                {{ dataItem.Name }}
                <span class="hint">
                  {{ dataItem.Location.Street1 }} {{ dataItem.Location.City }}
                  {{ dataItem.Location.State }}
                  {{ dataItem.Location.Zipcode }}
                </span>
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-column
              field="Cargo"
              title="Passenger(s)	"
              [headerStyle]="{ 'padding-left': '20px' }"
            >
              <ng-template
                kendoGridCellTemplate
                let-dataItem
                let-rowIndex="rowIndex"
              >
                <div
                  *ngFor="let Passenger of dataItem.Cargo"
                  style="margin-top: 6px"
                >
                  <span
                    class="k-icon k-i-arrow-up"
                    *ngIf="Passenger.ActionType == 'Pick-up'"
                  ></span>
                  <span
                    class="k-icon k-i-arrow-down"
                    *ngIf="Passenger.ActionType == 'Drop-off'"
                  ></span>
                  {{ Passenger.FirstName }} {{ Passenger.LastName }}
                  <span class="hint">({{ Passenger.CargoType }})</span>
                  <div
                    [ngSwitch]="Passenger.RideStatusId"
                    class="col-lg-5 float-right"
                    style="margin-right: 40px"
                  >
                    <span
                      class="badge badge-success"
                      *ngSwitchCase="PickedUp"
                    >
                      Picked Up
                    </span>
                    <span
                      class="badge badge-warning"
                      *ngSwitchCase="NoShow"
                    >
                      No Show
                    </span>
                    <span
                      class="badge badge-success"
                      *ngSwitchCase="DroppedOff"
                    >
                      Dropped Off
                    </span>
                    <span
                      class="badge badge-danger"
                      *ngSwitchCase="NotDroppedOff"
                    >
                      Not Dropped Off
                    </span>
                    <span
                      class="badge badge-secondary"
                      *ngSwitchCase="CanceledInAdvance"
                    >
                      Early Cancel
                    </span>
                    <span
                      class="badge badge-secondary"
                      *ngSwitchCase="CanceledDueToHoliday"
                    >
                      Holiday
                    </span>
                    <span
                      class="badge badge-secondary"
                      *ngSwitchCase="CanceledLate"
                    >
                      Late Cancel
                    </span>
                  </div>
                </div>
              </ng-template>
            </kendo-grid-column>

            <kendo-grid-column
              [width]="90"
              [sortable]="false"
              title="Action"
              [width]="90"
            >
              <ng-template
                kendoGridCellTemplate
                let-dataItem
                let-rowIndex="rowIndex"
              >
                <div class="d-flex align-items-center">
                  <div
                    ngbDropdown
                    [placement]="'left-top'"
                    container="body"
                    class="m-auto"
                  >
                    <a
                      ngbDropdownToggle
                      href="javascript:void(0);"
                      class="hide-arrow"
                      id="dropdownBrowserState"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <i
                        data-feather="more-vertical"
                        class="text-primary cursor-pointer mr-50"
                      ></i>
                    </a>
                    <div
                      ngbDropdownMenu
                      class="dropdown-menu-right"
                      aria-labelledby="dropdownBrowserState"
                    >
                      <a
                        href="javascript:void(0)"
                        (click)="
                          OpenRideActivityLog(
                            dataItem.RideUuid,
                            dataItem.Cargo[0].RideWaypointId,
                            rowIndex + 1
                          )
                        "
                        ngbDropdownItem
                        class="d-flex align-items-center"
                      >
                        View Activity Log
                      </a>
                    </div>
                  </div>
                </div>
              </ng-template>
            </kendo-grid-column>
          </kendo-grid>
        </div>
      </div>

      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-close waves-effect waves-float waves-light"
          style="border-color: #1877f2; color: #1877f2"
          (click)="modal.close('Accept click')"
          rippleEffect
        >
          Close
        </button>
      </div>
    </div>
  </ng-template>
  <!-- / Modal -->
</div>

<kendo-dialog
  title="Activity Log Details for Ride #{{
    rideMonitorModel.RideId
  }} - Waypoint #{{ rowNumber }}"
  *ngIf="opened"
  (close)="close('cancel')"
  [minWidth]="250"
  [width]="900"
>
  <kendo-grid
    [kendoGridBinding]="rideActivityLog"
    [sortable]="true"
    [scrollable]="false"
  >
    <kendo-grid-column
      [sortable]="false"
      field="#"
      [width]="50"
      title=" #"
    >
      <ng-template
        kendoGridCellTemplate
        let-rowIndex="rowIndex+1"
      >
        {{ rowIndex }}
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column
      field="ActionType"
      [sortable]="false"
      title="Action"
    >
    </kendo-grid-column>
    <kendo-grid-column
      field="CargoName"
      [sortable]="false"
      title="Passenger"
    >
      <ng-template
        kendoGridCellTemplate
        let-dateItem
      >
        <div *ngIf="dateItem.CargoName == ''">-</div>
        <div *ngIf="dateItem.CargoName != ''">{{ dateItem.CargoName }}</div>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column
      field="DoneAtUtc"
      [sortable]="false"
      title="Done At"
    >
      <ng-template
        kendoGridCellTemplate
        let-dateItem
      >
        {{ ConvertDateUinx(dateItem.DoneAtUtc) }} (EEST)
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column
      field="DistanceFromAddress"
      [sortable]="false"
      title="Distance From Address"
    >
      <ng-template
        kendoGridCellTemplate
        let-dateItem
      >
        <div *ngIf="dateItem.DistanceFromAddress > 0">
          {{ dateItem.DistanceFromAddress }} miles
        </div>
        <div *ngIf="dateItem.DistanceFromAddress == 0">N/A</div>
      </ng-template>
    </kendo-grid-column>
  </kendo-grid>

  <kendo-dialog-actions>
    <div class="modal-footer">
      <button
        type="button"
        (click)="close('no')"
        rippleeffect=""
        class="btn btn-close waves-effect waves-float waves-light"
        style="border-color: #1877f2; color: #1877f2"
      >
        Close
      </button>
    </div>
  </kendo-dialog-actions>
</kendo-dialog>

<kendo-dialog
  title="Activity Log Details for Ride #{{ rideId }} - Waypoint #{{
    subRowNumber
  }}"
  *ngIf="subOpened"
  (close)="close('cancel')"
  [minWidth]="250"
  [width]="900"
>
  <kendo-grid
    [kendoGridBinding]="subGridRideActivityLog"
    [sortable]="true"
    [scrollable]="false"
  >
    <kendo-grid-column
      [sortable]="false"
      field="#"
      [width]="50"
      title=" #"
    >
      <ng-template
        kendoGridCellTemplate
        let-rowIndex="rowIndex+1"
      >
        {{ rowIndex }}
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column
      field="ActionType"
      [sortable]="false"
      title="Action"
    >
    </kendo-grid-column>
    <kendo-grid-column
      field="CargoName"
      [sortable]="false"
      title="Passenger"
    >
      <ng-template
        kendoGridCellTemplate
        let-dateItem
      >
        <div *ngIf="dateItem.CargoName == ''">-</div>
        <div *ngIf="dateItem.CargoName != ''">{{ dateItem.CargoName }}</div>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column
      field="DoneAtUtc"
      [sortable]="false"
      title="Done At"
    >
      <ng-template
        kendoGridCellTemplate
        let-dateItem
      >
        {{ ConvertDateUinx(dateItem.DoneAtUtc) }} (EEST)
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column
      field="DistanceFromAddress"
      [sortable]="false"
      title="Distance From Address"
    >
      <ng-template
        kendoGridCellTemplate
        let-dateItem
      >
        <div *ngIf="dateItem.DistanceFromAddress > 0">
          {{ dateItem.DistanceFromAddress }} miles
        </div>
        <div *ngIf="dateItem.DistanceFromAddress == 0">N/A</div>
      </ng-template>
    </kendo-grid-column>
  </kendo-grid>

  <kendo-dialog-actions>
    <div class="modal-footer">
      <button
        type="button"
        (click)="close('no')"
        rippleeffect=""
        class="btn btn-close waves-effect waves-float waves-light"
        style="border-color: #1877f2; color: #1877f2"
      >
        Close
      </button>
    </div>
  </kendo-dialog-actions>
</kendo-dialog>

<div class="modal-size-lg d-inline-block">
  <!-- Modal -->
  <ng-template
    #Map
    let-modal
  >
    <div class="modal-header">
      <h5 class="modal-title">Map</h5>
      <button
        type="button"
        class="close"
        (click)="modal.dismiss('Cross click')"
        aria-label="Close"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="col-lg-8">
      <google-map
        height="500px"
        width="152%"
        [center]="center"
        [zoom]="zoom"
      >
        <map-marker
          #marker="mapMarker"
          *ngFor="let ridesWaypoint of ridesWaypointsData"
          [position]="ridesWaypoint.MarkerPositions"
          (mapClick)="openInfoWindow(marker, mapinfowindow, ridesWaypoint)"
          [icon]="ridesWaypoint.markerOptions.icon"
        ></map-marker>
        <map-marker
          #marker1="mapMarker"
          [position]="rideLocation"
          (mapClick)="openDriverInfo(marker1, mapinfowindow, rideLocation.info)"
          [icon]="rideLocation.icon.url"
        ></map-marker>
        <map-info-window #mapinfowindow="mapInfoWindow"></map-info-window>
        <map-info-window #mapDriverInfoWindow="mapInfoWindow"></map-info-window>
      </google-map>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-close waves-effect waves-float waves-light"
        style="border-color: #1877f2; color: #1877f2"
        (click)="modal.close('Accept click')"
        rippleEffect
      >
        Close
      </button>
    </div>
  </ng-template>
  <!-- / Modal -->
</div>

<div class="modal-size-lg d-inline-block">
  <!-- Modal -->
  <ng-template
    #DriverDetails
    let-modal
  >
    <div class="modal-header">
      <h5
        class="modal-title"
        id="myModalLabel160"
      >
        Driver Details for {{ driverProfile.Name }} #{{ driverProfile.Id }}
      </h5>
      <button
        type="button"
        class="close"
        (click)="modal.dismiss('Cross click')"
        aria-label="Close"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div
      class="modal-body"
      tabindex="0"
      ngbAutofocus
    >
      <div class="invoice-preview-card mb-2">
        <div class="card-body invoice-padding pb-0">
          <div
            class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0"
          >
            <div>
              <div class="logo-wrapper">
                <h5 class="text-primary invoice-logo">Vehicle Details</h5>
              </div>
            </div>
          </div>
        </div>
        <hr class="invoice-spacing mt-0 mb-0" />
        <div class="card-body invoice-padding">
          <div class="row">
            <div class="col-lg-6 col-md-12 col-sm-12 pb-0">
              <div class="d-flex bd-highlight info-container">
                <div class="w-30 info-title">Year</div>
                <div class="w-70 info-value">
                  {{ driverProfile.VehicleYear }}
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-md-12 col-sm-12 pb-0">
              <div class="d-flex bd-highlight info-container">
                <div class="w-30 info-title">Color</div>
                <div class="w-70 info-value">
                  {{ driverProfile.VehicleColor }}
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-md-12 col-sm-12 pb-0">
              <div class="d-flex bd-highlight info-container">
                <div class="w-30 info-title">Make</div>
                <div class="w-70 info-value">
                  {{ driverProfile.VehicleMake }}
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-md-12 col-sm-12 pb-0">
              <div class="d-flex bd-highlight info-container">
                <div class="w-30 info-title">Model</div>
                <div class="w-70 info-value">
                  {{ driverProfile.VehicleModel }}
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-md-12 col-sm-12 pb-0">
              <div class="d-flex bd-highlight info-container">
                <div class="w-30 info-title">Plate #</div>
                <div class="w-70 info-value">
                  {{ driverProfile.VehiclePlateNumber }}
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-md-12 col-sm-12 pb-0">
              <div class="d-flex bd-highlight info-container">
                <div class="w-30 info-title">Classification</div>
                <div class="w-70 info-value">
                  {{ driverProfile.VehicleClassification }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="modal-footer">
        <button
          type="button"
          (click)="modal.close('Accept click')"
          rippleEffect
          class="btn btn-close waves-effect waves-float waves-light"
          style="border-color: #1877f2; color: #1877f2"
        >
          Close
        </button>
      </div>
    </div>
  </ng-template>
  <!-- / Modal -->
</div>
