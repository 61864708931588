import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { RideStatusTypeEnum } from "../../../../enums/lookups/LookupEnum";
import { IFilters } from "../interfaces/controls/IFiltersConfig";

@Component({
  selector: "app-statues",
  templateUrl: "./statues.component.html",
})
export class StatuesComponent implements OnInit {
  @Input("filterData") Config: IFilters;
  @Output() changeValue = new EventEmitter<string>();

  ngOnInit(): void {
    this.Config.filterValues = [
      { id: <any>RideStatusTypeEnum.Completed, name: "Completed" },
      { id: <any>RideStatusTypeEnum.NoShow, name: "No-show" },
      { id: <any>RideStatusTypeEnum.CanceledLate, name: "Late Cancel" },
      { id: <any>RideStatusTypeEnum.CanceledInAdvance, name: "Early Cancel" },
      { id: <any>RideStatusTypeEnum.CanceledDueToHoliday, name: "Holiday" },
      { id: <any>RideStatusTypeEnum.CanceledDueToPandemic, name: "Pandemic" },
    ];
  }
  Onchange() {
    this.changeValue.emit("complete");
  }
}
