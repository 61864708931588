import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { AuthenticationService } from "../../auth/service";

@Injectable({
  providedIn: "root",
})
export class HomeService {
  constructor(
    private _httpClient: HttpClient,
    private _authenticationService: AuthenticationService,
  ) {}

  getStatistics() {
    return this._httpClient.get<any>(
      `${environment.apiUrl}/chart-statistics?FunName=ClientDashboard&ClientId=${this._authenticationService.currentUserValue.client_id}`,
    );
  }
}
