import { NgModule, ModuleWithProviders, ViewChild } from "@angular/core";
import { RouterModule } from "@angular/router";

import { CoreCommonModule } from "@core/common.module";
import { CoreSidebarModule } from "@core/components";

import { NavbarModule } from "app/layout/components/navbar/navbar.module";
import { ContentModule } from "app/layout/components/content/content.module";
import { MenuModule } from "app/layout/components/menu/menu.module";
import { FooterModule } from "app/layout/components/footer/footer.module";

import { VerticalLayoutComponent } from "app/layout/vertical/vertical-layout.component";
import { LayoutService } from "@core/services/layout.service";
import { MenudropdownDirective } from "@core/directives/menudropdown.directive";
import { LeftPanelComponent } from "../common/left-panel/left-panel.component";
import { HeaderComponent } from "../common/header/header.component";
import { ContentSectionComponent } from "../common/content-section/content-section.component";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";

@NgModule({
  declarations: [
    VerticalLayoutComponent,
    MenudropdownDirective,
    LeftPanelComponent,
    HeaderComponent,
    ContentSectionComponent,
  ],
  imports: [
    RouterModule,
    CoreCommonModule,
    CoreSidebarModule,
    NavbarModule,
    MenuModule,
    ContentModule,
    FooterModule,
    NgbModule,
  ],
  exports: [VerticalLayoutComponent, MenudropdownDirective],
})
export class VerticalLayoutModule {
  @ViewChild(HeaderComponent) HeaderComponent: HeaderComponent;

  static forRoot(): ModuleWithProviders<VerticalLayoutModule> {
    return {
      ngModule: VerticalLayoutModule,
      providers: [LayoutService],
    };
  }
}
