import {
  Component,
  Input,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { AuthenticationService } from "app/auth/service";
import { ToastrService } from "ngx-toastr";
import { User } from "app/auth/models";
import { AppComponent } from "app/app.component";
import { HelpersService } from "app/main/SharedServices/helpers.service";
import { State } from "@progress/kendo-data-query";
import { StudentAddNewGuardianComponent } from "./StudentAddNewGuardian/student-add-new-guardian.component";
import { ClientStudentsService } from "../services/client-students.service";
import { Router } from "@angular/router";
@Component({
  selector: "app-gurdians-student-section",
  templateUrl: "./gurdians-student-section.component.html",
  styleUrls: ["./gurdians-student-section.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class GurdiansStudentSectionComponent implements OnInit {
  public _currentUser: User;
  public rowIndex: number = -1;
  opened = false;
  @Input("studentId") studentId: number;
  @Input("disable") disable: boolean = true;
  public disabeld: boolean = false;
  @ViewChild("studentAddNewGuardianComponent")
  addNewGuardianComponent: StudentAddNewGuardianComponent;
  @Input() gridData: any = [];
  grideView: any = [];
  dataObject: any;
  state: State = {
    skip: 0,
    take: 25,
    group: [],
    filter: { filters: [], logic: "or" },
    sort: [
      {
        field: "name",
        dir: "asc",
      },
    ],
  };

  /**
   * Constructor
   * * @param {AuthenticationService} _authenticationService
   * * @param {HelpersService} _helpersService
   * * @param {ClientGuardiansService} _clientGurdiansService
   * * @param {NgbModal} _modalService
   * * @param {ToastrService} _toastrService
   * * @param {FormBuilder} _formBuilder
   * * @param {AppComponent} _appcomponent
   */

  constructor(
    private _authenticationService: AuthenticationService,
    private _helperService: HelpersService,
    private _clientStudentService: ClientStudentsService,
    private _toastrService: ToastrService,
    private _appComponent: AppComponent,
    private _router: Router,
  ) {
    this._authenticationService.currentUser.subscribe(
      x => (this._currentUser = x),
    );
  }

  ngOnInit(): void {
    this.disabeld = !this.disable;

    let that = this;
    if (that.studentId != 0) {
      that.loadStudentGuardians();
    }
  }

  modalOpenMD(data: any = null, rowIndex?: number) {
    let that = this;
    that.opened = true;
    that.addNewGuardianComponent.data = data;
    if (data != null) {
      that.addNewGuardianComponent.data = that.gridData[rowIndex];
    }
      that.rowIndex = rowIndex == null ? that.gridData.length : rowIndex;
    that.addNewGuardianComponent.open();
  }

  reloadData(result: any) {
    let that = this;
    if (that.studentId != 0) {
      that.loadStudentGuardians();
    } else {
      that.reloadDataNew(result);
    }
  }

  reloadDataNew(result) {
    let that = this;
    if (that.rowIndex != -1) {
      if (
        that.gridData[that.rowIndex] == undefined ||
        that.gridData[that.rowIndex] == null
      ) {
        that.gridData.push(JSON.parse(JSON.stringify(result.data)));
      } else {
        that.gridData[that.rowIndex] = JSON.parse(JSON.stringify(result.data));
      }
    } else {
      that.gridData.push(JSON.parse(JSON.stringify(result.data)));
    }
  }
  /*Guardians*/
  loadStudentGuardians() {
    let that = this;
    that._appComponent.toggeleLoader();
    that._clientStudentService.getStudentGuardians(that.studentId).subscribe(
      (Response: any) => {
        that.gridData = [];
        that.gridData = Response;
        that._appComponent.toggeleLoader();
      },
      error => {
        if (error.status == 401) {
          that._toastrService.error("UnAuthorized.", "");
          that._router.navigate(["/customer-success/students"]);
          that._appComponent.toggeleLoader();
        } else {
          that._toastrService.error(
            "The guardians account was not loaded due to a technical issue.",
            "",
          );
          that._appComponent.toggeleLoader();
        }
      },
    );
  }

  getMobileNumber(mobileNumber: string) {
    let that = this;
    return that._helperService.phoneNumber(mobileNumber);
  }

  unlinkFromStudent(data: any, rowIndex: number = 0) {
    let that = this;
    if (!data.Id) {
      that.gridData.splice(rowIndex, 1);
    } else {
      //unlink by api on edit Student
    }
  }
}
