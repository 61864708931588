import { Component, EventEmitter, Input, Output } from "@angular/core";
import { IFilters } from "../interfaces/controls/IFiltersConfig";

@Component({
  selector: "app-gurdians-app-users-filter",
  templateUrl: "./gurdians-app-users.component.html",
  styleUrls: ["./gurdians-app-users.component.scss"],
})
export class GurdiansAppUsersFilterComponent {
  @Input("filterData") Config: IFilters;
  @Output() changeValue = new EventEmitter<string>();

  Onchange() {
    this.changeValue.emit("complete");
  }
}
