import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { CoreCommonModule } from "@core/common.module";
import { ContentHeaderModule } from "app/layout/components/content-header/content-header.module";
import { RideBlueprintSheetComponent } from "./ride-blueprint-sheet/rideBlueprintSheet.component";
import { BrowserModule } from "@angular/platform-browser";
import { DataTablesModule } from "angular-datatables";
import { QuillModule } from "ngx-quill";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { NgSelectModule } from "@ng-select/ng-select";
import { OwlDateTimeModule, OwlNativeDateTimeModule } from "ng-pick-datetime";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import { CsvModule } from "@ctrl/ngx-csv";
import { GridModule, ExcelModule } from "@progress/kendo-angular-grid";
import { StatisticsModule } from "../../controls/component/statistics/statistics/statistics.module";
import { FiltersModule } from "../../controls/component/filters/filters.module";
import { PermissionsList } from "app/enums/Permissions/PermissionList";
import { AuthorizationGuard } from "app/auth/helpers/authorization.guard";
import { AuthGuard } from "app/auth/helpers";

const routes = [
  {
    path: "RouteSheet/RouteSheet",
    component: RideBlueprintSheetComponent,
    data: {
      animation: "home",
      PermissionPageName: [PermissionsList[PermissionsList.RideBlueprintView]],
    },
    canActivate: [AuthGuard, AuthorizationGuard],
  },
];

@NgModule({
  declarations: [RideBlueprintSheetComponent],
  exports: [RideBlueprintSheetComponent],
  imports: [
    GridModule,
    RouterModule.forChild(routes),
    ContentHeaderModule,
    QuillModule.forRoot(),
    NgxDatatableModule.forRoot({
      messages: {
        emptyMessage: '<div class="text-center">No records available</div>', // Message to show when array is presented, but contains no values
        totalMessage: "total records", // Footer total message
        selectedMessage: "selected", // Footer selected message
      },
    }),
    TranslateModule,
    CoreCommonModule,
    NgbModule,
    BrowserModule,
    DataTablesModule,
    NgSelectModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    CsvModule,
    StatisticsModule,
    ExcelModule,
    FiltersModule,
  ],
})
export class RideBlueprintsModule {}
