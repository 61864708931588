import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AuthenticationService } from "app/auth/service";
import { ToastrService } from "ngx-toastr";
import { TabPosition } from "@progress/kendo-angular-layout";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { IFiltersConfig } from "app/controls/component/filters/interfaces/controls/IFiltersConfig";
import { User } from "app/auth/models";
import { AppComponent } from "app/app.component";

import { ActivatedRoute } from "@angular/router";
import { ClientStudentsService } from "../../services/client-students.service";
import { StudentInstructionModel } from "../../model/StudentInstructionModel";
import { PermissionsList } from "app/enums/Permissions/PermissionList";
@Component({
  selector: "app-student-add-new-service-instruction",
  templateUrl: "./student-add-new-service-instruction.component.html",
  styleUrls: ["./student-add-new-service-instruction.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class StudentAddNewInstructionComponent implements OnInit {
  public submitted = false;
  public PageTitle: string;
  public InstructionTypeFieldvalidation2 = false;
  @Input("GridData") gridData;
  public newInstructionForm: FormGroup;
  public position: TabPosition = "top";
  @ViewChild("AddNewInstructionModal") AddNewInstructionModal: TemplateRef<any>;
  @ViewChild("EditInstructionModal") EditInstructionModal: TemplateRef<any>;
  @Output() Added = new EventEmitter<any>();
  @Input("disable") disable: boolean = false;
  public PermessionValue: string;
  public _currentUser: User;
  studentId = 0;
  public data: any;
  initialDate: Date;
  /**
   * Constructor
   * * @param {AuthenticationService} _authenticationService
   * * @param {HelpersService} _helpersService
   * * @param {ClientStudentsService} _clientStudentService
   * * @param {NgbModal} _modalService
   * * @param {ToastrService} _toastrService
   * * @param {FormBuilder} _formBuilder
   * * @param {AppComponent} _appcomponent
   */
  constructor(
    private _formBuilder: FormBuilder,
    private _authenticationService: AuthenticationService,
    private _toastrService: ToastrService,
    private _modalService: NgbModal,
    private _appcomponent: AppComponent,
    private _clientStudentService: ClientStudentsService,
    private route: ActivatedRoute,
  ) {
    this._authenticationService.currentUser.subscribe(
      x => (this._currentUser = x),
    );
  }

  public visisbilitys = [
    { id: "Admins", name: "Admins" },
    { id: "Clients", name: "Clients" },
    { id: "Drivers", name: "Drivers" },
  ];
  public Config: IFiltersConfig;
  options = ["Pick Up", "Drop Off", "On Board", "Special"];

  ngOnInit(): void {
    this.Config = {
      isFiltersEnable: false,
      Filters: [
        {
          filterValues: this.visisbilitys,
          filterClear: true,
          filterVisability: true,
          filterPlaceholder: "Instruction Visibility",
          filterMultiple: true,
          filterDisabled: true,
        },
      ],
    };
    if (this.route.snapshot.paramMap.get("id")) {
      this.PermessionValue = PermissionsList.ClientStudentsEdit.toString();
      this.studentId = parseInt(this.route.snapshot.paramMap.get("id")!);
    } else {
      this.PermessionValue = PermissionsList.ClientStudentsCreate.toString();
    }

    this.newInstructionForm = this._formBuilder.group({
      Type: ["", [Validators.required]],
      Visibility: ["", [Validators.required]],
      Description: ["", [Validators.required]],
    });
    this.Config.Filters[0].filterValue = this.visisbilitys.map(a => a.id);
    this.newInstructionForm.get("Visibility").disable();
  }

  public isValidForm(): boolean {
    let that = this;
    for (const control of Object.keys(that.newInstructionForm.controls)) {
      that.newInstructionForm.controls[control].markAsTouched();
    }

    if (that.gridData) {
      let filtedData = that.gridData.filter(
        x =>
          x.Type.toLowerCase() ==
          that.newInstructionForm.get("Type").value.toLowerCase(),
      );
      if (filtedData && filtedData.length > 0) {
        that.InstructionTypeFieldvalidation2 = false;
        return that.newInstructionForm.invalid;
      }
    }
    return that.newInstructionForm.invalid;
  }

  public open() {
    if (this.data && this.data != null) {
      this.PageTitle = "Edit Instruction";
      this.newInstructionForm.patchValue({
        Type: this.data.Type,
        Visibility: this.data.Visibility?.map(a => a),
        Description: this.data.Description,
      });
      this.newInstructionForm.get("Visibility").disable();
      this.Config.Filters[0].filterValue = this.data.Visibility?.map(a => a);
      this._modalService.open(this.AddNewInstructionModal, {
        centered: true,
        backdrop: "static",
        size: "lg",
      });
    } else {
      this.PageTitle = "Add Instruction";
      this.newInstructionForm.get("Visibility").disable();
      this.Config.Filters[0].filterValue = this.visisbilitys.map(a => a.id);
      this._modalService.open(this.AddNewInstructionModal, {
        centered: true,
        backdrop: "static",
        size: "lg",
      });
    }
  }

  close(model: any) {
    let that = this;
    for (const control of Object.keys(that.newInstructionForm.controls)) {
      that.newInstructionForm.controls[control].markAsUntouched();
    }
    that.newInstructionForm.reset();
    model.close("Accept click");
    that.data = null;
  }

  onSubmit(modal: any) {
    debugger;
    let that = this;

    that.submitted = true;

    if (that.checkTypeExist()) {
      that._toastrService.warning("Select or enter new type.", "");
      return false;
    }

    if (that.studentId != 0) {
      if (!that.isValidForm()) {
        that._appcomponent.toggeleLoader();
        //Add
        if (that.data == null || that.data?.Id == 0) {
          that._clientStudentService
            .addStuedntInstruction(
              that.getAddInstructionObject(),
              that.studentId,
            )
            .subscribe(
              (Response: any) => {
                let newInstruction = that.getAddInstructionObject();
                newInstruction.Id = Response;
                that._toastrService.success(
                  "The instruction was added successfully.",
                  "",
                );
                that.close(modal);
                that.Added.emit({ data: newInstruction, mode: "add" });
                that._appcomponent.toggeleLoader();
              },
              error => {
                that.errorHandler(
                  error,
                  "The instruction was not added due to technical issue.",
                );
              },
            );
        }
        //Edit
        else {
          that._clientStudentService
            .editStuedntInstruction(
              that.getAddInstructionObject(),
              that.studentId,
            )
            .subscribe(
              (Response: any) => {
                let newInstruction = that.getAddInstructionObject();
                that._toastrService.success(
                  "The  instruction was updated successfully.",
                  "",
                );
                that.close(modal);
                that.Added.emit({ data: newInstruction, mode: "edit" });
                that._appcomponent.toggeleLoader();
              },
              error => {
                that.errorHandler(
                  error,
                  "The instruction was not updated due to a technical issue.",
                );
              },
            );
        }
      }
    } else if (!that.isValidForm()) {
      that.Added.emit({ data: that.getAddInstructionObject(), mode: "add" });
      that.close(modal);
    }
  }

  getAddInstructionObject() {
    let that = this;
    debugger;
    let data = new StudentInstructionModel();
    data.Id = that.data && that.data.Id != 0 ? that.data.Id : 0;
    data.Type = that.newInstructionForm.value.Type;
    data.Visibility = JSON.stringify(this.visisbilitys.map(a => a.id));
    data.Description = that.newInstructionForm.value.Description;
    data.StudentId = that.studentId;
    return data;
  }
  errorHandler(error: any, message: string) {
    let that = this;
    if (error.status == 401) {
      that._toastrService.error("UnAuthorized.", "");
      window.location.href = "/pages/authentication/login";
      that._appcomponent.toggeleLoader();
    } else if (error.status == 300) {
      that._toastrService.error(error.error.Message, "");
      that._appcomponent.toggeleLoader();
    } else {
      that._toastrService.error(message, "");
      that._appcomponent.toggeleLoader();
    }
  }

  checkTypeExist() {
    let that = this;
    //Add
    if (that.data == null || that.data?.Id == 0) {
      return that.gridData
        .filter(a => a != that.data)
        .map(a => a.Type)
        .includes(that.newInstructionForm.value.Type);
    }
    //Edit
    return that.gridData
      .filter(a => a.Id != that.data.Id)
      .map(a => a.Type)
      .includes(that.newInstructionForm.value.Type);
  }
}
