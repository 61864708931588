import { Component, Input } from "@angular/core";
import { IFilters } from "../interfaces/controls/IFiltersConfig";

@Component({
  selector: "app-search-by-ride",
  templateUrl: "./search-by-ride.component.html",
})
export class SearchByRideComponent {
  @Input("filterData") Config: IFilters;
}
