import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from "@angular/common/http";
import { Observable } from "rxjs";

import { environment } from "environments/environment";
import { AuthenticationService } from "app/auth/service";
import { Router } from "@angular/router";

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  /**
   *
   * @param {AuthenticationService} _authenticationService
   */
  constructor(
    private _authenticationService: AuthenticationService,
    private _router: Router,
  ) {}

  /**
   * Add auth header with jwt if user is logged in and request is to api url
   * @param request
   * @param next
   */
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    let currentUser = this._authenticationService.currentUserValue;
    let isLoggedIn = currentUser && currentUser.access_token;
    let isApiUrl = request.url.startsWith(environment.apiUrl);
    if (isLoggedIn && isApiUrl) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${currentUser.access_token}`,
        },
      });
    }
    request.clone({
      setHeaders: {
        "Content-Security-Policy":
          "default-src *; script-src * 'unsafe-inline' 'unsafe-eval'; style-src * 'unsafe-inline' data:; img-src * data:; font-src * data:; connect-src *; object-src *; media-src *; frame-src *; worker-src *; manifest-src *",
      },
    });
    request.clone({
      setHeaders: {
        "Cache-Control": "no-cache, no-store, must-revalidate",
      },
    });
    return next.handle(request);
  }
}
