import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { IFilters } from "../interfaces/controls/IFiltersConfig";

@Component({
  selector: "app-type",
  templateUrl: "./type.component.html",
})
export class TypeComponent implements OnInit {
  @Input("filterData") Config: IFilters;
  @Output() changeState = new EventEmitter<string>();

  ngOnInit(): void {
    let types = [
      { id: "Amusement Park", name: "Amusement Park" },
      { id: "Business", name: "Business" },
      { id: "Community Center", name: "Community Center" },
      { id: "Day Care", name: "Day Care" },
      { id: "Education Center", name: "Education Cente" },
      { id: "Home", name: "Home" },
      { id: "Hospital", name: "Hospital" },
      { id: "Museum", name: "Museum" },
      { id: "Park", name: "Park" },
      { id: "School", name: "School" },
      { id: "School District Office", name: "School District Office" },
      { id: "Youth Center", name: "Youth Center" },
    ];
    this.Config.filterValues = types;
  }

  Onchange() {
    this.changeState.emit("complete");
  }
}
