import { RouterModule } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { CoreCommonModule } from "@core/common.module";
import { ContentHeaderModule } from "app/layout/components/content-header/content-header.module";
import { HomeComponent } from "./home/home.component";
import { BrowserModule } from "@angular/platform-browser";
import { DataTablesModule } from "angular-datatables";
import { QuillModule } from "ngx-quill";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { NgSelectModule } from "@ng-select/ng-select";
import { OwlDateTimeModule, OwlNativeDateTimeModule } from "ng-pick-datetime";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import { CsvModule } from "@ctrl/ngx-csv";
import { AuthGuard } from "app/auth/helpers";
import { AuthorizationGuard } from "app/auth/helpers/authorization.guard";
import { PermissionsList } from "app/enums/Permissions/PermissionList";
//home routes
import { ClientDashboardStatisticsComponent } from "./client-dashboard-statistics/client-dashboard-statistics.component";
import { ControlsModule } from "../../controls/component/custom-input/controls.module";
import { CommonPipesModule } from "../../utilities/customPipes/pipes.common.module";
import { AuthLoginV1Component } from "../pages/authentication/auth-login-v1/auth-login-v1.component";
import { SafePipeModule } from "safe-pipe";
import { NgModule } from "@angular/core";

const routes = [
  {
    path: "home",
    component: HomeComponent,
    data: {
      animation: "home",
      PermissionPageName: [PermissionsList[PermissionsList.ClientHomeView]],
    },
    canActivate: [AuthGuard, AuthorizationGuard],
  },
];

@NgModule({
  declarations: [HomeComponent, ClientDashboardStatisticsComponent],
  imports: [
    RouterModule.forChild(routes),
    ContentHeaderModule,
    QuillModule.forRoot(),
    NgxDatatableModule.forRoot({
      messages: {
        emptyMessage: '<div class="text-center">No records available</div>', // Message to show when array is presented, but contains no values
        totalMessage: "record", // Footer total message
        selectedMessage: "selected", // Footer selected message
      },
    }),
    TranslateModule,
    CoreCommonModule,
    NgbModule,
    ControlsModule,
    CommonPipesModule,
    BrowserModule,
    DataTablesModule,
    NgSelectModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    CsvModule,
    SafePipeModule,
  ],
  exports: [HomeComponent],
  entryComponents: [AuthLoginV1Component],
})
export class DashboardModule {}
