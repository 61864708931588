import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { StatisticsComponent } from "./statistics.component";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";

@NgModule({
  declarations: [StatisticsComponent],
  exports: [StatisticsComponent, NgbModule, StatisticsComponent],
  imports: [CommonModule],
})
export class StatisticsModule {}
