export enum PortalTypeEnum {
  /// <summary>
  /// The admin portal
  /// </summary>
  AdminPortal = 1,
  /// <summary>
  /// The client portal
  /// </summary>
  ClientPortal = 2,
  /// <summary>
  /// The service providers
  /// </summary>
  ServiceProvider = 4,
  /// <summary>
  /// The Business intelligence
  /// </summary>
  BusinessIntelligence = 8,
}
