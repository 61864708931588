import {
  Component,
  Input,
  AfterViewInit,
  AfterViewChecked,
} from "@angular/core";

// ContentHeader component interface
export interface ContentHeader {
  headerTitle: string;
  actionButton: boolean;
  breadcrumb?: {
    type?: string;
    links?: Array<{
      name?: string;
      isLink?: boolean;
      link?: string;
    }>;
  };
}

@Component({
  selector: "app-content-header",
  templateUrl: "./content-header.component.html",
})
export class ContentHeaderComponent implements AfterViewInit, AfterViewChecked {
  // input variable
  @Input() contentHeader: ContentHeader;
  @Input() isInvoiceDetail: boolean;

  ngAfterViewInit() {
      this.hendleDocument();
  }
  ngAfterViewChecked(): void {
      this.hendleDocument();

    }
    hendleDocument() {
        if (this.isInvoiceDetail) {
            document.getElementById("content-header").style.display = "none";
            document.getElementById("content-header").style.paddingLeft = "50px";
            document.getElementById("content-header").style.display = "block";
        } else {
            document.getElementById("content-header").style.display = "none";
            document.getElementById("content-header").style.paddingLeft = "0px";
            document.getElementById("content-header").style.display = "block";
        } }
}
