import {
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ExcelExportData } from "@progress/kendo-angular-excel-export";
import { DataBindingDirective } from "@progress/kendo-angular-grid";
import { process } from "@progress/kendo-data-query";
import * as moment from "moment";
import { ToastrService } from "ngx-toastr";
import { AppComponent } from "../../../app.component";
import { IFiltersConfig } from "../../../controls/component/filters/interfaces/controls/IFiltersConfig";
import { FiltersTypes } from "../../../enums/controls/filters/FiltersTypes";
import { Days } from "../../Enum/Days/days";
import { ClassColour } from "../../Enum/statistics/class-colour";
import { RideBlueprintSheetModel } from "../model/ride-blueprint-sheet-model";
import { RideBlueprintSheetService } from "../service/ride-blueprint-sheet.service";
import { HelpersService } from "app/main/SharedServices/helpers.service";

const { compile } = require("html-to-text"); //https://www.npmjs.com/package/html-to-text
const options = {};
const compiledConvert = compile(options); // options passed here

@Component({
  selector: "app-RideBlueprintSheet",
  templateUrl: "./rideBlueprintSheet.component.html",
  styleUrls: ["./rideBlueprintSheet.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class RideBlueprintSheetComponent implements OnInit {
  @ViewChild(DataBindingDirective) dataBinding: DataBindingDirective;
  @ViewChild("excel") excel: ElementRef<HTMLElement>;

  public contentHeader: object;
  public isFiltersEnable = false;
  public pageSize;
  public gridIndex;
  public gridView: any;
  public gridData: any[] = [];
  public days: any[] = [];
  public rideBlueprintSheetData: RideBlueprintSheetModel;
  public statistics: any[] = [
    {
      divClass: ClassColour.primary,
      label: "Total # of Blueprints",
      value: 0,
      valueLabel: "blueprint(s)",
    },
    {
      divClass: ClassColour.warning,
      label: "# of Outbound Blueprints",
      value: 0,
      valueLabel: "blueprint(s)",
    },
    {
      divClass: ClassColour.success,
      label: "# of Return Blueprints",
      value: 0,
      valueLabel: "blueprint(s)",
    },
    {
      divClass: ClassColour.light,
      label: "# of Students",
      value: 0,
      valueLabel: "student(s)",
    },
    {
      divClass: ClassColour.danger,
      label: "# of Places",
      value: 0,
      valueLabel: "place(s)",
    },
  ];
  public FiltersConfig: IFiltersConfig = {
    isFiltersEnable: false,
    Filters: [
      {
        filterType: FiltersTypes.containerNumber,
        filterPlaceholder: "Filter By Container",
        filterVisability: true,
        filterClear: true,
      },
      {
        filterType: FiltersTypes.bluePrintNumber,
        filterPlaceholder: "Filter By Blueprint",
        filterVisability: true,
        filterClear: true,
      },
      {
        filterType: FiltersTypes.rideTypes,
        filterPlaceholder: "Filter By Ride Type",
        filterVisability: true,
        filterClear: true,
      },
      {
        filterType: FiltersTypes.periods,
        filterPlaceholder: "Filter By  Start Period",
        filterVisability: true,
        filterClear: true,
      },
      {
        filterType: FiltersTypes.Passengers,
        filterPlaceholder: "Filter By Passenger Name",
        filterVisability: true,
        filterClear: true,
      },
      {
        filterType: FiltersTypes.places,
        filterPlaceholder: "Filter By Place Name",
        filterVisability: true,
        filterClear: true,
      },
    ],
  };

  public onFilter(input: Event): void {
    const inputValue = (input.target as HTMLInputElement).value;
    this.gridView = process(this.gridData, {
      filter: {
        logic: "or",
        filters: [
          {
            field: "RideInvoiceContainerId",
            operator: "contains",
            value: inputValue,
          },
          {
            field: "Id",
            operator: "contains",
            value: inputValue,
          },
          {
            field: "Type",
            operator: "contains",
            value: inputValue,
          },
          {
            field: "Destinations",
            operator: "contains",
            value: inputValue,
          },
        ],
      },
    }).data;

    this.dataBinding.skip = 0;
  }

  public viewFilter(): void {
    if (this.isFiltersEnable) {
      this.isFiltersEnable = false;
    } else {
      this.isFiltersEnable = true;
    }
  }

  public GetExcelData = (): ExcelExportData => {
    let result: ExcelExportData = {
      data: this.gridView.slice(),
    };
    this.excel.nativeElement.click();
    return result;
  };

  public viewMore(): void {
    this.pageSize = parseInt(this.pageSize) + parseInt(this.gridIndex);
  }

  public changeGridIndex(): void {
    this.pageSize = this.gridIndex;
  }

  /**
   * Constructor
   *
   * @param {NgbModal} modalService
   * @param {RideBlueprintSheetService} _rideBlueprintSheetService
   * @param _toastrService
   * @param _appComponent
   * @param sanitized
   * @param _appComponent
   * @param helperService
   * @param _helperService
   * @param _sanitized
   */
  constructor(
    private modalService: NgbModal,
    private _rideBlueprintSheetService: RideBlueprintSheetService,
    private _toastrService: ToastrService,
    private _appComponent: AppComponent,
    private _sanitized: DomSanitizer,
    private _helperService: HelpersService,
  ) {
    this._appComponent._mainpageTitle = "Route Sheet";
  }

  /**
   * On init
   */
  ngOnInit() {
    this.getGridData();
    this.getPassengersData();
    this.getPlacesData();
    this.getBluePrintData();
    this.getContainersData();
    this.getPeriodData();
    this.getRideTypesData();
    this.pageSize = 25;
    this.gridIndex = 25;
    this.gridView = this.gridData;
    this.contentHeader = {
      headerTitle: "Home",
      actionButton: true,
      breadcrumb: {
        type: "",
        links: [
          {
            name: "Home",
            isLink: true,
            link: "/",
          },
          {
            name: "Sample",
            isLink: false,
          },
        ],
      },
    };
    this.days = [
      { id: Days.Mon, day: Days[Days.Mon] },
      { id: Days.Tue, day: Days[Days.Tue] },
      { id: Days.Wed, day: Days[Days.Wed] },
      { id: Days.Thu, day: Days[Days.Thu] },
      { id: Days.Fri, day: Days[Days.Fri] },
      { id: Days.Sat, day: Days[Days.Sat] },
      { id: Days.Sun, day: Days[Days.Sun] },
    ];
  }

  modalOpenLG(modalLG, Uuid) {
    this.modalService.open(modalLG, {
      centered: true,
      backdrop: "static",
      size: "lg",
    });

    this.rideBlueprintSheetData = {
      Id: 0,
      Type: "",
      VehicleTypeName: "",
      Period: "",
      NumberOfCargo: 0,
      DurationInMinutes: 0,
      DistanceInMiles: 0,
      NumberOfAttendants: 0,
      ScheduledArrivalTime: "",
      Waypoints: [],
      ScheduleTimeAdjustments: [],
      Equipment: [],
      Destinations: [],
      Cargo: [],
      PickupInstructionsNote: "",
      DropOffInstructionsNote: "",
      Name: "",
      PlaceInstructions: [],
    };

    this._rideBlueprintSheetService.loadRideBlueprintSheetData(Uuid).subscribe(
      response => {
        this._appComponent.toggeleLoader();
        let that = this;
        that.rideBlueprintSheetData = response;
      },
      error => {
        if (error.status == 401) {
          this._toastrService.error("UnAuthorized.", "");
          window.location.href = "/pages/authentication/login";
        } else {
          this._toastrService.error(error.error.Message, "");
        }
      },
    );
    this._appComponent.toggeleLoader();
  }

  getGridData() {
    let that = this;
    let containerFilter = that.FiltersConfig.Filters.filter(
      x => x.filterType == FiltersTypes.containerNumber,
    )[0].filterValue
      ? that.FiltersConfig.Filters.filter(
          x => x.filterType == FiltersTypes.containerNumber,
        )[0].filterValue
      : 0;
    let bluePrintFilter = that.FiltersConfig.Filters.filter(
      x => x.filterType == FiltersTypes.bluePrintNumber,
    )[0].filterValue
      ? that.FiltersConfig.Filters.filter(
          x => x.filterType == FiltersTypes.bluePrintNumber,
        )[0].filterValue
      : 0;
    let rideTypeFilter = that.FiltersConfig.Filters.filter(
      x => x.filterType == FiltersTypes.rideTypes,
    )[0].filterValue
      ? that.FiltersConfig.Filters.filter(
          x => x.filterType == FiltersTypes.rideTypes,
        )[0].filterValue
      : "";
    let periodFilter = that.FiltersConfig.Filters.filter(
      x => x.filterType == FiltersTypes.periods,
    )[0].filterValue
      ? that.FiltersConfig.Filters.filter(
          x => x.filterType == FiltersTypes.periods,
        )[0].filterValue
      : "";
    let passengerFilter = that.FiltersConfig.Filters.filter(
      x => x.filterType == FiltersTypes.Passengers,
    )[0].filterValue
      ? that.FiltersConfig.Filters.filter(
          x => x.filterType == FiltersTypes.Passengers,
        )[0].filterValue
      : 0;
    let placeFilter = that.FiltersConfig.Filters.filter(
      x => x.filterType == FiltersTypes.places,
    )[0].filterValue
      ? that.FiltersConfig.Filters.filter(
          x => x.filterType == FiltersTypes.places,
        )[0].filterValue
      : 0;
    that.PrepareGridData(
      containerFilter,
      bluePrintFilter,
      rideTypeFilter,
      periodFilter,
      passengerFilter,
      placeFilter,
    );
  }
  PrepareGridData(
    containerFilter: any,
    bluePrintFilter: any,
    rideTypeFilter: any,
    periodFilter: any,
    passengerFilter: any,
    placeFilter: any,
  ) {
    this._rideBlueprintSheetService
      .getGridData(
        containerFilter,
        bluePrintFilter,
        rideTypeFilter,
        periodFilter,
        passengerFilter,
        placeFilter,
      )
      .subscribe(
        response => {
          this.gridView = response.Table;
          this.gridData = response.Table;
          this.statistics[0].value = this.gridView.length;
          this.statistics[1].value = this.gridView.filter(
            s => s.Type == "Outbound",
          ).length;
          this.statistics[2].value = this.gridView.filter(
            s => s.Type == "Return",
          ).length;
          let count = 1;
          for (const responseElement of response.Table) {
            responseElement.Type =
              (responseElement.Period == "Morning" ? "AM" : "PM") +
              " " +
              responseElement.Type;
            responseElement.DurationInMinutes =
              this.formatDurationInMinutes(responseElement.DurationInMinutes) +
              " " +
              responseElement.DistanceInMiles +
              " mi";
            responseElement.Index = count;
            count++;
            let arr = responseElement.Destinations.split(",");
            let destinations = "";
            for (const element of arr) {
              destinations += element + "\n";
            }
            responseElement.Destinations = destinations;

            response.Table.forEach(function (rowData) {
              rowData.AssignedDays = response.Table1.filter(
                p => p.Id == rowData.Id,
              );
            });

            let activeDays = "";
            for (let activeDay of this.days) {
              if (this.isAssignedDay(activeDay.id, responseElement.Id))
                activeDays += " " + activeDay.day;
            }
            responseElement.ActiveDays = activeDays;
          }
        },
        error => {
          if (error.status == 401) {
            this._toastrService.error("UnAuthorized.", "");
            window.location.href = "/pages/authentication/login";
          } else {
            this._toastrService.error(error.error.Message, "");
          }
        },
      );
  }
  isAssignedDay(day: number, bluePrintId: number): boolean {
    if (
      this.gridView
        .find(p => p.Id == bluePrintId)
        .AssignedDays.find(q => q.DayId == day)
    ) {
      return true;
    }
    return false;
  }

  formatDurationInMinutes(DurationInMinutes: string) {
    let durationFormattedText = "";
    let durationInMinutes = parseInt(DurationInMinutes);
    if (durationInMinutes < 60) {
      if (durationInMinutes < 9) {
        durationFormattedText = DurationInMinutes + " min";
      } else {
        durationFormattedText = DurationInMinutes + " mins";
      }
    } else {
      let durationInHours = durationInMinutes / 60;
      let remainingInMinutes =
        parseInt(DurationInMinutes) - durationInHours * 60;
      durationFormattedText =
        durationInHours +
        "h " +
        (remainingInMinutes > 0 ? remainingInMinutes + "m" : "");
    }
    return durationFormattedText;
  }

  getPassengersData() {
    let passenger = this.FiltersConfig.Filters.filter(
      x => x.filterType == FiltersTypes.Passengers,
    )[0];
    this._rideBlueprintSheetService.getPassengersData().subscribe(
      response => {
        let passengerfilterres = [];
        response.forEach(function (value) {
          let data = {
            id: value.Id,
            name: value.FirstName + " " + value.LastName,
          };
          passengerfilterres.push(data);
        });
        passenger.filterValues = passengerfilterres;
        this.statistics[3].value = passengerfilterres.length;
        passenger.filterDisabled = false;
      },
      error => {
        if (error.status == 401) {
          this._toastrService.error("UnAuthorized.", "");
          window.location.href = "/pages/authentication/login";
        } else {
          this._toastrService.error(error.error.Message, "");
        }
      },
    );
  }

  getPlacesData() {
    let places = this.FiltersConfig.Filters.filter(
      x => x.filterType == FiltersTypes.places,
    )[0];
    this._rideBlueprintSheetService.getPlacesData().subscribe(
      response => {
        let placefilterres = [];
        response.forEach(function (value) {
          let data = {
            id: value.Id,
            name: value.PlaceName,
          };
          placefilterres.push(data);
        });
        places.filterValues = placefilterres;
        this.statistics[4].value = placefilterres.length;
        places.filterDisabled = false;
      },
      error => {
        if (error.status == 401) {
          this._toastrService.error("UnAuthorized.", "");
          window.location.href = "/pages/authentication/login";
        } else {
          this._toastrService.error(error.error.Message, "");
        }
      },
    );
  }

  getBluePrintData() {
    let bluePrint = this.FiltersConfig.Filters.filter(
      x => x.filterType == FiltersTypes.bluePrintNumber,
    )[0];
    this._rideBlueprintSheetService.getBluePrintData().subscribe(
      response => {
        let bluePrintFilteres = [];
        response.forEach(function (value) {
          let data = {
            id: value.Id,
            name: value.Id,
          };
          bluePrintFilteres.push(data);
        });
        bluePrint.filterValues = bluePrintFilteres;
        bluePrint.filterDisabled = false;
      },
      error => {
        if (error.status == 401) {
          this._toastrService.error("UnAuthorized.", "");
          window.location.href = "/pages/authentication/login";
        } else {
          this._toastrService.error(error.error.Message, "");
        }
      },
    );
  }

  getContainersData() {
    let container = this.FiltersConfig.Filters.filter(
      x => x.filterType == FiltersTypes.containerNumber,
    )[0];
    this._rideBlueprintSheetService.getContainersData().subscribe(
      response => {
        let containerFilteres = [];
        response.forEach(function (value) {
          let data = {
            id: value.RideInvoiceContainerId,
            name: value.RideInvoiceContainerId,
          };
          containerFilteres.push(data);
        });
        container.filterValues = containerFilteres;
        container.filterDisabled = false;
      },
      error => {
        if (error.status == 401) {
          this._toastrService.error("UnAuthorized.", "");
          window.location.href = "/pages/authentication/login";
        } else {
          this._toastrService.error(error.error.Message, "");
        }
      },
    );
  }

  getPeriodData() {
    let period = this.FiltersConfig.Filters.filter(
      x => x.filterType == FiltersTypes.periods,
    )[0];
    let periodFilters = [
      { id: "Morning", name: "Morning (AM)" },
      { id: "Afternoon", name: "Afternoon (PM)" },
    ];
    period.filterValues = periodFilters;
    period.filterDisabled = false;
  }

  getRideTypesData() {
    let rideType = this.FiltersConfig.Filters.filter(
      x => x.filterType == FiltersTypes.rideTypes,
    )[0];
    let rideTypeFilters = [
      { id: "Outbound", name: "Outbound" },
      { id: "Return", name: "Return" },
    ];
    rideType.filterValues = rideTypeFilters;
    rideType.filterDisabled = false;
  }

  moment(date: string, durationInMinutes: number) {
    return moment(date).add("minutes", durationInMinutes).format("hh:mm A");
  }

  ContactPassengers(names) {
    let contactHtml = "";
    for (let name of names) {
      contactHtml +=
        name.FullName +
        " (" +
        name.RelationshipToEntity +
        ") " +
        "| " +
        this._helperService.phoneNumber(name.PhoneNumber) +
        "  ";
    }

    return contactHtml;
  }

  Equipments(names) {
    let equipmentHtml = "";
    for (let name of names) {
      equipmentHtml += name.Name + "  ";
    }

    return equipmentHtml;
  }

  ContactDestinations(names) {
    let contactHtml = "";
    for (let name of names) {
      contactHtml +=
        name.FullName +
        " (" +
        name.ContactType +
        ") " +
        "| " +
        this._helperService.phoneNumber(name.PhoneNumber) +
        "  ";
    }

    return contactHtml;
  }

  CargoInstructions(item) {
    let instructionHtml = "";
    let instructionCount = 0;
    if (item.PickupInstructionsNote != null) {
      let pickupInstructionsNote =
        item.PickupInstructionsNote.split('"').join("");
      instructionHtml +=
        "<span>#StrongStart#Pick-up Instructions#StrongEnd#</span>#NewLine#";
      instructionHtml +=
        "<span>" + pickupInstructionsNote + ".</span>#NewLine#";
    }

    if (item.DropOffInstructionsNote != null) {
      let dropOffInstructionsNote =
        item.DropOffInstructionsNote.split('"').join("");
      if (instructionHtml != "") {
        instructionHtml += "-------------------------------";
      }
      instructionHtml +=
        "<span>#NewLine# #StrongStart#Drop-off Instructions#StrongEnd#</span>#NewLine#";
      instructionHtml +=
        "<span>" + dropOffInstructionsNote + ".</span>#NewLine# ";
    }

    if (item.OnboardInstructionsNote != null) {
      let onboardInstructionsNote =
        item.OnboardInstructionsNote.split('"').join("");
      if (instructionHtml != "") {
        instructionHtml += "-------------------------------";
      }
      instructionHtml +=
        "<span>#NewLine# #StrongStart#Onboard Instructions#StrongEnd#</span>#NewLine# ";
      instructionHtml +=
        "<span>" + onboardInstructionsNote + ".</span>#NewLine# ";
    }

    if (item.SpecialInstructionsNote != null) {
      let specialInstructionsNote =
        item.SpecialInstructionsNote.split('"').join("");
      if (instructionHtml != "") {
        instructionHtml += "-------------------------------";
      }
      instructionHtml +=
        "<span>#NewLine# #StrongStart#Special Instructions#StrongEnd#</span>#NewLine# ";
      instructionHtml +=
        "<span>" + specialInstructionsNote + ".</span>#NewLine# ";
    }
    if (item.OtherInstructionsNote != null) {
      item.OtherInstructionsNote.forEach(element => {
        instructionHtml += `<span>#NewLine# #StrongStart# ${element.Type} #StrongEnd#</span>#NewLine# `;
        instructionHtml +=
          "<span>" + element.Description + ".</span>#NewLine# ";
      });
    }

    let result: string = compiledConvert(instructionHtml, options);
    let finalResutl = result
      .replaceAll("#NewLine#", "<br/>")
      .replaceAll("#StrongStart#", "<strong>")
      .replaceAll("#StrongEnd#", "</strong>");
    return finalResutl;
  }

  CheckInstructions(item) {
    let instructionHtml = "";
    let instructionCount = 0;
    if (item.PickupInstructionsNote != null) {
      let pickupInstructionsNote =
        item.PickupInstructionsNote.split('"').join("");
      instructionHtml +=
        "<span><strong>Pick-up Instructions</strong></span><br/>";
      instructionHtml += "<span>" + pickupInstructionsNote + ".</span><br/>";
    }

    if (item.DropOffInstructionsNote != null) {
      let dropOffInstructionsNote =
        item.DropOffInstructionsNote.split('"').join("");
      if (instructionHtml != "") {
        instructionHtml += "<hr/>";
      }
      instructionHtml +=
        "<span><strong>Drop-off Instructions</strong></span><br/>";
      instructionHtml += "<span>" + dropOffInstructionsNote + ".</span><br/>";
    }

    if (item.OnboardInstructionsNote != null) {
      let onboardInstructionsNote =
        item.OnboardInstructionsNote.split('"').join("");
      if (instructionHtml != "") {
        instructionHtml += "<hr/>";
      }
      instructionHtml +=
        "<span><strong>Onboard Instructions</strong></span><br/>";
      instructionHtml += "<span>" + onboardInstructionsNote + ".</span><br/>";
    }

    if (item.SpecialInstructionsNote != null) {
      let specialInstructionsNote =
        item.SpecialInstructionsNote.split('"').join("");
      if (instructionHtml != "") {
        instructionHtml += "<hr/>";
      }
      instructionHtml +=
        "<span><strong>Special Instructions</strong></span><br/>";
      instructionHtml += "<span>" + specialInstructionsNote + ".</span><br/>";
    }
    item.OtherInstructionsNote?.forEach(element => {
      instructionHtml += instructionHtml != "" ? "<hr/>" : "";
      instructionHtml +=
        "<span><strong>${cargo.OtherInstructionsNote[i].Type} Instructions</strong></span><br/>";
      instructionHtml +=
        "<span>${cargo.OtherInstructionsNote[i].Description}.</span><br/>";
    });

    if (instructionHtml == "") return false;
    return true;
  }

  FormatActiveDate(item) {
    return (
      moment(item.ServiceStartDate).format("MM/DD/YYYY") +
      " - " +
      moment(item.ServiceEndDate).format("MM/DD/YYYY")
    );
  }
}
