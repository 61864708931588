import { NgModule } from "@angular/core";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { NgSelectModule } from "@ng-select/ng-select";
import { Ng2FlatpickrModule } from "ng2-flatpickr";
import { CoreCommonModule } from "@core/common.module";
import { PersonalPhotoComponent } from "./personal-photo.component";
import { DialogsModule } from "@progress/kendo-angular-dialog";
@NgModule({
  declarations: [PersonalPhotoComponent],
  imports: [
    CoreCommonModule,
    NgbModule,
    NgSelectModule,
    Ng2FlatpickrModule,
    DialogsModule,
  ],
  exports: [PersonalPhotoComponent],
})
export class PhotoModule {}
