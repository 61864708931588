import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Self,
  ViewEncapsulation,
} from "@angular/core";
import {
  ControlValueAccessor,
  NgControl,
  Validators,
} from "@angular/forms";

@Component({
  selector: "app-required-field",
  templateUrl: "./required-field.component.html",
  styleUrls: ["./required-field.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class RequiredFieldComponent implements OnInit, ControlValueAccessor {
  @Input() placeholder: string = "";
  @Output() blur: EventEmitter<void> = new EventEmitter<void>();
  @Input() disabled: boolean = false;
  onChange: (value: any) => void = () => {};
  onTouched: () => void = () => {};
  input: string;
  @Input() type: string = "";
  @Input() maxlength: number = 50;
  @Input() Id: string = "";

  constructor(@Self() public controlDir: NgControl) {
    controlDir.valueAccessor = this;
  }

  ngOnInit(): void {
    const control = this.controlDir.control;
    const validators = control?.validator
      ? [control.validator, Validators.required]
      : null;
    control?.clearValidators();
    control?.setValidators(validators);
    control?.updateValueAndValidity();
  }

  writeValue(value: any): void {
    if (this.type == "number" && Number(value) < 0) {
      setTimeout(() => {
        this.input = "0";
      }, 10);
    } else {
      this.input = value;
    }
  }

  registerOnChange(onChange: (value: any) => void): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: () => void): void {
    this.onTouched = onTouched;
  }

  setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
  }
}
