<div
  id="actionBar"
  class="d-flex align-items-end bg-white"
>
  <div class="container-fluid">
    <div class="d-flex w-100 actionBar-content align-items-end">
      <div class="flex-fill">
        <div class="sub-title pb-0">Dashboard</div>
      </div>
    </div>
  </div>
</div>
<br />

<div class="content-wrapper container-fluid">
  <div class="content-body">
    <section id="home-page">
      <app-client-dashboard-statistics></app-client-dashboard-statistics>
      <div
        class="row"
        dragula="card-drag-area"
        id="draggable-cards"
      >
        <div class="col-lg-6 draggable">
          <div class="card {{ isMoveEnable ? 'MoveEnabled' : '' }}">
            <div class="card-header">
              <div
                class="moveIcon"
                *ngIf="isMoveEnable"
              >
                <span
                  [data-feather]="'move'"
                  [class]="'mr-25'"
                ></span>
              </div>

              <h4 class="card-title text-primary">Rides Overview</h4>
              <h6 class="card-title">(Last 7 days)</h6>
            </div>
            <iframe
              style="border: none; overflow: hidden"
              height="480px"
              width="100%"
              id="firstchart"
              [src]="urlSafe | safe : 'resourceUrl'"
              title="Rides Overview"
            ></iframe>
          </div>
        </div>

        <div class="col-lg-6 draggable">
          <div class="card {{ isMoveEnable ? 'MoveEnabled' : '' }}">
            <div class="card-header">
              <div
                class="moveIcon"
                *ngIf="isMoveEnable"
              >
                <span
                  [data-feather]="'move'"
                  [class]="'mr-25'"
                ></span>
              </div>
              <h4 class="card-title text-primary">Today's Rides Statistics</h4>
              <h6 class="card-title">(Every Minute)</h6>
            </div>
            <iframe
              style="border: none; overflow: hidden"
              height="480px"
              width="101%"
              id="firstchart"
              [src]="urlSafe1 | safe : 'resourceUrl'"
              title="Today's Rides Statistics"
            ></iframe>
          </div>
        </div>
      </div>
    </section>
    <!-- Basic Alerts end -->
  </div>
</div>
