import { Component, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { DataBindingDirective } from "@progress/kendo-angular-grid";
import { process } from "@progress/kendo-data-query";
import { SelectEvent } from "@progress/kendo-angular-layout";
import { AppComponent } from "app/app.component";
import moment from "moment";
import { ClassColour } from "../../Enum/statistics/class-colour";
import { FiltersTypes } from "../../../enums/controls/filters/FiltersTypes";
import { IFiltersConfig } from "../../../controls/component/filters/interfaces/controls/IFiltersConfig";
import { ActiveRidesService } from "../service/active-rides.service";
import { ToastrService } from "ngx-toastr";
import { DomSanitizer } from "@angular/platform-browser";
import { RideBlueprintSheetService } from "../../RideBlueprints/service/ride-blueprint-sheet.service";
import { RideBlueprintSheetModel } from "../../RideBlueprints/model/ride-blueprint-sheet-model";
import { RideMonitorModel } from "../../HistoricalRides/model/ride-monitor.model";
import { RideMonitorRideWaypointModel } from "../../HistoricalRides/model/ride-monitor-ride-waypoint.model";
import { RideActivityLogModel } from "../../HistoricalRides/model/ride-activity-log.model";
import { HistoricalRidesServices } from "../../HistoricalRides/services/historical-rides.services";
import { AuthenticationService } from "../../../auth/service";
import { HelpersService } from "app/main/SharedServices/helpers.service";
import { MapInfoWindow, MapMarker } from "@angular/google-maps";
import { DriverProfile } from "app/main/HistoricalRides/model/driver-profile.model";
import { TypeaHeadComponent } from "../typea-head/typea-head.component";
import { environment } from "environments/environment";
import {
  RideStatusTypeEnum,
  RideWaypointCargoTypeEnum,
  RideWaypointTypeEnum,
} from "../../../enums/lookups/LookupEnum";
import { ClientStudentsService } from "app/main/Students/services/client-students.service";
import { RideSheetModel } from "app/main/HistoricalRides/model/ride-sheet.model";
import { RideSheetWaypointModel } from "app/main/HistoricalRides/model/ride-sheet-waypoint.model";

declare var firebase;
declare var firebaseConfig;
const { compile } = require("html-to-text"); //https://www.npmjs.com/package/html-to-text
const options = {};
const compiledConvert = compile(options); // options passed here

@Component({
  selector: "app-active-rides",
  templateUrl: "./active-rides.component.html",
  styleUrls: ["./active-rides.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class ActiveRidesComponent implements OnInit {
  None = RideStatusTypeEnum.None;
  InProgress = RideStatusTypeEnum.InProgress;
  Completed = RideStatusTypeEnum.Completed;
  NoShow = RideStatusTypeEnum.NoShow;
  CanceledDueToHoliday = RideStatusTypeEnum.CanceledDueToHoliday;
  CanceledInAdvance = RideStatusTypeEnum.CanceledInAdvance;
  CanceledLate = RideStatusTypeEnum.CanceledLate;
  CanceledDueToPandemic = RideStatusTypeEnum.CanceledDueToPandemic;
  Deleted = RideStatusTypeEnum.Deleted;
  Offered = RideStatusTypeEnum.Offered;
  Accepted = RideStatusTypeEnum.Accepted;
  Declined = RideStatusTypeEnum.Declined;

  PickedUp = RideWaypointCargoTypeEnum.PickedUp;
  DroppedOff = RideWaypointCargoTypeEnum.DroppedOff;
  NotDroppedOff = RideWaypointCargoTypeEnum.NotDroppedOff;
  SkipDropOff = RideWaypointCargoTypeEnum.SkipDropOff;
  Abscent = RideWaypointCargoTypeEnum.Abscent;

  @ViewChild(DataBindingDirective) dataBinding: DataBindingDirective;
  @ViewChild(MapInfoWindow, { static: false }) infoWindow: MapInfoWindow;
  @ViewChild("mapDriverInfoWindow", { static: false })
  mapDriverInfoWindow: MapInfoWindow;
  @ViewChild("note") typeHeadComponanet: TypeaHeadComponent;
  @ViewChild("gridActiveRide") closeSubGrid;

  /**
   * Constructor
   *
   * @param {NgbModal} modalService
   * @param _appComponent
   * @param _appComponent
   * @param _activeRidesService
   * @param _toastrService
   * @param _appComponent
   * @param _historicalRidesServices
   * @param _rideBlueprintSheetService
   * @param _helperService
   * @param _sanitized
   */
  public initiallyExpanded: boolean = false;
  public contentHeader: object;
  public isFiltersEnable = false;
  public pagesize;
  public gridIndex;
  public opened = false;
  public subOpened = false;
  public CancelTransportationopened = false;

  rowNumber = 0;
  subRowNumber = 0;
  public gridView: any;
  public gridData: any[] = [];
  public subGrid: any[] = [];
  public rideBlueprintSheetData: RideBlueprintSheetModel;
  public rideMonitorModel: RideMonitorModel;
  rideWaypoints: RideMonitorRideWaypointModel[];
  rideActivityLog: RideActivityLogModel[];
  subGridRideActivityLog: RideActivityLogModel[];
  driverProfile: DriverProfile;
  rideActivity: any;
  disabled: boolean = true;
  mapInfo: any[];
  rideInfo: RideSheetModel;
  waypoints: RideSheetWaypointModel[];


  public statistics: any[] = [
    {
      divClass: ClassColour.primary,
      label: "Scheduled",
      value: 0,
      valueLabel: "rides",
    },
    {
      divClass: ClassColour.warning,
      label: "In Progress",
      value: 0,
      valueLabel: "rides",
    },
    {
      divClass: ClassColour.success,
      label: "Serviced",
      value: 0,
      valueLabel: "rides",
    },
    {
      divClass: ClassColour.light,
      label: "Late Cancel",
      value: 0,
      valueLabel: "rides",
    },
    {
      divClass: ClassColour.danger,
      label: "Early Cancel + Holiday",
      value: 0,
      valueLabel: "rides",
    },
  ];

  constructor(
    private modalService: NgbModal,
    private _appComponent: AppComponent,
    private _activeRidesService: ActiveRidesService,
    private _rideBlueprintSheetService: RideBlueprintSheetService,
    private _historicalRidesServices: HistoricalRidesServices,
    private _sanitized: DomSanitizer,
    private _helperService: HelpersService,
    private _authenticationService: AuthenticationService,
    private _toastrService: ToastrService,
    private _clientStudentService: ClientStudentsService,
  ) {
    this._appComponent._mainpageTitle = "Customer Care";
  }

  /**
   * On init
   */
  ngOnInit() {
    this.getGridData();
    this.getPassengersData();
    this.getPlacesData();
    this.pagesize = 25;
    this.gridIndex = 25;
    this.gridView = this.gridData;
    this.contentHeader = {
      headerTitle: "Home",
      actionButton: true,
      breadcrumb: {
        type: "",
        links: [
          {
            name: "Home",
            isLink: true,
            link: "/",
          },
          {
            name: "Sample",
            isLink: false,
          },
        ],
      },
    };
    this._clientStudentService.getGoogleAPIKey().subscribe(data => {
      firebaseConfig.apiKey = data.PropertyValue;
    });
  }

  public onFilter(input: Event): void {
    const inputValue = (input.target as HTMLInputElement).value;

    this.gridView = process(this.gridData, {
      filter: {
        logic: "or",
        filters: [
          {
            field: "RideId",
            operator: "contains",
            value: inputValue,
          },
          {
            field: "RideType",
            operator: "contains",
            value: inputValue,
          },
          {
            field: "StudentsName",
            operator: "contains",
            value: inputValue,
          },
          {
            field: "RideStatusId",
            operator: "contains",
            value: inputValue,
          },
        ],
      },
    }).data;

    this.dataBinding.skip = 0;
  }

  public viewFilter(): void {
    this.isFiltersEnable = !this.isFiltersEnable;
  }

  public viewMore(): void {
    this.pagesize = parseInt(this.pagesize) + parseInt(this.gridIndex);
  }

  public changeGridIndex(): void {
    this.pagesize = this.gridIndex;
  }

  public FiltersConfig: IFiltersConfig = {
    isFiltersEnable: false,
    Filters: [
      {
        filterType: FiltersTypes.periods,
        filterPlaceholder: "Start Period",
        filterVisability: true,
        filterClear: true,
        filtersize: "4",
      },
      {
        filterType: FiltersTypes.Passengers,
        filterPlaceholder: "Select Students",
        filterVisability: true,
        filterClear: true,
        filtersize: "4",
      },
      {
        filterType: FiltersTypes.places,
        filterPlaceholder: "Select Place",
        filterVisability: true,
        filterClear: true,
        filtersize: "4",
      },
    ],
  };

  modalOpenRideBluePrintSheet(modalId, id, partner_id) {
    let that = this;
    this._historicalRidesServices.getRideInfo(partner_id).subscribe(
      Response => {
          that._appComponent.toggeleLoader();
          that.rideInfo = Response;
          that.waypoints = Response.Waypoints;
          this.modalService.open(modalId, {
              centered: true,
              backdrop: "static",
              size: "lg",
          });
      },
      error => {
          if (error.status == 401) {
              this._toastrService.error("UnAuthorized.", "");
              window.location.href = "/pages/authentication/login";
          } else {
              this._toastrService.error(error.error.Message, "");
          }
      },
  );
  that._appComponent.toggeleLoader();
  }

  modalOpenRideMonitorModel(modelId, ride: RideMonitorModel) {
    let that = this;
    that.rideMonitorModel = ride;
    this._historicalRidesServices.getRideWaypoints(ride.RideUuid).subscribe(
      Response => {
        that._appComponent.toggeleLoader();
        that.rideWaypoints = Response;
        this.modalService.open(modelId, { centered: true, size: "xl" });
      },
      error => {
        if (error.status == 401) {
          this._toastrService.error("UnAuthorized.", "");
          window.location.href = "/pages/authentication/login";
        } else {
          this._toastrService.error(error.error.Message, "");
        }
      },
    );
    that._appComponent.toggeleLoader();
  }

  infoContent = "";
  public close(status: string): void {
    console.log(`Dialog result: ${status}`);
    this.opened = false;
    this.subOpened = false;
  }

  public open(): void {
    this.opened = true;
  }
  selectedCancelationCargoRideUuid = "";
  selectedCancelationCargoRideWaypointUuid = 0;
  selectedCancelationCargoUuid = 0;
  public openCancelTransportation(uuid, CargoId, RideWaypointId): void {
    this.selectedCancelationCargoRideUuid = uuid;
    this.selectedCancelationCargoRideWaypointUuid = CargoId;
    this.selectedCancelationCargoUuid = RideWaypointId;
    this.CancelTransportationopened = true;
  }

  public closeCancelTransportation(status: string): void {
    console.log(`Dialog result: ${status}`);
    this.CancelTransportationopened = false;
    this.disabled = true;
  }
  fullName = "";

  loadCancelationType() {
    let that = this;

    if (this.typeHeadComponanet.model != "") {
      that.disabled = true;
      this._activeRidesService
        .loadCancelationType(
          this.selectedCancelationCargoRideUuid,
          this.selectedCancelationCargoRideWaypointUuid,
          this.selectedCancelationCargoUuid,
        )
        .subscribe(
          Response => {
            this.CreateCargoCancelation(Response.RideStatusId);
          },
          error => {
            if (error.status == 401) {
              this._toastrService.error("UnAuthorized.", "");
              window.location.href = "/pages/authentication/login";
            } else {
              this._toastrService.error(error.error.Message, "");
            }
          },
        );
      that.getGridData();
    } else {
      that.disabled = false;
    }
  }

  public CreateCargoCancelation(StatusId) {
    let that = this;
    this._activeRidesService
      .cancelationCargo(
        that.selectedCancelationCargoRideUuid,
        that.selectedCancelationCargoRideWaypointUuid,
        that.selectedCancelationCargoUuid,
        this.typeHeadComponanet.model,
        StatusId,
      )
      .subscribe(
        response => {
          this._appComponent.toggeleLoader();
          this.getGridData();
        },

        error => {
          if (error.status == 401) {
            this._toastrService.error("UnAuthorized.", "");
            window.location.href = "/pages/authentication/login";
          } else {
            this._toastrService.error(error.error.Message, "");
          }
        },
      );
    this._appComponent.toggeleLoader();
    this.CancelTransportationopened = false;
  }

  public onTabSelect(e: SelectEvent): void {
    console.log(e);
  }

  getGridData() {
    let that = this;

    let periodFilter = that.FiltersConfig.Filters.filter(
      x => x.filterType == FiltersTypes.periods,
    )[0].filterValue
      ? that.FiltersConfig.Filters.filter(
          x => x.filterType == FiltersTypes.periods,
        )[0].filterValue
      : "";
    if (periodFilter == "") {
      if (moment().format("A") == "AM") {
        periodFilter = "Morning";
        that.FiltersConfig.Filters.filter(
          x => x.filterType == FiltersTypes.periods,
        )[0].filterValue = "Morning";
      } else {
        periodFilter = "Afternoon";
        that.FiltersConfig.Filters.filter(
          x => x.filterType == FiltersTypes.periods,
        )[0].filterValue = "Afternoon";
      }
    }

    let passengerFilter = that.FiltersConfig.Filters.filter(
      x => x.filterType == FiltersTypes.Passengers,
    )[0].filterValue
      ? that.FiltersConfig.Filters.filter(
          x => x.filterType == FiltersTypes.Passengers,
        )[0].filterValue
      : 0;
    let placeFilter = that.FiltersConfig.Filters.filter(
      x => x.filterType == FiltersTypes.places,
    )[0].filterValue
      ? that.FiltersConfig.Filters.filter(
          x => x.filterType == FiltersTypes.places,
        )[0].filterValue
      : 0;

    that.subGrid = [];

    this._activeRidesService
      .getGridData(periodFilter, passengerFilter, placeFilter)
      .subscribe(
        response => {
          this._appComponent.toggeleLoader();
          that.gridView = response;
          that.gridData = response;
          that.collapseRow(that.closeSubGrid);
          that.fillRidesStatistics(response);
        },
        error => {
          if (error.status == 401) {
            that._toastrService.error("UnAuthorized.", "");
            window.location.href = "/pages/authentication/login";
          } else {
            that._toastrService.error(error.error.Message, "");
          }
        },
      );
    that._appComponent.toggeleLoader();
  }

  getSubGridData(rideUuid) {
    let that = this;
    that._activeRidesService.getSubGridData(rideUuid).subscribe(
      response => {
        that._appComponent.toggeleLoader();
        let record = that.gridView.filter(x => x.RideUuid == rideUuid)[0];
        record.subGrid = response;
      },
      error => {
        if (error.status == 401) {
          that._toastrService.error("UnAuthorized.", "");
          window.location.href = "/pages/authentication/login";
        } else {
          that._toastrService.error(error.error.Message, "");
        }
      },
    );
    this._appComponent.toggeleLoader();
  }

  getPassengersData() {
    let that = this;

    let passenger = that.FiltersConfig.Filters.filter(
      x => x.filterType == FiltersTypes.Passengers,
    )[0];
    that._activeRidesService.getPassengersData().subscribe(
      response => {
        let passengerfilterres = [];
        response.forEach(function (value) {
          let data = {
            id: value.StudentId,
            name: value.FirstName + " " + value.LastName,
          };
          passengerfilterres.push(data);
        });
        passenger.filterValues = passengerfilterres;
        passenger.filterDisabled = false;
      },
      error => {
        if (error.status == 401) {
          that._toastrService.error("UnAuthorized.", "");
          window.location.href = "/pages/authentication/login";
        } else {
          that._toastrService.error(error.error.Message, "");
        }
      },
    );
  }

  getPlacesData() {
    let that = this;

    let places = this.FiltersConfig.Filters.filter(
      x => x.filterType == FiltersTypes.places,
    )[0];
    that._activeRidesService.getPlacesData().subscribe(
      response => {
        let placefilterres = [];
        response.forEach(function (value) {
          let data = {
            id: value.PlaceId,
            name: value.PlaceName,
          };
          placefilterres.push(data);
        });
        places.filterValues = placefilterres;
        places.filterDisabled = false;
      },
      error => {
        if (error.status == 401) {
          that._toastrService.error("UnAuthorized.", "");
          window.location.href = "/pages/authentication/login";
        } else {
          that._toastrService.error(error.error.Message, "");
        }
      },
    );
  }

  GetCurrentActiveRideDate() {
    if (
      parseInt(moment().format("HH")) >= 20 &&
      parseInt(moment().format("HH")) <= 24
    ) {
      return " Tomorrow " + moment().add(1, "days").format("MM/DD/YYYY");
    } else {
      return " Today " + moment().format("MM/DD/YYYY");
    }
  }

  GetScheduledTime(scheduledFirstArrivalTime, scheduledLastArrivalTime) {
    let etaP1 = moment(scheduledFirstArrivalTime).local().format("hh:mm A");
    let etaP2 = moment(scheduledLastArrivalTime).local().format("hh:mm A");

    return etaP1 + " - " + etaP2;
  }

  fillRidesStatistics(data) {
    if (data && data.length > 0) {
      if (
        this.FiltersConfig.Filters.filter(
          x => x.filterType == FiltersTypes.periods,
        )[0].filterValue == "Morning"
      ) {
        let amScheduledRides = this.getMorningRides(
          data,
          RideStatusTypeEnum.None,
        );
        let amInProgressRides = this.getMorningRides(
          data,
          RideStatusTypeEnum.InProgress,
        );
        let amCompletedRides =
          this.getMorningRides(data, RideStatusTypeEnum.Completed) +
          this.getMorningRides(data, RideStatusTypeEnum.NoShow);
        let amLateCancelRides = this.getMorningRides(
          data,
          RideStatusTypeEnum.CanceledLate,
        );
        let amHolidayCanceledRides =
          this.getMorningRides(data, RideStatusTypeEnum.CanceledDueToHoliday) +
          this.getMorningRides(data, RideStatusTypeEnum.CanceledInAdvance);

        this.statistics[0].value = amScheduledRides;
        this.statistics[1].value = amInProgressRides;
        this.statistics[2].value = amCompletedRides;
        this.statistics[3].value = amLateCancelRides;
        this.statistics[4].value = amHolidayCanceledRides;
      } else if (
        this.FiltersConfig.Filters.filter(
          x => x.filterType == FiltersTypes.periods,
        )[0].filterValue == "Afternoon"
      ) {
        let pmScheduledRides = this.getAfternoonRides(
          data,
          RideStatusTypeEnum.None,
        );
        let pmInProgressRides = this.getAfternoonRides(
          data,
          RideStatusTypeEnum.InProgress,
        );
        let pmCompletedRides =
          this.getAfternoonRides(data, RideStatusTypeEnum.Completed) +
          this.getAfternoonRides(data, RideStatusTypeEnum.NoShow);
        let pmLateCancelRides = this.getAfternoonRides(
          data,
          RideStatusTypeEnum.CanceledLate,
        );
        let pmHolidayCanceledRides =
          this.getAfternoonRides(
            data,
            RideStatusTypeEnum.CanceledDueToHoliday,
          ) +
          this.getAfternoonRides(data, RideStatusTypeEnum.CanceledInAdvance);

        this.statistics[0].value = pmScheduledRides;
        this.statistics[1].value = pmInProgressRides;
        this.statistics[2].value = pmCompletedRides;
        this.statistics[3].value = pmLateCancelRides;
        this.statistics[4].value = pmHolidayCanceledRides;
      }
    }
    this.setDocumentRealTimeChangesListener();
  }

  getMorningRides(data, status) {
    return data.filter(x => x.RideStatusId == status && x.Period == "Morning")
      .length;
  }

  getAfternoonRides(data, status) {
    return data.filter(x => x.RideStatusId == status && x.Period == "Afternoon")
      .length;
  }

  GetDrivers(jobsArr) {
    let jobs = "";
    for (const job of jobsArr.filter(x => x.Type == "Ride")) {
      if (job.PartnerFirstOrNickName == "Driver not Assigned") {
        jobs += "Driver will be assigned soon";
      } else if (jobs == "") {
        jobs += job.PartnerFirstOrNickName + " #" + job.PartnerId;
      } else {
        jobs += job.PartnerFirstOrNickName + " #" + job.PartnerId;
      }
    }

    return jobs;
  }

  moment(date: string, durationInMinutes: number) {
    return moment(date).add("minutes", durationInMinutes).format("hh:mm A");
  }

  ContactPassengers(names) {
    let contactHtml = "";
    for (let name of names) {
      contactHtml +=
        name.FullName +
        " (" +
        name.RelationshipToEntity +
        ") " +
        "| " +
        this._helperService.phoneNumber(name.PhoneNumber) +
        "  ";
    }

    return contactHtml;
  }

  Equipments(names) {
    let equipmentHtml = "";
    for (let name of names) {
      equipmentHtml += name.Name + "  ";
    }

    return equipmentHtml;
  }

  ContactDestinations(names) {
    let contactHtml = "";
    for (let name of names) {
      contactHtml +=
        name.FullName +
        " (" +
        name.ContactType +
        ") " +
        "| " +
        this._helperService.phoneNumber(name.PhoneNumber) +
        "  ";
    }

    return contactHtml;
  }

  FormatDurationInMinutes(DurationInMinutes: string) {
    let durationFormattedText: string;
    let durationInMinutes = parseInt(DurationInMinutes);
    if (durationInMinutes < 60) {
      if (durationInMinutes < 9) {
        durationFormattedText = DurationInMinutes + " min";
      } else {
        durationFormattedText = DurationInMinutes + " mins";
      }
    } else {
      let durationInHours = durationInMinutes / 60;
      let remainingInMinutes =
        parseInt(DurationInMinutes) - durationInHours * 60;
      durationFormattedText =
        durationInHours +
        "h " +
        (remainingInMinutes > 0 ? remainingInMinutes + "m" : "");
    }
    return durationFormattedText;
  }

  OpenRideActivityLog(rideUUid, waypointId, rowIndex): void {
    this.rowNumber = rowIndex;
    let that = this;
    this._historicalRidesServices
      .getRideActivityLog(rideUUid, waypointId)
      .subscribe(
        Response => {
          that._appComponent.toggeleLoader();
          that.rideActivityLog = Response;
          this.opened = true;
        },
        error => {
          if (error.status == 401) {
            this._toastrService.error("UnAuthorized.", "");
            window.location.href = "/pages/authentication/login";
          } else {
            this._toastrService.error(error.error.Message, "");
          }
        },
      );
    that._appComponent.toggeleLoader();
  }

  ConvertDateUinx(dateUnix: number) {
    return moment.unix(dateUnix).format("MM/DD/YYYY hh:mm:ss A");
  }
  rideId = 0;
  GetSubGridRideActivity(rideUuid, rideId, waypointId, rowIndex) {
    this._activeRidesService
      .showRideWaypointActivityLogsModal(rideUuid, waypointId)
      .subscribe(
        Response => {
          this.subOpened = true;
          this.rideId = rideId;
          this.subRowNumber = rowIndex;
          this.subGridRideActivityLog = Response;
        },
        error => {
          if (error.status == 401) {
            this._toastrService.error("UnAuthorized.", "");
            window.location.href = "/pages/authentication/login";
          } else {
            this._toastrService.error(error.error.Message, "");
          }
        },
      );
  }

  GetETAData(data) {
    let ETAdate = moment(data.ScheduledArrivalTime).local().format("hh:mm A");
    let ride = $("#ride_monitor_grid")
      .DataTable()
      .rows()
      .data()
      .toArray()
      .filter(a => a.RideId == data.RideId)[0];
    if (ride) {
      let Waypoint = ride.Waypoints.filter(a => a.waypoint_id == data.Id)[0];
      if (
        (Waypoint && ride.RideStatusId == RideStatusTypeEnum.InProgress) ||
        ride.RideStatusId == RideStatusTypeEnum.Completed
      ) {
        if (
          ((Waypoint != null &&
            Waypoint.StatusId == RideWaypointTypeEnum.EnRoute) ||
            Waypoint.StatusId == RideWaypointTypeEnum.None) &&
          Waypoint.last_estimated_arrival_time_utc > 0
        ) {
          ETAdate = moment
            .unix(Waypoint.last_estimated_arrival_time_utc)
            .local()
            .format("hh:mm A");
        } else if (
          ((Waypoint != null &&
            Waypoint.StatusId == RideWaypointTypeEnum.Arrived) ||
            Waypoint.StatusId == RideWaypointTypeEnum.Visited) &&
          Waypoint.actual_arrival_time_utc > 0
        ) {
          ETAdate = moment
            .unix(Waypoint.actual_arrival_time_utc)
            .local()
            .format("hh:mm A");
        }
      }
    }
    return ETAdate;
  }

  CheckInstructions(item) {
    let instructionHtml = "";
    if (item.PickupInstructionsNote != null) {
      let pickupInstructionsNote =
        item.PickupInstructionsNote.split('"').join("");
      instructionHtml +=
        "<span><strong>Pick-up Instructions</strong></span><br/>";
      instructionHtml += "<span>" + pickupInstructionsNote + ".</span><br/>";
    }

    if (item.DropOffInstructionsNote != null) {
      let dropOffInstructionsNote =
        item.DropOffInstructionsNote.split('"').join("");
      instructionHtml += instructionHtml != "" ? "<hr/>" : "";
      instructionHtml +=
        "<span><strong>Drop-off Instructions</strong></span><br/>";
      instructionHtml += "<span>" + dropOffInstructionsNote + ".</span><br/>";
    }

    if (item.OnboardInstructionsNote != null) {
      let onboardInstructionsNote =
        item.OnboardInstructionsNote.split('"').join("");
      instructionHtml += instructionHtml != "" ? "<hr/>" : "";
      instructionHtml +=
        "<span><strong>Onboard Instructions</strong></span><br/>";
      instructionHtml += "<span>" + onboardInstructionsNote + ".</span><br/>";
    }

    if (item.SpecialInstructionsNote != null) {
      let specialInstructionsNote =
        item.SpecialInstructionsNote.split('"').join("");
      instructionHtml += instructionHtml != "" ? "<hr/>" : "";
      instructionHtml +=
        "<span><strong>Special Instructions</strong></span><br/>";
      instructionHtml += "<span>" + specialInstructionsNote + ".</span><br/>";
    }

    if (item.OtherInstructionsNote != null) {
      item.OtherInstructionsNote.forEach(element => {
        instructionHtml += instructionHtml != "" ? "<hr/>" : "";
        instructionHtml +=
          "<span><strong>${cargo.OtherInstructionsNote[i].Type} Instructions</strong></span><br/>";
        instructionHtml +=
          "<span>${cargo.OtherInstructionsNote[i].Description}.</span><br/>";
      });
    }

    if (instructionHtml == "") return false;
    return true;
  }
  CargoInstructions(item) {
    let instructionHtml = "";
    if (item.PickupInstructionsNote != null) {
      let pickupInstructionsNote =
        item.PickupInstructionsNote.split('"').join("");
      instructionHtml +=
        "<span>#StrongStart#Pick-up Instructions#StrongEnd#</span>#NewLine#";
      instructionHtml +=
        "<span>" + pickupInstructionsNote + ".</span>#NewLine#";
    }

    if (item.DropOffInstructionsNote != null) {
      let dropOffInstructionsNote =
        item.DropOffInstructionsNote.split('"').join("");
      instructionHtml +=
        instructionHtml != "" ? "-------------------------------" : "";

      instructionHtml +=
        "<span>#NewLine# #StrongStart#Drop-off Instructions#StrongEnd#</span>#NewLine#";
      instructionHtml +=
        "<span>" + dropOffInstructionsNote + ".</span>#NewLine# ";
    }

    if (item.OnboardInstructionsNote != null) {
      let onboardInstructionsNote =
        item.OnboardInstructionsNote.split('"').join("");
      instructionHtml +=
        instructionHtml != "" ? "-------------------------------" : "";

      instructionHtml +=
        "<span>#NewLine# #StrongStart#Onboard Instructions#StrongEnd#</span>#NewLine# ";
      instructionHtml +=
        "<span>" + onboardInstructionsNote + ".</span>#NewLine# ";
    }

    if (item.SpecialInstructionsNote != null) {
      let specialInstructionsNote =
        item.SpecialInstructionsNote.split('"').join("");

      instructionHtml +=
        instructionHtml != "" ? "-------------------------------" : "";

      instructionHtml +=
        "<span>#NewLine# #StrongStart#Special Instructions#StrongEnd#</span>#NewLine# ";
      instructionHtml +=
        "<span>" + specialInstructionsNote + ".</span>#NewLine# ";
    }

    if (item.OtherInstructionsNote != null) {
      item.OtherInstructionsNote.forEach(element => {
        instructionHtml += `<span>#NewLine# #StrongStart# ${element.Type} #StrongEnd#</span>#NewLine# `;
        instructionHtml +=
          "<span>" + element.Description + ".</span>#NewLine# ";
      });
    }
    let result: string = compiledConvert(instructionHtml, options);
    let finalResutl = result
      .replaceAll("#NewLine#", "<br/>")
      .replaceAll("#StrongStart#", "<strong>")
      .replaceAll("#StrongEnd#", "</strong>");
    return finalResutl;
  }

  GetPassengersName(name) {
    this.fullName =
      name.FirstName.split('"').join("") +
      " " +
      name.LastName.split('"').join("");
    return this.fullName;
  }

  GetCancelTransportaionName() {
    return this.fullName;
  }

  modalOpenLG(modalId, id, partner_id, name) {
    let that = this;
    this._activeRidesService.getDriverProfile(id, partner_id).subscribe(
      Response => {
        that._appComponent.toggeleLoader();
        that.driverProfile = Response;
        that.driverProfile.Name = name;
        that.driverProfile.Id = id;
        this.modalService.open(modalId, {
          centered: true,
          size: "lg",
        });
      },
      error => {
        if (error.status == 401) {
          this._toastrService.error("UnAuthorized.", "");
          window.location.href = "/pages/authentication/login";
        } else {
          this._toastrService.error(error.error.Message, "");
        }
      },
    );
    that._appComponent.toggeleLoader();
  }

  display: any;
  center: google.maps.LatLngLiteral = {
    lat: 24,
    lng: 12,
  };
  zoom = 10;
  markerOptions: google.maps.MarkerOptions = {
    draggable: false,
  };
  markerPositions: google.maps.LatLngLiteral[] = [];

  ridesWaypointsData: any[];
  openMap(modelId, rideId) {
    debugger;
    let that = this;
    that._activeRidesService.getRideInfo().subscribe(
      Response => {
        that._appComponent.toggeleLoader();
        let record = Response.Monitors.filter(x => x.RideId == rideId)[0];
        that.center = {
          lat: record.Latitude,
          lng: record.Longitude,
        };
        that.mapInfo = record.Waypoints;
        let markerColor: string = "acb7bf";

        for (const element of record.RidesWaypoints) {
          element.MarkerPositions = {
            lat: element.Latitude,
            lng: element.Longitude,
          };
        }
        for (let i = 0; i < record.Waypoints.length; i++) {
          const iconBase = `${environment.clientUrl}assets/images/icons/`;
          markerColor = "mapDefultMarker.svg";
          if (record.Waypoints[i].StatusId == RideWaypointTypeEnum.None) {
            markerColor = "mapDefultMarker.svg";
          } else if (
            record.Waypoints[i].StatusId == RideWaypointTypeEnum.EnRoute
          ) {
            markerColor = "mapMarkerOrange.svg";
          } else if (
            record.Waypoints[i].StatusId == RideWaypointTypeEnum.Arrived
          ) {
            markerColor = "mapMarkerBlue.svg";
          } else if (
            record.Waypoints[i].StatusId == RideWaypointTypeEnum.Visited
          ) {
            markerColor = "mapMarkerGreen.svg";
          } else if (
            record.Waypoints[i].StatusId == RideWaypointTypeEnum.Skipped
          ) {
            markerColor = "mapMarkerGray.svg";
          }

          record.RidesWaypoints[i].markerOptions = {
            icon: { url: iconBase + markerColor },
          };
        }
        that.ridesWaypointsData = record.RidesWaypoints;
        that.zoom = 13.5;
        that.modalService.open(modelId, { centered: true, size: "lg" });
        that.setRideWaypointTrackingListener(rideId);
      },
      error => {
        if (error.status == 401) {
          that._toastrService.error("UnAuthorized.", "");
          window.location.href = "/pages/authentication/login";
        } else {
          that._toastrService.error(error.error.Message, "");
        }
      },
    );

    that._appComponent.toggeleLoader();
  }

  openInfoWindow(
    marker: MapMarker,
    mapInfoWindow: MapInfoWindow,
    ridesWaypoint: any,
  ) {
    let data = this.mapInfo.filter(
      a => a.waypoint_id == ridesWaypoint.RideWaypointId,
    )[0];
    let templete: string =
      "Name: #Name# Address: #street1# #city# #state# #zipcode# Status: #Status#";
    let status = data.waypoint_status;
    if (data.statusId == RideWaypointTypeEnum.None) {
      status = "Upcoming";
    } else if (data.StatusId == RideWaypointTypeEnum.EnRoute) {
      status = "En Route";
    }
    if (mapInfoWindow != undefined) {
      mapInfoWindow.infoWindow.setContent(
        templete
          .replace("#Name#", data.waypoint_name)
          .replace("#street1#", data.street1)
          .replace("#city#", data.city)
          .replace("#state#", data.state)
          .replace("#zipcode#", data.zipcode)
          .replace("#Status#", status),
      );
      mapInfoWindow.open(marker);
    }
  }
  openRideActivity(modelId, rideUuid) {
    this._activeRidesService
      .showRideActivityLogsModal(rideUuid)
      .subscribe(Response => {
        this.rideActivity = Response;
        this.modalService.open(modelId, { centered: true, size: "xl" });
      });
  }

  collapseRow(topGrid) {
    let that = this;
    that.gridData.forEach((item, idx) => {
      topGrid.collapseRow(idx);
    });
  }
  rideWaypointTrackingListener = null;
  rideMonitorDateOfService = moment(new Date()).format("YYYY-MM-DD");
  setRideWaypointTrackingListener(rideId) {
    let that = this;
    if (!firebase.apps.length) {
      firebase.initializeApp(firebaseConfig);
    }
    firebase.auth().onAuthStateChanged(function (user) {
      if (user) {
        // User is signed in.
        let db = firebase.firestore();
        let doucName =
          "date_" + moment(that.rideMonitorDateOfService).format("YYYY-MM-DD");
        that.rideWaypointTrackingListener = db
          .collection(environment.rideWaypointsEtaRootCollection)
          .doc(doucName)
          .collection("ride_location_breadcrumbs")
          .doc("ride_" + rideId)
          .onSnapshot(doc => {
            that.updateDriverMarker(doc.data());
          });
      } else {
        that._activeRidesService.getRideLocation().subscribe(
          Response => {
            console.log(Response);
            let x = Response.customToken;
            return firebase
              .auth()
              .signInWithCustomToken(x)
              .then(Credential => {
                let db = firebase.firestore();
                let doucName =
                  "date_" +
                  moment(that.rideMonitorDateOfService).format("YYYY-MM-DD");

                that.rideWaypointTrackingListener = db
                  .collection(environment.rideWaypointsEtaRootCollection)
                  .doc(doucName)
                  .collection("ride_location_breadcrumbs")
                  .doc("ride_" + rideId)
                  .onSnapshot(doc => {
                    that.updateDriverMarker(doc.data());
                  });
              });
          },
          error => {
            if (error.status == 401) {
              that._toastrService.error("UnAuthorized.", "");
              window.location.href = "/pages/authentication/login";
            } else {
              that._toastrService.error(error.error.Message, "");
            }
          },
        );
      }
    });
  }
  public rideLocation: any = {
    icon: {
      url: "assets/images/driver-marker.png",
    },
    lng: 0,
    lat: 0,
    info: "",
  };
  updateDriverMarker(data) {
    if (data != null) {
      this.rideLocation = {
        lng: data.longitude,
        lat: data.latitude,
        info:
          data.street + " " + data.city + " " + data.state + " " + data.zipcode,
        // url:'assets/images/driver-marker.png',
        icon: {
          url: "assets/images/driver-marker.png",
        },
      };
    }
  }
  openDriverInfo(marker: MapMarker, mapInfoWindow: MapInfoWindow, info) {
    if (mapInfoWindow != undefined) {
      mapInfoWindow.infoWindow.setContent(info);
      mapInfoWindow.open(marker);
    }
  }
  docETAWaypointsListener = null;
  ridesWaypointsArrivalTimes = [];
  setDocumentRealTimeChangesListener() {
    let that = this;
    let tabledata = that.gridView;
    if (that.docETAWaypointsListener == null) {
      firebase.initializeApp(firebaseConfig);
      firebase.auth().onAuthStateChanged(function (user) {
        if (user) {
          // User is signed in.
          let db = firebase.firestore();
          if (that.docETAWaypointsListener == null) {
            that.docETAWaypointsListener = db
              .collection(environment.rideWaypointsEtaRootCollection)
              .doc(
                "date_" +
                  moment(that.rideMonitorDateOfService).format("YYYY") +
                  "-" +
                  moment(that.rideMonitorDateOfService).format("MM") +
                  "-" +
                  moment(that.rideMonitorDateOfService).format("DD"),
              )
              .collection("ride_waypoint_arrival_times")
              .onSnapshot(querySnapshot => {
                that.ridesWaypointsArrivalTimes = [];
                let changedRidesWaypointsArrivalTimes = [];
                querySnapshot.forEach(doc => {
                  let recordExists = that.gridView.find(
                    x => x.RideId == doc.data().ride_id,
                  );
                  recordExists ??
                    that.ridesWaypointsArrivalTimes.push(doc.data());
                });
                querySnapshot.docChanges().forEach(change => {
                  let recordExists = tabledata.find(
                    x => x.RideId == change.doc.data().ride_id,
                  );
                  recordExists ??
                    changedRidesWaypointsArrivalTimes.push(change.doc.data());
                });
                that.updateRidesWaypointsArrivalTime(
                  changedRidesWaypointsArrivalTimes,
                );
              });
          }
        } else {
          that._activeRidesService.getRideLocation().subscribe(
            Response => {
              console.log(Response);
              let x = Response.customToken;
              return firebase
                .auth()
                .signInWithCustomToken(x)
                .then(userCredential => {
                  let db = firebase.firestore();
                  that.docETAWaypointsListener = db
                    .collection(environment.rideWaypointsEtaRootCollection)
                    .doc(
                      "date_" +
                        moment(that.rideMonitorDateOfService).format("YYYY") +
                        "-" +
                        moment(that.rideMonitorDateOfService).format("MM") +
                        "-" +
                        moment(this.rideMonitorDateOfService).format("DD"),
                    )
                    .collection("ride_waypoint_arrival_times")
                    .onSnapshot(querySnapshot => {
                      that.ridesWaypointsArrivalTimes = [];
                      let changedRidesWaypointsArrivalTimes = [];
                      querySnapshot.forEach(doc => {
                        let recordExists = tabledata.filter(
                          x => x.RideId == doc.data().ride_id,
                        );
                        recordExists ??
                          that.ridesWaypointsArrivalTimes.push(doc.data());
                      });
                      querySnapshot.docChanges().forEach(change => {
                        let recordExists = tabledata.filter(
                          x => x.RideId == change.doc.data().ride_id,
                        );
                        recordExists ??
                          changedRidesWaypointsArrivalTimes.push(
                            change.doc.data(),
                          );
                      });
                      that.updateRidesWaypointsArrivalTime(
                        changedRidesWaypointsArrivalTimes,
                      );
                    });
                });
            },
            error => {
              that.handleError(error.status, error.error.Message);
            },
          );
        }
      });
    } else {
      that.updateRidesWaypointsArrivalTime(that.ridesWaypointsArrivalTimes);
    }
  }

  //get the popover html data
  GetPopoverData(data) {
    let popOverHtml = "";
    let ride = this.gridView.filter(x => x.RideId == data.RideId)[0];
    if (ride) {
      let Waypoint = ride.subGrid.filter(a => a.Id == data.Id)[0];

      if (Waypoint != null) {
        console.log(Waypoint.ActualArrivalTimeUtc);
        if (
          Waypoint.StatusId == RideWaypointTypeEnum.Arrived ||
          Waypoint.StatusId == RideWaypointTypeEnum.Visited
        ) {
          let diff = Math.trunc(
            Number(
              Number(
                Waypoint.ActualAndScheduledArrivalTimeDifferenceInSeconds,
              ) / 60,
            ),
          );
          console.log(diff);
          console.log(moment.utc(Waypoint.ActualArrivalTimeUtc).local());
          let diffHtml = "";
          if (diff > 0) {
            diffHtml =
              '<span class="text-danger"> (' + diff + " mins late)</span>";
          } else if (diff < 0) {
            diffHtml =
              '<span class="text-success"> (' +
              diff * -1 +
              " mins early)</span>";
          }
          popOverHtml += `<div>Scheduled Arrival Time:   ${moment
            .utc(Waypoint.ScheduledArrivalTimeUtc)
            .local()
            .format("hh:mm A")} </div>`;
          popOverHtml += `<div>Actual Arrival Time:   ${
            moment
              .utc(Waypoint.ActualArrivalTimeUtc)
              .local()
              .format("hh:mm A") + diffHtml
          } </div>`;
        } else if (Waypoint.StatusId == RideWaypointTypeEnum.Skipped) {
          popOverHtml += `<div>Scheduled Arrival Time:   ${moment
            .utc(Waypoint.ScheduledArrivalTimeUtc)
            .local()
            .format("hh:mm A")} </div>`;
          popOverHtml += `<div>Note:   This stop has been skipped as transportation has been canceled for the passenger(s). </div>`;
        } else {
          popOverHtml += `<div>Scheduled Arrival Time:   ${moment
            .utc(Waypoint.ScheduledArrivalTimeUtc)
            .local()
            .format("hh:mm A")}</div>`;
          popOverHtml += `<div>Actual Arrival Time:   Didn’t arrive yet. </div>`;
        }
      }
    }
    return popOverHtml;
  }

  //update the dat on the waypoints
  updateRidesWaypointsArrivalTime(data) {
    let that = this;
    let tabledata = that.gridView;
    data.forEach(function (rowData) {
      let rideRow = tabledata.find(x => x.RideId == rowData.ride_id);
      let updatedWaypoints = [];
      if (that._helperService.isValid(rideRow)) {
        if (that._helperService.isValid(rowData.waypoints)) {
          rideRow.Waypoints.forEach(function (item) {
            let waypoint = rowData.waypoints.find(
              x => x.waypoint_id == item.waypoint_id,
            );
            if (that._helperService.isValid(waypoint)) {
              item.StatusId = waypoint.StatusId;
              item.actual_arrival_time_utc = waypoint.actual_arrival_time_utc;
              item.last_distance_to_waypoint_in_miles =
                waypoint.last_distance_to_waypoint_in_miles;
              item.last_estimated_arrival_status =
                waypoint.last_estimated_arrival_status;
              item.last_estimated_arrival_time_utc =
                waypoint.last_estimated_arrival_time_utc;
              item.scheduled_and_last_estimated_arrival_time_difference_in_seconds =
                waypoint.scheduled_and_last_estimated_arrival_time_difference_in_seconds;
              item.scheduled_arrival_time_utc =
                waypoint.scheduled_arrival_time_utc;
              updatedWaypoints.push(item);
            } else {
              updatedWaypoints.push(item);
            }
          });
          rideRow.Waypoints = updatedWaypoints;
        }
      }
    });
  }

  //get the color for the waypoint attention
  GetColorClass(status: any) {
    if (status == RideWaypointTypeEnum.Visited) {
      return "ml-1 status-badge bg-success";
    } else if (status == RideWaypointTypeEnum.Arrived) {
      return "ml-1 status-badge bg-primary ";
    } else if (status == RideWaypointTypeEnum.Skipped) {
      return "ml-1 status-badge bg-default-light";
    } else if (status == RideWaypointTypeEnum.EnRoute) {
      return "ml-1 status-badge bg-warning";
    } else {
      return "ml-1 status-badge bg-default";
    }
  }

  toTitleCase(data: any) {
    let that = this;
    return that._helperService.toTitleCase(data);
  }

  handleError(errorstatus, errorMessage) {
    let that = this;
    if (errorstatus == 401) {
      that._toastrService.error("UnAuthorized.", "");
      window.location.href = "/pages/authentication/login";
    } else {
      that._toastrService.error(errorMessage, "");
    }
  }
}
