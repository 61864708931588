<ng-template
  #AddNewScheduleModal
  let-modal
>
  <div class="modal-header">
    <h5
      class="modal-title"
      id="myModalLabel160"
    >
      {{ PageTitle }}
    </h5>

    <button
      type="button"
      class="close"
      (click)="close(modal)"
      aria-label="Close"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div
    class="modal-body"
    tabindex="0"
    style="overflow-y: hidden; overflow-x: hidden; padding: 14px"
  >
    <div class="row">
      <div class="row">
        <div class="col-lg-12">
          <h4 class="text-black">
            <strong style="margin: 14px">Basic Details</strong>
          </h4>
        </div>
        <div class="col-12">
          <form
            [formGroup]="newScheduleForm"
            (ngSubmit)="onSubmit(modal)"
          >
            <div
              class="row"
              style="
                display: flex;
                overflow-x: hidden;
                overflow-y: hidden;
                padding-left: 15px;
                padding-right: 15px;
              "
            >
              <div class="col-lg-4">
                <label>Name</label>
                <app-required-field
                  type="text"
                  formControlName="Name"
                  placeholder="Name"
                  [disabledd]="PermessionValue"
                ></app-required-field>
              </div>
              <div class="col-lg-8">
                <label>Internal Note</label>
                <app-required-field-textarea
                  Id="Note"
                  type="text"
                  formControlName="InternalNote"
                  placeholder="Add note here."
                  [disabledd]="PermessionValue"
                ></app-required-field-textarea>
              </div>
              <div class="col-12 separate"></div>
              <div class="col-lg-4">
                <app-date-time-picker
                  [initialValue]="ServiceStartDateInitialDate"
                  name="Service Start Date"
                  placeholder="00/00/0000"
                  formControlName="ServiceStartDate"
                  [disable]="disabledAdd"
                ></app-date-time-picker>
              </div>
              <div class="col-lg-4">
                <app-date-time-picker
                  [initialValue]="ServiceEndDateInitialDate"
                  name="Service End Date"
                  placeholder="00/00/0000"
                  formControlName="ServiceEndDate"
                  [disable]="disabledAdd"
                ></app-date-time-picker>
              </div>
            </div>
            <div class="col-12 separate"></div>
          </form>
          <div [formGroup]="formDays">
            <app-schedule-days
              [AddressGridData]="AddressGridData"
              [PlaceGridData]="PlaceGridData"
              [StudentScheduleDetails]="data?.StudentScheduleDetails"
              [formchild]="formDays"
            ></app-schedule-days>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal-footer about-adroit-footer">
    <button
      type="button"
      (click)="close(modal)"
      rippleeffect=""
      class="btn btn-close waves-effect waves-float waves-light"
      style="border-color: #1877f2; margin-bottom: 20px; color: #1877f2"
    >
      Close
    </button>
    <button
      type="submit"
      class="btn btn-info"
      (click)="onSubmit(modal)"
      style="margin-bottom: 20px; margin-right: 20px"
      [hidden]="!disabledAdd"
      rippleEffect
    >
      Submit
    </button>
  </div>
</ng-template>

<kendo-dialog
  title=""
  *ngIf="attentionInfo"
  (close)="closeAttentionDialog()"
  [minWidth]="250"
  [width]="400"
>
  <p style="text-align: center">Please select at least one schedule day.</p>
  <kendo-dialog-titlebar>
    <div style="font-size: 18px; line-height: 1.3em; font-weight: 500">
      <span
        class="k-icon k-i-info-circle mr-1"
        style="color: #eea236; font-size: 26px; margin-top: -3px"
      ></span
      >Attention
    </div>
  </kendo-dialog-titlebar>
  <kendo-dialog-actions>
    <p style="text-align: center">
      <button
        type="button"
        (click)="closeAttentionDialog()"
        rippleeffect=""
        class="btn btn-info waves-effect waves-float waves-light"
      >
        Ok, got it.
      </button>
    </p>
  </kendo-dialog-actions>
</kendo-dialog>
