import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TabPosition } from "@progress/kendo-angular-layout";
import { AppComponent } from "app/app.component";
import { User } from "app/auth/models";
import { AuthenticationService } from "app/auth/service";
import { ToastrService } from "ngx-toastr";

import { ActivatedRoute } from "@angular/router";
import {
  FileInfo,
  FileRestrictions,
  FileState,
  UploadComponent,
} from "@progress/kendo-angular-upload";
import {
  StudentFileUploadModel,
  UploadFileModel,
} from "../../model/UploadFileModel";
import { ClientStudentsService } from "../../services/client-students.service";
import { PermissionsList } from "app/enums/Permissions/PermissionList";
@Component({
  selector: "app-student-add-new-attachment",
  templateUrl: "./student-add-new-attachment.component.html",
  styleUrls: ["./student-add-new-attachment.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class StudentAddNewAttachmentComponent implements OnInit {
  public submitted = false;
  public PageTitle: string = "";
  public newAttachmentForm: FormGroup;
  public position: TabPosition = "top";
  @ViewChild("AddNewAttachmentModal") AddNewAttachmentModal: TemplateRef<any>;
  @Output() Added = new EventEmitter<any>();
  public _currentUser: User;
  studentId = 0;
  public data: any;
  initialDate: Date;
  modelfile = new UploadFileModel();
  fileModel = new StudentFileUploadModel();
  public PermessionValue;
  public  myRestrictions: FileRestrictions = {
    maxFileSize: 5500000,
  };

  /**
   * Constructor
   * * @param {AuthenticationService} _authenticationService
   * * @param {HelpersService} _helpersService
   * * @param {ClientStudentsService} _clientStudentService
   * * @param {NgbModal} _modalService
   * * @param {ToastrService} _toastrService
   * * @param {FormBuilder} _formBuilder
   * * @param {AppComponent} _appcomponent
   */
  constructor(
    private _formBuilder: FormBuilder,
    private _authenticationService: AuthenticationService,
    private _toastrService: ToastrService,
    private _modalService: NgbModal,
    private _appcomponent: AppComponent,
    private _clientStudentService: ClientStudentsService,
    private route: ActivatedRoute,
  ) {
    this._authenticationService.currentUser.subscribe(
      x => (this._currentUser = x),
    );
  }

  uploadSaveUrl = "saveUrl"; // should represent an actual API endpoint
  uploadRemoveUrl = "removeUrl"; // should represent an actual API endpoint
  public userImages: Array<FileInfo>;
  public url: string;

  public remove(upload: UploadComponent, uid: string): void {
    upload.removeFilesByUid(uid);
  }

  public showButton(state: FileState): boolean {
    return state === FileState.Uploaded;
  }
  public disabled: boolean = true;

  ngOnInit(): void {
    this.disabled = this._authenticationService.checkaccess(
      PermissionsList[PermissionsList.ClientStudentsEdit],
    );
    if (this.route.snapshot.paramMap.get("id")) {
      this.PermessionValue = PermissionsList.ClientStudentsEdit.toString();
      this.disabled = this._authenticationService.checkaccess(
        PermissionsList[PermissionsList.ClientStudentsEdit],
      );
    } else {
      this.PermessionValue = PermissionsList.ClientStudentsCreate.toString();
      this.disabled = this._authenticationService.checkaccess(
        PermissionsList[PermissionsList.ClientStudentsCreate],
      );
    }
    let that = this;

    if (that.route.snapshot.paramMap.get("id")) {
      that.studentId = parseInt(that.route.snapshot.paramMap.get("id")!);
    }
    that.newAttachmentForm = that._formBuilder.group({
      fileName: ["", [Validators.required]],
      file: [that.userImages, [Validators.required]],
    });
    that.newAttachmentForm.valueChanges.subscribe(data =>
      that.onValueChanged(data),
    );
  }

  editAttachmentForm(fileModel: StudentFileUploadModel) {
    this.newAttachmentForm.setValue({
      fileName: fileModel.Name,
      file: this.data.FirstName,
    });
  }

  onValueChanged(_data?: any): void {
    if (_data.file != undefined && _data.file.length > 0)
      this.convertFileToBytes(_data.file);
    // handle model changes
    console.log(_data);
  }

  public isValidForm(): boolean {
    let that = this;

    for (const control of Object.keys(that.newAttachmentForm.controls)) {
      that.newAttachmentForm.controls[control].markAsTouched();
    }

    return that.newAttachmentForm.invalid;
  }

  public open() {
    if (this.data && this.data != null) {
      this.PageTitle = "Edit Attachment";
      this.newAttachmentForm.setValue({
        fileName: this.data.Name,
        file: this.data,
      });
      this._modalService.open(this.AddNewAttachmentModal, {
        centered: true,
        backdrop: "static",
        size: "md",
      });
    } else {
      this.PageTitle = "Add Attachment";
      this.newAttachmentForm.reset();
      this._modalService.open(this.AddNewAttachmentModal, {
        centered: true,
        backdrop: "static",
        size: "md",
      });
    }
  }

  close(model: any) {
    let that = this;
    for (const control of Object.keys(that.newAttachmentForm.controls)) {
      that.newAttachmentForm.controls[control].markAsUntouched();
    }
    that.newAttachmentForm.reset();
    model.close("Accept click");
    that.data = null;
  }

  convertFileToBytes(element) {
    let that = this;
    let file = element[0]["rawFile"];
    let reader = new FileReader();
    let Extension = file.name
      .substring(file.name.lastIndexOf(".") + 1)
      .toLowerCase();
    reader.onloadend = function (e) {
      let data = reader.result.toString().split("base64,")[1];
      that.modelfile = new UploadFileModel();
      that.modelfile.entityType = "student";
      that.modelfile.file = data;
      that.modelfile.name = that.newAttachmentForm.value.fileName;
      that.modelfile.extension = Extension;
      that.modelfile.parentId = that.studentId;
      that.modelfile.folder = "student";
      that.modelfile.type = "Student Attachment";
    };
    reader.readAsDataURL(file);
  }

  onSubmit(modal: any) {
    let that = this;
    that.submitted = true;

    if (that.studentId != 0) {
      if (!that.isValidForm()) {
        //Add
        if (that.data == null || that.data?.Id == 0) {
          that.uploadFile(that.modelfile, modal);
        }
        //Edit
        else {
          let fileToUpdate = that.newAttachmentForm.controls.file.value;
          fileToUpdate.Name = that.newAttachmentForm.controls.fileName.value;
          that.updateFile(fileToUpdate, modal);
        }
      }
    } else if (!that.isValidForm()) {
      that.uploadFile(that.modelfile, modal);
    }
  }

  uploadFile(uploadFileModel: UploadFileModel, modal: any) {
    let that = this;
    that._appcomponent.toggeleLoader();
    this._clientStudentService.uploadFile(uploadFileModel).subscribe(
      response => {
        that.fileModel.CdnFileId = response.Id;
        that.fileModel.FileId = response.FileId;
        that.fileModel.CdnFileUrl = response.Url;
        that.fileModel.CdnFileName = response.Name;
        that.fileModel.Extension = response.Extension;
        that.fileModel.Name = that.newAttachmentForm.value.fileName;
        that.fileModel.ParentId = that.studentId;
        that.Added.emit({ data: that.fileModel, mode: "add" });
        that.close(modal);
        that._toastrService.success(
          "The attachment was uploaded successfully.",
          "",
        );
        that._appcomponent.toggeleLoader();
      },
      error => {
        if (error.status == 401) {
          that._toastrService.error("UnAuthorized.", "");
          window.location.href = "/pages/authentication/login";
          that._appcomponent.toggeleLoader();
        } else if (error.status == 300) {
          that._toastrService.error(error.error.Message, "");
          that._appcomponent.toggeleLoader();
        } else {
          that._toastrService.error(
            "The attachment was not uploaded due to technical issue.",
            "",
          );
          that._appcomponent.toggeleLoader();
        }
      },
    );
  }

  updateFile(uploadFileModel: UploadFileModel, modal: any) {
    let that = this;
    that._appcomponent.toggeleLoader();
    let id = that.data.Id == null ? that.data.FileId : that.data.Id;
    this._clientStudentService.updateFile(id, uploadFileModel).subscribe(
      response => {
        that.fileModel.CdnFileId = response.Id;
        that.fileModel.CdnFileUrl = response.Url;
        that.fileModel.CdnFileName = response.Name;
        that.fileModel.Extension = response.Extension;
        that.fileModel.Name = that.newAttachmentForm.value.fileName;
        that.fileModel.ParentId = that.studentId;
        that.Added.emit({ data: that.fileModel, mode: "edit" });
        that.close(modal);
        that._toastrService.success(
          "The attachment was uploaded successfully.",
          "",
        );
      },
      error => {
        if (error.status == 401) {
          that._toastrService.error("UnAuthorized.", "");
          window.location.href = "/pages/authentication/login";
          that._appcomponent.toggeleLoader();
        } else if (error.status == 300) {
          that._toastrService.error(error.error.Message, "");
          that._appcomponent.toggeleLoader();
        } else {
          that._toastrService.error(
            "The attachment was not uploaded due to technical issue.",
            "",
          );
          that._appcomponent.toggeleLoader();
        }
      },
    );
  }

  openBase64InNewTab(data, mimeType) {
    let byteCharacters = atob(data);
    let byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    let byteArray = new Uint8Array(byteNumbers);
    let file = new Blob([byteArray], { type: mimeType + ";base64" });
    let fileURL = URL.createObjectURL(file);
    window.open(fileURL);
  }

  generateGoogleCloudViewUrl(fileName) {
    let that = this;
    that._appcomponent.toggeleLoader();
    let file = fileName.replaceAll("___", "/");
    let result: UploadFileModel = new UploadFileModel();
    result.name = file;
    that._clientStudentService.downloadFile(result).subscribe(response => {
      that.openBase64InNewTab(
        response.Value,
        that.getFileType(response.Extension),
      );
      that._appcomponent.toggeleLoader();
    });
  }

  getFileType(type) {
    let value = "";
    switch (type) {
      case "png":
        value = "image/png;base64";
        break;
      case "pdf":
        value = "application/pdf";
        break;
      case "jpg":
        value = "image/jpg;base64";
        break;
    }
    return value;
  }
}
