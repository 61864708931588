import { AfterViewInit, Component, OnInit, ViewEncapsulation, NgZone } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

import { MicrosoftLoginProvider, SocialAuthService } from "angularx-social-login";
import { PortalTypeEnum } from "app/enums/Permissions/PortalTypes";
import { MenuService } from "app/menu/menu.service";
import { ToastrService } from "ngx-toastr";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { CoreConfigService } from "../../../../../@core/services/config.service";
import { environment } from "../../../../../environments/environment";
import { AppComponent } from "../../../../app.component";
import { User } from "../../../../auth/models";
import { AuthenticationService } from "../../../../auth/service";
import { ProviderType } from "../../../../enums/Configurtion/providerType";

declare var google: any;

@Component({
    selector: "app-auth-login-v1",
    templateUrl: "./auth-login-v1.component.html",
    styleUrls: ["./auth-login-v1.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class AuthLoginV1Component implements OnInit, AfterViewInit {

    //  Public
    public coreConfig: any;
    public loginForm: FormGroup;
    public submitted = false;
    public passwordTextType: boolean;
    private menuList: any;
    // Private
    private _unsubscribeAll: Subject<any>;
    /**
     * Constructor
     *
     * @param {CoreConfigService} _coreConfigService
     * @param {CoreConfigService} _coreConfigService
     * @param {FormBuilder} _formBuilder
     * @param {ToastrService} _toastrService
     */
    constructor(
        private _coreConfigService: CoreConfigService,
        private _formBuilder: FormBuilder,
        private _Auth: AuthenticationService,
        private _menuService: MenuService,
        private _toastrService: ToastrService,
        private _appComponent: AppComponent,
        private _authService: AuthenticationService,
        private _socialAuthService: SocialAuthService,
        private ngZone: NgZone
    ) {// https://developers.facebook.com/docs/reference/javascript/FB.login/v2.11



        let that = this;
        that._Auth.logout();
        that._unsubscribeAll = new Subject();

        // Configure the layout
        that._coreConfigService.config = {
            layout: {
                navbar: {
                    hidden: true,
                },
                menu: {
                    hidden: true,
                },
                footer: {
                    hidden: true,
                },
                customizer: false,
                enableLocalStorage: false,
            },
        };
    }

    // convenience getter for easy access to form fields
    get f() {
        return this.loginForm.controls;
    }

    /**
     * Toggle password
     */
    togglePasswordTextType() {
        this.passwordTextType = !this.passwordTextType;
    }

    /**
     * On Submit
     */
    onSubmit() {
        let that = this;
        this.submitted = true;
        // stop here if form is invalid
        if (this.loginForm.invalid) {
            return;
        } else {
            this._appComponent.toggeleLoader();
            this._Auth.login(this.f.email.value, this.f.password.value).subscribe(
                Response => {
                    that.saveLoginUser(Response)
                },
                error => {
                    that.errorHandler(error);
                    this._appComponent.toggeleLoader();
                },
            );
        }
    }

    saveLoginUser(Response) {
        let that = this;
        let currentUser = new User();
        currentUser.id = Response.user_profile.id;
        currentUser.email = Response.user_profile.email;
        currentUser.first_name = Response.user_profile.first_name;
        currentUser.last_name = Response.user_profile.last_name;
        currentUser.full_name = Response.user_profile.full_name;
        currentUser.mobile_number = Response.user_profile.mobile_number;
        currentUser.nick_name = Response.user_profile.nick_name;
        currentUser.role = Response.user_profile.role;
        currentUser.avatar = Response.user_profile.profile_picture_url;
        currentUser.access_token = Response.user_profile.access_token;
        currentUser.Permissions = Response.user_profile.Permissions;
        currentUser.PortalName = Response.user_profile.PortalName;
        currentUser.client_id = Response.identity.client_id;
        currentUser.OrganizationId = Response.identity.OrganizationId;
        currentUser.organizationstatusId =
            Response.identity.organizationstatusId;
        currentUser.PortalType = Response.user_profile.PortalType;
        if (currentUser.PortalType == PortalTypeEnum.ClientPortal) {
            let splittedName = currentUser.full_name.split(" ");
            if (splittedName.length > 0) {
                currentUser.short_name =
                    currentUser.first_name[0] + currentUser.last_name[0];
            }
            this._Auth.saveUserData(currentUser);
            that.menuList = this._menuService.menu;
            let navigatURL: any;
            localStorage.setItem(
                "ClientAuthToken",
                Response.user_profile.access_token,
            );
            localStorage.setItem(
                "UserFullName",
                Response.user_profile.full_name,
            );
            localStorage.setItem(
                "UserPortalType",
                Response.user_profile.PortalType,
            );
            localStorage.setItem(
                "ClientUserId",
                Response.identity.client_user_account_uuid,
            );
            localStorage.setItem("ClientName", Response.identity.client_name);
            localStorage.setItem("ClientId", Response.identity.client_id);
            localStorage.setItem("OrgId", Response.identity.organization_id);
            localStorage.setItem(
                "IsInternalOrg",
                Response.identity.is_internal_org,
            );
            localStorage.setItem(
                "ServiceProviderAfterWithholdPercentage",
                Response.identity.sp_default_percentage,
            );

            that.loginDevelopment(currentUser);
        } else {
            that._socialAuthService.signOut(true);
            that._appComponent.toggeleLoader();
            that._toastrService.error("This email and password combination is not valid.Please try again.", "",);
        }
    }

    loginDevelopment(currentUser) {
        let that = this;
        //find the first permession into investigate to it after
        let pages = currentUser.Permissions.filter((element, index, array) => {
            let exist = that.menuList.find(x => x.PermissionValue == element);
            if (exist) {
                return element;
            }
        });
        let navigatURL = that.menuList.find(x => x.PermissionValue == pages[0]);
        if (navigatURL == undefined) {
            navigatURL = "/pages/miscellaneous/error";
            window.location.href = navigatURL;
        } else {
            setTimeout(() => {
                localStorage.setItem("defalutnavigation", navigatURL.url.toString());
                window.location.href = navigatURL.url;
                that._Auth.saveUserData(currentUser);
            }, 500);
        }
    }

    errorHandler(error) {
        let that = this;
        if (error.status == 401) {
            that._toastrService.error(error.error.Message, "");
        } else if (error.status == 403) {
            that._toastrService.error(error.error.Message, "");
        } else {
            that._toastrService.error("Server internal error", "");
        }
    }
    // Lifecycle Hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {


        this.loginForm = this._formBuilder.group({
            email: ["", [Validators.required, Validators.email]],
            password: ["", Validators.required],
        });

        // Subscribe to config changes
        this._coreConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(config => {
                this.coreConfig = config;
            });
    }

    ngAfterViewInit() {
        let gAccounts: any = google.accounts;
        console.log(environment.client_id);
        gAccounts.id.initialize({
            client_id: environment.client_id,
            ux_mode: 'popup',
            cancel_on_tap_outside: true,
            callback: ({ credential }) => {
                this.ngZone.run(() => {
                    this.handleCredentialResponse(credential);
                });
            },
        });

        const createFakeGoogleWrapper = () => {
            const googleLoginWrapper = document.createElement("div");
            // Or you can simple hide it in CSS rule for custom-google-button
            googleLoginWrapper.style.display = "none";
            googleLoginWrapper.classList.add("custom-google-button");

            // Add the wrapper to body
            document.body.appendChild(googleLoginWrapper);

            // Use GSI javascript api to render the button inside our wrapper
            // You can ignore the properties because this button will not appear
            gAccounts.id.renderButton(googleLoginWrapper, {
                type: "icon",
                width: "200",
            });

            const googleLoginWrapperButton: any = googleLoginWrapper.querySelector("div[role=button]");

            return googleLoginWrapperButton;
        };

        // Now we have a wrapper to click
        let googleButtonWrapper = createFakeGoogleWrapper();
        let windowObject: any = window;
        windowObject.handleGoogleLogin = () => {
            // Use wrapper click to prevent Illegal invocation exception
            googleButtonWrapper.click();
            // This will open GSI login and after success you will have
            // a response on googleLoginCallback method previously created
        };
        google.accounts.id.prompt(); // also display the One Tap dialog
    }

    handleCredentialResponse(authToken) {
        let that = this;
        if (authToken) {
            that._appComponent.toggeleLoader();
            that.loginBySSO(authToken, ProviderType.Google);
        }
    }
    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    CheckValidation() {
        if (this.f.email.value == "") {
            this.submitted = true;
        }
        if (this.f.password.value == "") {
            this.submitted = true;
        }
    }

    public signInWithMicrosoft(): void {
        let that = this;
        that._appComponent.toggeleLoader();
        that._socialAuthService
            .signIn(MicrosoftLoginProvider.PROVIDER_ID)
            .then(user => {
                if (user) {
                    that.loginBySSO(user.authToken, ProviderType.Microsoft);
                }
            })
            .catch(err => {
                console.log(err);
                that._appComponent.toggeleLoader();
            });
    }

    public loginBySSO(accessToken: string, providerType: number): void {
        let that = this;
        that._authService.loginBySSO({
            token: accessToken,
            providerType: providerType,
            clientName: "app-web-client"
        }).subscribe({
            next: (response: any) => {
                that.saveLoginUser(response);
            },
            error: (error: any) => {
                if (providerType == ProviderType.Microsoft) {
                    that._socialAuthService.signOut(true);
                }
                that._toastrService.error("This email and password combination is not valid.Please try again.", "",);
                that._appComponent.toggeleLoader();
            }
        });
    }
}
