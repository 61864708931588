import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { environment } from "environments/environment";
import { ClientGurdiansFilterModel } from "../model/ClientGurdiansFilterModel";
import { AuthenticationService } from "app/auth/service";
import { GuardianDataModel } from "../model/GuardianDataModel";
import { HomeAddressStudentModel } from "app/main/Students/model/AddressStudentModel";
import { UpdateAddressGuardianModel } from "../model/updatelocationModel";
@Injectable({ providedIn: "root" })
export class ClientGuardiansService {
  /**
   * Constructor
   *
   * @param {HttpClient} _httpClient
   */
  constructor(
    private _httpClient: HttpClient,
    private _authenticationService: AuthenticationService,
  ) { }

  /**
   * Get rows
   */
  getDataTableRows(filterModel?: ClientGurdiansFilterModel) {
    return this._httpClient.post<any[]>(`${environment.apiUrl}/guardians`,
      filterModel
    );
  }

  getPassengers() {
    let queryParams = new HttpParams();
    let clientId = this._authenticationService.currentUserValue.client_id;
    queryParams = queryParams.append("client_id", clientId);

    return this._httpClient.get<any>(`${environment.apiUrl}/gurdian/students`, {
      params: queryParams,
    });
  }
  blockGurdian(id: number, isBlocked: boolean) {
    return this._httpClient.patch<any>(
      `${environment.apiUrl}/guardians/${id}`,
      {
        Id: id,
        IsBlock: !isBlocked,
      },
    );
  }
  deleteGurdian(id: number) {
    return this._httpClient.delete<any>(
      `${environment.apiUrl}/guardians/${id}`,
    );
  }
  archiveGurdian(id: number, isArchived: boolean) {
    return this._httpClient.patch<any>(
      `${environment.apiUrl}/guardians/${id}`,
      {
        Id: id,
        IsArchive: !isArchived,
      },
    );
  }
  checkGuardianExist(guardianId, studentId, guardianData: GuardianDataModel) {
    return this._httpClient.post<any>(
      `${environment.apiUrl}/guardians/${guardianId}/students/${studentId}/`,
      guardianData,
    );
  }
  addNewGuardian(studentId, guardianData: GuardianDataModel) {
    return this._httpClient.post<any>(
      `${environment.apiUrl}/students/${studentId}/guardians`,
      guardianData,
    );
  }
  loadGuardianOfStudents(StudentId) {
    return this._httpClient.get<any>(
      `${environment.apiUrl}/guardians/${StudentId}/students`,
    );
  }

  loadGuardianAddresses(StudentId) {
    return this._httpClient.get<any>(
      `${environment.apiUrl}/guardians/${StudentId}/locations`,
    );
  }
  loadGuardian(StudentId) {
    return this._httpClient.get<any>(
      `${environment.apiUrl}/guardians/${StudentId}`,
    );
  }
  unLinkGuardianFromStudent(StudentId, id) {
    return this._httpClient.delete(
      `${environment.apiUrl}/students/${id}/guardians/${StudentId}`,
    );
  }

  resetPassword(id) {
    return this._httpClient.patch(
      `${environment.apiUrl}/auth/reset-password/${id}`,
      0,
    );
  }
  updateGuardian(id, guardianData: GuardianDataModel) {
    return this._httpClient.put(
      `${environment.apiUrl}/guardians/${id}`,
      guardianData,
    );
  }

  addNewAddres(homeAddressModel: HomeAddressStudentModel) {
    return this._httpClient.post(
      `${environment.apiUrl}/guardians/${homeAddressModel.ParentId}/locations`,
      homeAddressModel,
    );
  }
  deleteAdress(id, locationId) {
    return this._httpClient.delete(
      `${environment.apiUrl}/guardians/${id}/locations/${locationId}`,
    );
  }

  editAdress(id, homeAddressModel: HomeAddressStudentModel) {
    let gurdianAdress = new UpdateAddressGuardianModel();

    gurdianAdress.Name = homeAddressModel.Name;
    gurdianAdress.ParentId = homeAddressModel.ParentId.toString();
    gurdianAdress.location = homeAddressModel.Location;

    return this._httpClient.put(
      `${environment.apiUrl}/guardians/${id}/locations/${homeAddressModel.Location.LocationId}`,
      gurdianAdress,
    );
  }
}
