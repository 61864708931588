import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { environment } from "environments/environment";
import { AuthenticationService } from "../../../auth/service";

@Injectable({
  providedIn: "root",
})
export class RideBlueprintSheetService {
  constructor(
    private _httpClient: HttpClient,
    private _authenticationService: AuthenticationService,
  ) { }

  getGridData(
    containerFilter,
    bluePrintFilter,
    rideTypeFilter,
    periodFilter,
    passengerFilter,
    placeFilter,
  ) {
    let data = {
      period: periodFilter,
      type: rideTypeFilter,
      id: bluePrintFilter,
      accountId: 0,
      clientId: this._authenticationService.currentUserValue.client_id,
      rideInvoiceContainerId: containerFilter,
      placeId: placeFilter,
      studentId: passengerFilter,
    }
    return this._httpClient.post<any>(
      `${environment.apiUrl}/clients/ride-blueprints`, data,
    );
  }

  loadRideBlueprintSheetData(rideBlueprintUuid) {
    let queryParams = new HttpParams();
    queryParams = queryParams.append("id", rideBlueprintUuid);

    return this._httpClient.get<any>(
      `${environment.apiUrl}/ride-blueprints/${rideBlueprintUuid}/data-sheet`,
      { params: queryParams },
    );
  }

  getPassengersData() {
    return this._httpClient.get<any>(
      `${environment.apiUrl}/clients/${this._authenticationService.currentUserValue.client_id}/ride-blueprints/students/`,
    );
  }

  getPlacesData() {
    return this._httpClient.get<any>(
      `${environment.apiUrl}/clients/${this._authenticationService.currentUserValue.client_id}/ride-blueprints/places/`,
    );
  }

  getBluePrintData() {
    return this._httpClient.get<any>(
      `${environment.apiUrl}/clients/${this._authenticationService.currentUserValue.client_id}/ride-blueprints/drop-down-filter/`,
    );
  }

  getContainersData() {
    return this._httpClient.get<any>(
      `${environment.apiUrl}/clients/${this._authenticationService.currentUserValue.client_id}/ride-blueprints/invoice-containers/`,
    );
  }
}
