import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TabPosition } from "@progress/kendo-angular-layout";
import { AppComponent } from "app/app.component";
import { User } from "app/auth/models";
import { AuthenticationService } from "app/auth/service";
import { IFiltersConfig } from "app/controls/component/filters/interfaces/controls/IFiltersConfig";
import { ToastrService } from "ngx-toastr";

import { ActivatedRoute } from "@angular/router";
import {
  DataBindingDirective,
  GridDataResult,
  PageChangeEvent,
} from "@progress/kendo-angular-grid";
import moment from "moment";
import { StudentServiceCancelationModel } from "../../model/StudentServiceCancelationModel";
import { ClientStudentsService } from "../../services/client-students.service";
import { State } from "@progress/kendo-data-query";
import { PermissionsList } from "app/enums/Permissions/PermissionList";
@Component({
  selector: "app-student-add-new-service-cancelation",
  templateUrl: "./student-add-new-service-cancelation.component.html",
  styleUrls: ["./student-add-new-service-cancelation.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class StudentAddNewServiceCancelationComponent implements OnInit {
  public submitted = false;
  public PageTitle: string;
  public newServiceCancelationForm: FormGroup;
  public position: TabPosition = "top";
  @ViewChild("AddNewServiceCancelationModal")
  AddNewServiceCancelationModal: TemplateRef<any>;
  @Output() Added = new EventEmitter<any>();
  validRelationShip: boolean = false;
  public Config: IFiltersConfig;
  public _currentUser: User;
  studentId = 0;
  public data: any;
  public type: string;
  initialDate: Date;
  validSlectorType: boolean = false;
  validSlectorDay: boolean = false;
  isAdd: boolean = false;
  public gridView: GridDataResult;
  gridData: StudentServiceCancelationModel[] = [];
  public PermessionValue: string;
  @Input("disable") disable: boolean = false;
  public disabledService: boolean;
  /**
   * Constructor
   * * @param {AuthenticationService} _authenticationService
   * * @param {HelpersService} _helpersService
   * * @param {ClientStudentsService} _clientStudentService
   * * @param {NgbModal} _modalService
   * * @param {ToastrService} _toastrService
   * * @param {FormBuilder} _formBuilder
   * * @param {AppComponent} _appcomponent
   */
  constructor(
    private _formBuilder: FormBuilder,
    private _authenticationService: AuthenticationService,
    private _toastrService: ToastrService,
    private _modalService: NgbModal,
    private _appcomponent: AppComponent,
    private _clientStudentService: ClientStudentsService,
    private route: ActivatedRoute,
  ) {
    this._authenticationService.currentUser.subscribe(
      x => (this._currentUser = x),
    );
  }
  reasons = [
    "Per mother’s request.",
    "Per father’s request.",
    "Per school district’s request.",
  ];
  state: State = {
    skip: 0,
    take: 10,
  };
  @ViewChild(DataBindingDirective) dataBinding: DataBindingDirective;
  ngOnInit(): void {
    this.disabledService = this.disable;
    if (this.route.snapshot.paramMap.get("id")) {
      this.PermessionValue = PermissionsList.ClientStudentsEdit.toString();
      this.studentId = parseInt(this.route.snapshot.paramMap.get("id")!);
    } else {
      this.PermessionValue = PermissionsList.ClientStudentsCreate.toString();
    }

    this.newServiceCancelationForm = this._formBuilder.group({
      ServiceCancelationFromDate: ["", [Validators.required]],
      ServiceCancelationToDate: ["", [Validators.required]],
      ServiceCancelationRecurrenceFrequency: ["", [Validators.required]],
      ApplyTo: ["", [Validators.required]],
      ServiceCancelationNote: ["", [Validators.required]],
      ServiceCancelationRecurrenceDay: ["", [Validators.required]],
    });

    this.Config = {
      isFiltersEnable: false,
      Filters: [
        {
          filterValues: [
            { id: "Once", name: "On specific date(s)" },
            { id: "Weekly", name: "Every Week." },
          ],
          filterVisability: true,
          filterPlaceholder: "",
          filterValue: "Once",
        },
        {
          filterValues: [
            { id: "morning", name: "Outbound Rides" },
            { id: "afternoon", name: "Return Rides" },
            { id: "all", name: "Entire Day’s Rides" },
          ],
          filterClear: true,
          filterVisability: true,
          filterPlaceholder: "Select a period.",
        },
        {
          filterValues: [
            { id: "2", name: "Monday" },
            { id: "3", name: "Tuesday" },
            { id: "4", name: "Wednesday" },
            { id: "5", name: "Thursday" },
            { id: "6", name: "Friday" },
            { id: "7", name: "Saturday" },
            { id: "1", name: "Sunday" },
          ],
          filterClear: true,
          filterVisability: true,
          filterPlaceholder: "Select a day.",
        },
      ],
    };
    const today = new Date();
    this.initialDate = new Date(today.setDate(today.getDate() + 1));
    this.newServiceCancelationForm
      .get("ServiceCancelationFromDate")
      .setValue(this.initialDate);
    this.newServiceCancelationForm
      .get("ServiceCancelationToDate")
      .setValue(this.initialDate);
    this.typeChanged();
  }

  public isValidForm(): boolean {
    let that = this;
    for (const control of Object.keys(
      that.newServiceCancelationForm.controls,
    )) {
      that.newServiceCancelationForm.controls[control].markAsTouched();
    }
    return that.newServiceCancelationForm.invalid;
  }

  typeChanged() {
    let that = this;
    if (this.Config.Filters[0].filterValue == "Once") {
      this.newServiceCancelationForm
        .get("ServiceCancelationRecurrenceDay")
        .setValue("0");
      this.newServiceCancelationForm
        .get("ServiceCancelationFromDate")
        .setValue(that.initialDate);
      this.newServiceCancelationForm
        .get("ServiceCancelationToDate")
        .setValue(that.initialDate);
      this.newServiceCancelationForm
        .get("ServiceCancelationFromDate")
        .setValidators([Validators.required]);
      this.newServiceCancelationForm
        .get("ServiceCancelationToDate")
        .setValidators([Validators.required]);
    } else {
      this.Config.Filters[2].filterValue = null;
      this.newServiceCancelationForm
        .get("ServiceCancelationRecurrenceDay")
        .setValidators([Validators.required]);
      this.newServiceCancelationForm
        .get("ServiceCancelationFromDate")
        .removeValidators([Validators.required]);
      this.newServiceCancelationForm
        .get("ServiceCancelationToDate")
        .removeValidators([Validators.required]);
    }
    this.newServiceCancelationForm.updateValueAndValidity();
    for (const control of Object.keys(
      that.newServiceCancelationForm.controls,
    )) {
      that.newServiceCancelationForm.controls[control].markAsUntouched();
    }
    this.Config.Filters[2].filterValue = null;
    that.validSlectorType = false;
    that.validSlectorDay = false;
  }

  public open() {
    let that = this;
    this.gridData = [];
    this.loadItems();
    if (this.data) {
      this.PageTitle = "Edit Service Cancelation";
      if (this.data.RecurrenceFrequency == "Once") {
        let today = new Date(this.data.EffectiveDate);
        this.initialDate = new Date(today);
        this.newServiceCancelationForm.setValue({
          ServiceCancelationFromDate: this.initialDate,
          ServiceCancelationToDate: this.initialDate,
          ServiceCancelationRecurrenceFrequency:
            this.Config.Filters[0].filterValues.filter(
              a => a.id == this.data.RecurrenceFrequency,
            )[0].id,
          ApplyTo: this.Config.Filters[1].filterValues.filter(
            a => a.id == this.data.ApplyTo,
          )[0].id,
          ServiceCancelationNote: this.getServiceCancelationNote(),
          ServiceCancelationRecurrenceDay: "",
        });

        this.newServiceCancelationForm.controls[
          "ServiceCancelationRecurrenceFrequency"
        ].disable();
        this.Config.Filters[0].filterValue =
          this.Config.Filters[0].filterValues.filter(
            a => a.id == this.data.RecurrenceFrequency,
          )[0].id;
        this.Config.Filters[1].filterValue =
          this.Config.Filters[1].filterValues.filter(
            a => a.id == this.data.ApplyTo,
          )[0].id;

        this.newServiceCancelationForm
          .get("ServiceCancelationRecurrenceDay")
          .setValue("0");
        this.newServiceCancelationForm
          .get("ServiceCancelationFromDate")
          .setValidators([Validators.required]);
        this.newServiceCancelationForm
          .get("ServiceCancelationToDate")
          .setValidators([Validators.required]);
      } else if (this.data.RecurrenceFrequency == "Weekly") {
        this.newServiceCancelationForm.setValue({
          ServiceCancelationFromDate: "",
          ServiceCancelationToDate: "",
          ServiceCancelationRecurrenceFrequency:
            this.Config.Filters[0].filterValues.filter(
              a => a.id == this.data.RecurrenceFrequency,
            )[0].id,
          ApplyTo: this.Config.Filters[1].filterValues.filter(
            a => a.id == this.data.ApplyTo,
          )[0].id,
          ServiceCancelationNote: this.getServiceCancelationNote(),
          ServiceCancelationRecurrenceDay:
            this.Config.Filters[2].filterValues.filter(
              a => a.id == this.data.RecurrenceDay.toString(),
            )[0].id,
        });

        this.newServiceCancelationForm.controls[
          "ServiceCancelationRecurrenceFrequency"
        ].disable();
        this.Config.Filters[0].filterValue =
          this.Config.Filters[0].filterValues.filter(
            a => a.id == this.data.RecurrenceFrequency,
          )[0].id;
        this.Config.Filters[1].filterValue =
          this.Config.Filters[1].filterValues.filter(
            a => a.id == this.data.ApplyTo,
          )[0].id;
        this.Config.Filters[2].filterValue =
          this.Config.Filters[2].filterValues.filter(
            a => a.id == this.data.RecurrenceDay.toString(),
          )[0].id;

        this.newServiceCancelationForm
          .get("ServiceCancelationRecurrenceDay")
          .setValidators([Validators.required]);
        this.newServiceCancelationForm
          .get("ServiceCancelationFromDate")
          .removeValidators([Validators.required]);
        this.newServiceCancelationForm
          .get("ServiceCancelationToDate")
          .removeValidators([Validators.required]);
      }
      this._modalService.open(this.AddNewServiceCancelationModal, {
        centered: true,
        backdrop: "static",
        size: "lg",
      });
      this.newServiceCancelationForm.updateValueAndValidity();
      for (const control of Object.keys(
        that.newServiceCancelationForm.controls,
      )) {
        that.newServiceCancelationForm.controls[control].markAsUntouched();
      }
    } else {
      this.PageTitle = "Add Service Cancelation";
      this.newServiceCancelationForm.controls[
        "ServiceCancelationRecurrenceFrequency"
      ].enable();
      this.Config.Filters[0].filterValue = "Once";
      const today = new Date();
      this.initialDate = new Date(today.setDate(today.getDate() + 1));
      this.newServiceCancelationForm
        .get("ServiceCancelationFromDate")
        .setValue(this.initialDate);
      this.newServiceCancelationForm
        .get("ServiceCancelationToDate")
        .setValue(this.initialDate);
      this.typeChanged();
      this._modalService.open(this.AddNewServiceCancelationModal, {
        centered: true,
        backdrop: "static",
        size: "lg",
      });
    }
  }
  getServiceCancelationNote() {
    if (this.data.InternalNote != null) {
      return this.data.InternalNote;
    }
    return "";
  }
  close(model: any) {
    let that = this;
    for (const control of Object.keys(
      that.newServiceCancelationForm.controls,
    )) {
      that.newServiceCancelationForm.controls[control].markAsUntouched();
    }

    that.newServiceCancelationForm.reset();
    that.validSlectorDay = false;
    that.validSlectorType = false;
    that.data = null;
    model.close("Accept click");
  }

  onSubmit(modal: any,$event) {
    let that = this;
    that.submitted = true;
    if (!$event.isTrusted) return;
    if (this.studentId != 0) {
      //Add
      if (that.data == null || that.data?.Id == 0) {
        that.AddNewStudentServiceCancelation(modal);
      }
      //Edit
      else if (
        that.newServiceCancelationForm.controls[
          "ServiceCancelationRecurrenceDay"
        ].invalid &&
        that.Config.Filters[0].filterValue != "Once"
      ) {
        this.validSlectorDay = true;
      } else {
        that.toggeleLoader();
        that._clientStudentService
          .editStuedntServiceCancelation(
            that.getAddServiceCancelationObject(),
            this.studentId,
          )
          .subscribe(
            (Response: any) => {
              that._toastrService.success(
                "The service cancelation was added successfully.",
                "",
              );
              that.close(modal);
              that.Added.emit({ mode: "edit", type: that.type });
              that.toggeleLoader();
            },
            error => {
              if (error.status == 401) {
                that._toastrService.error("UnAuthorized.", "");
                window.location.href = "/pages/authentication/login";
                that.toggeleLoader();
              } else if (error.status == 300) {
                that._toastrService.error(error.error.Message, "");
                that.toggeleLoader();
              } else {
                that._toastrService.error(
                  "The service cancelation was not added due to technical issue.",
                  "",
                );
                that.toggeleLoader();
              }
            },
          );
      }
    } else {
      that.Added.emit({
        mode: "add",
        data: that.getAddServiceCancelationObject(),
        type: that.type,
      });
      that.close(modal);
    }
  }

  toggeleLoader() {
    let that = this;
    if (that.studentId == 0) {
      that._appcomponent.toggeleLoader();
    }
  }
  getAddServiceCancelationObject() {
    let that = this;
    if (that.data) {
      that.previewServiceCanclationItems();
    }
    let data = that.gridData;
    this.loadItems();
    return data;
  }

  SelectedType() {
    if (this.Config.Filters[1].filterValue == null) {
      this.validSlectorType = true;
    } else {
      this.validSlectorType = false;
    }
  }

  SelectedDay() {
    if (this.Config.Filters[2].filterValue == null) {
      this.validSlectorDay = true;
    } else {
      this.validSlectorDay = false;
    }
  }

  previewServiceCanclationItems() {
    let that = this;
    that.SelectedType();
    let oldData = this.gridData;

    let newData = [];

    if (!that.isValidForm() && !that.validSlectorType) {
      if (that.Config.Filters[0].filterValue == "Once") {
        that.prepareValidSelectorForm(oldData);
      } else {
        this.gridData = [];
        this.loadItems();

        let data = new StudentServiceCancelationModel();

        data = {
          TypeId: 4,
          Id: that.data && that.data.Id != 0 ? that.data.Id : 0,
          StudentId: that.studentId,
          EffectiveDate: "",
          ApplyTo: that.newServiceCancelationForm.value.ApplyTo,
          RecurrenceFrequency:
            that.newServiceCancelationForm.controls[
              "ServiceCancelationRecurrenceFrequency"
            ].value,
          RecurrenceDay:
            that.newServiceCancelationForm.value.ServiceCancelationRecurrenceDay.toString(),
          InternalNote:
            that.newServiceCancelationForm.value.ServiceCancelationNote,
          CreatedByAccount:
            that._currentUser.first_name + " " + that._currentUser.last_name,
        };
        that.initialDate = new Date();
        that.gridData.push(data);
        this.loadItems();

        oldData.forEach(element => {
          this.gridData.push(element);
        });

        for (const control of Object.keys(
          that.newServiceCancelationForm.controls,
        )) {
          that.newServiceCancelationForm.controls[control].markAsUntouched();
        }
        setTimeout(() => {
          const today = new Date();
          that.initialDate = new Date(today.setDate(today.getDate() + 1));
          that.newServiceCancelationForm
            .get("ServiceCancelationNote")
            .setValue("");
          that.Config.Filters[2].filterValue = null;
          that.Config.Filters[1].filterValue = null;
          that.typeChanged();
        }, 50);
      }
    }
  }

  deleteServiceCancelationRow(event, rowIndex) {
    event.preventDefault();
    this.gridData.splice(rowIndex, 1);
    this.loadItems();
  }

  getApplyToData(data) {
    if (data == "all") {
      return "Entire Day’s Rides";
    } else if (data == "morning") {
      return "Outbound Rides";
    } else if (data == "afternoon") {
      return "Return Rides";
    }
  }

  ChangeStartValue() {
    let startDate = new Date(
      this.newServiceCancelationForm.value.ServiceCancelationFromDate,
    );
    let endDate = new Date(
      this.newServiceCancelationForm.value.ServiceCancelationToDate,
    );
    if (
      moment(startDate).format("YYYYMMDD") > moment(endDate).format("YYYYMMDD")
    ) {
      this.newServiceCancelationForm
        .get("ServiceCancelationToDate")
        .setValue(startDate);
    }
  }

  getEffectiveDate(dataItem: any) {
    if (dataItem.EffectiveDate != "") {
      return dataItem.EffectiveDate;
    } else {
      return (
        "Every " +
        this.Config.Filters[2].filterValues.filter(
          x => x.id == dataItem.RecurrenceDay,
        )[0].name
      );
    }
  }

  public disabledDates = (date: Date): boolean => {
    return date < new Date(new Date().toDateString());
  };

  public pageChange(event: PageChangeEvent): void {
    this.state.skip = event.skip;
    this.loadItems();
  }

  public loadItems() {
    this.gridView = {
      data: this.gridData.slice(
        this.state.skip,
        this.state.skip + this.state.take,
      ),
      total: this.gridData.length,
    };
  }
  prepareValidSelectorForm(oldData) {
    let that = this;
    let serviceCancelationRowsCounter = 1;
    let startDate = new Date(
      that.newServiceCancelationForm.value.ServiceCancelationFromDate,
    );
    let endDate = new Date(
      that.newServiceCancelationForm.value.ServiceCancelationToDate,
    );
    this.gridData = [];
    this.loadItems();

    while (
      moment(startDate).format("YYYYMMDD") <= moment(endDate).format("YYYYMMDD")
    ) {
      serviceCancelationRowsCounter = serviceCancelationRowsCounter + 1;
      let data = new StudentServiceCancelationModel();
      data = {
        TypeId: 4,
        Id: that.data && that.data.Id != 0 ? that.data.Id : 0,
        StudentId: that.studentId,
        EffectiveDate: moment(startDate).format("MM-DD-YYYY"),
        ApplyTo: that.newServiceCancelationForm.value.ApplyTo,
        RecurrenceFrequency:
          that.newServiceCancelationForm.controls[
            "ServiceCancelationRecurrenceFrequency"
          ].value,
        RecurrenceDay: "0",
        InternalNote:
          that.newServiceCancelationForm.value.ServiceCancelationNote,
        CreatedByAccount:
          that._currentUser.first_name + " " + that._currentUser.last_name,
      };
      that.initialDate = new Date();
      that.gridData.push(data);
      this.loadItems();

      startDate = new Date(startDate.setDate(startDate.getDate() + 1));
      if (
        moment(startDate).format("YYYYMMDD") >
        moment(endDate).format("YYYYMMDD")
      ) {
        oldData.forEach(element => {
          this.gridData.push(element);
        });
        for (const control of Object.keys(
          that.newServiceCancelationForm.controls,
        )) {
          that.newServiceCancelationForm.controls[control].markAsUntouched();
        }
        setTimeout(() => {
          const today = new Date();
          that.initialDate = new Date(today.setDate(today.getDate() + 1));
          that.newServiceCancelationForm
            .get("ServiceCancelationNote")
            .setValue("");
          that.Config.Filters[1].filterValue = null;
          that.newServiceCancelationForm
            .get("ServiceCancelationFromDate")
            .setValue(that.initialDate);
          that.newServiceCancelationForm
            .get("ServiceCancelationToDate")
            .setValue(that.initialDate);
          that.typeChanged();
        }, 50);
      }
    }
  }
  AddNewStudentServiceCancelation(modal) {
    let that = this;
    that._clientStudentService
      .addStuedntServiceCancelation(
        that.getAddServiceCancelationObject(),
        this.studentId,
      )
      .subscribe(
        (response: any) => {
          if (response[0].ExistsCount == 0) {
            that.toggeleLoader();
            that._toastrService.success(
              "All service cancelations has been added successfully.",
              "",
            );
            that.close(modal);
            that.Added.emit({ mode: "edit", type: that.type });
          } else if (response[0].ExistsCount == response.length) {
            that._toastrService.success(
              "All service cancelations are already exist.",
              "",
            );
            that.close(modal);
          } else {
            that._toastrService.success(
              "Only not exist service cancelations has been added successfully.",
              "",
            );
            that.close(modal);
          }
          that.toggeleLoader();
        },
        error => {
          if (error.status == 401) {
            that._toastrService.error("UnAuthorized.", "");
            window.location.href = "/pages/authentication/login";
            that.toggeleLoader();
          } else if (error.status == 300) {
            that._toastrService.error(error.error.Message, "");
            that.toggeleLoader();
          } else {
            that._toastrService.error(
              "The service cancelation was not added due to technical issue.",
              "",
            );
            that.toggeleLoader();
          }
        },
      );
  }
}
