import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { CoreCommonModule } from "@core/common.module";
import { ContentHeaderModule } from "app/layout/components/content-header/content-header.module";
import { BrowserModule } from "@angular/platform-browser";
import { DataTablesModule } from "angular-datatables";
import { QuillModule } from "ngx-quill";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { NgSelectModule } from "@ng-select/ng-select";
import { OwlDateTimeModule, OwlNativeDateTimeModule } from "ng-pick-datetime";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import { CsvModule } from "@ctrl/ngx-csv";
import { GridModule } from "@progress/kendo-angular-grid";
import { DialogsModule } from "@progress/kendo-angular-dialog";
import { ButtonsModule } from "@progress/kendo-angular-buttons";

import { LayoutModule } from "@progress/kendo-angular-layout";
import { FormsModule } from "@angular/forms";
import { IconsModule } from "@progress/kendo-angular-icons";
import { InputsModule } from "@progress/kendo-angular-inputs";
import { LabelModule } from "@progress/kendo-angular-label";
import { DropDownsModule } from "@progress/kendo-angular-dropdowns";
import { ActiveRidesComponent } from "./ActiveRides/active-rides.component";
import { StatisticsModule } from "../../controls/component/statistics/statistics/statistics.module";
import { FiltersModule } from "../../controls/component/filters/filters.module";
import { GoogleMapsModule } from "@angular/google-maps";
import { TypeaHeadComponent } from "./typea-head/typea-head.component";
import { PermissionsList } from "app/enums/Permissions/PermissionList";
import { AuthGuard } from "app/auth/helpers";
import { AuthorizationGuard } from "app/auth/helpers/authorization.guard";

const routes = [
  {
    path: "ActiveRides/ActiveRides",
    component: ActiveRidesComponent,

    data: {
      animation: "ActiveRide",
      PermissionPageName: [PermissionsList[PermissionsList.RideMonitorView]],
    },
    canActivate: [AuthGuard, AuthorizationGuard],
  },
];

@NgModule({
  declarations: [ActiveRidesComponent, TypeaHeadComponent],
  imports: [
    LayoutModule,
    FormsModule,
    IconsModule,
    InputsModule,
    LabelModule,
    DropDownsModule,
    GridModule,
    ButtonsModule,
    DialogsModule,
    RouterModule.forChild(routes),
    ContentHeaderModule,
    QuillModule.forRoot(),
    NgxDatatableModule.forRoot({
      messages: {
        emptyMessage: '<div class="text-center">No records available</div>', // Message to show when array is presented, but contains no values
        totalMessage: "record", // Footer total message
        selectedMessage: "selected", // Footer selected message
      },
    }),
    TranslateModule,
    CoreCommonModule,
    NgbModule,
    BrowserModule,
    DataTablesModule,
    NgSelectModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    CsvModule,
    StatisticsModule,
    FiltersModule,
    GoogleMapsModule,
  ],
  exports: [ActiveRidesComponent],
})
export class ActiveRidesModule {}
