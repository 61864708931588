import {
  Component,
  Input,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { TabPosition } from "@progress/kendo-angular-layout";
import { State } from "@progress/kendo-data-query";
import { AppComponent } from "app/app.component";
import { User } from "app/auth/models";
import { AuthenticationService } from "app/auth/service";
import moment from "moment";
import { ToastrService } from "ngx-toastr";
import { ClientStudentsService } from "../services/client-students.service";
import { StudentAddNewServiceCancelationComponent } from "./StudentAddNewServiceCancelation/student-add-new-service-cancelation.component";
import { PermissionsList } from "app/enums/Permissions/PermissionList";
@Component({
  selector: "app-service-cancelation-section",
  templateUrl: "./service-cancelation-section.component.html",
  styleUrls: ["./service-cancelation-section.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class ServiceCancelationSectionComponent implements OnInit {
  public _currentUser: User;
  opened = false;
  public rowIndex: number = 1;
  public selectedData: any = {};
  public openedDelete: boolean = false;
  @Input("studentId") studentId: number;
  counter: number = 0;
  @ViewChild("addNewServiceCancelation")
  addNewServiceCancelation: StudentAddNewServiceCancelationComponent;
  public position: TabPosition = "top";
  state: State = {
    skip: 0,
    take: 25,
    group: [],
    filter: { filters: [], logic: "or" },
    sort: [
      {
        field: "name",
        dir: "asc",
      },
    ],
  };
  @Input() upcomingGridData: any;
  @Input() recurentGridData: any;
  @Input() pastGridData: any;
  @Input("disable") disable: boolean = true;
  public disabeld: boolean = false;
  public PermessionValue: string =
    PermissionsList.ClientStudentsEdit.toString();
  upcomingGridDataView: any = [];
  recurentGridDataView: any = [];
  pastGridDataView: any = [];
  days = [
    { id: "2", name: "Monday" },
    { id: "3", name: "Tuesday" },
    { id: "4", name: "Wednesday" },
    { id: "5", name: "Thursday" },
    { id: "6", name: "Friday" },
    { id: "7", name: "Saturday" },
    { id: "1", name: "Sunday" },
  ];
  public pageSize = 10;
  public skip = 0;
  /**
   * Constructor
   * * @param {AuthenticationService} _authenticationService
   */
  constructor(
    private _authenticationService: AuthenticationService,
    private _appComponent: AppComponent,
    private _router: Router,
    private route: ActivatedRoute,
    private _clientStudentService: ClientStudentsService,
    private _toastrService: ToastrService,
  ) {
    this._authenticationService.currentUser.subscribe(
      x => (this._currentUser = x),
    );
  }

  ngOnInit(): void {
    this.disabeld = !this.disable;
    let that = this;
    if (that.studentId != 0) {
      that.LoadUpcomingServiceCancelations();
      that.LoadRecurrentServiceCancelations();
      that.LoadPastServiceCancelations();
    }
  }

  modalOpenMD(data: any = null, rowIndex?: number, type: string = "") {
    this.opened = true;
    this.addNewServiceCancelation.data = data;
    if (data != null) {
      if (type == "upcoming") {
        this.addNewServiceCancelation.data = this.upcomingGridData[rowIndex];
      } else if (type == "recurent") {
        this.addNewServiceCancelation.data = this.recurentGridData[rowIndex];
      }
    }
    if (type == "upcoming") {
      this.rowIndex = rowIndex ?? this.upcomingGridData.length;
    } else if (type == "recurent") {
      this.rowIndex = rowIndex ?? this.recurentGridData.length;
    }
    this.addNewServiceCancelation.isAdd = !!data;
    this.addNewServiceCancelation.type = type;
    this.addNewServiceCancelation.open();
  }

  reloadData(result: any) {
    let that = this;

    if (result.mode == "edit") {
      that.LoadUpcomingServiceCancelations();
      that.LoadRecurrentServiceCancelations();
      that.LoadPastServiceCancelations();
    } else {
      if (result.data[0].RecurrenceFrequency == "Once") {
        result.type = "upcoming";
      } else {
        result.type = "recurent";
      }
      that.addLoadData(result);
    }
  }
  addLoadData(result) {
    let that = this;

    if (that.rowIndex != -1) {
      if (result.type == "upcoming") {
        that.handleUpcomingGridData(result);
      } else if (result.type == "recurent") {
        that.handleRecurentGridData(result);
      } else {
        that.handlePastGridData(result);
      }
    } else {
      that.reloadSubData(result);
    }
  }
  handleUpcomingGridData(result) {
    let that = this;

      if (result.data?.length > 0) {
        result.data.forEach(item => {
          that.upcomingGridData.push(JSON.parse(JSON.stringify(item)));
        });
      
    }
  }
  handleRecurentGridData(result) {
    let that = this;
    if (
      that.recurentGridData[that.rowIndex] == undefined ||
      that.recurentGridData[that.rowIndex] == null
    ) {
      if (result.data?.length > 0) {
        result.data.forEach(item => {
          that.recurentGridData.push(JSON.parse(JSON.stringify(item)));
        });
      }
    } else {
      that.recurentGridData[that.rowIndex] = JSON.parse(
        JSON.stringify(result.data[0]),
      );
    }
  }
  handlePastGridData(result) {
    let that = this;
    if (
      that.pastGridData[that.rowIndex] == undefined ||
      that.pastGridData[that.rowIndex] == null
    ) {
      if (result.data?.length > 0) {
        result.data.forEach(item => {
          that.pastGridData.push(JSON.parse(JSON.stringify(item)));
        });
      }
    } else {
      that.pastGridData[that.rowIndex] = JSON.parse(
        JSON.stringify(result.data[0]),
      );
    }
  }
  reloadSubData(result) {
    let that = this;
    if (result.type == "upcoming") {
      that.upcomingGridData.push(JSON.parse(JSON.stringify(result.data)));
    } else if (result.type == "recurent") {
      that.recurentGridData.push(JSON.parse(JSON.stringify(result.data)));
    } else {
      that.pastGridData.push(JSON.parse(JSON.stringify(result.data)));
    }
  }
  /*-----Start - Service Cancellations-----------*/
  LoadUpcomingServiceCancelations() {
    let that = this;
    that._appComponent.toggeleLoader();
    that._clientStudentService
      .getUpcomingServiceCancelations(that.studentId)
      .subscribe(
        (Response: any) => {
          that.upcomingGridData = Response;
          that.toggeleLoader();
        },
        error => {
          if (error.status == 401) {
            that._toastrService.error("UnAuthorized.", "");
            this._router.navigate(["/customer-success/students"]);
            that._appComponent.toggeleLoader();
          } else {
            that._toastrService.error(
              "The service cancelations was not loaded due to a technical issue.",
              "",
            );
            that._appComponent.toggeleLoader();
          }
        },
      );
  }

  LoadRecurrentServiceCancelations() {
    let that = this;
    that._appComponent.toggeleLoader();
    that._clientStudentService
      .getRecurrentServiceCancelations(that.studentId)
      .subscribe(
        (Response: any) => {
          that.recurentGridData = Response;
          that.toggeleLoader();
        },
        error => {
          if (error.status == 401) {
            that._toastrService.error("UnAuthorized.", "");
            this._router.navigate(["/customer-success/students"]);
            that._appComponent.toggeleLoader();
          } else {
            that._toastrService.error("", "");
            that._appComponent.toggeleLoader();
          }
        },
      );
  }

  LoadPastServiceCancelations() {
    let that = this;
    that._appComponent.toggeleLoader();
    that._clientStudentService
      .getPastServiceCancelations(that.studentId)
      .subscribe(
        (Response: any) => {
          that.pastGridData = Response;
          that.toggeleLoader();
        },
        error => {
          if (error.status == 401) {
            that._toastrService.error("UnAuthorized.", "");
            this._router.navigate(["/customer-success/students"]);
            that._appComponent.toggeleLoader();
          } else {
            that._toastrService.error("", "");
            that._appComponent.toggeleLoader();
          }
        },
      );
  }

  toggeleLoader() {
    let that = this;

    that.counter++;
    if (that.counter == 3) {
      that._appComponent.toggeleLoader();
      that.counter = 0;
    }
  }

  /* -----End - Service Cancellations----------*/

  deleteServiceCancelation(data: any, rowIndex: number, type) {
    let that = this;
    if (!data.Id) {
      if (type == "Once") {
        let oldData = this.upcomingGridData;
        this.upcomingGridDataView = [];
        oldData.splice(rowIndex, 1);
        oldData.forEach(element => {
          this.upcomingGridDataView.push(element);
        });
      } else {
        let oldDataRecurrent = this.recurentGridData;
        this.recurentGridDataView = [];
        oldDataRecurrent.splice(rowIndex, 1);
        oldDataRecurrent.forEach(element => {
          this.recurentGridDataView.push(element);
        });
      }
    } else {
      // delete by api on edit Student
      that.openedDelete = true;
      that.selectedData.Data = data;
      that.selectedData.Type = type;
      that.selectedData.RowIndex = rowIndex;
    }
  }

  close(status: string): void {
    let that = this;
    if (status == "deleted") {
      that.openedDelete = false;
      that.selectedData = {};
    }
  }

  confirmDeleteServiceCancelation() {
    let that = this;

    that._appComponent.toggeleLoader();
    that._clientStudentService
      .deleteStudentServiceCancelation(
        that.studentId,
        that.selectedData.Data.Id,
      )
      .subscribe(
        (data: any) => {
          if (that.selectedData.Type == "upcoming") {
            that.upcomingGridData.splice(that.selectedData.RowIndex, 1);
          } else if (that.selectedData.Type == "recurrent") {
            that.recurentGridData.splice(that.selectedData.RowIndex, 1);
          }
          that._toastrService.success(
            "The service cancelation was deleted successfully.",
            "",
          );
          that._appComponent.toggeleLoader();
          that.close("deleted");
        },
        error => {
          if (error.status == 401) {
            that._toastrService.error("UnAuthorized", "");
          } else if (error.status == 403) {
            that._toastrService.error(error.error.Message, "");
          } else if (error.status == 500) {
            that._toastrService.error(
              "The service cancelation was not deleted due to a technical issue.",
              "",
            );
          } else {
            that._toastrService.error("Server internal error", "");
          }
          that._appComponent.toggeleLoader();
        },
      );
  }

  getApplyToData(data) {
    if (data == "all") {
      return "Entire Day’s Rides";
    } else if (data == "morning") {
      return "Outbound Rides";
    } else if (data == "afternoon") {
      return "Return Rides";
    }
  }

  getEffectiveDate(dataItem: any) {
    if (dataItem.EffectiveDate && dataItem.EffectiveDate != "") {
      return moment(dataItem.EffectiveDate.toString())
        .format("MM/DD/YYYY")
        .toString();
    } else {
      return (
        "Every " + this.days.filter(x => x.id == dataItem.RecurrenceDay)[0].name
      );
    }
  }
}
