
<form
  [formGroup]="newGuardianForm"
  (keydown.enter)="$event.preventDefault()"
  class="districtsContainer"
>
  <div class="mainDiv row">
    <div class="pageHeader">
      <strong class="PageHeadline">Edit Guardians App Users</strong>
      <hr />
    </div>

    <div
      class="form_headline_container col-lg-12"
      style="padding-left: 23px"
    >
      <span class="form_headline">Account Information</span>
    </div>
    <div class="col-lg-12 custom_district_form">
      <div class="row">
        <div class="col-lg-12 fieldsDiv">
          <app-personal-photo
            #PersonalPhotoComponent
            [Title]="title"
            [Id]="guardianId"
            [EntityType]="entityType"
            [Folder]="folder"
            [Type]="type"
          ></app-personal-photo>
        </div>
        <div class="col-lg-6 fieldsDiv">
          <label
            for="FirstName"
            class="form_field_label"
            >First Name</label
          >

          <app-required-field
            type="text"
            formControlName="FirstName"
            placeholder="First Name"
          ></app-required-field>
        </div>

        <div class="col-lg-6 fieldsDiv">
          <label
            for="Last Name"
            class="form_field_label"
            >Last Name</label
          >

          <app-required-field
            type="text"
            formControlName="LastName"
            placeholder="Last Name"
          ></app-required-field>
        </div>
        <div class="col-lg-6 fieldsDiv">
          <label>Phone Number</label>
          <app-required-field
            type="tel"
            formControlName="PhoneNumber"
            placeholder="(000) 000-0000"
            maxlength="14"
            [appPhoneMask]
          ></app-required-field>
        </div>
        <div class="col-lg-6 fieldsDiv">
          <label
            for="Email"
            class="form_field_label"
            >Email</label
          >

          <app-optional-field
            type="text"
            formControlName="Email"
            placeholder="(optional)"
            validationError="Please enter a valid email address."
          ></app-optional-field>
        </div>

        <div class="col-lg-6 fieldsDiv">
          <label>Password</label>
          <app-required-field
            type="Password"
            formControlName="Password"
            placeholder="Set to ChangeMe! by default."
            disabled="true"
          ></app-required-field>
        </div>
        <div class="col-lg-6">
          <button
            type="button"
            rippleeffect=""
            class="btn btn-close waves-effect waves-float waves-light"
            style="
              border-color: #1877f2;
              margin-bottom: 20px;
              color: #1877f2;
              margin-top: 24px;
            "
            (click)="openRestPassPopup()"
          >
            Reset Password to Default
          </button>
        </div>
      </div>
      <h4>Linked to Students</h4>
      <br />
      <kendo-grid
        [kendoGridBinding]="guardianGrid"
        [sortable]="true"
        [scrollable]="false"
      >
        <kendo-grid-column
          [sortable]="false"
          [width]="50"
          title=" #"
        >
          <ng-template
            kendoGridCellTemplate
            let-rowIndex="rowIndex+1"
          >
            {{ rowIndex }}
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column
          field="FirstName"
          [sortable]="false"
          title="Student Name"
        >
          <ng-template
            kendoGridCellTemplate
            let-dataItem
          >
            <a
              class="btn btn-link pl-0 pr-0 text-right"
              href="customer-success/students/edit/{{ dataItem.StudentId }}"
            >
              {{ dataItem.FirstName }} {{ dataItem.LastName }}
            </a>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column
          field="RelationshipToStudent"
          [sortable]="false"
          title="Relationship"
        >
        </kendo-grid-column>
        <kendo-grid-column
          [sortable]="false"
          title="Action"
          [width]="90"
        >
          <ng-template
            kendoGridCellTemplate
            let-dataItem
            let-rowIndex="rowIndex"
          >
            <div class="d-flex align-items-center">
              <div
                ngbDropdown
                [placement]="'left-top'"
                container="body"
                class="m-auto"
              >
                <a
                  ngbDropdownToggle
                  href="javascript:void(0);"
                  class="hide-arrow"
                  id="dropdownBrowserState"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i
                    data-feather="more-vertical"
                    class="text-primary cursor-pointer mr-50"
                  ></i>
                </a>
                <div
                  ngbDropdownMenu
                  class="dropdown-menu-right"
                  aria-labelledby="dropdownBrowserState"
                >
                  <a
                    (click)="
                      openUnlinkPopup(
                        dataItem.StudentId,
                        dataItem.FirstName,
                        dataItem.LastName
                      )
                    "
                    ngbDropdownItem
                    class="d-flex align-items-center"
                  >
                    Unlink from Student
                  </a>
                </div>
              </div>
            </div>
          </ng-template>
        </kendo-grid-column>
      </kendo-grid>
      <br />
      <br />
      <h4>Address</h4>
      <app-address-gurdians-section
        [gridData]="AddressGridData"
        (deleted)="reloadData($event)"
      ></app-address-gurdians-section>
    </div>
    <div class="col-xl-12">
      <div class="modal-footer about-adroit-footer">
        <a href="client-gurdians/gurdians">
          <button
            type="button"
            rippleeffect=""
            class="btn btn-close waves-effect waves-float waves-light"
            style="border-color: #1877f2; margin-bottom: 20px; color: #1877f2"
          >
            Close
          </button>
        </a>
        <button
          type="submit"
          class="btn btn-info"
          style="margin-bottom: 20px; margin-right: 20px"
          rippleEffect
          (click)="onSubmit()"
        >
          Save Guardian
        </button>
      </div>
    </div>
  </div>
</form>

<kendo-dialog
  title=""
  *ngIf="openUnlink"
  (close)="close('block')"
  [minWidth]="250"
  [width]="400"
>
  <p style="text-align: center">
    Are you sure you want to unlink the student {{ fullName }}?
  </p>
  <kendo-dialog-titlebar>
    <div style="font-size: 18px; line-height: 1.3em; font-weight: 500">
      <span
        class="k-icon k-i-info-circle mr-1"
        style="color: #eea236; font-size: 26px; margin-top: -3px"
      ></span>
      Block Confirmation
    </div>
  </kendo-dialog-titlebar>

  <kendo-dialog-actions>
    <p style="text-align: center">
      <button
        type="button"
        (click)="close('block')"
        rippleeffect=""
        class="btn btn-default waves-effect waves-float waves-light"
        style="
          background-color: #acb7bf;
          border-color: #acb7bf;
          color: white;
          margin-right: 10px;
        "
      >
        No
      </button>
      <button
        type="button"
        (click)="Unlink()"
        rippleeffect=""
        class="btn btn-danger waves-effect waves-float waves-light"
      >
        Yes, unlink.
      </button>
    </p>
  </kendo-dialog-actions>
</kendo-dialog>

<kendo-dialog
  title=""
  *ngIf="openRestPass"
  (close)="close('block')"
  [minWidth]="250"
  [width]="400"
>
  <p style="text-align: center">
    Are you sure you want to reset the password ?
  </p>
  <kendo-dialog-titlebar>
    <div style="font-size: 18px; line-height: 1.3em; font-weight: 500">
      <span
        class="k-icon k-i-info-circle mr-1"
        style="color: #eea236; font-size: 26px; margin-top: -3px"
      ></span>
      Reset Password Confirmation
    </div>
  </kendo-dialog-titlebar>
  <kendo-dialog-actions>
    <p style="text-align: center">
      <button
        type="button"
        (click)="close('block')"
        rippleeffect=""
        class="btn btn-default waves-effect waves-float waves-light"
        style="
          background-color: #acb7bf;
          border-color: #acb7bf;
          color: white;
          margin-right: 10px;
        "
      >
        No
      </button>
      <button
        type="button"
        (click)="resetPassword()"
        rippleeffect=""
        class="btn btn-danger waves-effect waves-float waves-light"
      >
        Yes, reset it.
      </button>
    </p>
  </kendo-dialog-actions>
</kendo-dialog>
