<header
  class="flux-header clearfix"
  [attr.nav-layout]="navLayout"
  [attr.navbar-type]="toggleStatus ? defaultNavbar : toggleNavbar"
  [attr.effect-type]="navbarEffect"
  [attr.header-theme]="headerColorTheme"
  [attr.collapsed-left-header]="collapsedLeftHeader"
  [attr.device-type]="deviceType"
>
  <div class="flux-header-wrapper clearfix">
    <div class="flux-brand">
      <div
        class="sidebarToggle"
        (click)="changeTheToggleStatus()"
      >
        <span>
          <svg
            width="22"
            height="22"
            viewBox="0 0 22 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              x="16"
              width="6"
              height="6"
              rx="2"
              fill="white"
            />
            <rect
              x="8"
              width="6"
              height="6"
              rx="2"
              fill="white"
            />
            <rect
              width="6"
              height="6"
              rx="2"
              fill="white"
            />
            <rect
              x="16"
              y="8"
              width="6"
              height="6"
              rx="2"
              fill="white"
            />
            <rect
              x="8"
              y="8"
              width="6"
              height="6"
              rx="2"
              fill="white"
            />
            <rect
              y="8"
              width="6"
              height="6"
              rx="2"
              fill="white"
            />
            <rect
              x="16"
              y="16"
              width="6"
              height="6"
              rx="2"
              fill="white"
            />
            <rect
              x="8"
              y="16"
              width="6"
              height="6"
              rx="2"
              fill="white"
            />
            <rect
              y="16"
              width="6"
              height="6"
              rx="2"
              fill="white"
            />
          </svg>
        </span>
      </div>
    </div>
    <div class="flux-header-navbar">
      <div class="flux-left-header clearfix">
        <div class="title-left-header">
          {{ headerTitle }}
        </div>
        <div class="left-header-nav">
          <ul class="top-level-menu">
            <div *ngIf="TopMenu.length <= 0"></div>
            <li *ngFor="let item of TopMenu">
              <a
                [routerLink]="item.url"
                [ngStyle]="{ display: item.hidden ? 'none' : 'block' }"
                [ngClass]="
                  item.cildsubMenu?.length > 0
                    ? 'has-dropdown top-nav-link'
                    : 'top-nav-link'
                "
                >{{ item.name }}</a
              >
              <ul
                class="second-level-menu"
                *ngIf="item.cildsubMenu?.length > 0"
              >
                <li *ngFor="let subitem of item.cildsubMenu">
                  <a
                    [routerLink]="subitem.url"
                    class="top-nav-link"
                  >
                    {{ subitem.name }}
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
        <div class="navbar-container main-menu-content">
          <ul
            class="nav navbar-nav"
            id="main-menu-navigation"
            layout="horizontal"
            core-menu
          ></ul>
        </div>
      </div>

      <div class="flux-right-header clearfix">
        <ul>
          <li
            ngbDropdown
            class="nav-item dropdown-user"
          >
            <a
              class="nav-link dropdown-toggle dropdown-user-link"
              id="dropdown-user"
              ngbDropdownToggle
              id="navbarUserDropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <div class="user-nav d-sm-flex d-none">
                <span
                  class="user-name mb-0 font-weight-bolder"
                  id="username"
                  *ngIf="currentUser != null"
                  [innerHTML]="currentUser.full_name"
                ></span>
              </div>
              <a
                class="cui-avatar-nav-bar cui-avatar-border-white cui-avatar-90 bg-pink"
                href="javascript:void(0);"
              >
                <span
                  class="app-profile-avatar-nav-bar"
                  id="account_profile_avatar"
                  style="margin-top: 17px; color: #152858 !important"
                  >{{ currentUser?.short_name }}</span
                >
              </a>
            </a>

            <div
              ngbDropdownMenu
              aria-labelledby="navbarUserDropdown"
              class="dropdown-menu dropdown-user-control dropdown-menu-right"
            >
              <a
                ngbDropdownItem
                [routerLink]="['/Profile']"
                ><span
                  [data-feather]="'user'"
                  [class]="'mr-50'"
                ></span>
                Profile</a
              >

              <a
                ngbDropdownItem
                (click)="modalOpenSM(AboutADROIT)"
              >
                <img
                  src="./assets/images/logo/logo.svg"
                  class=""
                  style="height: 12px"
                  alt="logo"
                /><span [class]="'mr-50'"></span> About ADROIT</a
              >
              <div class="dropdown-divider"></div>
              <a
                ngbDropdownItem
                [routerLink]="['/pages/authentication/login']"
                ><span
                  [data-feather]="'power'"
                  [class]="'mr-50'"
                ></span>
                Logout</a
              >
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>

  <div
    (click)="changeTheToggleStatus()"
    class="overlay"
  ></div>
</header>
<!-- Modal -->
<ng-template
  #AboutADROIT
  let-modal
  style="overflow-y: hidden"
  class="about"
>
  <div class="modal-header">
    <h5
      class="modal-title"
      id="myModalLabel160"
    >
      About ADROIT
    </h5>

    <button
      type="button"
      class="close"
      (click)="modal.dismiss('Cross click')"
      aria-label="Close"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div
    class="modal-body"
    tabindex="0"
    style="overflow-y: hidden"
    ngbAutofocus
  >
    <div class="row col-xl-14">
      <div class="col-xl-14">
        <div class="card cui-apps-about-card">
          <div class="card-body text-center c">
            <img
              src="./assets/images/logo/FullLogo.png"
              class="img-fluid about-adroit-item"
              style="height: 60%; width: 60%; padding-bottom: 10px"
              alt="fulllogo"
            />

            <br />
            <div class="about-adroit-item">Version 2.0</div>
            <div class="about-adroit-item">
              <a
                href="https://www.goadroit.com/privacy-policy"
                target="_blank"
                style="color: #1877f2 !important"
                rel="noopener"
              >
                Privacy policy
              </a>
              <a style="color: #1877f2 !important">|</a>
              <a
                href="https://www.goadroit.com/terms-of-service"
                target="_blank"
                style="color: #1877f2 !important"
                rel="noopener"
                >Terms of service</a
              >
            </div>
            <div class="about-adroit-item">
              ©2024 Adroit Advanced Technologies Inc, a Beacon company. All
              rights reserved.
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal-footer about-adroit-footer">
    <button
      type="button"
      class="btn btn-info"
      (click)="modal.close('Accept click')"
      rippleEffect
    >
      Close
    </button>
  </div>
</ng-template>
<!-- / Modal -->
