<div
  *ngIf="Config.filterVisability"
  class="form-group"
>
  <!-- Basic Select with Search -->
  <input
    style="height: 38px"
    type="number"
    [disabled]="Config.filterDisabled"
    class="form-control"
    [(ngModel)]="Config.filterValue"
    oninput="this.value =!!this.value && Math.abs(this.value) >= 1 && Math.abs(this.value) <= 50 ? Math.abs(this.value) : null"
    [placeholder]="Config.filterPlaceholder"
  />
  <!--/ Basic Select with Search -->
</div>
