import {
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import {
  DataBindingDirective,
  PageSizeItem,
} from "@progress/kendo-angular-grid";
import { SortDescriptor, State } from "@progress/kendo-data-query";
import { User } from "app/auth/models";
import { AuthenticationService } from "app/auth/service";
import moment from "moment";
import { AppComponent } from "app/app.component";
import { ToastrService } from "ngx-toastr";
import { FiltersTypes } from "app/enums/controls/filters/FiltersTypes";
import { CollectionFiltersComponent } from "app/controls/component/filters/collection-filters/collection-filter.component";
import { IFiltersConfig } from "app/controls/component/filters/interfaces/controls/IFiltersConfig";
import { ClientStudentsFilterModel } from "../model/ClientStudentsFilterModel";
import { HelpersService } from "app/main/SharedServices/helpers.service";
import { ClientStudentsService } from "../services/client-students.service";
import { ClientGurdiansFilterModel } from "app/main/Guardians/model/ClientGurdiansFilterModel";
import { ClientGuardiansService } from "app/main/Guardians/services/client-guardians.service";
import { environment } from "environments/environment";
import { ActivatedRoute, Router } from "@angular/router";
import { PermissionsList } from "app/enums/Permissions/PermissionList";
import { UploadFileModel } from "../model/UploadFileModel";
import * as FileSaver from "file-saver";

@Component({
  selector: "app-client-students",
  templateUrl: "./client-students.component.html",
  styleUrls: ["./client-students.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class ClientStudentsComponent implements OnInit, OnDestroy {
  @ViewChild(DataBindingDirective) dataBinding: DataBindingDirective;
  contentHeader: object;
  isFiltersEnable = false;
  pageSize = 25;
  gridIndex: number = 0;
  disableLoadMore: boolean = false;
  basicSelectedOption: number = 0;
  opened = false;
  opendSearch = false;
  skip: number = 0;
  CancelTransportationopened = false;
  gridData: any[] = [];
  gridView: any;
  wayPotintId: number;
  total: number = 0;
  downlowdUrl = environment.webUrl + "Students/DownloadStaticFile";
  public disabled: boolean = true;
  public isFromClear:boolean=false;
  state: State = {
    skip: 0,
    take: 25,
    group: [],
    filter: { filters: [], logic: "or" },
    sort: [
      {
        field: "name",
        dir: "asc",
      },
    ],
  };
  pagesizes: PageSizeItem[] = [
    { text: "50", value: 50 },
    { text: "100", value: 100 },
    { text: "250", value: 250 },
    { text: "500", value: 500 },
    { text: "1000", value: 1000 },
    { text: "All", value: -1 },
  ];
  public _currentUser: User;
  public FiltersConfig: IFiltersConfig = {
    isFiltersEnable: false,
    Filters: [
      {
        filterType: FiltersTypes.GurdiansAppUsers,
        filterPlaceholder: "Filter by Guardian App User",
        filterClear: true,
        filterVisability: true,
        filtersize: "4",
      },
      {
        filterType: FiltersTypes.Grade,
        filterPlaceholder: "Filter By Grade",
        filterClear: true,
        filterVisability: true,
        filtersize: "4",
      },
      {
        filterType: FiltersTypes.places,
        filterPlaceholder: "Filter By Place Name",
        filterClear: true,
        filterVisability: true,
        filtersize: "4",
      },
    ],
  };
  @ViewChild(CollectionFiltersComponent)
  collectionOfFiltersComponent: CollectionFiltersComponent;
  /**
   * Constructor
   * * @param {AuthenticationService} _authenticationService
   * * @param {HelpersService} _helpersService
   * * @param {ClientGuardiansService} _clientStudentsService
   * * @param {AppComponent} _appComponent
   * * @param {ToastrService} _toastrService
   */
  constructor(
    private _authenticationService: AuthenticationService,
    private _appComponent: AppComponent,
    private _toastrService: ToastrService,
    private _helperService: HelpersService,
    private _clientStudentsService: ClientStudentsService,
    private _clientGurdiansService: ClientGuardiansService,
    private route: ActivatedRoute,
    private _router: Router,
  ) {
    this._authenticationService.currentUser.subscribe(
      x => (this._currentUser = x),
    );
    this._appComponent._mainpageTitle = "Passengers";
  }
  public pagesize;

  onFilter(input: Event): void {
    const inputValue = (input.target as HTMLInputElement).value;
    let filterModel: ClientStudentsFilterModel = this.getFilterModel();
    filterModel.searchTerm = inputValue;
    this.getGridData(filterModel);
  }
  ngOnInit() {
    this.disabled = this._authenticationService.checkaccess(
      PermissionsList[PermissionsList.ClientStudentsCreate],
    );
    let id = parseInt(this.route.snapshot.paramMap.get("id")!);
    this.pagesize = 25;
    this.gridIndex = 25;
    this.getGuardians();
    this.getPlaces();
    let filterModel: ClientStudentsFilterModel = this.getFilterModel();
    if (!Number.isNaN(id)) {
      filterModel.guardianId = id;
      this.getFilterData(false);
    }
    this.getGridData(filterModel);
    this.gridView = this.gridData;
    this.contentHeader = {
      headerTitle: "",
      actionButton: true,
      breadcrumb: {
        type: "",
        links: [
          {
            name: "Home",
            isLink: true,
            link: "/",
          },
          {
            name: "Sample",
            isLink: false,
          },
        ],
      },
    };
  }

  viewFilter(): void {
    if (this.isFiltersEnable) {
      this.isFiltersEnable = false;
    } else {
      this.isFiltersEnable = true;
    }
  }

  viewMore(): void {
    let that = this;
    let filterModel: ClientStudentsFilterModel = that.getFilterModel();
    if (that.gridData.length >= this.basicSelectedOption) {
      return;
    }
    if (that.gridData.length > this.basicSelectedOption) {
      this.disableLoadMore = true;
    }
    if (that.gridData.length + 5 >= this.basicSelectedOption) {
      that.skip = 0;
      filterModel.limit = this.basicSelectedOption;
      this.disableLoadMore = true;
    } else {
      filterModel.limit = that.gridData.length + that.gridIndex;
      that.skip = 0;
    }
    that.getGridData(filterModel);
  }

  changeGridIndex(): void {
    this.gridIndex = Number(this.gridIndex);
    this.pagesize = Number(this.gridIndex);
    let filteModel = this.getFilterModel();
    this.getGridData(filteModel);
  }

  getGridData(filterModel?: ClientStudentsFilterModel) {
    let that = this;
    filterModel.offset = that.skip;

    this._appComponent.toggeleLoader();
    this._clientStudentsService.getDataTableRows(filterModel).subscribe(
      (data: any) => {
        that.gridData = data.Students;
        that.gridView = data.Students;
        that.total = data.TotalNumberofRecords;
        that.basicSelectedOption = data.TotalNumberofFilteredRecords;
        that.disableLoadMore =
          that.gridData.length == data.TotalNumberofFilteredRecords;
        this._appComponent.toggeleLoader();
      },
      error => {
        if (error.status == 401) {
          this._toastrService.error("UnAuthorized", "");
        } else if (error.status == 403) {
          this._toastrService.error(error.error.Message, "");
        } else if (error.status == 500) {
          this._toastrService.error(
            "The students  was not loaded due to a technical issue.",
            "",
          );
        } else {
          this._toastrService.error("Server internal error", "");
        }
        this._appComponent.toggeleLoader();
      },
    );
  }

  getName(data) {
    return (
      data.FirstName.split('"').join("") +
      " " +
      data.LastName.split('"').join("")
    );
  }

  getPlaceVlaue(date) {
    return date.Places.replace(/(\r\n|\n|\r)/gm, "<br>");
  }

  clearAllData() {
    this.isFromClear=true;
    this.getFilterData(this.isFromClear);
  }

  // public changeStatus() {}
  getGuardianFilterData() {
    let that = this;
    let statusRecord = that.FiltersConfig.Filters.filter(
      x => x.filterType == FiltersTypes.GurdiansAppUsers,
    )[0];
    if (statusRecord.filterValue != undefined) return statusRecord.filterValue;
    else return 0;
  }

  getGradeFilterData() {
    let that = this;
    let statusRecord = that.FiltersConfig.Filters.filter(
      x => x.filterType == FiltersTypes.Grade,
    )[0];

    if (statusRecord.filterValue != undefined) return statusRecord.filterValue;
    else return "";
  }

  getPlaceFilterData() {
    let that = this;
    let statusRecord = that.FiltersConfig.Filters.filter(
      x => x.filterType == FiltersTypes.places,
    )[0];
    if (statusRecord.filterValue != undefined) return statusRecord.filterValue;
    else return 0;
  }

  getDateOfBirth(data) {
    return (
      moment(data.DateOfBirth).format("MM/DD/YYYY") +
      " (" +
      Math.round(
        moment.duration(moment().diff(moment(data.DateOfBirth))).asYears(),
      ) +
      ")"
    );
  }

  getFilterData(isFromClear): void {
    let filterModel: ClientStudentsFilterModel = this.getFilterModel();
    let id = parseInt(this.route.snapshot.paramMap.get("id")!);
    if (!Number.isNaN(id) && filterModel.guardianId == 0) {
      if(!isFromClear){
        filterModel.guardianId = id;
        this.FiltersConfig.Filters[0].filterValue = id;
      }
      
    }
    this.getGridData(filterModel);
  }

  sortChange(sort: SortDescriptor[]): void {
    let that = this;
    this.state.sort = sort;
    let filterModel: ClientStudentsFilterModel = this.getFilterModel();
    console.log(sort[0].dir);
    if (sort[0].dir == "desc") {
      filterModel.order_by = "desc";
      filterModel.sort_by = "name";
    } else {
      filterModel.order_by = "asc";
      filterModel.sort_by = "name";
    }
    that.getGridData(filterModel);
  }

  getFilterModel() {
    let filterModel: ClientStudentsFilterModel =
      new ClientStudentsFilterModel();
    filterModel.clientId = this._currentUser.client_id;
    filterModel.limit = this.gridIndex > 0 ? this.gridIndex : filterModel.limit;
    filterModel.offset = this.skip;
    filterModel.order_by = "asc";
    filterModel.viewType = "ClientGetStudentsPage";
    filterModel.searchTerm = "";
    filterModel.grade = this.getGradeFilterData();
    filterModel.placeId = this.getPlaceFilterData();
    filterModel.guardianId = this.getGuardianFilterData();
    return filterModel;
  }

  reloadData() {
    let filterModel: ClientStudentsFilterModel = this.getFilterModel();
    this.getGridData(filterModel);
  }

  getPlaces() {
    let places = this.FiltersConfig.Filters.filter(
      x => x.filterType == FiltersTypes.places,
    )[0];
    this._clientStudentsService.getPlaces().subscribe(
      Response => {
        let placesfilterres = [];
        Response.Places.forEach(function (value) {
          let data = {
            id: value.Id,
            name: value.Name,
          };
          placesfilterres.push(data);
        });
        places.filterValues = placesfilterres;
        places.filterDisabled = false;
      },
      error => {
        if (error.status == 401) {
          this._toastrService.error("UnAuthorized.", "");
          window.location.href = "/pages/authentication/login";
        } else {
          this._toastrService.error(error.error.Message, "");
        }
      },
    );
  }

  getGuardians() {
    let that = this;
    let filter = this.FiltersConfig.Filters.filter(
      x => x.filterType == FiltersTypes.GurdiansAppUsers,
    )[0];
    that._clientGurdiansService
      .getDataTableRows(that.getGurdianFilterModel())
      .subscribe(
        (Response: any) => {
          let guardianfilter = [];
          Response.Guardians.forEach(function (value) {
            let data = {
              id: value.Id,
              name:
                value.FirstName +
                " " +
                value.LastName +
                " | " +
                that.getMobileNumber(value.MobileNumber),
            };
            guardianfilter.push(data);
          });
          filter.filterValues = guardianfilter;
          filter.filterDisabled = false;
        },
        error => {
          if (error.status == 401) {
            this._toastrService.error("UnAuthorized.", "");
            window.location.href = "/pages/authentication/login";
          } else {
            this._toastrService.error(error.error.Message, "");
          }
        },
      );
  }

  getMobileNumber(mobileNumber: string) {
    return this._helperService.phoneNumber(mobileNumber);
  }

  getGurdianFilterModel() {
    let filterModel: ClientGurdiansFilterModel =
      new ClientGurdiansFilterModel();
    filterModel.archived = false;
    filterModel.isBlocked = false;
    filterModel.clientId = this._currentUser.client_id;
    filterModel.limit = 200;
    filterModel.offset = 0;
    filterModel.order_by = "asc";
    filterModel.viewType = "ClientGuardians";
    filterModel.searchTerm = "";
    filterModel.studentId = 0;
    return filterModel;
  }

  selectFile() {
    let input: any = document.getElementById("Import_upload");

    let files = input.files;
    if (files.length > 0) {
      let formData = new FormData();
      for (let i = 0; i <= files.length; i++) {
        formData.append("files", files[i]);
      }
      let fileExtension = files[0].name.split(".");
      let lastElement = fileExtension[fileExtension.length - 1];
      if (lastElement != "xlsx") {
        this._toastrService.info(
          "File extension is not correct.",
          "Kindly make sure that you are using the correct format.",
        );
        $("#Import_upload").val("").trigger("change");
        return false;
      }
      this.ImportBulkUpload(formData);
    }
  }
  generateGoogleCloudViewUrl(fileName) {
    let that = this;
    let result: UploadFileModel = new UploadFileModel();
    result.name = fileName;
    that._clientStudentsService.downloadFile(result).subscribe(response => {
      that.openBase64InNewTab(
        response.Value,
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      );
    });
  }

  openBase64InNewTab(data, mimeType) {
    let byteCharacters = atob(data);
    let byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    let byteArray = new Uint8Array(byteNumbers);
    let file = new Blob([byteArray], { type: mimeType + ";base64" });

    FileSaver.saveAs(file, "Students.xlsx");
  }

  ImportBulkUpload(formData: FormData) {
    this._appComponent.toggeleLoader();
    this._clientStudentsService.importBulkStudnet(formData).subscribe(
      (reponse: any) => {
        if(reponse.length != 0){
          this.generateGoogleCloudViewUrl(
            "STATIC_FILES/ImportBulkStudents/ImportStudentStatistics_" +
              this._currentUser.client_id +
              ".xlsx",
          );
          $("#Import_upload").val("").trigger("change");
          this._toastrService.success(
            "The students were Imported successfully.",
            "",
          );
        }else{
          this._toastrService.error(
            "The uploaded file is empty. Please make sure the file you are attempting to upload contains data.",
            "",
          );
        }

        this._appComponent.toggeleLoader();
      },
      error => {
        if (error.status == 401) {
          this._toastrService.error("UnAuthorized", "");
        } else if (error.status == 403) {
          this._toastrService.error(error.error.Message, "");
        } else if (error.status == 500) {
          this._toastrService.error(
            "The sheet not Import due to a technical issue.",
            "",
          );
        } else {
          this._toastrService.error("Server internal error", "");
        }
        this._appComponent.toggeleLoader();
      },
    );
  }

  addNewStudent() {
    this._router.navigate(["/customer-success/students/new"]);
  }

  ngOnDestroy() {
    sessionStorage.removeItem("Addresses");
    sessionStorage.removeItem("Places");
  }
}
