import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AuthenticationService } from "app/auth/service";
import { ToastrService } from "ngx-toastr";
import { Subject } from "rxjs";
import { TabPosition } from "@progress/kendo-angular-layout";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { GuardianAppUsersComponent } from "../GuardianAppUsers/guardian-app-users.component";
import { GuardianSelectorsComponent } from "../GuardianSelectors/guardian-selectors.component";
import { IFiltersConfig } from "app/controls/component/filters/interfaces/controls/IFiltersConfig";
import { ClientGuardiansService } from "../services/client-guardians.service";
import { ClientGurdiansFilterModel } from "../model/ClientGurdiansFilterModel";
import { User } from "app/auth/models";
import { GuardianDataModel } from "../model/GuardianDataModel";
import { AccountDataModel } from "../model/AccountDataModel";
import { AppComponent } from "app/app.component";
import { HelpersService } from "app/main/SharedServices/helpers.service";
@Component({
  selector: "app-add-new-guardian",
  templateUrl: "./add-new-guardian.component.html",
  styleUrls: ["./add-new-guardian.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class AddNewGuardianComponent implements OnInit {
  public submitted = false;
  public newGuardianForm: FormGroup;
  private _unsubscribeAll: Subject<any>;
  public position: TabPosition = "top";
  @ViewChild("AddNewGuardian") AddNewGuardianModal: TemplateRef<any>;
  @ViewChild("gurdianAppUsers")
  guardianAppUsersComponent: GuardianAppUsersComponent;
  @ViewChild(GuardianSelectorsComponent, { static: true })
  guardianSelectorsComponent: GuardianSelectorsComponent;
  @Output() Added = new EventEmitter<string>();
  validRelationShip: boolean = false;
  validStudent: boolean = false;
  validGuardian: boolean = false;
  public Config: IFiltersConfig;
  public _currentUser: User;
  guardianfilterres = [];
  addExistingTap: boolean = false;
  addNewTap: boolean = true;
  guardianexist: boolean = false;
  /**
   * Constructor
   * * @param {AuthenticationService} _authenticationService
   * * @param {HelpersService} _helpersService
   * * @param {ClientGuardiansService} _clientGurdiansService
   * * @param {NgbModal} _modalService
   * * @param {ToastrService} _toastrService
   * * @param {FormBuilder} _formBuilder
   * * @param {AppComponent} _appcomponent
   */
  constructor(
    private _formBuilder: FormBuilder,
    private _authenticationService: AuthenticationService,
    private _toastrService: ToastrService,
    private _modalService: NgbModal,
    private _clientGurdiansService: ClientGuardiansService,
    private _helperService: HelpersService,
    private _appcomponent: AppComponent,
  ) {
    this._authenticationService.currentUser.subscribe(
      x => (this._currentUser = x),
    );
  }

  ngOnInit(): void {
    this.newGuardianForm = this._formBuilder.group({
      relationshipName: ["", [Validators.required]],
      studentId: ["", Validators.required],
      FirstName: ["", [Validators.required]],
      LastName: ["", [Validators.required]],
      PhoneNumber: [
        "",
        [Validators.pattern(/^\(\d{3}\)\s\d{3}-\d{4}$/), Validators.required],
      ],
      Email: ["", [Validators.email]],
      Password: [""],
      guardianId: [""],
    });
    this.Config = {
      isFiltersEnable: false,
      Filters: [
        {
          filterValues: [
            { id: "Aunt", name: "Aunt" },
            { id: "Brother", name: "Brother" },
            { id: "Father", name: "Father" },
            { id: "Grandfather", name: "Grandfather" },
            { id: "Grandmother", name: "Grandmother" },
            { id: "Legal Guardian", name: "Legal Guardian" },
            { id: "Mother", name: "Mother" },
            { id: "Sister", name: "Sister" },
            { id: "Uncle", name: "Uncle" },
            { id: "Other", name: "Other" },
          ],
          filterClear: true,
          filterVisability: true,
          filterPlaceholder: "Select a relationship.",
        },
        {
          filterValues: this.getPassengers(),
          filterClear: true,
          filterVisability: true,
          filterPlaceholder: "Select a student.",
        },
        {
          filterValues: this.getGuardians(),
          filterClear: true,
          filterVisability: true,
          filterPlaceholder: "Select a guardian app user.",
        },
      ],
    };
  }

  changeRelationship(e: any) {
    this.relationshipName?.setValue(e.target.value, {
      onlySelf: true,
    });
  }

  // Access formcontrols getter
  get relationshipName() {
    return this.newGuardianForm.get("relationshipName");
  }

  get studentName() {
    return this.newGuardianForm.get("studentName");
  }

  changeStudent(e: any) {
    this.studentName?.setValue(e.target.value, {
      onlySelf: true,
    });
  }

  public isValidForm(): boolean {
    let that = this;

    if (that.addExistingTap) {
      that.relationshipSelected();
      that.gurdianSelected();
      return (
        !that.studentSelected() &&
        !that.relationshipSelected() &&
        !that.gurdianSelected()
      );
    } else {
      that.prepareGuardianForm();
    }
  }

  public open() {
    this._modalService.open(this.AddNewGuardianModal, {
      centered: true,
      backdrop: "static",
      size: "md",
    });
    this.newGuardianForm.get("studentId").valueChanges.subscribe(value => {});
  }

  getPassengers() {
    let passengerfilterres = [];
    this._clientGurdiansService.getPassengers().subscribe(
      Response => {
        Response.forEach(function (value) {
          let data = {
            Id: value.Id,
            name: value.FirstName + " " + value.LastName,
          };
          passengerfilterres.push(data);
        });
      },
      error => {
        if (error.status == 401) {
          this._toastrService.error("UnAuthorized.", "");
          window.location.href = "/pages/authentication/login";
        } else {
          this._toastrService.error(error.error.Message, "");
        }
      },
    );
    return passengerfilterres;
  }

  public studentSelected() {
    let that = this;
    if (this.Config.Filters[1].filterValue == null) {
      that.validStudent = true;
      return that.validStudent;
    } else {
      that.validStudent = false;
      return that.validStudent;
    }
  }

  public relationshipSelected() {
    if (this.Config.Filters[0].filterValue == null) {
      this.validRelationShip = true;
      return this.validRelationShip;
    } else {
      this.validRelationShip = false;
      return this.validRelationShip;
    }
  }

  getGuardians() {
    let that = this;
    let guardianfilter = [];
    that._clientGurdiansService
      .getDataTableRows(that.getFilterModel())
      .subscribe(
        (Response: any) => {
          that.guardianfilterres = Response.Guardians;

          Response.Guardians.forEach(function (value) {
            let data = {
              Id: value.Id,
              name: value.FirstName + " " + value.LastName,
              MobileNumber: that.getMobileNumber(value.MobileNumber),
              Email: value.Email,
            };
            guardianfilter.push(data);
          });
        },
        error => {
          if (error.status == 401) {
            that._toastrService.error("UnAuthorized.", "");
            window.location.href = "/pages/authentication/login";
          } else {
            that._toastrService.error(error.error.Message, "");
          }
        },
      );
    return guardianfilter;
  }

  public getFilterModel() {
    let filterModel: ClientGurdiansFilterModel =
      new ClientGurdiansFilterModel();
    filterModel.archived = false;
    filterModel.isBlocked = false;
    filterModel.clientId = this._currentUser.client_id;
    filterModel.limit = 200;
    filterModel.offset = 0;
    filterModel.order_by = "asc";
    filterModel.viewType = "ClientGuardians";
    filterModel.searchTerm = "";
    filterModel.studentId = 0;
    return filterModel;
  }

  public gurdianSelected() {
    if (this.Config.Filters[2].filterValue == null) {
      this.validGuardian = true;
      return this.validGuardian;
    } else {
      this.validGuardian = false;
      return this.validGuardian;
    }
  }

  getMobileNumber(mobileNumber: string) {
    return this._helperService.phoneNumber(mobileNumber);
  }

  onTapSelected(input: any) {
    this.validGuardian = false;
    for (const control of Object.keys(this.newGuardianForm.controls)) {
      this.newGuardianForm.controls[control].markAsUntouched();
    }
    if (input.index == 1) {
      this.addExistingTap = true;
      this.addNewTap = false;
    } else {
      this.addExistingTap = false;
      this.addNewTap = true;
    }
  }

  close(model: any) {
    let that = this;
    for (const control of Object.keys(that.newGuardianForm.controls)) {
      that.newGuardianForm.controls[control].markAsUntouched();
    }

    that.newGuardianForm.reset();
    that.validStudent = false;
    that.validGuardian = false;
    that.validRelationShip = false;
    that.addExistingTap = false;
    that.addNewTap = true;
    model.close("Accept click");
  }

  onSubmit(modal: any) {
    let that = this;
    that.submitted = true;
    if (that.isValidForm() && that.addExistingTap) {
      that.getExistingGuardianForm(modal);
    } else if (!that.isValidForm() && that.addNewTap) {
      that._appcomponent.toggeleLoader();
      that._clientGurdiansService
        .addNewGuardian(
          Number(that.newGuardianForm.value.studentId),
          that.getAddGuardianObject(),
        )
        .subscribe(
          (Response: any) => {
            that._toastrService.success(
              "The guardian account was added successfully.",
              "",
            );
            that.close(modal);
            that.Added.emit();
            that._appcomponent.toggeleLoader();
          },
          error => {
            if (error.status == 401) {
              that._toastrService.error("UnAuthorized.", "");
              window.location.href = "/pages/authentication/login";
              that._appcomponent.toggeleLoader();
            } else if (error.status == 300) {
              that._toastrService.error(error.error.Message, "");
              that._appcomponent.toggeleLoader();
            } else {
              that._toastrService.error(
                "The guardian account was not added due to technical issue.",
                "",
              );
              that._appcomponent.toggeleLoader();
            }
          },
        );
    }
  }

  getAddGuardianObject() {
    let that = this;
    let data = new GuardianDataModel();
    if (
      that.addExistingTap &&
      Number(that.newGuardianForm.value.guardianId) != 0
    ) {
      data.AccountId = Number(that.newGuardianForm.value.guardianId);
      let guardian = that.guardianfilterres.filter(
        x => x.Id == Number(that.newGuardianForm.value.guardianId),
      );
      let account = new AccountDataModel();
      account.Id = Number(that.newGuardianForm.value.guardianId);
      account.FirstName = guardian[0].FirstName;
      account.LastName = guardian[0].LastName;
      account.Email = guardian[0].Email;
      account.MobileNumber = guardian[0].MobileNumber;
      data.Account = account;

      data.CreatedByAccountId = that._currentUser.id;
      data.StudentId = Number(that.newGuardianForm.value.studentId);
      data.RelationshipToStudent = that.newGuardianForm.value.relationshipName;
    } else {
      data.Id = 0;
      let account = new AccountDataModel();
      account.Id = Number(that.newGuardianForm.value.guardianId);
      account.FirstName = that.newGuardianForm.value.FirstName;
      account.LastName = that.newGuardianForm.value.LastName;
      account.Email = that.newGuardianForm.value.Email;

      account.MobileNumber = that.newGuardianForm.value.PhoneNumber.replace(
        /\D/g,
        "",
      );
      data.Account = account;
      data.RelationshipToStudent = that.newGuardianForm.value.relationshipName;
      data.StudentId = that.newGuardianForm.value.studentId;
      data.AccountId = 0;
      data.isClientPortal = true;
      data.CreatedByAccountId = that._currentUser.id;
    }
    return data;
  }

  closeConfiramtion() {
    this.guardianexist = false;
  }
  prepareGuardianForm() {
    let that = this;
    that.studentSelected();
    that.relationshipSelected();
    for (const control of Object.keys(that.newGuardianForm.controls)) {
      that.newGuardianForm.controls[control].markAsTouched();
    }

    if (
      this.newGuardianForm.value.FirstName != null &&
      this.newGuardianForm.value.LastName != null
    ) {
      let newData = this.newGuardianForm.value.FirstName.replace(/\s/g, "");
      let newData1 = this.newGuardianForm.value.LastName.replace(/\s/g, "");
      if (newData.length <= 0 && newData1.length <= 0) {
        this.newGuardianForm.get("FirstName").reset();
        this.newGuardianForm.get("LastName").reset();
      } else if (newData.length <= 0) {
        this.newGuardianForm.get("FirstName").reset();
      } else if (newData1.length <= 0) {
        this.newGuardianForm.get("LastName").reset();
      }
    } else if (this.newGuardianForm.value.FirstName != null) {
      let newData = this.newGuardianForm.value.FirstName.replace(/\s/g, "");
      if (newData.length <= 0) {
        this.newGuardianForm.get("FirstName").reset();
      }
    } else if (this.newGuardianForm.value.LastName != null) {
      let newData1 = this.newGuardianForm.value.LastName.replace(/\s/g, "");
      if (newData1.length <= 0) {
        this.newGuardianForm.get("LastName").reset();
      }
    }

    return that.newGuardianForm.invalid;
  }
  getExistingGuardianForm(modal) {
    let that = this;
    that._appcomponent.toggeleLoader();
    if (
      that.addExistingTap &&
      Number(that.newGuardianForm.value.guardianId) != 0
    ) {
      that._clientGurdiansService
        .checkGuardianExist(
          Number(that.newGuardianForm.value.guardianId),
          Number(that.newGuardianForm.value.studentId),
          that.getAddGuardianObject(),
        )
        .subscribe(
          (Response: any) => {
            if (Response) {
              that.guardianexist = true;
              that._appcomponent.toggeleLoader();
            } else {
              that.guardianexist = false;
              that._toastrService.success(
                "The guardian account was added successfully.",
                "",
              );
              that.close(modal);
              that.Added.emit();
              that._appcomponent.toggeleLoader();
            }
          },
          error => {
            if (error.status == 401) {
              that._toastrService.error("UnAuthorized.", "");
              window.location.href = "/pages/authentication/login";
              that._appcomponent.toggeleLoader();
            } else {
              that._toastrService.error(
                "The guardian account was not added due to technical issue.",
                "",
              );
              that._appcomponent.toggeleLoader();
            }
          },
        );
    }
  }
}
