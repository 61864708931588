import {
  Component,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
  ElementRef,
  HostListener,
  Input,
  ViewChild,
} from "@angular/core";

import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

import { CoreConfigService } from "@core/services/config.service";
import { LayoutService } from "@core/services/layout.service";
import { HeaderComponent } from "../common/header/header.component";

@Component({
  selector: "vertical-layout",
  templateUrl: "./vertical-layout.component.html",
  styleUrls: ["./vertical-layout.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class VerticalLayoutComponent implements OnInit, OnDestroy {
  @Input("IsHidden") isHidden: boolean = false;
  @ViewChild(HeaderComponent) HeaderComponent: HeaderComponent;
  setNavLayout: string;
  themeLayout: string;
  setDefaultNavbar: string;
  setToggleNavbar: string;
  setToggleStatus: boolean;
  setVerticalNavbarEffect: string;
  setDeviceType: string;
  setHeaderColorTheme: string;
  setLeftHeaderColorTheme: string;
  setNavbarColorTheme: string;
  setActiveNavColorTheme: string;
  setHeaderHeight: number;
  setFooterHeight: number;
  setCollapsedLeftHeader: boolean;
  @Input() pageTitle: string;
  coreConfig: any;

  // Private
  private _unsubscribeAll: Subject<any>;

  /**
   * Constructor
   *
   * @param {CoreConfigService} _coreConfigService
   */

  // Lifecycle Hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */

  constructor(
    private layoutService: LayoutService,
    private _coreConfigService: CoreConfigService,
    private _elementRef: ElementRef,
  ) {
    this._unsubscribeAll = new Subject();
  }
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  ngOnInit() {
    this._coreConfigService.config
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(config => {
        this.coreConfig = config;
      });
    this.layoutService.checkWindowWidth(window.innerWidth);

    this.layoutService.navLayoutCast.subscribe(
      navlayout => (this.setNavLayout = navlayout),
    );

    this.layoutService.dfNavbarCast.subscribe(
      dfNavbar => (this.setDefaultNavbar = dfNavbar),
    );
    this.layoutService.toggleNavbarCast.subscribe(
      tNavbar => (this.setToggleNavbar = tNavbar),
    );
    this.layoutService.tStatusCast.subscribe(
      tStatus => (this.setToggleStatus = tStatus),
    );
    this.layoutService.nvEffectCast.subscribe(
      nvEffect => (this.setVerticalNavbarEffect = nvEffect),
    );
    this.layoutService.headerThemeCast.subscribe(
      headerTheme => (this.setHeaderColorTheme = headerTheme),
    );
    this.layoutService.leftHeaderThemeCast.subscribe(
      leftHeaderTheme => (this.setLeftHeaderColorTheme = leftHeaderTheme),
    );
    this.layoutService.navbarThemeCast.subscribe(
      navbarTheme => (this.setNavbarColorTheme = navbarTheme),
    );
    this.layoutService.activeNavThemeCast.subscribe(
      activeNavTheme => (this.setActiveNavColorTheme = activeNavTheme),
    );
    this.layoutService.themeLayoutCast.subscribe(
      themeLayout => (this.themeLayout = themeLayout),
    );
    this.layoutService.collapsedLeftHeaderCast.subscribe(
      collapsedLeftHeader =>
        (this.setCollapsedLeftHeader = collapsedLeftHeader),
    );
    this.layoutService.deviceTypeCast.subscribe(
      appDeviceType => (this.setDeviceType = appDeviceType),
    );
    this.setHeaderHeight = this.layoutService.headerHeight;
  }

  @HostListener("window:resize", ["$event"])
  onResize(event: any) {
    this.layoutService.getVerticalNavbarOnWindowResize(event.target.innerWidth);
  }
  changeTheToggleStatus() {
    this.layoutService.getToggleStatus();
  }
}

// @Component({
//   selector: 'vertical-layout',
//   templateUrl: './vertical-layout.component.html',
//   styleUrls: ['./vertical-layout.component.scss'],
//   encapsulation: ViewEncapsulation.None
// })
// export class VerticalLayoutComponent implements OnInit, OnDestroy {

//   ngOnInit(): void {
//     // Subscribe to config changes

//   }

//   /**
//    * On destroy
//    */

// }
