import { Injectable } from "@angular/core";
let StringMask = require("string-mask");
@Injectable({
  providedIn: "root",
})
export class HelpersService {
  floatTwoDigits(value) {
    return parseFloat(value).toFixed(2);
  }
  toTitleCase(value) {
    if(value != null){
      value = value.replace(/_/g, " ");
      return value.replace(/(?:^|\s)\w/g, function (match) {
        return match.toUpperCase();
      });
    }
  }
  formatNumberThousandSeparator(value: any) {
    let exchange = /\B(?=(\d{3})+(?!\d))/g;
    return value.toString().replace(exchange, ",");
  }

  phoneNumber(value: string) {
    let formatter = new StringMask("(000) 000-0000");
    let result = formatter.apply(value);
    return result;
  }
  SSN(value: string) {
    value = value.substring(value.length - 4);
    let formatter = new StringMask("xxx-xx-0000");
    let result = formatter.apply(value);
    return result;
  }
  BankNumbers(value: string) {
    value = value.substring(value.length - 4);
    let formatter = new StringMask("xxxxxxxx0000");
    let result = formatter.apply(value);
    return result;
  }
  poNumber(value: string, max: number) {
    return value.length < max ? this.poNumber("0" + value, max) : value;
  }
  isValid(object) {
    return typeof object != "undefined" && object != null;
  }
}
