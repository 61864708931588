<label>{{ name }}</label>
<ng-select
  appendTo="body"
  [ngModel]="controlDir.control.value"
  [items]="data"
  [bindLabel]="bindLabel"
  [bindValue]="bindValue"
  class="dllMultipleSelectore"
  [multiple]="multiple"
  [placeholder]="placeholder"
  [clearable]="clearable"
  (blur)="onTouched(); blur.emit()"
  [ngClass]="{
    'is-invalid': controlDir?.control?.touched && controlDir.control?.invalid
  }"
  (add)="onChangeSelector($event, 'add')"
  (remove)="onChangeSelector($event, 'remove')"
  [disabled]="disabeld"
>
</ng-select>
<div
  class="invalid-feedback"
  style="font-size: 10px !important"
>
  This field is required, please select a value.
</div>
