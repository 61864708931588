import { Component, OnInit, Input, HostListener } from "@angular/core";
import { LayoutService } from "@core/services/layout.service";
import { MenuService } from "app/menu/menu.service";
import { ReflectRoMenuToHeader } from "../../../../assets/js/menu.js";

@Component({
  selector: "app-left-panel",
  templateUrl: "./left-panel.component.html",
  styleUrls: ["./left-panel.component.scss"],
})
export class LeftPanelComponent implements OnInit {
  asidebarHeight: number;
  @Input() navLayout: string;
  @Input() defaultNavbar: string;
  @Input() toggleNavbar: string;
  @Input() toggleStatus: boolean;
  @Input() navbarEffect: string;
  @Input() deviceType: string;
  @Input() headerColorTheme: string;
  @Input() navbarColorTheme: string;
  @Input() activeNavColorTheme: string;
  title: any;
  menuList: any;
  selected: any;
  currentchildMenu: any;
  opened = -1;
  constructor(
    private layoutService: LayoutService,
    private _menuService: MenuService,
  ) {}

  isActive(item) {
    return this.selected === item;
  }
  onItemSelect(item) {
    this.selected = item;
  }

  @HostListener("window:resize", ["$event"])
  onResizeHeight(event: any) {
    this.asidebarHeight = window.innerHeight;
  }

  ngOnInit() {
    this.layoutService.setAsidebarHeightCast.subscribe(
      setSidebarHeight => (this.asidebarHeight = setSidebarHeight),
    );

    this.title = "Navigation";
    this.menuList = this._menuService.menu;
  }

  viewSubMenu(CurrentMenu) {
    if (CurrentMenu == this.currentchildMenu) {
      this.currentchildMenu = "";
    } else {
      this.currentchildMenu = CurrentMenu;
    }
  }
  ngAfterViewInit() {
    ReflectRoMenuToHeader();
  }
}
