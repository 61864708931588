<div class="row">
  <div class="col-lg-12">
    <div class="cui-apps-profile-wall-avatar custom-avatar-div">
      <div class="custom-cui-avatar custom-cui-avatar-50">
        <img
          id="profile_image"
          src="{{ imgSRC }}"
          alt="Profile picture."
          (click)="openedProfilePicture(imgSRC)"
          [style.cursor]="imgSRC == defualtImage ? 'unset' : 'pointer'"
        />
      </div>
    </div>
    <div
      class="cui-apps-profile-wall-content"
      [hidden]="disabeld"
    >
      <div class="mt-1">
        <input
          id="profile_photo_upload"
          accept="image/*"
          class="file_upload"
          type="file"
          (change)="uploadFile($event)"
        />
        <label
          class="cui-utils-link-underlined cui-utils-link-blue cui-label-link"
          for="profile_photo_upload"
          id="profile_photo_upload_text"
          >{{ title }}</label
        >
      </div>
      <div>
        <small class="text-muted">Profile 160 x 160 (min. 128 x 128)</small>
      </div>
    </div>
  </div>
</div>
<kendo-dialog
  title=""
  *ngIf="openedProfileImage"
  (close)="close()"
  [width]="900"
  [height]="600"
>
  <kendo-dialog-titlebar> </kendo-dialog-titlebar>
  <img
    id="profile_image"
    src="{{ imgSRC }}"
    alt="Profile picture."
    style="cursor: unset; height: 100%; width: 100%"
  />
</kendo-dialog>
