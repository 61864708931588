<div
  id="actionBar"
  class="d-flex align-items-end bg-white"
>
  <div class="container-fluid">
    <div class="d-flex w-100 actionBar-content align-items-end">
      <div class="flex-fill">
        <div class="sub-title pb-0">No Show Report</div>
      </div>
      <div class="btn-group">
        <div
          ngbDropdown
          [placement]="'bottom-right'"
        >
          <button
            ngbDropdownToggle
            id="dropdownSettings"
            class="btn-icon btn btn-info btn-round btn-sm btn-page-action"
            type="button"
            rippleEffect
          >
            <i
              class="fa fa-bars"
              aria-hidden="true"
              data-automation-id="dropdown_btn"
            ></i>
          </button>
          <div
            ngbDropdownMenu
            aria-labelledby="dropdownSettings"
          >
            <a
              ngbDropdownItem
              (click)="print()"
              data-automation-id="print_btn"
            >
              <span
                [data-feather]="'printer'"
                [class]="'mr-50'"
              ></span>
              Print
            </a>
            <a
              ngbDropdownItem
              href="javascript:void(0)"
              (click)="GetExcelData()"
            >
              <span
                [data-feather]="'download'"
                [class]="'mr-50'"
              ></span
              >Download Xlsx
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<br />

<div class="content-wrapper container-fluid">
  <div class="content-body">
    <div>
      <div class="card">
        <app-collection-filters
          (search)="getGridData()"
          [filters]="FiltersConfig"
        >
        </app-collection-filters>

        <div class="card-body">
          <kendo-grid
            #gridActiveRide
            [kendoGridBinding]="gridView"
            [sortable]="true"
            [pageSize]="pagesize"
            #dataGridVar
          >
            <ng-template
              kendoGridToolbarTemplate
              NgClass="p-0"
            >
              <button
                [hidden]="true"
                kendoGridExcelCommandtype="button"
                kendoGridExcelCommand
                icon="file-excel"
                #excel
              >
                Export to Excel
              </button>
              <div class="col-lg-12 toolbar-control">
                <div class="row">
                  <div class="col-md-6 col-12 pb-0">
                    <div
                      class="d-flex justify-content-between align-items-center"
                    >
                      <label class="d-flex align-items-center">
                        Show
                        <select
                          (change)="changeGridIndex()"
                          [(ngModel)]="gridIndex"
                          class="form-control mx-25 form-control-sm ng-pristine ng-valid ng-touched"
                        >
                          <option value="5">5</option>
                          <option value="10">10</option>
                          <option value="25">25</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                        </select>
                        entries
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <kendo-grid-spacer></kendo-grid-spacer>
            </ng-template>

            <kendo-grid-column
              [width]="50"
              field="id"
              [sortable]="false"
              title="#"
            >
              <ng-template
                kendoGridCellTemplate
                let-rowIndex="rowIndex+1"
              >
                {{ rowIndex }}
              </ng-template>
            </kendo-grid-column>

            <kendo-grid-column
              [sortable]="false"
              field="RideId"
              title="Ride #"
              [width]="110"
            >
            </kendo-grid-column>

            <kendo-grid-column
              [sortable]="false"
              field="DateOfService"
              title="Date of Service"
              [width]="150"
            >
              <ng-template
                kendoGridCellTemplate
                let-dataItem
              >
                {{ dataItem.DateOfService }}
              </ng-template>
            </kendo-grid-column>

            <kendo-grid-column
              [sortable]="false"
              field="WaypointName"
              title="Place Name"
              [width]="150"
            >
              <ng-template
                kendoGridCellTemplate
                let-dataItem
              >
                <div class="wrap-text">
                  {{ dataItem.WaypointName }}
                </div>
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-column
              [sortable]="false"
              field="FullName"
              title="Student Name"
              [width]="150"
            >
              <ng-template
                kendoGridCellTemplate
                let-dataItem
              >
                <a
                  class="btn btn-link pl-0 pr-0 d-block text-left p-0"
                  (click)="
                    openStudentProfile(
                      dataItem.StudentFirstName,
                      dataItem.StudentLastName,
                      dataItem.StudentId
                    )
                  "
                >
                  {{ dataItem.StudentFirstName }} {{ dataItem.StudentLastName }}
                </a>
              </ng-template>
            </kendo-grid-column>

            <kendo-grid-column
              [sortable]="false"
              field="Period"
              title="Period"
              [width]="100"
            >
            </kendo-grid-column>

            <kendo-grid-column
              [sortable]="false"
              field="NoShowTime"
              title="No-Show Time"
              [width]="150"
            >
              <ng-template
                kendoGridCellTemplate
                let-dataItem
              >
                {{ dataItem.NoShowTime }}
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-column
              [sortable]="false"
              field="CancelTime"
              title="Late Cancel Time"
              [width]="180"
            >
              <ng-template
                kendoGridCellTemplate
                let-dataItem
              >
                {{ dataItem.CancelTime }}
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-excel
              class="excel"
              fileName="No Show Report.xlsx"
            >
            </kendo-grid-excel>
          </kendo-grid>
          <kendo-dialog
            title="Attention "
            *ngIf="opendSearch"
            (close)="close('cancel')"
            [minWidth]="250"
            [width]="400"
          >
            <p style="text-align: center">{{ textValidation }}</p>

            <kendo-dialog-actions>
              <p style="text-align: center">
                <button
                  type="button"
                  (click)="close('no')"
                  rippleeffect=""
                  class="btn btn-info waves-effect waves-float waves-light"
                >
                  OK, Got It
                </button>
              </p>
            </kendo-dialog-actions>
          </kendo-dialog>
          <div class="col-lg-12 text-center Load-More-section">
            <button
              [disabled]=""
              class="btn btn-light btn-Load-More btn-sm"
              (click)="viewMore()"
            >
              load more
            </button>

            <div class="page-count ng-star-inserted">
              {{ pagesize >= gridView.length ? gridView.length : pagesize }}
              of {{ gridView.length }} record
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <kendo-dialog
    title="Profile of {{ fullName }} "
    id="student"
    *ngIf="opendProfile"
    (close)="close('cancel')"
    [width]="900"
    height="auto"
  >
    <kendo-tabstrip tabPosition="left">
      <kendo-tabstrip-tab
        title="Basic Information"
        [selected]="true"
      >
        <ng-template kendoTabContent>
          <h5>Basic Information</h5>
          <div class="row">
            <span class="col-xl-4">Name</span>
            <dd class="col-xl-8">{{ fullName }}</dd>
          </div>
          <div class="row">
            <span class="col-xl-4">Gender</span>

            <dd class="col-xl-8">{{ studentInfo.Sex }}</dd>
          </div>
          <div class="row">
            <span class="col-xl-4">Date of Birth</span>

            <dd class="col-xl-8">
              {{ studentInfo.DateOfBirth | date: "MM/dd/YYYY" }} ({{
                getAge(studentInfo.DateOfBirth)
              }}
              years old)
            </dd>
          </div>
          <div class="row">
            <span class="col-xl-4">School Grade</span>

            <dd class="col-xl-8">{{ studentInfo.SchoolGrade }}</dd>
          </div>
          <div class="row">
            <span class="col-xl-4">Population Types</span>

            <div style="margin-left: 14px">
              <dd *ngFor="let item of studentInfo.PopulationTypes">
                {{ item.Name }}
              </dd>
            </div>
          </div>
          <div
            class="row"
            *ngIf="studentInfo.SpecialEdCategories.length != 0"
          >
            <span class="col-xl-4">Special Ed Categories</span>
            <div style="margin-left: 14px">
              <dd *ngFor="let item of studentInfo.SpecialEdCategories">
                {{ item.Name }}
              </dd>
            </div>
          </div>
          <div
            class="col-lg-12"
            *ngIf="
              studentInfo.PickupInstructionsNote != '' ||
              studentInfo.DropOffInstructionsNote != '' ||
              studentInfo.SpecialInstructionsNote != '' ||
              studentInfo.OnboardInstructionsNote != ''
            "
          >
            <p>Instructions</p>
          </div>
          <div
            class="col-auto"
            *ngIf="studentInfo.PickupInstructionsNote != ''"
          >
            <span class="col-auto">Pick-up</span>

            <dd class="col-auto">{{ studentInfo.PickupInstructionsNote }}</dd>
          </div>
          <div
            class="col-auto"
            *ngIf="studentInfo.DropOffInstructionsNote != ''"
          >
            <span class="col-auto">Drop-off</span>

            <dd class="col-auto">{{ studentInfo.DropOffInstructionsNote }}</dd>
          </div>
          <div
            class="col-auto"
            *ngIf="studentInfo.OnboardInstructionsNote != ''"
          >
            <span class="col-auto">Onboard</span>

            <dd class="col-auto">{{ studentInfo.OnboardInstructionsNote }}</dd>
          </div>
          <div
            class="col-auto"
            *ngIf="studentInfo.SpecialInstructionsNote != ''"
          >
            <span class="col-auto">Special</span>

            <dd class="col-auto">{{ studentInfo.SpecialInstructionsNote }}</dd>
          </div>
        </ng-template>
      </kendo-tabstrip-tab>
      <kendo-tabstrip-tab title="Service Details">
        <ng-template kendoTabContent>
          <h5 class="mb-1">Service Details</h5>
          <div
            *ngIf="
              serviceDetails.ServiceStartDate != null &&
              serviceDetails.ServiceEndDate != null
            "
            class="row"
          >
            <span class="col-xl-4">Service Dates</span>

            <dd class="col-xl-8">
              {{ serviceDetails.ServiceStartDate | date: "MM/dd/YYYY" }} -
              {{ serviceDetails.ServiceEndDate | date: "MM/dd/YYYY" }}
            </dd>
          </div>
          <div
            *ngIf="
              serviceDetails.ServiceStartDate == null &&
              serviceDetails.ServiceEndDate == null
            "
            class="row"
          >
            <span class="col-xl-4">Service Dates</span>

            <dd class="col-xl-8">Invalid date - Invalid date</dd>
          </div>
          <div class="row">
            <span class="col-xl-4">PO #</span>

            <dd class="col-xl-8">
              {{ serviceDetails.ClientContractPurchaseOrderNo }}
            </dd>
          </div>
          <div class="row">
            <span class="col-xl-4">Solo Rider</span>

            <dd
              *ngIf="serviceDetails.IsSoloRider"
              class="col-xl-4"
            >
              Yes
            </dd>
            <dd
              *ngIf="serviceDetails.IsSoloRider == false"
              class="col-xl-4"
            >
              No
            </dd>
          </div>
          <div class="row">
            <span class="col-sm-5">Wheelchair Vehicle Required</span>

            <dd
              style="margin-left: -56px"
              *ngIf="serviceDetails.NeedsWheelchairVehicle"
              class="col-xl-4"
            >
              Yes
            </dd>
            <dd
              style="margin-left: -56px"
              *ngIf="serviceDetails.NeedsWheelchairVehicle == false"
              class="col-xl-4"
            >
              No
            </dd>
          </div>
          <div class="row">
            <span class="col-xl-4">Client Monitor Required</span>

            <dd
              *ngIf="serviceDetails.NeedsClientProvidedAttendant"
              class="col-xl-4"
            >
              Yes
            </dd>
            <dd
              *ngIf="serviceDetails.NeedsClientProvidedAttendant == false"
              class="col-xl-4"
            >
              No
            </dd>
          </div>
          <div class="row">
            <span class="col-xl-4">ADROIT Monitor Required</span>

            <dd
              *ngIf="serviceDetails.NeedsPlatformProvidedAttendant"
              class="col-xl-4"
            >
              Yes
            </dd>
            <dd
              *ngIf="serviceDetails.NeedsPlatformProvidedAttendant == false"
              class="col-xl-4"
            >
              No
            </dd>
          </div>
          <div
            class="row"
            *ngIf="Equipment.length != 0"
          >
            <span class="col-xl-4">Equipment</span>

            <dd
              class="col-xl-4"
              *ngFor="let item of Equipment"
            >
              {{ item.Name }}
            </dd>
          </div>
        </ng-template>
      </kendo-tabstrip-tab>
      <kendo-tabstrip-tab title="Home Address(es)">
        <ng-template kendoTabContent>
          <h5 style="margin-left: 19px">Home Address(es)</h5>
          <div class="card-body invoice-padding py-0">
            <div class="row">
              <div class="col-lg-12">
                <kendo-grid
                  #homeAddressGrid
                  [kendoGridBinding]="studentAdress"
                  [pageSize]="innepagesize"
                  [pageable]="true"
                  [skip]="skip"
                >
                  <ng-template kendoGridNoRecordsTemplate>
                    No addresses added yet.
                  </ng-template>
                  <kendo-grid-column
                    [width]="50"
                    field="Index"
                    [sortable]="false"
                    title="#"
                  >
                    <ng-template
                      kendoGridCellTemplate
                      let-rowIndex="rowIndex+1"
                    >
                      {{ rowIndex }}
                    </ng-template>
                  </kendo-grid-column>

                  <kendo-grid-column
                    field="Name"
                    title="Name"
                    [sortable]="false"
                  >
                    <ng-template
                      kendoGridCellTemplate
                      let-dataItem
                      let-rowIndex="rowIndex"
                    >
                      {{ dataItem.Name }}
                    </ng-template>
                  </kendo-grid-column>

                  <kendo-grid-column
                    field="Address"
                    title="Address"
                    [sortable]="false"
                  >
                    <ng-template
                      kendoGridCellTemplate
                      let-dataItem
                      let-rowIndex="rowIndex"
                    >
                      {{ dataItem.Location.Street1 }}
                      {{ dataItem.Location.City }} ,
                      {{ dataItem.Location.State }}
                      {{ dataItem.Location.Zipcode }}
                    </ng-template>
                  </kendo-grid-column>

                  <ng-template
                    kendoPagerTemplate
                    *ngIf="studentAdress.length >= 0"
                  >
                    <kendo-grid-spacer></kendo-grid-spacer>
                    <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
                    <kendo-pager-numeric-buttons
                      [buttonCount]="3"
                    ></kendo-pager-numeric-buttons>
                    <kendo-pager-next-buttons></kendo-pager-next-buttons>
                    <kendo-pager-info></kendo-pager-info>
                  </ng-template>
                </kendo-grid>
              </div>
            </div>
          </div>
        </ng-template>
      </kendo-tabstrip-tab>
      <kendo-tabstrip-tab title="Place(s) Attended">
        <ng-template kendoTabContent>
          <h5 style="margin-left: 19px">Place(s) Attended</h5>
          <div class="card-body invoice-padding py-0">
            <div class="row">
              <div class="col-lg-12">
                <kendo-grid
                  #placesGrid
                  [kendoGridBinding]="placesAttended"
                  [pageSize]="innepagesize"
                  [pageable]="true"
                  [skip]="skip"
                >
                  <ng-template kendoGridNoRecordsTemplate>
                    No places added yet.
                  </ng-template>

                  <kendo-grid-column
                    [width]="50"
                    field="Index"
                    [sortable]="false"
                    title="#"
                  >
                    <ng-template
                      kendoGridCellTemplate
                      let-rowIndex="rowIndex+1"
                    >
                      {{ rowIndex }}
                    </ng-template>
                  </kendo-grid-column>
                  <kendo-grid-column
                    field="Name"
                    title="Name"
                    [sortable]="false"
                  >
                    <ng-template
                      kendoGridCellTemplate
                      let-dataItem
                      let-rowIndex="rowIndex"
                    >
                      {{ dataItem.Name }}
                    </ng-template>
                  </kendo-grid-column>

                  <kendo-grid-column
                    field="Address"
                    title="Address"
                    [sortable]="false"
                  >
                    <ng-template
                      kendoGridCellTemplate
                      let-dataItem
                      let-rowIndex="rowIndex"
                    >
                      <span class="text-muted">
                        {{ dataItem.Location.Street1 }}
                        {{ dataItem.Location.City }} ,
                        {{ dataItem.Location.State }}
                        {{ dataItem.Location.Zipcode }}
                      </span>
                    </ng-template>
                  </kendo-grid-column>

                  <ng-template
                    kendoPagerTemplate
                    *ngIf="placesAttended.length >= 0"
                  >
                    <kendo-grid-spacer></kendo-grid-spacer>
                    <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
                    <kendo-pager-numeric-buttons
                      [buttonCount]="3"
                    ></kendo-pager-numeric-buttons>
                    <kendo-pager-next-buttons></kendo-pager-next-buttons>
                    <kendo-pager-info></kendo-pager-info>
                  </ng-template>
                </kendo-grid>
              </div>
            </div>
          </div>
        </ng-template>
      </kendo-tabstrip-tab>
      <kendo-tabstrip-tab
        title="Emergency Contact(s)"
        class="col-lg-3"
      >
        <ng-template kendoTabContent>
          <h5 style="margin-left: 19px">Emergency Contact(s)</h5>
          <div class="card-body invoice-padding py-0">
            <div class="row">
              <div class="col-lg-12">
                <kendo-grid
                  #emergencyGrid
                  [kendoGridBinding]="EmergencyContacts"
                  [pageSize]="innepagesize"
                  [pageable]="true"
                  [skip]="skip"
                >
                  <ng-template kendoGridNoRecordsTemplate>
                    No contacts added yet.
                  </ng-template>

                  <kendo-grid-column
                    field="Name"
                    title="Name"
                    [sortable]="false"
                  >
                    <ng-template
                      kendoGridCellTemplate
                      let-dataItem
                      let-rowIndex="rowIndex"
                    >
                      {{ dataItem.FirstName + " " + dataItem.LastName }}
                    </ng-template>
                  </kendo-grid-column>

                  <kendo-grid-column
                    field="Relationship"
                    title="Relationship"
                    [sortable]="false"
                  >
                    <ng-template
                      kendoGridCellTemplate
                      let-dataItem
                      let-rowIndex="rowIndex"
                    >
                      {{ dataItem.RelationshipToEntity }}
                    </ng-template>
                  </kendo-grid-column>
                  <kendo-grid-column
                    field="PhoneNumber"
                    title="Reach At"
                    [sortable]="false"
                  >
                    <ng-template
                      kendoGridCellTemplate
                      let-dataItem
                      let-rowIndex="rowIndex"
                    >
                      <div
                        *ngIf="
                          dataItem.PhoneExtention != '' &&
                          dataItem.PhoneExtention != null
                        "
                      >
                        {{ dataItem.PhoneNumber }} ext.{{
                          dataItem.PhoneExtention
                        }}
                      </div>
                      <div
                        *ngIf="
                          dataItem.PhoneExtention == '' ||
                          dataItem.PhoneExtention == null
                        "
                      >
                        {{ dataItem.PhoneNumber }}
                      </div>
                      <div *ngIf="dataItem.Email != ''">
                        {{ dataItem.Email }}
                      </div>
                    </ng-template>
                  </kendo-grid-column>

                  <ng-template
                    kendoPagerTemplate
                    *ngIf="EmergencyContacts.length >= 0"
                  >
                    <kendo-grid-spacer></kendo-grid-spacer>
                    <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
                    <kendo-pager-numeric-buttons
                      [buttonCount]="3"
                    ></kendo-pager-numeric-buttons>
                    <kendo-pager-next-buttons></kendo-pager-next-buttons>
                    <kendo-pager-info></kendo-pager-info>
                  </ng-template>
                </kendo-grid>
              </div>
            </div>
          </div>
        </ng-template>
      </kendo-tabstrip-tab>
    </kendo-tabstrip>
    <kendo-dialog-actions>
      <p style="text-align: right; height: 20px">
        <button
          type="button"
          (click)="close('no')"
          rippleeffect=""
          style="border-color: #1877f2; margin-bottom: 20px; color: #1877f2"
          class="btn btn-close waves-effect waves-float waves-light"
        >
          Close
        </button>
      </p>
    </kendo-dialog-actions>
  </kendo-dialog>
</div>
