<!-- Basic Select with Search -->
<label>{{ name }}</label>
<kendo-datepicker
  #FromDate
  [placeholder]="placeholder"
  (focus)="FromDate.toggle()"
  [format]="format"
  (valueChange)="onChange($event)"
  (blur)="onTouched(); blur.emit()"
  [ngClass]="{
    'is-invalid': controlDir?.control?.touched && controlDir.control?.invalid
  }"
  [(value)]="initialValue"
  [disabledDates]="disabledDates"
  [disabled]="disabeld"
>
  <ng-template
    kendoCalendarNavigationItemTemplate
    let-title
    let-date="date"
    let-view="activeView"
  >
    <span
      onMouseOver="this.style.color='#1877f2'"
      onMouseOut="this.style.color='black'"
      style="color: black"
    >
      {{ view === "month" && date.getMonth() === 0 ? "Jan" : title }}
    </span>
  </ng-template></kendo-datepicker
>
<!--/ Basic Select with Search -->
<div
  class="invalid-feedback"
  style="font-size: 10px !important"
>
  This field is required, please select a value.
</div>
