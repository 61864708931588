import {
  AfterContentChecked,
  Component,
  EventEmitter,
  Output,
  ViewChild,
} from "@angular/core";
import { NgbTypeahead } from "@ng-bootstrap/ng-bootstrap";
import { Observable, Subject, merge, OperatorFunction } from "rxjs";
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
} from "rxjs/operators";
const states = [
  "Per the father’s request.",
  "Per the guardian’s request.",
  "Per the mother’s request.",
  "Per the school district’s request.",
  "Per the teacher’s request.",
];
@Component({
  selector: "app-typea-head",
  templateUrl: "./typea-head.component.html",
  styleUrls: ["./typea-head.component.scss"],
})
export class TypeaHeadComponent implements AfterContentChecked {
  @Output() onOpen = new EventEmitter<string>();

  ngAfterContentChecked(): void {
    this.emitEvent();
  }
  public model: string = "";
  emitEvent() {
    this.onOpen.emit();
  }
  @ViewChild("instance", { static: true }) instance: NgbTypeahead;
  focus$ = new Subject<string>();
  click$ = new Subject<string>();

  search: OperatorFunction<string, readonly string[]> = (
    text$: Observable<string>,
  ) => {
    const debouncedText$ = text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
    );
    const clicksWithClosedPopup$ = this.click$.pipe(
      filter(() => !this.instance.isPopupOpen()),
    );
    const inputFocus$ = this.focus$;

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      map(term =>
        (term === ""
          ? states
          : states.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1)
        ).slice(0, 10),
      ),
    );
  };
}
