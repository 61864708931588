import { Component, EventEmitter, Input, Output } from "@angular/core";
import { IFilters } from "../interfaces/controls/IFiltersConfig";

@Component({
  selector: "app-clients",
  templateUrl: "./clients.component.html",
})
export class ClientsComponent {
  @Input("filterData") Config: IFilters;
  @Output() changeClient = new EventEmitter<string>();

  Onchange() {
    this.changeClient.emit("complete");
  }
}
