import { Component, Input, OnInit, ViewEncapsulation } from "@angular/core";
import { FormGroup, FormGroupDirective } from "@angular/forms";
import { IFiltersConfig } from "app/controls/component/filters/interfaces/controls/IFiltersConfig";
import moment from "moment";
import { TransportationStudentsService } from "../../services/transportation.service";
import { PermissionsList } from "app/enums/Permissions/PermissionList";
import { AuthenticationService } from "app/auth/service/authentication.service";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-schedule-field",
  templateUrl: "./schedule-field.component.html",
  styleUrls: ["./schedule-field.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class ScheduleFieldComponent implements OnInit {
  @Input() Config: IFiltersConfig;
  @Input() Item: any;
  @Input() AddressGridData: any[];
  @Input() PlaceGridData: any[];
  @Input() StudentScheduleDetails: any[];

  @Input() formGroupName: string;
  @Input() formchild: FormGroup;
  public PermessionValue: string;
  public disabled: boolean;

  constructor(
    private rootFormGroup: FormGroupDirective,
    private _transportationStudentsService: TransportationStudentsService,
    private _authenticationService: AuthenticationService,
    private route: ActivatedRoute,
  ) {}

  validSlector2: boolean = false;
  validSlectorDropOff1: boolean = false;
  validSlectorDropOff2: boolean = false;
  validSlectorPickup1: boolean = false;
  validSlectorPickup2: boolean = false;

  ngOnInit(): void {
    if (this.route.snapshot.paramMap.get("id")) {
      this.PermessionValue = PermissionsList.ClientStudentsEdit.toString();
      this.disabled = this._authenticationService.checkaccess(
        PermissionsList[PermissionsList.ClientStudentsEdit],
      );
      this.formchild.get("pickup2").disable();
      this.formchild.get("dropOff2").disable();
      this.formchild.get("pickup1").disable();
      this.formchild.get("dropOff1").disable();
    } else {
      this.PermessionValue = PermissionsList.ClientStudentsCreate.toString();
      this.disabled = this._authenticationService.checkaccess(
        PermissionsList[PermissionsList.ClientStudentsCreate],
      );
      this.formchild.get("pickup2").enable();
      this.formchild.get("dropOff2").enable();
      this.formchild.get("pickup1").enable();
      this.formchild.get("dropOff1").enable();
    }

    let that = this;
    this.Config = {
      isFiltersEnable: false,
      Filters: [
        {
          filterValues: [],
          filterClear: true,
          filterVisability: true,
          filterPlaceholder: "Select address.",
        },
        {
          filterValues: [],
          filterClear: true,
          filterVisability: true,
          filterPlaceholder: "Select a place.",
        },
        {
          filterValues: [],
          filterClear: true,
          filterVisability: true,
          filterPlaceholder: "Select a place.",
        },
        {
          filterValues: [],
          filterClear: true,
          filterVisability: true,
          filterPlaceholder: "Select address.",
        },
      ],
    };

    if (that.addresses?.length > 0) {
      this.Config.Filters[0].filterValues = that.addresses.map(a => ({
        id: a.Id,
        name: a.Name,
      })); //pickup1 - Outbound
      that.prepareAddresses();

      if (that.StudentScheduleDetails?.length > 0) {
        that.prepareStudentScheduleDetailsAddressses();
      } else if (that.StudentScheduleDetails != null) {
        that.Item.Checked1 = false;
        that.Item.Checked2 = false;
      }
    }

    if (that.places?.length > 0) {
      that.Config.Filters[1].filterValues = that.places.map(a => ({
        id: a.Id,
        name: a.Name,
      })); //dropOff1
      if (that.places.map(a => ({ id: a.Id, name: a.Name })).length == 1) {
        that.Config.Filters[1].filterValue = that.places.map(a => ({
          id: a.Id,
          name: a.Name,
        }))[0].id;
      }

      that.Config.Filters[2].filterValues = that.places.map(a => ({
        id: a.Id,
        name: a.Name,
      })); //pickup2
      if (that.places.map(a => ({ id: a.Id, name: a.Name })).length == 1) {
        that.Config.Filters[2].filterValue = that.places.map(a => ({
          id: a.Id,
          name: a.Name,
        }))[0].id;
      }

      if (that.StudentScheduleDetails?.length > 0) {
        that.prepareStudentScheduleDetailsPlaces();
      } else if (that.StudentScheduleDetails != null) {
        that.Item.Checked2 = false;
        that.Item.Checked1 = false;
      }
    }
  }

  get places() {
    let that = this;
    let data = JSON.parse(sessionStorage.getItem("Places"));
    if (data && data.length > 0) {
      return data;
    }
    return that.PlaceGridData;
  }

  get addresses() {
    let that = this;
    let data = JSON.parse(sessionStorage.getItem("Addresses"));
    if (data && data.length > 0) {
      return data;
    }
    return that.AddressGridData;
  }

  get dropOff2() {
    return this.formchild.get("dropOff2");
  }

  get dropOff1() {
    return this.formchild.get("dropOff1");
  }

  get checkP1() {
    return this.formchild.get("checkP1");
  }

  get checkP2() {
    return this.formchild.get("checkP2");
  }

  get pickup2() {
    return this.formchild.get("pickup2");
  }

  get pickup1() {
    return this.formchild.get("pickup1");
  }

  get time1() {
    return this.formchild.get("time1");
  }

  get time2() {
    return this.formchild.get("time2");
  }

  check1() {
    if (this.Item.Checked1) {
      if (this.route.snapshot.paramMap.get("id")) {
        if (
          this._authenticationService.checkaccess(
            PermissionsList[PermissionsList.ClientStudentsEdit],
          )
        ) {
          this.formchild.get("pickup1").enable();
          this.formchild.get("dropOff1").enable();
        } else {
          this.formchild.get("pickup1").disable();
          this.formchild.get("dropOff1").disable();
        }
      } else {
        this.formchild.get("pickup1").enable();
        this.formchild.get("dropOff1").enable();
      }
    } else {
      this.formchild.get("pickup1").disable();
      this.formchild.get("dropOff1").disable();
      this.validSlectorDropOff1 = false;
      this.validSlectorPickup1 = false;
    }
  }

  check2() {
    if (this.Item.Checked2) {
      if (this.route.snapshot.paramMap.get("id")) {
        if (
          this._authenticationService.checkaccess(
            PermissionsList[PermissionsList.ClientStudentsEdit],
          )
        ) {
          this.formchild.get("pickup2").enable();
          this.formchild.get("dropOff2").enable();
        } else {
          this.formchild.get("pickup2").disable();
          this.formchild.get("dropOff2").disable();
        }
      } else {
        this.formchild.get("pickup2").enable();
        this.formchild.get("dropOff2").enable();
      }
    } else {
      this.formchild.get("pickup2").disable();
      this.formchild.get("dropOff2").disable();
      this.validSlectorDropOff2 = false;
      this.validSlectorPickup2 = false;
    }
  }

  SelectedDropOff1() {
    if (
      this.Config.Filters[1].filterValue == null &&
      this.formchild.get("dropOff1").enabled
    ) {
      this.validSlectorDropOff1 = true;
    } else {
      this.validSlectorDropOff1 = false;
    }
  }

  SelectedDropOff2() {
    if (
      this.Config.Filters[2].filterValue == null &&
      this.formchild.get("dropOff2").enabled
    ) {
      this.validSlectorDropOff2 = true;
    } else {
      this.validSlectorDropOff2 = false;
    }
  }

  SelectedPickup1() {
    if (
      this.Config.Filters[0].filterValue == null &&
      this.formchild.get("pickup1").enabled
    ) {
      this.validSlectorPickup1 = true;
    } else {
      this.validSlectorPickup1 = false;
    }
  }

  SelectedPickup2() {
    if (
      this.Config.Filters[3].filterValue == null &&
      this.formchild.get("pickup2").enabled
    ) {
      this.validSlectorPickup2 = true;
    } else {
      this.validSlectorPickup2 = false;
    }
  }
  prepareStudentScheduleDetailsAddressses() {
    let that = this;
    let outboundData = that.StudentScheduleDetails.filter(
      a => a.Type == "Outbound",
    )[0];
    let returnData = that.StudentScheduleDetails.filter(
      a => a.Type == "Return",
    )[0];

    if (outboundData) {
      this.Config.Filters[0].filterValue =
        this.Config.Filters[0].filterValues.filter(
          a => a.id == outboundData.PickUpStudentPlaceId,
        )[0].id;
      let data = that.PlaceGridData.filter(
        a => a.Id == outboundData.DropOffStudentPlaceId,
      )[0];
      if (data?.PlaceSchedule) {
        that.formchild
          .get("time1")
          .setValue(
            moment(data.PlaceSchedule.DefaultStartTime).format("hh:mm A"),
          );
        that.formchild.get("time1").markAllAsTouched();
      }
      that.Item.Checked1 = true;
    } else {
      that.Item.Checked1 = false;
    }

    if (returnData) {
      this.Config.Filters[3].filterValue =
        this.Config.Filters[3].filterValues.filter(
          a => a.id == returnData.DropOffStudentPlaceId,
        )[0].id;
      that.Item.Checked2 = true;
    } else {
      that.Item.Checked2 = false;
    }
  }
  prepareStudentScheduleDetailsPlaces() {
    let that = this;
    let outboundData = that.StudentScheduleDetails.filter(
      a => a.Type == "Outbound",
    )[0];
    let returnData = that.StudentScheduleDetails.filter(
      a => a.Type == "Return",
    )[0];
    if (outboundData) {
      that.Config.Filters[1].filterValue =
        that.Config.Filters[1].filterValues.filter(
          a => a.id == outboundData.DropOffStudentPlaceId,
        )[0].id;
      that.Item.Checked1 = true;
    } else {
      that.Item.Checked1 = false;
    }
    if (returnData) {
      that.Config.Filters[2].filterValue =
        that.Config.Filters[2].filterValues.filter(
          a => a.id == returnData.PickUpStudentPlaceId,
        )[0].id;

      let data = that.places.filter(
        a => a.Id == outboundData.DropOffStudentPlaceId,
      )[0];
      if (data?.PlaceSchedule) {
        that.formchild
          .get("time2")
          .setValue(
            moment(data.PlaceSchedule.DefaultReturnTime).format("hh:mm A"),
          );
        that.formchild.get("time2").markAllAsTouched();
      }
      that.Item.Checked2 = true;
    } else {
      that.Item.Checked2 = false;
    }
  }
  prepareAddresses() {
    let that = this;
    if (that.addresses.map(a => ({ id: a.Id, name: a.Name })).length == 1) {
      this.Config.Filters[0].filterValue = that.addresses.map(a => ({
        id: a.Id,
        name: a.Name,
      }))[0].id;
    }

    this.Config.Filters[3].filterValues = that.addresses.map(a => ({
      id: a.Id,
      name: a.Name,
    })); //dropOff2 - Return
    if (that.addresses.map(a => ({ id: a.Id, name: a.Name })).length == 1) {
      this.Config.Filters[3].filterValue = that.addresses.map(a => ({
        id: a.Id,
        name: a.Name,
      }))[0].id;
    }
  }
}
