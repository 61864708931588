import { Component, Input } from "@angular/core";
import { IFilters } from "../interfaces/controls/IFiltersConfig";

@Component({
  selector: "app-container-blue-print",
  templateUrl: "./container-blue-print.component.html",
})
export class ContainerBluePrintComponent {
  @Input("filterData") Config: IFilters;
}
