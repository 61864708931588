<div
  *ngIf="Config.filterVisability"
  class="form-group"
>
  <!-- Basic Select with Search -->
  <kendo-datepicker
    #FromDate
    [(value)]="Config.filterValue"
    [placeholder]="Config.filterPlaceholder"
    (focus)="FromDate.toggle()"
    [format]="format"
    (valueChange)="onchange($event)"
  >
    <ng-template
      kendoCalendarNavigationItemTemplate
      let-title
      let-date="date"
      let-view="activeView"
    >
      <span
        onMouseOver="this.style.color='#1877f2'"
        onMouseOut="this.style.color='black'"
        style="color: black"
      >
        {{ view === "month" && date.getMonth() === 0 ? "Jan" : title }}
      </span>
    </ng-template>
  </kendo-datepicker>
  <!--/ Basic Select with Search -->
</div>
