import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { AuthenticationService } from "app/auth/service";
import { ToastrService } from "ngx-toastr";
import { User } from "app/auth/models";
import { State } from "@progress/kendo-data-query";
import { GurdiansAddNewAddressComponent } from "./GuardiansAddNewHomeAddress/gurdians-add-new-address.component";
import { ClientGuardiansService } from "../services/client-guardians.service";
import { ActivatedRoute } from "@angular/router";
@Component({
  selector: "app-address-gurdians-section",
  templateUrl: "./address-gurdians-section.component.html",
  styleUrls: ["./address-gurdians-section.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class AddressGurdiansSectionComponent implements OnInit {
  public _currentUser: User;
  opened = false;
  public accId: number;
  @ViewChild("studentaddHomeAddressComponent")
  addNewGuardianComponent: GurdiansAddNewAddressComponent;
  @Input() gridData: any = [];
  state: State = {
    skip: 0,
    take: 25,
    group: [],
    filter: { filters: [], logic: "or" },
    sort: [
      {
        field: "name",
        dir: "asc",
      },
    ],
  };
  @Output() deleted = new EventEmitter<boolean>();

  /**
   * Constructor
   * * @param {AuthenticationService} _authenticationService
   * * @param {HelpersService} _helpersService
   * * @param {ClientGuardiansService} _clientGurdiansService
   * * @param {NgbModal} _modalService
   * * @param {ToastrService} _toastrService
   * * @param {FormBuilder} _formBuilder
   * * @param {AppComponent} _appcomponent
   */
  constructor(
    private _authenticationService: AuthenticationService,
    private _toastrService: ToastrService,
    private _clientGuardiansService: ClientGuardiansService,
    private route: ActivatedRoute,
  ) {
    this._authenticationService.currentUser.subscribe(
      x => (this._currentUser = x),
    );
  }
  openDeletePopup: boolean = false;
  ngOnInit(): void {
    let id = parseInt(this.route.snapshot.paramMap.get("id")!);
    this.accId = id;
  }
  reloadData(dataItem: any) {
    //edit
    if (
      this.gridData.filter(
        x => x.Location.LocationId == dataItem.Location.LocationId,
      ).length > 0
    ) {
      let Index = this.gridData.findIndex(
        x => x.Location.LocationId == dataItem.Location.LocationId,
      );
      this.gridData.splice(Index, 1);
      this.gridData.push(dataItem);
    } else {
      //add
      this.gridData.push(dataItem);
    }
  }

  modalOpenMD(title, dataItem: any = null, rowIndex: any = 0) {
    this.opened = true;
    this.addNewGuardianComponent.data = dataItem;
    this.addNewGuardianComponent.open(title);
  }
  public locationId: number;
  openDeleteAddress(locationId) {
    this.locationId = locationId;
    this.openDeletePopup = true;
  }
  close(status: string): void {
    console.log(`Dialog result: ${status}`);
    this.openDeletePopup = false;
  }
  removeFromStudent() {
    this._clientGuardiansService
      .deleteAdress(this.accId, this.locationId)
      .subscribe(
        Response => {
          this.openDeletePopup = false;
          this.deleted.emit(true);
        },
        error => {
          if (error.status == 401) {
            this._toastrService.error("UnAuthorized.", "");
            window.location.href = "/pages/authentication/login";
          } else {
            this._toastrService.error(error.error.Message, "");
          }
        },
      );
  }
}
