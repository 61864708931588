import { Component, Input } from "@angular/core";
import { IFilters } from "../interfaces/controls/IFiltersConfig";

@Component({
  selector: "app-search-by-text",
  templateUrl: "./search-by-text.component.html",
})
export class SearchByTextComponent {
  @Input("filterData") Config: IFilters;
}
