import { Component, Input } from "@angular/core";
import { IFilters } from "../interfaces/controls/IFiltersConfig";

@Component({
  selector: "app-contractors",
  templateUrl: "./contractors.component.html",
})
export class ContractorsComponent {
  @Input("filterData") Config: IFilters;
}
