import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { YearComponent } from "./year/year.component";
import { MonthlyPeriodsComponent } from "./monthly-periods/monthly-periods.component";
import { StateComponent } from "./state/state.component";
import { ContractorsComponent } from "./contractors/contractors.component";
import { FiscalPeriodComponent } from "./fiscal-period/fiscal-period.component";
import { ClientsComponent } from "./clients/clients.component";
import { FromDatePickerComponent } from "./from-date-picker/from-date-picker.component";
import { ToDatePickerComponent } from "./to-date-picker/to-date-picker.component";
import { SearchByNumberComponent } from "./search-by-number/search-by-number.component";
import { DatePeriodsComponent } from "./date-periods/date-periods.component";
import { StatuesComponent } from "./statues/statues.component";
import { ServiceProviderComponent } from "./service-provider/service-provider.component";
import { UsersTypesComponent } from "./users-types/users-types.component";
import { PassengersComponent } from "./passengers/passengers.component";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { NgSelectModule } from "@ng-select/ng-select";
import { Ng2FlatpickrModule } from "ng2-flatpickr";
import { CoreCommonModule } from "@core/common.module";
import { WeeklyPeriodsComponent } from "./weekly-periods/weekly-periods.component";
import { CollectionFiltersComponent } from "./collection-filters/collection-filter.component";
import { PlacesComponent } from "./places/places.component";
import { PeriodsComponent } from "./periods/periods.component";
import { RideTypesComponent } from "./ride-types/ride-types.component";
import { BluePrintComponent } from "./blue-print/blue-print.component";
import { ContainerBluePrintComponent } from "./container-blue-print/container-blue-print.component";
import { DatePickerModule } from "@progress/kendo-angular-dateinputs";
import { GurdiansStatusComponent } from "./gurdians-status/gurdians-status.component";
import { NoShowReportStatuesComponent } from "./no-show-statuse/no-show-statuse.component";
import { GurdiansAppUsersFilterComponent } from "./gurdians-app-users/gurdians-app-users.component";
import { GradeComponent } from "./grade/grade.component";
import { ConsecutivNoShowReportStatuesComponent } from "./Consecutiv-no-show-statuse/Consecutiv-no-show-statuse.component";
import { TypeComponent } from "./types/type.component";
import { SearchByTextComponent } from "./search-by-text/search-by-text.component";
import { SearchByRideComponent } from "./search-by-ride/search-by-ride.component";

@NgModule({
  declarations: [
    CollectionFiltersComponent,
    YearComponent,
    MonthlyPeriodsComponent,
    StateComponent,
    ContractorsComponent,
    FiscalPeriodComponent,
    ClientsComponent,
    FromDatePickerComponent,
    ToDatePickerComponent,
    SearchByNumberComponent,
    DatePeriodsComponent,
    StatuesComponent,
    ServiceProviderComponent,
    UsersTypesComponent,
    PassengersComponent,
    WeeklyPeriodsComponent,
    PlacesComponent,
    PeriodsComponent,
    RideTypesComponent,
    BluePrintComponent,
    ContainerBluePrintComponent,
    GurdiansStatusComponent,
    NoShowReportStatuesComponent,
    GurdiansAppUsersFilterComponent,
    GradeComponent,
    ConsecutivNoShowReportStatuesComponent,
    TypeComponent,
    SearchByTextComponent,
    SearchByRideComponent,
  ],
  imports: [
    CoreCommonModule,
    CommonModule,
    NgbModule,
    NgSelectModule,
    Ng2FlatpickrModule,
    DatePickerModule,
  ],
  exports: [CollectionFiltersComponent],
})
export class FiltersModule {}
