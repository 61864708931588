export class AddEditStudentDataModel {
  Id: number;
  ClientId: string;
  FirstName: string;
  MiddleName: string;
  LastName: string;
  NickName: string;
  DateOfBirth: string;
  Gender: string;
  HomeLanguage: string;
  SchoolGradeId: string;
  NeedsWheelchairVehicle: boolean;
  RideNeeds: any = [];
  PopulationTypes: any = [];
  SpecialEdCategories: any = [];
  HealthConcernsAndComments: string;
  isTransporationConfirmed: boolean;
  Weight: string;
  Height: string;
  ClientContractId: number;
  ClientContractPurchaseOrderId: number;
  Sex: string;
  InternalNote: string;
  NeedsClientProvidedAttendant: boolean;
  NeedsPlatformProvidedAttendant: boolean;
  ClientAssistantId: number;
  PreferredAttendantSex: string;
  IsSoloRider: boolean;
  isClientPortal: boolean = true;
  ProfilePhoto: any;
  ServiceCancelations: any = [];
  Places: any = [];
  Guardians: any = [];
  Contacts: any = [];
  Attachments: any = [];
  StudentSchedules: any = [];
  Instructions: any = [];
}

export type IForm<T> = {
  [K in keyof T]?: any;
};
