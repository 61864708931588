<input
  id="typeahead-focus"
  type="text"
  class="form-control"
  style="word-break: break-word"
  [(ngModel)]="model"
  [ngbTypeahead]="search"
  style="padding: 16px 0px 70px 10px"
  (focus)="focus$.next($any($event).target.value)"
  (click)="click$.next($any($event).target.value)"
  #instance="ngbTypeahead"
  placeholder="Select or enter a reason."
/>
