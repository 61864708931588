import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { environment } from "environments/environment";
@Injectable({ providedIn: "root" })
export class CientInvoicesService {
  /**
   * Constructor
   *
   * @param {HttpClient} _httpClient
   */
  constructor(private _httpClient: HttpClient) {}

  /**
   * Get rows
   */
  getDataTableRows(clientid, year) {
    let queryParams = new HttpParams();
    queryParams = queryParams.append("year", year);
    return this._httpClient.get<any[]>(
      `${environment.apiUrl}/clients/${clientid}/invoices`,
      {
        params: queryParams,
      },
    );
  }

  getClients(date) {
    let queryParams = new HttpParams();
    queryParams = queryParams.append("from_date", date[0]);
    queryParams = queryParams.append("to_date", date[1]);
    return this._httpClient.get<any[]>(
      `${environment.apiUrl}client-invoices/period-clients`,
      { params: queryParams },
    );
  }

  getStatuses(date) {
    let queryParams = new HttpParams();
    queryParams = queryParams.append("from_date", date[0]);
    queryParams = queryParams.append("to_date", date[1]);
    return this._httpClient.get<any[]>(
      `${environment.apiUrl}client-invoices/period-status`,
      { params: queryParams },
    );
  }
}
