<div
  id="actionBar"
  class="d-flex align-items-end bg-white"
>
  <div class="container-fluid">
    <div class="d-flex w-100 actionBar-content align-items-end">
      <div class="flex-fill">
        <div class="sub-title pb-0">Route Sheet</div>
      </div>
      <div class="flex-fill text-right">
        <div class="btn-group">
          <div
            ngbDropdown
            [placement]="'bottom-right'"
          >
            <button
              ngbDropdownToggle
              id="dropdownSettings"
              class="btn-icon btn btn-info btn-round btn-sm btn-page-action"
              type="button"
              rippleEffect
            >
              <i
                class="fa fa-bars"
                aria-hidden="true"
              ></i>
            </button>
            <div
              ngbDropdownMenu
              aria-labelledby="dropdownSettings"
            >
              <div
                class="dropdown-menu dropdown-menu-right"
                role="menu"
              >
                <a
                  ngbDropdownItem
                  href="javascript:window.print();"
                  data-automation-id="print_btn"
                  ><span
                    [data-feather]="'printer'"
                    [class]="'mr-50'"
                  ></span>
                  Print</a
                >
                <a
                  ngbDropdownItem
                  href="javascript:void(0)"
                  (click)="GetExcelData()"
                  ><span
                    [data-feather]="'download'"
                    [class]="'mr-50'"
                  ></span
                  >Download Xlsx</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<br />

<div class="content-wrapper container-fluid">
  <app-statistics [statisticsList]="statistics"></app-statistics>

  <div class="content-body">
    <div class="card">
      <app-collection-filters
        (search)="getGridData()"
        (clearSearch)="getGridData()"
        [filters]="FiltersConfig"
      >
      </app-collection-filters>

      <!--   GRID   -->
      <div class="card-body">
        <kendo-grid
          [kendoGridBinding]="gridView"
          [sortable]="true"
          [pageSize]="pageSize"
        >
          <ng-template
            kendoGridToolbarTemplate
            NgClass="p-0"
          >
            <button
              [hidden]="true"
              type="button"
              kendoGridExcelCommand
              icon="file-excel"
              #excel
            >
              Export to Excel
            </button>

            <div class="col-lg-12 toolbar-control">
              <div class="row">
                <div class="col-md-6 col-12 pb-0">
                  <div
                    class="d-flex justify-content-between align-items-center"
                  >
                    <label class="d-flex align-items-center">
                      Show
                      <select
                        (change)="changeGridIndex()"
                        [(ngModel)]="gridIndex"
                        class="form-control mx-25 form-control-sm ng-pristine ng-valid ng-touched"
                      >
                        <option value="5">5</option>
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                      </select>
                      entries
                    </label>
                  </div>
                </div>
                <div
                  class="col-md-6 col-12 d-flex justify-content-md-end pb-0 pr-0"
                >
                  <label class="d-flex align-items-center mr-0">
                    Search
                    <input
                      [style.width.px]="165"
                      class="form-control form-control-sm ml-25"
                      placeholder="Search"
                      kendoTextBox
                      (input)="onFilter($event)"
                    />
                  </label>
                </div>
              </div>
            </div>
            <kendo-grid-spacer></kendo-grid-spacer>
          </ng-template>

          <kendo-grid-column
            [width]="50"
            field="Index"
            [sortable]="false"
            title="#"
          >
            <ng-template
              kendoGridCellTemplate
              let-rowIndex="rowIndex+1"
            >
              {{ rowIndex }}
            </ng-template>
          </kendo-grid-column>

          <kendo-grid-column
            field="RideInvoiceContainerId"
            title="Container #"
          ></kendo-grid-column>

          <kendo-grid-column
            field="Id"
            title="Blueprint #"
          >
            <ng-template
              kendoGridCellTemplate
              let-dataItem
              let-rowIndex="rowIndex"
            >
              <a
                class="btn btn-link pl-0 pr-0"
                (click)="modalOpenLG(RideSummary, dataItem.Uuid)"
              >
                {{ dataItem.Id }}
              </a>
            </ng-template>
          </kendo-grid-column>

          <kendo-grid-column
            field="Type"
            title="Type"
          >
          </kendo-grid-column>

          <kendo-grid-column
            field="Destinations"
            title="Place(s)"
          ></kendo-grid-column>

          <kendo-grid-column
            field="DurationInMinutes"
            title="Duration"
            [sortable]="false"
          ></kendo-grid-column>

          <kendo-grid-column
            class="assign-days"
            field="ActiveDays"
            title="Active Days"
            [sortable]="false"
            [width]="300"
          >
            <ng-template
              kendoGridCellTemplate
              let-dataItem
            >
              {{ FormatActiveDate(dataItem) }}
              <br />
              <span *ngFor="let day of days">
                <span
                  [ngClass]="
                    isAssignedDay(day.id, dataItem.Id)
                      ? 'badge badge-success table-badge'
                      : 'badge badge-secondary table-badge'
                  "
                  >{{ day.day }}</span
                >
              </span>
            </ng-template>
          </kendo-grid-column>

          <kendo-grid-excel
            class="excel"
            fileName="Ride Blue Print Sheet.xlsx"
          ></kendo-grid-excel>
        </kendo-grid>

        <div class="col-lg-12 text-center Load-More-section">
          <button
            [disabled]="pageSize >= gridView.length"
            class="btn btn-light btn-Load-More btn-sm"
            (click)="viewMore()"
          >
            load more
          </button>
          <div class="page-count ng-star-inserted">
            {{ pageSize >= gridView.length ? gridView.length : pageSize }}
            of {{ gridView.length }} record
          </div>
        </div>
      </div>
      <!--   GRID   -->
    </div>
  </div>
</div>

<div class="modal-size-lg d-inline-block">
  <!-- Modal -->
  <ng-template
    #RideSummary
    let-modal
  >
    <div class="modal-header">
      <h5
        class="modal-title"
        id="myModalLabel160"
      >
        Ride Blueprint Sheet #{{ rideBlueprintSheetData.Id }}
      </h5>
      <button
        type="button"
        class="close"
        (click)="modal.dismiss('Cross click')"
        aria-label="Close"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div
      class="modal-body"
      tabindex="0"
      ngbAutofocus
    >
      <div class="card-body invoice-padding py-0">
        <div class="my-2">
          <div
            class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0"
          >
            <div>
              <div class="logo-wrapper">
                <h5 class="text-primary invoice-logo">Basic Details</h5>
              </div>
            </div>
          </div>
          <hr class="m-0 mb-2" />
        </div>
        <!-- Invoice Note starts -->
        <div class="row">
          <div class="col-lg-6 col-md-12 col-sm-12 pb-0">
            <div class="d-flex bd-highlight info-container">
              <div class="w-30 info-title">Type</div>
              <div class="w-70 info-value">
                {{ rideBlueprintSheetData.Type }}
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-md-12 col-sm-12 pb-0">
            <div class="d-flex bd-highlight info-container">
              <div class="w-30 info-title">Service</div>
              <div class="w-70 info-value">
                {{ rideBlueprintSheetData.VehicleTypeName }}
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-md-12 col-sm-12 pb-0">
            <div class="d-flex bd-highlight info-container">
              <div class="w-30 info-title">Period</div>
              <div class="w-70 info-value">
                {{ rideBlueprintSheetData.Period }}
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-md-12 col-sm-12 pb-0">
            <div class="d-flex bd-highlight info-container">
              <div class="w-30 info-title">Student(s)</div>
              <div class="w-70 info-value">
                {{ rideBlueprintSheetData.NumberOfCargo }}
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-md-12 col-sm-12 pb-0">
            <div class="d-flex bd-highlight info-container">
              <div class="w-30 info-title">Duration</div>
              <div class="w-70 info-value">
                {{
                  formatDurationInMinutes(
                    rideBlueprintSheetData.DurationInMinutes
                  )
                }}
                ({{ rideBlueprintSheetData.DistanceInMiles }} mi)
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-md-12 col-sm-12 pb-0">
            <div class="d-flex bd-highlight info-container">
              <div class="w-30 info-title">Monitor(s)</div>
              <div class="w-70 info-value">
                {{ rideBlueprintSheetData.NumberOfAttendants }}
              </div>
            </div>
          </div>
        </div>
        <!-- Invoice Note ends -->
      </div>
      <div class="invoice-preview-card mb-2">
        <!-- Header starts -->
        <div class="card-body invoice-padding pb-0">
          <div class="my-2">
            <div
              class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0"
            >
              <div>
                <div class="logo-wrapper">
                  <h5 class="text-primary invoice-logo">Ride Overview</h5>
                </div>
              </div>
            </div>
            <hr class="mt-0 mb-2" />
          </div>
          <div
            class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0"
          >
            <div class="w-100">
              <ul class="timeline">
                <li
                  *ngFor="
                    let wayPoint of rideBlueprintSheetData.Waypoints;
                    index as indexOfelement
                  "
                  class="timeline-item"
                >
                  <div class="timeline-time timeline-stop-time">
                    {{ wayPoint.ScheduledArrivalTime | date: "hh:mm a" }}
                  </div>

                  <span
                    class="badge badge-secondary bg-info Stop-span pt-1 pb-1"
                  >
                    Stop {{ indexOfelement + 1 }}
                  </span>
                  <span
                    class="timeline-point timeline-point-info timeline-point-indicator"
                  ></span>
                  <div class="timeline-event">
                    <div class="row">
                      <div class="col-lg-12 pb-0">
                        <p
                          class="Time-line-title text-primary bold mb-0 d-inline"
                        >
                          {{ wayPoint.Name }}
                        </p>
                      </div>
                      <div class="col-lg-12 pb-0">
                        <p class="pl-1 Time-line-title Text-secondary">
                          {{ wayPoint.FullAddress }}
                        </p>
                      </div>
                    </div>
                    <hr />
                    <div class="row">
                      <div class="col-lg-12 pb-0">
                        <div class="row">
                          <div class="col-lg-6 pb-0">
                            <div class="row">
                              <div class="col-lg-12 pb-0">
                                <p
                                  class="Time-line-title text-primary bold mb-0"
                                >
                                  {{ wayPoint.ActionType }}
                                </p>
                              </div>
                              <div
                                *ngFor="let cargoValue of wayPoint.Cargo"
                                class="col-lg-12 pb-0"
                              >
                                <p class="pl-1 Time-line-title Text-secondary">
                                  {{ cargoValue.Name }}
                                  <span class="hint">
                                    ({{
                                      cargoValue.CargoType == "Assistant"
                                        ? "Client
                                                                        Monitor"
                                        : cargoValue.CargoType == "Aide" ||
                                            cargoValue.CargoType ==
                                              "Attendant" ||
                                            cargoValue.CargoType == "Attentdant"
                                          ? "Monitor"
                                          : cargoValue.CargoType
                                    }})</span
                                  >
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12 mt-1">
                      <p
                        *ngIf="
                          rideBlueprintSheetData.ScheduleTimeAdjustments != null
                        "
                        class="Time-line-title text-primary ride-sub-title bold mb-0 d-inline"
                      >
                        Weekly Schedule Adjustments
                      </p>
                      <ul class="disc-list ride-Schedule-list">
                        <li
                          *ngFor="
                            let ScheduleTimeAdjustment of rideBlueprintSheetData.ScheduleTimeAdjustments
                          "
                        >
                          {{ ScheduleTimeAdjustment.TypeName }}
                          {{
                            ScheduleTimeAdjustment.RecurrenceDay == "2"
                              ? "every " + "Monday"
                              : ScheduleTimeAdjustment.RecurrenceDay == "3"
                                ? "every " + "Tuesday"
                                : ScheduleTimeAdjustment.RecurrenceDay == "4"
                                  ? "every " + "Wednesday"
                                  : ScheduleTimeAdjustment.RecurrenceDay == "5"
                                    ? "every " + "Thursday"
                                    : ScheduleTimeAdjustment.RecurrenceDay ==
                                        "6"
                                      ? "every " + "Friday"
                                      : ScheduleTimeAdjustment.RecurrenceDay ==
                                          "7"
                                        ? "every " + "Saturday"
                                        : "every " + "Sunday"
                          }}
                          at
                          {{
                            moment(
                              wayPoint.ScheduledArrivalTime,
                              ScheduleTimeAdjustment.AdjustedDurationInMinutes
                            )
                          }}
                        </li>
                      </ul>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="card-body invoice-padding py-0">
        <div class="row">
          <div class="col-lg-6">
            <div class="my-1">
              <div
                class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0"
              >
                <div>
                  <div class="logo-wrapper">
                    <h5 class="text-primary invoice-logo">Equipment Needed</h5>
                  </div>
                </div>
              </div>
              <hr class="m-0" />
            </div>
            <!-- Invoice Note starts -->
            <div class="row">
              <div class="col-12 pb-0">
                <div>
                  <p
                    *ngIf="rideBlueprintSheetData.Equipment.length <= 0"
                    class="mb-75 text-secondary w-30 d-inline-block"
                  >
                    None required.
                  </p>
                  <div
                    *ngIf="rideBlueprintSheetData.Equipment.length > 0"
                    class="mb-75 text-secondary w-30 d-inline-block"
                  >
                    <p
                      *ngFor="
                        let equipment1 of rideBlueprintSheetData.Equipment
                      "
                    >
                      {{ equipment1.NumberOfEquipment }} x {{ equipment1.Name }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="my-1">
              <div
                class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0"
              >
                <div>
                  <div class="logo-wrapper">
                    <h5 class="text-primary invoice-logo">Calendars</h5>
                  </div>
                </div>
              </div>
              <hr class="m-0" />
            </div>
            <!-- Invoice Note starts -->
            <div class="row">
              <div class="col-12 pb-0">
                <p
                  *ngIf="rideBlueprintSheetData.Destinations.length <= 0"
                  class="mb-75 text-secondary d-inline-block"
                >
                  None.
                </p>
                <div
                  *ngIf="rideBlueprintSheetData.Destinations.length > 0"
                  class="mb-75 text-secondary d-inline-block"
                >
                  <p
                    *ngFor="let calendar of rideBlueprintSheetData.Destinations"
                  >
                    {{ calendar.PlaceScheduleName }} ({{
                      calendar.PlaceScheduleNickName
                    }}) -
                    {{ calendar.Name }}
                  </p>
                </div>
              </div>
              <div></div>
            </div>
          </div>
        </div>
      </div>

      <div
        *ngIf="rideBlueprintSheetData.PlaceInstructions.length > 0"
        class="card-body invoice-padding py-0"
      >
        <div class="my-1">
          <div
            class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0"
          >
            <div>
              <div class="logo-wrapper">
                <h5 class="text-primary invoice-logo">Place Instructions</h5>
              </div>
            </div>
          </div>
          <hr class="m-0" />
        </div>
        <div class="row">
          <div class="col-12 pb-0">
            <table class="table adr-table">
              <caption>
                Instructions Table
              </caption>
              <thead class="thead-default">
                <tr>
                  <th>#</th>
                  <th>Type</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  *ngFor="
                    let instructios of rideBlueprintSheetData.PlaceInstructions;
                    index as indexOfelement
                  "
                  role="row"
                  class="odd"
                >
                  <td>
                    {{ indexOfelement + 1 }}
                  </td>

                  <td>
                    {{ instructios.Type }}
                    <br />
                  </td>

                  <td>
                    <div>
                      {{ instructios.Description }}
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div class="card-body invoice-padding py-0">
        <div class="my-1">
          <div
            class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0"
          >
            <div>
              <div class="logo-wrapper">
                <h5 class="text-primary invoice-logo">Passenger(s)</h5>
              </div>
            </div>
          </div>
          <hr class="m-0" />
        </div>
        <div class="row">
          <div class="col-12 pb-0">
            <table
              class="table adr-table"
              style="width: 100% !important"
            >
              <caption>
                Equipment Table
              </caption>
              <thead class="thead-default">
                <tr>
                  <th>#</th>
                  <th>Name</th>
                  <th>Contact(s)</th>
                  <th>Equipment</th>
                  <th>Instructions</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  *ngFor="
                    let item of rideBlueprintSheetData.Cargo;
                    index as indexOfelement
                  "
                  role="row"
                  class="odd"
                >
                  <td>
                    {{ indexOfelement + 1 }}
                  </td>

                  <td>
                    {{ item.FullName }} <br />
                    <span class="text-muted">
                      ({{
                        item.CargoType == "Assistant"
                          ? "Client Monitor"
                          : item.CargoType == "Aide" ||
                              item.CargoType == "Attendant" ||
                              item.CargoType == "Attentdant"
                            ? "Monitor"
                            : item.CargoType
                      }})</span
                    >
                    <br />
                  </td>

                  <td>
                    <div>
                      {{ ContactPassengers(item.Contacts) }}
                    </div>
                  </td>

                  <td>
                    <div
                      *ngIf="
                        rideBlueprintSheetData.Cargo[0].Equipment.length == 0
                      "
                      class="d-block"
                    >
                      <p>None required</p>
                    </div>

                    <div class="d-block">
                      {{ Equipments(item.Equipment) }}
                      <br />
                    </div>
                  </td>

                  <td>
                    <button
                      *ngIf="CargoInstructions(item) != ''"
                      type="button"
                      rippleEffect
                      class="btn btn-link pl-0 pr-0 text-left"
                      [ngbPopover]="elseBlock"
                      placement="right"
                      triggers="mouseenter:mouseleave"
                    >
                      View Instructions
                    </button>
                    <button
                      *ngIf="CargoInstructions(item) == ''"
                      type="button"
                      rippleEffect
                      class="btn btn-link pl-0 pr-0 text-left"
                      [disabled]="CargoInstructions(item) == ''"
                      placement="right"
                      triggers="mouseenter:mouseleave"
                      style="color: gray !important"
                    >
                      View Instructions
                    </button>
                    <ng-template #elseBlock
                      ><span
                        style="display: block; width: 500px; height: 500px"
                        [outerHTML]="CargoInstructions(item)"
                      ></span
                    ></ng-template>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="card-body invoice-padding py-0">
        <div class="my-1">
          <div
            class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0"
          >
            <div>
              <div class="logo-wrapper">
                <h5 class="text-primary invoice-logo">Destination(s)</h5>
              </div>
            </div>
          </div>
          <hr class="m-0" />
        </div>
        <div class="row">
          <div class="col-12 pb-0">
            <table
              class="table adr-table"
              style="width: 100% !important"
            >
              <caption>
                Contact Table
              </caption>
              <thead class="thead-default">
                <tr>
                  <th>#</th>
                  <th>Name</th>
                  <th>Contact(s)</th>
                  <th style="width: 150px">Note(s)</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  *ngFor="
                    let destination of rideBlueprintSheetData.Destinations;
                    index as indexOfelement
                  "
                  role="row"
                  class="odd"
                >
                  <td>
                    {{ indexOfelement + 1 }}
                  </td>

                  <td>
                    {{ destination.Name }}
                  </td>

                  <td
                    *ngFor="let contact of rideBlueprintSheetData.Destinations"
                  >
                    <div class="d-block">
                      {{ ContactDestinations(contact.Contacts) }}
                    </div>
                  </td>

                  <td>
                    <div *ngIf="destination.Note != ''">
                      <button
                        type="button"
                        rippleEffect
                        class="btn btn-link pl-0 pr-0 text-left"
                        ngbPopover="{{ destination.Note }}"
                        placement="right"
                        triggers="mouseenter:mouseleave"
                        [disabled]="destination.Note == ''"
                      >
                        View Note
                      </button>
                    </div>
                    <div *ngIf="destination.Note == ''">-</div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-info"
          (click)="modal.close('Accept click')"
          rippleEffect
        >
          Close
        </button>
      </div>
    </div>
  </ng-template>
  <!-- / Modal -->
</div>
