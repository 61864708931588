export class ClientStudentsFilterModel {
  searchTerm: string = "";
  clientId: number = 0;
  gender: string = "";
  grade: string = "";
  placeId: number = 0;
  limit: number = 25;
  offset: number = 0;
  sort_by: string = "name";
  order_by: string = "asc";
  viewType: string = "";
  guardianId: number = 0;
  clientIds: string = "";
  archived: boolean = false;
  clientPortal: boolean = true
}
