<ng-container [formGroup]="formchild">
  <div
    class="row"
    style="padding: 14px"
  >
    <!--Outbound-->
    <div class="col-lg-6">
      <div class="row">
        <div
          class="col-lg-2"
          style="margin-top: -16px"
        >
          <label class="checkboxLabel cui-utils-control-checkbox mt-2">
            {{ Item.name
            }}<input
              type="checkbox"
              name="checkAll"
              [(ngModel)]="Item.Checked1"
              [checked]="Item.Checked1"
              style="display: none"
              [value]="Item.Checked1"
              formControlName="checkP1"
              (ngModelChange)="check1()"
              [disabledd]="PermessionValue"
            />
            <span class="custom-checkbox"></span>
          </label>
        </div>
        <div class="col-lg-3">
          <!--pickup1-->
          <div
            Id="Pickup"
            *ngIf="Config.Filters[0].filterVisability"
          >
            <ng-select
              [(ngModel)]="Config.Filters[0].filterValue"
              [clearable]="Config.Filters[0].filterClear"
              [disabled]="!Item.Checked1"
              [multiple]="Config.filterMultiple"
              [placeholder]="Config.Filters[0].filterPlaceholder"
              formControlName="pickup1"
              (change)="SelectedPickup1()"
              (click)="SelectedPickup1()"
            >
              <ng-option
                *ngFor="let item of Config.Filters[0].filterValues"
                [value]="item.id"
                >{{ item.name }}</ng-option
              >
            </ng-select>
            <div
              *ngIf="
                formchild.get('pickup1').touched &&
                formchild.get('pickup1').invalid
              "
              class="invalid-feedback"
              style="font-size: 9px !important"
              [ngClass]="{
                'd-block':
                  formchild.get('pickup1').touched &&
                  formchild.get('pickup1').invalid
              }"
            >
              <div
                *ngIf="
                  formchild.get('pickup1').touched &&
                  formchild.get('pickup1').invalid
                "
              >
                This field is required.
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <!--dropOff1-->
          <div
            Id="DropOff"
            *ngIf="Config.Filters[1].filterVisability"
          >
            <ng-select
              [(ngModel)]="Config.Filters[1].filterValue"
              [clearable]="Config.Filters[1].filterClear"
              [disabled]="!Item.Checked1"
              [multiple]="Config.filterMultiple"
              [placeholder]="'Select a place.'"
              formControlName="dropOff1"
              (change)="SelectedDropOff1()"
              (click)="SelectedDropOff1()"
            >
              <ng-option
                *ngFor="let item of Config.Filters[1].filterValues"
                [value]="item.id"
                >{{ item.name }}</ng-option
              >
            </ng-select>
            <div
              *ngIf="
                formchild.get('dropOff1').touched &&
                formchild.get('dropOff1').invalid
              "
              class="invalid-feedback"
              style="font-size: 9px !important"
              [ngClass]="{
                'd-block':
                  formchild.get('dropOff1').touched &&
                  formchild.get('dropOff1').invalid
              }"
            >
              <div
                *ngIf="
                  formchild.get('dropOff1').touched &&
                  formchild.get('dropOff1').invalid
                "
              >
                This field is required.
              </div>
            </div>
          </div>
        </div>
        <div
          class="col-lg-2"
          Id="Time"
        >
          <input
            type="text"
            class="form-control"
            [placeholder]="'00.00'"
            formControlName="time1"
          />
        </div>
      </div>
      <hr
        *ngIf="Item.name != 'Sun'"
        class="hr-dashed"
      />
    </div>
    <!--Return-->
    <div class="col-lg-6">
      <div class="row">
        <div
          class="col-lg-2"
          style="margin-top: -16px"
        >
          <label class="checkboxLabel cui-utils-control-checkbox mt-2">
            {{ Item.name
            }}<input
              type="checkbox"
              name="checkAll"
              [(ngModel)]="Item.Checked2"
              [checked]="Item.Checked2"
              style="display: none"
              [value]="Item.Checked2"
              formControlName="checkP2"
              (ngModelChange)="check2()"
              [disabledd]="PermessionValue"
            />
            <span class="custom-checkbox"></span>
          </label>
        </div>
        <div
          class="col-lg-3"
          Id="Time"
        >
          <input
            type="text"
            class="form-control"
            [placeholder]="'00.00'"
            formControlName="time2"
          />
        </div>
        <!--pickup2-->
        <div class="col-lg-3">
          <div
            Id="Pickup"
            *ngIf="Config.Filters[2].filterVisability"
          >
            <ng-select
              [(ngModel)]="Config.Filters[2].filterValue"
              [clearable]="Config.Filters[2].filterClear"
              [disabled]="!Item.Checked2"
              [multiple]="Config.filterMultiple"
              [placeholder]="Config.Filters[2].filterPlaceholder"
              formControlName="pickup2"
              (change)="SelectedPickup2()"
              (click)="SelectedPickup2()"
            >
              <ng-option
                *ngFor="let item of Config.Filters[2].filterValues"
                [value]="item.id"
                >{{ item.name }}</ng-option
              >
            </ng-select>
            <div
              *ngIf="
                formchild.get('pickup2').touched &&
                formchild.get('pickup2').invalid
              "
              class="invalid-feedback"
              style="font-size: 9px !important"
              [ngClass]="{
                'd-block':
                  formchild.get('pickup2').touched &&
                  formchild.get('pickup2').invalid
              }"
            >
              <div
                *ngIf="
                  formchild.get('pickup2').touched &&
                  formchild.get('pickup2').invalid
                "
              >
                This field is required.
              </div>
            </div>
            <div
              *ngIf="formchild.get('ReturnError').value != ''"
              class="invalid-feedback"
              [ngClass]="{
                'd-block': formchild.get('ReturnError').value != ''
              }"
            >
              <div *ngIf="formchild.get('ReturnError').value != ''">
                Pick-up conflict with outbound drop-off.
              </div>
            </div>
          </div>
        </div>
        <!--dropOff2-->
        <div class="col-lg-4">
          <div
            Id="DropOff"
            *ngIf="Config.Filters[3].filterVisability"
          >
            <ng-select
              [(ngModel)]="Config.Filters[3].filterValue"
              [clearable]="Config.Filters[3].filterClear"
              [disabled]="!Item.Checked2"
              [multiple]="Config.filterMultiple"
              [placeholder]="Config.Filters[3].filterPlaceholder"
              formControlName="dropOff2"
              (change)="SelectedDropOff2()"
              (click)="SelectedDropOff2()"
            >
              <ng-option
                *ngFor="let item of Config.Filters[3].filterValues"
                [value]="item.id"
                >{{ item.name }}</ng-option
              >
            </ng-select>
            <div
              *ngIf="
                formchild.get('dropOff2').touched &&
                formchild.get('dropOff2').invalid
              "
              class="invalid-feedback"
              style="font-size: 9px !important"
              [ngClass]="{
                'd-block':
                  formchild.get('dropOff2').touched &&
                  formchild.get('dropOff2').invalid
              }"
            >
              <div
                *ngIf="
                  formchild.get('dropOff2').touched &&
                  formchild.get('dropOff2').invalid
                "
              >
                This field is required.
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr
        *ngIf="Item.name != 'Sun'"
        class="hr-dashed"
      />
    </div>
  </div>
</ng-container>
