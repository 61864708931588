import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Self,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { ControlValueAccessor, NgControl, Validators } from "@angular/forms";
import { FlatpickrInstance } from "ng2-flatpickr/src/flatpickr-instance";
import { FormatSettings } from "@progress/kendo-angular-dateinputs";
import { FlatpickrOptions } from "ng2-flatpickr";
@Component({
  selector: "app-date-time-picker",
  templateUrl: "./date-time-picker.component.html",
  styleUrls: ["./date-time-picker.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class DateTimePickerComponent implements OnInit, ControlValueAccessor {
  @Input() placeholder: string = "";
  @Input() data: [];
  @Input() clearable: boolean = false;
  @Input() name: string = "";
  @Input() bindLabel: string = "";
  @Input() bindValue: string = "";
  @Input() disabled: boolean = false;
  @Input() multiple: boolean = true;
  @Output() blur: EventEmitter<void> = new EventEmitter<void>();
  @Input() validationError: string = "";
  @Input("disable") disable: boolean = true;
  public disabeld: boolean = false;

  onChange: (value: any) => void = () => {
    console.log("changes selector");
  };
  @Input() OnChangeFunction: Function;
  @ViewChild("FromDate") FromDate: FlatpickrInstance;
  @Input() disabledDates: Function;
  onTouched: () => void = () => {};
  input: string;
  class: string = "";
  @Input() initialValue: Date;
  constructor(
    @Self() public controlDir: NgControl,
    private cd: ChangeDetectorRef,
  ) {
    controlDir.valueAccessor = this;
  }

  ngOnInit(): void {
    this.disabeld = !this.disable;
    this.cd.detectChanges();
    const control = this.controlDir.control;
    const validators = control?.validator
      ? [control.validator, Validators.required]
      : null;
    control?.setValidators(validators);
    control?.updateValueAndValidity();
  }

  writeValue(value: any): void {
    this.input = value;
  }

  registerOnChange(onChange: (value: any) => void): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: () => void): void {
    this.onTouched = onTouched;
  }
  setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
  }

  public FromDateOptions: FlatpickrOptions = {
    altInput: true,
    weekNumbers: true,
    locale: {
      firstDayOfWeek: 1, // start week on Monday
    },
  };

  public format: FormatSettings = {
    displayFormat: "MM/dd/yyyy",
    inputFormat: "MM/dd/yyyy",
  };

  clear() {
    (<any>this.FromDate).flatpickr.clear();
    this.input = null;
  }
}
