<div
  class="row"
  style="overflow-y: hidden; overflow-x: hidden; padding: 14px"
>
  <div class="col-lg-6">
    <div class="row custom-grid-header gride-broder-top">
      <div class="col-lg-12 col-sm-12">
        <p class="text-center text-black mt-2">Outbound</p>
      </div>
    </div>
  </div>
  <div class="col-lg-6">
    <div class="row custom-grid-header gride-broder-top">
      <div class="col-lg-12 col-sm-12">
        <p class="text-center text-black mt-2">Return</p>
      </div>
    </div>
  </div>
  <div class="col-lg-6">
    <div class="row custom-grid-header">
      <div class="col-lg-2">
        <label class="checkboxLabel cui-utils-control-checkbox mt-2">
          All<input
            type="checkbox"
            name="checkAll"
            style="display: none"
            [(ngModel)]="checkAll1"
            [checked]="checkAll1"
            (change)="check1()"
            [value]="checkAll1"
            [disabled]="disabled"
          />
          <span class="custom-checkbox"></span>
        </label>
      </div>
      <div class="col-lg-3">
        <p class="text-left text-black mt-2">Pick-up</p>
      </div>
      <div class="col-lg-4">
        <p class="text-left text-black mt-2">Drop-off</p>
      </div>
      <div class="col-lg-2">
        <p class="text-left text-black mt-2">Time</p>
      </div>
    </div>
  </div>
  <div class="col-lg-6">
    <div class="row custom-grid-header">
      <div class="col-lg-2">
        <label class="checkboxLabel cui-utils-control-checkbox mt-2">
          All<input
            type="checkbox"
            name="checkAll"
            style="display: none"
            [(ngModel)]="checkAll2"
            [checked]="checkAll2"
            (change)="check2()"
            [value]="checkAll2"
            [disabled]="disabled"
          />
          <span class="custom-checkbox"></span>
        </label>
      </div>
      <div class="col-lg-3">
        <p class="text-left text-black mt-2">Time</p>
      </div>
      <div class="col-lg-4">
        <p class="text-left text-black mt-2">Pick-up</p>
      </div>
      <div class="col-lg-3">
        <p class="text-left text-black mt-2">Drop-off</p>
      </div>
    </div>
  </div>
</div>
<div [formGroup]="formchild">
  <div *ngFor="let item of days; let i = index">
    <app-schedule-field
      #AppScheduleField
      [AddressGridData]="AddressGridData"
      [PlaceGridData]="PlaceGridData"
      [StudentScheduleDetails]="ReturnDay(item)"
      [Item]="item"
      [formchild]="daysFormGroup(i + 2)"
    ></app-schedule-field>
  </div>
</div>
