import {
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AppComponent } from "app/app.component";
import { AuthenticationService } from "app/auth/service";
import { IFiltersConfig } from "app/controls/component/filters/interfaces/controls/IFiltersConfig";
import { FiltersTypes } from "app/enums/controls/filters/FiltersTypes";
import { RideBlueprintSheetService } from "app/main/RideBlueprints/service/ride-blueprint-sheet.service";
import { HelpersService } from "app/main/SharedServices/helpers.service";
import { ToastrService } from "ngx-toastr";
import { NoShowReportFiliter } from "../model/no-show-filiter.model";
import { NoShowReportModel } from "../model/no-show-report.model";
import moment from "moment";
import { NoShowReportService } from "../services/no-show-report.services";
import { StudentTransportationProfileModel } from "../model/student-transportation-profile.model";
import { StudentServiceDetailsModel } from "../model/student-service-details.model";
import { StudentPlaceModel } from "../model/student-place.model";
import { LocationModel } from "app/main/HistoricalRides/model/location.model";
import { ContactModel } from "../model/contact.model";
import { ExcelExportData } from "@progress/kendo-angular-excel-export";

@Component({
  selector: "app-no-show-report",
  templateUrl: "./no-show-report.component.html",
  styleUrls: ["./no-show-report.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class NoShowReportComponent implements OnInit {
  @ViewChild("excel") excel: ElementRef<HTMLElement>;

  gridView: NoShowReportModel[] = [];
  public opendSearch: boolean;
  public opendProfile: boolean;
  public fullName: string = "";
  public studentInfo: StudentTransportationProfileModel;
  public serviceDetails: StudentServiceDetailsModel;
  public studentAdress: LocationModel[];
  public placesAttended: StudentPlaceModel;
  public EmergencyContacts: ContactModel[];
  public Equipment: any[];
  public textValidation: string;
  public skip = 0;
  public innepagesize = 3;
  constructor(
    private modalService: NgbModal,
    private _appComponent: AppComponent,
    private _noShowReportService: NoShowReportService,
    private _rideBlueprintSheetService: RideBlueprintSheetService,
    private _sanitized: DomSanitizer,
    private _helperService: HelpersService,
    private _authenticationService: AuthenticationService,
    private _toastrService: ToastrService,
  ) {
    this._appComponent._mainpageTitle = "Reports";
  }

  ngOnInit(): void {
    this.pagesize = 25;
    this.gridIndex = 25;
    this.getPassengersData();
  }

  FiltersConfig: IFiltersConfig = {
    isFiltersEnable: true,
    Filters: [
      {
        filterType: FiltersTypes.fromDatePicker,
        filterPlaceholder: "From Date",
        filterClear: true,
        filterVisability: true,
      },
      {
        filterType: FiltersTypes.toDatePicker,
        filterPlaceholder: "To Date",
        filterClear: true,
        filterVisability: true,
      },
      {
        filterType: FiltersTypes.NoShowStatus,
        filterPlaceholder: "Filter by Status",
        filterClear: true,
        filterVisability: true,
      },
      {
        filterType: FiltersTypes.Passengers,
        filterPlaceholder: "Filter By Student",
        filterVisability: true,
        filterClear: true,
      },
    ],
  };
  public gridData: any[] = [];
  public pagesize;
  public gridIndex;
  getGridData(filterModel?: NoShowReportFiliter) {
    let that = this;
    if (filterModel == null || filterModel == undefined) {
      filterModel = new NoShowReportFiliter();
    }
    let a = that.FiltersConfig.Filters.filter(
      x => x.filterType == FiltersTypes.fromDatePicker,
    )[0].filterValue
      ? this.FiltersConfig.Filters.filter(
          x => x.filterType == FiltersTypes.fromDatePicker,
        )[0].filterValue
      : null;
    let b = that.FiltersConfig.Filters.filter(
      x => x.filterType == FiltersTypes.toDatePicker,
    )[0].filterValue
      ? this.FiltersConfig.Filters.filter(
          x => x.filterType == FiltersTypes.toDatePicker,
        )[0].filterValue
      : null;
    filterModel.FromServiceDate = that.FiltersConfig.Filters.filter(
      x => x.filterType == FiltersTypes.fromDatePicker,
    )[0].filterValue
      ? this.FiltersConfig.Filters.filter(
          x => x.filterType == FiltersTypes.fromDatePicker,
        )[0].filterValue.toLocaleDateString()
      : null;
    filterModel.ToServiceDate = that.FiltersConfig.Filters.filter(
      x => x.filterType == FiltersTypes.toDatePicker,
    )[0].filterValue
      ? this.FiltersConfig.Filters.filter(
          x => x.filterType == FiltersTypes.toDatePicker,
        )[0].filterValue.toLocaleDateString()
      : null;
    filterModel.Status = that.FiltersConfig.Filters.filter(
      x => x.filterType == FiltersTypes.NoShowStatus,
    )[0].filterValue
      ? this.FiltersConfig.Filters.filter(
          x => x.filterType == FiltersTypes.NoShowStatus,
        )[0].filterValue
      : "";

    filterModel.StudentId = that.FiltersConfig.Filters.filter(
      x => x.filterType == FiltersTypes.Passengers,
    )[0].filterValue
      ? this.FiltersConfig.Filters.filter(
          x => x.filterType == FiltersTypes.Passengers,
        )[0].filterValue
      : 0;

    if (
      filterModel.FromServiceDate == null &&
      filterModel.ToServiceDate == null
    ) {
      that.openSearchvalidation("Select dates.");
    } else if (filterModel.FromServiceDate == null) {
      that.openSearchvalidation("Select From Date");
    } else if (filterModel.ToServiceDate == null) {
      that.openSearchvalidation("Select To Date");
    } else if (a >= b) {
      that.openSearchvalidation("To date should be grater than from date.");
    } else {
      that.getDefaultGridData(filterModel);
    }
  }
  getPassengersData() {
    let passenger = this.FiltersConfig.Filters.filter(
      x => x.filterType == FiltersTypes.Passengers,
    )[0];
    this._noShowReportService.getPassengersData().subscribe(
      response => {
        let passengerfilterres = [];
        response.forEach(function (value) {
          let data = {
            id: value.StudentId,
            name: value.FirstName + " " + value.LastName,
          };
          passengerfilterres.push(data);
        });
        passenger.filterValues = passengerfilterres;
        passenger.filterDisabled = false;
      },
      error => {
        if (error.status == 401) {
          this._toastrService.error("UnAuthorized.", "");
          window.location.href = "/pages/authentication/login";
        } else {
          this._toastrService.error(error.error.Message, "");
        }
      },
    );
  }

  openSearchvalidation(text): void {
    this.textValidation = text;
    this.opendSearch = true;
  }
  close(status: string): void {
    console.log(`Dialog result: ${status}`);

    this.opendSearch = false;
    this.opendProfile = false;
  }
  public viewMore(): void {
    this.pagesize = parseInt(this.pagesize) + parseInt(this.gridIndex);
  }
  public changeGridIndex(): void {
    this.pagesize = this.gridIndex;
  }
  getTime(time) {
    return time == null ? "NA" : moment.utc(time).local().format("LT");
  }
  openStudentProfile(fisrtName, LastName, studentId) {
    this._appComponent.toggeleLoader();

    this.fullName = fisrtName + " " + LastName;
    this._noShowReportService.getStudentProfile(studentId).subscribe(
      Response => {
        this.studentInfo = Response[0].BasicInformation;
        this.serviceDetails = Response[0].ServiceDetails;
        this.studentAdress = Response[0].HomeAddresses;
        this.placesAttended = Response[0].PlacesAttended;
        this.EmergencyContacts = Response[0].EmergencyContacts;
        this.Equipment = Response[0].Equipment;
        this._appComponent.toggeleLoader();
        this.opendProfile = true;
      },
      error => {
        if (error.status == 401) {
          this._toastrService.error("UnAuthorized.", "");
          this._appComponent.toggeleLoader();

          window.location.href = "/pages/authentication/login";
        } else {
          this._toastrService.error(error.error.Message, "");
          this._appComponent.toggeleLoader();
        }
      },
    );
  }

  getAge(date) {
    return moment().diff(moment(date).format("MM/DD/YYYY"), "years");
  }

  public GetExcelData = (): ExcelExportData => {
    let result: ExcelExportData = {
      data: this.gridView.slice(),
    };

    this.excel.nativeElement.click();
    return result;
  };

  getDate(Datevalue) {
    return moment(Datevalue).format("MM/DD/YYYY");
  }

  print() {
    // do other stuff...
    window.print();
  }
  getMobileNumber(mobileNumber: string) {
    return this._helperService.phoneNumber(mobileNumber);
  }
  getDefaultGridData(filterModel) {
    let that = this;
    this._noShowReportService.getNoShowReport(filterModel).subscribe(
      Response => {
        that._appComponent.toggeleLoader();
        that.gridView = Response;
        let i = 1;
        that.gridView.forEach(element => {
          element.CancelTime =
            that.getTime(element.CancelTime) == "NA"
              ? "N/A"
              : that.getTime(element.CancelTime);
          element.NoShowTime =
            that.getTime(element.NoShowTime) == "NA"
              ? "N/A"
              : that.getTime(element.NoShowTime);
          element.DateOfService = that.getDate(element.DateOfService);

          
          element.FullName = element.StudentFirstName + ' ' + element.StudentLastName;
          
          element.id = i;
          i++;
        });
      },
      error => {
        if (error.status == 401) {
          this._toastrService.error("UnAuthorized.", "");
          window.location.href = "/pages/authentication/login";
        } else {
          this._toastrService.error(error.error.Message, "");
        }
      },
    );

    that._appComponent.toggeleLoader();
  }
}
