import { Component, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { DataBindingDirective } from "@progress/kendo-angular-grid";
import { process } from "@progress/kendo-data-query";
import { User } from "app/auth/models";
import { AuthenticationService } from "app/auth/service";
import { HelpersService } from "app/main/SharedServices/helpers.service";
import moment from "moment";
import { CientInvoicesService } from "../services/client-invoices.service";
import { AppComponent } from "app/app.component";
import { ToastrService } from "ngx-toastr";
import { FiltersTypes } from "app/enums/controls/filters/FiltersTypes";
import { CollectionFiltersComponent } from "app/controls/component/filters/collection-filters/collection-filter.component";
import { IFiltersConfig } from "app/controls/component/filters/interfaces/controls/IFiltersConfig";
import { InvoicesModule } from "../Invoices.module";
import { InvoiceType as InvoiceTypeEnum } from './../../../enums/lookups/LookupEnum';

@Component({
  selector: "app-client-invoices",
  templateUrl: "./client-invoices.component.html",
  styleUrls: ["./client-invoices.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class ClientInvoicesComponent implements OnInit {
  @ViewChild(DataBindingDirective) dataBinding: DataBindingDirective;
  public _currentUser: User;
  public FiltersConfig: IFiltersConfig = {
    isFiltersEnable: false,
    Filters: [
      {
        filterType: FiltersTypes.year,
        filterPlaceholder: "Select a Year",
        filterVisability: true,
      },
    ],
  };
  @ViewChild(CollectionFiltersComponent)
  collectionOfFiltersComponent: CollectionFiltersComponent;
  /**
   * Constructor
   * * @param {AuthenticationService} _authenticationService
   * * @param {HelpersService} _helpersService
   * * @param {CientInvoicesService} _cientInvoicesService
   * * @param {AppComponent} _appComponent
   * * @param {ToastrService} _toastrService
   */
  constructor(
    private _authenticationService: AuthenticationService,
    private _helpersService: HelpersService,
    private _cientInvoicesService: CientInvoicesService,
    private _appComponent: AppComponent,
    private _toastrService: ToastrService,
  ) {
    this._authenticationService.currentUser.subscribe(
      x => (this._currentUser = x),
    );
    let that = this;
    that.fillYearsData();
    this._appComponent._mainpageTitle = "Invoice";
  }
  public contentHeader: object;
  public isFiltersEnable = false;
  public pagesize;
  public gridIndex;
  year: number;
  years = [
    { id: 1, name: "2021" },
    { id: 2, name: "2022" },
    { id: 3, name: "2023" },
  ];
  private monthsArray = [
    { monthValue: 0, month: "" },
    { monthValue: 1, month: "January" },
    { monthValue: 2, month: "February" },
    { monthValue: 3, month: "March" },
    { monthValue: 4, month: "April" },
    { monthValue: 5, month: "May" },
    { monthValue: 6, month: "June" },
    { monthValue: 7, month: "July" },
    { monthValue: 8, month: "August" },
    { monthValue: 9, month: "September" },
    { monthValue: 10, month: "October" },
    { monthValue: 11, month: "November" },
    { monthValue: 12, month: "December" },
  ];
  public gridData: any[] = [];
  public gridView: any[] = [];

  public onFilter(input: Event): void {
    const inputValue = (input.target as HTMLInputElement).value;
    this.gridView = process(this.gridData, {
      filter: {
        logic: "or",
        filters: [
          {
            field: "Index",
            operator: "contains",
            value: inputValue,
          },

          {
            field: "Month",
            operator: "contains",
            value: inputValue,
          },
          {
            field: "period",
            operator: "contains",
            value: inputValue,
          },
          {
            field: "ToDate",
            operator: "contains",
            value: inputValue,
          },
          {
            field: "Id",
            operator: "contains",
            value: inputValue,
          },
          {
            field: "Number",
            operator: "contains",
            value: inputValue,
          },
          {
            field: "Amount",
            operator: "contains",
            value: inputValue,
          },
          {
            field: "Status",
            operator: "contains",
            value: inputValue,
          },
        ],
      },
    }).data;

    this.dataBinding.skip = 0;
  }
  ngOnInit() {
    this.pagesize = 25;
    this.gridIndex = 25;
    this.getGridData();
    this.gridView = this.gridData;
    this.contentHeader = {
      headerTitle: "",
      actionButton: true,
      breadcrumb: {
        type: "",
        links: [
          {
            name: "Home",
            isLink: true,
            link: "/",
          },
          {
            name: "Sample",
            isLink: false,
          },
        ],
      },
    };
  }

  public viewFilter(): void {
    if (this.isFiltersEnable) {
      this.isFiltersEnable = false;
    } else {
      this.isFiltersEnable = true;
    }
  }

  public viewMore(): void {
    this.pagesize = parseInt(this.pagesize) + parseInt(this.gridIndex);
  }

  public changeGridIndex(): void {
    this.pagesize = this.gridIndex;
  }
  getGridData() {
    let filterYear = this.getFilterData();
    let that = this;
    this._appComponent.toggeleLoader();
    this._cientInvoicesService
      .getDataTableRows(this._currentUser.client_id, filterYear)
      .subscribe(
          data => {
          that.gridData = data;
          that.configGridView();
          that.gridView = data;
          this._appComponent.toggeleLoader();
        },
        error => {
          if (error.status == 401) {
            this._toastrService.error("UnAuthorized", "");
          } else if (error.status == 403) {
            this._toastrService.error(error.error.Message, "");
          } else if (error.status == 500) {
            this._toastrService.error(
              "The invoices was not loaded due to a technical issue.",
              "",
            );
          } else {
            this._toastrService.error("Server internal error", "");
          }
          this._appComponent.toggeleLoader();
        },
      );
  }

  getstatus(row, type) {
    let returnvalue;
    if (row.Status == "None" || row.Status == "" || row.Status == null) {
      returnvalue = this.getbadgeValues(type);
    } else if (row.Status.includes("Due on") && type == "class") {
      returnvalue = this.getdangerValue(row.DueDate);
    } else if (row.Status.includes("Sent") && type == "value") {
      returnvalue = `Due on ${moment(row.DueDate).format("MM/DD/YYYY")}`;
    } else if (row.Status == "Received") {
      returnvalue = this.getRecivedValue(type, row.Status);
    } else if (row.Status=="Paid" && type == "class") {
      returnvalue = "badge badge-success";
    } else if (row.Status.includes("Deposited") && type == "value") {
      returnvalue = `Paid`;
    } else if (row.Status == "Partially Paid") {
      return this.getPartiallyPaidValue(type);
    } else {
      this.defaultBadgeValue(type, row.Status);
    }
    return returnvalue;
  }
  defaultBadgeValue(type, status) {
    let returnvalue = "";
    if (type == "class") {
      returnvalue = "badge badge-secondary";
    } else {
      returnvalue = `${this._helpersService.toTitleCase(status)}`;
    }
    return returnvalue;
  }
  getPartiallyPaidValue(type) {
    let returnvalue = "";

    if (type == "class") {
      returnvalue = "badge badge-warning";
    } else {
      returnvalue = `Partially Paid`;
    }
    return returnvalue;
  }
  getRecivedValue(type, status) {
    let returnvalue = "";

    if (type == "class") {
      returnvalue = "badge badge-primary";
    } else {
      returnvalue = `${this._helpersService.toTitleCase(status)}`;
    }
    return returnvalue;
  }
  getdangerValue(DueDate) {
    let returnvalue = "";

    if (moment(DueDate).format("YYYY/MM/DD") < moment().format("YYYY/MM/DD")) {
      returnvalue = "badge badge-danger";
    } else {
      returnvalue = "badge badge-warning ";
    }
    return returnvalue;
  }
  getbadgeValues(type) {
    let returnvalue = "";

    if (type == "class") {
      returnvalue = "badge-secondary";
    } else {
      returnvalue = `Not Reviewed`;
    }
    return returnvalue;
    }
    automatic = InvoiceTypeEnum.Automatic;

    poNumber(value: string, max: number, invoiceType: number) {
        if (invoiceType != this.automatic) {
            return "";
        }
        return value.length < max ? this.poNumber("0" + value, max, invoiceType) : value;
  }
  amountValue(amount: string) {
    return (
      "$" +
      this._helpersService
        .formatNumberThousandSeparator(parseFloat(amount).toFixed(2))
        .toString()
    );
  }
  dateValue(date) {
    return moment(date).format("MM/DD/YYYY");
  }
  configGridView() {
    this.gridData.forEach(element => {
      element.Amount = this.amountValue(element.Amount);
        element.Number = this.poNumber(element.Number, 5, element.InvoiceType);
      element.FromDate = this.dateValue(element.FromDate);
      element.ToDate = this.dateValue(element.ToDate);
      element.period =
        this.monthsArray[element.Month].month +
        " (" +
        element.FromDate +
        " - " +
        element.ToDate +
        ")";
      element.Status = this.getstatus(
        { Status: element.Status, DueDate: element.DueDate },
        "value",
      );
      element.Month = this.monthsArray[element.Month].month;
    });
  }

  public clearAllData() {
    let that = this;
    let yearRecord = that.FiltersConfig.Filters.filter(
      x => x.filterType == FiltersTypes.year,
    )[0];
    let year = new Date().getFullYear();
    yearRecord.filterValue = year.toString();
    that.getGridData();
  }

  fillYearsData() {
    let that = this;
    let yearRecord = that.FiltersConfig.Filters.filter(
      x => x.filterType == FiltersTypes.year,
    )[0];
    let year = new Date().getFullYear();
    let years = [];

    for (let i = year; i >= 2021; i--) {
      years.push({ id: i, name: i.toString() });
    }

    yearRecord.filterValues = years;
    yearRecord.filterValue = year.toString();
  }
  getFilterData() {
    let that = this;
    let yearRecord = that.FiltersConfig.Filters.filter(
      x => x.filterType == FiltersTypes.year,
    )[0];
    return yearRecord.filterValue;
  }
}
