export class PlaceFiliter {
  searchTerm: string = "";
  type: string = "";
  archived?: boolean = null;
  limit: number = 25;
  offset: number = 0;
  sort_by: string = "";
  order_by: string = "asc";
  state: string = "name";
  viewType: string = "";
  id: number = 0;
  addedBy: string = "";
  ClientId: number = 0;
  accountId: number = 0;
}
