<div
  id="actionBar"
  class="d-flex align-items-end bg-white"
>
  <div class="container-fluid">
    <div class="d-flex w-100 actionBar-content align-items-end">
      <div class="flex-fill">
        <div class="sub-title pb-0">Students</div>
        <button
          type="button"
          class="btn btn-info btn-sm"
          (click)="addNewStudent()"
          style="margin-top: 10px"
          [hidden]="!disabled"
          rippleEffect
        >
          Add New Student
        </button>
      </div>
      <div class="flex-fill text-right">
        <div class="btn-group">
          <div
            ngbDropdown
            [placement]="'bottom-right'"
          >
            <button
              ngbDropdownToggle
              id="dropdownSettings"
              class="btn-icon btn btn-info btn-round btn-sm btn-page-action"
              type="button"
              data-automation-id="dropdown_btn"
              rippleEffect
            >
              <i
                class="fa fa-bars"
                aria-hidden="true"
              ></i>
            </button>
            <div
              ngbDropdownMenu
              aria-labelledby="dropdownSettings"
            >
              <div class="dropdown-header">Excel Sheet (.xlsx)</div>
              <a
                ngbDropdownItem
                [href]="downlowdUrl"
                data-automation-id="print_btn"
              >
                Download</a
              >
              <input
                style="display: none"
                id="Import_upload"
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                class="file_upload"
                type="file"
                (change)="selectFile()"
              />
              <label
                ngbDropdownItem
                for="Import_upload"
                id="Import_upload_label"
                >Import</label
              >
              <div class="dropdown-divider"></div>
              <a
                ngbDropdownItem
                href="javascript:window.print();"
                data-automation-id="print_btn"
              >
                <span
                  [data-feather]="'printer'"
                  [class]="'mr-50'"
                ></span>
                Print
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<br />

<div class="content-wrapper container-fluid">
  <div class="content-body">
    <div class="card">
      <app-collection-filters
        (search)="getFilterData(true)"
        (clearSearch)="clearAllData()"
        [filters]="FiltersConfig"
      ></app-collection-filters>
      <div class="card-body">
        <kendo-grid
          #mainGrid
          [data]="gridData"
          [pageSize]="state.take"
          [skip]="state.skip"
          [sortable]="true"
          [sort]="state.sort"
          [filter]="state.filter"
          [pageable]="false"
          (sortChange)="sortChange($event)"
        >
          <ng-template
            kendoGridToolbarTemplate
            NgClass="p-0"
          >
            <div class="col-lg-12 toolbar-control">
              <div class="row">
                <div class="col-md-6 col-12 pb-0">
                  <div
                    class="d-flex justify-content-between align-items-center"
                  >
                    <label class="d-flex align-items-center">
                      Show
                      <select
                        (change)="changeGridIndex()"
                        [(ngModel)]="gridIndex"
                        class="form-control mx-25 form-control-sm ng-pristine ng-valid ng-touched"
                      >
                        <option value="5">5</option>
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                      </select>
                      entries
                    </label>
                  </div>
                </div>
                <div
                  class="col-md-6 col-12 d-flex justify-content-md-end pb-0 pr-0"
                >
                  <label class="d-flex align-items-center mr-0">
                    Search
                    <input
                      [style.width.px]="165"
                      class="form-control form-control-sm ml-25"
                      placeholder="Search"
                      (input)="onFilter($event)"
                    />
                  </label>
                </div>
              </div>
            </div>
            <kendo-grid-spacer></kendo-grid-spacer>
          </ng-template>
          <kendo-grid-spacer></kendo-grid-spacer>
          <kendo-grid-column
            [width]="50"
            field="Index"
            [sortable]="false"
            title="#"
          >
            <ng-template
              kendoGridCellTemplate
              let-rowIndex="rowIndex+1"
            >
              {{ rowIndex }}
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-column
            field="Name"
            title="Name"
            [sortable]="true"
            [width]="250"
            (sortChange)="sortChange($event)"
          >
            <ng-template
              kendoGridCellTemplate
              let-dataItem
              let-rowIndex="rowIndex"
            >
              <a
                class="btn btn-link pl-0 pr-0"
                href="customer-success/students/edit/{{ dataItem.Id }}"
              >
                {{ getName(dataItem) }}
              </a>
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-column
            field="DateOfBirth"
            title="Date of Birth"
            [sortable]="false"
          >
            <ng-template
              kendoGridCellTemplate
              let-dataItem
              let-rowIndex="rowIndex"
            >
              {{ getDateOfBirth(dataItem) }}
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-column
            field="SchoolGrade"
            title="Grade"
            [sortable]="false"
          >
            <ng-template
              kendoGridCellTemplate
              let-dataItem
              let-rowIndex="rowIndex"
            >
              <span>
                {{ dataItem.SchoolGrade }}
              </span>
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-column
            field="Places"
            title="Place(s)"
            [sortable]="false"
          >
            <ng-template
              kendoGridCellTemplate
              let-dataItem
              let-rowIndex="rowIndex"
            >
              <span id="formatted">
                {{ dataItem.Places }}
              </span>
            </ng-template>
          </kendo-grid-column>

          <kendo-grid-column
            [sortable]="false"
            title="Action"
            [width]="90"
          >
            <ng-template
              kendoGridCellTemplate
              let-dataItem
              let-rowIndex="rowIndex"
            >
              <div class="d-flex align-items-center">
                <div
                  ngbDropdown
                  [placement]="'left-top'"
                  container="body"
                  class="m-auto"
                >
                  <a
                    ngbDropdownToggle
                    href="javascript:void(0);"
                    class="hide-arrow"
                    id="dropdownBrowserState"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <i
                      data-feather="more-vertical"
                      class="text-primary cursor-pointer mr-50"
                    ></i>
                  </a>
                  <div
                    ngbDropdownMenu
                    class="dropdown-menu-right"
                    aria-labelledby="dropdownBrowserState"
                  >
                    <a
                      href="customer-success/students/edit/{{ dataItem.Id }}"
                      ngbDropdownItem
                      class="d-flex align-items-center"
                    >
                      Edit Student
                    </a>
                  </div>
                </div>
              </div>
            </ng-template>
          </kendo-grid-column>
        </kendo-grid>

        <div class="col-lg-12 text-center Load-More-section">
          <button
            [disabled]="disableLoadMore"
            class="btn btn-light btn-Load-More btn-sm"
            (click)="viewMore()"
          >
            load more
          </button>
          <div class="page-count ng-star-inserted">
            {{
              basicSelectedOption >= gridData.length
                ? gridData.length
                : basicSelectedOption
            }}
            of {{ basicSelectedOption }} record
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
