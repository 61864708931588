<!-- Footer -->
<p class="clearfix mb-0 text-center">
  <span> ADROIT Advanced Technologies LLC / Client Portal V.2.0 </span>
</p>
<!--/ Footer -->

<!-- Move to top Button-->
<app-scroll-top *ngIf="coreConfig.layout.scrollTop"></app-scroll-top>

<!-- Buynow Button-->
