import { Component, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { AuthenticationService } from "../../../auth/service";
import { AppComponent } from "app/app.component";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class HomeComponent implements OnInit {
  /**
   * Constructor
   *
   @param {NgbModal} modalService
   @param {DomSanitizer} _sanitizer
   * @param _authenticationService
   */
  public IsPopoverActive = false;
  public isAddNoteEnable = false;
  public contentHeader: object;
  public isCheckeAllServicesAndFees: boolean = false;
  public isCheckeAllCONTRACTS: boolean = false;

  constructor(
    private modalService: NgbModal,
    private _sanitizer: DomSanitizer,
    private _authenticationService: AuthenticationService,
    private _appComponent: AppComponent,
  ) {
    this._appComponent._mainpageTitle = "Dashboard";
  }

  public currentDate;
  urlSafe: SafeResourceUrl;
  urlSafe1: SafeResourceUrl;

  public isCONTRACTSTableEnable: boolean = true;
  public isAddCONTRACTSTableEnable: boolean = false;
  public isViewCONTRACTSTableEnable: boolean = false;

  // Lifecycle Hooks
  // -----------------------------------------------------------------------------------------------------
  @ViewChild("CloseEvent") CloseEvent;
  @ViewChild("OpenEvent") OpenEvent;

  public isMoveEnable = false;

  public quillEditorContent = ``;
  public PricingModelInbut: string = "";
  States = [
    { id: 1, name: "AZ" },
    { id: 2, name: "CA" },
  ];
  year: number;
  years = [
    { id: 1, name: "Early Cancel", title: "EC" },
    { id: 2, name: "Late Cancel ", title: "LC" },
    { id: 3, name: "No Show", title: "NS" },
  ];

  PricingModel = [
    { id: 1, name: "Per Mile" },
    { id: 2, name: "Per Hour" },
    { id: 3, name: "Per Student" },
    { id: 3, name: "Per Seat" },
    { id: 4, name: "Per Kilo" },
  ];
  public openPopOver(): void {
    this.OpenEvent.open();
    this.IsPopoverActive = true;
  }
  public closePopOver(): void {
    if (this.PricingModelInbut == "") {
      this.IsPopoverActive = false;
      this.CloseEvent.close();
    } else {
      this.IsPopoverActive = false;
      this.CloseEvent.close();
      this.PricingModel.push({ id: 0, name: this.PricingModelInbut });
      this.PricingModelInbut = "";
    }
  }
  public ViewCONTRACTSTable(): void {
    this.isCONTRACTSTableEnable = false;
    this.isAddCONTRACTSTableEnable = true;
  }
  public AddCONTRACTSTable(): void {
    this.isAddCONTRACTSTableEnable = false;
    this.isViewCONTRACTSTableEnable = true;
  }
  public removeCONTRACTSTable(): void {
    this.isAddCONTRACTSTableEnable = false;
    this.isViewCONTRACTSTableEnable = false;
    this.isCONTRACTSTableEnable = true;
  }
  public checkeAllCONTRACTS(): void {
    if (this.isCheckeAllCONTRACTS) {
      this.isCheckeAllCONTRACTS = false;
    } else {
      this.isCheckeAllCONTRACTS = true;
    }
  }
  public checkeAllServicesAndFees(): void {
    if (this.isCheckeAllServicesAndFees) {
      this.isCheckeAllServicesAndFees = false;
    } else {
      this.isCheckeAllServicesAndFees = true;
    }
  }
  /**
   * On init
   */
  ngOnInit() {
    let to = new Date();
    to.setDate(to.getDate() - 1);

    let from = new Date();
    from.setDate(from.getDate() - 7);
    this.urlSafe =
      "https://bi.goadroit.com/embed/query/19/visualization/35?api_key=q9Bd2qJeUL4fiDHlXA9XZBFVuIG7Q4SPCxMNKLkH&p_date%20range=" +
      from +
      "--" +
      to +
      "&p_ClientId=" +
      this._authenticationService.currentUserValue.client_id +
      "&p_AccountId=" +
      this._authenticationService.currentUserValue.id +
      "&hide_link&hide_header&hide_parameters";
    this.urlSafe1 =
      "https://bi.goadroit.com/embed/query/16/visualization/30?api_key=yHuV2x4IkraEhXZDU57kxn4q6yz0A5vkbLifoL8o&p_client=" +
      this._authenticationService.currentUserValue.client_id +
      "&p_AccountId=" +
      this._authenticationService.currentUserValue.id +
      "&p_hours=" +
      new Date().getHours() +
      "&p_mints=" +
      new Date().getMinutes() +
      "&p_sec=" +
      new Date().getSeconds() +
      "&p_day=" +
      new Date().getDate() +
      "&p_month=" +
      new Date().getMonth() +
      "&p_year=" +
      new Date().getFullYear() +
      "&hide_link&hide_header&hide_parameters";
    this.contentHeader = {
      headerTitle: "Home",
      actionButton: true,
      breadcrumb: {
        type: "",
        links: [
          {
            name: "Home",
            isLink: true,
            link: "/",
          },
          {
            name: "Sample",
            isLink: false,
          },
        ],
      },
    };
  }
  // modal Open Large
  modalOpenLG(modalLG) {
    this.modalService.open(modalLG, {
      size: "lg",
      backdrop: "static",
    });
  }
  public viewAddNote(): void {
    if (this.isAddNoteEnable) {
      this.isAddNoteEnable = false;
    } else {
      this.isAddNoteEnable = true;
    }
  }
  public minusChange(event) {
    if ($(event).parent().hasClass("badge-info")) {
      $(event).parent().removeClass("badge-info");
      $(event).parent().addClass("badge-secondary");
    } else {
      $(event).parent().removeClass("badge-secondary");
      $(event).parent().addClass("badge-info");
    }
  }
}
