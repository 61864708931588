<div class="card-header">
  <div></div>
  <div class="heading-elements">
    <button
      class="btn btn-info btn-sm"
      data-automation-id="view_filter_ btn"
      (click)="viewFilter()"
    >
      {{ filters.isFiltersEnable ? "Hide Filter" : "View Filter" }}
    </button>
  </div>
</div>

<div
  class="col-lg-12 px-0"
  *ngIf="filters.isFiltersEnable"
>
  <div
    class="filters-section"
    style="min-height: 160px; max-height: 245px"
  >
    <div class="col-lg-12 p-0">
      <h6 class="section-label mx-1 mb-2">Filters</h6>
    </div>
    <ng-container *ngFor="let f of filters.Filters">
      <div
        class="col-12 pb-0"
        [ngClass]="'col-sm-' + (f.filtersize ? f.filtersize : '3')"
        data-automation-id="year_filter"
        *ngIf="f.filterType == filtersTypesEnum.year && f.filterVisability"
      >
        <app-year
          [filterData]="f"
          (changeyear)="changeYearFilter()"
        ></app-year>
      </div>

      <div
        class="col-12 pb-0"
        [ngClass]="'col-sm-' + (f.filtersize ? f.filtersize : '3')"
        *ngIf="
          f.filterType == filtersTypesEnum.fiscalPeriod && f.filterVisability
        "
      >
        <app-fiscal-period
          [filterData]="f"
          (changeValue)="changefiscalPeriodFilter()"
        ></app-fiscal-period>
      </div>

      <div
        class="col-12 pb-0"
        [ngClass]="'col-sm-' + (f.filtersize ? f.filtersize : '3')"
        *ngIf="
          f.filterType == filtersTypesEnum.monthlyPeriods && f.filterVisability
        "
      >
        <div
          class="col-sm-3 col-12 pb-0"
          *ngIf="f.filterType == filtersTypesEnum.year && f.filterVisability"
        ></div>
        <app-year
          [filterData]="f"
          (changeyear)="changeYearFilter()"
        ></app-year>
      </div>

      <div
        class="col-12 pb-0"
        [ngClass]="'col-sm-' + (f.filtersize ? f.filtersize : '3')"
        *ngIf="
          f.filterType == filtersTypesEnum.fiscalPeriod && f.filterVisability
        "
      >
        <app-fiscal-period
          [filterData]="f"
          (changeValue)="changefiscalPeriodFilter()"
        ></app-fiscal-period>
      </div>

      <div
        class="col-12 pb-0"
        [ngClass]="'col-sm-' + (f.filtersize ? f.filtersize : '3')"
        *ngIf="
          f.filterType == filtersTypesEnum.monthlyPeriods && f.filterVisability
        "
      >
        <app-monthly-periods [filterData]="f"> </app-monthly-periods>
      </div>

      <div
        class="col-12 pb-0"
        [ngClass]="'col-sm-' + (f.filtersize ? f.filtersize : '3')"
        *ngIf="f.filterType == filtersTypesEnum.state && f.filterVisability"
      >
        <app-state
          [filterData]="f"
          (changeState)="changeStateFilter()"
        ></app-state>
      </div>

      <div
        class="col-12 pb-0"
        [ngClass]="'col-sm-' + (f.filtersize ? f.filtersize : '3')"
        *ngIf="
          f.filterType == filtersTypesEnum.contractors && f.filterVisability
        "
      >
        <app-contractors [filterData]="f"> </app-contractors>
      </div>

      <div
        class="col-12 pb-0"
        [ngClass]="'col-sm-' + (f.filtersize ? f.filtersize : '3')"
        *ngIf="f.filterType == filtersTypesEnum.clients && f.filterVisability"
      >
        <app-clients
          [filterData]="f"
          (changeClient)="changeClientFilter()"
        >
        </app-clients>
      </div>

      <div
        class="col-12 pb-0"
        [ngClass]="'col-sm-' + (f.filtersize ? f.filtersize : '3')"
        *ngIf="
          f.filterType == filtersTypesEnum.fromDatePicker && f.filterVisability
        "
      >
        <app-from-date-picker
          [filterData]="f"
          (changeValue)="changefromdateFilter()"
        >
        </app-from-date-picker>
      </div>

      <div
        class="col-12 pb-0"
        [ngClass]="'col-sm-' + (f.filtersize ? f.filtersize : '3')"
        *ngIf="
          f.filterType == filtersTypesEnum.toDatePicker && f.filterVisability
        "
      >
        <app-to-date-picker
          [filterData]="f"
          (changeValue)="changetodateFilter()"
        >
        </app-to-date-picker>
      </div>

      <div
        class="col-12 pb-0"
        [ngClass]="'col-sm-' + (f.filtersize ? f.filtersize : '3')"
        *ngIf="
          f.filterType == filtersTypesEnum.searchByNumber && f.filterVisability
        "
      >
        <app-search-by-number [filterData]="f"> </app-search-by-number>
      </div>

      <div
        class="col-12 pb-0"
        [ngClass]="'col-sm-' + (f.filtersize ? f.filtersize : '3')"
        *ngIf="
          f.filterType == filtersTypesEnum.searchByText && f.filterVisability
        "
      >
        <app-search-by-text [filterData]="f"> </app-search-by-text>
      </div>

      <div
        class="col-12 pb-0"
        [ngClass]="'col-sm-' + (f.filtersize ? f.filtersize : '3')"
        *ngIf="
          f.filterType == filtersTypesEnum.DayPeriods && f.filterVisability
        "
      >
        <app-date-periods [filterData]="f"> </app-date-periods>
      </div>
      <div
        class="col-12 pb-0"
        [ngClass]="'col-sm-' + (f.filtersize ? f.filtersize : '3')"
        *ngIf="f.filterType == filtersTypesEnum.ByRide && f.filterVisability"
      >
        <app-search-by-ride [filterData]="f"> </app-search-by-ride>
      </div>

      <div
        class="col-12 pb-0"
        [ngClass]="'col-sm-' + (f.filtersize ? f.filtersize : '3')"
        *ngIf="f.filterType == filtersTypesEnum.Statues && f.filterVisability"
      >
        <app-statues [filterData]="f"> </app-statues>
      </div>

      <div
        class="col-12 pb-0"
        [ngClass]="'col-sm-' + (f.filtersize ? f.filtersize : '3')"
        *ngIf="
          f.filterType == filtersTypesEnum.serviceproviders &&
          f.filterVisability
        "
      >
        <app-service-provider [filterData]="f"> </app-service-provider>
      </div>

      <div
        class="col-12 pb-0"
        [ngClass]="'col-sm-' + (f.filtersize ? f.filtersize : '3')"
        *ngIf="
          f.filterType == filtersTypesEnum.userstypes && f.filterVisability
        "
      >
        <app-users-types [filterData]="f"> </app-users-types>
      </div>

      <div
        class="col-12 pb-0"
        [ngClass]="'col-sm-' + (f.filtersize ? f.filtersize : '3')"
        *ngIf="
          f.filterType == filtersTypesEnum.Passengers && f.filterVisability
        "
      >
        <app-passengers [filterData]="f"> </app-passengers>
      </div>

      <div
        class="col-12 pb-0"
        [ngClass]="'col-sm-' + (f.filtersize ? f.filtersize : '3')"
        *ngIf="f.filterType == filtersTypesEnum.places && f.filterVisability"
      >
        <app-places [filterData]="f"> </app-places>
      </div>

      <div
        class="col-12 pb-0"
        [ngClass]="'col-sm-' + (f.filtersize ? f.filtersize : '3')"
        *ngIf="
          f.filterType == filtersTypesEnum.bluePrintNumber && f.filterVisability
        "
      >
        <app-blue-print [filterData]="f"> </app-blue-print>
      </div>

      <div
        class="col-12 pb-0"
        [ngClass]="'col-sm-' + (f.filtersize ? f.filtersize : '3')"
        *ngIf="
          f.filterType == filtersTypesEnum.containerNumber && f.filterVisability
        "
      >
        <app-container-blue-print [filterData]="f"> </app-container-blue-print>
      </div>

      <div
        class="col-12 pb-0"
        [ngClass]="'col-sm-' + (f.filtersize ? f.filtersize : '3')"
        *ngIf="f.filterType == filtersTypesEnum.periods && f.filterVisability"
      >
        <app-periods [filterData]="f"> </app-periods>
      </div>

      <div
        class="col-sm-3 col-12 pb-0"
        *ngIf="f.filterType == filtersTypesEnum.rideTypes && f.filterVisability"
      >
        <app-ride-types [filterData]="f"> </app-ride-types>
      </div>

      <div
        class="col-sm-3 col-12 pb-0"
        *ngIf="
          f.filterType == filtersTypesEnum.weeklyperiods && f.filterVisability
        "
      >
        <app-weekly-periods
          [filterData]="f"
          (changeValue)="changeWeeklyPeriodFilter()"
        >
        </app-weekly-periods>
      </div>
      <div
        class="col-12 pb-0"
        [ngClass]="'col-sm-' + (f.filtersize ? f.filtersize : '3')"
        *ngIf="
          f.filterType == filtersTypesEnum.GurdiansStatus && f.filterVisability
        "
      >
        <app-gurdians-status [filterData]="f"> </app-gurdians-status>
      </div>
      <div
        class="col-12 pb-0"
        [ngClass]="'col-sm-' + (f.filtersize ? f.filtersize : '3')"
        *ngIf="
          f.filterType == filtersTypesEnum.NoShowStatus && f.filterVisability
        "
      >
        <app-noShowStatus [filterData]="f"> </app-noShowStatus>
      </div>
      <div
        class="col-12 pb-0"
        [ngClass]="'col-sm-' + (f.filtersize ? f.filtersize : '3')"
        *ngIf="
          f.filterType == filtersTypesEnum.ConsecutivNoShowStatus &&
          f.filterVisability
        "
      >
        <app-ConsecutivnoShowStatus [filterData]="f">
        </app-ConsecutivnoShowStatus>
      </div>
      <div
        class="col-12 pb-0"
        [ngClass]="'col-sm-' + (f.filtersize ? f.filtersize : '3')"
        *ngIf="f.filterType == filtersTypesEnum.Types && f.filterVisability"
      >
        <app-type [filterData]="f"> </app-type>
      </div>
      <div
        class="col-12 pb-0"
        [ngClass]="'col-sm-' + (f.filtersize ? f.filtersize : '3')"
        *ngIf="
          f.filterType == filtersTypesEnum.GurdiansAppUsers &&
          f.filterVisability
        "
      >
        <app-gurdians-app-users-filter [filterData]="f">
        </app-gurdians-app-users-filter>
      </div>
      <div
        class="col-12 pb-0"
        [ngClass]="'col-sm-' + (f.filtersize ? f.filtersize : '3')"
        *ngIf="f.filterType == filtersTypesEnum.Grade && f.filterVisability"
      >
        <app-grade [filterData]="f"> </app-grade>
      </div>
    </ng-container>
    <div class="col-lg-12 p-1 text-right">
      <button
        type="button"
        class="btn btn-outline-secondary btn-sm"
        rippleEffect
        data-automation-id="clear_btn"
        (click)="clearFilters()"
      >
        <span
          [data-feather]="'x-circle'"
          [class]="'mr-25'"
        ></span
        >Clear
      </button>
      <button
        type="button"
        class="btn btn-outline-info btn-sm"
        rippleEffect
        (click)="apply()"
      >
        <span
          [data-feather]="'search'"
          [class]="'mr-25'"
          data-automation-id="apply_btn"
        ></span
        >Apply
      </button>
    </div>
  </div>
</div>
