import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { IFiltersConfig } from "app/controls/component/filters/interfaces/controls/IFiltersConfig";
import { ClientGuardiansService } from "../services/client-guardians.service";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-guardian-selectors",
  templateUrl: "./guardian-selectors.component.html",
  styleUrls: ["./guardian-selectors.component.scss"],
})
export class GuardianSelectorsComponent implements OnInit {
  public Config: IFiltersConfig;
  @Output() changeValue = new EventEmitter<string>();
  //public select: any;
  constructor(
    private _clientGurdiansService: ClientGuardiansService,
    private _toastrService: ToastrService,
  ) {}
  @Input() parentForm: FormGroup;
  validRelationShip: boolean = false;
  validStudent: boolean = false;
  ngOnInit(): void {
    this.Config = {
      isFiltersEnable: true,
      Filters: [
        {
          filterValues: [
            { id: "Aunt", name: "Aunt" },
            { id: "Brother", name: "Brother" },
            { id: "Father", name: "Father" },
            { id: "Grandfather", name: "Grandfather" },
            { id: "Grandmother", name: "Grandmother" },
            { id: "Legal Guardian", name: "Legal Guardian" },
            { id: "Mother", name: "Mother" },
            { id: "Sister", name: "Sister" },
            { id: "Uncle", name: "Uncle" },
            { id: "Other", name: "Other" },
          ],
          filterClear: true,
          filterVisability: true,
          filterPlaceholder: "Select a relationship.",
        },
        {
          filterValues: [
            { id: "Aunt", name: "Aunt" },
            { id: "Brother", name: "Brother" },
            { id: "Father", name: "Father" },
            { id: "Grandfather", name: "Grandfather" },
            { id: "Grandmother", name: "Grandmother" },
            { id: "Legal Guardian", name: "Legal Guardian" },
            { id: "Mother", name: "Mother" },
            { id: "Sister", name: "Sister" },
            { id: "Uncle", name: "Uncle" },
            { id: "Other", name: "Other" },
          ],
          filterClear: true,
          filterVisability: true,
          filterPlaceholder: "Select a student.",
        },
      ],
    };
  }

  getPassengers() {
    let passengerfilterres = [];
    this._clientGurdiansService.getPassengers().subscribe(
      Response => {
        Response.forEach(function (value) {
          let data = {
            id: value.Id,
            name: value.FirstName + " " + value.LastName,
          };
          passengerfilterres.push(data);
        });
      },
      error => {
        if (error.status == 401) {
          this._toastrService.error("UnAuthorized.", "");
          window.location.href = "/pages/authentication/login";
        } else {
          this._toastrService.error(error.error.Message, "");
        }
      },
    );
    return passengerfilterres;
  }
  public studentSelected() {
    let that = this;
    if (that.Config.Filters[1].filterValue == null) {
      that.validStudent = true;
    } else {
      that.validStudent = false;
    }
  }
  public relationshipSelected() {
    let that = this;

    if (that.Config.Filters[0].filterValue == null) {
      that.validRelationShip = true;
    } else {
      that.validRelationShip = false;
    }
  }
  Onchange() {
    this.changeValue.emit("complete");
  }
}
