<ng-template
  #AddNewHomeAddress
  let-modal
>
  <div class="modal-header">
    <h5
      class="modal-title"
      id="myModalLabel160"
    >
      {{ PageTitle }}
    </h5>

    <button
      type="button"
      class="close"
      (click)="close(modal)"
      aria-label="Close"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div
    class="modal-body"
    tabindex="0"
    style="overflow-y: hidden; overflow-x: hidden; padding: 14px"
    ngbAutofocus
  >
    <div class="row">
      <div class="col-lg-12 padding-left: 26px;">
        <h4 class="text-black"><strong>Basic Details</strong></h4>
      </div>
      <div class="col-12 separate"></div>
      <div class="col-12">
        <form
          id="address-form"
          action=""
          method="get"
          autocomplete="off"
          [formGroup]="newAddressform"
          (ngSubmit)="onSubmit(modal)"
        >
          <div class="row">
            <div class="col-lg-12">
              <label>Nick Name</label>
              <app-type-head
                [states]="states"
                formControlName="Name"
                [disabled]="PermessionValue"
                placeholder="Select or enter a nickname."
              ></app-type-head>
            </div>
            <div class="col-12 separate"></div>
            <div class="col-lg-12">
              <label>Full Address</label>
              <app-optional-field
                placeholder="123 Sesame Street, Hollywood, CA 12345"
                [Id]="'FullAddress'"
                formControlName="FullAddress"
                type="text"
                [validationError]="validationError"
                [extraValidationError]="validation"
                [disabled]="PermessionValue"
              ></app-optional-field>
            </div>
            <div class="col-12 separate"></div>
            <div class="col-lg-6">
              <label>Street</label>
              <input
                id="street"
                disabled="true"
                formControlName="Street"
                placeholder="Street"
                class="form-control"
                name="street"
                required
                autocomplete="off"
              />
            </div>
            <div class="col-lg-6">
              <label>Unit #</label>
              <input
                id="Street2"
                placeholder="(optional)"
                class="form-control"
                formControlName="Street2"
                name="Street2"
                autocomplete="off"
              />
            </div>
            <div class="col-12 separate"></div>
            <div class="col-lg-6">
              <label>City</label>
              <input
                id="locality"
                disabled="true"
                placeholder="City"
                formControlName="City"
                class="form-control"
                name="locality"
                required
                autocomplete="off"
              />
            </div>
            <div class="col-lg-6">
              <label>State</label>
              <input
                id="state"
                disabled="true"
                formControlName="State"
                placeholder="State"
                class="form-control"
                name="state"
                required
                autocomplete="off"
              />
            </div>
            <div class="col-12 separate"></div>
            <div class="col-lg-6">
              <label>Zip Code</label>
              <input
                id="postcode"
                disabled="true"
                formControlName="ZipCode"
                placeholder="Zipcode"
                class="form-control"
                name="postcode"
                required
                autocomplete="off"
              />
            </div>
            <div class="col-lg-6">
              <label>County</label>
              <input
                id="County"
                disabled="true"
                formControlName="County"
                placeholder="Country"
                class="form-control"
                name="County"
                required
                autocomplete="off"
              />
            </div>
            <div class="col-12 separate"></div>
            <div class="col-lg-6">
              <label>Country</label>
              <input
                id="country"
                disabled="true"
                formControlName="Country"
                placeholder="Country"
                class="form-control"
                name="country"
                required
                autocomplete="off"
              />
            </div>
            <div class="col-lg-6">
              <label>Time Zone</label>
              <input
                id="TimeZone"
                disabled="true"
                formControlName="TimeZone"
                placeholder="Time Zone"
                class="form-control"
                name="TimeZone"
                required
                autocomplete="off"
              />
            </div>
            <div class="col-12 separate"></div>
            <div class="col-lg-6">
              <label>Latitude</label>
              <input
                id="Latitude"
                formControlName="Latitude"
                placeholder="-90.00000000 to 90.00000000"
                class="form-control"
                name="Latitude"
                required
                autocomplete="off"
              />
            </div>
            <div class="col-lg-6">
              <label>Longitude</label>
              <input
                id="Longitude"
                formControlName="Latitude"
                placeholder="-180.00000000 to 180.00000000"
                class="form-control"
                name="Longitude"
                required
                autocomplete="off"
              />
            </div>
            <div class="col-12 separate"></div>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div class="modal-footer about-adroit-footer">
    <button
      type="button"
      (click)="close(modal)"
      rippleeffect=""
      class="btn btn-close waves-effect waves-float waves-light"
      style="border-color: #1877f2; margin-bottom: 20px; color: #1877f2"
    >
      Close
    </button>
    <button
      type="submit"
      class="btn btn-info"
      (click)="onSubmit(modal)"
      style="margin-bottom: 20px; margin-right: 20px"
      [hidden]="disable"
      rippleEffect
    >
      Submit
    </button>
  </div>
</ng-template>
