import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AppComponent } from "app/app.component";
import { User } from "app/auth/models";
import { AuthenticationService } from "app/auth/service";
import { ClientGuardiansService } from "app/main/Guardians/services/client-guardians.service";
import moment from "moment";
import { ToastrService } from "ngx-toastr";
import { IFiltersConfig } from "../../../../controls/component/filters/interfaces/controls/IFiltersConfig";
import {
  AddressStudentModel,
  HomeAddressStudentModel,
} from "../../model/AddressStudentModel";
import { ClientStudentsService } from "../../services/client-students.service";
import { PermissionsList } from "app/enums/Permissions/PermissionList";
const { compile } = require("html-to-text"); //https://www.npmjs.com/package/html-to-text
const options = {};
const compiledConvert = compile(options); // options passed here

@Component({
  selector: "app-student-add-new-place",
  templateUrl: "./student-add-new-place.component.html",
  styleUrls: ["./student-add-new-place.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class StudentAddPlaceComponent implements OnInit {
  public submitted = false;
  public attentionInfo = false;
  public calenders_validation = false;
  public reason: string = "";
  public PageTitle: string = "";
  public validStudent: boolean = false;
  public newPlaceform: FormGroup;
  public PlacesSelectDataSource;
  @ViewChild("AddNewPlace") AddNewPlace: TemplateRef<any>;
  public Config: IFiltersConfig;

  @Output() Added = new EventEmitter<any>();
  @Input() gridData: any = [];
  placeResuls: any = [];
  places: any = [];
  @Input("disable") disable: boolean = false;
  public disabeld: boolean = false;
  public PermessionValue: string =
    PermissionsList.ClientStudentsEdit.toString();
  public data: any;

  //#region google maps configurations
  public _currentUser: User;
  studentId: number = 0;

  /**
   * Constructor
   * * @param {AuthenticationService} _authenticationService
   * * @param {HelpersService} _helpersService
   * * @param {ClientGuardiansService} _clientGurdiansService
   * * @param {NgbModal} _modalService
   * * @param {ToastrService} _toastrService
   * * @param {FormBuilder} _formBuilder
   * * @param {AppComponent} _appcomponent
   */
  constructor(
    private _formBuilder: FormBuilder,
    private _authenticationService: AuthenticationService,
    private _toastrService: ToastrService,
    private _modalService: NgbModal,
    private _clientGurdiansService: ClientGuardiansService,
    private _appcomponent: AppComponent,
    private route: ActivatedRoute,
    private _clientStudentService: ClientStudentsService,
  ) {
    this._authenticationService.currentUser.subscribe(
      x => (this._currentUser = x),
    );
  }

  ngOnInit(): void {
    this.disabeld = this.disable;
    let that = this;
    if (this.route.snapshot.paramMap.get("id")) {
      this.studentId = parseInt(this.route.snapshot.paramMap.get("id")!);
    }
    this.Config = {
      isFiltersEnable: false,
      Filters: [
        {
          filterValues: [],
          filterClear: true,
          filterVisability: true,
          filterPlaceholder: "Select a place.",
        },
      ],
    };

    that.fillPlaceSelector();

    this.newPlaceform = this._formBuilder.group({
      place: ["", [Validators.required]],
    });
  }

  public isValidForm(): boolean {
    let that = this;
    let isUsed: boolean = false;
    for (const control of Object.keys(that.newPlaceform.controls)) {
      that.newPlaceform.controls[control].markAsTouched();
    }
    if (!that.newPlaceform.invalid && that.data == null) {
      let place = that.gridData.filter(
        a => a.PlaceId == that.Config.Filters[0].filterValue,
      )[0];
      isUsed = place != null;
      if (isUsed) {
        that.attentionInfo = true;
        return true;
      }
    }
    if (!that.newPlaceform.invalid) {
      that.calenders_validation =
        that.placeResuls.filter(a => a.IsSelected).length <= 0;
      if (that.calenders_validation) {
        return true;
      }
    }
    return that.newPlaceform.invalid;
  }

  public open() {
    let that = this;
    that.reason = "";
    if (that.data != null) {
      this.PageTitle = "Edit Place";
      let valueSelected = that.Config.Filters[0].filterValues.filter(
        a => a.id == that.data.PlaceId,
      )[0];
      that.newPlaceform.setValue({
        place: valueSelected.id,
      });
      that.Config.Filters[0].filterValue = valueSelected.id;
      that.onChangeSelector(that.data.PlaceScheduleId);
      that.newPlaceform.get("place").disable();
      that._modalService.open(that.AddNewPlace, {
        centered: true,
        backdrop: "static",
        size: "md",
      });
    } else {
      this.PageTitle = "Add Place";
      that.placeResuls = [];
      that.newPlaceform.get("place").enable();
      that._modalService.open(that.AddNewPlace, {
        centered: true,
        backdrop: "static",
        size: "md",
      });
    }
  }

  close(model: any) {
    let that = this;
    for (const control of Object.keys(that.newPlaceform.controls)) {
      that.newPlaceform.controls[control].markAsUntouched();
    }
    that.newPlaceform.reset();
    that.data = null;
    that.reason = "";
    model.close("Accept click");
  }

  onSubmit(modal: any) {
    let that = this;
    that.submitted = true;
    if (that.studentId != 0) {
      if (!that.isValidForm()) {
        if (that.data == null || that.data?.Id == 0) {
          that._toastrService.success("The place was added successfully.", "");
        } else {
          that._toastrService.success(
            "The place was updated successfully.",
            "",
          );
        }
        //Add, Edit
        that.createUpdateStudentPlace(modal);
      }
    } else if (!that.isValidForm()) {
      that.Added.emit({ data: that.studentPlaceData(), mode: "add" });
      that.close(modal);
    }
  }

  getAddAddressObject() {
    let that = this;
    let data = new HomeAddressStudentModel();
    let location = new AddressStudentModel();

    location.LocationId = 0;
    if (that.data?.Location) {
      location.LocationId = that.data.Location.LocationId;
    }
    location.FullAddress = that.data?.Location
      ? that.data?.Location.FullAddress
      : that.newPlaceform.value.FullAddress;
    location.City = that.newPlaceform.value.City;
    location.Country = that.newPlaceform.value.County;
    location.County = that.newPlaceform.value.County;
    location.CustomLatitude = Number(that.newPlaceform.value.Latitude).toFixed(
      8,
    );
    location.CustomLongitude = Number(
      that.newPlaceform.value.Longitude,
    ).toFixed(8);
    location.Latitude = that.newPlaceform.value.Latitude;
    location.Longitude = that.newPlaceform.value.Longitude;
    location.State = that.newPlaceform.value.State;
    location.Street1 = that.newPlaceform.value.Street;
    location.Street2 = that.newPlaceform.value.Street2;
    location.ZipCode = that.newPlaceform.value.ZipCode;

    data.ParentId = that.studentId;
    data.Location = location;
    data.Name = that.newPlaceform.value.Name;
    data.IsAddedFromClientPortal = true;
    data.Id = that.data && that.data.Id != 0 ? that.data.Id : 0;
    data.Type = "Home";

    return data;
  }

  fillPlaceSelector() {
    let that = this;
    that._clientStudentService.getPlace().subscribe(
      (response: any) => {
        let data = response.Places;
        that.PlacesSelectDataSource = data;
        let placeData = [];

        data.forEach(function (value) {
          let data = {
            id: value.Id,
            name: `<span>${value.Name}</span><small class='text-default pull-right'>${value.Type}</small>`,
          };
          let result: string = compiledConvert(data.name, options);
          let finalResutl = result.replaceAll("#NewLine#", "<br/>");
          data.name = finalResutl;
          placeData.push(data);
        });
        this.Config.Filters[0].filterValues = placeData;
        that.places = placeData;
      },
      error => {
        if (error.status == 401) {
          that._toastrService.error("UnAuthorized.", "");
          that._appcomponent.toggeleLoader();
        } else {
          that._toastrService.error(
            "The places list was not loaded due to a technical issue.",
            "",
          );
          that._appcomponent.toggeleLoader();
        }
      },
    );
  }

  onChangeSelector(placeScheduleId?: number) {
    let that = this;

    let placeId = that.Config.Filters[0].filterValue;
    if (placeId != null) {
      that.LoadPlaceShedules(placeId, placeScheduleId);
    } else {
      that.placeResuls = [];
    }
  }

  LoadPlaceShedules(placeId: number, calendarId?: number) {
    let that = this;
    that._appcomponent.toggeleLoader();
    that._clientStudentService.getPlaceShedules(placeId).subscribe(
      (Response: Array<any>) => {
        Response.forEach(a => {
          a.Class = "btn btn-sm btn-outline-primary pull-right";
          a.ButtonText = "Select this Calendar";
          a.IsSelected = false;
          a.PlaceScheduleId = a.Id;
        });
        that.placeResuls = Response;
        if (Response && Response.length == 0) {
          that._toastrService.info(
            "No calendar(s) added for this place, please navigate to place and add a calendar.",
          );
          return;
        }
        if (calendarId != null) {
          that.selectPlaceCalendar(
            that.placeResuls.filter(a => a.Id == calendarId)[0],
          );
        }
        if (calendarId == null && Response.length > 0) {
          that.selectPlaceCalendar(that.placeResuls[0]);
        }
        that._appcomponent.toggeleLoader();
      },
      error => {
        if (error.status == 401) {
          that._toastrService.error("UnAuthorized.", "");
          that._appcomponent.toggeleLoader();
        } else {
          that._toastrService.error(
            "The calendars was not loaded due to a technical issue.",
            "",
          );
          that._appcomponent.toggeleLoader();
        }
      },
    );
  }

  getTimeValue(item, format) {
    return moment(item).format(format);
  }

  selectPlaceCalendar(item) {
    let that = this;
    if (item) {
      that.placeResuls.forEach(a => {
        a.Class = "btn btn-sm btn-outline-primary pull-right";
        a.ButtonText = "Select this Calendar";
        a.IsSelected = false;
      });
      item.Class = "btn btn-sm btn-primary pull-right";
      item.ButtonText = "Selected";
      item.IsSelected = true;
      that.calenders_validation = false;
    }
  }

  closeAttentionDialog(model: any) {
    let that = this;
    that.attentionInfo = false;
    model.close("Accept click");
  }

  createUpdateStudentPlace(modal) {
    let that = this;

    that._appcomponent.toggeleLoader();
    that._clientStudentService
      .createUpdateStudentPlace(that.studentId, that.studentPlaceData())
      .subscribe(
        (response: any) => {
          if (that.data == null) {
            that.Added.emit({ data: response, mode: "add" });
          } else {
            that.Added.emit({ data: response, mode: "edit" });
          }
          that.close(modal);
          that._appcomponent.toggeleLoader();
        },
        error => {
          if (error.status == 401) {
            that._toastrService.error("UnAuthorized.", "");
            window.location.href = "/pages/authentication/login";
            that._appcomponent.toggeleLoader();
          } else if (error.status == 300) {
            that._toastrService.error(error.error.Message, "");
            that._appcomponent.toggeleLoader();
          } else {
            that._toastrService.error(
              "The place was not updated due to a technical issue.",
              "",
            );
            that._appcomponent.toggeleLoader();
          }
        },
      );
  }

  studentPlaceData() {
    let that = this;

    let item;
    let selectedStudentPlaceId = that.data ? that.data.Id : 0;
    let data = that.placeResuls.filter(a => a.IsSelected)[0];
    data.Id = that.studentId != 0 && that.studentId != null ? data.Id : 0;
    let selPlace = that.PlacesSelectDataSource.find(x => x.Id == data.PlaceId);

    if (selectedStudentPlaceId == 0) {
      item = {
        Id: that.studentId != 0 ? 0 : that.gridData.length + 1,
        RowId: that.studentId != 0 ? 0 : that.gridData.length + 1,
        PlaceId: data.PlaceId,
        PlaceScheduleId: data.PlaceScheduleId,
        PlaceScheduleName: data.Name,
        PlaceScheduleNickName: data.NickName,
        Type: "Destination",
        Name: data.Name,
        InternalNote: that.reason,
        Status: selPlace.Status,
        RequiresAttention: selPlace.RequiresAttention,
        IsAddedFromClientPortal: true,
        Location: {
          Street1: selPlace.Street1,
          City: selPlace.City,
          State: selPlace.State,
          Zipcode: selPlace.Zipcode,
          FullAddress: selPlace.FullAddress,
          TimezoneId: selPlace.TimezoneId,
          Latitude: selPlace.Latitude,
          Longitude: selPlace.Longitude,
          Country: selPlace.Country,
        },
        Place: selPlace,
      };
      item.Place.RequiresAttention = 0;
      return item;
    } else {
      let selStudentPlace = that.gridData.find(
        x => x.Id == selectedStudentPlaceId,
      );
      if (selStudentPlace) {
        item = {
          Id: selStudentPlace.Id,
          PlaceId: selStudentPlace.PlaceId,
          PlaceScheduleId: data.Id,
          PlaceScheduleName: selPlace.Name,
          PlaceScheduleNickName: selPlace.NickName,
          Type: selStudentPlace.Type,
          Name: selStudentPlace.Name,
          InternalNote: that.reason,
          Location: selStudentPlace.Location,
          IsAddedFromClientPortal: true,
        };
        return item;
      }
    }
    return item;
  }
}
