import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "striphtml",
})
export class StripHtmlPipe implements PipeTransform {
    transform(value: string): any {
        let exchange = /<.*?>/g;
        return value.replace(exchange, ""); // replace tags
  }
}
