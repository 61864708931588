import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { environment } from "environments/environment";
import { RideFinderSearchModel } from "../model/ride-finder-search.model";
import { AuthenticationService } from "app/auth/service";
import { DriverProfile } from "../model/driver-profile.model";
import { RideSheetModel } from "../model/ride-sheet.model";
import { RideMonitorRideWaypointModel } from "../model/ride-monitor-ride-waypoint.model";
import { RideActivityLogModel } from "../model/ride-activity-log.model";
import { HistoricalRidesFilterModel } from "../model/historical-rides-filter.model";
import { RideBlueprintDataSheetModel } from "../model/ride-blueprint-datasheet.model";
@Injectable()
export class HistoricalRidesServices {
  constructor(
    private _httpClient: HttpClient,
    private _authenticationService: AuthenticationService,
  ) {}

  getGridData(filterModel: HistoricalRidesFilterModel) {
    filterModel.clientId =
      this._authenticationService.currentUserValue.client_id;
    return this._httpClient.post<RideFinderSearchModel>(
      `${environment.apiUrl}/rides-finder`,
      filterModel,
    );
  }

  getDriverProfile(id, RideUuid) {
    return this._httpClient.get<DriverProfile>(
      `${environment.apiUrl}/rides/${RideUuid}/driver-profile/${id}`,
    );
  }

  getRideInfo(rideUuid) {
    return this._httpClient.get<RideSheetModel>(
      `${environment.apiUrl}/rides/${rideUuid}/sheet-details`,
    );
  }

  getRideWaypoints(rideUuid) {
    return this._httpClient.get<RideMonitorRideWaypointModel[]>(
      `${environment.apiUrl}/rides/${rideUuid}/waypoints`,
    );
  }

  getRideActivityLog(RideUuid, waypointId) {
    return this._httpClient.get<RideActivityLogModel[]>(
      `${environment.apiUrl}/rides/${RideUuid}/waypoints/${waypointId}/activity-logs`,
    );
  }
  getPassengers() {
    let queryParams = new HttpParams();
    queryParams = queryParams.append(
      "client_id",
      this._authenticationService.currentUserValue.client_id,
    );

    return this._httpClient.get<any>(
      `${environment.apiUrl}/rides-finder/passengers`,
      { params: queryParams },
    );
  }
  getRideBlueprintSheetData(RideUuid) {
    return this._httpClient.get<RideBlueprintDataSheetModel>(
      `${environment.apiUrl}/ride-blueprints/${RideUuid}/data-sheet`,
    );
  }
  getPlaces() {
    let data = {
      ClientId: this._authenticationService.currentUserValue.client_id,
      viewType: "StudentRequestDropdownClient",
    };

    return this._httpClient.post<any>(`${environment.apiUrl}/places-all`, data);
  }
  getBluePrintIds() {
    let data = {
      clientId: this._authenticationService.currentUserValue.client_id,
      viewType: "rideblueprintlistviewdropdown",
    };
    return this._httpClient.patch<any>(
      `${environment.apiUrl}/ride-blueprints`,
      data,
    );
  }
  getGetRideFinderStatistics(filterModel: HistoricalRidesFilterModel) {
    filterModel.clientId =
      this._authenticationService.currentUserValue.client_id;
    return this._httpClient.post<any>(
      `${environment.apiUrl}/rides-finder/statistics`,
      filterModel,
    );
  }
}
