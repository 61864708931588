import { Component, Input } from "@angular/core";
import { IFilters } from "../interfaces/controls/IFiltersConfig";

@Component({
  selector: "app-places",
  templateUrl: "./places.component.html",
})
export class PlacesComponent {
  @Input("filterData") Config: IFilters;
}
