import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AuthenticationService } from "app/auth/service";
import { environment } from "environments/environment";
import { PlaceSearchModel } from "../model/place-search.model";
import { PlaceFiliter } from "../model/Place-filiter.model";

@Injectable({
  providedIn: "root",
})
export class PlacesService {
  constructor(
    private _httpClient: HttpClient,
    private _authenticationService: AuthenticationService,
  ) {}
  getPlaces(filterModel: PlaceFiliter) {
      filterModel.viewType = "clientPlaces";
    filterModel.sort_by = "name";
    filterModel.archived = false;
    filterModel.ClientId =
      this._authenticationService.currentUserValue.client_id;
    return this._httpClient.post<PlaceSearchModel>(
      `${environment.apiUrl}/places-all`,
      filterModel,
    );
  }
}
