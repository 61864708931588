<input
  id="typeahead-focus"
  type="text"
  class="form-control"
  style="word-break: break-word"
  [(ngModel)]="input"
  (input)="onChange($any($event.target).value)"
  [ngbTypeahead]="search"
  (focus)="focus$.next($any($event).target.value)"
  maxlength="{{ maxlength }}"
  [ngClass]="{
    'is-invalid': controlDir?.control?.touched && controlDir.control?.invalid
  }"
  (blur)="onTouched(); blur.emit()"
  [disabled]="disabled"
  [value]="input"
  (ngModelChange)="changes($event)"
  (click)="click$.next($any($event).target.value)"
  #instance="ngbTypeahead"
  placeholder="{{ placeholder }}"
/>
<div
  class="invalid-feedback"
  style="font-size: 10px !important"
>
  This field is required, please select or insert a value.
</div>
