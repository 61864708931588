import { Injectable } from "@angular/core";
import { AuthenticationService } from "../../../auth/service";
import { environment } from "../../../../environments/environment";
import { HttpClient, HttpParams } from "@angular/common/http";
import { DriverProfile } from "app/main/HistoricalRides/model/driver-profile.model";
@Injectable({
  providedIn: "root",
})
export class ActiveRidesService {
  constructor(
    private _httpClient: HttpClient,
    private _authenticationService: AuthenticationService,
  ) {}

  getGridData(periodFilter, passengerFilter, placeFilter) {
    let queryParams = new HttpParams();

    queryParams = queryParams.append("period", periodFilter);
    queryParams = queryParams.append("place_id", placeFilter);
    queryParams = queryParams.append("student_id", passengerFilter);

    return this._httpClient.get<any>(
      `${environment.apiUrl}/rides/client/${this._authenticationService.currentUserValue.client_id}`,
      { params: queryParams },
    );
  }

  getSubGridData(rideUuid) {
    return this._httpClient.get<any>(
      `${environment.apiUrl}/rides/${rideUuid}/waypoints/`,
    );
  }

  getPassengersData() {
    return this._httpClient.get<any>(
      `${environment.apiUrl}/rides/client/${this._authenticationService.currentUserValue.client_id}/students/`,
    );
  }

  getPlacesData() {
    return this._httpClient.get<any>(
      `${environment.apiUrl}/rides/client/${this._authenticationService.currentUserValue.client_id}/places/`,
    );
  }

  cancelationCargo(
    selectedCancelationCargoRideUuid,
    selectedCancelationCargoRideWaypointUuid,
    selectedCancelationCargoUuid,
    interealNote,
    StatusId,
  ) {
    let body = {
      InternalNote: interealNote,
      RideStatusId: StatusId,
      IsNotifyPartner: true,
      IsNotifyFamilyAppUser: false,
      IsFromAdminDashboard: true,
    };
    return this._httpClient.patch<any>(
      `${environment.apiUrl}/rides/${selectedCancelationCargoRideUuid}/waypoints/${selectedCancelationCargoRideWaypointUuid}/cargo/${selectedCancelationCargoUuid}/update-status/`,
      body,
    );
  }

  loadCancelationType(
    selectedCancelationCargoRideUuid,
    selectedCancelationCargoRideWaypointUuid,
    selectedCancelationCargoUuid,
  ) {
    let queryParams = new HttpParams();

    queryParams = queryParams.append("isStudentCancelationPopUp", true);

    return this._httpClient.get<any>(
      `${environment.apiUrl}/rides/${selectedCancelationCargoRideUuid}/waypoints/${selectedCancelationCargoRideWaypointUuid}/cargo/${selectedCancelationCargoUuid}/check-cancel-type`,
      { params: queryParams },
    );
  }
  isStudentCancelationPopUp;

  showRideWaypointActivityLogsModal(rideUuid, waypointId) {
    return this._httpClient.get<any>(
      `${environment.apiUrl}/rides/${rideUuid}/waypoints/${waypointId}/activity-logs/`,
    );
  }

  showRideActivityLogsModal(rideUuid) {
    return this._httpClient.get<any>(
      `${environment.apiUrl}/rides/${rideUuid}/activity-logs`,
    );
  }
  getRideInfo() {
    let body = {
      ClientId: this._authenticationService.currentUserValue.client_id,
      PartnerId: 0,
      Period: "AllDay",
      PlaceId: 0,
      RidesWithNotes: false,
      ScheduledFirstArrivalTime: null,
      ScheduledLastArrivalTime: null,
      ServiceAreaId: 0,
      ServiceDate: "5/24/2023",
      ServiceRegionId: 0,
      Status: "",
      StudentId: 0,
      Limit: 10000,
      offset: 0,
    };

    return this._httpClient.post<any>(
      `${environment.apiUrl}/rides/GetRides`,
      body,
    );
  }
  getDriverProfile(id, RideUuid) {
    return this._httpClient.get<DriverProfile>(
      `${environment.apiUrl}/rides/${RideUuid}/driver-profile/${id}`,
    );
  }

  getRideLocation() {
    let queryParams = new HttpParams();

    queryParams = queryParams.append("is_from_client", true);
    return this._httpClient.get<any>(
      `${environment.apiUrl}/auth/cloud-documents/mobile-custom-token`,
      { params: queryParams },
    );
  }
}
