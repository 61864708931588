import {
  Component,
  Input,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { AuthenticationService } from "app/auth/service";
import { ToastrService } from "ngx-toastr";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { User } from "app/auth/models";
import { AppComponent } from "app/app.component";
import { HelpersService } from "app/main/SharedServices/helpers.service";
import { StudentAddNewTransportationScheduleComponent } from "./StudentAddNewTransportationSchedule/student-add-new-transportation-schedule.component";
import { State } from "@progress/kendo-data-query";
import { ActivatedRoute } from "@angular/router";
import { ClientStudentsService } from "../services/client-students.service";
import { PermissionsList } from "app/enums/Permissions/PermissionList";
@Component({
  selector: "app-transportation-schedule-section",
  templateUrl: "./transportation-schedule-section.component.html",
  styleUrls: ["./transportation-schedule-section.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class TransportationScheduleSectionComponent implements OnInit {
  public _currentUser: User;
  public rowIndex: number = -1;
  @Input("studentId") studentId: number;
  opened = false;
  @Input() gridData: any = [];
  @Input() AddressGridData: any[];
  @Input() PlaceGridData: any[];
  attentionInfo: boolean = false;

  @Input("disable") disable: boolean = true;
  public disabeld: boolean = false;
  public PermessionValue: string =
    PermissionsList.ClientStudentsEdit.toString();
  @ViewChild("studentAddNewScheduleComponent")
  studentAddNewScheduleComponent: StudentAddNewTransportationScheduleComponent;
  state: State = {
    skip: 0,
    take: 25,
    group: [],
    filter: { filters: [], logic: "or" },
    sort: [
      {
        field: "name",
        dir: "asc",
      },
    ],
  };

  /**
   * Constructor
   * * @param {AuthenticationService} _authenticationService
   * * @param {HelpersService} _helpersService
   * * @param {ClientGuardiansService} _clientGurdiansService
   * * @param {NgbModal} _modalService
   * * @param {ToastrService} _toastrService
   * * @param {FormBuilder} _formBuilder
   * * @param {AppComponent} _appcomponent
   */
  constructor(
    private _formBuilder: FormBuilder,
    private _authenticationService: AuthenticationService,
    private _toastrService: ToastrService,
    private _modalService: NgbModal,
    private route: ActivatedRoute,
    private _helperService: HelpersService,
    private _appcomponent: AppComponent,
    private _clientStudentService: ClientStudentsService,
  ) {
    this._authenticationService.currentUser.subscribe(
      x => (this._currentUser = x),
    );
  }

  ngOnInit(): void {
    this.disabeld = !this.disable;
    let that = this;
    if (that.route.snapshot.paramMap.get("id")) {
      that.studentId = parseInt(that.route.snapshot.paramMap.get("id")!);
    }
    if (that.studentId != 0) {
      that.loadStudentTransportationSchedules();
    }
  }

  reloadData(result: any) {
    let that = this;

    if (that.studentId != 0) {
      that.loadStudentTransportationSchedules();
    } else if (this.rowIndex != -1) {
      if (
        this.gridData[this.rowIndex] == undefined ||
        this.gridData[this.rowIndex] == null
      ) {
        this.gridData.push(
          JSON.parse(JSON.stringify(result.data.StudentSchedules[0])),
        );
      } else {
        this.gridData[this.rowIndex] = JSON.parse(
          JSON.stringify(result.data.StudentSchedules[0]),
        );
      }
    } else {
      this.gridData.push(
        JSON.parse(JSON.stringify(result.data.StudentSchedules[0])),
      );
    }
  }

  modalOpenMD(data: any = null, rowIndex?: number) {
    let that = this;
    if (that.AddressGridData.length == 0 || that.PlaceGridData.length == 0) {
      that.attentionInfo = true;
      return false;
    }
    this.studentAddNewScheduleComponent.data = data;
    if (data != null) {
      this.studentAddNewScheduleComponent.data = this.gridData[rowIndex];
    }
    this.rowIndex = rowIndex ?? this.gridData.length;
    this.studentAddNewScheduleComponent.open();
  }

  closeAttentionDialog(model: any) {
    let that = this;
    that.attentionInfo = false;
    model.close("Accept click");
  }

  /*Transportation Schedule*/
  loadStudentTransportationSchedules() {
    let that = this;
    that._appcomponent.toggeleLoader();
    that._clientStudentService
      .getStudentTransportationSchedules(that.studentId)
      .subscribe(
        (Response: any) => {
          that.gridData = Response;
          that._appcomponent.toggeleLoader();
        },
        error => {
          if (error.status == 401) {
            that._toastrService.error("UnAuthorized.", "");
            that._appcomponent.toggeleLoader();
          } else {
            that._toastrService.error(
              "The transportation schedule was not loaded due to a technical issue.",
              "",
            );
            that._appcomponent.toggeleLoader();
          }
        },
      );
  }
}
