import {
  Component,
  Input,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { State } from "@progress/kendo-data-query";
import { AppComponent } from "app/app.component";
import { User } from "app/auth/models";
import { AuthenticationService } from "app/auth/service";
import { HelpersService } from "app/main/SharedServices/helpers.service";
import { ToastrService } from "ngx-toastr";
import { IForm } from "../model/AddEditStudentDataModel";
import { ClientStudentsService } from "../services/client-students.service";
import { StudentAddNewHomeAddressComponent } from "./StudentAddNewHomeAddress/student-add-new-home-address.component";
import { StudentAddPlaceComponent } from "./StudentAddNewPlace/student-add-new-place.component";
const { compile } = require("html-to-text"); //https://www.npmjs.com/package/html-to-text
const options = {};
const compiledConvert = compile(options); // options passed here

@Component({
  selector: "app-address-student-section",
  templateUrl: "./address-student-section.component.html",
  styleUrls: ["./address-student-section.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class AddressStudentSectionComponent implements OnInit {
  public _currentUser: User;
  opened = false;
  public rowIndex: number = -1;
  isNewPlace = false;
  public selectedData: any = {};
  public openedDelete: boolean = false;
  public openedDeleteDenied: boolean = false;
  public openedDeleteStudentPlace: boolean = false;
  public openedRemoveConfirmationStudentPlace: boolean = false;

  public deleteStudentPlaceContent: any = { Title: "", Body: "" };

  public deleteAddressStudentData: FormGroup;
  public deleteStudentPlace: FormGroup;

  @Input("studentId") studentId: number;
  @ViewChild("studentaddHomeAddressComponent")
  addNewGuardianComponent: StudentAddNewHomeAddressComponent;
  @ViewChild("studentaddPlaceComponent")
  addNewPlaceComponent: StudentAddPlaceComponent;
  @Input() AddressGridData: any = [];
  @Input() PlaceGridData: any = [];
  @Input("disable") disable: boolean = true;
  public disabeld: boolean = false;
  state: State = {
    skip: 0,
    take: 25,
    group: [],
    filter: { filters: [], logic: "or" },
    sort: [
      {
        field: "name",
        dir: "asc",
      },
    ],
  };
  /**
   * Constructor
   * * @param {AuthenticationService} _authenticationService
   * * @param {HelpersService} _helpersService
   * * @param {ClientGuardiansService} _clientGurdiansService
   * * @param {NgbModal} _modalService
   * * @param {ToastrService} _toastrService
   * * @param {FormBuilder} _formBuilder
   * * @param {AppComponent} _appcomponent
   */
  constructor(
    private _formBuilder: FormBuilder,
    private _authenticationService: AuthenticationService,
    private _toastrService: ToastrService,
    private _modalService: NgbModal,
    private _helperService: HelpersService,
    private _appcomponent: AppComponent,
    private route: ActivatedRoute,
    private _clientStudentService: ClientStudentsService,
  ) {
    this._authenticationService.currentUser.subscribe(
      x => (this._currentUser = x),
    );
  }

  ngOnInit(): void {
    this.disabeld = !this.disable;
    let that = this;
    if (that.route.snapshot.paramMap.get("id")) {
      that.studentId = parseInt(that.route.snapshot.paramMap.get("id")!);
    }
    let data: IForm<any> = {
      InternalNote: ["", [Validators.required]],
    };
    that.deleteAddressStudentData = that._formBuilder.group(data);
    that.deleteStudentPlace = that._formBuilder.group(data);
    if (that.studentId != 0) {
      that.loadStudentAddresses();
      that.loadStudentPlaces();
    }
  }

  reloadData(result: any) {
    let that = this;
    if (that.studentId != 0) {
      that.loadStudentAddresses();
    } else {
      if (that.rowIndex != -1) {
        if (
          that.AddressGridData[that.rowIndex] == undefined ||
          that.AddressGridData[that.rowIndex] == null
        ) {
          that.AddressGridData.push(JSON.parse(JSON.stringify(result.data)));
        } else {
          that.AddressGridData[that.rowIndex] = JSON.parse(
            JSON.stringify(result.data),
          );
        }
      } else {
        that.AddressGridData.push(JSON.parse(JSON.stringify(result.data)));
      }
      if ( result?.data) {
        sessionStorage.setItem("Addresses", JSON.stringify(result.data));
      }
    }
  }

  reloadPlaceData(result: any) {
    let that = this;
    if (that.studentId != 0) {
      that.loadStudentPlaces();
    } else {
      if (that.rowIndex != -1) {
        if (
          that.PlaceGridData[that.rowIndex] == undefined ||
          that.PlaceGridData[that.rowIndex] == null
        ) {
          that.PlaceGridData.push(JSON.parse(JSON.stringify(result.data)));
        } else {
          that.PlaceGridData[that.rowIndex] = JSON.parse(
            JSON.stringify(result.data),
          );
        }
      } else {
        that.PlaceGridData.push(JSON.parse(JSON.stringify(result.data)));
      }
      if ( result?.data) {
        sessionStorage.setItem("Places", JSON.stringify(result.data));
      }
    }
  }

  modalOpenMD(data: any = null, rowIndex?: any) {
    let that = this;
    that.opened = true;
    that.addNewGuardianComponent.data = data;
    if (data != null) {
      that.addNewGuardianComponent.data = that.AddressGridData[rowIndex];
    }
    that.rowIndex = rowIndex ?? that.AddressGridData.length;
    that.addNewGuardianComponent.open();
  }

  modalOpenPlace(data: any = null, rowIndex?: any) {
    let that = this;
    that.opened = true;
    that.addNewPlaceComponent.data = data;
    if (data != null) {
      that.addNewPlaceComponent.data = that.PlaceGridData[rowIndex];
    }
    that.rowIndex = rowIndex ?? that.PlaceGridData.length;
    that.addNewPlaceComponent.open();
  }

  deleteAddress(data: any, rowIndex: number = 0) {
      let that = this;
    if (!data.Id || that.studentId == 0) {
      that.AddressGridData.splice(rowIndex, 1);
    } else {
      //unlink by api on edit Student
      that.selectedData.Data = data;
      that.selectedData.RowIndex = rowIndex;
      that.checkAllowDelete();
    }
  }

  checkAllowDelete() {
    let that = this;
    that._appcomponent.toggeleLoader();

    that._clientStudentService
      .checkAllowDeleteAddress(
        that.selectedData.Data.ParentId,
        that.selectedData.Data.Id,
      )
      .subscribe(
        (data: any) => {
          if (data.result == "Confirm") {
            that.openedDelete = true;
            that.deleteAddressStudentData.reset();
          } else {
            that.openedDeleteDenied = true;
          }
          that._appcomponent.toggeleLoader();
        },
        error => {
          if (error.status == 401) {
            that._toastrService.error("UnAuthorized", "");
          } else if (error.status == 403) {
            that._toastrService.error(error.error.Message, "");
          } else if (error.status == 500) {
            that._toastrService.error(
              "The contact was not deleted due to a technical issue.",
              "",
            );
          } else {
            that._toastrService.error("Server internal error", "");
          }
          that._appcomponent.toggeleLoader();
        },
      );
  }

  close(status: string): void {
    let that = this;
    if (status == "deleted") {
      that.openedDelete = false;
      that.selectedData = {};
    } else if (status == "denied") {
      that.openedDeleteDenied = false;
      that.selectedData = {};
    } else if (status == "DeleteStudentPlace") {
      that.openedDeleteStudentPlace = false;
      that.selectedData = {};
    } else if (status == "ConfirmationStudentPlace") {
      that.openedRemoveConfirmationStudentPlace = false;
      that.selectedData = {};
      that.deleteStudentPlace.reset();
    }
  }

  confirmDeleteAddress() {
      let that = this;
    that.openedDelete = false;
    if (!that.isValidForm()) {
      that._appcomponent.toggeleLoader();
      let data = {
        InternalNote: this.deleteAddressStudentData.value.InternalNote,
        IsDeletedFromClientPortal: true,
      };
      that._clientStudentService
        .deleteStudentAddress(that.studentId, that.selectedData.Data.Id, data)
        .subscribe(
          (data: any) => {
            that.AddressGridData.splice(that.selectedData.RowIndex, 1);
            that._toastrService.success(
              "The address was deleted successfully.",
              "",
            );
            that._appcomponent.toggeleLoader();
            that.loadStudentAddresses();
          },
          error => {
            if (error.status == 401) {
              that._toastrService.error("UnAuthorized", "");
            } else if (error.status == 403) {
              that._toastrService.error(error.error.Message, "");
            } else if (error.status == 500) {
              that._toastrService.error(
                "The address was not deleted due to a technical issue.",
                "",
              );
            } else {
              that._toastrService.error("Server internal error", "");
            }
            that._appcomponent.toggeleLoader();
          },
        );
    }
  }

  public isValidForm(): boolean {
      let that = this;
    for (const control of Object.keys(that.deleteAddressStudentData.controls)) {
      that.deleteAddressStudentData.controls[control].markAsTouched();
    }
    return that.deleteAddressStudentData.invalid;
  }

  getCalendar(dataItem) {
    let result: string = "";
    if (
      (dataItem.PlaceScheduleName != "" &&
        dataItem.PlaceScheduleName != null) || (dataItem.PlaceScheduleNickName != "" &&
            dataItem.PlaceScheduleNickName != null)
    ) {
        result = this.getCalendarName(dataItem);
    } 
    return result;
  }
    getCalendarName(dataItem) {
        return dataItem.PlaceScheduleNickName && dataItem.PlaceScheduleNickName != ""
                ? "(" + dataItem.PlaceScheduleNickName + ")"
                : "";
    }
  openDeleteStudentPlaceAlert(item, rowIndex) {
    let that = this;
    that.selectedData.Data = item;
    that.selectedData.RowIndex = rowIndex;
    if (that.studentId != 0) {
      that._appcomponent.toggeleLoader();
      that._clientStudentService
        .checkAllowDeleteAddress(item.Id, item.Id)
        .subscribe(
          (data: any) => {
            if (data.result == "Confirm") {
              that.deleteStudentPlaceContent.Content = item.Name;
              that.openedRemoveConfirmationStudentPlace = true;
            } else if (data.result == "linkedToRideBlueprint") {
              that.deleteStudentPlaceContent.Title = "Remove Denied";
              that.deleteStudentPlaceContent.Content =
                "This place cannot be removed. This place is currently linked to at least one Ride Blueprint";
              that.openedDeleteStudentPlace = true;
            } else if (data.result == "linkedToSchedule") {
              that.deleteStudentPlaceContent.Title = "Remove Denied";
              that.deleteStudentPlaceContent.Content =
                "This place cannot be removed. This place is currently linked to at least one Transportation Schedule. If you wish to remove this place, you must do one of the following first:";
              that.deleteStudentPlaceContent.Step1 =
                "1. Change the place of the Transportation Schedules.";
              that.deleteStudentPlaceContent.Step2 =
                "2. Archive the Transportation Schedules";
              that.openedDeleteStudentPlace = true;
            }
            that._appcomponent.toggeleLoader();
          },
          error => {
            if (error.status == 401) {
              that._toastrService.error("UnAuthorized", "");
            } else if (error.status == 403) {
              that._toastrService.error(error.error.Message, "");
            } else if (error.status == 500) {
              that._toastrService.error(
                "The contact was not deleted due to a technical issue.",
                "",
              );
            } else {
              that._toastrService.error("Server internal error", "");
            }
            that._appcomponent.toggeleLoader();
          },
        );
    } else {
      that.PlaceGridData.splice(rowIndex, 1);
      that._toastrService.success("The place was removed successfully.", "");
    }
  }

  confirmDeleteStudentPlaceByPlaceId() {
    let that = this;
    that._appcomponent.toggeleLoader();
    let data = {
      InternalNote: this.deleteStudentPlace.value.InternalNote,
      IsDeletedFromClientPortal: true,
    };
    that._clientStudentService
      .deleteStudentAddress(that.studentId, that.selectedData.Data.Id, data)
      .subscribe(
        (data: any) => {
          that.PlaceGridData.splice(that.selectedData.RowIndex, 1);
          that._toastrService.success(
            "The place was removed successfully.",
            "",
          );
          that.close("ConfirmationStudentPlace");
          that._appcomponent.toggeleLoader();
          that.loadStudentPlaces();
        },
        error => {
          if (error.status == 401) {
            that._toastrService.error("UnAuthorized", "");
          } else if (error.status == 403) {
            that._toastrService.error(error.error.Message, "");
          } else if (error.status == 500) {
            that._toastrService.error(
              "The place was not deleted due to a technical issue.",
              "",
            );
          } else {
            that._toastrService.error("Server internal error", "");
          }
          that._appcomponent.toggeleLoader();
        },
      );
  }

  /*Home Address(es)*/
  loadStudentAddresses() {
    let that = this;
    that._appcomponent.toggeleLoader();
    that._clientStudentService.getStudentAddresses(that.studentId).subscribe(
      (Response: any) => {
        that.AddressGridData = JSON.parse(JSON.stringify(Response));
        sessionStorage.setItem("Addresses", JSON.stringify(Response));
        that._appcomponent.toggeleLoader();
      },
      error => {
        if (error.status == 401) {
          that._toastrService.error("UnAuthorized.", "");
          that._appcomponent.toggeleLoader();
        } else {
          that._toastrService.error(
            "The addresses was not loaded due to a technical issue.",
            "",
          );
          that._appcomponent.toggeleLoader();
        }
      },
    );
  }

  /*Student Places*/
  loadStudentPlaces() {
    let that = this;
    that._appcomponent.toggeleLoader();
    that._clientStudentService.getStudentPlaces(that.studentId).subscribe(
      (Response: any) => {
        Response.forEach(a => {
          if (a.Place) {
            a.Status = a.Place.Status;
            a.RequiresAttention = a.Place.RequiresAttention;
          }
        });
        that.PlaceGridData = JSON.parse(JSON.stringify(Response));
        sessionStorage.setItem("Places", JSON.stringify(Response));
        that._appcomponent.toggeleLoader();
      },
      error => {
        if (error.status == 401) {
          that._toastrService.error("UnAuthorized.", "");
          that._appcomponent.toggeleLoader();
        } else {
          that._toastrService.error(
            "The places was not loaded due to a technical issue.",
            "",
          );
          that._appcomponent.toggeleLoader();
        }
      },
    );
  }
}
