<div
  id="actionBar"
  class="d-flex align-items-end bg-white"
>
  <div class="container-fluid">
    <div class="d-flex w-100 actionBar-content align-items-end">
      <div class="flex-fill">
        <div class="sub-title pb-0">Places</div>
      </div>
    </div>
  </div>
</div>

<br />

<div class="content-wrapper container-fluid">
  <div class="content-body">
    <div>
      <div class="card">
        <app-collection-filters
          (search)="getGridData()"
          (clearSearch)="getGridData()"
          [filters]="FiltersConfig"
        >
        </app-collection-filters>

        <div class="card-body">
          <kendo-grid
            #gridActiveRide
            [kendoGridBinding]="gridView"
            [sortable]="true"
            [pageSize]="pagesize"
            #dataGridVar
          >
            <ng-template
              kendoGridToolbarTemplate
              NgClass="p-0"
            >
              <div class="col-lg-12 toolbar-control">
                <div class="row">
                  <div class="col-md-6 col-12 pb-0">
                    <div
                      class="d-flex justify-content-between align-items-center"
                    >
                      <label class="d-flex align-items-center">
                        Show
                        <select
                          (change)="changeGridIndex()"
                          [(ngModel)]="gridIndex"
                          class="form-control mx-25 form-control-sm ng-pristine ng-valid ng-touched"
                        >
                          <option value="5">5</option>
                          <option value="10">10</option>
                          <option value="25">25</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                        </select>
                        entries
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <kendo-grid-spacer></kendo-grid-spacer>
            </ng-template>

            <kendo-grid-column
              [width]="50"
              field="id"
              [sortable]="false"
              title="#"
            >
              <ng-template
                kendoGridCellTemplate
                let-rowIndex="rowIndex+1"
              >
                {{ rowIndex }}
              </ng-template>
            </kendo-grid-column>

            <kendo-grid-column
              [sortable]="false"
              field="Name"
              title="Name"
              [width]="400"
            >
            </kendo-grid-column>

            <kendo-grid-column
              [sortable]="false"
              field="Type"
              title="Type"
              [width]="200"
            >
            </kendo-grid-column>

            <kendo-grid-column
              [sortable]="false"
              field="FullAddress"
              title="Address"
              [width]="500"
            >
            </kendo-grid-column>

            <kendo-grid-column
              [sortable]="false"
              field="Status"
              title="Status"
              [width]="100"
            >
              <ng-template
                kendoGridCellTemplate
                let-dataItem
                let-rowIndex="rowIndex"
              >
                <span class="badge badge-success">
                  {{ dataItem.Status }}
                </span>
              </ng-template>
            </kendo-grid-column>
          </kendo-grid>

          <div class="col-lg-12 text-center Load-More-section">
            <button
              [disabled]=""
              class="btn btn-light btn-Load-More btn-sm"
              (click)="viewMore()"
            >
              load more
            </button>

            <div class="page-count ng-star-inserted">
              {{ pagesize >= gridView.length ? gridView.length : pagesize }}
              of {{ gridView.length }} record
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
