export class StudentServiceCancelationModel {
  ApplyTo: string;
  CreatedByAccount: string;
  EffectiveDate: string;
  Id: number;
  InternalNote: string;
  RecurrenceDay: string;
  RecurrenceFrequency: string;
  StudentId: number;
  TypeId: number = 4;
}
