<form [formGroup]="studentTransportationDetailForm">
  <div class="row section-div">
    <div class="col-lg-6">
      <strong id="monitor_requiremnt_title">Monitor Requirements</strong>
      <div
        class="row"
        style="padding-top: 17px"
      >
        <div class="col-lg-12">
          <label class="checkboxLabel cui-utils-control-checkbox">
            ADROIT monitor required during ride(s).<input
              type="checkbox"
              name="NeedsPlatformProvidedAttendant"
              formControlName="NeedsPlatformProvidedAttendant"
              (change)="adroitMonitor()"
              style="display: none"
              [disabledd]="PermessionValue"
            />
            <span class="custom-checkbox"></span>
          </label>
        </div>
        <div class="col-lg-12">
          <label class="checkboxLabel cui-utils-control-checkbox">
            Client monitor required during ride(s).<input
              type="checkbox"
              id="NeedsClientProvidedAttendant"
              formControlName="NeedsClientProvidedAttendant"
              (change)="clientMonitor()"
              value="NeedsPlatformProvidedAttendant"
              style="display: none"
              [disabledd]="PermessionValue"
            />
            <span class="custom-checkbox"></span>
          </label>
        </div>
        <div class="col-lg-6 fieldsSRS">
          <label>Preferred Monitor Gender</label>
          <ng-select
            appendTo="body"
            [(ngModel)]="Config.Filters[0].filterValue"
            [clearable]="Config.Filters[0].filterClear"
            [disabledd]="PermessionValue"
            [placeholder]="Config.Filters[0].filterPlaceholder"
            formControlName="preferredMonitorGender"
          >
            <ng-option
              *ngFor="let item of Config.Filters[0].filterValues"
              [value]="item.id"
              >{{ item.name }}</ng-option
            >
          </ng-select>
        </div>
        <div class="col-lg-6 fieldsSRS">
          <label>Client Monitor</label>

          <ng-select
            appendTo="body"
            [(ngModel)]="Config.Filters[1].filterValue"
            [clearable]="Config.Filters[1].filterClear"
            [disabledd]="PermessionValue"
            [placeholder]="Config.Filters[1].filterPlaceholder"
            formControlName="ClientMonitor"
          >
            <ng-option
              *ngFor="let item of Config.Filters[1].filterValues"
              [value]="item.id"
              >{{ item.name }}</ng-option
            >
          </ng-select>
        </div>
      </div>
    </div>
    <div class="col-lg-6">
      <strong id="monitor_requiremnt_title">Transportation Requirements</strong>
      <div
        class="row"
        style="padding-top: 17px"
      >
        <div class="col-lg-12">
          <label class="checkboxLabel cui-utils-control-checkbox">
            Solo rider. The student shouldn’t be transported with other
            students.<input
              type="checkbox"
              id="IsSoloRider"
              name="IsSoloRider"
              formControlName="IsSoloRider"
              style="display: none"
              [disabledd]="PermessionValue"
            />
            <span class="custom-checkbox"></span>
          </label>
        </div>
        <div class="col-lg-12">
          <label class="checkboxLabel cui-utils-control-checkbox">
            Wheelchair vehicle required.<input
              type="checkbox"
              id="NeedsWheelchairVehicle"
              formControlName="NeedsWheelchairVehicle"
              value="NeedsWheelchairVehicle"
              style="display: none"
              [disabledd]="PermessionValue"
            />
            <span class="custom-checkbox"></span>
          </label>
        </div>
        <div class="col-lg-12 fieldsSRS">
          <label>Equipment Needed</label>
          <ng-select
            appendTo="body"
            [(ngModel)]="Config.Filters[2].filterValue"
            [clearable]="Config.Filters[2].filterClear"
            [disabledd]="PermessionValue"
            [placeholder]="Config.Filters[2].filterPlaceholder"
            [multiple]="Config.Filters[2].filterMultiple"
            formControlName="RideNeeds"
            Id="Equipment"
          >
            <ng-option
              *ngFor="let item of Config.Filters[2].filterValues"
              [value]="item.Id"
              >{{ item.Name }}</ng-option
            >
          </ng-select>
        </div>
      </div>
    </div>
  </div>
</form>
