import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { IFilters } from "../interfaces/controls/IFiltersConfig";

@Component({
  selector: "app-grade",
  templateUrl: "./grade.component.html",
  styleUrls: ["./grade.component.scss"],
})
export class GradeComponent implements OnInit {
  @Input("filterData") Config: IFilters;
  @Output() changeValue = new EventEmitter<string>();

  ngOnInit(): void {
    this.Config.filterValues = [
      { id: "Pre-KG", name: "Pre-KG" },
      { id: "1st Grade", name: "1st Grade" },
      { id: "2nd Grade", name: "2nd Grade" },
      { id: "3rd Grade", name: "3rd Grade" },
      { id: "4th Grade", name: "4th Grade" },
      { id: "5th Grade", name: "5th Grade" },
      { id: "6th Grade", name: "6th Grade" },
      { id: "7th Grade", name: "7th Grade" },
      { id: "8th Grade", name: "8th Grade" },
      { id: "9th Grade", name: "9th Grade" },
      { id: "10th Grade", name: "10th Grade" },
      { id: "11th Grade", name: "11th Grade" },
      { id: "12th Grade", name: "12th Grade" },
      { id: "12th + Grade", name: "12th + Grade" },
      { id: "Transitional KG", name: "Transitional KG" },
    ];
  }
  Onchange() {
    this.changeValue.emit("complete");
  }
}
