import {
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { GridDataResult } from "@progress/kendo-angular-grid";
import { TabPosition } from "@progress/kendo-angular-layout";
import { AppComponent } from "app/app.component";
import { AuthenticationService } from "app/auth/service";
import { WhiteSpace } from "app/utilities/Validators/white-space.validator";
import { environment } from "environments/environment";
import moment from "moment";
import { ToastrService } from "ngx-toastr";
import { PersonalPhotoComponent } from "../../../controls/component/photo/personal-photo/personal-photo.component";
import {
  AddEditStudentDataFormModel,
  IForm,
} from "../model/AddEditStudentDataFormModel";
import { AddEditStudentDataModel } from "../model/AddEditStudentDataModel";
import { StudentTransportationDetailsModel } from "../model/StudentTransportationDetailsModel";
import { ClientStudentsService } from "../services/client-students.service";
import { StudentSpecialRequirementsSectionComponent } from "../StudentSpecialRequirementsSection/student-special-requirements-section.component";
import { PermissionsList } from "app/enums/Permissions/PermissionList";

@Component({
  selector: "app-add-edit-student",
  templateUrl: "./add-edit-student.component.html",
  styleUrls: ["./add-edit-student.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class AddEditStudentComponent implements OnInit, OnDestroy {
  //#region  Properities
  public PageTitle = "Add New Student";

  @ViewChild("PersonalPhotoComponent")
  personalPhotoComponent: PersonalPhotoComponent;
  @ViewChild("RequirementsSectionComponent")
  requirementsSectionComponent: StudentSpecialRequirementsSectionComponent;

  public SchoolsGridData: GridDataResult = { data: [], total: 0 };
  public UsersGridData: GridDataResult = { data: [], total: 0 };
  public schoolButton: boolean;
  public isLoadTransportationData = false;
  public usersButton: boolean;
  public indexThreeIsLoaded: boolean = false;
  public addressButton: boolean = true;
  public showAccessibleSchoolsCount: number = 0;
  public position: TabPosition = "top";
  public entityType = "student";
  public folder = "student";
  public type = "Student Profile Photo";
  public title = "Edit Student Photo";

  initialDate: Date;

  public GuardianGridData: any = [];
  public ContactGridData: any = [];
  public AddressGridData: any = [];
  public PlaceGridData: any = [];
  public ScheduleGridData: any = [];
  public InstructionsGridData: any = [];
  public UpcomingServiceCancelationGridData: any = [];
  public RecurrentServiceCancelationGridData: any = [];
  public PastServiceCancelationGridData: any = [];
  public TransportationData: StudentTransportationDetailsModel =
    new StudentTransportationDetailsModel();
  public AttachmentGridData: any = [];
  public studentGradeId: number;
  public currentStudentData: any;
  populationTypes: any = [];
  specialEdCategories: any = [];
  addEditStudentModel: AddEditStudentDataModel = new AddEditStudentDataModel();
  Languages = [
    { Id: "en", Name: "English" },
    { Id: "ar", Name: "Arabic" },
    { Id: "bn", Name: "Bengali" },
    { Id: "cantonese", Name: "Cantonese" },
    { Id: "fa", Name: "Farsi" },
    { Id: "fr", Name: "French" },
    { Id: "de", Name: "German" },
    { Id: "el", Name: "Greek" },
    { Id: "hi", Name: "Hindi" },
    { Id: "it", Name: "Italian" },
    { Id: "ko", Name: "Japanese" },
    { Id: "de", Name: "Korean" },
    { Id: "mandarin", Name: "Mandarin" },
    { Id: "pt", Name: "Portuguese" },
    { Id: "ru", Name: "Russian" },
    { Id: "es", Name: "Spanish" },
    { Id: "th", Name: "Thai" },
    { Id: "tr", Name: "Turkish" },
    { Id: "vi", Name: "Vietnamese" },
    { Id: "Other", Name: "Other" },
  ];
  grades: any = [];
  genders = [
    { id: "Female", name: "Female" },
    { id: "Male", name: "Male" },
    { id: "None binary", name: "None binary" },
    { id: "Prefer not to say", name: "Prefer not to say" },
  ];
  AddEditStudentData: FormGroup;
  public disabled: boolean = true;
  //#endregion

  //#region TabsButtons
  setSchoolButton() {
    this.usersButton = false;
    this.addressButton = false;
    this.schoolButton = true;
  }

  setUsersButton() {
    this.schoolButton = false;
    this.addressButton = false;
    this.usersButton = true;
  }

  hideButton() {
    this.usersButton = false;
    this.schoolButton = false;
    this.addressButton = true;
  }

  //#endregion

  //#region Districts Form properites
  get PayingClient() {
    return this.AddEditStudentData.get("PayingClient")!;
  }
  get FirstName() {
    return this.AddEditStudentData.get("FirstName")!;
  }
  get LastName() {
    return this.AddEditStudentData.get("LastName")!;
  }
  get MiddleName() {
    return this.AddEditStudentData.get("MiddleName")!;
  }
  get NickName() {
    return this.AddEditStudentData.get("NickName")!;
  }
  get DateOfBirth() {
    return this.AddEditStudentData.get("DateOfBirth")!;
  }
  get Gender() {
    return this.AddEditStudentData.get("Gender")!;
  }
  get Language() {
    return this.AddEditStudentData.get("Language")!;
  }
  get Weight() {
    return this.AddEditStudentData.get("Weight")!;
  }
  get Height() {
    return this.AddEditStudentData.get("Height")!;
  }
  get SchoolGrade() {
    return this.AddEditStudentData.get("SchoolGrade")!;
  }
  get PopulationType() {
    return this.AddEditStudentData.get("PopulationType")!;
  }

  get SpecialEdCategories() {
    return this.AddEditStudentData.get("SpecialEdCategories")!;
  }
  get HealthConcernsAndComments() {
    return this.AddEditStudentData.get("HealthConcernsAndComments")!;
  }
  get InternalNote() {
    return this.AddEditStudentData.get("InternalNote")!;
  }
  get Age() {
    return this.AddEditStudentData.get("Age")!;
  }
  //#endregion

  studentId: number = 0;
  //#region  constructor & ngOnInit
  constructor(
    private modalService: NgbModal,
    private fb: FormBuilder,
    private _appComponent: AppComponent,
    private _router: Router,
    private route: ActivatedRoute,
    private _authenticationService: AuthenticationService,
    private _clientStudentService: ClientStudentsService,

    private _toastrService: ToastrService,
  ) {
    this._appComponent._mainpageTitle = "Student";
  }

  public saveButtonName :string = "Save New Student";
  public DistrictId: number = 0;
  public PermessionValue: boolean;
  PermessionValues: string;
  ngOnInit(): void {
    if (this.route.snapshot.paramMap.get("id")) {
      this.saveButtonName = "Save Student";
      this.PermessionValue = !this._authenticationService.checkaccess(
        PermissionsList[PermissionsList.ClientStudentsEdit],
      );
      this.disabled = this._authenticationService.checkaccess(
        PermissionsList[PermissionsList.ClientStudentsEdit],
      );
      this.PermessionValues = PermissionsList.ClientStudentsEdit.toString();
    } else {
      this.PermessionValue = !this._authenticationService.checkaccess(
        PermissionsList[PermissionsList.ClientStudentsCreate],
      );
      this.disabled = this._authenticationService.checkaccess(
        PermissionsList[PermissionsList.ClientStudentsCreate],
      );
      this.PermessionValues = PermissionsList.ClientStudentsCreate.toString();
    }

    let that = this;
    this.getPopulationTypes();
    this.getSpecialEdCategories();
    this.getschoolGrades();
    let AddEditStudentData: IForm<AddEditStudentDataFormModel>;
    if (this.route.snapshot.paramMap.get("id")) {
      let id = parseInt(this.route.snapshot.paramMap.get("id")!);
      this.studentId = id;
      if (id) {
        this.DistrictId = id;
        this.PageTitle = "Edit Student";
        AddEditStudentData = {
          FirstName: ["", [Validators.required, WhiteSpace.noSpaceAllowed]],
          MiddleName: [],
          LastName: ["", [Validators.required, WhiteSpace.noSpaceAllowed]],
          NickName: [],
          DateOfBirth: ["", [Validators.required]],
          Gender: ["", [Validators.required]],
          Language: ["", [Validators.required]],
          SchoolGrade: ["", [Validators.required]],
          Weight: [],
          Height: [],
          PopulationType: ["", [Validators.required]],
          SpecialEdCategories: [],
          HealthConcernsAndComments: [],
          InternalNote: [],
          Age: [],
        };
        this.AddEditStudentData = this.fb.group(AddEditStudentData);

        that._appComponent.toggeleLoader();
        that.loadStudent();
        that.loadStudentGuardians();
        that.loadContacts();
        that.loadStudentAddresses();
        that.loadStudentPlaces();
        that.LoadUpcomingServiceCancelations();
        that.LoadRecurrentServiceCancelations();
        that.LoadPastServiceCancelations();
        that.loadInstructions();
        that.loadAttachments();
        that.loadStudentTransportationSchedules();
      } else {
        this._router.navigate(["/customer-success/students"]);
      }
    } else {
      AddEditStudentData = {
        FirstName: ["", [Validators.required, WhiteSpace.noSpaceAllowed]],
        MiddleName: [],
        LastName: ["", [Validators.required, WhiteSpace.noSpaceAllowed]],
        NickName: [],
        DateOfBirth: ["", [Validators.required]],
        Gender: ["", [Validators.required]],
        Language: ["", [Validators.required]],
        SchoolGrade: ["", [Validators.required]],
        Weight: [],
        Height: [],
        PopulationType: ["", [Validators.required]],
        SpecialEdCategories: [],
        HealthConcernsAndComments: [],
        InternalNote: [],
        Age: [],
      };
      this.AddEditStudentData = this.fb.group(AddEditStudentData);
    }
  }

  //#endregion

  //#endregion
  public isValidForm(): boolean {
    let that = this;
    for (const control of Object.keys(that.AddEditStudentData.controls)) {
      that.AddEditStudentData.controls[control].markAsTouched();
    }

    return that.AddEditStudentData.invalid;
  }

  onSubmit() {
    let that = this;
    if (!that.isValidForm()) {
      that._appComponent.toggeleLoader();
      that._clientStudentService.addEditStudent(that.studentData()).subscribe(
        (Response: any) => {
          if (Response.IsFails) {
            that._toastrService.warning(
              "Adding students is not allowed yet. Please Contact ADROIT Team to complete your contract information.",
              "",
            );
            that._appComponent.toggeleLoader();
          } else {
            that._toastrService.success(
              "The student was added successfully.",
              "",
            );
            if (environment.type == "Development")
              window.location.href = "/customer-success/students";
            else window.location.href = "/customer-success/students";

            that._appComponent.toggeleLoader();
          }
        },
        error => {
          if (error.status == 401) {
            that._toastrService.error("UnAuthorized.", "");
            window.location.href = "/pages/authentication/login";
            that._appComponent.toggeleLoader();
          } else {
            that._toastrService.error(
              "The student was not  added due to technical issue.",
              "",
            );
            that._appComponent.toggeleLoader();
          }
        },
      );
    }
  }

  getPopulationTypes() {
    this._clientStudentService.getPopulationTypes().subscribe(
      Response => {
        let populationTypesData = [];
        Response.forEach(function (value) {
          let data = {
            Id: value.Id,
            Name: value.Name,
          };
          populationTypesData.push(data);
        });
        this.populationTypes = populationTypesData;
      },
      error => {
        if (error.status == 401) {
          this._toastrService.error("UnAuthorized.", "");
          window.location.href = "/pages/authentication/login";
        } else {
          this._toastrService.error(error.error.Message, "");
        }
      },
    );
  }

  getSpecialEdCategories() {
    this._clientStudentService.getSpecialEdCategories().subscribe(
      Response => {
        let SpecialEdCategoriesdata = [];
        Response.forEach(function (value) {
          let data = {
            Id: value.Id,
            Name: value.Name,
          };
          SpecialEdCategoriesdata.push(data);
        });
        this.specialEdCategories = SpecialEdCategoriesdata;
      },
      error => {
        if (error.status == 401) {
          this._toastrService.error("UnAuthorized.", "");
          window.location.href = "/pages/authentication/login";
        } else {
          this._toastrService.error(error.error.Message, "");
        }
      },
    );
  }

  getschoolGrades() {
    this._clientStudentService.getschoolGrades().subscribe(
      Response => {
        let schoolGrades = [];
        Response.forEach(function (value) {
          let data = {
            Id: value.Id,
            Name: value.Name,
          };
          schoolGrades.push(data);
        });
        this.grades = schoolGrades;
        if (this.currentStudentData != undefined) {
          this.assignStudentValue(this.currentStudentData);
        }
      },
      error => {
        if (error.status == 401) {
          this._toastrService.error("UnAuthorized.", "");
          window.location.href = "/pages/authentication/login";
        } else {
          this._toastrService.error(error.error.Message, "");
        }
      },
    );
  }

  ChangeAgeValue() {
    let date = new Date(this.AddEditStudentData.get("DateOfBirth").value);
    if (date.getFullYear() <= new Date().getFullYear())
      this.AddEditStudentData.get("Age").patchValue(
        Math.abs(date.getFullYear() - new Date().getFullYear()),
      );
  }

  onClose() {
    sessionStorage.removeItem("Addresses");
    sessionStorage.removeItem("Places");
    this._router.navigate(["/customer-success/students"]);
  }

  studentData() {
    let that = this;
    if (that.AddEditStudentData.value.PopulationType) {
      that.addEditStudentModel.PopulationTypes = [];
      that.AddEditStudentData.value.PopulationType.forEach(element => {
        that.addEditStudentModel.PopulationTypes.push(
          that.populationTypes.filter(x => x.Id == element)[0],
        );
      });
    }

    if (that.AddEditStudentData.value.SpecialEdCategories) {
      that.addEditStudentModel.SpecialEdCategories = [];
      that.AddEditStudentData.value.SpecialEdCategories.forEach(element => {
        that.addEditStudentModel.SpecialEdCategories.push(
          that.specialEdCategories.filter(x => x.Id == element)[0],
        );
      });
    }

    that.addEditStudentModel.ClientId =
      that._authenticationService.currentUserValue.client_id.toString();
    that.addEditStudentModel.ClientContractId = 0;
    that.addEditStudentModel.ClientContractPurchaseOrderId = 0;
    that.addEditStudentModel.FirstName =
      that.AddEditStudentData.value.FirstName;
    that.addEditStudentModel.MiddleName =
      that.AddEditStudentData.value.MiddleName;
    that.addEditStudentModel.LastName = that.AddEditStudentData.value.LastName;
    that.addEditStudentModel.NickName =
      that.AddEditStudentData.value.Weight == null
        ? ""
        : that.AddEditStudentData.value.NickName;
    that.addEditStudentModel.DateOfBirth = moment(
      that.AddEditStudentData.value.DateOfBirth.toString(),
    )
      .format("MM/DD/YYYY")
      .toString();

    that.addEditStudentModel.Sex = that.AddEditStudentData.value.Gender;
    that.addEditStudentModel.HomeLanguage =
      that.AddEditStudentData.value.Language;
    that.addEditStudentModel.SchoolGradeId =
      that.AddEditStudentData.value.SchoolGrade;
    that.addEditStudentModel.Weight =
      that.AddEditStudentData.value.Weight == null ||
      that.AddEditStudentData.value.Weight == ""
        ? 0
        : that.AddEditStudentData.value.Weight;
    that.addEditStudentModel.Height =
      that.AddEditStudentData.value.Height == null ||
      that.AddEditStudentData.value.Height == ""
        ? 0
        : that.AddEditStudentData.value.Height;
    that.addEditStudentModel.InternalNote = that.getInternalNote();

    let requirementsData =
      that.isLoadTransportationData || that.requirementsSectionComponent == null
        ? that.TransportationData
        : that.requirementsSectionComponent.getTransportationData();
    if (requirementsData) {
      that.addEditStudentModel.NeedsClientProvidedAttendant =
        requirementsData.NeedsClientProvidedAttendant;
      that.addEditStudentModel.NeedsPlatformProvidedAttendant =
        requirementsData.NeedsPlatformProvidedAttendant;
      that.addEditStudentModel.ClientAssistantId =
        that.getClientAssistantId(requirementsData);
      that.addEditStudentModel.PreferredAttendantSex =
        that.getPreferedSex(requirementsData);

      that.addEditStudentModel.IsSoloRider = requirementsData.IsSoloRider;
      that.addEditStudentModel.NeedsWheelchairVehicle =
        requirementsData.NeedsWheelchairVehicle;
      that.addEditStudentModel.RideNeeds = that.getRideNeeds(requirementsData);
    }

    that.addEditStudentModel.HealthConcernsAndComments =
      that.getHealthconcernes();

    that.addEditStudentModel.isClientPortal = true;

    if (that.studentId == 0) {
      that.addEditStudentModel.ServiceCancelations = [
        ...that.PastServiceCancelationGridData,
        ...that.RecurrentServiceCancelationGridData,
        ...that.UpcomingServiceCancelationGridData,
      ];
      that.addEditStudentModel.Places = that.AddressGridData;
      let placeList = that.PlaceGridData;
      placeList.forEach(function (rowData) {
        that.addEditStudentModel.Places.push(rowData);
      });
      that.addEditStudentModel.Guardians = that.GuardianGridData;
      that.addEditStudentModel.Contacts = that.ContactGridData;
      that.addEditStudentModel.Attachments = that.AttachmentGridData;
      that.addEditStudentModel.StudentSchedules = that.ScheduleGridData;
      that.InstructionsGridData.forEach(eleement => {
        eleement.Visibility = JSON.stringify(eleement.Visibility);
      });
      that.addEditStudentModel.Instructions = that.InstructionsGridData;
      this.addEditStudentModel.ProfilePhoto =
        that.personalPhotoComponent.fileModel &&
        that.personalPhotoComponent.fileModel.CdnFileName != "" &&
        that.personalPhotoComponent.fileModel.CdnFileName != undefined
          ? that.personalPhotoComponent.fileModel
          : null;
    }

    that.addEditStudentModel.Id = that.studentId;
    return that.addEditStudentModel;
  }

  counter = 0;
  /*Student*/
  loadStudent() {
    let that = this;
    that._clientStudentService.getStudentById(that.studentId).subscribe(
      (Response: any) => {
        that.currentStudentData = Response;
        that.assignStudentValue(Response);
        that.initialDate = new Date(Response.DateOfBirth);

        let transportationData: StudentTransportationDetailsModel =
          new StudentTransportationDetailsModel();
        transportationData.ClientMonitor = Response.ClientAssistantId;
        transportationData.NeedsClientProvidedAttendant =
          Response.NeedsClientProvidedAttendant;
        transportationData.NeedsPlatformProvidedAttendant =
          Response.NeedsPlatformProvidedAttendant;
        transportationData.preferredMonitorGender =
          Response.PreferredAttendantSex;
        transportationData.IsSoloRider = Response.IsSoloRider;
        transportationData.NeedsWheelchairVehicle =
          Response.NeedsWheelchairVehicle;
        transportationData.RideNeeds = Response.RideNeeds;
        that.TransportationData = transportationData;

        if (Response.ProfilePhoto) {
          that.personalPhotoComponent.data = Response.ProfilePhoto;
          that.personalPhotoComponent.loadPersonalPhoto();
        }
        that.studentToggeleLoader();
      },
      error => {
        if (error.status == 401) {
          that._toastrService.error("UnAuthorized.", "");
          this._router.navigate(["/customer-success/students"]);
          that._appComponent.toggeleLoader();
        } else {
          that._toastrService.error(
            "The student was not  added due to technical issue.",
            "",
          );
          that._appComponent.toggeleLoader();
        }
      },
    );
  }
  assignStudentValue(Response: any) {
    var that = this;
    let date = new Date(Response.DateOfBirth);
    this.studentGradeId = Response.SchoolGradeId;
    let schoolGrade = that.grades.filter(a => a.Id == Response.SchoolGradeId);
    that.AddEditStudentData.setValue({
      FirstName: Response.FirstName,
      MiddleName: Response.MiddleName,
      LastName: Response.LastName,
      NickName: Response.NickName,
      DateOfBirth: Response.DateOfBirth.toString(),
      Gender: that.genders.filter(a => a.id == Response.Sex)[0],
      Language: that.Languages.filter(a => a.Id == Response.HomeLanguage)[0],
      SchoolGrade: schoolGrade.length == 0 ? "" : schoolGrade[0],
      Weight: Response.Weight,
      Height: Response.Height,

      PopulationType: Response.PopulationTypes?.map((element, index) => ({
        Id: element.Id,
        Name: element.Name,
      })),
      SpecialEdCategories: Response.SpecialEdCategories?.map(
        (element, index) => ({ Id: element.Id, Name: element.Name }),
      ),

      HealthConcernsAndComments: Response.HealthConcernsAndComments,
      InternalNote: Response.InternalNote,
      Age: Math.abs(date.getFullYear() - new Date().getFullYear()),
    });
  }
  /*Guardians*/
  loadStudentGuardians() {
    let that = this;
    that._clientStudentService.getStudentGuardians(that.studentId).subscribe(
      (Response: any) => {
        that.GuardianGridData = Response;
        that.studentToggeleLoader();
      },
      error => {
        if (error.status == 401) {
          that._toastrService.error("UnAuthorized.", "");
          this._router.navigate(["/customer-success/students"]);
          that._appComponent.toggeleLoader();
        } else {
          that._toastrService.error(
            "The guardians account was not loaded due to a technical issue.",
            "",
          );
          that._appComponent.toggeleLoader();
        }
      },
    );
  }

  /*Emergency Contact(s)*/
  loadContacts() {
    let that = this;
    that._clientStudentService.getContacts(that.studentId).subscribe(
      (Response: any) => {
        that.ContactGridData = Response;
        that.studentToggeleLoader();
      },
      error => {
        if (error.status == 401) {
          that._toastrService.error("UnAuthorized.", "");
          this._router.navigate(["/customer-success/students"]);
          that._appComponent.toggeleLoader();
        } else {
          that._toastrService.error(
            "The contacts was not loaded due to a technical issue.",
            "",
          );
          that._appComponent.toggeleLoader();
        }
      },
    );
  }

  /*Home Address(es)*/
  loadStudentAddresses() {
    let that = this;
    that._clientStudentService.getStudentAddresses(that.studentId).subscribe(
      (Response: any) => {
        that.AddressGridData = Response;
        sessionStorage.setItem("Addresses", JSON.stringify(Response));
        that.studentToggeleLoader();
      },
      error => {
        if (error.status == 401) {
          that._toastrService.error("UnAuthorized.", "");
          this._router.navigate(["/customer-success/students"]);
          that._appComponent.toggeleLoader();
        } else {
          that._toastrService.error(
            "The addresses was not loaded due to a technical issue.",
            "",
          );
          that._appComponent.toggeleLoader();
        }
      },
    );
  }

  /*Student Places*/
  loadStudentPlaces() {
    let that = this;
    that._clientStudentService.getStudentPlaces(that.studentId).subscribe(
      (Response: any) => {
        Response.forEach(a => {
          if (a.Place) {
            a.Status = a.Place.Status;
            a.RequiresAttention = a.Place.RequiresAttention;
          }
        });
        that.PlaceGridData = Response;
        sessionStorage.setItem("Places", JSON.stringify(Response));
        that.studentToggeleLoader();
      },
      error => {
        if (error.status == 401) {
          that._toastrService.error("UnAuthorized.", "");
          this._router.navigate(["/customer-success/students"]);
          that._appComponent.toggeleLoader();
        } else {
          that._toastrService.error(
            "The places was not loaded due to a technical issue.",
            "",
          );
          that._appComponent.toggeleLoader();
        }
      },
    );
  }

  /*-----Start - Service Cancellations-----------*/
  LoadUpcomingServiceCancelations() {
    let that = this;
    that._clientStudentService
      .getUpcomingServiceCancelations(that.studentId)
      .subscribe(
        (Response: any) => {
          that.UpcomingServiceCancelationGridData = Response;
          that.studentToggeleLoader();
        },
        error => {
          if (error.status == 401) {
            that._toastrService.error("UnAuthorized.", "");
            this._router.navigate(["/customer-success/students"]);
            that._appComponent.toggeleLoader();
          } else {
            that._toastrService.error(
              "The service cancelations was not loaded due to a technical issue.",
              "",
            );
            that._appComponent.toggeleLoader();
          }
        },
      );
  }

  LoadRecurrentServiceCancelations() {
    let that = this;
    that._clientStudentService
      .getRecurrentServiceCancelations(that.studentId)
      .subscribe(
        (Response: any) => {
          that.RecurrentServiceCancelationGridData = Response;
          that.studentToggeleLoader();
        },
        error => {
          if (error.status == 401) {
            that._toastrService.error("UnAuthorized.", "");
            this._router.navigate(["/customer-success/students"]);
            that._appComponent.toggeleLoader();
          } else {
            that._toastrService.error("", "");
            that._appComponent.toggeleLoader();
          }
        },
      );
  }

  LoadPastServiceCancelations() {
    let that = this;
    that._clientStudentService
      .getPastServiceCancelations(that.studentId)
      .subscribe(
        (Response: any) => {
          that.PastServiceCancelationGridData = Response;
          that.studentToggeleLoader();
        },
        error => {
          if (error.status == 401) {
            that._toastrService.error("UnAuthorized.", "");
            this._router.navigate(["/customer-success/students"]);
            that._appComponent.toggeleLoader();
          } else {
            that._toastrService.error("", "");
            that._appComponent.toggeleLoader();
          }
        },
      );
  }
  /* -----End - Service Cancellations----------*/

  /*Instructions*/
  loadInstructions() {
    let that = this;
    that._clientStudentService.getInstructions(that.studentId).subscribe(
      (Response: Array<any>) => {
        Response.forEach(a => {
          a.Visibility = JSON.parse(a.Visibility);
        });
        that.InstructionsGridData = Response;
        that.studentToggeleLoader();
      },
      error => {
        if (error.status == 401) {
          that._toastrService.error("UnAuthorized.", "");
          this._router.navigate(["/customer-success/students"]);
          that._appComponent.toggeleLoader();
        } else {
          that._toastrService.error(
            "The Instruction was not loaded due to a technical issue.",
            "",
          );
          that._appComponent.toggeleLoader();
        }
      },
    );
  }

  /*Attachments*/
  loadAttachments() {
    let that = this;
    that._clientStudentService.getAttachments(that.studentId).subscribe(
      (Response: any) => {
        that.AttachmentGridData = Response;
        that.studentToggeleLoader();
      },
      error => {
        if (error.status == 401) {
          that._toastrService.error("UnAuthorized.", "");
          this._router.navigate(["/customer-success/students"]);
          that._appComponent.toggeleLoader();
        } else {
          that._toastrService.error(
            "The attachments was not loaded due to a technical issue.",
            "",
          );
          that._appComponent.toggeleLoader();
        }
      },
    );
  }

  /*Transportation Schedule*/
  loadStudentTransportationSchedules() {
    let that = this;
    that._clientStudentService
      .getStudentTransportationSchedules(that.studentId)
      .subscribe(
        (Response: any) => {
          that.ScheduleGridData = Response;
          that.studentToggeleLoader();
        },
        error => {
          if (error.status == 401) {
            that._toastrService.error("UnAuthorized.", "");
            this._router.navigate(["/customer-success/students"]);
            that._appComponent.toggeleLoader();
          } else {
            that._toastrService.error(
              "The transportation schedule was not loaded due to a technical issue.",
              "",
            );
            that._appComponent.toggeleLoader();
          }
        },
      );
  }

  studentToggeleLoader() {
    let that = this;
    that.counter++;
    if (that.counter == 11) {
      that._appComponent.toggeleLoader();
      that.counter = 0;
    }
  }
  ngOnDestroy() {
    sessionStorage.removeItem("Addresses");
    sessionStorage.removeItem("Places");
  }

  reloadData(data) {
    let that = this;
    that.isLoadTransportationData = true;
    that.TransportationData = data;
  }
  getPreferedSex(requirementsData: any): string {
    if (requirementsData.preferredMonitorGender == null) {
      return "No Preference";
    } else {
      return requirementsData.preferredMonitorGender;
    }
  }
  getInternalNote(): string {
    let that = this;
    if (that.AddEditStudentData.value.InternalNote == null) {
      return "";
    } else {
      return that.AddEditStudentData.value.InternalNote;
    }
  }
  getHealthconcernes(): string {
    let that = this;
    if (that.AddEditStudentData.value.HealthConcernsAndComments == null) {
      return that.AddEditStudentData.value.HealthConcernsAndComments;
    } else {
      return that.AddEditStudentData.value.InternalNote;
    }
  }
  getClientAssistantId(requirementsData): number {
    if (requirementsData.ClientMonitor == null) {
      return 0;
    } else {
      return Number(requirementsData.ClientMonitor);
    }
  }
  getRideNeeds(requirementsData) {
    let that = this;
    return requirementsData.RideNeeds
      ? requirementsData.RideNeeds.map(a => ({
          Id: that.isHaveOfObject(a) ? a.Id : a,
          Name: a.Name,
          DisplayName: null,
          Description: null,
        }))
      : null;
  }
  isHaveOfObject(object) {
    return typeof object === "object" && object !== null;
  }
}
