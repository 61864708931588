import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Self,
} from "@angular/core";
import {
  ControlValueAccessor,
  NgControl,
} from "@angular/forms";

@Component({
  selector: "app-optional-field",
  templateUrl: "./optional-field.component.html",
  styleUrls: ["./optional-field.component.scss"],
})
export class OptionalFieldComponent implements OnInit, ControlValueAccessor {
  @Input() placeholder: string = "";
  @Output() blur: EventEmitter<void> = new EventEmitter<void>();
  @Input() disabled: boolean = false;
  @Input() validationError: string = "";
  onChange: (value: any) => void = () => {};
  @Input() OnChangeFunction: Function;
  onTouched: () => void = () => {};
  input: string;
  @Input() type: string = "";
  @Input() maxlength: number = 50;
  @Input() Id: string = "";
  @Input() extraValidationError = {
    Valid: false,
    validationError: "",
  };

  constructor(@Self() public controlDir: NgControl) {
    controlDir.valueAccessor = this;
  }

  ngOnInit(): void {
    const control = this.controlDir.control;
    const validators = control?.validator ? [control.validator] : null;
    control?.setValidators(validators);
    control?.updateValueAndValidity();
  }

  writeValue(value: any): void {
    if (this.type == "number" && value != null) {
      value = value.replace(/\D/g, "");
    }
    setTimeout(() => {
      this.extraValidationError.Valid = false;
      this.input = value;
    }, 10);
  }

  registerOnChange(onChange: (value: any) => void): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: () => void): void {
    this.onTouched = onTouched;
  }

  setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
  }
}
