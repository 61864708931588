import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";

import { BehaviorSubject } from "rxjs";
import { environment } from "environments/environment";

@Injectable()
export class InvoiceDetailService {
  rows: any;
  onDatatablessChanged: BehaviorSubject<any>;

  /**
   * Constructor
   *
   * @param {HttpClient} _httpClient
   */
  constructor(private _httpClient: HttpClient) {
    // Set the defaults
    this.onDatatablessChanged = new BehaviorSubject({});
  }

  getGridData(invoiceUuid, show_all_rides) {
    let queryParams = new HttpParams();
    queryParams = queryParams.append("show_all_rides", show_all_rides);
    return this._httpClient.get<any>(
      `${environment.apiUrl}/client-invoices/${invoiceUuid}/details`,
      { params: queryParams },
    );
  }

  getInvoiceData(invoiceUuid) {
    return this._httpClient.get<any>(
      `${environment.apiUrl}/client-invoices/${invoiceUuid}`,
    );
  }

  getClientData(clientId) {
    return this._httpClient.get<any>(
      `${environment.apiUrl}/clients/${clientId}`,
    );
  }
  loadConfiguration() {
    return this._httpClient.get<any>(`${environment.apiUrl}/Configurations`);
  }
}
