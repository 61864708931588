import {
  Component,
  EventEmitter,
  Injectable,
  Input,
  Output,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { FiltersTypes } from "app/enums/controls/filters/FiltersTypes";
import { FromDatePickerComponent } from "../from-date-picker/from-date-picker.component";
import { IFiltersConfig } from "../interfaces/controls/IFiltersConfig";
import { ToDatePickerComponent } from "../to-date-picker/to-date-picker.component";
import { WeeklyPeriodsComponent } from "../weekly-periods/weekly-periods.component";

@Component({
  selector: "app-collection-filters",
  templateUrl: "./collection-filter.component.html",
  encapsulation: ViewEncapsulation.None,
})
@Injectable({
  providedIn: "root",
})
export class CollectionFiltersComponent {
  @Input() filters: IFiltersConfig;
  @Output() search = new EventEmitter<string>();
  @Output() clearSearch = new EventEmitter<string>();
  @Output() changeyear = new EventEmitter<string>();
  @Output() changefiscalPeriod = new EventEmitter<string>();
  @Output() changefromdate = new EventEmitter<string>();
  @Output() changetodate = new EventEmitter<string>();
  @Output() changeWeeklyPeriod = new EventEmitter<string>();
  @Output() changeClient = new EventEmitter<string>();
  @Output() changeState = new EventEmitter<string>();
  @ViewChild(FromDatePickerComponent) FromDate: FromDatePickerComponent;
  @ViewChild(ToDatePickerComponent) ToDate: ToDatePickerComponent;
  @ViewChild(WeeklyPeriodsComponent) weeklyperiod: WeeklyPeriodsComponent;
  @Input() height: string = "0";

  public minHeight: string = "0";
  public get filtersTypesEnum(): typeof FiltersTypes {
    return FiltersTypes;
  }

  public viewFilter(): void {
    if (this.filters.isFiltersEnable) {
      this.filters.isFiltersEnable = false;
    } else {
      this.filters.isFiltersEnable = true;
    }
  }

  apply() {
    this.search.emit();
  }

  clearFilters() {
    this.filters.Filters.forEach(item => {
      if (item.filterClear) item.filterValue = null;

      if (item.multifilter && item.multifilter.length > 0)
        item.multifilter
          .filter(x => x.filterClear)
          .forEach(subitem => {
            subitem.filterValue = null;
          });
    });
    this.clearSearch.emit();
  }

  changeYearFilter() {
    this.changeyear.emit();
  }

  changeClientFilter() {
    this.changeClient.emit();
  }

  changeStateFilter() {
    this.changeState.emit();
  }

  changefiscalPeriodFilter() {
    this.changefiscalPeriod.emit();
  }

  changefromdateFilter() {
    this.changefromdate.emit();
  }

  changetodateFilter() {
    this.changetodate.emit();
  }

  changeWeeklyPeriodFilter() {
    this.changeWeeklyPeriod.emit();
  }
}
