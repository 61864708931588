import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AuthenticationService } from "app/auth/service";
import { environment } from "environments/environment";
import { NoShowReportFiliter } from "../model/no-show-filiter.model";
import { NoShowReportModel } from "../model/no-show-report.model";
import { StudentTransportationProfileModel } from "../model/student-transportation-profile.model";
@Injectable({
  providedIn: "root",
})
export class NoShowReportService {
  constructor(
    private _httpClient: HttpClient,
    private _authenticationService: AuthenticationService,
  ) {}
  getNoShowReport(filterModel: NoShowReportFiliter) {
      let queryParams = new HttpParams();
      let that = this; 
    queryParams = queryParams.append("isFromClient", true);
    queryParams = queryParams.append(
      "from_date",
      filterModel.FromServiceDate.toString(),
    );
    queryParams = queryParams.append(
      "to_date",
      filterModel.ToServiceDate.toString(),
    );
    queryParams = queryParams.append(
      "client_id",
      this._authenticationService.currentUserValue.client_id,
    );
    queryParams = queryParams.append("student_id", filterModel.StudentId);
    queryParams = queryParams.append(
      "consecutiveFilter",
      filterModel.Status == "No Show"
            ? 1
            : that.statusFilterValue(filterModel),
    );

    return this._httpClient.get<NoShowReportModel[]>(
      `${environment.apiUrl}/no-show-report`,
      { params: queryParams },
    );
    }
    statusFilterValue(filterModel) {
        return filterModel.Status == "Late Cancel"
            ? 2
            : 0;
    }
  getPassengersData() {
    let queryParams = new HttpParams();

    queryParams = queryParams.append(
      "client_id",
      this._authenticationService.currentUserValue.client_id,
    );

    return this._httpClient.get<any>(
      `${environment.apiUrl}/rides-finder/passengers`,
      { params: queryParams },
    );
  }
  getStudentProfile(id) {
    return this._httpClient.get<StudentTransportationProfileModel>(
      `${environment.apiUrl}/students/${id}/transportation-profile`,
    );
  }
}
