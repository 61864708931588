export enum FiltersTypes {
  /**
   * year component
   */
  year = "Year",
  /**
   * monthly periods component
   */
  monthlyPeriods = "MonthlyPeriods",
  /**
   * state component
   */
  state = "State",
  /**
   * contractors component
   */
  contractors = "Contractors",

  /**
   * fiscal period
   */
  fiscalPeriod = "FiscalPeriod",

  /**
   * clients
   */
  clients = "clients",

  /**
   * From Date Picker
   */
  fromDatePicker = "FromDatePicker",

  /**
   * to Date Picker
   */
  toDatePicker = "ToDatePicker",

  /**
   *search By Number
   */
  searchByNumber = "searchByNumber",

  /**
   *search By Text
   */
  searchByText = "searchByText",

  /**
   * Day Periods  Morning Or Evening
   */
  DayPeriods = "DayPeriods",

  /**
   * Statues
   */
  Statues = "Statues",

  /**
   * service providers
   */
  serviceproviders = "serviceproviders",

  /**
   * userstypes
   */
  userstypes = "userstypes",
  /**
   * Passengers
   */
  Passengers = "Passengers",
  /**
   * weekly periods
   */
  weeklyperiods = "weeklyperiods",

  /**
   * places
   */
  places = "places",

  /**
   * periods
   */
  periods = "periods",

  /**
   * rideTypes
   */
  rideTypes = "rideTypes",

  /**
   * bluePrintNumber
   */
  bluePrintNumber = "bluePrintNumber",

  /**
   * containerNumber
   */
  containerNumber = "containerNumber",

  /**
   * GurdiansStatus
   */
  GurdiansStatus = "GurdiansStatus",
  /**
   * NoShowStatus
   */
  NoShowStatus = "NoShowStatus",
  /**
   * ConsecutivNoShowStatus
   */
  ConsecutivNoShowStatus = "ConsecutivNoShowStatus",
  /**
   * GurdiansAppUsers
   */
  GurdiansAppUsers = "GurdiansAppUsers",
  /**
   * Grade
   */
  Grade = "Grade",
  /**
   * Types
   */
  Types = "Types",
  /**
   * By Ride
   */
  ByRide = "ByRide",
}
