import { Injectable } from "@angular/core";
import { AuthenticationService } from "../auth/service";
import { PermissionsList } from "../enums/Permissions/PermissionList";

@Injectable({ providedIn: "root" })
export class MenuService {
  public menu: any = [];
  constructor(public _authenticationService: AuthenticationService) {
    this.menu = [
      {
        name: "Dashboard",
        icon: "fa-Dashboard",
        url: "/home",
        //ishidden:haspermission('aview')
        hidden: !this._authenticationService.checkparantaccess([
          PermissionsList.ClientHomeView,
        ]),
        PermissionValue: PermissionsList.ClientHomeView,
      },
      {
        name: "Invoices",
        icon: "fa-Accounting",
        url: "/client-invoices/Invoices",
        //ishidden:haspermission('aview')
        hidden: !this._authenticationService.checkparantaccess([
          PermissionsList.ClientClientInvoicesView,
        ]),
        PermissionValue: PermissionsList.ClientClientInvoicesView,
      },
      {
        name: "Ride Blueprint Sheet",
        icon: "fa-Customer-Success",
        url: "/RouteSheet/RouteSheet",
        //ishidden:haspermission('aview')
        hidden: !this._authenticationService.checkparantaccess([
          PermissionsList.RideBlueprintView,
        ]),
        PermissionValue: PermissionsList.RideBlueprintView,
      },
      {
        name: "Active Rides",
        icon: "fa-CustomerCare",
        url: "/ActiveRides/ActiveRides",
        //ishidden:haspermission('aview')
        hidden: !this._authenticationService.checkparantaccess([
          PermissionsList.RideMonitorView,
        ]),
        PermissionValue: PermissionsList.RideMonitorView,
      },
      {
        name: "Places",
        icon: "fa fa-map-marker",
        url: "/customer-success/place",
        //ishidden:haspermission('aview')
        hidden: !this._authenticationService.checkparantaccess([
          PermissionsList.ClientPlacesView,
        ]),
        PermissionValue: PermissionsList.ClientPlacesView,
      },
      {
        name: "Passengers",
        icon: "fa-Partner-Care",
        url: "",
        //ishidden:haspermission('aview')
        hidden: !this._authenticationService.checkparantaccess([
          PermissionsList.ClientStudentsView,
          PermissionsList.ClientGuardiansView,
        ]),
        PermissionValue:
          PermissionsList.ClientStudentsView ||
          PermissionsList.ClientGuardiansView,

        subMenu: [
          {
            name: "Students",
            icon: "fa-Partner-Care",
            url: "/customer-success/students",
            //ishidden:haspermission('aview')
            hidden: !this._authenticationService.checkparantaccess([
              PermissionsList.ClientStudentsView,
            ]),
            PermissionValue: PermissionsList.ClientStudentsView,
          },
          {
            name: "Guardians App Users",
            icon: "fa fa-list-alt",
            url: "/client-gurdians/gurdians",
            //ishidden:haspermission('aview')
            hidden: !this._authenticationService.checkparantaccess([
              PermissionsList.ClientGuardiansView,
            ]),
            PermissionValue: PermissionsList.ClientGuardiansView,
          },
        ],
      },
      {
        name: "Reports",
        icon: "fa fa-file",
        url: "ConsecutiveNoShowReport/Index",
        //ishidden:haspermission('aview')
        hidden: !this._authenticationService.checkparantaccess([
          PermissionsList.ClientRideFinderReportView,
          PermissionsList.ClientNoShowReportView,
          PermissionsList.ClientConsecutiveNoShowReportView,
        ]),
        PermissionValue:
          PermissionsList.ClientRideFinderReportView ||
          PermissionsList.ClientNoShowReportView ||
          PermissionsList.ClientConsecutiveNoShowReportView,
        subMenu: [
          {
            name: "Historical Rides",
            icon: "fa-Safety-Compliance",
            url: "/HistoricalRides/HistoricalRides",
            //ishidden:haspermission('aview')
            hidden: !this._authenticationService.checkparantaccess([
              PermissionsList.ClientRideFinderReportView,
            ]),
            PermissionValue: PermissionsList.ClientRideFinderReportView,
          },
          {
            name: "No Show Report",
            icon: "fa fa-file-text",
            url: "/NoShowReport/Index",
            hidden: !this._authenticationService.checkparantaccess([
              PermissionsList.ClientNoShowReportView,
            ]),
            PermissionValue: PermissionsList.ClientNoShowReportView,
          },
          {
            name: "Consecutive No Show Report",
            icon: "fa fa-file",
            url: "ConsecutiveNoShowReport/Index",
            hidden: !this._authenticationService.checkparantaccess([
              PermissionsList.ClientConsecutiveNoShowReportView,
            ]),
            PermissionValue: PermissionsList.ClientConsecutiveNoShowReportView,
          },
        ],
      },
    ];
  }
}
