<div class="row section-div">
  <div>
    <kendo-tabstrip
      [tabPosition]="position"
      style="margin: 10px"
    >
      <!--Upcoming-->
      <kendo-tabstrip-tab
        title="Upcoming ({{ upcomingGridData.length }})"
        style="font-weight: 500"
        [selected]="true"
      >
        <ng-template kendoTabContent>
          <kendo-grid
            #UpcomingGrid
            [data]="upcomingGridData"
            [scrollable]="false"
            [pageSize]="state.take"
            [skip]="state.skip"
            [sort]="state.sort"
            [filter]="state.filter"
            [pageable]="false"
          >
            <ng-template kendoGridNoRecordsTemplate>
              No upcoming service cancelations.
            </ng-template>
            <ng-template
              kendoGridToolbarTemplate
              NgClass="p-0"
            >
            </ng-template>
            <kendo-grid-spacer></kendo-grid-spacer>
            <kendo-grid-column
              [width]="50"
              field="Index"
              [sortable]="false"
              title="#"
            >
              <ng-template
                kendoGridCellTemplate
                let-rowIndex="rowIndex+1"
              >
                {{ rowIndex }}
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-column
              field="EffectiveDate"
              title="Effective Date"
              [sortable]="false"
            >
              <ng-template
                kendoGridCellTemplate
                let-dataItem
                let-rowIndex="rowIndex"
              >
                {{ getEffectiveDate(dataItem) }}
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-column
              field="ApplyTo"
              title="Apply To"
              [sortable]="false"
            >
              <ng-template
                kendoGridCellTemplate
                let-dataItem
                let-rowIndex="rowIndex"
              >
                {{ getApplyToData(dataItem.ApplyTo) }}
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-column
              field="Reason"
              title="Reason"
              [sortable]="false"
              [width]="250"
            >
              <ng-template
                kendoGridCellTemplate
                let-dataItem
                let-rowIndex="rowIndex"
              >
                <span>
                  <button
                    type="button"
                    rippleEffect
                    class="btn btn-link pl-0 pr-0 text-left"
                    ngbPopover="{{ dataItem.InternalNote }}"
                    placement="right"
                    popoverTitle="Reason"
                    container="body"
                    triggers="mouseenter:mouseleave"
                  >
                    View Reason
                  </button>
                </span>
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-column
              [sortable]="false"
              title="Action"
              [width]="90"
            >
              <ng-template
                kendoGridCellTemplate
                let-dataItem
                let-rowIndex="rowIndex"
              >
                <div class="d-flex align-items-center">
                  <div
                    ngbDropdown
                    [placement]="'left-top'"
                    container="body"
                    class="m-auto"
                  >
                    <a
                      ngbDropdownToggle
                      href="javascript:void(0);"
                      class="hide-arrow"
                      id="dropdownBrowserState"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <i
                        data-feather="more-vertical"
                        class="text-primary cursor-pointer mr-50"
                      ></i>
                    </a>
                    <div
                      ngbDropdownMenu
                      class="dropdown-menu-right"
                      aria-labelledby="dropdownBrowserState"
                      disabled
                    >
                      <a
                        href="javascript:void(0)"
                        (click)="modalOpenMD(dataItem, rowIndex, 'upcoming')"
                        ngbDropdownItem
                        class="d-flex align-items-center"
                      >
                        Edit
                      </a>
                      <div
                        class="dropdown-divider"
                        *ngIf="!disabeld"
                      ></div>
                      <a
                        href="javascript:void(0)"
                        (click)="
                          deleteServiceCancelation(
                            dataItem,
                            rowIndex,
                            'upcoming'
                          )
                        "
                        ngbDropdownItem
                        class="d-flex align-items-center"
                        *ngIf="!disabeld"
                      >
                        Delete
                      </a>
                    </div>
                  </div>
                </div>
              </ng-template>
            </kendo-grid-column>
            <ng-template
              kendoPagerTemplate
              *ngIf="upcomingGridData.length != 0"
            >
              <kendo-grid-spacer></kendo-grid-spacer>
              <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
              <kendo-pager-numeric-buttons
                [buttonCount]="3"
              ></kendo-pager-numeric-buttons>
              <kendo-pager-next-buttons></kendo-pager-next-buttons>
              <kendo-pager-info></kendo-pager-info>
            </ng-template>
          </kendo-grid>
        </ng-template>
      </kendo-tabstrip-tab>

      <!--Recurrent-->
      <kendo-tabstrip-tab title="Recurrent ({{ recurentGridData.length }})">
        <ng-template kendoTabContent>
          <kendo-grid
            #RecurrentGrid
            [data]="recurentGridData"
            [scrollable]="false"
            [pageSize]="state.take"
            [skip]="state.skip"
            [sort]="state.sort"
            [filter]="state.filter"
            [pageable]="false"
          >
            <ng-template kendoGridNoRecordsTemplate>
              No recurring service cancelations.
            </ng-template>
            <ng-template
              kendoGridToolbarTemplate
              NgClass="p-0"
            >
            </ng-template>
            <kendo-grid-spacer></kendo-grid-spacer>
            <kendo-grid-column
              [width]="50"
              field="Index"
              [sortable]="false"
              title="#"
            >
              <ng-template
                kendoGridCellTemplate
                let-rowIndex="rowIndex+1"
              >
                {{ rowIndex }}
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-column
              field="EffectiveDate"
              title="Effective Date"
              [sortable]="false"
            >
              <ng-template
                kendoGridCellTemplate
                let-dataItem
                let-rowIndex="rowIndex"
              >
                {{ getEffectiveDate(dataItem) }}
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-column
              field="ApplyTo"
              title="Apply To"
              [sortable]="false"
            >
              <ng-template
                kendoGridCellTemplate
                let-dataItem
                let-rowIndex="rowIndex"
              >
                {{ getApplyToData(dataItem.ApplyTo) }}
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-column
              field="Reason"
              title="Reason"
              [sortable]="false"
              [width]="250"
            >
              <ng-template
                kendoGridCellTemplate
                let-dataItem
                let-rowIndex="rowIndex"
              >
                <span>
                  <button
                    type="button"
                    rippleEffect
                    class="btn btn-link pl-0 pr-0 text-left"
                    ngbPopover="{{ dataItem.InternalNote }}"
                    placement="right"
                    container="body"
                    popoverTitle="Reason"
                    triggers="mouseenter:mouseleave"
                  >
                    View Reason
                  </button>
                </span>
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-column
              [sortable]="false"
              title="Action"
              [width]="90"
            >
              <ng-template
                kendoGridCellTemplate
                let-dataItem
                let-rowIndex="rowIndex"
              >
                <div class="d-flex align-items-center">
                  <div
                    ngbDropdown
                    [placement]="'left-top'"
                    container="body"
                    class="m-auto"
                  >
                    <a
                      ngbDropdownToggle
                      href="javascript:void(0);"
                      class="hide-arrow"
                      id="dropdownBrowserState"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <i
                        data-feather="more-vertical"
                        class="text-primary cursor-pointer mr-50"
                      ></i>
                    </a>
                    <div
                      ngbDropdownMenu
                      class="dropdown-menu-right"
                      aria-labelledby="dropdownBrowserState"
                      disabled
                    >
                      <a
                        href="javascript:void(0)"
                        (click)="modalOpenMD(dataItem, rowIndex, 'recurent')"
                        ngbDropdownItem
                        class="d-flex align-items-center"
                      >
                        Edit
                      </a>
                      <div
                        class="dropdown-divider"
                        *ngIf="!disabeld"
                      ></div>
                      <a
                        href="javascript:void(0)"
                        (click)="
                          deleteServiceCancelation(
                            dataItem,
                            rowIndex,
                            'recurrent'
                          )
                        "
                        ngbDropdownItem
                        class="d-flex align-items-center"
                        *ngIf="!disabeld"
                      >
                        Delete
                      </a>
                    </div>
                  </div>
                </div>
              </ng-template>
            </kendo-grid-column>
            <ng-template
              kendoPagerTemplate
              *ngIf="recurentGridData.length != 0"
            >
              <kendo-grid-spacer></kendo-grid-spacer>
              <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
              <kendo-pager-numeric-buttons
                [buttonCount]="3"
              ></kendo-pager-numeric-buttons>
              <kendo-pager-next-buttons></kendo-pager-next-buttons>
              <kendo-pager-info></kendo-pager-info>
            </ng-template>
          </kendo-grid>
        </ng-template>
      </kendo-tabstrip-tab>

      <!--Past-->
      <kendo-tabstrip-tab title=" Past ({{ pastGridData.length }})">
        <ng-template kendoTabContent>
          <kendo-grid
            #PastGrid
            [data]="pastGridData"
            [pageSize]="state.take"
            [skip]="state.skip"
            [sort]="state.sort"
            [filter]="state.filter"
            [pageable]="false"
          >
            <ng-template kendoGridNoRecordsTemplate>
              No past service cancelations.
            </ng-template>
            <ng-template
              kendoGridToolbarTemplate
              NgClass="p-0"
            >
            </ng-template>
            <kendo-grid-spacer></kendo-grid-spacer>
            <kendo-grid-column
              [width]="50"
              field="Index"
              [sortable]="false"
              title="#"
            >
              <ng-template
                kendoGridCellTemplate
                let-rowIndex="rowIndex+1"
              >
                {{ rowIndex }}
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-column
              field="EffectiveDate"
              title="Effective Date"
              [sortable]="false"
            >
              <ng-template
                kendoGridCellTemplate
                let-dataItem
                let-rowIndex="rowIndex"
              >
                {{ getEffectiveDate(dataItem) }}
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-column
              field="ApplyTo"
              title="Apply To"
              [sortable]="false"
            >
              <ng-template
                kendoGridCellTemplate
                let-dataItem
                let-rowIndex="rowIndex"
              >
                {{ getApplyToData(dataItem.ApplyTo) }}
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-column
              field="Reason"
              title="Reason"
              [sortable]="false"
              [width]="250"
            >
              <ng-template
                kendoGridCellTemplate
                let-dataItem
                let-rowIndex="rowIndex"
              >
                <span>
                  <button
                    type="button"
                    rippleEffect
                    class="btn btn-link pl-0 pr-0 text-left"
                    ngbPopover="{{ dataItem.InternalNote }}"
                    placement="right"
                    container="body"
                    popoverTitle="Reason"
                    triggers="mouseenter:mouseleave"
                  >
                    View Reason
                  </button>
                </span>
              </ng-template>
            </kendo-grid-column>
          </kendo-grid>
        </ng-template>
      </kendo-tabstrip-tab>
    </kendo-tabstrip>
  </div>
  <div class="col-lg-3">
    <button
      type="button"
      rippleeffect=""
      class="btn btn-close waves-effect waves-float waves-light mt-1"
      style="border-color: #1877f2; margin-bottom: 20px; color: #1877f2"
      (click)="modalOpenMD()"
      [hidden]="disabeld"
    >
      Add Service Cancelation
    </button>
  </div>
</div>
<kendo-dialog
  title=""
  *ngIf="openedDelete"
  (close)="close('deleted')"
  [minWidth]="250"
  [width]="400"
>
  <p style="text-align: center">
    Are you sure you want to delete the service cancelation?
  </p>
  <kendo-dialog-titlebar>
    <div style="font-size: 18px; line-height: 1.3em; font-weight: 500">
      <span
        class="k-icon k-i-info-circle mr-1"
        style="color: #eea236; font-size: 26px; margin-top: -3px"
      ></span
      >Delete Confirmation
    </div>
  </kendo-dialog-titlebar>
  <kendo-dialog-actions>
    <p style="text-align: center">
      <button
        type="button"
        (click)="close('deleted')"
        rippleeffect=""
        class="btn btn-default waves-effect waves-float waves-light"
        style="
          background-color: #acb7bf;
          border-color: #acb7bf;
          color: white;
          margin-right: 10px;
        "
      >
        No
      </button>
      <button
        type="button"
        (click)="confirmDeleteServiceCancelation()"
        rippleeffect=""
        class="btn btn-danger waves-effect waves-float waves-light"
      >
        Yes
      </button>
    </p>
  </kendo-dialog-actions>
</kendo-dialog>

<app-student-add-new-service-cancelation
  #addNewServiceCancelation
  (Added)="reloadData($event)"
  [disable]="disabeld"
></app-student-add-new-service-cancelation>
