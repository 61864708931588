import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { ClientStudentsService } from "../../services/client-students.service";
import { ScheduleFieldComponent } from "../ScheduleField/schedule-field.component";
import { PermissionsList } from "app/enums/Permissions/PermissionList";
import { AuthenticationService } from "app/auth/service/authentication.service";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-schedule-days",
  templateUrl: "./schedule-days.component.html",
  styleUrls: ["./schedule-days.component.scss"],
})
export class ScheduleDaysComponent implements OnInit {
  @Input() formchild: FormGroup;
  @Input() AddressGridData: any[];
  @Input() PlaceGridData: any[];
  @Input() StudentScheduleDetails: any[];
  @ViewChild("AppScheduleField") appScheduleField: ScheduleFieldComponent;
  public disabled: boolean = true;
  public PermessionValue: string;

  days: any = [
    {
      name: "Mon",
      Checked1: true,
      Checked2: true,
      DayId: 2,
    },
    {
      name: "Tue",
      Checked1: true,
      Checked2: true,
      DayId: 3,
    },
    {
      name: "Wed",
      Checked1: true,
      Checked2: true,
      DayId: 4,
    },
    {
      name: "Thu",
      Checked1: true,
      Checked2: true,
      DayId: 5,
    },
    {
      name: "Fri",
      Checked1: true,
      Checked2: true,
      DayId: 6,
    },
    {
      name: "Sat",
      Checked1: false,
      Checked2: false,
      DayId: 7,
    },
    {
      name: "Sun",
      Checked1: false,
      Checked2: false,
      DayId: 1,
    },
  ];
  checkAll2: boolean = false;
  checkAll1: boolean = false;
  constructor(
    private fb: FormBuilder,
    private _clientStudentsService: ClientStudentsService,
    private _authenticationService: AuthenticationService,
    private route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    if (this.route.snapshot.paramMap.get("id")) {
      this.disabled = !this._authenticationService.checkaccess(
        PermissionsList[PermissionsList.ClientStudentsEdit],
      );
    } else {
      this.disabled = !this._authenticationService.checkaccess(
        PermissionsList[PermissionsList.ClientStudentsCreate],
      );
    }

    for (const element of this.days) {
      let formGroup = this.daysFormGroup(element.DayId);
      formGroup.get("time2").disable();
      formGroup.get("time1").disable();
    }
  }

  check2() {
    let that = this;
    that.checkAll2 = this.checkAll2;
    for (let index = 0; index < that.days.length; index++) {
      if (that.days[index].name != "Sat" && that.days[index].name != "Sun") {
        if (!that.checkAll2) {
          let formGroup = this.daysFormGroup(this.days[index].DayId);
          formGroup.get("dropOff2").disable();
          formGroup.get("pickup2").disable();
        } else {
          let formGroup = this.daysFormGroup(this.days[index].DayId);
          formGroup.get("dropOff2").enable();
          formGroup.get("pickup2").enable();
        }
        that.days[index].Checked2 = that.checkAll2;
      }
    }
  }

  check1() {
    let that = this;
    for (let index = 0; index < that.days.length; index++) {
      if (that.days[index].name != "Sat" && that.days[index].name != "Sun") {
        if (!that.checkAll1) {
          let formGroup = this.daysFormGroup(this.days[index].DayId);
          formGroup.get("dropOff1").disable();
          formGroup.get("pickup1").disable();
        } else {
          let formGroup = this.daysFormGroup(this.days[index].DayId);
          formGroup.get("dropOff1").enable();
          formGroup.get("pickup1").enable();
        }
        that.days[index].Checked1 = that.checkAll1;
      }
    }
  }

  daysFormGroup(i: string | number) {
    if (i == 8) {
      i = 1;
    }
    let name = "days" + i;
    return this.formchild.get(name) as FormGroup;
  }

  ReturnDay(item: any) {
    let that = this;
    let result = null;
    if (that.StudentScheduleDetails != null) {
      result = that.StudentScheduleDetails.filter(a => a.DayId == item.DayId);
    }
    return result;
  }
}
