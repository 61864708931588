import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { CoreCommonModule } from "@core/common.module";
import { ContentHeaderModule } from "app/layout/components/content-header/content-header.module";
import { BrowserModule } from "@angular/platform-browser";
import { DataTablesModule } from "angular-datatables";
import { QuillModule } from "ngx-quill";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { NgSelectModule } from "@ng-select/ng-select";
import { OwlDateTimeModule, OwlNativeDateTimeModule } from "ng-pick-datetime";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import { CsvModule } from "@ctrl/ngx-csv";
import { GridModule } from "@progress/kendo-angular-grid";
import { PipesModule } from "../../utilities/customPipes/customPipesModule";
import { AuthGuard } from "app/auth/helpers/auth.guards";
import { AuthorizationGuard } from "app/auth/helpers/authorization.guard";
import { PermissionsList } from "app/enums/Permissions/PermissionList";
import { FiltersModule } from "app/controls/component/filters/filters.module";

import { DialogsModule } from "@progress/kendo-angular-dialog";

import { LayoutModule } from "@progress/kendo-angular-layout";
import { InputsModule } from "@progress/kendo-angular-inputs";
import { ControlsModule } from "app/controls/component/custom-input/controls.module";

import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ClientStudentsComponent } from "./ClientStudents/client-students.component";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ButtonsModule } from "@progress/kendo-angular-buttons";
import { GurdiansStudentSectionComponent } from "./GurdiansStudentSection/gurdians-student-section.component";
import { StudentEmergencyContactsSectionComponent } from "./StudentEmergencyContactsSection/student-emergency-contacts-section.component";
import { AddEditStudentComponent } from "./AddEditStudent/add-edit-student.component";
import { StudentSpecialRequirementsSectionComponent } from "./StudentSpecialRequirementsSection/student-special-requirements-section.component";
import { InstructionsSectionComponent } from "./InstructionsSection/instructions-section.component";
import { AttachmentSectionComponent } from "./AttachmentSection/attachment-section.component";
import { AddressStudentSectionComponent } from "./AddressStudentSection/address-student-section.component";
import { StudentAddNewContactComponent } from "./StudentEmergencyContactsSection/StudentAddNewContact/student-add-new-contact.component";
import { StudentAddNewHomeAddressComponent } from "./AddressStudentSection/StudentAddNewHomeAddress/student-add-new-home-address.component";
import { StudentAddNewGuardianComponent } from "./GurdiansStudentSection/StudentAddNewGuardian/student-add-new-guardian.component";
import { DropDownListModule } from "@progress/kendo-angular-dropdowns";
import { StudentAddNewTransportationScheduleComponent } from "./TransportationScheduleSection/StudentAddNewTransportationSchedule/student-add-new-transportation-schedule.component";
import { ScheduleFieldComponent } from "./TransportationScheduleSection/ScheduleField/schedule-field.component";
import { ScheduleDaysComponent } from "./TransportationScheduleSection/ScheduleDays/schedule-days.component";
import { StudentAddNewServiceCancelationComponent } from "./ServiceCancelationSection/StudentAddNewServiceCancelation/student-add-new-service-cancelation.component";
import { StudentAddNewInstructionComponent } from "./InstructionsSection/StudentAddNewInstruction/student-add-new-service-instruction.component";
import { UploadInterceptor } from "app/utilities/interceptores/upload.interceptor";
import { UploadsModule } from "@progress/kendo-angular-upload";
import { ServiceCancelationSectionComponent } from "./ServiceCancelationSection/service-cancelation-section.component";
import { TransportationScheduleSectionComponent } from "./TransportationScheduleSection/transportation-schedule-section.component";
import { StudentAddNewAttachmentComponent } from "./AttachmentSection/StudentAddNewAttachment/student-add-new-attachment.component";
import { PhotoModule } from "../../controls/component/photo/personal-photo/photo.module";
import { PhoneMaskStudentDirective } from "../../utilities/directives/PhoneNumberStudent.directive";
import { StudentAddPlaceComponent } from "./AddressStudentSection/StudentAddNewPlace/student-add-new-place.component";
import { DisabledDirective } from "app/utilities/directives/Disabled.directive";

const routes = [
  {
    path: "customer-success/students",
    component: ClientStudentsComponent,
    data: {
      animation: "Students",
      PermissionPageName: [PermissionsList[PermissionsList.ClientStudentsView]],
    },
    canActivate: [AuthGuard, AuthorizationGuard],
  },
  {
    path: "customer-success/studentsEdit/:id",
    component: ClientStudentsComponent,
    data: {
      animation: "Students",
      PermissionPageName: [PermissionsList[PermissionsList.ClientStudentsView]],
    },
    canActivate: [AuthGuard, AuthorizationGuard],
  },
  {
    path: "customer-success/students/new",
    component: AddEditStudentComponent,
    data: {
      animation: "Students",
      PermissionPageName: [
        PermissionsList[PermissionsList.ClientStudentsCreate],
      ],
    },
    canActivate: [AuthGuard, AuthorizationGuard],
  },
  {
    path: "customer-success/students/edit/:id",
    component: AddEditStudentComponent,
    data: {
      animation: "Students",
      PermissionPageName: [
        PermissionsList[
          (PermissionsList.ClientStudentsEdit,
          PermissionsList.ClientStudentsView)
        ],
      ],
    },
    canActivate: [AuthGuard, AuthorizationGuard],
  },
];

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [
    ClientStudentsComponent,
    AddEditStudentComponent,
    GurdiansStudentSectionComponent,
    StudentEmergencyContactsSectionComponent,
    StudentAddNewContactComponent,
    StudentSpecialRequirementsSectionComponent,
    TransportationScheduleSectionComponent,
    ServiceCancelationSectionComponent,
    InstructionsSectionComponent,
    AttachmentSectionComponent,
    AddressStudentSectionComponent,
    StudentAddNewHomeAddressComponent,
    StudentAddNewGuardianComponent,
    StudentAddNewTransportationScheduleComponent,
    ScheduleDaysComponent,
    ScheduleFieldComponent,
    StudentAddNewServiceCancelationComponent,
    StudentAddNewInstructionComponent,
    StudentAddNewAttachmentComponent,
    PhoneMaskStudentDirective,
    StudentAddPlaceComponent,
    DisabledDirective,
  ],
  exports: [
    ClientStudentsComponent,
    AddEditStudentComponent,
    GurdiansStudentSectionComponent,
    StudentEmergencyContactsSectionComponent,
    StudentAddNewContactComponent,
    StudentSpecialRequirementsSectionComponent,
    TransportationScheduleSectionComponent,
    ServiceCancelationSectionComponent,
    InstructionsSectionComponent,
    AttachmentSectionComponent,
    AddressStudentSectionComponent,
    StudentAddNewHomeAddressComponent,
    StudentAddNewGuardianComponent,
    StudentAddNewTransportationScheduleComponent,
    ScheduleDaysComponent,
    StudentAddNewServiceCancelationComponent,
    StudentAddNewInstructionComponent,
    StudentAddNewAttachmentComponent,
    PhoneMaskStudentDirective,
    StudentAddPlaceComponent,
    DisabledDirective,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: UploadInterceptor,
      multi: true,
    },
  ],
  imports: [
    RouterModule.forChild(routes),
    ContentHeaderModule,
    QuillModule.forRoot(),
    NgxDatatableModule.forRoot({
      messages: {
        emptyMessage: '<div class="text-center">No records available</div>',
        totalMessage: "record",
        selectedMessage: "selected", // Footer selected message
      },
    }),
    GridModule,
    TranslateModule,
    CoreCommonModule,
    BrowserModule,
    DataTablesModule,
    NgbModule,
    NgSelectModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    CsvModule,
    FiltersModule,
    PipesModule,
    DialogsModule,
    LayoutModule,
    InputsModule,
    ControlsModule,

    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ButtonsModule,
    DropDownListModule,
    UploadsModule,

    PhotoModule,
  ],
})
export class StudentsModule {}
