import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AppComponent } from "app/app.component";
import { User } from "app/auth/models";
import { AuthenticationService } from "app/auth/service";
import { GuardianSelectorsComponent } from "app/main/Guardians/GuardianSelectors/guardian-selectors.component";
import { ClientGuardiansService } from "app/main/Guardians/services/client-guardians.service";
import { ToastrService } from "ngx-toastr";
import {
  HomeAddressStudentModel,
  AddressStudentModel,
} from "app/main/Students/model/AddressStudentModel";
import { ClientStudentsService } from "app/main/Students/services/client-students.service";

@Component({
  selector: "app-gurdians-add-new-home-address",
  templateUrl: "./gurdians-add-new-address.component.html",
  styleUrls: ["./gurdians-add-new-address.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class GurdiansAddNewAddressComponent implements OnInit {
  public submitted = false;
  public newAddressform: FormGroup;
  @ViewChild("AddNewHomeAddress") AddNewHomeAddress: TemplateRef<any>;
  @ViewChild("EditHomeAddress") EditHomeAddress: TemplateRef<any>;
  validationError = "This field is required, please select or insert a value.";
  // There seems to be an issue with this address, please enter it again.
  states = [
    "After School Daycare",
    "After School Program",
    "Daycare",
    "Home",
    "Grandfather’s Home",
    "Grandmother’s Home",
    "Grandparents Home",
  ];
  validation = {
    Valid: false,
    validationError:
      "There seems to be an issue with this address, please enter it again.",
  };
  @ViewChild(GuardianSelectorsComponent, { static: true })
  guardianSelectorsComponent: GuardianSelectorsComponent;
  @Output() Added = new EventEmitter<HomeAddressStudentModel>();
  @Input() gridData: any = [];
  public data: any = null;
  TimeZoneId: number = 0;
  //#region google maps configurations
  public autocomplete: google.maps.places.Autocomplete;
  address1Field: HTMLInputElement;
  address2Field: HTMLInputElement;
  postalField: HTMLInputElement;
  validRelationShip: boolean = false;
  validStudent: boolean = false;
  validGuardian: boolean = false;
  public _currentUser: User;
  guardianfilterres = [];
  gurdianId: number = 0;
  selectedCountry: string;
  public title: string;

  /**
   * Constructor
   * * @param {AuthenticationService} _authenticationService
   * * @param {HelpersService} _helpersService
   * * @param {ClientGuardiansService} _clientGurdiansService
   * * @param {NgbModal} _modalService
   * * @param {ToastrService} _toastrService
   * * @param {FormBuilder} _formBuilder
   * * @param {AppComponent} _appcomponent
   */
  constructor(
    private _formBuilder: FormBuilder,
    private _authenticationService: AuthenticationService,
    private _toastrService: ToastrService,
    private _modalService: NgbModal,
    private _clientGurdiansService: ClientGuardiansService,
    private _appcomponent: AppComponent,
    private route: ActivatedRoute,
    private _clientStudentService: ClientStudentsService,
  ) {
    this._authenticationService.currentUser.subscribe(
      x => (this._currentUser = x),
    );
  }

  ngOnInit(): void {
    if (this.route.snapshot.paramMap.get("id")) {
      this.gurdianId = parseInt(this.route.snapshot.paramMap.get("id")!);
    }
    this.newAddressform = this._formBuilder.group({
      Name: ["", [Validators.required]],
      FullAddress: ["", [Validators.required]],
      Country: [""],
      City: [""],
      State: [""],
      County: [""],
      ZipCode: [""],
      Street: [""],
      Street2: [""],
      Latitude: [""],
      Longitude: [""],
      TimeZone: [""],
    });
  }

  public isValidForm(): boolean {
    let that = this;

    for (const control of Object.keys(that.newAddressform.controls)) {
      that.newAddressform.controls[control].markAsTouched();
    }

    return that.newAddressform.invalid;
  }

  public open(title) {
    this.title = title;
    let that = this;
    if (this.data != null) {
      that.newAddressform.setValue({
        Name: that.data.Name,
        FullAddress: that.data.Location.FullAddress,
        State: that.data.Location.State,
        County: that.data.Location.County,
        Country: that.data.Location.Country,
        City: that.data.Location.City,
        ZipCode: that.data.Location.Zipcode,
        Street: that.data.Location.Street1,
        Street2: that.data.Location.Street2,
        Latitude: that.data.Location.Latitude,
        Longitude: that.data.Location.Longitude,
        TimeZone: "",
      });
      that.fillTimeZone(
        that.data.Location.Latitude,
        that.data.Location.Longitude,
      );
      that.newAddressform.get("FullAddress").disable();
      that._modalService.open(that.AddNewHomeAddress, {
        centered: true,
        backdrop: "static",
        size: "md",
      });
      setTimeout(() => {
        that.initAutocomplete();
      }, 40);
    } else {
      that.newAddressform.get("FullAddress").enable();
      that._modalService.open(this.AddNewHomeAddress, {
        centered: true,
        backdrop: "static",
        size: "md",
      });
      setTimeout(() => {
        that.initAutocomplete();
      }, 40);
    }
  }

  close(model: any) {
    let that = this;
    for (const control of Object.keys(that.newAddressform.controls)) {
      that.newAddressform.controls[control].markAsUntouched();
    }
    that.newAddressform.reset();

    this.TimeZoneId = 0;
    model.close("Accept click");
  }

  onSubmit(modal: any) {
    let that = this;

    if (this.data == null) {
      that.submitted = true;
      if (that.gurdianId != 0) {
        if (!that.isValidForm()) {
          that.submitValidForm(modal);
        } else {
          that.submitNotValidForm(modal);
        }
      }
    } else {
      that.submitDefault(modal);
    }
  }
  submitNotValidForm(modal) {
    let that = this;
    let valid = this.validAddress(
      that.newAddressform.value.Latitude,
      that.newAddressform.value.Longitude,
    );
    if (!valid) {
      this.validation.validationError =
        "There seems to be an issue with this address, please enter it again.";
      this.validation.Valid = true;
    }
    if (!that.isValidForm() && valid) {
      that.Added.emit(that.getAddAddressObject());
      that.close(modal);
    }
  }
  submitValidForm(modal) {
    let that = this;
    that._appcomponent.toggeleLoader();
    that._clientGurdiansService
      .addNewAddres(that.getAddAddressObject())
      .subscribe(
        (Response: any) => {
          that._toastrService.success(
            "The address was added successfully.",
            "",
          );
          that.Added.emit(Response);
          that.close(modal);
          that._appcomponent.toggeleLoader();
        },
        error => {
          if (error.status == 401) {
            that._toastrService.error("UnAuthorized.", "");
            window.location.href = "/pages/authentication/login";
            that._appcomponent.toggeleLoader();
          } else if (error.status == 300) {
            that._toastrService.error(error.error.Message, "");
            that._appcomponent.toggeleLoader();
          } else {
            that._toastrService.error(
              "The address was not added due to technical issue.",
              "",
            );
            that._appcomponent.toggeleLoader();
          }
        },
      );
  }
  submitDefault(modal) {
    let that = this;
    that.submitted = true;
    if (that.gurdianId != 0) {
      if (!that.isValidForm()) {
        that._appcomponent.toggeleLoader();
        that._clientGurdiansService
          .editAdress(that.gurdianId, that.getAddAddressObject())
          .subscribe(
            (Response: any) => {
              that._toastrService.success(
                "The address was update successfully.",
                "",
              );
              that.Added.emit(Response);
              that.close(modal);
              that._appcomponent.toggeleLoader();
            },
            error => {
              if (error.status == 401) {
                that._toastrService.error("UnAuthorized.", "");
                window.location.href = "/pages/authentication/login";
                that._appcomponent.toggeleLoader();
              } else if (error.status == 300) {
                that._toastrService.error(error.error.Message, "");
                that._appcomponent.toggeleLoader();
              } else {
                that._toastrService.error(
                  "The address was not update due to technical issue.",
                  "",
                );
                that._appcomponent.toggeleLoader();
              }
            },
          );
      }
    }
  }
  getAddAddressObject() {
    let that = this;
    let data = new HomeAddressStudentModel();

    data.ParentId = this.gurdianId;
    let location = new AddressStudentModel();

    if (that.data == null) {
      location.LocationId = 0;
      location.FullAddress = that.newAddressform.value.FullAddress;
    } else {
      location.LocationId = that.data.Location.LocationId;
      location.FullAddress = that.data.Location.FullAddress;
    }

    location.City = that.newAddressform.value.City;
    location.Country = that.newAddressform.value.County;
    location.County = that.newAddressform.value.County;
    location.CustomLatitude = Number(
      that.newAddressform.value.Latitude,
    ).toFixed(8);
    location.CustomLongitude = Number(
      that.newAddressform.value.Longitude,
    ).toFixed(8);
    location.Latitude = that.newAddressform.value.Latitude;
    location.Longitude = that.newAddressform.value.Longitude;
    location.State = that.newAddressform.value.State;
    location.Street1 = that.newAddressform.value.Street;
    location.Street2 = that.newAddressform.value.Street2;
    location.TimeZoneId = that.TimeZoneId;
    location.ZipCode = that.newAddressform.value.ZipCode;
    data.Location = location;
    data.Name = that.newAddressform.value.Name;
    data.IsAddedFromClientPortal = true;
    data.Id = 0;
    data.Type = "Home";

    return data;
  }
  fillTimeZone(lat, lang) {
    let that = this;
    this._clientStudentService.getGoogleAPIKey().subscribe(data => {
      let key = data.PropertyValue;
      this._clientStudentService.GetTimezone(lat, lang, key).subscribe(
        response => {
          this._clientStudentService
            .getTimeZoneData(response.timeZoneId)
            .subscribe(
              data => {
                if (data.length > 0) {
                  that.newAddressform
                    .get("TimeZone")
                    .setValue(data[0].shortName);
                  that.TimeZoneId = data.id;
                }
              },
              error => {
                if (error.status == 401) {
                  that._toastrService.error("UnAuthorized.", "");
                  window.location.href = "/pages/authentication/login";
                } else {
                  that._toastrService.error(error.error.Message, "");
                }
              },
            );
        },
        error => {
          if (error.status == 401) {
            that._toastrService.error("UnAuthorized.", "");
            window.location.href = "/pages/authentication/login";
          } else {
            that._toastrService.error(error.error.Message, "");
          }
        },
      );
    });
  }
  //#region Google maps configurations
  initAutocomplete() {
    this.address1Field = document.querySelector("#FullAddress");
    this.address2Field = document.querySelector("#Street2");
    this.postalField = document.querySelector("#postcode");

    // Create the autocomplete object, restricting the search predictions to
    // addresses in the US and Canada.
    this.autocomplete = new google.maps.places.Autocomplete(
      this.address1Field,
      {
        componentRestrictions: { country: ["us", "jo"] },

        strictBounds: true,
      },
    );

    // When the user selects an address from the drop-down, populate the
    // address fields in the form.
    let that = this;
    this.autocomplete.addListener("place_changed", () => {
      const place = that.autocomplete.getPlace();
      let address1 = place.formatted_address;
      let postcode = "";
      let ST1: string = "";
      let ST2: string = "";
      let addressModel = new AddressStudentModel();
      // Get each component of the address from the place details,
      // and then fill-in the corresponding field on the form.
      // place.address_components are google.maps.GeocoderAddressComponent objects
      // which are documented at http://goo.gle/3l5i5Mr
      for (const component of place.address_components) {
        // @ts-ignore remove once typings fixed
        const componentType = component.types[0];
        switch (componentType) {
          case "street_number": {
            ST1 = component.long_name;
            break;
          }

          case "route": {
            ST2 = component.long_name;
            break;
          }

          case "postal_code": {
            postcode = `${component.long_name}${postcode}`;
            break;
          }

          case "locality":
            document.querySelector("#locality").ariaValueNow =
              component.long_name;

            addressModel.City = component.long_name;
            break;

          case "administrative_area_level_1": {
            document.querySelector("#state").ariaValueNow =
              component.short_name;
            addressModel.State = component.short_name;

            break;
          }
          case "administrative_area_level_2": {
            document.querySelector("#County").ariaValueNow =
              component.long_name;
            addressModel.County = component.long_name;

            break;
          }
          case "country":
            document.querySelector("#country").ariaValueNow =
              component.short_name;
            addressModel.Country = component.short_name;

            break;
        }
      }
      let lat: HTMLInputElement = document.querySelector("#Latitude");
      let lang: HTMLInputElement = document.querySelector("#Longitude");
      let street: HTMLInputElement = document.querySelector("#street");
      street.value = ST1 + " " + ST2;
      lat.value = place.geometry.location.lat().toString();
      lang.value = place.geometry.location.lng().toString();
      that.address1Field.value = address1;
      that.postalField.value = postcode;
      this._clientStudentService.getGoogleAPIKey().subscribe(data => {
        let key = data.PropertyValue;
        this._clientStudentService
          .GetTimezone(lat.value, lang.value, key)
          .subscribe(
            response => {
              this._clientStudentService
                .getTimeZoneData(response.timeZoneId)
                .subscribe(
                  data => {
                    let timeZone: HTMLInputElement =
                      document.querySelector("#TimeZone");
                    if (data.length > 0) {
                      timeZone.value = data[0].shortName;
                      that.TimeZoneId = data[0].id;
                    }
                    that.setAddressValue(
                      addressModel,
                      address1,
                      postcode,
                      street,
                      lat,
                      lang,
                      timeZone,
                    );
                  },
                  error => {
                    that.errorHandler(error);
                  },
                );
            },
            error => {
              that.errorHandler(error);
            },
          );
      });
    });
  }
  //#endregion
  validAddress(lat, lng) {
    if (this.isValidForm() && this.newAddressform.value.FullAddress != "") {
      this.validation.Valid = true;
      return false;
    } else if (Number(lat) > 90 && Number(lat) < -90) {
      return false;
    } else if (Number(lng) > 180 && Number(lng) < -180) {
      return false;
    } else {
      return true;
    }
  }
  errorHandler(error) {
    let that = this;
    if (error.status == 401) {
      that._toastrService.error("UnAuthorized.", "");
      window.location.href = "/pages/authentication/login";
    } else {
      that._toastrService.error(error.error.Message, "");
    }
  }
  setAddressValue(
    addressModel,
    address1,
    postcode,
    street,
    lat,
    lang,
    timeZone,
  ) {
    let that = this;
    let name = that.newAddressform.value.Name;
    that.newAddressform.setValue({
      Name: name,
      FullAddress: address1,
      State: addressModel.State,
      County: !addressModel.County ? "" : addressModel.County,
      Country: !addressModel.Country ? "" : addressModel.Country,
      City: !addressModel.City ? "" : addressModel.City,
      ZipCode: !postcode ? "" : postcode,
      Street: street.value == " " ? "" : street.value,
      Street2: that.newAddressform.value.Street2,
      Latitude: lat.value,
      Longitude: lang.value,
      TimeZone: timeZone.value,
    });
  }
}
