import { Component, OnInit, Input, HostListener, OnDestroy, ViewEncapsulation, ElementRef } from "@angular/core";
import { LayoutService } from "@core/services/layout.service";
import { fadeInLeft, zoomIn, fadeIn } from "@core/animations/core.animation";

import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

import { CoreConfigService } from "@core/services/config.service";
@Component({
  selector: "app-content-section",
  templateUrl: "./content-section.component.html",
  styleUrls: ["./content-section.component.scss"],
  encapsulation: ViewEncapsulation.None,
  animations: [fadeInLeft, zoomIn, fadeIn],
})
export class ContentSectionComponent implements OnInit, OnDestroy {
  screenTitle = "Page Title";
  contentHeight: number;
  @Input() navLayout: string;
  @Input() defaultNavbar: string;
  @Input() toggleNavbar: string;
  @Input() toggleStatus: boolean;
  @Input() navbarEffect: string;
  @Input() deviceType: string;
  @Input() headerColorTheme: string;
  @Input() navbarColorTheme: string;
  @Input() activeNavColorTheme: string;
  coreConfig: any;

  // Private
  private _unsubscribeAll: Subject<any>;

  /**
   * Constructor
   *
   * @param {CoreConfigService} _coreConfigService
   */

  public animate;
  constructor(
    private layoutService: LayoutService,
    private _coreConfigService: CoreConfigService,
    private _elementRef: ElementRef,
  ) {
    this._unsubscribeAll = new Subject();
  }

  ngOnInit() {
    this.layoutService.contentHeightCast.subscribe(
      setCtHeight => (this.contentHeight = setCtHeight),
    );

    // Subscribe to config changes
    this._coreConfigService.config
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(config => {
        this.coreConfig = config;
      });
  }

  onScroll() {
   //$("div").not(".user-nav").click();
  }
  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
  @HostListener("window:resize", ["$event"])
  onResizeHeight(event: any) {
    this.contentHeight = window.innerHeight - this.layoutService.headerHeight;
  }

  /**
   * Zoom In Animation
   *
   * @param outlet
   */
  zoomIn(outlet) {
    if (this.animate === "zoomIn") {
      return outlet.activatedRouteData.animation;
    }
    return null;
  }

  /**
   * Fade In Animation
   *
   * @param outlet
   */
  fadeIn(outlet) {
    if (this.animate === "fadeIn") {
      return outlet.activatedRouteData.animation;
    }
    return null;
  }
  /**
   * Fade In Left Animation
   *
   * @param outlet
   */
  fadeInLeft(outlet) {
    if (this.animate === "fadeInLeft") {
      return outlet.activatedRouteData.animation;
    }
    return null;
  }
}
