import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AuthenticationService } from "app/auth/service";
import { environment } from "environments/environment";
import moment from "moment";
import { AddEditStudentDataModel } from "../model/AddEditStudentDataModel";
import { HomeAddressStudentModel } from "../model/AddressStudentModel";
import { ClientStudentsFilterModel } from "../model/ClientStudentsFilterModel";
import { GuardianDataModel } from "../model/GuardianDataModel";
import { AddTransportaionScheduleStudentDataModel } from "../model/ScheduleStudentDataModel";
import { StudentContactModel } from "../model/StudentContactModel";
import { StudentInstructionModel } from "../model/StudentInstructionModel";
import { StudentServiceCancelationModel } from "../model/StudentServiceCancelationModel";
import { UploadFileModel } from "../model/UploadFileModel";

@Injectable({ providedIn: "root" })
export class ClientStudentsService {
  /**
   * Constructor
   *
   * @param {HttpClient} _httpClient
   */
  constructor(
    private _httpClient: HttpClient,
    private _authenticationService: AuthenticationService,
  ) {}

  /**
   * Get rows
   */
  addEditStudent(addEditStudentDataModel: AddEditStudentDataModel) {
    if (addEditStudentDataModel.Id == 0) {
      return this._httpClient.post<any>(
        `${environment.apiUrl}/students/`,
        addEditStudentDataModel,
      );
    }
    return this._httpClient.put<any>(
      `${environment.apiUrl}/students/${addEditStudentDataModel.Id}?is_client_portal=true`,
      addEditStudentDataModel,
    );
  }

  getDataTableRows(filterModel?: ClientStudentsFilterModel) {
    return this._httpClient.post<any[]>(
      `${environment.apiUrl}/clients/students`,
      filterModel,
    );
  }

  getPlaces() {
    let data = {
      ClientId: this._authenticationService.currentUserValue.client_id,
      viewType: "StudentRequestDropdownClient",
    };

    return this._httpClient.post<any>(`${environment.apiUrl}/places-all`, data);
  }

  importBulkStudnet(formData: FormData) {
    return this._httpClient.post<any>(
      `${environment.apiUrl}/import/bulkStudents?clientId=${this._authenticationService.currentUserValue.client_id}&isClientPortal=true`,
      formData,
    );
  }

  getPopulationTypes() {
    return this._httpClient.get<any>(
      `${environment.apiUrl}/students/population-types/`,
    );
  }

  getschoolGrades() {
    return this._httpClient.get<any>(
      `${environment.apiUrl}/students/school-grades/`,
      {
        params: { archived: false },
      },
    );
  }

  getSpecialEdCategories() {
    return this._httpClient.get<any>(
      `${environment.apiUrl}/students/special-ed-categories`,
    );
  }

  getClientMonitors() {
    let data = {
      clientId: this._authenticationService.currentUserValue.client_id,
      viewType: "createrideblueprintdropdown",
      canBeRideAttendant: true,
    };
    return this._httpClient.post<any>(
      `${environment.apiUrl}/assistants-all/`,
      data,
    );
  }

  getRideNeeds() {
    return this._httpClient.get<any>(
      `${environment.apiUrl}/students/ride-needs/`,
    );
  }

  addStudentContact(contactModel: StudentContactModel) {
    let result = this._httpClient.post<any>(
      `${environment.apiUrl}/contacts`,
      contactModel,
    );
    return result;
  }

  editStudentContact(contactModel: StudentContactModel) {
    let result = this._httpClient.put<any>(
      `${environment.apiUrl}/contacts`,
      contactModel,
    );
    return result;
  }

  deleteStudentContact(id: number) {
    let result = this._httpClient.delete<any>(
      `${environment.apiUrl}/contacts/${id}?isClientPortal=true`,
    );
    return result;
  }

  checkAllowDeleteAddress(addressParentId: number, selectedAddressId: number) {
    return this._httpClient.get<any>(
      `${environment.apiUrl}/students/${addressParentId}/places/${selectedAddressId}/check-allow-delete`,
    );
  }

  checkExistMobileNumber(Id: number, mobileNumber: string) {
    let queryParams = new HttpParams();
    queryParams = queryParams.append("Id", Id);
    queryParams = queryParams.append("mobile_number", mobileNumber);
    return this._httpClient.get<any>(
      `${environment.apiUrl}/accounts/check-exists-mobile-number`,
      { params: queryParams },
    );
  }

  checkExistEmail(Id: number, mobileNumber: string) {
    let queryParams = new HttpParams();
    queryParams = queryParams.append("Id", Id);
    queryParams = queryParams.append("email", mobileNumber);
    return this._httpClient.get<any>(
      `${environment.apiUrl}/accounts/check-exists-email`,
      { params: queryParams },
    );
  }

    GetTimezone(latitude: any, longitude: any, key: any) {
        let addressModel = {
            address: {
                latitude: latitude,
                longitude: longitude,
            },
            addressName: ''
        };
      return this._httpClient.post<any>(
          `${environment.apiUrl}/google-map/timezone`,
          addressModel,
      );
  }
  getGoogleAPIKey() {
    let propertyName = "GoogleAPIKeyClient";
    return this._httpClient.get<any>(
      `${environment.apiUrl}/configurations/${propertyName}/name`,
    );
  }
  getTimeZoneData(name: any) {
    return this._httpClient.get<any>(`${environment.apiUrl}/timezone`, {
      params: {
        name: name,
      },
    });
  }

  addNewHomerAddress(homeAddressModel: HomeAddressStudentModel) {
    return this._httpClient.post<any>(
      `${environment.apiUrl}/students/${homeAddressModel.ParentId}/places?is_client_portal=true`,
      homeAddressModel,
    );
  }

  editHomerAddress(homeAddressModel: HomeAddressStudentModel) {
    return this._httpClient.put<any>(
      `${environment.apiUrl}/students/${homeAddressModel.ParentId}/places/${homeAddressModel.Id}?is_client_portal=true`,
      homeAddressModel,
    );
  }

  addScheduleStudent(
    addTransportaionScheduleStudentDataModel: AddTransportaionScheduleStudentDataModel,
  ) {
    return this._httpClient.post<any>(
      `${environment.apiUrl}/students/${addTransportaionScheduleStudentDataModel.StudentSchedules[0].StudentId}/schedule`,
      addTransportaionScheduleStudentDataModel,
    );
  }

  addStuedntInstruction(
    studentInstructionModel: StudentInstructionModel,
    studentId: number,
  ) {
    let result = this._httpClient.post<any>(
      `${environment.apiUrl}/students/${studentId}/instructions`,
      studentInstructionModel,
    );
    return result;
  }

  editStuedntInstruction(
    studentInstructionModel: StudentInstructionModel,
    studentId: number,
  ) {
    let result = this._httpClient.put<any>(
      `${environment.apiUrl}/students/${studentId}/instructions`,
      studentInstructionModel,
    );
    return result;
  }

  addStuedntServiceCancelation(
    studentServiceCancelationModel: StudentServiceCancelationModel[],
    studentId: number,
  ) {
    return this._httpClient.post<any>(
      `${environment.apiUrl}/students/${studentId}/service-cancelations?isClientPortal=true`,
      { StudentCancelations: studentServiceCancelationModel },
    );
  }

  editStuedntServiceCancelation(
    studentServiceCancelationModel: StudentServiceCancelationModel[],
    studentId: number,
  ) {
    return this._httpClient.put<any>(
      `${environment.apiUrl}/students/${studentId}/service-cancelations?isClientPortal=true`,
      studentServiceCancelationModel[0],
    );
  }

  uploadFile(uploadFileModel: UploadFileModel) {
    return this._httpClient.post<any>(
      `${environment.apiUrl}/files/uploadFile`,
      uploadFileModel,
    );
  }

  updateFile(fileId: number, uploadFileModel: UploadFileModel) {
    return this._httpClient.put<any>(
      `${environment.apiUrl}/files/${fileId}?isClientPortal=true`,
      uploadFileModel,
    );
  }

  blockGurdian(id: number, isBlocked: boolean) {
    return this._httpClient.patch<any>(
      `${environment.apiUrl}/guardians/${id}`,
      {
        Id: id,
        IsBlock: !isBlocked,
      },
    );
  }

  deleteGurdian(id: number) {
    return this._httpClient.delete<any>(
      `${environment.apiUrl}/guardians/${id}`,
    );
  }

  archiveGurdian(id: number, isArchived: boolean) {
    return this._httpClient.patch<any>(
      `${environment.apiUrl}/guardians/${id}`,
      {
        Id: id,
        IsArchive: !isArchived,
      },
    );
  }

  checkGuardianExist(guardianId, studentId, guardianData: GuardianDataModel) {
    return this._httpClient.post<any>(
      `${environment.apiUrl}/guardians/${guardianId}/students/${studentId}/`,
      guardianData,
    );
  }

  addNewGuardian(studentId, guardianData: GuardianDataModel) {
    return this._httpClient.post<any>(
      `${environment.apiUrl}/students/${studentId}/guardians`,
      guardianData,
    );
  }

  editGuardian(
    studentId,
    student_guardian_id: number,
    guardianData: GuardianDataModel,
  ) {
    return this._httpClient.put<any>(
      `${environment.apiUrl}/students/${studentId}/guardians/${student_guardian_id}`,
      guardianData,
    );
  }

  downloadFile(uploadFileModel: UploadFileModel) {
    return this._httpClient.post<any>(
      `${environment.apiUrl}/file/download-file`,
      uploadFileModel,
    );
  }

  getStudentById(id: number) {
    let result = this._httpClient.get<any>(
      `${environment.apiUrl}/students/${id}`,
    );
    return result;
  }

  getStudentGuardians(id: number) {
    let url = `${environment.apiUrl}/students/${id}/guardians`;
    let result = this._httpClient.get<any>(url);
    return result;
  }

  getContacts(id: number) {
    let url = `${environment.apiUrl}/students/${id}/contacts`;
    let result = this._httpClient.get<any>(url);
    return result;
  }

  getStudentAddresses(id: number) {
    let url = `${environment.apiUrl}/students/${id}/places?type=Home`;
    let result = this._httpClient.get<any>(url);
    return result;
  }

  getStudentPlaces(id: number) {
    let url = `${environment.apiUrl}/students/${id}/places?type=Destination`;
    let result = this._httpClient.get<any>(url);
    return result;
  }

  getUpcomingServiceCancelations(id: number) {
    let RecurrenceFrequency = "Once";
    let SpecificDateType = "upcoming";
    let date = moment().format("YYYY-MM-DD");
    let url = `${environment.apiUrl}/students/${id}/service-cancelations?recurrence_frequency=${RecurrenceFrequency}&specific_type=${SpecificDateType}&effective_date=${date}`;
    let result = this._httpClient.get<any>(url);
    return result;
  }

  getRecurrentServiceCancelations(id: number) {
    let RecurrenceFrequency = "Weekly";
    let SpecificDateType = "";
    let date = moment().format("YYYY-MM-DD");
    let url = `${environment.apiUrl}/students/${id}/service-cancelations?recurrence_frequency=${RecurrenceFrequency}&specific_type=${SpecificDateType}&effective_date=${date}`;
    let result = this._httpClient.get<any>(url);
    return result;
  }

  getPastServiceCancelations(id: number) {
    let RecurrenceFrequency = "Once";
    let SpecificDateType = "past";
    let date = moment().format("YYYY-MM-DD");
    let url = `${environment.apiUrl}/students/${id}/service-cancelations?recurrence_frequency=${RecurrenceFrequency}&specific_type=${SpecificDateType}&effective_date=${date}`;
    let result = this._httpClient.get<any>(url);
    return result;
  }

  getInstructions(id: number) {
    let url = `${environment.apiUrl}/students/${id}/instructions`;
    let result = this._httpClient.get<any>(url);
    return result;
  }

  getAttachments(id: number) {
    let url = `${environment.apiUrl}/students/${id}/attachments`;
    let result = this._httpClient.get<any>(url);
    return result;
  }

  deleteInstruction(id: number) {
    let result = this._httpClient.delete<any>(
      `${environment.apiUrl}/students/${id}/instructions`,
    );
    return result;
  }

  deleteStudentAddress(studentId: number, student_place_id: number, data: any) {
    let httpParams = new HttpParams();
    httpParams.set("updateStatusModel", data);

    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
      body: data,
    };

    let url = `${environment.apiUrl}/students/${studentId}/places/${student_place_id}?is_client_portal=true`;
    let result = this._httpClient.delete<any>(url, httpOptions);
    return result;
  }

  deleteStudentServiceCancelation(
    studentId: number,
    service_cancelation_id: number,
  ) {
    let result = this._httpClient.delete<any>(
      `${environment.apiUrl}/students/${studentId}/service-cancelations/${service_cancelation_id}?isClientPortal=true`,
    );
    return result;
  }

  deleteAttachment(id: number) {
    let result = this._httpClient.delete<any>(
      `${environment.apiUrl}/files/${id}?isClientPortal=true`,
    );
    return result;
  }

  getStudentTransportationSchedules(id: number) {
    let url = `${environment.apiUrl}/students/${id}/transportation-schedule`;
    let result = this._httpClient.get<any>(url);
    return result;
  }

  getPlaceShedules(placeId: number) {
    let url = `${environment.apiUrl}/places/${placeId}/schedules`;
    let result = this._httpClient.get<any>(url);
    return result;
  }

  getPlace() {
    let data = {
      state: "",
      viewType: "dropdown",
    };
    let url = `${environment.apiUrl}/places-all/`;
    let result = this._httpClient.post<any>(url, data);
    return result;
  }

  createUpdateStudentPlace(studentId: number, studentPlaceModel: any) {
    let url = `${environment.apiUrl}/students/${studentId}/places`;
    if (studentPlaceModel.Id != 0) {
      //put
      return this._httpClient.put<any>(
        `${url}/${studentPlaceModel.Id}?is_client_portal=true`,
        studentPlaceModel,
      );
    }
    //post
    return this._httpClient.post<any>(
      `${url}?is_client_portal=true`,
      studentPlaceModel,
    );
  }
}
