import {
  AfterContentChecked,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Self,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { ControlValueAccessor, NgControl, Validators } from "@angular/forms";
import { NgbTypeahead } from "@ng-bootstrap/ng-bootstrap";
import { Observable, Subject, merge, OperatorFunction } from "rxjs";
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
} from "rxjs/operators";

@Component({
  selector: "app-type-head",
  templateUrl: "./typea-head.component.html",
  styleUrls: ["./typea-head.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class TypeaHeadComponent
  implements OnInit, AfterContentChecked, ControlValueAccessor
{
  @Output() onOpen = new EventEmitter<string>();
  @Input() placeholder: string = "";
  @Input() states: any = [];
  @Output() blur: EventEmitter<void> = new EventEmitter<void>();
  @Input() disabled: boolean = false;
  onChange: (value: any) => void = () => {};
  onTouched: () => void = () => {};
  input: string;
  @Input() maxlength: number = 50;
  @ViewChild("instance", { static: true }) instance: NgbTypeahead;
  focus$ = new Subject<string>();
  click$ = new Subject<string>();
  public model: string = "";
  constructor(@Self() public controlDir: NgControl) {
    controlDir.valueAccessor = this;
  }

  ngOnInit(): void {
    const control = this.controlDir.control;
    const validators = control?.validator
      ? [control.validator, Validators.required]
      : null;
    control?.clearValidators();
    control?.setValidators(validators);
    control?.updateValueAndValidity();
  }

  writeValue(value: any): void {
    this.input = value;
  }

  registerOnChange(onChange: (value: any) => void): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: () => void): void {
    this.onTouched = onTouched;
  }

  setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
  }
  ngAfterContentChecked(): void {
    this.emitEvent();
  }
  emitEvent() {
    this.onOpen.emit();
  }
  changes(event) {
    this.onChange(event);
  }
  search: OperatorFunction<string, readonly string[]> = (
    text$: Observable<string>,
  ) => {
    const debouncedText$ = text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
    );
    const clicksWithClosedPopup$ = this.click$.pipe(
      filter(() => !this.instance.isPopupOpen()),
    );
    const inputFocus$ = this.focus$;

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      map(term =>
        (term === ""
          ? this.states
          : this.states.filter(
              v => v.toLowerCase().indexOf(term.toLowerCase()) > -1,
            )
        ).slice(0, 10),
      ),
    );
  };
}
