import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TabPosition } from "@progress/kendo-angular-layout";
import { AppComponent } from "app/app.component";
import { User } from "app/auth/models";
import { AuthenticationService } from "app/auth/service";
import { HelpersService } from "app/main/SharedServices/helpers.service";
import { ToastrService } from "ngx-toastr";
import { ClientStudentsService } from "../../services/client-students.service";
import {
  AddTransportaionScheduleStudentDataModel,
  ScheduleStudentDataModel,
  TransportaionScheduleStudentDataModel,
} from "../../model/ScheduleStudentDataModel";
import { ScheduleDaysComponent } from "../ScheduleDays/schedule-days.component";
import { TransportationStudentsService } from "../../services/transportation.service";
import moment from "moment";
import { PermissionsList } from "app/enums/Permissions/PermissionList";

@Component({
  selector: "app-student-add-new-transportation-schedule",
  templateUrl: "./student-add-new-transportation-schedule.component.html",
  styleUrls: ["./student-add-new-transportation-schedule.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class StudentAddNewTransportationScheduleComponent implements OnInit {
  public submitted = false;
  public PageTitle: string;
  @Input() AddressGridData: any[];
  @Input() PlaceGridData: any[];
  @Input() GridData: any[];
  @Input() StudentScheduleDetails: any[];
  @Input("disable") disable: boolean = true;
  public disabledAdd: boolean = true;
  public PermessionValue: string;
  isValidDays: boolean = true;
  attentionInfo: boolean = false;
  public newScheduleForm: FormGroup;
  public formDays: FormGroup;
  public position: TabPosition = "top";
  @ViewChild("AddNewScheduleModal") AddNewScheduleModal: TemplateRef<any>;
  @ViewChild("daysList") public scheduleDays: ScheduleDaysComponent;
  @Output() Added = new EventEmitter<any>();
  validRelationShip: boolean = false;
  public _currentUser: User;
  studentId = 0;
  guardianexist: boolean = false;
  public data: any;
  ServiceStartDateInitialDate: Date;
  ServiceEndDateInitialDate: Date;
  numbersInWord = [
    "Default Schedule",
    "Second Schedule",
    "Third Schedule",
    "Fourth Schedule",
    "Fifth Schedule",
    "Sixth Schedule",
    "Seventh Schedule",
    "Eighth Schedule",
    "Ninth Schedule",
    "Tenth Schedule",
  ];
  /**
   * Constructor
   * * @param {AuthenticationService} _authenticationService
   * * @param {HelpersService} _helpersService
   * * @param {ClientStudentsService} _clientStudentService
   * * @param {NgbModal} _modalService
   * * @param {ToastrService} _toastrService
   * * @param {FormBuilder} _formBuilder
   * * @param {AppComponent} _appcomponent
   */
  constructor(
    private fb: FormBuilder,
    private _authenticationService: AuthenticationService,
    private _toastrService: ToastrService,
    private _modalService: NgbModal,
    private _helperService: HelpersService,
    private _appcomponent: AppComponent,
    private _clientStudentService: ClientStudentsService,
    private _transportationStudentsService: TransportationStudentsService,
    private route: ActivatedRoute, //private resolver: ComponentFactoryResolver
  ) {
    this._authenticationService.currentUser.subscribe(
      x => (this._currentUser = x),
    );
  }

  ngOnInit(): void {
    this.disabledAdd = !this.disable;
    let that = this;
    if (this.route.snapshot.paramMap.get("id")) {
      this.PermessionValue = PermissionsList.ClientStudentsEdit.toString();
      this.studentId = parseInt(this.route.snapshot.paramMap.get("id")!);
    } else {
      this.PermessionValue = PermissionsList.ClientStudentsCreate.toString();
    }
    that.initialForm();
  }

  initialForm() {
    let that = this;

    let defualtName: string = "Default Schedule";
    if (that.GridData) {
      defualtName = that.numbersInWord[that.GridData.length];
    }
    that.newScheduleForm = that.fb.group({
      Name: [defualtName, [Validators.required]],
      ServiceStartDate: ["", [Validators.required]],
      ServiceEndDate: ["", [Validators.required]],
      InternalNote: [""],
    });
    that.ServiceStartDateInitialDate = null;
    that.ServiceEndDateInitialDate = null;

    that.newScheduleForm
      .get("ServiceStartDate")
      .valueChanges.subscribe(serviceStartDate => {
        if (
          serviceStartDate > that.newScheduleForm.get("ServiceEndDate").value
        ) {
          that.ServiceEndDateInitialDate = serviceStartDate;
          that.newScheduleForm.get("ServiceEndDate").setValue(serviceStartDate);
        }
      });

    that.newScheduleForm
      .get("ServiceEndDate")
      .valueChanges.subscribe(serviceEndDate => {
        if (
          serviceEndDate < that.newScheduleForm.get("ServiceStartDate").value
        ) {
          that.ServiceStartDateInitialDate = serviceEndDate;
          that.newScheduleForm.get("ServiceStartDate").setValue(serviceEndDate);
        }
      });

    //dropOff1(Outbound) + pickup2(Return)
    that.formDays = that.fb.group({
      days2: that.fb.group({
        /*Outbound*/
        pickup1: ["", [Validators.required]],
        dropOff1: ["", [Validators.required]],
        /*Return*/
        dropOff2: ["", [Validators.required]],
        pickup2: ["", [Validators.required]],
        /*Time*/
        time1: [""],
        time2: [""],

        checkP1: [""],
        checkP2: [""],
        ReturnError: [""],
      }),
      days3: that.fb.group({
        /*Outbound*/
        pickup1: ["", [Validators.required]],
        dropOff1: ["", [Validators.required]],
        /*Return*/
        dropOff2: ["", [Validators.required]],
        pickup2: ["", [Validators.required]],
        /*Time*/
        time1: [""],
        time2: [""],

        checkP1: [""],
        checkP2: [""],
        ReturnError: [""],
      }),
      days4: that.fb.group({
        /*Outbound*/
        pickup1: ["", [Validators.required]],
        dropOff1: ["", [Validators.required]],
        /*Return*/
        dropOff2: ["", [Validators.required]],
        pickup2: ["", [Validators.required]],
        /*Time*/
        time1: [""],
        time2: [""],

        checkP1: [""],
        checkP2: [""],
        ReturnError: [""],
      }),
      days5: that.fb.group({
        /*Outbound*/
        pickup1: ["", [Validators.required]],
        dropOff1: ["", [Validators.required]],
        /*Return*/
        dropOff2: ["", [Validators.required]],
        pickup2: ["", [Validators.required]],
        /*Time*/
        time1: [""],
        time2: [""],

        checkP1: [""],
        checkP2: [""],
        ReturnError: [""],
      }),
      days6: that.fb.group({
        /*Outbound*/
        pickup1: ["", [Validators.required]],
        dropOff1: ["", [Validators.required]],
        /*Return*/
        dropOff2: ["", [Validators.required]],
        pickup2: ["", [Validators.required]],
        /*Time*/
        time1: [""],
        time2: [""],

        checkP1: [""],
        checkP2: [""],
        ReturnError: [""],
      }),
      days7: that.fb.group({
        /*Outbound*/
        pickup1: ["", [Validators.required]],
        dropOff1: ["", [Validators.required]],
        /*Return*/
        dropOff2: ["", [Validators.required]],
        pickup2: ["", [Validators.required]],
        /*Time*/
        time1: [""],
        time2: [""],

        checkP1: [""],
        checkP2: [""],
        ReturnError: [""],
      }),
      days1: that.fb.group({
        /*Outbound*/
        pickup1: ["", [Validators.required]],
        dropOff1: ["", [Validators.required]],
        /*Return*/
        dropOff2: ["", [Validators.required]],
        pickup2: ["", [Validators.required]],
        /*Time*/
        time1: [""],
        time2: [""],

        checkP1: [""],
        checkP2: [""],
        ReturnError: [""],
      }),
    });
    let daysNumber = [1, 2, 3, 4, 5, 6, 7];
    daysNumber.forEach(element => {
      that.prepareDaysForm(element);
    });
  }

  get places() {
    let that = this;
    let data = JSON.parse(sessionStorage.getItem("Places"));
    if (data && data.length > 0) {
      return data;
    }
    return that.PlaceGridData;
  }

  setFormData() {
    let that = this;
    this.newScheduleForm.setValue({
      Name: that.data.Name,
      ServiceStartDate: new Date(that.data.ServiceStartDate),
      ServiceEndDate: that.data.ServiceEndDate,
      InternalNote: that.data.InternalNote,
    });

    that.ServiceStartDateInitialDate = new Date(that.data.ServiceStartDate);
    that.ServiceEndDateInitialDate = new Date(that.data.ServiceEndDate);
  }

  public isValidForm(): boolean {
    let that = this;
    let counter = 0;
    let formDaysValid: boolean = true;
    for (let control of Object.keys(that.newScheduleForm.controls)) {
      that.newScheduleForm.controls[control].markAsTouched();
    }
    for (let i = 1; i <= 7; i++) {
      let name = `days${i}`;
      let form: any = that.formDays.controls[name];
      formDaysValid =
        formDaysValid && form.valid && form.controls.ReturnError.value == "";

      for (let item of Object.keys(form.controls)) {
        form.controls[item].markAsTouched();
        if (
          item == "pickup1" ||
          item == "dropOff1" ||
          item == "dropOff2" ||
          item == "pickup2"
        ) {
          if (form.controls[item].disabled) {
            counter++;
          }
        }
      }
    }

    if (counter == 28) {
      that.attentionInfo = true;
      formDaysValid = false;
    }
    return that.newScheduleForm.valid && formDaysValid;
  }

  public open() {
    let that = this;
    if (this.data && this.data != null) {
      this.PageTitle = "Edit Transportation Schedule";
      that.setFormData();
      this._modalService.open(this.AddNewScheduleModal, {
        centered: true,
        backdrop: "static",
        modalDialogClass: "dialog",
        // size: 'lg',
      });
    } else {
      this.PageTitle = "Add Transportation Schedule";
      that.initialForm();
      this._modalService.open(this.AddNewScheduleModal, {
        centered: true,
        backdrop: "static",
        modalDialogClass: "dialog",
        // size: 'lg',
      });
    }
  }

  close(model: any) {
    let that = this;
    for (const control of Object.keys(that.newScheduleForm.controls)) {
      that.newScheduleForm.controls[control].markAsUntouched();
    }
    that.newScheduleForm.reset();
    that.validRelationShip = false;
    that.data = null;
    model.close("Accept click");
  }

  closeAttentionDialog() {
    let that = this;
    that.attentionInfo = false;
  }

  onSubmit(modal: any) {
    let that = this;
    that.submitted = true;

    if (that.isValidForm()) {
      if (this.studentId != 0) {
        that._appcomponent.toggeleLoader();
        that._clientStudentService
          .addScheduleStudent(that.getAddScheduleObject())
          .subscribe(
            (Response: any) => {
              that._toastrService.success(
                "The transportation schedule was added successfully.",
                "",
              );
              that.Added.emit({
                data: that.getAddScheduleObject(),
                mode: "edit",
              });
              that.close(modal);
              that._appcomponent.toggeleLoader();
            },
            error => {
              if (error.status == 401) {
                that._toastrService.error("UnAuthorized.", "");
                window.location.href = "/pages/authentication/login";
                that._appcomponent.toggeleLoader();
              } else if (error.status == 300) {
                that._toastrService.error(error.error.Message, "");
                that._appcomponent.toggeleLoader();
              } else {
                that._toastrService.error(
                  "The transportation schedule was not added due to technical issue.",
                  "",
                );
                that._appcomponent.toggeleLoader();
              }
            },
          );
      } else {
        that.Added.emit({ data: that.getAddScheduleObject(), mode: "add" });
        that.close(modal);
      }
    }
  }

  getAddScheduleObject() {
    let that = this;
    let data = new AddTransportaionScheduleStudentDataModel();
    data.isAddedFromClientPortal = true;
    let transportationScheduleData =
      new TransportaionScheduleStudentDataModel();
    transportationScheduleData.StudentId = this.studentId;
    transportationScheduleData.Name = that.newScheduleForm.value.Name;
    transportationScheduleData.ServiceStartDate = new Date(
      that.newScheduleForm.value.ServiceStartDate,
    ).toDateString();
    transportationScheduleData.ServiceEndDate = new Date(
      that.newScheduleForm.value.ServiceEndDate,
    ).toDateString();
    transportationScheduleData.InternalNote =
      that.newScheduleForm.value.InternalNote;
    transportationScheduleData.GroupId = that.data ? that.data.GroupId : 0;

    transportationScheduleData.StudentScheduleDetails =
      that.getTransportationScheduleDetails();
    data.StudentSchedules = [];
    data.StudentSchedules.push(transportationScheduleData);
    return data;
  }

  getTransportationScheduleDetails() {
    let that = this;
    let daysNumber = [2, 3, 4, 5, 6, 7, 1];

    let temTransportationScheduleDetails: Array<ScheduleStudentDataModel> = [];

    daysNumber.forEach(element => {
      temTransportationScheduleDetails =
        that.prepareObjTransportationScheduleOutbound(
          element,
          temTransportationScheduleDetails,
        );
    });
    //------------------------------------------------
    daysNumber.forEach(element => {
      temTransportationScheduleDetails =
        that.prepareObjTransportationScheduleReturn(
          element,
          temTransportationScheduleDetails,
        );
    });

    return temTransportationScheduleDetails;
  }

  closeConfiramtion() {
    this.guardianexist = false;
  }

  get InternalNote() {
    return this.newScheduleForm.get("InternalNote");
  }

  get ServiceStartDate() {
    return this.newScheduleForm.get("ServiceStartDate");
  }

  get ServiceEndDate() {
    return this.newScheduleForm.get("ServiceEndDate");
  }

  get Name() {
    return this.newScheduleForm.get("Name");
  }
  prepareObjTransportationScheduleReturn(
    DayId: number,
    temTransportationScheduleDetails: Array<ScheduleStudentDataModel>,
  ): Array<ScheduleStudentDataModel> {
    let that = this;
    const objTransportationScheduleReturn = new ScheduleStudentDataModel();
    objTransportationScheduleReturn.Type = "Return";
    const form: any = that.formDays.controls[`days${DayId}`];
    objTransportationScheduleReturn.DayId = DayId;
    if (!form.controls["pickup2"].disabled) {
      objTransportationScheduleReturn.PickUpStudentPlaceId =
        form.controls["pickup2"].value;
      objTransportationScheduleReturn.PickUpStudentPlaceName =
        form.controls["pickup2"].value.name;
    }
    if (!form.controls["dropOff2"].disabled) {
      objTransportationScheduleReturn.DropOffStudentPlaceId =
        form.controls["dropOff2"].value;
      objTransportationScheduleReturn.DropOffStudentPlaceName =
        form.controls["dropOff2"].value.name;
    }
    if (
      parseInt(objTransportationScheduleReturn.PickUpStudentPlaceId) > 0 ||
      parseInt(objTransportationScheduleReturn.DropOffStudentPlaceId) > 0 ||
      parseInt(objTransportationScheduleReturn.PickUpStudentPlaceId) == 0 ||
      parseInt(objTransportationScheduleReturn.DropOffStudentPlaceId) == 0
    ) {
      temTransportationScheduleDetails.push(objTransportationScheduleReturn);
    }
    return temTransportationScheduleDetails;
  }
  prepareObjTransportationScheduleOutbound(
    DayId: number,
    temTransportationScheduleDetails: Array<ScheduleStudentDataModel>,
  ): Array<ScheduleStudentDataModel> {
    let that = this;
    const objTransportationScheduleOutbound = new ScheduleStudentDataModel();
    objTransportationScheduleOutbound.Type = "Outbound";
    const form: any = that.formDays.controls[`days${DayId}`];
    objTransportationScheduleOutbound.DayId = DayId;
    if (!form.controls["pickup1"].disabled) {
      objTransportationScheduleOutbound.PickUpStudentPlaceId =
        form.controls["pickup1"].value;
      objTransportationScheduleOutbound.PickUpStudentPlaceName =
        form.controls["pickup1"].value.name;
    }
    if (!form.controls["dropOff1"].disabled) {
      objTransportationScheduleOutbound.DropOffStudentPlaceId =
        form.controls["dropOff1"].value;
      objTransportationScheduleOutbound.DropOffStudentPlaceName =
        form.controls["dropOff1"].value.name;
    }
    if (
      parseInt(objTransportationScheduleOutbound.PickUpStudentPlaceId) > 0 ||
      parseInt(objTransportationScheduleOutbound.DropOffStudentPlaceId) > 0 ||
      parseInt(objTransportationScheduleOutbound.PickUpStudentPlaceId) == 0 ||
      parseInt(objTransportationScheduleOutbound.DropOffStudentPlaceId) == 0
    ) {
      temTransportationScheduleDetails.push(objTransportationScheduleOutbound);
    }
    return temTransportationScheduleDetails;
  }
  prepareDaysForm(DayId: number) {
    let that = this;
    //dropOff1(Outbound)
    that.formDays.controls[`days${DayId}`]
      .get("dropOff1")
      .valueChanges.subscribe(value => {
        if (value) {
          let id = value;

          let data = that.places.filter(a => a.Id == id)[0];
          if (data?.PlaceSchedule) {
            that.formDays.controls[`days${DayId}`]
              .get("time1")
              .setValue(
                moment(data.PlaceSchedule.DefaultStartTime).format("hh:mm A"),
              );
            that.formDays.controls[`days${DayId}`]
              .get("time1")
              .markAllAsTouched();
          } else {
            that.formDays.controls[`days${DayId}`]
              .get("time1")
              .setValue("00:00");
          }
        }
      });
    //pickup2(Return)
    that.formDays.controls[`days${DayId}`]
      .get("pickup2")
      .valueChanges.subscribe(value => {
        if (value) {
          let id = value;

          let data = that.places.filter(a => a.Id == id)[0];
          if (data?.PlaceSchedule) {
            that.formDays.controls[`days${DayId}`]
              .get("time2")
              .setValue(
                moment(data.PlaceSchedule.DefaultReturnTime).format("hh:mm A"),
              );
            that.formDays.controls[`days${DayId}`]
              .get("time2")
              .markAllAsTouched();
          } else {
            that.formDays.controls[`days${DayId}`]
              .get("time2")
              .setValue("00:00");
          }
          if (that.formDays.controls[`days${DayId}`].get("dropOff1").value) {
            if (
              id != that.formDays.controls[`days${DayId}`].get("dropOff1").value
            ) {
              that.formDays.controls[`days${DayId}`]
                .get("ReturnError")
                .setValue("Pick-up conflict with outbound drop-off.");
              that.isValidDays = false;
            } else {
              that.formDays.controls[`days${DayId}`]
                .get("ReturnError")
                .setValue("");
            }
          }
        }
      });
  }
}
