import { Component, Input } from "@angular/core";
import { IFilters } from "../interfaces/controls/IFiltersConfig";

@Component({
  selector: "app-passengers",
  templateUrl: "./passengers.component.html",
})
export class PassengersComponent {
  @Input("filterData") Config: IFilters;
}
