import { Component, Input, OnInit } from "@angular/core";
import { IFilters } from "../interfaces/controls/IFiltersConfig";

@Component({
  selector: "app-ride-types",
  templateUrl: "./ride-types.component.html",
})
export class RideTypesComponent implements OnInit {
  @Input("filterData") Config: IFilters;

  ngOnInit(): void {
    this.Config.filterValues = [
      { id: "Outbound", name: "Outbound" },
      { id: "Return", name: "Return" },
    ];
  }
}
