<div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
        <!-- Login v1 -->
        <div class="card mb-0">
            <div class="card-body">
                <a href="javascript:void(0);"
                   class="brand-logo">
                    <img src="assets/images/logo/FullLogo.png"
                         alt="brand-logo"
                         height="45" />
                </a>
                <h4 class="card-title mb-1 text-primary">Welcome to Adroit! 👋</h4>
                <p class="card-text mb-2">
                    Please sign-in to your account and start the adventure
                </p>
                <form class="auth-login-form mt-2"
                      [formGroup]="loginForm"
                      (ngSubmit)="onSubmit()"
                      (keydown.enter)="onSubmit()">
                    <div class="form-group">
                        <label for="login-email"
                               class="form-label">Email</label>
                        <input type="text"
                               formControlName="email"
                               class="form-control"
                               placeholder="you@domain.com"
                               aria-describedby="login-email"
                               tabindex="1"
                               autofocus
                               [ngClass]="{ 'is-invalid': submitted && f.email.errors }"
                               data-automation-id="login-email"
                               (change)="CheckValidation()" />
                        <div *ngIf="submitted && f.email.errors"
                             class="invalid-feedback">
                            <div *ngIf="f.email.errors.required">Email is required</div>
                            <div *ngIf="f.email.errors.email">
                                Email must be a valid email address
                            </div>
                        </div>
                    </div>

                    <div class="form-group">
                        <div class="d-flex justify-content-between">
                            <label for="login-password">Password</label>
                        </div>
                        <div class="input-group input-group-merge form-password-toggle input-group-focus">
                            <input [type]="passwordTextType ? 'text' : 'password'"
                                   formControlName="password"
                                   class="form-control form-control-merge"
                                   tabindex="2"
                                   placeholder="Password"
                                   aria-describedby="login-password"
                                   autocomplete="off"
                                   data-automation-id="login-password"
                                   [ngClass]="{
                  'is-invalid error': submitted && f.password.errors
                }" />

                            <div class="input-group-append">
                                <span class="input-group-text cursor-pointer">
                                    <i class="feather font-small-4"
                                       [ngClass]="{
                      'icon-eye-off': passwordTextType,
                      'icon-eye': !passwordTextType
                    }"
                                       (click)="togglePasswordTextType()"
                                       data-automation-id="show-password"></i>
                                </span>
                            </div>
                        </div>
                        <div *ngIf="submitted && f.password.errors"
                             class="invalid-feedback"
                             [ngClass]="{ 'd-block': submitted && f.password.errors }">
                            <div *ngIf="f.password.errors.required">Password is required</div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6">
                            <div class="form-group">
                                <div class="custom-control custom-checkbox">
                                    <input class="custom-control-input"
                                           type="checkbox"
                                           id="remember-me"
                                           tabindex="3"
                                           value="true"
                                           disabled />
                                    <label class="custom-control-label"
                                           for="remember-me">
                                        Remember Me
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class="col-6 text-right">
                            <button class="btn btn-link p-0"
                                    style="color: #b8c2cc !important"
                                    disabled>
                                Forgot Password?
                            </button>
                        </div>
                    </div>

                    <button type="submit"
                            class="btn btn-info btn-block"
                            tabindex="4"
                            data-automation-id="sign-in"
                            rippleEffect>
                        Sign in
                    </button>
                    <div class="row mt-1">
                        <h6><span> or </span></h6>
                    </div>
                    <div class="row mt-1">
                        <div class="col">
                            <button id="Google-SignIn"
                                    type="button"
                                    class="google-button"
                                    onclick="handleGoogleLogin(); return false;">
                                <div class="google-icon-wrapper">
                                    <img width="15" height="13" src="assets/images/icons/google-icon.svg" />
                                </div>
                                <p class="google-button-text">Sign in with Google</p>
                            </button>
                        </div>
                    </div>

                    <div class="row mt-1">
                        <div class="col">
                            <button type="button"
                                    class="google-button"
                                    (click)="signInWithMicrosoft()">
                                <div class="google-icon-wrapper">
                                    <img width="15" height="13" src="assets/images/icons/microsoft-icon.png" />
                                </div>
                                <p class="google-button-text">Sign in with Microsoft</p>
                            </button>
                        </div>
                    </div>

                    <p class="card-text mb-2 condition">
                        By signing in I agree to ADROITs
                        <a href="https://www.goadroit.com/terms-of-service"
                           data-automation-id="register-link"
                           target="_blank"
                           class="cui-utils-link-blue cui-utils-link-underlined"
                           rel="noopener">terms of service</a>.
                    </p>
                </form>
            </div>
        </div>
        <!-- /Login v1 -->
    </div>
</div>
