import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from "@angular/core";
import { IFilters } from "../interfaces/controls/IFiltersConfig";

@Component({
  selector: "app-weekly-periods",
  templateUrl: "./weekly-periods.component.html",
})
export class WeeklyPeriodsComponent {
  @Input("filterData") Config: IFilters;
  @Output() changeValue = new EventEmitter<string>();
  @ViewChild("weeklyperiod") weeklyperiod: any;

  onchange() {
    this.changeValue.emit();
  }
}
