import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { CoreCommonModule } from "@core/common.module";
import { ContentHeaderModule } from "app/layout/components/content-header/content-header.module";
import { BrowserModule } from "@angular/platform-browser";
import { DataTablesModule } from "angular-datatables";
import { QuillModule } from "ngx-quill";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { NgSelectModule } from "@ng-select/ng-select";
import { OwlDateTimeModule, OwlNativeDateTimeModule } from "ng-pick-datetime";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import { CsvModule } from "@ctrl/ngx-csv";
import { GridModule } from "@progress/kendo-angular-grid";
import { PipesModule } from "../../utilities/customPipes/customPipesModule";
import { AuthGuard } from "app/auth/helpers/auth.guards";
import { AuthorizationGuard } from "app/auth/helpers/authorization.guard";
import { PermissionsList } from "app/enums/Permissions/PermissionList";
import { FiltersModule } from "app/controls/component/filters/filters.module";
import { ClientGuardiansComponent } from "./ClientGuardians/client-guardians.component";
import { ClientGuardiansService } from "./services/client-guardians.service";
import { DialogsModule } from "@progress/kendo-angular-dialog";
import { AddNewGuardianComponent } from "./AddNewGuardian/add-new-guardian.component";
import { LayoutModule } from "@progress/kendo-angular-layout";
import { InputsModule } from "@progress/kendo-angular-inputs";
import { ControlsModule } from "app/controls/component/custom-input/controls.module";
import { GuardianSelectorsComponent } from "./GuardianSelectors/guardian-selectors.component";
import { GuardianAppUsersComponent } from "./GuardianAppUsers/guardian-app-users.component";
import { PhoneMaskDirective } from "app/utilities/directives/PhoneNumber.directive";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { EditGuardianComponent } from "./EditGuardian/edit-guardian.component";
import { PhotoModule } from "app/controls/component/photo/personal-photo/photo.module";
import { AddressGurdiansSectionComponent } from "./AddressStudentSection/address-gurdians-section.component";
import { GurdiansAddNewAddressComponent } from "./AddressStudentSection/GuardiansAddNewHomeAddress/gurdians-add-new-address.component";
const routes = [
  {
    path: "client-gurdians/gurdians",
    component: ClientGuardiansComponent,
    data: {
      animation: "ClientGurdians",
      PermissionPageName: [
        PermissionsList[PermissionsList.ClientClientInvoicesView],
      ],
    },
    canActivate: [AuthGuard, AuthorizationGuard],
  },
  {
    path: "client-gurdians/gurdians/edit/:id",
    component: EditGuardianComponent,
    data: {
      animation: "ClientGurdians",
      PermissionPageName: [
        PermissionsList[PermissionsList.ClientClientInvoicesView],
      ],
    },
    canActivate: [AuthGuard, AuthorizationGuard],
  },
];

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],

  declarations: [
    ClientGuardiansComponent,
    AddNewGuardianComponent,
    GuardianSelectorsComponent,
    GuardianAppUsersComponent,
    EditGuardianComponent,
    AddressGurdiansSectionComponent,
    GurdiansAddNewAddressComponent,
    PhoneMaskDirective,
  ],
  exports: [
    ClientGuardiansComponent,
    AddNewGuardianComponent,
    GuardianSelectorsComponent,
    GuardianAppUsersComponent,
    EditGuardianComponent,
    AddressGurdiansSectionComponent,
    GurdiansAddNewAddressComponent,
    PhoneMaskDirective,
  ],
  providers: [ClientGuardiansService],
  imports: [
    RouterModule.forChild(routes),
    ContentHeaderModule,
    QuillModule.forRoot(),
    NgxDatatableModule.forRoot({
      messages: {
        emptyMessage: '<div class="text-center">No records available</div>', // Message to show when array is presented, but contains no values
        totalMessage: "record", // Footer total message
        selectedMessage: "selected", // Footer selected message
      },
    }),
    GridModule,
    TranslateModule,
    CoreCommonModule,
    BrowserModule,
    DataTablesModule,
    NgbModule,
    NgSelectModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    CsvModule,
    FiltersModule,
    PipesModule,
    DialogsModule,
    LayoutModule,
    InputsModule,
    ControlsModule,
    FormsModule,
    ReactiveFormsModule,
    PhotoModule,
  ],
})
export class GuardiansModule {}
