import { Component, Input, OnInit } from "@angular/core";
import { IFilters } from "../interfaces/controls/IFiltersConfig";

@Component({
  selector: "app-periods",
  templateUrl: "./periods.component.html",
})
export class PeriodsComponent implements OnInit {
  @Input("filterData") Config: IFilters;

  ngOnInit(): void {
    this.Config.filterValues = [
      { id: "Morning", name: "Morning (AM)" },
      { id: "Afternoon", name: "Afternoon (PM)" },
    ];
  }
}
