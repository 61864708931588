<div class="row section-div">
  <div>
    <kendo-grid
      #mainGrid
      [data]="gridData"
      [pageSize]="state.take"
      [skip]="state.skip"
      [sort]="state.sort"
      [filter]="state.filter"
      [pageable]="false"
    >
      <ng-template kendoGridNoRecordsTemplate>
        No instructions added yet.
      </ng-template>
      <ng-template
        kendoGridToolbarTemplate
        NgClass="p-0"
      >
      </ng-template>
      <kendo-grid-spacer></kendo-grid-spacer>
      <kendo-grid-column
        field="Type"
        title="Name"
        [sortable]="false"
      >
        <ng-template
          kendoGridCellTemplate
          let-dataItem
          let-rowIndex="rowIndex"
        >
          <a
            class="btn btn-link pl-0 pr-0"
            (click)="modalOpenMD(dataItem, rowIndex)"
          >
            {{ dataItem.Type }}
          </a>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column
        field="Description"
        title="Description"
        [sortable]="false"
      >
        <ng-template
          kendoGridCellTemplate
          let-dataItem
          let-rowIndex="rowIndex"
        >
          {{ dataItem.Description }}
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column
        field="Visibility"
        title="Visibility"
        [sortable]="false"
      >
        <ng-template
          kendoGridCellTemplate
          let-dataItem
          let-rowIndex="rowIndex"
        >
          <span *ngFor="let item of dataItem.Visibility; let i = index">
            <button
              style="
                pointer-events: none;
                padding: 0px 6px 0px 6px;
                color: #74708d !important;
                background-color: #e4e9f0 !important;
                border-color: #e4e9f0 !important;
                border-radius: 8px;
              "
              class="btn btn-sm"
            >
              {{ item }}
            </button>
          </span>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column
        [sortable]="false"
        title="Action"
        [width]="90"
      >
        <ng-template
          kendoGridCellTemplate
          let-dataItem
          let-rowIndex="rowIndex"
        >
          <div class="d-flex align-items-center">
            <div
              ngbDropdown
              [placement]="'left-top'"
              container="body"
              class="m-auto"
            >
              <a
                ngbDropdownToggle
                href="javascript:void(0);"
                class="hide-arrow"
                id="dropdownBrowserState"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i
                  data-feather="more-vertical"
                  class="text-primary cursor-pointer mr-50"
                ></i>
              </a>
              <div
                ngbDropdownMenu
                class="dropdown-menu-right"
                aria-labelledby="dropdownBrowserState"
                disabled
              >
                <a
                  href="javascript:void(0)"
                  (click)="modalOpenMD(dataItem, rowIndex)"
                  ngbDropdownItem
                  class="d-flex align-items-center"
                >
                  Edit
                </a>
                <div
                  class="dropdown-divider"
                  *ngIf="!disabeld"
                ></div>
                <a
                  href="javascript:void(0)"
                  (click)="deleteInstruction(dataItem, rowIndex)"
                  ngbDropdownItem
                  class="d-flex align-items-center"
                  *ngIf="!disabeld"
                >
                  Delete
                </a>
              </div>
            </div>
          </div>
        </ng-template>
      </kendo-grid-column>
    </kendo-grid>
  </div>
  <div class="col-lg-3">
    <button
      type="button"
      rippleeffect=""
      class="btn btn-close waves-effect waves-float waves-light mt-1"
      style="border-color: #1877f2; margin-bottom: 20px; color: #1877f2"
      (click)="modalOpenMD()"
      [hidden]="disabeld"
    >
      Add Instruction
    </button>
  </div>
</div>

<kendo-dialog
  title=""
  *ngIf="openedDelete"
  (close)="close('deleted')"
  [minWidth]="250"
  [width]="400"
>
  <p style="text-align: center">
    Are you sure you want to delete the instruction?
  </p>
  <kendo-dialog-titlebar>
    <div style="font-size: 18px; line-height: 1.3em; font-weight: 500">
      <span
        class="k-icon k-i-info-circle mr-1"
        style="color: #eea236; font-size: 26px; margin-top: -3px"
      ></span
      >Delete Confirmation
    </div>
  </kendo-dialog-titlebar>
  <kendo-dialog-actions>
    <p style="text-align: center">
      <button
        type="button"
        (click)="close('deleted')"
        rippleeffect=""
        class="btn btn-default waves-effect waves-float waves-light"
        style="
          background-color: #acb7bf;
          border-color: #acb7bf;
          color: white;
          margin-right: 10px;
        "
      >
        No
      </button>
      <button
        type="button"
        (click)="confirmDeleteInstruction()"
        rippleeffect=""
        class="btn btn-danger waves-effect waves-float waves-light"
      >
        Yes
      </button>
    </p>
  </kendo-dialog-actions>
</kendo-dialog>

<app-student-add-new-service-instruction
  [GridData]="gridData"
  #addNewInstruction
  (Added)="reloadData($event)"
  [disable]="disabeld"
></app-student-add-new-service-instruction>
