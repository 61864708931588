<input
  type="{{ type }}"
  id="{{ Id }}"
  [(ngModel)]="input"
  (input)="onChange($any($event.target).value)"
  (blur)="onTouched(); blur.emit()"
  [disabled]="disabled"
  (ngModelChange)="writeValue($event)"
  [placeholder]="placeholder"
  class="form-control"
  maxlength="{{ maxlength }}"
  [ngClass]="{
    'is-invalid': controlDir?.control?.touched && controlDir.control?.invalid
  }"
/>
<div
  class="invalid-feedback"
  style="font-size: 10px !important"
>
  This field is required, please insert a value.
</div>
