<ng-template
  #AddNewContactModal
  let-modal
>
  <div class="modal-header">
    <h5
      class="modal-title"
      id="myModalLabel160"
    >
      {{ PageTitle }}
    </h5>

    <button
      type="button"
      class="close"
      (click)="close(modal)"
      aria-label="Close"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div
    class="modal-body"
    tabindex="0"
    style="overflow-y: hidden; overflow-x: hidden; padding: 14px"
    ngbAutofocus
  >
    <div class="row">
      <div class="row">
        <div class="col-lg-12">
          <h4 class="text-black">
            <strong style="margin: 14px">Basic Details</strong>
          </h4>
        </div>
        <div class="col-12">
          <form
            [formGroup]="newContactForm"
            (ngSubmit)="onSubmit(modal)"
          >
            <div
              *ngIf="Config.Filters[0].filterVisability"
              class="col-12"
            >
              <!-- Basic Select with Search -->
              <div style="margin-bottom: 5px">Relationship to Student</div>

              <ng-select
                appendTo="body"
                [(ngModel)]="Config.Filters[0].filterValue"
                [clearable]="Config.Filters[0].filterClear"
                [multiple]="Config.filterMultiple"
                [placeholder]="Config.Filters[0].filterPlaceholder"
                formControlName="relationshipName"
                (change)="relationshipSelected()"
                (click)="relationshipSelected()"
              >
                <ng-option
                  *ngFor="let item of Config.Filters[0].filterValues"
                  [value]="item.id"
                  >{{ item.name }}</ng-option
                >
              </ng-select>
              <div class="dll-invalid-feedback">
                <div
                  *ngIf="validRelationShip"
                  class="invalid-feedback"
                  style="font-size: 10px !important"
                  [ngClass]="{ 'd-block': validRelationShip }"
                >
                  <div *ngIf="validRelationShip">
                    This field is required, please select a value.
                  </div>
                </div>
              </div>
              <!--/ Basic Select with Search -->
            </div>
            <div class="col-12 separate"></div>

            <div
              class="row"
              style="
                display: flex;
                overflow-x: hidden;
                overflow-y: hidden;
                padding-left: 15px;
                padding-right: 15px;
              "
            >
              <div class="col-lg-6">
                <label>First Name</label>
                <app-required-field
                  type="text"
                  formControlName="FirstName"
                  placeholder="First Name"
                ></app-required-field>
              </div>
              <div class="col-lg-6">
                <label>Last Name</label>
                <app-required-field
                  type="text"
                  formControlName="LastName"
                  placeholder="Last Name"
                ></app-required-field>
              </div>
              <div class="col-lg-12 separate"></div>
              <div class="col-lg-6">
                <label>Phone Number</label>
                <app-required-field
                  type="tel"
                  formControlName="PhoneNumber"
                  placeholder="(000) 000-0000"
                  maxlength="14"
                  [appPhoneMask]
                ></app-required-field>
              </div>
              <div class="col-lg-6">
                <label>Ext.</label>
                <app-optional-field
                  type="number"
                  formControlName="ContactPhoneExtention"
                  placeholder="000000 (optional)"
                  [maxlength]="6"
                  validationError="This field is required, please Insert a value."
                ></app-optional-field>
              </div>
              <div class="col-lg-12 separate"></div>
              <div class="col-lg-12">
                <label>Email</label>
                <app-optional-field
                  type="text"
                  formControlName="Email"
                  placeholder="name@domain.com (optional)"
                  validationError="Please enter a valid email address."
                ></app-optional-field>
              </div>
              <div class="col-lg-12 separate"></div>
              <div class="col-lg-12">
                <label>Internal Note</label>
                <app-required-field-textarea
                  formControlName="ContactNote"
                  placeholder="Add notes here. (optional)"
                  [validationError]="validationError"
                ></app-required-field-textarea>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>

  <div class="modal-footer about-adroit-footer">
    <button
      type="button"
      (click)="close(modal)"
      rippleeffect=""
      class="btn btn-close waves-effect waves-float waves-light"
      style="border-color: #1877f2; margin-bottom: 20px; color: #1877f2"
    >
      Close
    </button>
    <button
      type="submit"
      class="btn btn-info"
      (click)="onSubmit(modal)"
      style="margin-bottom: 20px; margin-right: 20px"
      rippleEffect
    >
      Submit
    </button>
  </div>
</ng-template>
<ng-template
  #EditContactModal
  let-modal
>
  <div class="modal-header">
    <h5
      class="modal-title"
      id="myModalLabel160"
    >
      {{ PageTitle }}
    </h5>

    <button
      type="button"
      class="close"
      (click)="close(modal)"
      aria-label="Close"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div
    class="modal-body"
    tabindex="0"
    style="overflow-y: hidden; overflow-x: hidden; padding: 14px"
    ngbAutofocus
  >
    <div class="row">
      <div class="row">
        <div class="col-lg-12">
          <h4 class="text-black">
            <strong style="margin: 14px">Basic Details</strong>
          </h4>
        </div>
        <div class="col-12">
          <form
            [formGroup]="newContactForm"
            (ngSubmit)="onSubmit(modal)"
          >
            <div
              *ngIf="Config.Filters[0].filterVisability"
              class="col-12"
            >
              <!-- Basic Select with Search -->
              <div style="margin-bottom: 5px">Relationship to Student</div>

              <ng-select
                appendTo="body"
                [(ngModel)]="Config.Filters[0].filterValue"
                [clearable]="Config.Filters[0].filterClear"
                [disabledd]="PermessionValue"
                [multiple]="Config.filterMultiple"
                [placeholder]="Config.Filters[0].filterPlaceholder"
                formControlName="relationshipName"
                (change)="relationshipSelected()"
                (click)="relationshipSelected()"
              >
                <ng-option
                  *ngFor="let item of Config.Filters[0].filterValues"
                  [value]="item.id"
                  >{{ item.name }}</ng-option
                >
              </ng-select>
              <div
                *ngIf="validRelationShip"
                class="invalid-feedback"
                style="font-size: 10px !important"
                [ngClass]="{ 'd-block': validRelationShip }"
              >
                <div *ngIf="validRelationShip">
                  This field is required, please select a value.
                </div>
              </div>
              <!--/ Basic Select with Search -->
            </div>
            <div class="col-12 separate"></div>

            <div
              class="row"
              style="
                display: flex;
                overflow-x: hidden;
                overflow-y: hidden;
                padding-left: 15px;
                padding-right: 15px;
              "
            >
              <div class="col-lg-6">
                <label>First Name</label>
                <app-required-field
                  type="text"
                  formControlName="FirstName"
                  placeholder="First Name"
                  [disabledd]="PermessionValue"
                ></app-required-field>
              </div>
              <div class="col-lg-6">
                <label>Last Name</label>
                <app-required-field
                  type="text"
                  formControlName="LastName"
                  placeholder="Last Name"
                  [disabledd]="PermessionValue"
                ></app-required-field>
              </div>
              <div class="col-lg-12 separate"></div>
              <div class="col-lg-6">
                <label>Phone Number</label>
                <app-required-field
                  type="tel"
                  formControlName="PhoneNumber"
                  placeholder="(000) 000-0000"
                  maxlength="14"
                  [appPhoneMask]
                  [disabledd]="PermessionValue"
                ></app-required-field>
              </div>
              <div class="col-lg-6">
                <label>Ext.</label>
                <app-optional-field
                  type="number"
                  formControlName="ContactPhoneExtention"
                  placeholder="000000 (optional)"
                  [maxlength]="6"
                  validationError="This field is required, please Insert a value."
                  [disabledd]="PermessionValue"
                ></app-optional-field>
              </div>
              <div class="col-lg-12 separate"></div>
              <div class="col-lg-12">
                <label>Email</label>
                <app-optional-field
                  type="text"
                  formControlName="Email"
                  placeholder="name@domain.com (optional)"
                  validationError="Please enter a valid email address."
                  [disabledd]="PermessionValue"
                ></app-optional-field>
              </div>
              <div class="col-lg-12 separate"></div>
              <div class="col-lg-12">
                <label>Internal Note</label>
                <app-required-field-textarea
                  formControlName="ContactNote"
                  placeholder="Add notes here. (optional)"
                  [validationError]="validationError"
                  [disabledd]="PermessionValue"
                ></app-required-field-textarea>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>

  <div class="modal-footer about-adroit-footer">
    <button
      type="button"
      (click)="close(modal)"
      rippleeffect=""
      class="btn btn-close waves-effect waves-float waves-light"
      style="border-color: #1877f2; margin-bottom: 20px; color: #1877f2"
    >
      Close
    </button>
    <button
      type="submit"
      class="btn btn-info"
      (click)="onSubmit(modal)"
      style="margin-bottom: 20px; margin-right: 20px"
      [hidden]="disabledContact"
      rippleEffect
    >
      Submit
    </button>
  </div>
</ng-template>
