import { Component, Input } from "@angular/core";
import { IFilters } from "../interfaces/controls/IFiltersConfig";

@Component({
  selector: "app-blue-print",
  templateUrl: "./blue-print.component.html",
})
export class BluePrintComponent {
  @Input("filterData") Config: IFilters;
}
