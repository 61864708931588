import { Component, Input } from "@angular/core";
import { IFilters } from "../interfaces/controls/IFiltersConfig";

@Component({
  selector: "app-service-provider",
  templateUrl: "./service-provider.component.html",
})
export class ServiceProviderComponent {
  @Input("filterData") Config: IFilters;
}
