export class StudentContactModel {
    Id: number;
    EntityType: string = "student";
    ParentId: number;
    RelationshipToEntity: string;
    PhoneNumber: number;
    CreatedByAccountId: number;
    PhoneExtention: string;
    Email: string = "";
    TypeName: string = "Emergency Contact";
    Note: string = "";
    isClientPortal: boolean;
    FirstName: string;
    LastName: string;
}
