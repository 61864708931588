<div class="flux-container">
  <div
    class="overlay-box no-print"
    *ngIf="
      setVerticalNavbarEffect === 'overlay' &&
      setDefaultNavbar === 'offcanvas' &&
      setToggleStatus === false
    "
    (click)="changeTheToggleStatus()"
  ></div>
  <div
    class="overlay-box no-print"
    *ngIf="
      setNavLayout === 'horizontal' &&
      setDeviceType === 'mobile' &&
      setToggleStatus === false
    "
    (click)="changeTheToggleStatus()"
  ></div>
  <div class="flux-wrapper">
    <app-header
      *ngIf="isHidden"
      [navLayout]="setNavLayout"
      [defaultNavbar]="setDefaultNavbar"
      [toggleNavbar]="setToggleNavbar"
      [toggleStatus]="setToggleStatus"
      [navbarEffect]="setVerticalNavbarEffect"
      [headerColorTheme]="setHeaderColorTheme"
      [navbarColorTheme]="setNavbarColorTheme"
      [activeNavColorTheme]="setActiveNavColorTheme"
      [headerHeight]="setHeaderHeight"
      [leftHeaderColorTheme]="setLeftHeaderColorTheme"
      [collapsedLeftHeader]="setCollapsedLeftHeader"
      [deviceType]="setDeviceType"
      [headerTitle]="pageTitle"
      class="no-print"
    ></app-header>

    <app-left-panel
      *ngIf="isHidden"
      [navLayout]="setNavLayout"
      [defaultNavbar]="setDefaultNavbar"
      [toggleNavbar]="setToggleNavbar"
      [toggleStatus]="setToggleStatus"
      [navbarEffect]="setVerticalNavbarEffect"
      [headerColorTheme]="setHeaderColorTheme"
      [navbarColorTheme]="setNavbarColorTheme"
      [activeNavColorTheme]="setActiveNavColorTheme"
      [deviceType]="setDeviceType"
      class="no-print"
    >
    </app-left-panel>
    <app-content-section
      [navLayout]="setNavLayout"
      [defaultNavbar]="setDefaultNavbar"
      [toggleNavbar]="setToggleNavbar"
      [toggleStatus]="setToggleStatus"
      [navbarEffect]="setVerticalNavbarEffect"
      [headerColorTheme]="setHeaderColorTheme"
      [navbarColorTheme]="setNavbarColorTheme"
      [activeNavColorTheme]="setActiveNavColorTheme"
      [deviceType]="setDeviceType"
    >
    </app-content-section>
  </div>
</div>
